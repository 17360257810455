<template>
    <div class="">

    </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        loadingStatus: {
            type: String,
            default: ''
        },
    },
    data() {
        return {

        }
    }
}
</script>