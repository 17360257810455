<template>
  <div class="container-fluid px-0 fsc-30">
    <div class="row">
      <div class="col-2">
        <UploadFileWithPreview
          :file-preview="'https://i.stack.imgur.com/wDSxY.png'"
          accept="image/*,.pdf"
          :max-weight="10000"
          :max-x-size="1000"
          :max-y-size="750"
          :additionalParams="{}"
          :error-message-max-weight="dataLang['error_message_max_file_size']"
          classes="file-load--doc"
          @load-file=""
          @remove-file=""
          @error-load=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ObjectsService from '@/api/ObjectsService'
import UploadFileWithPreview from '@/components/UploadFileWithPreview.vue'
export default {
  components: {
    VeTable,
    UploadFileWithPreview
  },

  props: {},
  data() {
    return {
      options: ['list', 'of', 'options'],
      rowStyleOption: {
        stripe: true,
      },
      cellSelectionOption: {
        enable: false,
      },
      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        },
      },
      checkboxOption: {
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          console.log(row, isSelected, selectedRowKeys)
        },
        // selected all change event
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          console.log(isSelected, selectedRowKeys)
        },
      },
      // checkStatement(){
      //      return  (<div><vSelect :options="this.options"/></div> );
      // },
      columns: [
        {
          field: '',
          key: 'a',
          // type=checkbox
          type: 'checkbox',
          title: '',
          width: 50,
          align: 'center',
        },
        {
          field: 'name',
          key: 'b',
          title: 'Name',
          width: 200,
          align: 'left',
          sortBy: 'asc',
        },
        {
          field: 'hobby',
          key: 'c',
          title: 'Hobby',
          width: 300,
          align: 'left',
          sortBy: 'asc',
          renderHeaderCell: ({ column }, h) => {
            const scopedSlots = {
              noOptions: () => <div>{this.dataLang['select_empty']}</div>,
              // footer: () => <footer>footer</footer>,
            }
            return (
              <div>
                <span>{column.title}</span>
                <BaseSelect
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                  id="generation"
                  options={[]}
                >
                  <template slot={'noOptions'}>
                    {this.dataLang.select_empty}
                  </template>
                </BaseSelect>
              </div>
            )
          },
        },
        {
          field: 'address',
          key: 'd',
          title: 'Address',
          width: '',
          align: 'left',
        },
      ],
      tableData: [
        {
          rowKey: 1001,
          name: 'John',
          date: '1900-05-20',
          hobby: 'coding',
          address: 'No.1 Century Avenue, Shanghai',
        },
        {
          rowKey: 1002,
          name: 'Dickerson',
          date: '1910-06-20',
          hobby: 'coding',
          address: 'No.1 Century Avenue, Beijing',
        },
        {
          rowKey: 1003,
          name: 'Larsen',
          date: '2000-07-20',
          hobby: 'coding and coding repeat',
          address: 'No.1 Century Avenue, Chongqing',
        },
        {
          rowKey: 1004,
          name: 'Geneva',
          date: '2010-08-20',
          hobby: 'coding and coding repeat',
          address: 'No.1 Century Avenue, Xiamen',
        },
        {
          rowKey: 1005,
          name: 'Jami',
          date: '2020-09-20',
          hobby: 'coding and coding repeat',
          address: 'No.1 Century Avenue, Shenzhen',
        },
      ],
    }
  },
  methods: {
    uploadFile(e) {
      console.log(e.target.files[0])
    },
    sortChange(params) {
      this.tableData.sort((a, b) => {
        if (params.age) {
          if (params.age === 'asc') {
            return a.age - b.age
          } else if (params.age === 'desc') {
            return b.age - a.age
          } else {
            return 0
          }
        } else if (params.weight) {
          if (params.weight === 'asc') {
            return a.weight - b.weight
          } else if (params.weight === 'desc') {
            return b.weight - a.weight
          } else {
            return 0
          }
        }
      })
    },
    // async loadDoc() {
    //   let datas = await fetch('http://api.yandexrent.development/vehicles/table/convert/xlsx?type=car&where=[]', {
    //     headers: {
    //       'X-Employee-Token':
    //         'dsAuUj8c7q7R2zqfX6ciWkm9EBnnWazCitsqovUd0aNf3SoqxTmGtRap7IKteERi8Kiiu+v6qU14swaBWz9/uIL0TXS7HV/33pzGHsfChvZbiOzW8rbl+ZSXZfoRwj7KLe0exzsj1Cg3ajGCagZenyLQKTWxSTiHkLq6zeA1VMI0ST/XFWechXbypnOPJ1YUzPWM9yEuAUeTqhUXPue9Qw==',
    //     },
    //   });
    //   //   Здесь нужно присвоить ответ для реактивного отобраения в шаблоне
    //   // Например: this.resultTest = await datas.json()
    //   const blob = await datas.blob();
    //   if (blob != null) {
    //     const filename = new Date().toISOString();
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = `${filename}.xlsx`;
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //   }
    // },
    //   Без await
    ...mapActions('transportVehiclesModule', [
      'getTransportVehiclesAll',
      'editTransportVehiclesMultiple',
      'createTransportVehiclesData',
      'deleteTransportVehiclesData',
      'downloadTransportVehiclesExel',
      'toArchiveTransportVehiclesMultiple',
      'duplicateTransportVehiclesMultiple',
      'toExcelTransportVehiclesData',
    ]),
  },
  mounted() {
    // EventBus.$emit("openModal", { nameForAction: 'TollRoadsModal' })
  },

  created() {
    // Метод вызываетя сразу при компиляции шаблона

    Promise.all([
      ObjectsService.getAll({
        url: `/193?fields=vehicles__docs_file_1,vehicles__docs_file_2,vehicles__docs_file_3,vehicles__docs_file_4,vehicles__docs_file_5,vehicles__docs_file_6`,
      })
        .then((data) => {
          console.log(data)
        })
        .catch((err) => {
          // console.log(err)
        }),
    ]).then(() => {
      console.log('This end')
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
  },
}
</script>
