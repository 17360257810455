import http from "@/http-common.js";

class BookPhonesService {
  basePathesApiService = {
    "\/book\/admin-contacts":"/book-contacts-admin",
    "\/book\/contacts":"/book-contacts",
  }

  //basePathApiService = "/book-passwords-admin";
 
  getAll(params) {
    console.log(this.basePathApiServic)
    return http.get(this.basePathApiService, params);
  } 
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(id, editData) {
    return http.put(this.basePathApiService + `/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
  setRoute(route){
    this.basePathApiService = this.basePathesApiService[route];
  }
}
export default new BookPhonesService();
