<template>
    <a :href="linkToDoc">{{ this.title }}</a>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            API_PATH: null
        }
    },
    props: {
        path: {
            type: String,
        },
        hash: {
            type: String,
        },
        title: {
            type: String,
        },
        
    },
    computed: {
        linkToDoc() {
            return `${this.API_PATH}/${this.path}${this.hash}`
        }
    },
    methods: {
    },
    created() {
        switch (process.env.VUE_APP_MODE_BUILD) {
            case 'development':
                this.API_PATH = 'http://api.yandexrent.development'
                break;
            case 'production':
                this.API_PATH = 'https://api.yandexrent.ru'
                break;
            case 'testing':
                this.API_PATH = 'https://api.yandex-rent.ru'
                break;
            case 'development-testing':
                this.API_PATH = 'http://api.yandexrent.development'
                break;
        }
    }
};
</script>