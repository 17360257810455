<template>
  <div>
    <BaseTopLine>
      <div class="d-flex gap-10 align-items-center">
        <div
          class="btn btn-sm btn-primary"
          @click="openModalForCreate('checklistModal')"
        >
          Добавить сценарии
        </div>
        <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">
          Удалить сценарий
        </div>
      </div>
    </BaseTopLine>
    <div class="p-20">
      <BasePagination
        :page-count="numPages"
        :page-range="3"
        :margin-pages="2"
        :break-view-class="'d-none'"
        :click-handler="clickLoadData"
        :prev-text="'Назад'"
        :next-text="'Далее'"
        :container-class="'pagination pagination-sm'"
        :page-class="'page-item'"
        :pageLinkClass="'page-link'"
      >
      </BasePagination>

      <BaseReuseTable
        :props-data="checklistData"
        :props-columns-setting="fieldsTableChecklist"
        :selectRowActivateFlag="true"
        :modalActivateFlag="true"
        nameForAction="checklistModal"
        v-if="checklistLoadStatus == 'success' && loadJobTitiles"
      >
      </BaseReuseTable>
    </div>
    <Teleport to="body">
      <Modal nameForAction="checklistModal" @closeModal="closeModal">
        <div class="p-10">
          <span>Выберите должность</span>
          <BaseSelect
            placeholder=""
            selectLabel=""
            deselectLabel=""
            v-model="dataForSelect.dataJobTitle"
            @input="setSimpleSelect"
            id="jobtitle"
            :options="dataJobTitleOptions"
            label="employee_jobtitles__name"
          >
            <div slot="no-options">Пока нет опций</div>
          </BaseSelect>
          <!-- //             {
//   "checklist_scripts__company_id": 2,
//   "checklist_scripts__created_at": null,
//   "checklist_scripts__description": "Сдать декларацию за прошлый год\nдо 25 марта",
//   "checklist_scripts__id": 7,
//   "checklist_scripts__is_friday": null,
//   "checklist_scripts__is_monday": null,
//   "checklist_scripts__is_saturday": null,
//   "checklist_scripts__is_sunday": null,
//   "checklist_scripts__is_thursday": null,
//   "checklist_scripts__is_tuesday": null,
//   "checklist_scripts__is_wensday": null,
//   "checklist_scripts__jobtitle_id": 2,
//   "checklist_scripts__name": "Налоговая: УСН3 и баланс",
//   "checklist_scripts__period": "checklist_period_everyday",
//   "checklist_scripts__time": "14:05:00",
//   "checklist_scripts__updated_at": null
// } -->

          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.checklist_scripts__name"
            name-sign="Наименование"
            input-name="checklist_scripts__name"
          >
          </BaseInput>
          <span> Время </span>
          <BaseDatePicker
            v-model="dateTimeSelect.timeStart"
            type="time"
            class="wp-100"
            lang="ru"
            value-type="format"
            format="HH:mm"
          ></BaseDatePicker>
          <BaseTextarea
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.checklist_scripts__description"
            name-sign="Описание"
            input-name="checklist_scripts__description"
          >
          </BaseTextarea>
          <span>Период</span>
          <BaseSelect
            placeholder=""
            selectLabel=""
            deselectLabel=""
            v-model="dataForSelect.dataPeriod"
            @input="setSimpleSelect"
            id="period"
            :options="dataPeriodOptions"
            label="label"
          >
            <div slot="no-options">Пока нет опций</div>
          </BaseSelect>
          <div
            class="my-15"
            v-if="dataForSelect.dataPeriod.value == 'checklist_period_weekday'"
          >
            <!-- //   "checklist_scripts__is_friday": null,
//   "checklist_scripts__is_monday": null,
//   "checklist_scripts__is_saturday": null,
//   "checklist_scripts__is_sunday": null,
//   "checklist_scripts__is_thursday": null,
//   "checklist_scripts__is_tuesday": null,
//   "checklist_scripts__is_wensday": null, -->

            <BaseCheckboxV2
              placeholder=""
              input-name="checkbox1"
              v-model="dataForModal.checklist_scripts__is_monday"
              @oncheckeds="
                updateValueListAccess('checklist_scripts__is_monday')
              "
            >
              Понедельник
            </BaseCheckboxV2>
            <BaseCheckboxV2
              placeholder=""
              input-name="checkbox1"
              v-model="dataForModal.checklist_scripts__is_tuesday"
              @oncheckeds="
                updateValueListAccess('checklist_scripts__is_tuesday')
              "
            >
              Вторник
            </BaseCheckboxV2>
            <BaseCheckboxV2
              placeholder=""
              input-name="checkbox1"
              v-model="dataForModal.checklist_scripts__is_wensday"
              @oncheckeds="
                updateValueListAccess('checklist_scripts__is_wensday')
              "
            >
              Среда
            </BaseCheckboxV2>
            <BaseCheckboxV2
              placeholder=""
              input-name="checkbox1"
              v-model="dataForModal.checklist_scripts__is_thursday"
              @oncheckeds="
                updateValueListAccess('checklist_scripts__is_thursday')
              "
            >
              Четверг
            </BaseCheckboxV2>
            <BaseCheckboxV2
              placeholder=""
              input-name="checkbox1"
              v-model="dataForModal.checklist_scripts__is_friday"
              @oncheckeds="
                updateValueListAccess('checklist_scripts__is_friday')
              "
            >
              Пятница
            </BaseCheckboxV2>
            <BaseCheckboxV2
              placeholder=""
              input-name="checkbox1"
              v-model="dataForModal.checklist_scripts__is_saturday"
              @oncheckeds="
                updateValueListAccess('checklist_scripts__is_saturday')
              "
            >
              Суббота
            </BaseCheckboxV2>
            <BaseCheckboxV2
              placeholder=""
              input-name="checkbox1"
              v-model="dataForModal.checklist_scripts__is_sunday"
              @oncheckeds="
                updateValueListAccess('checklist_scripts__is_sunday')
              "
            >
              Воскресенье
            </BaseCheckboxV2>
          </div>
          <div class="d-flex gap-10 mt-15">
            <div class="btn btn-sm btn-primary" @click="sendDataFromModal">
              {{ dataLang.form_btn__btn_save }}
            </div>
            <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">
              {{ dataLang.form_btn__btn_caneсl }}
            </div>
          </div>
        </div>
      </Modal>
      <Modal nameForAction="acceptDelete">
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">
            Да
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import JobtitlesService from '@/api/JobtitlesService.js'
import SelectsService from '@/api/SelectsService.js'
export default {
  components: {
    Modal
  },

  props: {},
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      editData: {},
      dataForSelect: {
        dataPeriod: [],
        dataJobTitle: []
      },
      activeSelectRow: null,
      dateTimeSelect: { timeStart: '' },
      loadJobTitiles: false,
      dataJobTitleOptions: [],
      dataPeriodOptions: []
    }
  },
  methods: {
    clickLoadData(numPage) {
      this.getChecklistAll({
        params: {
          limit: 100,
          page: numPage
        }
      })
    },

    updateValueListAccess(nameFiled) {
      this.dataForModal[nameFiled] = !this.dataForModal[nameFiled]
      console.log(this.dataForModal)
    },
    openModalForCreate(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create'
      })
    },

    setSimpleSelect(data, key) {
      console.log(data, key)
      switch (key) {
        case 'period':
          this.dataForModal.checklist_scripts__period = data.value
          console.log(this.dataForModal)
          break

        case 'jobtitle':
          this.dataForModal.checklist_scripts__jobtitle_id =
            data.employee_jobtitles__id
          console.log(this.dataForModal)
          break
      }
    },

    deleteActionAndModal() {
      if (this.activeSelectRow) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDelete',
          mode: 'create'
        })
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    deleteRow(nameModal) {
      this.deleteChecklistData(this.activeSelectRow)
      this.activeSelectRow = null
      EventBus.$emit('closeModal', { nameForAction: nameModal })
    },

    sendDataFromModal() {
      console.log(this.dataForModal)
      if (this.dataForModal.checklist_scripts__id) {
        if (this.dataForModal.checklist_scripts__time) {
          this.dataForModal.checklist_scripts__time =
            this.dateTimeSelect.timeStart
        }

        this.editChecklistMultiple({
          id: this.dataForModal.checklist_scripts__id,
          dataEdit: this.dataForModal
        })
        EventBus.$emit('closeModal', { nameForAction: 'checklistModal' })
      } else {
        this.createChecklistData(
          Object.assign(this.dataForModal, {
            checklist_scripts__time: this.dateTimeSelect.timeStart
          })
        )
        // this.$refs[checklistModal].$el.querySelectorAll('input').forEach(elem => {
        //     elem.value = ''
        // })

        EventBus.$emit('closeModal', { nameForAction: 'checklistModal' })
      }
    },

    updateValue(data) {
      this.dataForModal[data.name] = data.value
      console.log(this.dataForModal)
    },

    prepareKeysForModal(payloadData) {
      this.updatedValues = {}
      this.dataForModal = {}
      if (!payloadData) {
        for (const key in this.checklistData[0]) {
          this.dataForModal[key] = null
        }
      } else {
        this.dataForModal = Object.assign({}, payloadData)
        this.dataForSelect.dataPeriod = this.dataPeriodOptions.find(
          (x) => payloadData.checklist_scripts__period == x.label
        )
        this.dataForSelect.dataJobTitle = this.dataJobTitleOptions.find(
          (x) =>
            payloadData.checklist_scripts__jobtitle_id ==
            x.employee_jobtitles__id
        )
        this.dateTimeSelect.timeStart = payloadData.checklist_scripts__time
        //     dataForSelect: {
        //     dataPeriod: [],
        //     dataJobTitle: []
        // },
        // activeSelectRow: null,
        // dateTimeSelect: { timeStart: '' },
      }
    },

    closeModal(data) {
      this.activeSelectRow = null
      this.dataForModal = {}
      for (const key in this.dataForModal) {
        this.dataForModal[key] = ''
      }
      ;(this.dataForSelect = {
        dataPeriod: [],
        dataJobTitle: []
      }),
        (this.activeSelectRow = null),
        (this.dateTimeSelect = { timeStart: '' })
    },
    btnHandlerCloseModal(nameModal) {
      EventBus.$emit('closeModal', { nameForAction: 'checklistModal' })
    },

    ...mapActions('checklistAdminModule', [
      'getChecklistAll',
      'createChecklistData',
      'editChecklistMultiple',
      'deleteChecklistData'
    ])
  },
  mounted() {
    EventBus.$on('openModal', (payload) => {
      this.editData = payload.data
      console.log(payload.data)
      this.prepareKeysForModal(payload.data)
    })
    EventBus.$on('selectTableRows', (payload) => {
      this.activeSelectRow = payload.data.checklist_scripts__id
    })
    EventBus.$on('deselectTableRows', (payload) => {
      this.activeSelectRow = null
    })
  },

  created() {
    this.getChecklistAll({ params: { page: 1, limit: 100 } })

    JobtitlesService.getAll()
      .then((data) => {
        this.dataJobTitleOptions = data.data.data.rows
        this.loadJobTitiles = true
      })
      .catch((err) => {
        console.log(err)
      })
    SelectsService.getOneByField('checklist_period').then((data) => {
      this.dataPeriodOptions = data.data.data.rows
      console.log(data)
    })
  },
  computed: {
    ...mapState('checklistAdminModule', [
      'checklistData',
      'checklistLoadStatus',
      'paginationChecklistStateParams'
    ]),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('checklistAdminModule', ['numPages']),

    // page: {
    //     get() {
    //         return this.paginationStateParams.page
    //     },
    //     set(page) {
    //         console.log(page);
    //         this.getAll({page: page , limit: 10})
    //     }
    // },

    fieldsTableChecklist() {
      return [
        { title: 'ID', field: 'checklist_scripts__id', headerFilter: 'input' },

        {
          title: 'Наименование',
          field: 'checklist_scripts__name',
          headerFilter: 'input'
        },
        {
          title: 'Описание',
          field: 'checklist_scripts__description',
          headerFilter: 'input'
        },
        {
          title: 'Период',
          field: 'checklist_scripts__period',
          headerFilter: 'input',
          formatter: (cell) => {
            let index = this.dataPeriodOptions.findIndex(
              (item, index) =>
                item.label == cell.getData().checklist_scripts__period
            )
            console.log(
              this.dataPeriodOptions[index],
              cell.getData().checklist_scripts__period
            )
            // this.dataJobTitleOptions[index].employee_jobtitles__name
            return this.dataPeriodOptions[index]
              ? this.dataPeriodOptions[index].label
              : 'Не определен'
          }
        },
        {
          title: 'Время',
          field: 'checklist_scripts__time',
          headerFilter: 'input'
        }
      ]
    }
  }
}
</script>
