<template>
    <div>
        <BaseTopLine>
            <div class="d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('clientsModal')">Добавить</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div>
            </div>
        </BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="clientsData" :props-columns-setting="fieldsTableClients"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="clientsModal" v-if="clientsLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="clientsModal" @closeModal="closeModal">
                aawdawdwd
            </Modal>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getClientsArchiveAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },
        openModalForCreate(nameModal) {
            EventBus.$emit("openModal", { data: null, nameForAction: nameModal, mode: 'create' })
        },


        deleteActionAndModal() {
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },
        deleteRow(nameModal) {
            this.deleteClientsData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },


        sendDataFromModal() {
            console.log(this.dataForModal);
            if (this.dataForModal.clients__id) {
                console.log(this.updatedValues);
                this.editClientsMultiple({ id: this.dataForModal.clients__id, dataEdit: this.updatedValues })
                EventBus.$emit("closeModal", { nameForAction: 'clientsModal' })
            } else {
                this.createClientsData(this.updatedValues)
                // this.$refs[clientsModal].$el.querySelectorAll('input').forEach(elem => {
                //     elem.value = ''
                // })
                
                EventBus.$emit("closeModal", { nameForAction: 'clientsModal' })
            }
        },


        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },

        prepareKeysForModal(payloadData) {
            this.updatedValues = {}
            this.dataForModal = {}
            if (!payloadData) {
                for (const key in this.ClientsData[0]) {
                    this.dataForModal[key] = null
                }
            } else {
                this.dataForModal = Object.assign({}, payloadData)
            }

        },

        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
            // this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
            //     element.value = ''
            // })
        },
        btnHandlerCloseModal(nameModal) {
            EventBus.$emit("closeModal", { nameForAction: 'clientsModal' })
        },


        ...mapActions("clientsModule", ["getClientsArchiveAll", 'createClientsData', 'editClientsMultiple', 'deleteClientsData', 'setClientsRoute']),


    },
    mounted() {
      
        EventBus.$on("openModal", (payload) => {
            this.editData = payload.data
            console.log(payload.data);
            this.prepareKeysForModal(payload.data)
        });
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.Clients__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getClientsArchiveAll({ params: { 
            page: 1, limit: 10, 
            
        }})
    },
    computed: {
        ...mapState("clientsModule", ["clientsData", "clientsLoadStatus", 'paginationClientsStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("clientsModule", ["numPages"]),


        fieldsTableClients() {
            return [
                { title: 'Л/C', field: "clients__id", headerFilter: "input" },
                { title: 'ФИО', field: "clients__name", headerFilter: "input" },
                { title: 'Дата рождения', field: "clients__date_birth", headerFilter: "input", formatter: (cell) => {
                    let value = cell.getValue();
                    if(!value){
                        return "Не заполнена";
                    }

                    return value;
                }},
                { title: 'Баланс', field: "clients__balance", headerFilter: "input"},
                { title: 'Телефон', field: "clients__phone", headerFilter: "input",
                    formatter: (cell) => {
                        let value = cell.getValue();
                        if(!value){
                            return "Не заполнен";
                        }

                        return value;
                    }
                },
                { title: 'Email', field: "clients__email", headerFilter: "input", formatter: (cell) => {
                    let value = cell.getValue();
                    if(!value){
                        return "Не заполнен";
                    }

                    return value;
                }},
            ]
        }
    },
};
</script>
    
    