import http from "@/http-common.js";

class NoticesService {
  basePathApiService = "/notices";

  getAll(params) {
    return http.get(this.basePathApiService, params);
  }
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(ids, editData) {
    return http.put(this.basePathApiService + `/${ids}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
}
export default new NoticesService();
