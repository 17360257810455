<template>
  <nav
    class="header-nav"
    :class="{ 'header-nav--open': openMenu }"
    v-click-outside="closeSubMenu"
  >
    <!-- <div class="p-5 fs-8 bg-opposite d-flex gap-10 flex-wrap">
            <div>
                <span class="fw-bold">Компания</span>
                <p class="mb-0">{{ aboutUser.company__name }}</p>
            </div>
            <div>
                <span class="fw-bold">Сотрудник</span>
                <p class="mb-0">{{ aboutUser.employee_name }} </p>
            </div>
            <div>
                <span class="fw-bold">Баланс</span>
                <p class="mb-0">{{ aboutUser.company_balance }}</p>
            </div>
            <div>
                <span class="fw-bold">Лицевой счет</span>
                <p class="mb-0">{{ aboutUser.company_lc }}</p>
            </div>
            <div class="d-flex flex-column">
                <span class="fw-bold">Чеклист</span>
                <router-link :to="{path: '/checklist'}" class="mb-0">{{ aboutUser.employee_checklist_count }}</router-link>
            </div>
        </div> -->
    <ul class="header-nav__list mt-10">
      <li
        v-if="menuData"
        v-for="(item, index) in menuData"
        :key="index"
        :menu="setMenu(item)"
        class="header-nav__item"
        :class="{ 'header-nav__item--active': item.active }"
        @click="openSubMenu(index)"
      >
        <div class="header-nav__link-cont">
          <span class="header-nav__name">{{ item.menu__item }}</span>

          <div>
            <svg
              fill="#000000"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 16a1 1 0 0 1-.707-.293L5.636 10.05A1 1 0 0 1 7.05 8.636l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414l-5.657 5.657A1 1 0 0 1 12 16z"
              />
            </svg>
          </div>
        </div>
        <ul class="header-nav__sub-menu">
          <li
            v-for="(children, idx) in item.children"
            :key="children.menu__id"
            @click="closeMenu"
            :submenu="setMenu(children)"
            v-if="Boolean(children.menu__is_active) && children.menu__href"
          >
            <router-link to="/" :to="{ path: children.menu__href }">
              <!-- <span class="fw-semi-bold" :class="children.menu__css_class ? `${children.menu__css_class} text-white` : ''"><span v-if="children.menu__number">[</span>{{ children.menu__number
                            }}<span v-if="children.menu__number">]</span></span><span
                                :class="children.menu__css_class ? `${children.menu__css_class} text-white` : ''"> {{
                                    children.menu__item }}</span> -->
              <span>{{ children.menu__item }} {{ children.menu__number }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ClickOutside from '@/directives/click-outside.js'
import AboutUser from '@/api/AboutUser.js'
import { get } from 'lodash'
// import simplebar from 'simplebar-vue';
// import 'simplebar/dist/simplebar.min.css';
export default {
  props: ['openMenu'],
  components: {},
  data() {
    return {
      aboutUser: {},
      countNumber: 0,
    }
  },

  methods: {
    ...mapActions('menuModule', ['getAll', 'setMenuByKey']),
    closeMenu() {
      this.$emit('closeMenu')
      this.updateUserInfo()
    },
    openSubMenu(index) {
      if (window.innerWidth < 992) {
        let item = this.menuData[index]
        console.log(item)
        item.active = !item.active
        this.$set(this.menuData, index, item)
      }
    },
    closeSubMenu() {
      this.menuData.forEach((element) => {
        this.$delete(element, 'active')
      })
    },

    setMenu(item) {
      this.setMenuByKey(item)
      return true
    },
    signOut() {
      localStorage.removeItem('user-token')
      localStorage.removeItem('translations')
      this.$router.push('/auth')
    },
    updateUserInfo() {
      AboutUser.getAllAboutUser()
        .then((data) => {
          this.aboutUser = data.data.data
          this.getUserSetting(data.data.data)
        })
        .then((err) => {
          console.log(err)
        })
    },
    // setNumberMenuItem() {
    //     this.countNumber = this.countNumber + 1
    //     return this.countNumber
    // }
    ...mapActions('userSettingModule', ['getUserSetting']),
  },
  mounted() {},
  created() {
    this.getAll()
    this.updateUserInfo()
  },
  computed: {
    ...mapState('menuModule', ['menuData']),
    ...mapState('userSettingModule', ['userSetting']),
    // setNumberMenuItem: {
    //   get() {
    //     return this.countNumber
    //   },
    //   set(index) {
    //     console.log('awdwdawad')
    //     this.countNumber + 1
    //   },
    // },
  },
  directives: {
    ClickOutside,
  },
}
</script>
