import http from "@/http-common.js";

class SummaryService {

  basePathApiService = "/statistics?type=full";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
}
export default new SummaryService();
