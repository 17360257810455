import Vue from 'vue'
export const citiesPagiation = {
  namespaced: true,
  state: () => ({
    paginationCitiesStateParams: {
      count: 0,
      limit: 100,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPagesCities: (state) =>
      Math.ceil(
        state.paginationCitiesStateParams.total /
          state.paginationCitiesStateParams.limit
      )
  },
  mutations: {
    update_pagination(state, { total, count, limit, page }) {
      state.paginationCitiesStateParams = { total, count, limit, page }
    }
  },
  actions: {
    getCitiesAll({ commit }, pagination) {
      commit('update_pagination', pagination)
    }
  }
}
