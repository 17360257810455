<template>
  <BaseModal
    name="addNoteModal"
    width="420"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="beforeClose"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div class="py-20 px-30 rounded-25 bg-white">
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="$modal.hide('addNoteModal')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p class="fw-bold mb-30 text-center">
          {{ dataLang['vehicle_modal_notes_title'] }}
        </p>
        <div class="wp-100 mb-15" ref="vehicle_notes__note">
          <BaseInputNew
            placeholder=""
            :width="''"
            class="mb-10 wp-100"
            signClass="fsc-14"
            input-name="vehicle_notes__note"
            @input="updateValue"
            :horizontalTitle="true"
            :value="dataForEmit.vehicle_notes__note"
            maxLength="65"
          >
          </BaseInputNew>
          <span class="text-muted fw-light fsc-14">{{
            dataLang['vehicle_modal_notes_warning']
          }}</span>
        </div>
        <div class="d-flex justify-content-end gap-15">
          <div
            class="base-button px-20 rounded-10"
            @click="$modal.hide('addNoteModal')"
          >
            {{ dataLang['vehile_modal_btn_reset'] }}
          </div>
          <div
            @click="sendData"
            class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            {{ dataLang['vehile_modal_btn_save'] }}
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import EventBus from '@/event-bus.js'
// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  props: {
    stateLoading: {
      type: Boolean,
      default: false,
    },
    optionalParams: {
      type: Object,
    },
  },
  data() {
    return {
      dataForEmit: {
        vehicle_notes__note: null,
      },
    }
  },
  methods: {
    beforeClose(event) {
      this.dataForEmit.vehicle_notes__note = null
      Object.keys(this.dataForEmit).forEach((elem, index) => {
        if (this.$refs[elem]) {
          this.$refs[elem].classList.remove('input-error')
        }
      })
    },
    updateValue(data) {
      this.dataForEmit.vehicle_notes__note = data.value
      this.$refs[data.name].classList.remove('input-error')
      console.log(this.dataForEmit);
    },

    sendData() {
      ObjectService.createNote(this.optionalParams.id, this.dataForEmit)
        .then((data) => {
          console.log(data)
          this.$toast.success('Запись добавлена')
          Object.keys(this.dataForEmit).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.remove('input-error')
            }
          })
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.$modal.hide('addNoteModal')
          this.dataForEmit.vehicle_notes__note = null
        })
        .catch((err) => {
          console.log(err)
          Object.keys(err.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
          this.$toast.error(err.response.data.message)
        })
    },
  },
  mounted() {},
  beforeDestroy() {},

  computed: {
    ...mapState('translationModule', ['dataLang']),
  },
}
</script>
