import Vue from 'vue'
export const integrationsYandexrentModule = {
  namespaced: true,
  state: () => ({
    paginationIntegrationsYandexrentStateParams: {
      count: 0,
      limit: 100,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: (state) =>
      Math.ceil(
        state.paginationIntegrationsYandexrentStateParams.total /
          state.paginationIntegrationsYandexrentStateParams.limit
      )
  },
  mutations: {
    update_pagination(state, { total, count, limit, page }) {
      state.paginationIntegrationsYandexrentStateParams = { total, count, limit, page }
    }
  },
  actions: {
    getIntegrationsYandexrentAll({ commit }, pagination) {
      commit('update_pagination', pagination)
    }
  }
}
