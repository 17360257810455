<template>
  <div class="position-relative">
    <BaseLoader v-if="localLoading"></BaseLoader>
    <BaseTopLine> </BaseTopLine>
    <vue-tabs>
      <v-tab :title="dataLang['car']"> </v-tab>
      <v-tab :title="dataLang['commercial']"> </v-tab>
      <v-tab :title="dataLang['bike']"> </v-tab>
      <v-tab :title="dataLang['track']"> </v-tab>
      <v-tab :title="dataLang['trailer']"> </v-tab>
      <v-tab :title="dataLang['spec']"></v-tab>
      <v-tab :title="dataLang['air']"></v-tab>
      <v-tab :title="dataLang['water']"></v-tab>
    </vue-tabs>
    <div class="position-relative h-100 container-fluid">
      <!-- <div
          v-if="localLoading"
          class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
          style="top: 0; left: 0; z-index: 500"
        >
          <svg class="spinner" viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div> -->
      <div class="d-flex justify-content-between align-items-center mt-5 mb-5">
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <div
          class="btn btn-primary btn-sm fsc-14"
          @click="$modal.show('TariffModalCreate')"
        >
          {{ dataLang['tariff_register_text_97'] }}
        </div>
        <div class="px-10 border">
          <div class="d-flex align-items-center gap-10">
            <span class="fsc-14 fw-bold">{{
              dataLang['tariff_register_text_88']
            }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="15"
              height="15"
              fill="currentColor"
              ref="tooltip"
              data-bs-toggle="tooltip"
              style="color: #0056af"
              :title="dataLang['tariff_register_text_91']"
              class="p-0"
            >
              <path
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
          </div>
          <div class="d-flex align-items-center justify-content-center gap-10">
            <span class="fsc-11">
              {{ dataLang['tariff_register_text_89'] }}
            </span>
            <label class="form-switch ps-0">
              <input
                type="checkbox"
                :checked="comissionState"
                @input="(e) => changeComission(!!e.target.checked)"
              />
              <i></i>
            </label>
            <span class="fsc-11">
              {{ dataLang['tariff_register_text_90'] }}
            </span>
          </div>
        </div>
      </div>
      <div class="overflow-auto">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="orders__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          :checkbox-option="{
            hideSelectAll: false
          }"
          v-if="!localLoading"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>

    <EditsTariffModal />
    <TariffModalCreate />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'
import SmsPresetsModal from './orders-modal/SmsPresetsModal.vue'
import EventBus from '@/event-bus.js'
import SendSomeSms from '@/components/reuse_modal/SendSomeSms.vue'
import { bootstrap, Tooltip } from 'bootstrap'
import EditsTariffModal from './tarrifs-modal/EditsTariffModal.vue'
import TariffModalCreate from './tarrifs-modal/TariffModalCreate.vue'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
export default {
  components: {
    VeTable,
    EditsTariffModal,
    TariffModalCreate,
    VueTabs,
    VTab
  },

  data() {
    return {
      dataTimeSelect: { orders_from: [], orders_to: [] },
      selectsData: {
        types: [],
        statuses: [],
        ads: [],
        koef: []
      },
      selectsFilter: {
        type: [],
        statuses: [],
        type_ts: [],
        type_body: [],
        ads: [],
        koef: []
      },
      selects: [],
      statistics: {},
      countOrders: '',
      pincode: '',
      messageError: [],
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      activePagePagination: {},
      testedloading: false,
      currentOpenedId: null,
      mainModalOpened: false,
      rowStyleOption: {
        stripe: true
      },
      comissionState: false,
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('EditsTariffModal', {
                id: row.tariff_for_car__id
              })
            }
          }
        }
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (column.colorize) {
            return 'bg-spec-table'
          }
        }
      }
    }
  },
  methods: {
    acceptDelete() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.delete('/orders/' + this.activeSelectRowsMultiple, {
          ...(this.pincode && { pincode: this.pincode })
        })
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            console.log(data)
            this.loadData(1)
            this.$toast.success(data.data.message)
            this.$modal.hide('acceptDeleteWithPin')
          })
          .catch((e) => {
            console.log(e.response.status)
            if (e.response.status == 409) {
              this.$modal.show('acceptDeleteWithPin')
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
            } else if (e.response.status == 400) {
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
            }
          })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    toArchive() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.get(
          '/orders/to/archive/' + this.activeSelectRowsMultiple,
          {}
        )
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            console.log(data)
            this.loadData(1)
            this.$toast.success(data.data.message)
          })
          .catch((e) => {
            console.log(e)
            // console.log(e.response.status)
            // if (e.response.status == 409) {
            //   this.$modal.show('acceptDeleteWithPin')
            // }
          })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },

    loadData() {
      this.localLoading = true
      Promise.all([
        GeneralService.get(
          '/settings?fields=company_settings__tariff_table_comission_custom_or_top_cars'
        )
          .then((data) => {
            this.comissionState =
              data.data.data.rows[0].company_settings__tariff_table_comission_custom_or_top_cars
          })
          .catch((e) => console.log(e)),

        GeneralService.get('/tariffs_groups_car')
          .then((data) => {
            console.log(data)
            this.selectsData.koef = data.data.rows
          })
          .catch((e) => console.log(e))
      ]).then(() => {
        GeneralService.get('/tariffs_car/table/main?joins=object,group', {
          params: {
            ...(this.filter.length && { where: JSON.stringify(this.filter) })
          }
        })
          .then((data) => {
            this.dataTable = data.data.rows
            this.localLoading = false
          })
          .catch((e) => console.log(e))
      })
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData(1)
      console.log(this.selectsData, this.$refs.serviceTable)
      this.selectsFilter = {
        type: [],
        statuses: []
      }
      this.dataTimeSelect = {
        orders_from: [],
        orders_to: []
      }
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.mod == 'phone' && { mod: 'phone' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
      this.messageError = ''
    },
    changeComission(data) {
      GeneralService.edit('/tariffs_car/setcomissiontype', {
        company_settings__tariff_table_comission_custom_or_top_cars: data
      })
        .then((data) => {
          this.loadData()
        })
        .catch((e) => console.log(e))
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('orderPagiation', ['getOrdersAll'])
  },
  mounted() {
    EventBus.$on('updateContentDataTariff', () => {
      this.loadData()
    })
    let aaa = new Tooltip(this.$refs.tooltip, {})
    // this.$modal.show('EditsTariffModal', {
    //   id: 1
    // })
    // this.$modal.show('TariffModalCreate')
  },
  created() {
    Promise.all([
      // /orders/infopanel
      // GeneralService.get(
      //   `/tariffs_cars`
      // )
      //   .then((data) => {
      //     console.log(data, 'get ads')
      //     // this.selects.push(data.data.data.rows)
      //     this.selectsData.ads = data.data.data.rows
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //   }),
    ]).then(() => {
      this.loadData(1)
    })
    // SSEEventSource.addEventListener('newOrder', (event) => {
    //   this.loadData(1)
    //   this.$toast.success('У вас новый заказ')

    //   if (
    //     this.settingInfoPanel
    //       .employee_profiles__is_active_order_notification_sound ||
    //     this.updatedValues.employee_profiles__is_active_order_notification_sound
    //   ) {
    //     this.soundClick()
    //   }
    // })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('orderPagiation', ['paginationOrdersStateParams']),
    ...mapGetters('orderPagiation', ['numPages']),
    columns() {
      return [
        {
          title: this.dataLang['tariff_register_text_4'],
          children: [
            {
              field: 'vehicles__id',
              key: '12',
              title: this.dataLang['tariff_register_text_12'],
              align: 'center',
              width: '4%',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div class={'h-100'}>
                    <div style={'height: 50px'}></div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'min-width: 60px'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'vehicles__id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },

            {
              field: 'vehicles__name',
              key: '13',
              title: this.dataLang['tariff_register_text_13'],
              align: 'center',
              width: '5%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div style={'height: 50px'}></div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'min-width: 60px'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'vehicles__name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'vehicles__year_release',
              key: '14',
              title: this.dataLang['tariff_register_text_14'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div style={'height: 30px'}></div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            },
            {
              field: 'vehicles__gosnumber',
              key: '15',
              title: this.dataLang['tariff_register_text_15'],
              align: 'center',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div style={'height: 50px'}></div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'min-width: 60px'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'vehicles__gosnumber'}
                      value={''}
                      nameSign={''}
                      inputRef={'vehicles__gosnumber'}
                      inputMask={'a000aa000'}
                      maxLength={'9'}
                    />
                  </div>
                )
              }
            },
            {
              field: 'tariff_for_car__pledge',
              key: '153',
              title: this.dataLang['tariff_register_text_5'],
              align: 'center',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div style={'height: 30px'}></div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            },
            {
              field: 'tariff_groups_for_car__name',
              key: '16',
              title: this.dataLang['tariff_register_text_16'],
              align: 'center',
              width: '8%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div style={'height: 50px'}></div>
                    <div class={'text-center'}>{column.title}</div>
                    <BaseSelect
                      vModel={this.selectsFilter.koef}
                      onInput={(e, w) => {
                        console.log(e)
                        if (
                          !this.filter.find(
                            (x) => x.field == 'tariff_groups_for_car__id'
                          )
                        ) {
                          this.filter.push({
                            field: 'tariff_groups_for_car__id',
                            value: e.tariff_groups_for_car__id
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'tariff_groups_for_car__id'
                            )
                          ].value = e.tariff_groups_for_car__id
                        }
                      }}
                      style={'min-width: 60px'}
                      label={'tariff_groups_for_car__name'}
                      placeholder={''}
                      id={'tariff_groups_for_car__name'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData.koef}
                    ></BaseSelect>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_6'],
          children: [
            {
              title: this.dataLang['tariff_register_text_47'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t1',
                      key: 'tariff_for_car__t1',
                      title: this.dataLang['tariff_register_text_65'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_48'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t2',
                      key: 'tariff_for_car__t2',
                      title: this.dataLang['tariff_register_text_66'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_49'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t3',
                      key: 'tariff_for_car__t3',
                      title: this.dataLang['tariff_register_text_67'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_50'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t4',
                      key: '12234',
                      title: this.dataLang['tariff_register_text_68'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_51'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t5',
                      key: '11232',
                      title: this.dataLang['tariff_register_text_69'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_52'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t6',
                      key: '34312',
                      title: this.dataLang['tariff_register_text_70'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_53'],
              children: [
                {
                  title: '225 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t7',
                      key: '15432',
                      title: this.dataLang['tariff_register_text_71'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_54'],
              children: [
                {
                  title: '225 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t8',
                      key: '1234',
                      title: this.dataLang['tariff_register_text_72'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_55'],
              children: [
                {
                  title: '125 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_for_car__t9',
                      key: '1245',
                      title: this.dataLang['tariff_register_text_73'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_7'],
          children: [
            {
              title: this.dataLang['tariff_register_text_56'],
              children: [
                {
                  field: 'tariff_for_car__km_up',
                  key: '1762',
                  title: this.dataLang['tariff_register_text_74'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_8'],
          children: [
            {
              title: this.dataLang['tariff_register_text_57'],
              children: [
                {
                  field: 'tariff_for_car__fr',
                  key: '1552',
                  title: this.dataLang['tariff_register_text_75'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_58'],
              children: [
                {
                  field: 'tariff_for_car__day_fr',
                  key: '12325',
                  title: this.dataLang['tariff_register_text_76'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_44'],
          children: [
            {
              title: this.dataLang['tariff_register_text_27'],
              children: [
                {
                  field: 'tariff_for_car__sub_1',
                  key: '1242',
                  title: this.dataLang['tariff_register_text_77'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_28'],
              children: [
                {
                  field: 'tariff_for_car__sub_3',
                  key: '1212',
                  title: this.dataLang['tariff_register_text_78'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_29'],
              children: [
                {
                  field: 'tariff_for_car__sub_6',
                  key: '12332',
                  title: this.dataLang['tariff_register_text_79'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_30'],
              children: [
                {
                  field: 'tariff_for_car__sub_9',
                  key: '12172',
                  title: this.dataLang['tariff_register_text_80'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_31'],
              children: [
                {
                  field: 'tariff_for_car__sub_12',
                  key: '12334',
                  title: this.dataLang['tariff_register_text_81'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_45'],
          children: [
            {
              title: this.dataLang['tariff_register_text_31'],
              children: [
                {
                  field: 'tariff_for_car__buy_12',
                  key: '12423',
                  title: this.dataLang['tariff_register_text_82'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_32'],
              children: [
                {
                  field: 'tariff_for_car__buy_24',
                  key: '12122',
                  title: this.dataLang['tariff_register_text_83'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_113'],
              children: [
                {
                  field: 'tariff_for_car__buy_36',
                  key: '123332',
                  title: this.dataLang['tariff_register_text_84'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_11'],
          children: [
            {
              title: this.dataLang['tariff_register_text_33'],
              children: [
                {
                  field: 'tariff_for_car__drive',
                  key: '1245233',
                  title: this.dataLang['tariff_register_text_85'],
                  align: 'center',
                  width: '3%',
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_88'],
          colorize: true,
          children: [
            {
              field: 'tariff_for_car__commission_nodriver_day',
              key: '1242133',
              title: this.dataLang['tariff_register_text_43'],
              align: 'center',
              width: '3%',
              colorize: true,
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'min-width: 60px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    {row.tariff_for_car__commission_nodriver_day} /{' '}
                    <span style={'color: #23b14d'}>
                      {row.tariff_for_car__commission_nodriver_day_top}%
                    </span>
                  </div>
                )
              }
            },
            {
              field: 'tariff_for_car__commission_nodriver_subs',
              key: '1242333',
              title: this.dataLang['tariff_register_text_44'],
              align: 'center',
              width: '3%',
              colorize: true,
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'min-width: 60px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    {row.tariff_for_car__commission_nodriver_subs} /{' '}
                    <span style={'color: #23b14d'}>
                      {row.tariff_for_car__commission_nodriver_subs_top}%
                    </span>
                  </div>
                )
              }
            },

            {
              field: 'tariff_for_car__commission_nodriver_buyout',
              key: '1242533',
              title: this.dataLang['tariff_register_text_45'],
              align: 'center',
              width: '3%',
              colorize: true,
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'min-width: 60px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    {row.tariff_for_car__commission_nodriver_buyout} /{' '}
                    <span style={'color: #23b14d'}>
                      {row.tariff_for_car__commission_nodriver_buyout_top}%
                    </span>
                  </div>
                )
              }
            },
            {
              field: 'tariff_for_car__commission_driver',
              key: '1242303',
              title: this.dataLang['tariff_register_text_11'],
              align: 'center',
              width: '3%',
              colorize: true,
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'min-width: 60px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    {row.tariff_for_car__commission_driver} /{' '}
                    <span style={'color: #23b14d'}>
                      {row.tariff_for_car__commission_driver_top}%
                    </span>
                  </div>
                )
              }
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding-left: 0;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #f5f5f5;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #f5f5f5;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #23b14d;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}
</style>
