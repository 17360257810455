
import SecureSettingsService from "@/api/SecureSettingsService.js";
import Vue from "vue";
export const secureClientsFieldsModule = {
  namespaced: true,
  state: () => ({
    secureClientsFieldsData: [],
    secureClientsFieldsLoadStatus: 'request',
    paginationsecureClientsFieldsStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationsecureClientsFieldsStateParams.total / state.paginationsecureClientsFieldsStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      console.log(data)
      state.secureClientsFieldsData = [...data];
    },

    create_client_field(state, data){
      data.forEach(item => { 
        state.secureClientsFieldsData.unshift(item)
      })
    },

    delete_client_field_data(state, id){
      let indexed = state.secureClientsFieldsData.findIndex(x => x.clients_fields__id == id)
      state.secureClientsFieldsData.splice(indexed, 1)
    },

    load_request(state, status) {
      state.secureClientsFieldsLoadStatus = 'request'
    },
    load_success(state) {
      state.secureClientsFieldsLoadStatus = 'success'
    },
    load_error(state) {
      state.secureClientsFieldsLoadStatus = 'error'
    },
  },
  actions: {
    getSecureСlientsFieldsAll({ commit }, params) {
      commit("load_request");
      SecureSettingsService.getClientsFields(params)
        .then((data) => {
         // commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createSecureСlientField({commit}, dataCreate){
      SecureSettingsService.createClientField(dataCreate.id, dataCreate.data)
        .then((data) => {
          commit("create_client_field", data.data.data.rows);
          Vue.$toast.success(data.data.message)
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    deleteClientsFieldsData({ commit }, dataOnDelete) {
      // commit("load_request");
      SecureSettingsService.deleteQuestion(dataOnDelete.idGroup, dataOnDelete.idField)
        .then((data) => {
          console.log(data)
          commit("delete_client_field_data", dataOnDelete.idField);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },
  }
};
