
import FinreestrService from "@/api/FinreestrService.js";
import FundsService from "@/api/FundsService";
import Vue from "vue";
import { fundsModule } from '@/store/modules/funds-module.js'

export const finreestrModule = {
  namespaced: true,
  state: () => ({
    finreestrData: [],
    finreestrLoadStatus: 'request',
    
    paginationFinreestrStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationFinreestrStateParams.total / state.paginationFinreestrStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.finreestrData = [...data];
    },

    clear_updated_funds(state, data){
      state.updateFinreestrFunds = [];
    },

    create(state, data) {
      data.forEach(item => { 
        state.finreestrData.unshift(item)
      })
    },

    edit_multiple(state, data) {
      let indexed = state.finreestrData.findIndex(x => x.finreestr__id == data.id)
      Object.assign(state.finreestrData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.finreestrData.findIndex(x => x.finreestr__id == id)
      state.finreestrData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationFinreestrStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.finreestrLoadStatus= 'request'
    },
    load_success(state) {
      state.finreestrLoadStatus= 'success'
    },
    load_error(state) {
      state.finreestrLoadStatus= 'error'
    },
  },
  actions: {
   

    getFinreestrAll({ commit }, params) {
      commit("load_request");
      FinreestrService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createFinreestrData({ commit }, createData) { 
        FinreestrService.create(createData)
        .then((data) => {
          let rows = data.data.data.rows
          commit("create", data.data.data.rows);
          rows.forEach(item => {
            this.state.changeFund
            commit("fundsModule/edit_multiple", {id:item.fund.finreestr_funds__id, dataEdit:item.fund}, { root: true });
          }); 
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editFinreestrMultiple({ commit }, {id, dataEdit}) {
        FinreestrService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteFinreestrData({ commit }, id) {
      // commit("load_request");
      FinreestrService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

    clearFinreestrUpdatedFunds({ commit }){
      commit("clear_updated_funds")
    }
  },
};
