<template>
  <div class="px-30 py-10" style="height: 610px" ref="filesTab">
    <div class="row gx-15" v-if="!loadingState">
      <div class="col-8">
        <div class="system-block">
          <div class="mb-15">
            <p class="fw-bolder fsc-12 mb-15 text-dark">
              {{ dataLang['vehicle_modal_files_text1'] }}
              {{ dataLang['vehicle_modal_files_text2'] }}
            </p>
            <div class="row gx-25">
              <div
                class="col-2"
                v-for="(docFile, index) in docsFiles"
                :key="docFile.id"
              >
                <UploadFileWithPreview
                  :file-preview="docFile.value"
                  accept="image/png,image/jpg,image/jpeg,.pdf"
                  :max-weight="10000"
                  :max-x-size="1000"
                  :max-y-size="750"
                  :additionalParams="{ ...docFile }"
                  type-of-form-data="vehicleDoc"
                  style="height: 109px"
                  @clickOnImage="clickOnImage(docFile.value)"
                  :error-message-max-weight="
                    dataLang['error_message_max_file_size']
                  "
                  classes="file-load--doc"
                  @load-file="(params) => loadfileDoc(params)"
                  @remove-file="(params) => removefileDoc(params)"
                />
                <p class="text-muted fsc-11 mb-0 text-center">
                  {{ signsForDocs[index] }}
                </p>
              </div>
            </div>
          </div>
          <div class="mb-15">
            <p class="fw-bolder fsc-12 mb-0 text-dark">
              {{ dataLang['vehicle_modal_files_text3'] }}
            </p>
            <p class="fw-normal fsc-12 mb-10">
              {{ dataLang['vehicle_modal_files_text4'] }}
            </p>
            <div class="row gx-10 gy-15">
              <div
                style="flex: 135px 1 1"
                class=""
                v-for="(galleryFile, index) of galleryFiles"
                :key="galleryFile.position"
              >
                <UploadFileWithPreview
                  :file-preview="galleryFile.image?.vehicle_gallery__file"
                  accept="image/jpg,image/jpeg"
                  :max-weight="100"
                  :max-x-size="1000"
                  :max-y-size="750"
                  @clickOnImage="
                    clickOnImage(galleryFile.image?.vehicle_gallery__file)
                  "
                  :additionalParams="{ ...galleryFile }"
                  :error-message-max-weight="
                    dataLang['error_message_max_file_size']
                  "
                  style="height: 109px"
                  :indexForPrint="galleryFile.position"
                  :preview="galleryFile.icon"
                  classes="file-load--gallery"
                  @load-file="(params) => loadfile(params)"
                  @remove-file="(params) => removefile(params)"
                />
                <p class="text-muted fsc-11 mb-0 text-center">
                  {{ galleryFile.placeholder }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="fsc-14 fw-medium text-dark rounded-5"
            style="height: 55px"
            :class="calcClassForExeption"
          >
            <div
              v-if="
                !dataForModal.vehicles__moderation_status_id ||
                dataForModal.vehicles__moderation_status_id == 1
              "
              @click="changeStatusVehicle"
              class="cursor-pointer text-center d-flex justify-content-center align-items-center h-100"
            >
              <span v-html="dataLang['vehicle_modal_settings_text34']"></span>
            </div>

            <div
              class="cursor-pointer text-center d-flex justify-content-center align-items-center h-100"
              v-if="statusVehicle.vehicles__moderation_status_id == 2"
              v-html="dataLang['vehicle_modal_settings_text36']"
            ></div>
            <div
              class="cursor-pointer text-center d-flex justify-content-center align-items-center h-100 fsc-11 px-10"
              v-if="statusVehicle.vehicles__moderation_status_id == 3"
              v-html="dataLang['vehicle_modal_settings_text37']"
            ></div>
          </div>
          <!-- <div class="mb-15">
            <p class="fw-bolder fsc-12 mb-10 text-dark">
              {{ dataLang['vehicle_modal_files_text16'] }}
            </p>
            <div class="d-flex justify-content-between">
              <BaseInputNew
                placeholder=""
                :width="'316'"
                input-name="vehicles__docs_youtube_link"
                @input="
                  (data) => {
                    updatedValues[data.name] = data.value
                  }
                "
                :horizontalTitle="true"
                :value="ytLink"
                :name-sign="dataLang['vehicle_modal_files_text17']"
              >
              </BaseInputNew>

              <div
                class="py-10 px-20 rounded-10 bg-primary fsc-14 text-dark cursor-pointer"
                @click="$modal.show('orderVideo')"
              >
                {{ dataLang['vehicle_modal_files_text18'] }}
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-4">
        <div class="system-block d-flex flex-column h-100">
          <p class="fw-bolder fsc-12 mb-10 text-dark text-center">
            {{ dataLang['vehicle_modal_files_text19'] }}
          </p>
          <div class="base-button base-button--action flex-grow-1 fsc-14 mb-10">
            <span>{{ dataLang['vehicle_modal_files_text20'] }}</span>
          </div>
          <p class="fw-bolder fsc-12 mb-10 text-dark text-center">
            {{ dataLang['vehicle_modal_files_text22'] }}
          </p>
          <div
            class="py-10 px-20 rounded-10 bg-primary fsc-14 text-dark cursor-pointer flex-grow-1 mb-15 d-flex flex-column justify-content-center align-items-center"
            @click="$modal.show('orderPhoto')"
          >
            <span class="fsc-14 lh-base">{{
              dataLang['vehicle_modal_files_text23']
            }}</span>
            <span class="fsc-12 lh-base">{{
              dataLang['vehicle_modal_files_text24']
            }}</span>
          </div>
          <p class="fw-bolder fsc-12 text-dark mb-0 text-center">
            {{ dataLang['vehicle_modal_files_text25'] }}
          </p>
        </div>
      </div>
    </div>
    <AddNewPhotoBid :optionalParams="{ id: currentOpenedId }"></AddNewPhotoBid>
    <AddNewVideoBid :optionalParams="{ id: currentOpenedId }"></AddNewVideoBid>
    <gallery
      v-if="loadGallery && !loadingState"
      :images="images"
      :index="index"
      @close="index = null"
    ></gallery>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ObjectsService from '@/api/ObjectsService'
import UploadFileWithPreview from '@/components/UploadFileWithPreview.vue'
import StorageFileService from '@/api/StorageFileService'
import AddNewPhotoBid from '@/components/vehicles-page-single-modal-inner/AddNewPhotoBid.vue'
import AddNewVideoBid from '@/components/vehicles-page-single-modal-inner/AddNewVideoBid.vue'
import EventBus from '@/event-bus.js'
import VueGallery from 'vue-gallery'
import TransportVehiclesService from '@/api/TransportVehiclesService'
//   import SingleModal from '@/components/SingleModal.vue'
export default {
  components: {
    UploadFileWithPreview,
    AddNewPhotoBid,
    AddNewVideoBid,
    gallery: VueGallery
  },
  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      loadingState: true,
      docsFiles: [],
      galleryFiles: {},
      docsFilesForSend: {},
      galleryFilesForSend: [],
      updatedValues: {},
      ytLink: '',
      dataForModal: {},
      images: [],
      index: null,
      loadGallery: false,
      localIndexed: 0,
      statusVehicle: ''
    }
  },
  methods: {
    changeStatusVehicle() {
      console.log(12121)
      this.$emit('loadingModal', true)
      TransportVehiclesService.update(this.currentOpenedId, {
        vehicles__moderation_status_id: 2
      })
        .then((data) => {
          this.$toast.success(data.data.message)
          this.dataForModal.vehicles__moderation_status_id = 2
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.$emit('loadingModal', false)
        })
        .catch((err) => {
          console.log(err)
          this.$emit('loadingModal', false)
          this.$toast.error(err.response.data.message)
        })
    },
    clickOnImage(index) {
      console.log(index)

      this.index = this.images.findIndex((x) => x == index)
    },
    loadfileDoc(params) {
      this.$emit('loadingModal', true)
      this.loadingState = true
      console.log(params)
      StorageFileService.upload(params.fileInFormData)
        .then((data) => {
          console.log(data.data.data.url)
          this.$toast.success(data.data.message)
          this.docsFilesForSend[params.key] = data.data.data.url
          let indexDocs = this.docsFiles.findIndex((x) => x.key == params.key)
          console.log(indexDocs)
          this.docsFiles[indexDocs].value = data.data.data.url
          // this.images.splice(params.index, 0, data.data.data.url);
          ObjectsService.update(this.currentOpenedId, {
            ...this.docsFilesForSend
          })
            .then((data) => {
              this.images.splice(params.index, 0, data.data.data.url)
              console.log(this.docsFiles)
              this.$toast.success(data.data.message)
              this.$emit('loadingModal', false)
              this.loadingState = false
              this.forceReload()
            })
            .catch((error) => {
              this.$toast.error(error.response.data.message)
            })
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
          this.$emit('loadingModal', false)
        })
    },
    removefileDoc(params) {
      console.log(params)
      this.$emit('loadingModal', true)
      this.docsFilesForSend[params.key] = null
      this.loadingState = true
      ObjectsService.delete('docs', this.currentOpenedId, params.key)
        .then((data) => {
          delete this.images[this.images.findIndex((x) => x == params.value)]
          this.$toast.success(data.data.message)
          this.$emit('loadingModal', false)
          this.loadingState = false
          this.forceReload()
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
        })

      //     delete(url, parentId, ids) {
      //   console.log(url, parentId, ids);
      //   return http.delete(this.basePathApiService + `/${parentId}/${url}/${ids}`)
      // }
    },
    loadfile(params) {
      console.log(params)
      this.$emit('loadingModal', true)
      this.loadingState = true
      StorageFileService.upload(params.fileInFormData)
        .then((data) => {
          console.log(params.position, Object.keys(this.galleryFiles))
          // this.galleryFiles[params.position].image = {
          //   vehicle_gallery__file: data.data.data.url,
          // }
          this.images.push(data.data.data.url)
          console.log(this.images)
          Promise.all([
            ObjectsService.update(this.currentOpenedId, {
              gallery: [
                {
                  vehicle_gallery__file: data.data.data.url,
                  vehicle_gallery__position: params.position
                }
              ]
            })
              .then((data) => {
                this.$toast.success(data.data.message)
                ObjectsService.getAll({
                  url: `/${this.currentOpenedId}/images`
                }).then((data) => {
                  this.galleryFiles = data.data.data.rows
                })
              })
              .catch((error) => {
                this.loadingState = false
                this.$emit('loadingModal', false)
                this.$toast.error(error.response.data.message)
              })
          ]).then(() => {
            this.$emit('loadingModal', false)
            this.loadingState = false
            this.forceReload()
          })
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
          this.$emit('loadingModal', false)
        })
    },
    removefile(params) {
      console.log(params)
      this.$emit('loadingModal', true)
      this.loadingState = true
      ObjectsService.delete(
        'images',
        this.currentOpenedId,
        params.image.vehicle_gallery__id
      )
        .then((data) => {
          delete this.images[
            this.images.findIndex(
              (x) => x == params.image.vehicle_gallery__file
            )
          ]
          this.$toast.success(data.data.message)
          this.$emit('loadingModal', false)
          this.loadingState = false
          this.forceReload()
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
        })
    },

    forceReload() {
      this.$emit('forceReload')
    },
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      ObjectsService.update(this.currentOpenedId, {
        ...(this.updatedValues.vehicles__docs_youtube_link && {
          vehicles__docs_youtube_link:
            this.updatedValues.vehicles__docs_youtube_link
        })
      })
        .then((data) => {
          this.$toast.success(data.data.message)
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          callback(true, nameTab, oldTab, newTab, flagIsClose)
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
        })
    },
    updateValue(data) {
      this.updatedValues[data.name] = data.value
    },
    getAllData() {
      Promise.all([
        ObjectsService.getAll({
          url: `/${this.currentOpenedId}?fields=vehicles__ygsf_city_no_driver,
        vehicles__moderation_status_id,
vehicles__ygsf_city_no_driver_min_days,
vehicles__ygsf_city_with_driver,
vehicles__ygsf_city_with_driver_min_hour,
vehicles__ygsf_city_buyout,
vehicles__ygsf_city_buyout_12,
vehicles__ygsf_city_buyout_24,
vehicles__ygsf_city_buyout_36,
vehicles__ygs_world,
vehicles__ygs_world_buyout,
vehicles__ygs_world_min_days,
vehicles__ygf_world,
vehicles__ygf_world_min_days,
vehicles__ygt_world,
vehicles__local_buyout_12,
vehicles__local_buyout_24,
vehicles__local_buyout_36,
vehicles__local_driver,
vehicles__local_driver_add_hour,
vehicles__local_nodriver_pledge,
vehicles__local_nodriver_min_days,
vehicles__local_driver_km_in_hour,
vehicles__local_driver_min_hours,
vehicles__local_nodriver, vehicles__local_buyout`
        })
          .then((data) => {
            console.log(data)
            this.dataForModal = data.data.data.rows[0]
            this.statusVehicle = this.dataForModal
          })
          .catch((err) => {
            console.log(err)
          }),
        ObjectsService.getAll({
          url: `/${this.currentOpenedId}?fields=vehicles__docs_file_1,vehicles__docs_file_2,vehicles__docs_file_3,vehicles__docs_file_4,vehicles__docs_file_5,vehicles__docs_file_6`
        })
          .then((data) => {
            let fetchedDocFiles = data.data.data.rows[0]
            for (const key in fetchedDocFiles) {
              this.docsFiles.push({
                key: key,
                id: key.split('__').at(-1),
                value: fetchedDocFiles[key],
                index: this.localIndexed
              })
              // console.log(!!fetchedDocFiles[key]);
              // this.images.push(!!fetchedDocFiles[key] ? fetchedDocFiles[key] : '')
              if (
                !!fetchedDocFiles[key] &&
                fetchedDocFiles[key].split('.').at(-1) !== 'pdf'
              ) {
                this.images.push(fetchedDocFiles[key])
              }
            }
            // Object.keys(fetchedDocFiles).forEach((elem, index) => {
            //   if (elem.split('.').at(-1) !== 'pdf') {
            //     this.images.push(elem)
            //     console.log(this.images)
            //   }
            // })
            console.log(this.images)
          })
          .catch((err) => {
            // console.log(err)
          }),

        ObjectsService.getAll({
          url: `/${this.currentOpenedId}/images`
        }).then((data) => {
          this.galleryFiles = data.data.data.rows
          let iteratedFiles = []
          Object.values(data.data.data.rows).forEach((element) => {
            if (element.image.vehicle_gallery__file) {
              this.images.push(element.image.vehicle_gallery__file)
            }
          })
          console.log(this.images)
        }),

        ObjectsService.getAll({
          url: `/${this.currentOpenedId}?fields=vehicles__docs_youtube_link`
        }).then((data) => {
          console.log(data)
          this.ytLink = data.data.data.rows[0].vehicles__docs_youtube_link
        })

        // /objects/{id}?fields=vehicles__docs_youtube_link
      ]).then(() => {
        console.log('This end')
        this.$emit('loadingModal', false)
        this.loadingState = false
        this.loadGallery = true
      })
    },
    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },

  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)

    this.getAllData()
  },
  computed: {
    calcClassForExeption() {
      if (
        !this.dataForModal.vehicles__moderation_status_id ||
        this.dataForModal.vehicles__moderation_status_id == 1
      ) {
        return ['cell-status-danger', 'text-white']
      } else if (this.dataForModal.vehicles__moderation_status_id == 2) {
        return 'cell-status-warning'
      } else if (this.dataForModal.vehicles__moderation_status_id == 3) {
        return ['bg-greensetting', 'text-white']
      }
    },
    signsForDocs() {
      return [
        this.dataLang['vehicle_modal_files_text30'],
        this.dataLang['vehicle_modal_files_text31'],
        this.dataLang['vehicle_modal_files_text32'],
        this.dataLang['vehicle_modal_files_text33'],
        this.dataLang['vehicle_modal_files_text34'],
        this.dataLang['vehicle_modal_files_text35']
      ]
    },
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('transportVehiclesModule', ['numPages'])
  }
}
</script>

<style scoped>
.bg-greensetting {
  background-color: rgba(35, 177, 77, 1);
}
</style>
