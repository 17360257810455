
import VehicleMarksService from "@/api/VehicleMarksService.js";
import StorageFileService from "@/api/StorageFileService";
import Vue from "vue";
export const vehicleMarksModule = {
  namespaced: true,
  state: () => ({
    vehicleMarks: [],
    loadStatus: ''
  }),

  getters: {},
  mutations: {
    create_one(state, data) {
      state.vehicleMarks = data
    },
    get_all(state, data) {
      state.vehicleMarks = data
    },
    edit_one(state, data) {

    },
    delete_one(state, data) {
      //   let indexed = state.vehicleTypeAndCharacteristics.findIndex(x => x.vehicle_examples__id == data)
      //   console.log(22222);
      //   state.vehicleTypeAndCharacteristics.splice(indexed, 1)
    },
    load_request(state, status) {
      state.loadStatus = 'request'
    },
    load_success(state) {
      state.loadStatus = 'success'
    },
    load_error(state) {
      state.loadStatus = 'error'
    },
  },
  actions: {
    createOneMark({ commit, dispatch, rootState }, newData) {
      StorageFileService.upload(newData).then((data) => {
        VehicleMarksService.create({
          "vehicle_marks__name": newData.newName,
          "vehicle_marks__logo": data.data.data
          }
        )
          .then((data) => {
            console.log(data)
            Vue.$toast.success('Данные сохранены');
            // commit("edit_one", data.data.data.rows);
          })
          .catch((error) => {
            console.log(error)
            Vue.$toast.error(error.response.data.message);
            // commit("load_error");
          });
      })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('При сохранении изображения возникла ошибка.');
          commit("load_error");
        });

    },
    editOne({ commit }, payload) {
      VehicleMarksService.update(payload.id, payload.data)
        .then((data) => {
          console.log(data.data.data)
          // commit("edit_one", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error)
          commit("load_error");
        });
    },
    getAllMarks({ commit }, params) {
      commit("load_request");
      VehicleMarksService.getAll(params)
        .then((data) => {
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error)
          commit("load_error");
        });
    },
    deleteOne({ commit }, params) {
      VehicleTypeAndCharacteristicsService.deleteOne(params)
        .then((data) => {
          commit("delete_one", params);
          Vue.$toast.success('Данные удалены')
        })
        .catch((error) => {
          console.log(error)
          commit("load_error");
        });
    },
  },
};
