<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
    };
  },
  methods: {
    //...mapActions("translationModule", ["getTranslation"]),
  },
  mounted() {
    // if (!localStorage.getItem('translations')) {
    //   this.getTranslation(1)
    // }
    /* 
      let handl = await fetch('http://api.yandexrent.development/redis/')
      let datanew = await handl.json()
      console.log(datanew)
 */

    /* let eventSource = new EventSource("http://localhost:3000/"); */

   /*  eventSource.onmessage = (event) => {
      const parseData = JSON.parse(event.data);
      console.log(parseData);
    };
    eventSource.onmessage = (event) => {
      console.log("Connection started");
    };
    eventSource.onerror = function (e) {
      console.log("Событие: error");
      if (this.readyState == EventSource.CONNECTING) {
        console.log(`Переподключение (readyState=${this.readyState})...`);
      } else {
        console.log("Произошла ошибка.");
      }
    }; */
  },
};
</script>


