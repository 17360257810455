<template>
  <div>
    <div class="container">
      <template v-if="statusEdit">
        <div class="mt-40">
          <div class="mb-30 text-center">
            <h1 class="fsc-16 fsc-md-24 mb-10 fw-bold">
              Анкета клиента по аренде транспорта
            </h1>
            <p class="fsc-12 fsc-lg-16">
              Заполните все поля и нажмите кнопку отправить
            </p>
          </div>
          <div class="row">
            <div
              v-for="item in editData"
              v-if="
                item.secur_clients_fields__type_field !== 'secur_field_file'
              "
              class="col-12 col-md-6 mb-5 mt-10"
              :ref="item.secur_clients_fields__name"
            >
              <!-- <template
                v-if="
                  item.secur_clients_fields__type_field == 'secur_field_file'
                "
              > -->
              <!-- <VueFileAgent
                  class="mt-20"
                  :ref="item.secur_clients_fields__name"
                  :theme="'list'"
                  :multiple="false"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*'"
                  :maxSize="'10MB'"
                  @beforedelete="beforeDeleteFile($event, item)"
                  @delete="deleteFile($event, item)"
                  :maxFiles="1"
                  :helpText="item.secur_clients_fields__label"
                  :errorText="{
                    type: 'Only images',
                    size: 'Files should not exceed 10MB in size'
                  }"
                  @select="uploadFile($event, item.secur_clients_fields__name)"
                  v-model="fileList[item.secur_clients_fields__name]"
                ></VueFileAgent> -->
              <!-- </template> -->
              <!-- <template v-else-if="item.secur_clients_fields__type_field == 'secur_field_date'">
                                <BaseDatePicker lang="ru" v-model="test"
                                     alue-type="format"  type="date" :format="item.secur_clients_fields__mask" @change="changeDate($event)" ></BaseDatePicker>

                            </template> -->

              <BaseInput
                :placeholder="item.secur_clients_fields__placeholder"
                title=""
                @onInput="updateValue"
                :maxLength="prepareMountLenght(item.secur_clients_fields__name)"
                :value="
                  prepareValue(
                    item.secur_clients_fields__name,
                    item.secur_clients_answers__answer_text
                  )
                "
                :input-ref="
                  item.secur_clients_fields__name +
                  item.secur_clients_fields__label
                "
                :name-sign="item.secur_clients_fields__label"
                :input-name="item.secur_clients_fields__name"
                :input-mask="item.secur_clients_fields__mask"
              >
              </BaseInput>
            </div>
            <div
              style="
                display: flex;
                gap: 15px;
                flex-wrap: wrap;
                padding-top: 20px;
              "
              class="speccontainer"
            >
              <div
                class="specnewbox newfileupload"
                style="
                  padding-bottom: 8px;
                  flex-shrink: 0;
                  flex-grow: 1;
                  min-width: 200px;
                "
                v-for="(item, index) in galleryList"
                :ref="item.secur_clients_fields__name"
              >
                <UploadFileWithPreview
                  style="height: 150px"
                  :key="item.secur_clients_answers__id"
                  accept="image/png,image/jpg,image/jpeg"
                  :max-weight="10000"
                  :max-x-size="1000"
                  :max-y-size="750"
                  classForSingLoad="fsc-16"
                  preview="image.svg"
                  :additionalParams="{
                    keyData: item.secur_clients_answers__id,
                    name: item.secur_clients_fields__name
                  }"
                  type-of-form-data="file"
                  :error-message-max-weight="
                    dataLang['error_message_max_file_size']
                  "
                  classes="file-load--gallery ws-nowrap"
                  @load-file="(params) => uploadFile(params)"
                  @remove-file="(params) => deleteFile(params)"
                  :file-preview="item.secur_clients_answers__answer_text"
                >
                </UploadFileWithPreview>
                <div>{{ item.secur_clients_fields__label }}</div>
              </div>
            </div>
          </div>
          <div class="mt-20 mb-20">
            <div class="p-10 wp-100 btn btn-sm btn-primary" @click="sendAnketa">
              Отправить
            </div>
          </div>
        </div>
      </template>
      <template v-if="statusFormFilling">
        <div class="align-items-center d-flex h1 mt-1" style="height: 80vh">
          Анкета отправлена на проверку в СБ. <br />
          Результат будет в течение 30 минут. <br />
          Ожидайте.
        </div>
      </template>
    </div>
    <Teleport to="body">
      <Modal nameForAction="FormTypeFaceClients">
        <div class="p-10">
          <div>
            <h3>Кто вы?</h3>
            <span>Выберите тип лица</span>
            <BaseSelect
              :options="selectFaceOptions"
              placeholder=""
              label="label"
              v-model="selectedFaceOption"
              @select="setFace"
            >
              <div slot="no-options">Пока нет опций</div>
            </BaseSelect>
          </div>
        </div>
      </Modal>
      <Modal nameForAction="FormSuccessSecure">
        <div class="p-10">
          <div>
            Анкета отправлена на проверку в СБ. <br />
            Результат будет в течение 30 минут. <br />
            Ожидайте.
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import ClientsAnketaSecurService from '@/api/ClientsAnketaSecurService.js'
import StorageFileService from '@/api/StorageFileService.js'
import GeneralService from '@/api/GeneralService'
import UploadFileWithPreview from '@/components/UploadFileWithPreview.vue'
export default {
  components: {
    Modal,
    EventBus,
    UploadFileWithPreview
  },

  data() {
    return {
      selectedFaceOption: null,
      statusEdit: false,
      statusFormFilling: false,
      formAnketData: {},
      galleryList: []
      // fileList: {
      //   client_document_image_1: {},
      //   client_document_image_2: {},
      //   client_document_image_3: {},
      //   client_document_image_4: {},
      //   client_document_image_5: {}
      // }
    }
  },
  methods: {
    ...mapActions('translationModule', ['getTranslation']),
    prepareValue(
      secur_clients_fields__name,
      secur_clients_answers__answer_text
    ) {
      if (
        secur_clients_fields__name == 'fiz_date_birth' &&
        secur_clients_answers__answer_text
      ) {
        return secur_clients_answers__answer_text.split('-').reverse().join('.')
      }
      if (
        secur_clients_fields__name == 'fiz_doc_vu_date' &&
        secur_clients_answers__answer_text
      ) {
        return secur_clients_answers__answer_text.split('-').reverse().join('.')
      }
      return secur_clients_answers__answer_text
    },
    prepareMountLenght(type) {
      if (type == 'fiz_date_birth') {
        return 10
      } else if (type == 'fiz_doc_vu_date') {
        return 10
      } else if (type == 'fiz_doc_vu_year') {
        return 4
      } else if (type == 'garant_phone') {
        return 16
      } else {
        return ''
      }
    },
    deleteFile(data) {
      console.log(data)
      // galleryList
      // for (const key in this.fileList) {
      //   if (e == this.fileList[key]) {
      //     console.log(e, this.fileList[key])
      //     this.fileList[key] = null
      //   }
      // }
      this.galleryList.forEach((elem, index) => {
        if (elem.secur_clients_fields__name == data.name) {
          elem.secur_clients_answers__answer_text = ''
        }
      })
      this.editData.forEach((elem, index) => {
        if (elem.secur_clients_fields__name == data.name) {
          elem.secur_clients_answers__answer_text = ''
        }
      })

      ClientsAnketaSecurService.updateFields(this.$route.params['key'], {
        [data.name]: null
      })
      // console.log(e, item, this.fileList['client_document_image_1'])
    },
    uploadFile(data) {
      console.log(data, data.fileInFormData)
      let formdata = new FormData()
      formdata.append('file', data.localFile)
      this.$refs[data.name][0].classList.remove('input-error')
      StorageFileService.uploadWithoutToken(formdata).then((response) => {
        this.editData.forEach((elem, index) => {
          if (elem.secur_clients_fields__name == data.name) {
            elem.secur_clients_answers__answer_text = response.data.data.url
          }
        })
        ClientsAnketaSecurService.updateFields(this.$route.params['key'], {
          [data.name]: response.data.data.url
        })
      })
    },
    // uploadFile(data, name) {
    //   console.log(data, name);

    //   // let uploadData = {
    //   //   file: data.file,
    //   //   type: 'file'
    //   // }

    //   // StorageFileService.uploadWithoutToken(upodaData).then((response) => {
    //   //   let obj = {}
    //   //   console.log(response.data.data)
    //   //   obj[name] = response.data.data.url
    //   //   console.log(obj)
    //   //   this.editData.forEach((elem, index) => {
    //   //     if (elem.secur_clients_fields__name == name) {
    //   //       elem.secur_clients_answers__answer_text = response.data.data.url
    //   //     }
    //   //   })
    //   //   ClientsAnketaSecurService.updateFields(this.$route.params['key'], obj)
    //   // })
    // },
    changeDate(date, name) {
      if (!date) date = ''
      this.updateValue({ name: name, value: date })
      console.log(this.$refs[name])
    },
    setFace(data) {
      ClientsAnketaSecurService.update(this.$route.params['key'], {
        secur_clients_ankets__group_id: data.id
      }).then((data) => {
        console.log(data)
        this.getAnketa()
      })

      EventBus.$emit('closeModal', { nameForAction: 'FormTypeFaceClients' })
    },

    getAnketa() {
      ClientsAnketaSecurService.getOne(this.$route.params['key'])
        .then((data) => {
          if (data.data.code == 1001) {
            EventBus.$emit('openModal', {
              data: null,
              nameForAction: 'FormTypeFaceClients',
              mode: 'edit'
            })
          } else if (data.data.code == 1002) {
            this.statusFormFilling = true
          } else {
            this.editData = data.data.data.rows
            data.data.data.rows.forEach((el, index) => {
              console.log(el)
              if (el.secur_clients_fields__type_field == 'secur_field_file') {
                this.galleryList.push(el)
              }
            })
            console.log(this.editData)
            this.statusEdit = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    updateValue(data) {
      this.formAnketData[data.name] = data.value
      console.log(data)
      this.editData.forEach((elem, index) => {
        if (elem.secur_clients_fields__name == data.name) {
          elem.secur_clients_answers__answer_text = data.value
        }
      })
      console.log(this.$refs[data.name][0].classList)
      this.$refs[data.name][0].classList.remove('input-error')
    },

    sendAnketa() {
      console.log(this.formAnketData)
      if (!this.editData.find((x) => !x.secur_clients_answers__answer_text)) {
        this.formAnketData['save'] = true
        this.statusEdit = false
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'FormSuccessSecure',
          mode: 'edit'
        })
        this.statusFormFilling = true
        console.log(this.editData)
        this.$toast.success('Успешное заполнение')
        if (this.formAnketData.fiz_date_birth) {
          this.formAnketData.fiz_date_birth = this.formAnketData.fiz_date_birth
            .split('.')
            .reverse()
            .join('-')
        }
        if (this.formAnketData.fiz_date_birth) {
          this.formAnketData.fiz_doc_vu_date =
            this.formAnketData.fiz_doc_vu_date.split('.').reverse().join('-')
        }

        console.log(
          this.formAnketData.fiz_date_birth,
          this.formAnketData.fiz_doc_vu_date
        )
        ClientsAnketaSecurService.updateFields(
          this.$route.params['key'],
          this.formAnketData
        ).then((response) => {})
      } else {
        this.$toast.error('Все поля являются обязательными для заполнения')
        this.editData.forEach((element) => {
          if (!element.secur_clients_answers__answer_text) {
            this.$refs[element.secur_clients_fields__name][0].classList.add(
              'input-error'
            )
          }
        })
        console.log(this.editData, this.formAnketData)
      }
    }
  },

  computed: {
    ...mapState('translationModule', ['dataLang']),

    selectFaceOptions() {
      return [
        {
          id: 1,
          label: this.dataLang['secur_fiz']
        },
        {
          id: 2,
          label: this.dataLang['secur_ur']
        }
      ]
    }
  },

  mounted() {
    this.getAnketa()
  },

  created() {
    this.getTranslation(1)
  }
}
</script>

<style lang="scss">
.specnewbox {
  max-width: 210px;
}
.speccontainer {
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .specnewbox {
    max-width: none;
    min-width: 240px;
    flex: 1;
  }
  .speccontainer {
    flex-direction: column;
    flex-wrap: nowrap !important;
  }
}
.newfileupload {
  .file-load__btn-del {
    width: 36px;
    height: 36px;
  }
}

.specnewbox--error {
  border: 1px solid red;
}

.input-error.newfileupload {
  border: 1px solid red;
}
</style>
