<template>
  <div class="drop-grid">
    <div class="box-images" v-for="image of arrayFiles" :key="image">
      <picture>
        <img
          class="box-images__images box-images__images--square"
          :src="image"
        />
      </picture>
      <div class="box-images__coverage">
        <div @click="deleteImage(image)" class="box-images__inner-icon">
          <!-- <BaseSystemIcons :name="'close'" :width="30"></BaseSystemIcons> -->
        </div>
      </div>
    </div>
    <label
      class="drop-zone mb-0"
      :class="[paramDeterm ? ' opacity-25 cursor-default' : '']"
    >
      <input
        type="file"
        class="d-none"
        accept="image/*"
        @change="changeImages"
        :multiple="!uploadConfig.onceImage"
        :disabled="paramDeterm"
      />
      <div v-if="!loading" class="drop-zone__sign fs-2 text-muted text-center">
        {{ "+" }}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    uploadConfig: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      arrayFiles: [],
      files: null,
      unraw: null,
      loading: false,
    };
  },
  methods: {
    changeImages(e) {
      this.loading = !this.loading;
      this.unraw = e.target.files;
      this.files = Array.from(e.target.files);
      this.files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          this.arrayFiles.push(event.target.result);
          this.arrayFiles.filter((item, index) => {
            return this.arrayFiles.indexOf(item) === index;
          });
        };
      });
      this.loading = !this.loading;
    },
    deleteImage(images) {
      this.arrayFiles.splice(this.arrayFiles.indexOf(images), 1);
    },
  },
  computed: {
    paramDeterm: function () {
      if (this.arrayFiles.length && this.uploadConfig.onceImage) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
