<template>
    <div>
        <div class="mt-20">
            <div class="d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('secureQuestionsModal')">Добавить</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div>
            </div>
            <BaseReuseTable class="mt-10" :props-data="secureQuestionsData" :props-columns-setting="fieldsTablesecureQuestions"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="secureQuestionsModal" v-if="secureQuestionsLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="secureQuestionsModal" size="xl">
                <div class="px-10 fw-bold">Добавить вопрос</div>
                <div class="p-10"> 
                    <div class="mb-5 mt-10 d-flex  gap-10">
                        <div class="wp-20">
                            <BaseInput placeholder="" title="Default Title" ref="secur_questions__number"
                            input-name="secur_questions__number" value="" @onInput="updateValue"
                            name-sign="Номер вопроса"></BaseInput> 
                        </div>
                        <div class="wp-80">
                            <BaseInput placeholder="" title="Default Title" ref="secur_questions__wording"
                            input-name="secur_questions__wording" value="" @onInput="updateValue"
                            name-sign="Формулировка вопроса"></BaseInput>
                        </div>
                    </div>
                    <div class="mb-5 mt-10">
                        <BaseTextarea name-sign="Полная формулировка вопроса"  input-name="secur_questions__full_wording" @onInput="updateValue"></BaseTextarea>
                    </div>
                    <div class="mb-5 mt-10">
                        <BaseInput placeholder="" title="Default Title" ref="secur_clients_fields__link"
                            input-name="secur_clients_fields__link" value="" @onInput="updateValue"
                            name-sign="Ссылка по желанию"></BaseInput> 
                    </div>
                    <div class="mb-5 mt-10">
                        <div class="d-flex gap-10 align-items-center wp-100">
                            <div style="white-space:pre">Тип ответа</div>
                            <BaseSelect :options="typeFieldQuestionSetlectOptions" placeholder="" label="label" selectLabel=""
                                @select="updateSelectValue" v-model="typeFieldQuestionSetlectedOption">
                                <div slot="no-options">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                    </div>
                    <div class="d-flex gap-10 mb-5 mt-10" >
                        <div class="d-flex gap-10 align-items-center wp-25">
                            <div style="white-space:pre">Критический ответ</div>
                            <BaseSelect :options="criticalSelectOptions" placeholder="" label="label"  selectLabel=""
                                @select="updateSelectValue" v-model="criticalSelectedOption">
                                <div slot="no-options">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div class="d-flex gap-10 align-items-center wp-75">
                            <div style="white-space:pre">Действие на критический ответ</div>
                            <BaseSelect :options="actionsCrtiticalSelectOptions" placeholder="" label="label" selectLabel=""
                                @select="updateSelectValue" v-model="actionsCrtiticalSelectedOption">
                                <div slot="no-options">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                    </div>
                    <div class="d-flex gap-10 align-items-center mb-5 mt-10">    
                        <BaseRadio input-name="secur_questions__auto_raport" style="white-space: pre;" value="1" @change="updateValue" v-model="autoRaport">
                            Авторапорт
                        </BaseRadio>  
                        <BaseInput class="wp-100" placeholder="" title="Default Title" ref="secur_questions__auto_raport_text"
                            input-name="secur_questions__auto_raport_text" value="" @change="updateValue"></BaseInput> 
                    </div>
                    <div class="mb-5 mt-10">
                        <BaseRadio input-name="secur_questions__auto_raport" style="white-space: pre;" value="0" @change="updateValue" v-model="autoRaport">
                            Включить поле комментарий
                        </BaseRadio> 
                    </div>
                    <div class="mb-5 mt-10">
                        <BaseInput placeholder="" title="Default Title" ref="secur_questions__help_text"
                            input-name="secur_questions__help_text" value="" @onInput="updateValue"
                            name-sign="Подсказка под текстовым полем"></BaseInput> 
                    </div>

                    <div class="d-flex gap-10 mt-15">
                        <div class="btn btn-sm btn-primary" @click="sendData">{{
                            dataLang.form_btn__btn_save }}</div>
                        <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                            dataLang.form_btn__btn_caneсl }}</div>
                    </div>
                </div>
            </Modal>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>

<script>
    
    import { mapActions, mapState, mapMutations } from "vuex";
    import Modal from '@/components/Modals.vue'
    import EventBus from '@/event-bus.js'
    import SelectsService from "@/api/SelectsService";
    import BaseTextarea from "../ui/BaseTextarea.vue";
    import BaseRadio from "../ui/BaseRadio.vue";
    export default {
        components:{
            Modal,
            EventBus,
            BaseTextarea,
            BaseRadio
        },

        data(){
            return {
                dataForm: {},
                typeFieldQuestionSetlectOptions:[],
                typeFieldQuestionSetlectedOption:null,
                criticalSelectOptions:[],
                criticalSelectedOption: null,
                actionsCrtiticalSelectOptions:[],
                actionsCrtiticalSelectedOption:null,
                autoRaport: 1,
                activeSelectRow: null,
                editData: null,
            }
        },

       
        created(){
            this.loadSelects("secur_questions__type_field", (typeFieldQuestionSetlectData) => {
                this.typeFieldQuestionSetlectOptions = typeFieldQuestionSetlectData.data.data.rows;
            })

            this.loadSelects("secur_questions__status_critical_answer", (criticalSelectData) => {
                this.criticalSelectOptions = criticalSelectData.data.data.rows;
            })

            this.loadSelects("secur_questions__action", (actionsCrtiticalSelectData) => {
                this.actionsCrtiticalSelectOptions = actionsCrtiticalSelectData.data.data.rows;
            })
        },

        mounted() {
            EventBus.$on("openModal", (payload) => {
                this.editData = payload.data
                // console.log(payload.data);
                // this.prepareKeysForModal(payload.data)
            });

            EventBus.$on("selectTableRows", (payload) => {
                this.activeSelectRow = payload.data.secur_questions__id
            });

            EventBus.$on("deselectTableRows", (payload) => {
                this.activeSelectRow = null
            });

        },
        methods: {
            ...mapActions("secureQuestionsModule", ["getSecureQuestionsAll", "createSecureQuestion", "deleteSecurQuestionsData"]),

            openModalForCreate(nameModal) {
                EventBus.$emit("openModal", { data: null, nameForAction: nameModal, mode: 'create' })
            },

            sendData(){
                this.dataForm["secur_questions__group_id"] = this.secureOpenGroupId;
                this.createSecureQuestion({id:this.secureOpenGroupId, data:this.dataForm});
                EventBus.$emit("closeModal", { nameForAction: 'secureQuestionsModal' })
            },

            btnHandlerCloseModal(nameModal) {
                EventBus.$emit("closeModal", { nameForAction: 'secureQuestionsModal' })
            },


            loadSelects(name, callback){
                SelectsService.getOneByField(name).then(callback);
            },

            updateSelectValue(data){
                this.dataForm[data.field] = data.value;
            },

            updateValue(data){
                this.dataForm[data.name] = data.value;
            },


            deleteActionAndModal() {
                if (this.activeSelectRow) {
                    EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
                } else {
                    this.$toast.error('Вы не выбрали ни одного поля')
                }
            },

            deleteRow(nameModal) {
                this.deleteSecurQuestionsData({idGroup:this.secureOpenGroupId, idQuestion:this.activeSelectRow})
                this.activeSelectRow = null
                EventBus.$emit("closeModal", { nameForAction: nameModal })
            },
        },

        watch:{
            secureOpenGroupId(val){
                if(val)
                    this.getSecureQuestionsAll(this.secureOpenGroupId);
            },
        },

        computed:{
            ...mapState("secureGroupsModule", ["secureOpenGroupId"]),
            ...mapState("secureQuestionsModule", ["secureQuestionsData", "secureQuestionsLoadStatus"]),
            ...mapState("translationModule", ["dataLang"]),

            fieldsTablesecureQuestions(){
                return [
                    { title: 'ID', field: "secur_questions__id", headerFilter: "input", width:100},
                    { title: 'Номер', field: "secur_questions__number", headerFilter: "input" },
                    { title: 'Вопрос', field: "secur_questions__wording", headerFilter: "input" },
                    { title: 'Крит. ответ', field: "secur_questions__status_critical_answer", headerFilter: "input", formatter:(cell) => {
                        let val = cell.getValue();
                        switch(val){
                            case 1:
                                return "Да";
                            case 0:
                                return "Нет";
                        }
                    }},
                ]
            }
        }
    }
</script>