<template>
    <div>
        <BaseTopLine>
            <!-- <div class="d-flex gap-10 align-items-center">
                
            </div> -->
        </BaseTopLine>
        <div class="container-fluid mt-20">
            <vue-tabs @tab-change="handleTabChange">
                <v-tab :title="dataLang['transport_vehicles__ui_sign__tabs__passenger_transport']" ref="car" data-name="car"
                    data-type="1" data-type-id="1">
                    <div class="pt-20" v-if="transportVehiclesLoadStatus == 'success'">
                        <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2"
                            :break-view-class="'d-none'" :click-handler="clickLoadData" :prev-text="'Назад'"
                            :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'"
                            :pageLinkClass="'page-link'">
                        </BasePagination>
                        <div class="d-flex gap-10 align-items-center mb-20">
                            <div class="btn btn-sm btn-primary" @click="openModalForCreate">{{
                                dataLang.global_ui_sign__btn_add }}</div>
                            <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">{{
                                dataLang.global_ui_sign__btn_delete }}</div>

                        </div>
                        <BaseReuseTable :props-data="transportVehiclesData" :props-columns-setting="fieldsTableOwners"
                            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="car" />
                    </div>
                </v-tab>

            </vue-tabs>
        </div>
        <Teleport to="body">
            <Modal nameForAction="car" size="xl" @closeModal="closeModal">
                <div class="row">
                    <div class="col-lg-4">
                        <div>
                            <span>Город*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataCity"
                                label="cities__name" @input="setSelected" :options="cities">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Площадка*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataPlace"
                                label="vehicle_places__address" @input="setSelected" :options="places">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>

                        </div>
                        <div>
                            <span>Марка*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataMark"
                                label="vehicle_marks__name" @input="setSelected" :options="marks">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Модель*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataModel"
                                label="vehicles__model" @input="setSelected" :options="models">
                                <div slot="noOptions">Пока нет опций</div>
                                
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Поколение кузова*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel=""
                                v-model="dataStupedSelect.dataGeneration" label="car_examples__body_generation"
                                @input="setSelected" :options="generation">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Тип кузова</span>
                            <div class="fw-semi-bold">{{ !dataLang[dataForModal.vehicle_cars__body_type] ?
                                dataForModal.vehicle_cars__body_type : dataLang[dataForModal.vehicle_cars__body_type] }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Категория</span>
                            <div class="fw-semi-bold">{{
                                !dataLang[dataForModal.vehicle_cars__vehicle_class] ?
                                dataForModal.vehicle_cars__vehicle_class :
                                dataLang[dataForModal.vehicle_cars__vehicle_class] }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Ширина</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__width }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Высота</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__height }}</div>
                        </div>
                        <!-- <div class="d-flex justify-content-between flex-wrap">
                            <span>Длина</span>
                            <div class="fw-semi-bold">{{ dataForModal.car_examples__length }}</div>
                        </div> -->
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Мест </span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__count_places }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Дверей</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__count_doors }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Объем багажника (л)</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__trunk_volume }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Дорожный просвет</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__ground_clearance }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Снар. масса</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__curb_weight }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Полная масса</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__full_weight }}</div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <span>Объем бака (л)</span>
                            <div class="fw-semi-bold">{{ dataForModal.vehicle_cars__tank_capacity }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div>
                            <span>Год выпуска*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataYears"
                                label="label" @input="setSimpleSelect" :options="years">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Привод*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataDrive"
                                label="label" @input="setSimpleSelect" :options="selectsData.vehicle_cars__drive">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Цвет*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataColor"
                                label="label" @input="setSimpleSelect" :options="selectsData.vehicles__color">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Коробка*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataKpp"
                                label="label" @input="setSimpleSelect" :options="selectsData.vehicle_cars__kpp">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Тип двигателя*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel=""
                                v-model="dataStupedSelect.dataTypeEngine" label="label" @input="setSimpleSelect"
                                :options="selectsData.vehicles__type_engine">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Люк</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" @input="setSimpleSelect"
                                label="label" v-model="dataStupedSelect.dataLuk" :options="selectsData.vehicle_cars__luk">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Фаркоп</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataFarcop"
                                label="label" @input="setSimpleSelect" :options="selectsData.vehicle_cars__farcop">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Рейлинги на крыше</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel=""
                                v-model="dataStupedSelect.dataReyling" label="label" @input="setSimpleSelect"
                                :options="selectsData.vehicle_cars__reyling">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Тонировка</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataTon"
                                label="label" @input="setSimpleSelect" :options="selectsData.vehicle_cars__ton">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Материал сидений</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel=""
                                v-model="dataStupedSelect.dataMaterial" label="label" @input="setSimpleSelect"
                                :options="selectsData.vehicle_cars__material">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <div>
                            <span>Кондиционер</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel=""
                                v-model="dataStupedSelect.dataConditioner" label="label" @input="setSimpleSelect"
                                :options="selectsData.vehicle_cars__conditioner">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>

                        <div>
                            <BaseInput placeholder="" inputRef="vehicles__engine_power" inputMask="0.0" input-name="vehicles__engine_power" @onInput="updateValue"
                                :value="dataForModal.vehicles__engine_power" name-sign="Объем двигателя">
                            </BaseInput>
                        </div>
                        <div>
                            <BaseInput placeholder="" ref="wwww" input-name="vehicles__power" @onInput="updateValue"
                                :value="dataForModal.vehicles__power" name-sign="Мощность">
                            </BaseInput>
                        </div>
                        <div>
                            <BaseInput placeholder="" ref="wwww" input-name="vehicles__flow" @onInput="updateValue"
                                :value="dataForModal.vehicles__flow" name-sign="Расход топлива">
                            </BaseInput>
                        </div>
                        <div>
                            <BaseInput placeholder="" ref="wwww" input-name="vehicles__cost" @onInput="updateValue"
                                :value="dataForModal.vehicles__cost" name-sign="Стоимость">
                            </BaseInput>
                        </div>



                    </div>
                    <div class="col-lg-4">
                        <div>
                            <span>Собственник*</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataStupedSelect.dataOwner"
                                :custom-label="({ vehicle_owners__name, vehicle_owner_ur__in_face }) => { return vehicle_owners__name ? vehicle_owners__name : vehicle_owner_ur__in_face }"
                                @input="setSelectedOwner" :options="owners">
                                <div slot="noOptions">Пока нет опций</div>
                            </BaseSelect>
                        </div>
                        <BaseInput placeholder="a123бв 123" ref="wwww" input-name="vehicles__gosnumber" @onInput="updateValue"
                            :value="dataForModal.vehicles__gosnumber" :name-sign="'Госномер'"  inputRef="vehicles__gosnumber" inputMask="a000aa000">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__sts" @onInput="updateValue"
                            :value="dataForModal.vehicles__sts" :name-sign="'СТС'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__sts_date" @onInput="updateValue"
                            :value="dataForModal.vehicles__sts_date" :name-sign="'СТС дата'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__pts" @onInput="updateValue"
                            :value="dataForModal.vehicles__pts" :name-sign="'ПТС'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__pts_date" @onInput="updateValue"
                            :value="dataForModal.vehicles__pts_date" :name-sign="'ПТС дата'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__exit_at" @onInput="updateValue"
                            :value="dataForModal.vehicles__exit_at" :name-sign="'У кого находится ПТС'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__vin" @onInput="updateValue"
                            :value="dataForModal.vehicles__vin" :name-sign="'VIN/кузов:'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__engine" @onInput="updateValue"
                            :value="dataForModal.vehicles__engine" :name-sign="'Номер двигателя'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicle_cars__range_oil_dvs" @onInput="updateValue"
                            :value="dataForModal.vehicle_cars__range_oil_dvs" :name-sign="'Интервал замены масла ДВС'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicle_cars__range_oil_kpp" @onInput="updateValue"
                            :value="dataForModal.vehicle_cars__range_oil_kpp" :name-sign="'Интервал замены масла КПП'">
                        </BaseInput>
                        <!-- <BaseInput placeholder="" ref="wwww" input-name="vehicles__reglament_step" @onInput="updateValue"
                            :value="dataForModal.vehicles__reglament_step" :name-sign="'Интервал замены масла '">
                        </BaseInput> -->
                        <!-- <BaseInput placeholder="" ref="wwww" input-name="vehicle_status__status" @onInput="updateValue"
                            :value="dataForModal.vehicle_status__status" :name-sign="'Статус ТС'">
                        </BaseInput> -->
                        <!-- <BaseInput placeholder="" ref="wwww" input-name="vehicles__entrance_at" @onInput="updateValue"
                            :value="dataForModal.vehicles__entrance_at" :name-sign="'Дата поступления в парк'">
                        </BaseInput> -->
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__mileage_in" @onInput="updateValue"
                            :value="dataForModal.vehicles__mileage_in" :name-sign="'Пробег поступления в парк'">
                        </BaseInput>
                        <BaseInput placeholder="" ref="wwww" input-name="vehicles__mileage_end" @onInput="updateValue"
                            :value="dataForModal.vehicles__mileage_end" :name-sign="'Пробег списания'">
                        </BaseInput>
                        <!-- <span>СТС скан</span>
                        <div class="mb-10">
                            <a :href="dataForModal.vehicles__sts_file" v-if="dataForModal.vehicles__sts_file"
                                class="responsive-img box-aspect box-aspect--square">
                                <img
                                    :src="dataForModal.vehicles__sts_file"
                                    class="responsive-img__image" alt="">
                                </a>
                            <div class="d-flex gap-10 mt-10"><label class="btn btn-sm btn-primary"
                                    :class="{ disabled: dataForModal.vehicles__sts_file }">Добавить файл<input type="file"
                                        class="d-none" @change="uploadFile('CTC', 'vehicles__sts_file')" ref="CTC"></label>
                                <div class="btn btn-sm btn-opposite" :class="{ disabled: !dataForModal.vehicles__sts_file }"
                                    @click="delFile('vehicles__sts_file')">
                                    Удалить файл</div>
                            </div>

                        </div>
                        <span>ПТС скан</span>
                        <div class="mb-10">
                            
        <a :href="dataForModal.vehicles__pts_file " v-if="dataForModal.vehicles__pts_file "
                                class="responsive-img box-aspect box-aspect--square">
                                <img
                                    :src="dataForModal.vehicles__pts_file "
                                    class="responsive-img__image" alt="">
                                </a>
                            <div class="d-flex gap-10 mt-10"><label class="btn btn-sm btn-primary"
                                    :class="{ disabled: dataForModal.vehicles__pts_file }">Добавить файл<input type="file"
                                        class="d-none" @change="uploadFile('PTS', 'vehicles__pts_file')" ref="PTS"></label>
                                <div class="btn btn-sm btn-opposite" :class="{ disabled: !dataForModal.vehicles__pts_file }"
                                    @click="delFile('vehicles__pts_file')">
                                    Удалить файл</div>
                            </div>

                        </div> -->



                    </div>
                    <!-- <p class="fw-bold">Галерея</p>
                    <div class="d-flex justify-content-between flex-wrap">

                        <div class="mb-10" v-for="(item, index) in dataGallery" :key="index">
                            <a :href="item.vehicles__pts_file" v-if="item.vehicle_gallery__file" style="text-overflow: ellipsis; white-space: nowrap; 
    overflow: hidden;">{{
        item.vehicle_gallery__file }}</a>
                            <div class="d-flex gap-10"><label class="btn btn-sm btn-primary"
                                    :class="{ disabled: item.vehicle_gallery__file }">Добавить файл<input type="file"
                                        class="d-none" @change="uploadFileGallery(index, 'vehicle_gallery__file')"
                                        :id="`vehicle_gallery__file${index}`"></label>
                                <div class="btn btn-sm btn-opposite" :class="{ disabled: !item.vehicle_gallery__file }"
                                    @click="delFileGallery(index, 'vehicle_gallery__file')">
                                    Удалить файл</div>
                            </div>

                        </div>
                    </div> -->
                    <div class="d-flex gap-10 align-items-center justify-content-end mt-20">
                        <div class="btn btn-sm btn-primary" @click="sendDataForModal">{{
                            dataLang.global_ui_sign__btn_add }}</div>
                        <!-- <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Закрыть без сохранения</div> -->

                    </div>
                </div>
            </Modal>
        </Teleport>
        <Teleport to="body">
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow">{{ dataLang.confirm_btn__btn_yes }}</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import CitiesService from "@/api/CitiesService.js";
import OwnersService from "@/api/OwnersService.js";
import VehicleMarksService from "@/api/VehicleMarksService.js";
import SelectsService from "@/api/SelectsService.js";
import PlacesService from "@/api/PlacesService.js";
import StorageFileService from '@/api/StorageFileService.js';
import TransportVehiclesService from '@/api/TransportVehiclesService';
import VehicleTypeAndCharacteristicsService from '@/api/VehicleTypeAndCharacteristicsService.js'
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import { get, set } from 'lodash';
export default {
    components: {
        Modal,
        VueTabs,
        VTab,
    },

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null,
            activeTab: 'car',
            modalOpenType: 'edit',
            fileRecordCTC: {
                "name": "www",
                "imageThumbnail": "https://4803871b-yandexrent.s3.timeweb.com/a4d764078614e646d852a3fbe25ff068e5d23c06.jpg",
                "type": "image/jpg",
                // "ext": "jpg",
            },
            selectsData: {
                vehicle_cars__drive: [],
                vehicles__color: [],
                vehicle_cars__kpp: [],
                vehicles__type_engine: [],
                vehicle_cars__luk: [],
                vehicle_cars__farcop: [],
                vehicle_cars__reyling: [],
                vehicle_cars__ton: [],
                vehicle_cars__material: [],
                vehicle_cars__conditioner: []
            },
            cities: [],
            marks: [],
            places: [],
            models: [],
            generation: [],
            years: [],
            owners: [],
            dataStupedSelect: {
                dataCity: [],
                dataPlace: [],
                dataMark: [],
                dataModel: [],
                dataGeneration: [],
                dataYears: [],
                dataColor: [],
                dataDrive: [],
                dataKpp: [],
                dataTypeEngine: [],
                dataLuk: [],
                dataFarcop: [],
                dataReyling: [],
                dataTon: [],
                dataMaterial: [],
                dataConditioner: [],
                dataMaterial: [],
                dataOwner: []
            },
            markName: '',
            modelName: '',
            fullVehicleName: '',
            stsFile: null,
            ptsFile: null,
            dataGallery: []

        };
    },
    methods: {
        uploadFileGallery(index, name) {
            let formData = new FormData()
            formData.append('file', document.querySelector(`#${name}${index}`).files[0]);
            formData.append('type', 'vehicle');
            StorageFileService.upload(formData).then((data) => {
                this.dataGallery[index]['vehicle_gallery__file'] = data.data.data
                console.log(this.dataGallery);
            }).catch(err => { console.log(err); throw new Error(err) })
        }
        ,
        delFileGallery(index, name) {
            console.log(name);
            this.dataGallery[index]['vehicle_gallery__file'] = ''
        },
        uploadFile(nameField, key) {
            console.log(this.$refs[nameField].files[0]);
            let formData = new FormData()
            formData.append('file', this.$refs[nameField].files[0]);
            formData.append('type', 'vehicle');
            StorageFileService.upload(formData).then((data) => {
                console.log(data);
                this.dataForModal[key] = data.data.data
            }).catch(err => { console.log(err); throw new Error(err) })
            console.log(this.dataForModal);
        }
        ,
        delFile(name) {
            this.dataForModal[name] = null
        },
        setSelectedOwner(data) {
            this.dataForModal['vehicles__owner_id'] = data.vehicle_owners__id
            this.dataForModal['vehicle_owners__name'] = data.vehicle_owners__name
        },
        computedDateForSelect(data) {
            console.log(data);
            if (data['car_examples__body_year_starts']) {
                let start = Number(data['car_examples__body_year_starts'].slice(0, 4))
                let end = Number(data['car_examples__body_year_ends'].slice(0, 4))
                console.log();
                let computedYears = end - start
                for (let index = 0; index < computedYears; index++) {
                    console.log(start + index);
                    this.years.push({ label: start + index, key: 'vehicles__year_release', value: start + index });
                }
                console.log(this.years);
            }


        },

        setSimpleSelect(data, id) {
            console.log(data, id);
            this.dataForModal[data.key] = data.value
            console.log(this.dataForModal);
        },
        setSelected(dataFromSelect) {
            if (dataFromSelect) {
                switch (dataFromSelect.selectName) {
                    case 'cities':
                        console.log(dataFromSelect);
                        this.dataForModal = Object.assign(this.dataForModal, { cities__id: dataFromSelect.cities__id, cities__name: dataFromSelect.cities__name })
                        PlacesService.getAll(dataFromSelect.cities__id).then(data => {
                            this.places = this.prepareLabelsForSelects(data.data.data.rows, 'place')
                        }).catch(err => console.log(err))
                        break;

                    case 'place':
                        console.log('place', dataFromSelect);
                        this.dataForModal.vehicles__place_id = dataFromSelect.vehicle_places__id
                        this.dataForModal = Object.assign(this.dataForModal, { vehicle_places__address: dataFromSelect.vehicle_places__address })
                        break;

                    case 'marks':
                        this.markName = dataFromSelect.vehicle_marks__name
                        console.log(dataFromSelect.vehicle_marks__id);
                        this.dataForModal.vehicles__mark_id = dataFromSelect.vehicle_marks__id
                        VehicleTypeAndCharacteristicsService.getAllByParams({
                            params: {
                                where: JSON.stringify([{ 'field': 'vehicle_examples__mark_id', 'value': dataFromSelect['vehicle_marks__id'] }, { "field": "vehicle_examples__type_id", "value": this.$refs[this.activeTab].$attrs['data-type'] }])
                            }
                        }).then(data => {
                            this.models = this.prepareLabelsForSelects(data.data.data.rows, 'models')
                        }).catch(err => console.log(err))
                        this.dataStupedSelect.dataModel = []
                        this.dataStupedSelect.dataGeneration = []
                        this.fillAfterSelectGeneration()
                        break;
                    case 'models':
                        this.modelName = dataFromSelect.vehicles__model
                        this.dataForModal.vehicles__model = dataFromSelect.vehicles__model
                        // this.dataForModal = Object.assign(this.dataForModal, {vehicles__place_id: dataFromSelect.vehicle_places__id, vehicles__company_id: dataFromSelect.vehicle_places__company_id})
                        VehicleTypeAndCharacteristicsService.getAll({
                            params: {
                                vehicleType: this.activeTab,
                                fields: 'vehicle_examples__id,car_examples__body_generation',
                                where: JSON.stringify([{ 'field': 'vehicle_examples__mark_id', 'value': dataFromSelect['id'] }, { "field": "vehicle_examples__model", "value": dataFromSelect['vehicles__model'] }])
                            }
                        }).then(data => {
                            console.log(data);
                            this.generation = this.prepareLabelsForSelects(data.data.data.rows, 'generation')

                        }).catch(err => console.log(err))
                        this.dataForModal.vehicles__name = `${this.markName} ${this.modelName}`
                        this.dataStupedSelect.dataGeneration = []
                        this.fillAfterSelectGeneration()
                        break;
                    case 'generation':
                        console.log(dataFromSelect);
                        this.fillAfterSelectGeneration(dataFromSelect)
                        break;

                }
            }

            console.log(dataFromSelect);
        },
        fillAfterSelectGeneration(dataFromSelect) {
            if (dataFromSelect) {
                this.computedDateForSelect(dataFromSelect)
                this.dataForModal.vehicle_cars__body_generation = dataFromSelect.car_examples__body_generation
                this.dataForModal = Object.assign(this.dataForModal, dataFromSelect)
                this.dataForModal.vehicle_cars__body_type = dataFromSelect.car_examples__body_type
                this.dataForModal.vehicle_cars__vehicle_class = dataFromSelect.car_examples__vehicle_class
                this.dataForModal.vehicle_cars__width = dataFromSelect.car_examples__width
                this.dataForModal.vehicle_cars__height = dataFromSelect.car_examples__height
                this.dataForModal.vehicle_cars__count_doors = dataFromSelect.car_examples__count_doors
                this.dataForModal.vehicle_cars__count_places = dataFromSelect.car_examples__count_places
                this.dataForModal.vehicle_cars__ground_clearance = dataFromSelect.car_examples__ground_clearance
                this.dataForModal.vehicle_cars__curb_weight = dataFromSelect.car_examples__curb_weight
                this.dataForModal.vehicle_cars__full_weight = dataFromSelect.car_examples__full_weight
                this.dataForModal.vehicle_cars__trunk_volume = dataFromSelect.car_examples__trunk_volume
                this.dataForModal.vehicle_cars__tank_capacity = dataFromSelect.car_examples__tank_capacity
            }
            else {
                this.dataForModal.vehicle_cars__body_generation = ''
                this.dataForModal.vehicle_cars__body_type = ''
                this.dataForModal.vehicle_cars__vehicle_class = ''
                this.dataForModal.vehicle_cars__width = ''
                this.dataForModal.vehicle_cars__height = ''
                this.dataForModal.vehicle_cars__count_doors = ''
                this.dataForModal.vehicle_cars__count_places = ''
                this.dataForModal.vehicle_cars__ground_clearance = ''
                this.dataForModal.vehicle_cars__curb_weight = ''
                this.dataForModal.vehicle_cars__full_weight = ''
                this.dataForModal.vehicle_cars__trunk_volume = ''
                this.dataForModal.vehicle_cars__tank_capacity = ''
            }

        },
        clickLoadData(numPage) {
            this.getTransportVehiclesAll({
                limit: 10,
                page: numPage
            }
            )
        },
        handleTabChange(tabIndex, newTab, oldTab) {
            if (!(newTab.$attrs['data-name'] == this.activeTab)) {
                this.activeTab = newTab.$attrs['data-name']
                // this.vehicleTypeId = newTab.$attrs['data-type-id']
                this.getTransportVehiclesAll({
                    vehicleType: newTab.$attrs['data-name'],
                    limit: 10,
                    page: 1

                })
            }

        },
        openModalForCreate() {
            this.modalOpenType = 'create'
            EventBus.$emit("openModal", { data: null, nameForAction: this.activeTab, mode: 'create' })
        },


        deleteActionAndModal() {
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete" });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },
        deleteRow() {
            this.deleteTransportVehiclesData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },


        sendDataForModal() {
            if (this.dataForModal.vehicles__id) {

                console.log(this.dataForModal.vehicles__id, this.dataForModal);
                this.editTransportVehiclesMultiple({ id: this.dataForModal.vehicles__id, dataEdit: this.dataForModal })
                EventBus.$emit("closeModal", { nameForAction: 'car' })
                this.updateGallery()
            } else {
                this.dataForModal['images'] = this.dataGallery
                this.createTransportVehiclesData(Object.assign(this.dataForModal, { vehicles__type: 'car' }))
                console.log(this.dataForModal.vehicles__id, this.updatedValues);
                EventBus.$emit("closeModal", { nameForAction: 'car' })
                this.dataForModal = {}
                this.updateGallery()
            }
        },


        updateValue(data) {
            this.dataForModal[data.name] = data.value
            console.log(this.dataForModal, this.dataForModal[data.name]);
        },


        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
            this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
                element.value = ''
            })
            for (const key in this.dataStupedSelect) {
                this.dataStupedSelect[key] = []
            }
            
        },
        btnHandlerCloseModal(nameModal) {
            console.log(nameModal);
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },

        prepareKeysForModal(payloadData) {
            this.updatedValues = {}
            this.dataForModal = {}
            console.log(payloadData);
            if (!payloadData) {
                for (const key in this.transportVehiclesData[0]) {
                    this.dataForModal[key] = null
                    if (key == 'vehicles__sts_file') {
                        console.log('id here!!!');
                    }
                }
            } else {
                console.log(payloadData);
                // this.transportVehiclesData.find(data => data['vehicles__id'] === payloadData['vehicles__id'])
                // TransportVehiclesService.getImagesGallery(payloadData.vehicles__id).then(data => {
                //     this.dataGallery = data.data.data.rows
                //     console.log(this.dataGallery);
                // }).catch(err => console.log(err))
                TransportVehiclesService.getAll(payloadData.vehicles__id).then(data => {
                    console.log(data);
                }).catch(err => console.log(err))
                this.dataForModal = Object.assign({}, payloadData)
                this.dataForModal.vehicle_cars__body_type = payloadData.vehicle_cars__body_type
                this.dataForModal.vehicle_cars__vehicle_class = payloadData.vehicle_cars__vehicle_class
                this.dataStupedSelect = {
                    dataYears: { label: payloadData.vehicles__year_release },
                    dataCity: { cities__name: payloadData.cities__name },
                    dataPlace: { vehicle_places__address: payloadData.vehicle_places__address },
                    dataMark: { vehicle_marks__name: payloadData.vehicles__name ? payloadData.vehicles__name.trim().split(' ')[0] : '' },
                    dataModel: { vehicles__model: payloadData.vehicles__model },
                    dataGeneration: { car_examples__body_generation: payloadData.vehicle_cars__body_generation },
                    dataColor: { label: payloadData.vehicles__color },
                    dataDrive: { label: payloadData.vehicle_cars__drive },
                    dataKpp: { label: payloadData.vehicle_cars__kpp },
                    dataTypeEngine: { label: payloadData.vehicles__type_engine },
                    dataLuk: { label: payloadData.vehicle_cars__luk },
                    dataFarcop: { label: payloadData.vehicle_cars__farcop },
                    dataReyling: { label: payloadData.vehicle_cars__reyling },
                    dataTon: { label: payloadData.vehicle_cars__ton },
                    dataMaterial: { label: payloadData.vehicle_cars__material },
                    dataConditioner: { label: payloadData.vehicle_cars__conditioner },
                    dataOwner: { vehicle_owners__name: payloadData.vehicle_owners__name }
                }
                console.log(this.dataStupedSelect);
                this.loadDataForEdit(payloadData)
            }


        },

        prepareLabelsForSelects(data, keyDataFromFetch) {
            for (const key in data) {
                data[key]['selectName'] = keyDataFromFetch
            }
            return data
        },
        prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
            for (const key in data) {
                data[key].forEach(element => {
                    element['label'] = element[Object.keys(element).pop()];
                    element['key'] = key
                    element['value'] = Object.keys(element)[0]
                    element['selectName'] = keyDataFromFetch
                })
            }
            return data
        },
        ...mapActions("transportVehiclesModule", ["getTransportVehiclesAll", 'editTransportVehiclesMultiple', 'createTransportVehiclesData', 'deleteTransportVehiclesData']),

        loadDataForEdit(payload) {
            PlacesService.getAll(payload.cities__id).then(data => {
                this.places = this.prepareLabelsForSelects(data.data.data.rows, 'place')
            }).catch(err => console.log(err))

            VehicleTypeAndCharacteristicsService.getAllByParams({
                params: {
                    where: JSON.stringify([{ 'field': 'vehicle_examples__mark_id', 'value': payload['vehicles__mark_id'] }, { "field": "vehicle_examples__type_id", "value": this.$refs[this.activeTab].$attrs['data-type'] }])
                }
            }).then(data => {
                this.models = this.prepareLabelsForSelects(data.data.data.rows, 'models')
            }).catch(err => console.log(err))

            VehicleTypeAndCharacteristicsService.getAll({
                params: {
                    vehicleType: this.activeTab,
                    fields: 'vehicle_examples__id,car_examples__body_generation',
                    where: JSON.stringify([{ 'field': 'vehicle_examples__mark_id', 'value': payload['vehicles__mark_id'] }, { "field": "vehicle_examples__model", "value": payload['vehicles__model'] }])
                }
            }).then(data => {
                console.log(data);
                this.generation = this.prepareLabelsForSelects(data.data.data.rows, 'generation')
                this.computedDateForSelect(data.data.data.rows[0])

            }).catch(err => console.log(err))




        },
        updateGallery() {
            for (let index = 1; index <= 10; index++) {
                this.dataGallery.push({
                    "vehicle_gallery__file": "",
                    "vehicle_gallery__position": index
                }
                )
            }
        }
    },
    mounted() {
        EventBus.$on("openModal", (payload) => {
            this.prepareKeysForModal(payload.data)
        });
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.vehicle_owners__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },
    created() {
        this.getTransportVehiclesAll({ page: 1, limit: 10, vehicleType: 'car' })
        // this.prepareKeysForModal()
        CitiesService.getAll({
            params: {
                limit: 1000
            }
        }).then(data => {
            this.cities = this.prepareLabelsForSelects(data.data.data.rows, 'cities')
        }).catch(e => console.log(e))
        VehicleMarksService.getAll().then(data => {
            this.marks = this.prepareLabelsForSelects(data.data.data.rows, 'marks')
        }).catch(e => console.log(e))
        OwnersService.getAll().then(data => {
            this.owners = this.prepareLabelsForSelects(data.data.data.rows, 'owners')
        }).catch(e => console.log(e))

        SelectsService.getAll().then(data => {
            this.selectsData = this.prepareLabelsForDefaultSelects(data.data.data.rows, 'allSelects', true)
        }).catch(e => console.log(e))


        this.updateGallery()

    },
    computed: {
        ...mapState("transportVehiclesModule", ["transportVehiclesData", "transportVehiclesLoadStatus", 'paginationStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("transportVehiclesModule", ["numPages"]),


        // setMarksModelName: {
        //     get() {
        //         return this.dataForModal
        //     },
        //     set(newValue) {
        //         Object.keys(this.dataForModal, newValue)
        //         console.log(12121212121);

        //     }
        // },

        fieldsTableOwners() {
            return [
                { title: 'ID', field: "vehicles__id", headerFilter: "input", hozAlign: "center", },
                {
                    title: "Статус", field: "vehicle_status__status", headerFilter: "input", mutator: (value, data) => {
                        return { "status": value, "code": data.vehicle_status__code, "style": data.vehicle_status__style };
                    }, formatter: (cell) => {
                        let value = cell.getValue();
                        let style = JSON.parse(value.style);
                        for (let key in style) {
                            console.log(key);
                            cell.getElement().style[key] = style[key];
                        }

                        return value.status;
                    }
                },
                { title: "Транспорт", field: "vehicles__name", headerFilter: "input" },
                { title: "Год", field: "vehicles__year_release", headerFilter: "input" },
                { title: "Госномер", field: "vehicles__gosnumber", headerFilter: "input" },
                { title: "Цвет", field: "vehicles__color", headerFilter: "input" },
                { title: "Пробег", field: "vehicles__mileage_in", headerFilter: "input" },
                // { title: this.dataLang["form_fiz__vehicle_owners_fiz__passport"], field: "vehicle_cars__drive", headerFilter: "input" },
                // { title: this.dataLang["form_fiz__vehicle_owners__phone"], field: "vehicle_cars__kpp", headerFilter: "input" },
                // { title: this.dataLang["form_fiz__vehicle_owners__name"], field: "vehicle_cars__luk", headerFilter: "input" },
                // { title: this.dataLang["form_fiz__vehicle_owners__address"], field: "vehicle_cars__farcop", headerFilter: "input" },
                // { title: this.dataLang["form_ur__vehicle_owners_ur__rs"], field: "vehicle_cars__ton", headerFilter: "input" },
                // { title: this.dataLang["form_ur__vehicle_owners_ur__ks"], field: "vehicle_cars__material", headerFilter: "input" },
                // { title: this.dataLang["form_ur__vehicle_owners_ur__in_face"], field: "vehicle_cars__conditioner", headerFilter: "input" },
                // { title: this.dataLang["form_ur__vehicle_owners_ur__acts_on"], field: "vehicle_cars__multimedia", headerFilter: "input" },
                // { title: this.dataLang["form_ur__vehicle_owners_ur__ogrn"], field: "vehicle_cars__summer_tires", headerFilter: "input" },
                // { title: this.dataLang["form_ur__vehicle_owners_ur__inn"], field: "vehicle_cars__winter_tires", headerFilter: "input" },
                // { title: this.dataLang["form_ur__vehicle_owners_ur__kpp"], field: "vehicle_cars__tires_storage", headerFilter: "input" },has
                
            ]
        },

    },
};
</script>
    
    