<template>
    <div>  
        <Modal nameForAction="finrreestrActionFormModel"> 
            <div class="pb-10 px-10 pe-10">
                <div><b>{{ formHeader}}</b></div>
            </div>
            <div class="p-10">
                <BaseInput placeholder="" title="" @onInput="updateValues" v-if="action != 'transit'"
                    name-sign="ФИО" input-name="finreestr__contragent">
                </BaseInput>
                
                <BaseInput placeholder="" title="" @onInput="updateValues"
                    name-sign="Сумма" input-name="finreestr__amount">
                </BaseInput>
                
                <BaseTextarea placeholder="" title="" @onInput="updateValues"
                    name-sign="Основание" input-name="finreestr__basis">
                </BaseTextarea>

                <template v-if="action != 'transit'">
                    <div>
                        <span>Тип платежа</span>
                        <BaseSelect @open="getFunds()" :options="funds" placeholder="" label="finreestr_funds__name" @select="selectFund" v-model="selectedFund">
                            <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                    </div>
                </template>

                <template v-if="action == 'transit'">
                    <div>
                        <span>Из фонда</span>
                        <BaseSelect @open="getTransitFunds('from')" :options="fromFunds" placeholder="" label="finreestr_funds__name" @select="selectFundFrom" v-model="selectedFromFund">
                            <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                    </div>

                    <div>
                        <span>В фонд</span>
                        <BaseSelect @open="getTransitFunds('to')" :options="toFunds" placeholder="" label="finreestr_funds__name" @select="selectFundTo" v-model="selectedToFund">
                            <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                    </div>
                </template>

       
                
                
                
                <div class="d-flex gap-10 mt-15">
                    <div class="btn btn-sm btn-primary" @click="sendData">{{
                        dataLang.form_btn__btn_save }}</div>
                    <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                        dataLang.form_btn__btn_caneсl }}</div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
    import Modal from '@/components/Modals.vue'
    import BaseTextarea from "@/components/ui/BaseTextarea.vue";
    import EventBus from '@/event-bus.js'
import FundsService from "@/api/FundsService";

    export default {
        props:['action'],
        components: {
            Modal
        },
        data(){
            return {
                updatedValues: {
                    finreestr__action:"coming"
                },

                selectedFund: null,
                selectedFromFund:null,
                selectedToFund: null,
                funds: [],
                fromFunds: [],
                toFunds: []
            }
        },

        methods :{
            getFunds(data){
                switch(this.action){
                    case 'coming':
                        this.selectedFund = null;
                        FundsService.getComingFunds().then(response => {
                            response = response.data.data;
                            if(response && response.rows){
                                this.funds = response.rows
                            }
                        });
                        break;
                    case 'spend':
                        this.selectedFund = null;
                        FundsService.getSpendFunds().then(response => {
                            response = response.data.data;
                            if(response && response.rows){
                                this.funds = response.rows
                            }
                        });
                }
            },

            getTransitFunds(type){
                switch(type){
                    case 'from':
                        FundsService.getTransitFunds(this.selectedToFund).then(response => {
                            response = response.data.data;
                            if(response && response.rows){
                                this.fromFunds = response.rows
                            }
                        });
                        break;
                    case 'to':
                        FundsService.getTransitFunds(this.selectedFromFund).then(response => {
                            response = response.data.data;
                            if(response && response.rows){
                                this.toFunds = response.rows
                            }
                        });
                        break;
                }
            },

            updateValues(data) {
                if(this.action)
                    this.updatedValues['finreestr__action'] = this.action;

                this.updatedValues[data.name] = data.value
            },

            ...mapActions("finreestrModule", ['createFinreestrData', 'editFinreestrMultiple']),
      

            sendData(){
                this.createFinreestrData(this.updatedValues);
               
                EventBus.$emit("closeModal", { nameForAction: 'finrreestrActionFormModel' })
            },

            btnHandlerCloseModal(nameModal) {
                EventBus.$emit("closeModal", { nameForAction: 'finrreestrActionFormModel' })
            },

            selectFund(data){
                this.updatedValues['finreestr__type_payment_id'] = data.finreestr_funds__id;
            },

            selectFundFrom(data){
                this.updatedValues['finreestr__from_fund_id'] = data.finreestr_funds__id;
            },

            selectFundTo(data){
                this.updatedValues['finreestr__to_fund_id'] = data.finreestr_funds__id;
            },
        },

        computed: {
            ...mapState("translationModule", ["dataLang"]),
            
            formHeader(){
                switch (this.action) {
                    case "coming":
                        return "Добавить прочий приход";
                        break;
                    case "spend":
                        return "Добавить прочий расход";
                        break;
                    case "transit":
                        return "Транзит";
                    default:
                        return "Добавить прочий приход"
                        break;
                }
            }
        }
    }
</script>