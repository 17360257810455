var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination-container"},[(_vm.showLimitInput)?_c('div',{staticClass:"d-flex align-items-center gap-5"},[_c('span',{staticClass:"fsc-12"},[_vm._v("Выводить по")]),_c('div',[_c('BaseSelect',{staticStyle:{"width":"50px"},attrs:{"placeholder":"","selectLabel":"","deselectLabel":"","id":"city","label":"label","options":[
          { label: 10, value: 10 },
          { label: 50, value: 50 },
          { label: 100, value: 100 }
        ]},on:{"input":_vm.setSelect},model:{value:(_vm.limitValue),callback:function ($$v) {_vm.limitValue=$$v},expression:"limitValue"}})],1),_c('span',{staticClass:"fsc-12"},[_vm._v("записей")])]):_vm._e(),_c('div',{staticClass:"pagination-info"},[_vm._v(" Показано "+_vm._s(_vm.paginationParams.count)+" из "+_vm._s(_vm.paginationParams.total)+" ")]),(!_vm.noLiSurround)?_c('ul',{class:_vm.containerClass},[(_vm.firstLastButton)?_c('li',{class:[_vm.pageClass, _vm.firstPageSelected() ? _vm.disabledClass : '']},[_c('a',{class:_vm.pageLinkClass,attrs:{"tabindex":_vm.firstPageSelected() ? -1 : 0},on:{"click":function($event){return _vm.selectFirstPage()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectFirstPage()}}})]):_vm._e(),(!(_vm.firstPageSelected() && _vm.hidePrevNext))?_c('li',{staticClass:"page-item",class:[
        _vm.prevClass,
        _vm.firstPageSelected() ? _vm.disabledClass : '',
        _vm.pageClass
      ]},[_c('a',{class:[_vm.prevLinkClass, _vm.pageLinkClass],attrs:{"tabindex":_vm.firstPageSelected() ? -1 : 0},on:{"click":function($event){return _vm.prevPage()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.prevPage()}}},[_c('svg',{attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.78065 0.28186L9.7908 1.29897L6.01016 5.10566L9.7908 8.91235L8.78065 9.92946L3.98986 5.10566L8.78065 0.28186ZM5.20922 0.28186L6.21937 1.29897L2.43874 5.10566L6.21937 8.91235L5.20922 9.92946L0.41843 5.10566L5.20922 0.28186Z","fill":"#1D1E2C"}})])])]):_vm._e(),_vm._l((_vm.pages),function(page){return _c('li',{class:[
        _vm.pageClass,
        page.selected ? _vm.activeClass : '',
        page.disabled ? _vm.disabledClass : '',
        page.breakView ? _vm.breakViewClass : ''
      ]},[(page.breakView)?_c('a',{class:[_vm.pageLinkClass, _vm.breakViewLinkClass],attrs:{"tabindex":"0"}},[_vm._t("breakViewContent",function(){return [_vm._v(_vm._s(_vm.breakViewText))]})],2):(page.disabled)?_c('a',{class:_vm.pageLinkClass,attrs:{"tabindex":"0"}},[_vm._v(_vm._s(page.content))]):_c('a',{class:_vm.pageLinkClass,attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.handlePageSelected(page.index + 1)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handlePageSelected(page.index + 1)}}},[_vm._v(_vm._s(page.content))])])}),(!(_vm.lastPageSelected() && _vm.hidePrevNext))?_c('li',{staticClass:"page-item",class:[_vm.nextClass, _vm.lastPageSelected() ? _vm.disabledClass : '', _vm.pageClass]},[_c('a',{class:[_vm.prevLinkClass, _vm.pageLinkClass],attrs:{"tabindex":_vm.lastPageSelected() ? -1 : 0},on:{"click":function($event){return _vm.nextPage()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextPage()}}},[_c('svg',{attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M1.21938 0.28186L0.209229 1.29897L3.98987 5.10566L0.209229 8.91235L1.21938 9.92946L6.01017 5.10566L1.21938 0.28186ZM4.79081 0.28186L3.78066 1.29897L7.5613 5.10566L3.78066 8.91235L4.79081 9.92946L9.5816 5.10566L4.79081 0.28186Z","fill":"#1D1E2C"}})])])]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }