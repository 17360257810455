<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>
      <div class="d-flex justify-content-between align-items-center my-15">
        <div class="d-flex gap-15 align-items-center">
          <div
            class="base-button base-button--action"
            @click="$modal.show('addIntegrator')"
          >
            <img
              :src="require(`@/assets/icons/add.svg`)"
              width="13.5"
              height="13.5"
            />
            <span class="fsc-14">{{ dataLang['registry_ads_text1'] }}</span>
          </div>
          <div
            class="base-button base-button--action"
            @click="deleteActionAndModal"
          >
            <img
              :src="require(`@/assets/icons/adddel.svg`)"
              width="13.5"
              height="13.5"
            />
            <span class="fsc-14">{{ dataLang['registry_ads_text2'] }}</span>
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationIntegrationsYandexrentStateParams"
          :showLimitInput="false"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="overflow-auto">
        
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="integrators__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          :checkbox-option="{
            hideSelectAll: true,
            selectedRowChange: selectedRowChange
          }"
          v-if="!localLoading"
          :clickHighlight="false"
          :cell-style-option="{}"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>
    <BaseModal
      name="acceptDelete"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDelete')"
          ></button>
        </div>
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">
            {{ dataLang.confirm_btn__btn_yes }}
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="addIntegrator"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="closemodal"
      :adaptive="true"
      :height="260"
      classes="bg-transparent shadow-none"
    >
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white h-100"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('addIntegrator')"
          ></button>
        </div>
        <p class="text-center fw-semi-bold mb-0">
          {{ dataLang.integrator_register_text_5 }}
        </p>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input pb-50"
        >
          <div
            class="d-flex align-items-center justify-content-between wp-100 mb-15" v-if="!currentId"
            ref="integrators__company_id"
          >
            <span class="fsc-11 input-title">{{
              dataLang.integrator_register_text_3
            }}</span>
            <BaseSelect
              placeholder=""
              selectLabel=""
              style="width: 253px"
              deselectLabel=""
              @input="
                (data) => {
                  updateValue({
                    name: 'integrators__company_id',
                    value: data.companies__id
                  })
                }
              "
              v-model="selectsFromModal.name"
              :options="selectsData.name"
              label="companies__name"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between wp-100"
            ref="integrators__percent"
          >
            <span class="fsc-11 input-title">{{
              dataLang.integrator_register_text_4
            }}</span>
            <BaseSelect
              placeholder=""
              selectLabel=""
              style="width: 253px"
              deselectLabel=""
              @input="
                (data) => {
                  updateValue({
                    name: 'integrators__percent',
                    value: data.value
                  })
                }
              "
              v-model="selectsFromModal.percent"
              :options="[
                {
                  value: 1
                },
                {
                  value: 2
                }
              ]"
              label="value"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div class="d-flex flex-column align-items-center mt-15">
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="createorEdit"
                class="btn btn-sm btn-opposite text-uppercase px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['integrator_register_text_6'] }}
              </div>
              <div
                @click="$modal.hide('addIntegrator')"
                class="btn btn-sm btn-opposite text-uppercase px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['integrator_register_text_7'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="acceptDeleteWithPin"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="pincodeModalHandler"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteWithPin')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">Внимание!</p>

          <div class="d-flex flex-column align-items-center">
            <!-- <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('acceptDeleteWithPin')"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div> -->
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              :input-name="'pincode'"
              @input="(data) => (pincode = data.value)"
              value=""
              name-sign=""
            >
            </BaseInputNew>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('acceptDeleteWithPin')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="deleteRow"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_save'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'

export default {
  components: {
    VeTable
  },

  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      nameValue: '',
      activePagePagination: {},
      currentId: null,
      pincode: '',
      selectsFromModal: {
        name: [],
        percent: []
      },
      selectsData: {
        name: [],
        percent: []
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('addIntegrator')
              this.currentId = row.integrators__id
              this.selectsFromModal.name = [
                {
                  integrators__company_id: row.integrators__company_id,
                  companies__name: row.company_settings__set_req_company_name
                }
              ]

              // this.selectsData.name.find(
              //   (x) => x.companies__id == row.integrators__company_id
              // )
              this.selectsFromModal.percent = [
                { value: row.integrators__percent }
              ]
            }
          }
        }
      }
    }
  },
  methods: {
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    saveIntegrator() {},

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
    },

    createorEdit() {
      if (this.currentId) {
        GeneralService.edit(`/integrators/${this.currentId}`, {
          ...this.updatedValues
        })
          .then((data) => {
            this.$modal.hide('addIntegrator')
            this.loadData(1)
            console.log(data)
          })
          .catch((e) => {
            this.loadData(1)
            console.log(e)
          })
      } else {
        GeneralService.editOrCreate(`/integrators`, {
          ...this.updatedValues
        })
          .then((data) => {
            this.$modal.hide('addIntegrator')
            this.loadData(1)
            console.log(data)
          })
          .catch((e) => {
            this.loadData(1)
            console.log(e)
          })
      }
    },
    closemodal() {
      this.currentId = null
      this.updatedValues = {}
      this.activeSelectRowsMultiple = []
    },
    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
    },
    deleteRow(nameModal) {
      GeneralService.delete(`/integrators/` + this.activeSelectRowsMultiple, {
        ...(this.pincode && { pincode: this.pincode })
      })
        .then((data) => {
          this.loadData(1)
          console.log(data);
          this.$toast.success(data.data.message
          )
          this.$modal.hide('acceptDeleteWithPin')
          this.$modal.hide('acceptDelete')
        })
        .catch((e) => {
          console.log(e)
          if (e.response.status == 409) {
            this.$modal.show('acceptDeleteWithPin')
            this.$toast.error(e.response.data.message)
          } else if (e.response.status == 400) {
            this.$toast.error(e.response.data.message)
          }
        })
    },
    loadData(page) {
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/integrators/table/main/select`)
          .then((data) => {
            console.log(data)

            this.selectsData.name = data.data.data
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка')
          })
      ]).then(() => {
        GeneralService.get(`/integrators?joins=company`)
          .then((data) => {
            console.log(data)
            this.getIntegrationsYandexrentAll(data.data.data)
            this.dataTable = data.data.data.rows
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка')
          })
      })
    },

    ...mapActions('integrationsYandexrentModule', [
      'getIntegrationsYandexrentAll'
    ])
  },
  mounted() {},
  created() {
    Promise.all([
      //   }),
    ]).then(() => {
      this.loadData(1)
      //   GeneralService.editOrCreate(`/integrators`, {
      //     integrators__company_id: 1,
      //     integrators__percent: 2
      //   })
      //     .then((data) => {
      //       console.log(data)
      //     })
      //     .catch((e) => {
      //       console.log(e)
      //       this.$toast.error('Неизвестная ошибка')
      //     })
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),

    ...mapState('integrationsYandexrentModule', [
      'paginationIntegrationsYandexrentStateParams'
    ]),
    ...mapGetters('integrationsYandexrentModule', ['numPages']),
    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: 5,
          align: 'center'
        },
        {
          field: 'company_settings__set_req_company_name',
          key: '12',
          title: this.dataLang['integrator_register_text_3'],
          align: 'center',
          width: '95%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },

        {
          field: 'integrators__percent',
          key: '13',
          title: this.dataLang['integrator_register_text_4'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        }
      ]
    }
  }
}
</script>

<style>
.client-status-danger {
  background-color: #ff2400 !important;
  color: white !important;
}
</style>
