<template>
        <label class="base-checkbox">
            <input type="checkbox" class="base-checkbox__input" :checked="Boolean(onchecked)" @change="changeHandler">
            <div class="base-checkbox__fill-square base-checkbox__fill-square--active-input"></div>
            <span class="base-checkbox__sign">
                <slot>Лучший чекбокс</slot>
            </span>
        </label>
</template>


<script>
export default {
    name: 'base-checkbox',
    props: {
        onchecked: {
            default: false,
           
        },
        inputName:{
            default: ''
        }
    },
   
    methods: {
        changeHandler () {
            this.$emit('oncheckeds', {name: this.inputName, value: !Boolean(this.onchecked)})
        }
    }
}
</script>