<template>
  <div
    class="base-container-input--new"
    :class="[
      horizontalTitle
        ? 'd-flex align-items-center gap-5 justify-content-between'
        : '',
      options.length ? 'position-relative' : ''
    ]"
    :ref="inputRef"
  >
    <div class="base-container-input-title" v-if="nameSign">
      <span
        :class="[disabled ? 'text-disabled' : '', signClass ? signClass : '']"
        class="input-title"
        >{{ nameSign }}</span
      >
    </div>
    <input
      class="base-input--new"
      :class="inputClass"
      :style="{ width: `${width}px` }"
      :type="inputType"
      @input="onInput"
      :placeholder="placeholder"
      @paste="onInput"
      :value="value"
      :disabled="disabled"
      @focus="openSelect(true)"
      :maxlength="maxLength"
      v-click-outside="outsideClickHandler"
    />
    <div
      class="base-input-options"
      :class="{
        'base-input-options--show': selectOpened
      }"
    >
      <div
        class="base-input-options__item"
        v-for="(item, index) in options"
        :key="index"
        @click="
          () => {
            $emit('selectData', {
              name: inputName,
              value: item,
              type: 'select',
              ...additionalParams
            })
          }
        "
      >
        {{ item?.[selectKey] }}
      </div>
    </div>
  </div>
</template>

<script>
import IMask from 'imask'
export default {
  components: {},
  props: {
    selectFocusmethod: {
      type: String,
      default: 'focus'
    },
    options: {
      type: Array,
      default: () => []
    },
    selectKey: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: ''
    },
    inputRef: {
      default: 'baseInput'
    },
    additionalParams: {
      default: () => {}
    },
    inputName: {
      type: String
    },
    nameSign: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    width: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text'
    },

    disabled: {
      default: false
    },

    inputMask: {
      default: false
    },
    horizontalTitle: {
      type: Boolean,
      default: false
    },
    signClass: {
      type: String
    },
    maxLength: {
      type: String
    }
  },
  data() {
    return {
      masked: null,
      selectOpened: false
    }
  },

  methods: {
    outsideClickHandler(e) {
      if (this.options.length) {
        this.selectOpened = false
      }
    },
    selectData(data) {
      console.log(data)
      this.$emit('selectData', {
        name: this.inputName,
        value: data,
        type: 'select',
        ...this.additionalParams
      })

      this.selectOpened = false
    },
    openSelect(state) {
      if (this.selectFocusmethod == 'focus') {
        setTimeout(() => {
          this.selectOpened = state
        }, 1)
      }
    },
    onInput(event) {
      console.log(event.target.value, event)
      this.$emit('input', {
        name: this.inputName,
        value: event.target.value,
        ...this.additionalParams
      })
    }
  },
  created() {
    // if (this.selectFocusmethod == 'focus') {
    //   this.$refs.inputRef.addEventListener('focus', () => {
    //     this.selectOpened = true
    //   })
    // }
    // if (this.selectFocusmethod == 'input') {
    //   this.$refs.inputRef.addEventListener('input', () => {
    //     this.selectOpened = true
    //   })
    // }
  },

  mounted() {
    if (this.selectFocusmethod == 'input') {
      this.$refs[this.inputRef].addEventListener('input', () => {
        this.selectOpened = true
      })
    }
    this.masked = IMask.createMask({
      mask: '+{7}(000)000-00-00'
    })
    if (this.inputMask) {
      let element = null
      let maskOptions = {}
      if (typeof this.inputMask === 'string') {
        element = this.$refs[this.inputRef].querySelector('input')
        maskOptions = {
          mask: this.inputMask
        }
      } else {
        element = this.$refs[this.inputRef].querySelector('input')
        maskOptions = {
          mask: '+{7}(000)000-00-00'
        }
      }

      const mask = IMask(element, maskOptions)
    }
  }
}
</script>
