<template>
  <div>
    <BaseTopLine>
      <div class="d-flex gap-10 align-items-center">
        <div
          class="btn btn-sm btn-primary"
          @click="openModalForCreate('entity')"
        >
          Добавить ЮР. лицо
        </div>
        <div
          class="btn btn-sm btn-primary"
          @click="openModalForCreate('individual')"
        >
          Добавить физическое лицо
        </div>
        <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">
          Удалить
        </div>
      </div>
    </BaseTopLine>
    <div class="p-20">
      <BasePagination
        :page-count="numPages"
        :page-range="3"
        :margin-pages="2"
        :break-view-class="'d-none'"
        :click-handler="clickLoadData"
        :prev-text="'Назад'"
        :next-text="'Далее'"
        :container-class="'pagination pagination-sm'"
        :page-class="'page-item'"
        :pageLinkClass="'page-link'"
      >
      </BasePagination>

      <BaseReuseTable
        :props-data="ownersData"
        :props-columns-setting="fieldsTableOwners"
        :selectRowActivateFlag="true"
        v-if="loadStatus == 'success'"
        :callbackForModal="callbackForModal"
      >
      </BaseReuseTable>
    </div>
    <Teleport to="body">
      <Modal nameForAction="entity" ref="entity" @closeModal="closeModal">
        <div class="p-10">
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__organization"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__organiz"
            input-name="vehicle_owner_ur__organization"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owners__name"
            name-sign="Лицо ФИО"
            input-name="vehicle_owners__name"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__titlejob"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__jobtitle"
            input-name="vehicle_owner_ur__titlejob"
          >
          </BaseInput>
          <!-- <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__in_face"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__in_face"
            input-name="vehicle_owner_ur__in_face"
          >
          </BaseInput> -->
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__acts_on"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__acts_on"
            input-name="vehicle_owner_ur__acts_on"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__ogrn"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__ogrn"
            input-name="vehicle_owner_ur__ogrn"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owners__address"
            :name-sign="dataLang.form_ur__vehicle_owners__address"
            input-name="vehicle_owners__address"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__inn"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__inn"
            input-name="vehicle_owner_ur__inn"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__kpp"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__kpp"
            input-name="vehicle_owner_ur__kpp"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__bik"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__bik"
            input-name="vehicle_owner_ur__bik"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__ks"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__ks"
            input-name="vehicle_owner_ur__ks"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__rs"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__rs"
            input-name="vehicle_owner_ur__rs"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_ur__bank"
            :name-sign="dataLang.form_ur__vehicle_owners_ur__bank"
            input-name="vehicle_owner_ur__bank"
          >
          </BaseInput>
          <!-- <BaseInput placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.vehicle_owner_ur__organiz"
                        :name-sign="dataLang.form_ur__vehicle_owners_ur__organiz" input-name="vehicle_owner_ur__organiz">
                    </BaseInput> -->
          <!-- <BaseInput placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.vehicle_owner_fiz__passport"
                        :name-sign="dataLang.form_fiz__vehicle_owners_fiz__passport"
                        input-name="vehicle_owner_fiz__passport">
                    </BaseInput> -->
          <!-- <BaseInput placeholder="" title="" @onInput="updateValue" :value="dataForModal.vehicle_owners__address"
                        :name-sign="dataLang.form_fiz__vehicle_owners__address" input-name="vehicle_owners__address">
                    </BaseInput> -->
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owners__phone"
            :name-sign="dataLang.form_fiz__vehicle_owners__phone"
            input-name="vehicle_owners__phone"
            inputRef="vehicle_owners__phone"
            :inputMask="true"
            :maxLength="17"
          >
          </BaseInput>
          <div class="d-flex gap-10 mt-15">
            <div
              class="btn btn-sm btn-primary"
              @click="sendDataFromModal('entity', 1)"
            >
              {{ dataLang.form_btn__btn_save }}
            </div>
            <div
              class="btn btn-sm btn-opposite"
              @click="btnHandlerCloseModal('entity')"
            >
              {{ dataLang.form_btn__btn_caneсl }}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        nameForAction="individual"
        ref="individual"
        @closeModal="closeModal"
      >
        <div class="p-10">
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owners__name"
            :name-sign="dataLang.form_fiz__vehicle_owners__name"
            input-name="vehicle_owners__name"
          >
          </BaseInput>
          <BaseInput
            placeholder="0000 000000"
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owner_fiz__passport"
            :name-sign="dataLang.form_fiz__vehicle_owners_fiz__passport"
            input-name="vehicle_owner_fiz__passport"
            inputRef="vehicle_owner_fiz__passport"
            inputMask="0000 000000"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owners__address"
            :name-sign="dataLang.form_fiz__vehicle_owners__address"
            input-name="vehicle_owners__address"
          >
          </BaseInput>
          <BaseInput
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.vehicle_owners__phone"
            :name-sign="dataLang.form_fiz__vehicle_owners__phone"
            input-name="vehicle_owners__phone"
            inputRef="form_fiz__vehicle_owners__phone"
            :maxLength="17"
            :inputMask="true"
          >
          </BaseInput>
          <div class="d-flex gap-10 mt-15">
            <div
              class="btn btn-sm btn-primary"
              @click="sendDataFromModal('individual', 2)"
            >
              {{ dataLang.form_btn__btn_save }}
            </div>
            <div
              class="btn btn-sm btn-opposite"
              @click="btnHandlerCloseModal('individual')"
            >
              {{ dataLang.form_btn__btn_caneсl }}
            </div>
          </div>
        </div>
      </Modal>
      <Modal nameForAction="acceptDelete">
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">
            Да
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
export default {
  components: {
    Modal
  },

  props: {},
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      editData: {},
      activeSelectRow: null
    }
  },
  methods: {
    clickLoadData(numPage) {
      this.getAll({
        limit: 10,
        page: numPage
      })
    },
    openModalForCreate(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create'
      })
    },

    deleteActionAndModal() {
      if (this.activeSelectRow) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDelete',
          mode: 'create'
        })
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    deleteRow(nameModal) {
      this.deleteData(this.activeSelectRow)
      this.activeSelectRow = null
      EventBus.$emit('closeModal', { nameForAction: nameModal })
    },

    sendDataFromModal(nameModal, vehicleOwnersType) {
      if (this.dataForModal.vehicle_owners__id) {
        console.log(this.dataForModal.vehicle_owners__id, this.updatedValues)
        this.editMultiple({
          id: this.dataForModal.vehicle_owners__id,
          dataEdit: this.updatedValues
        })
        EventBus.$emit('closeModal', { nameForAction: nameModal })
      } else {
        this.createData(
          Object.assign(this.updatedValues, {
            vehicle_owners__type_id: vehicleOwnersType
          })
        )
        this.$refs[nameModal].$el.querySelectorAll('input').forEach((elem) => {
          elem.value = ''
        })
        EventBus.$emit('closeModal', { nameForAction: nameModal })
      }
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
    },

    prepareKeysForModal(payloadData) {
      this.updatedValues = {}
      this.dataForModal = {}
      if (!payloadData) {
        console.log('эданных нет')
        for (const key in this.ownersData[0]) {
          this.dataForModal[key] = null
        }
      } else {
        this.dataForModal = Object.assign({}, payloadData)
      }
    },

    closeModal(data) {
      this.activeSelectRow = {}
      this.dataForModal = {}
      this.$refs[data.nameForAction].$el
        .querySelectorAll('input')
        .forEach((element) => {
          element.value = ''
        })
    },
    btnHandlerCloseModal(nameModal) {
      console.log(nameModal)
      EventBus.$emit('closeModal', { nameForAction: nameModal })
    },

    ...mapActions('ownersModule', [
      'getAll',
      'createData',
      'editMultiple',
      'deleteData'
    ]),
    callbackForModal(e, row) {
      this.updatedValues = {}
      if (row.getData().vehicle_owners__type_id == 1) {
        EventBus.$emit('openModal', {
          data: row.getData(),
          nameForAction: 'entity',
          mode: 'edit'
        })
      } else {
        EventBus.$emit('openModal', {
          data: row.getData(),
          nameForAction: 'individual',
          mode: 'edit'
        })
      }
    }
  },
  mounted() {
    EventBus.$on('openModal', (payload) => {
      this.editData = payload.data
      console.log(payload.data)
      this.prepareKeysForModal(payload.data)
    })
    EventBus.$on('selectTableRows', (payload) => {
      this.activeSelectRow = payload.data.vehicle_owners__id
    })
    EventBus.$on('deselectTableRows', (payload) => {
      this.activeSelectRow = null
    })
  },

  created() {
    this.getAll({ page: 1, limit: 100 })
  },
  computed: {
    ...mapState('ownersModule', [
      'ownersData',
      'loadStatus',
      'paginationStateParams'
    ]),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('ownersModule', ['numPages']),

    // page: {
    //     get() {
    //         return this.paginationStateParams.page
    //     },
    //     set(page) {
    //         console.log(page);
    //         this.getAll({page: page , limit: 10})
    //     }
    // },

    fieldsTableOwners() {
      return [
        {
          title: 'ID',
          field: 'vehicle_owners__id',
          headerFilter: 'input',
          hozAlign: 'center'
        },
        {
          title: this.dataLang['form_fiz__vehicle_owners__name'],
          field: 'vehicle_owners__name',
          headerFilter: 'input'
        },
        {
          title: this.dataLang['form_fiz__vehicle_owners__phone'],
          field: 'vehicle_owners__phone',
          headerFilter: 'input'
        },
        {
          title: 'Тип',
          field: 'vehicle_owner_types__name',
          headerFilter: 'input'
        },
        {
          title: 'Адрес',
          field: 'vehicle_owners__address',
          headerFilter: 'input'
        }
      ]
    }
  }
}
</script>
