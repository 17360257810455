<template>
  <div class="base-container-input" :ref="inputRef">
    <div class="base-container-input-title" v-if="nameSign">
      <span>{{ nameSign }}</span>
    </div>
    <slot></slot>
    <input
      class="base-input"
      :type="inputType"
      @input="onInput"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      :style="inputStyle"
      :maxlength="maxLength"
    />
  </div>
</template>

<script>
import IMask from 'imask'
export default {
  components: {},
  props: {
    inputRef: {
      default: null
    },
    inputName: {
      type: String
    },
    nameSign: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },

    inputType: {
      type: String,
      default: 'text'
    },

    disabled: {
      default: false
    },

    inputMask: {
      default: false
    },
    maxLength: {
      type: Number | String
    },
    inputStyle: {
      type: String
    }
  },

  methods: {
    onInput(event) {
      this.$emit('onInput', { name: this.inputName, value: event.target.value })
    }
  },

  mounted() {
    if (this.inputMask) {
      let element = null
      let maskOptions = {}
      if (typeof this.inputMask === 'string') {
        element = this.$refs[this.inputRef].querySelector('input')
        maskOptions = {
          mask: this.inputMask
        }
      } else {
        element = this.$refs[this.inputRef].querySelector('input')
        maskOptions = {
          mask: '+{7}(000)000-00-00'
        }
      }

      const mask = IMask(element, maskOptions)
    }
  }
}
</script>
