<template>
  <form class="access-layout">
    <div
      class="access-wrapper"
      ref="wrapperTape"
      :class="[statusReg ? 'access-wrapper--active' : '']"
    >
      <div class="access-tape">
        <div class="access-block">
          <span class="access-block__title">Регистрация</span>
          <div>
            <div class="mb-10 mt-10">
              <input
                placeholder="Email*"
                v-model="email"
                type="text"
                class="form-control wp-100"
              />
              <BaseFieldError :errors-data="errorsData.email"></BaseFieldError>
            </div>
            <div class="mb-10">
              <input
                placeholder="Телефон*"
                v-model="telephone"
                type="text"
                class="form-control wp-100"
                :class="{ 'error-field': errorsData.phone }"
                ref="phone"
                maxlength="16"
              />
              <BaseFieldError :errors-data="errorsData.phone"></BaseFieldError>
            </div>
            <!-- <div class="mb-10">

              <select name="" class="form-select wp-100" id="">
                <option value="Россия">Россия</option>
                <option value="Китай">Китай</option>
              </select>
              <input placeholder="" type="text" class="form-control wp-100" />
            </div> -->
            <!-- <BaseCheckbox :onchecked="false" @oncheckeds="toggleSwitch">У меня есть промокод</BaseCheckbox> -->
            <!-- <label class="base-checkbox">
              <input
                type="checkbox"
                class="base-checkbox__input"
                @change="onchecked"
              />
              <div
                class="base-checkbox__fill-square base-checkbox__fill-square--active-input"
              ></div>
              <span class="base-checkbox__sign"> Промокод </span>
            </label> -->

            <!-- <div class="mb-10 mt-10">
              <input
                placeholder="Промокод"
                v-if="checked"
                v-model="integrator"
                type="text"
                class="form-control wp-100"
              />
              <BaseFieldError
                :errors-data="errorsData.integrator_id"
              ></BaseFieldError>
            </div> -->
            <span
              class="access-block__desc access-block__desc--warn-active"
              ref="descreg"
            ></span>
            <span
              class="access-block__desc access-block__desc--warn-active"
              v-for="(item, index) in regMessage"
              :key="index"
              v-if="regMessage"
              >{{ item[0] }} <br
            /></span>
            <div class="mt-5">
              <div class="btn btn-primary py-10 wp-100" @click="forwardReg">
                Регистрация
              </div>
            </div>
            <div class="d-flex flex-column gap-10 justify-content-center">
              <a href="/auth">Авторизация</a>
              <a href="/recovery-password">Восстановление пароля</a>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="access-tape">
        <div class="access-block">
          <span class="access-block__title">На указанную вами почту отправлен логин и пароль для входа</span>
          <div>
            <div class="mt-10">
              <div class="btn btn-outline-secondary py-10 wp-100" @click="backReg">Изменить данные</div>
            </div>
            <div class="mt-5">
              <router-link to="/auth" class="btn btn-primary py-10 wp-100">Войти</router-link>
            </div>
          </div>
        </div>
      </div> -->
      <BaseModal
        name="loadingReg"
        width="420"
        :clickToClose="false"
        :shiftY="0.5"
        :adaptive="true"
        height="auto"
        classes="bg-transparent shadow-none"
      >
        <div class="bg-white rounded-25 py-25 px-20">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              @click="$modal.hide('loadingReg')"
            ></button>
          </div>
          <p class="fs-5 text-center">Идет создание аккаунта</p>
        </div>
      </BaseModal>
      <BaseModal
        name="successReg"
        width="420"
        :clickToClose="false"
        :shiftY="0.5"
        :adaptive="true"
        height="auto"
        classes="bg-transparent shadow-none"
      >
        <div class="bg-white rounded-25 py-25 px-20">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              @click="$modal.hide('successReg')"
            ></button>
          </div>
          <p class="fs-5 text-center">
            Спасибо за регистрацию!<br />
            Ваш пароль отправлен на почту, которую вы указали
          </p>
        </div>
      </BaseModal>
      <Teleport to="body">
        <!-- <Modal name-for-action="loadingReg">
          <p class="fs-5 text-center">Идет создание аккаунта</p>
        </Modal> -->
        <Modal name-for-action="successReg">
          <p class="fs-5 text-center">
            Спасибо за регистрацию!<br />
            Ваш пароль отправлен на почту, которую вы указали
          </p>
        </Modal>
      </Teleport>
    </div>
  </form>
</template>

<script>
import BaseFieldError from '@/components/ui/BaseFieldError.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import Teleport from 'vue2-teleport'
import IMask from 'imask'
export default {
  components: { BaseFieldError, Teleport, Modal },
  props: {},
  data() {
    return {
      email: null,
      telephone: null,
      enterCodeAccess: null,
      codeAccess: null,
      checked: false,
      promocode: null,
      integrator: null
    }
  },
  methods: {
    onchecked() {
      this.checked = !this.checked
      console.log(this.checked)
    },
    ...mapActions('accessModule', ['reg', 'auth']),
    callbackForModal() {
      EventBus.$emit('closeModal', { nameForAction: 'successReg' })
    },
    async forwardReg() {
      if (this.email && this.telephone) {
        this.$modal.show('loadingReg')
        this.$refs.descreg.textContent = ''
        await this.reg({
          authData: {
            login: this.email,
            phone: this.telephone,
            integrator_id: this.integrator
            // callback: this.callbackForModal
          },
          callback: (stat) => {
            if (stat == 'success') {
              this.$modal.hide('loadingReg')
              this.$modal.show('successReg')
            } else {
              this.$modal.hide('loadingReg')
              this.$modal.hide('successReg')
            }

            this.$modal.hide('successReg')
          }
        })
      } else {
        this.$refs.descreg.textContent = 'Заполните все обязательные поля'
      }
    },
    backReg() {
      this.$refs.wrapperTape.classList.remove('access-wrapper--active')
    }
    // async nextStep() {
    //   let response = await fetch('/settings', {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json;charset=utf-8',
    //       'Authorization': 'Bearer axios'
    //     },
    //   });
    //   let data = await response.json()
    //   console.log(data)
    // }
  },
  mounted() {
    IMask(this.$refs.phone, {
      mask: '+{7}(000)000-00-00'
    })
  },
  watch: {
    // whenever question changes, this function will run
    // loadingNow: function (newQuestion, oldQuestion) {
    //   EventBus.$emit('openModal', { nameForAction: 'loadingReg' })
    // },
  },
  computed: {
    ...mapState('accessModule', [
      'statusAuth',
      'reg_status',
      'statusReg',
      'loggedStatus',
      'authMessage',
      'regMessage',
      'errorsData',
      'loadingNow'
    ])
  }
}
</script>
