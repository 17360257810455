import { render, staticRenderFns } from "./RatingTab.vue?vue&type=template&id=91057a78&"
import script from "./RatingTab.vue?vue&type=script&lang=js&"
export * from "./RatingTab.vue?vue&type=script&lang=js&"
import style0 from "./RatingTab.vue?vue&type=style&index=0&id=91057a78&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports