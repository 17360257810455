export const finesPagination = {
  namespaced: true,
  state: () => ({
    paginationFinesStateParams: {
      count: 0,
      limit: 100,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: (state) =>
      Math.ceil(
        state.paginationFinesStateParams.total /
          state.paginationFinesStateParams.limit
      )
  },
  mutations: {
    update_pagination(state, { total, count, limit, page }) {
      state.paginationFinesStateParams = { total, count, limit, page }
    }
  },
  actions: {
    getFinesAll({ commit }, pagination) {
      commit('update_pagination', pagination)
    }
  }
}
