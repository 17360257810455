import GeneralService from '@/api/GeneralService'
import Vue from 'vue'
export const userSettingModule = {
  namespaced: true,
  state: () => ({
    userSetting: {},
    loadUserSetting: false,
    countNotices: 0
  }),

  getters: {},
  mutations: {
    updateUserSetting(state, data) {
      state.userSetting = data
    },
    loadUserSettings(state, data) {
      state.loadUserSetting = data
    },
    updateCountNotices(state, data) {
      state.countNotices = data
    }
  },
  actions: {
    getUserSetting({ commit, state }, data) {
      commit('updateUserSetting', data)
      commit('loadUserSettings', true)
    },
    setUserNotification({ commit }, data) {
      GeneralService.get('/notices', {
        params: {
          where: JSON.stringify([
            {
              field: 'notices_statuses__status',
              value: 'notification_status_new'
            }
          ])
        }
      }).then((data) => {
        console.log(data)
        commit('updateCountNotices', data.data.data.total)
      })
    }
  }
}
