<template>
  <div>
    <BaseTopLine>
      <div class="d-flex gap-10 align-items-center">
        <div
          class="btn btn-sm btn-primary"
          @click="openModalForCreate('contractsModal')"
        >
          Добавить
        </div>
        <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">
          Удалить
        </div>
      </div>
    </BaseTopLine>
    <div class="p-20">
      <BasePagination
        :page-count="numPages"
        :page-range="3"
        :margin-pages="2"
        :break-view-class="'d-none'"
        :click-handler="clickLoadData"
        :prev-text="'Назад'"
        :next-text="'Далее'"
        :container-class="'pagination pagination-sm'"
        :page-class="'page-item'"
        :pageLinkClass="'page-link'"
      >
      </BasePagination>

      <BaseReuseTable
        :props-data="contractsData"
        :props-columns-setting="fieldsTableContracts"
        :selectRowActivateFlag="true"
        :modalActivateFlag="true"
        nameForAction="contractsModal"
        v-if="contractsLoadStatus == 'success'"
      >
      </BaseReuseTable>
    </div>
    <Teleport to="body">
      <Modal nameForAction="contractsModal" size="xl" @closeModal="closeModal">
        <vue-tabs @tab-change="handleTabChange" v-model="activateTab">
          <v-tab title="Главная" data-name="main">
            <div class="pt-15">
              <div class="row gx-20">
                <div class="col-lg-3">
                  <div class="" style="">
                    <div style="background-color: #4898c4">
                      <span class="fw-bold text-white">Начало аренды</span>
                    </div>

                    <div class="">
                      <div class="d-flex gap-10 mt-10 mb-65">
                        <div>
                          <span> Дата </span>
                          <div>
                            <BaseDatePicker
                              v-model="dateTimeSelect.dateStartSelect"
                              type="date"
                              class="wp-100"
                              lang="ru"
                              value-type="format"
                              format="YYYY-MM-DD"
                              @change="changeDate"
                            ></BaseDatePicker>
                          </div>
                        </div>
                        <div>
                          <span> Время </span>
                          <div>
                            <BaseDatePicker
                              v-model="dateTimeSelect.timeStartSelect"
                              type="time"
                              class="wp-100"
                              lang="ru"
                              value-type="format"
                              format="HH:mm"
                              @change="changeDate"
                            ></BaseDatePicker>
                          </div>
                        </div>
                      </div>
                      <div>
                        <span>Выдача</span>
                        <BaseSelect
                          placeholder=""
                          selectLabel=""
                          deselectLabel=""
                          v-model="dataForSelect.dataDeliveryStart"
                          @input="setMainSelect"
                          id="deliveryStart"
                          label="place_deliveries__address"
                          :options="dataDeliveryOptions"
                        >
                          <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                      </div>
                      <div>
                        <BaseTextarea
                          placeholder=""
                          title=""
                          @onInput="updateValue"
                          :value="
                            dataForModal.contracts__start_delivery_comment
                          "
                          name-sign="Комментарий"
                          input-name="contracts__start_delivery_comment"
                        >
                        </BaseTextarea>
                      </div>
                    </div>
                    <span class="fw-bold">Показания при выдаче</span>
                    <div class="d-flex gap-10 mt-10">
                      <BaseInput
                        placeholder=""
                        title=""
                        @onInput="updateValue"
                        inputType="number"
                        :value="dataForModal.contracts__start_odometr"
                        name-sign="Одометр"
                        input-name="contracts__start_odometr"
                      >
                      </BaseInput>
                      <BaseInput
                        placeholder=""
                        title=""
                        @onInput="updateValue"
                        inputType="number"
                        :value="dataForModal.contracts__start_fuel"
                        name-sign="Топливо"
                        input-name="contracts__start_fuel"
                      >
                      </BaseInput>
                    </div>
                    <div v-if="washingData">
                      <span class="fw-bold">Осмотр при выдаче</span>
                      <div v-for="(item, index) in washingData" :key="index">
                        <span>{{ item.washing_prices__type }}</span>
                        <BaseSelect
                          placeholder=""
                          selectLabel=""
                          deselectLabel=""
                          v-model="
                            dataForWashingStartSelect[item.washing_prices__id]
                          "
                          @input="setStartWashingSelect"
                          :id="item.washing_prices__id"
                          label="label"
                          :options="selectsData.contract_washings__start_state"
                        >
                          <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                      </div>
                    </div>
                    <div class="mt-10">
                      <BaseCheckbox
                        @onCheckeds="updateValue"
                        input-name="contracts__start_is_done"
                        :checked="dataForModal.contracts__start_is_done"
                        >Авто выдано клиенту
                      </BaseCheckbox>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <div>
                      <div class="d-flex gap-20 p-10">
                        <div v-if="modeOpenModal == 'edit'">
                          <p class="mb-0">Баланс</p>
                          <span class="fw-bold">{{
                            dataForModal.clients__balance
                          }}</span>
                        </div>
                        <div v-if="dataForModal.contract_statuses__status">
                          <p class="mb-0">Статус договора</p>
                          <span class="fw-bold">{{
                            dataForModal.contract_statuses__status
                          }}</span>
                        </div>
                        <div v-if="dataForModal.employees__name">
                          <p class="mb-0">Текущий менеджер</p>
                          <span class="fw-bold">{{
                            dataForModal.employees__name
                          }}</span>
                        </div>
                        <div v-if="this.dataForModal.contractDuration">
                          <p class="mb-0">Длительность</p>
                          <span class="fw-bold"
                            >{{
                              this.dataForModal.contractDuration?.days
                            }}
                            дней</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="mt-10">
                      <span>Транспортное средство</span>
                      <BaseSelect
                        placeholder=""
                        selectLabel=""
                        deselectLabel=""
                        v-model="dataForSelect.dataTS"
                        :custom-label="
                          ({
                            vehicles__name,
                            vehicles__gosnumber,
                            vehicles__id
                          }) => {
                            return (
                              vehicles__name +
                              ` ` +
                              vehicles__gosnumber +
                              ` id${vehicles__id}`
                            )
                          }
                        "
                        @input="setMainSelect"
                        id="ts"
                        :options="dataTSOptions"
                      >
                        <div slot="no-options">Пока нет опций</div>
                      </BaseSelect>
                    </div>
                    <div class="d-flex gap-10">
                      <div class="wp-50">
                        <span> Тариф от </span>
                        <div>
                          <BaseDatePicker
                            v-model="dateTimeSelect.dateTariffFrom"
                            type="date"
                            class="wp-100"
                            lang="ru"
                            value-type="format"
                            format="YYYY-MM-DD"
                            @change="changeDate"
                          ></BaseDatePicker>
                        </div>
                      </div>
                      <div class="wp-50">
                        <BaseInput
                          class="wp-100"
                          placeholder=""
                          title=""
                          @onInput="updateValue"
                          :value="dataForModal.contracts__expected_mileage"
                          name-sign="Предварительный пробег"
                          input-name="contracts__expected_mileage"
                        >
                        </BaseInput>
                      </div>
                    </div>
                    <span class="fw-bold">Клиенты</span>
                    <div>
                      <span> Клиент </span>
                      <BaseSelect
                        placeholder=""
                        selectLabel=""
                        deselectLabel=""
                        v-model="dataForSelect.dataClient"
                        :custom-label="
                          ({ clients__phone, clients__name, clients__id }) => {
                            return (
                              clients__phone +
                              ` ` +
                              clients__name +
                              ` id` +
                              clients__id
                            )
                          }
                        "
                        @input="setMainSelect"
                        id="clients"
                        :options="dataClientOptions"
                      >
                        <div slot="no-options">Пока нет опций</div>
                      </BaseSelect>
                      <span> Водитель 1 </span>
                      <BaseSelect
                        placeholder=""
                        selectLabel=""
                        deselectLabel=""
                        v-model="dataForSelect.dataDriverOne"
                        :custom-label="
                          ({ clients__phone, clients__name, clients__id }) => {
                            return (
                              clients__phone +
                              ` ` +
                              clients__name +
                              ` id` +
                              clients__id
                            )
                          }
                        "
                        @input="setMainSelect"
                        id="driver-one"
                        :options="dataClientOptions"
                      >
                        <div slot="no-options">Пока нет опций</div>
                      </BaseSelect>
                      <span> Водитель 2 </span>
                      <BaseSelect
                        placeholder=""
                        selectLabel=""
                        deselectLabel=""
                        v-model="dataForSelect.dataDriverTwo"
                        :custom-label="
                          ({ clients__phone, clients__name, clients__id }) => {
                            return (
                              clients__phone +
                              ` ` +
                              clients__name +
                              ` id` +
                              clients__id
                            )
                          }
                        "
                        @input="setMainSelect"
                        id="driver-two"
                        :options="dataClientOptions"
                      >
                        <div slot="no-options">Пока нет опций</div>
                      </BaseSelect>
                    </div>
                    <div class="d-flex gap-20">
                      <div class="wp-50">
                        <span class="fw-bold"> Страховка </span>
                        <BaseSelect
                          placeholder=""
                          selectLabel=""
                          deselectLabel=""
                          v-model="dataForSelect.dataInsurance"
                          @input="setSimpleSelect"
                          label="label"
                          :options="selectsData.contracts__insurance"
                        >
                          <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                      </div>
                      <div class="wp-50">
                        <span class="fw-bold"> Детское кресло </span>
                        <BaseSelect
                          placeholder=""
                          selectLabel=""
                          deselectLabel=""
                          v-model="dataForSelect.dataChildSeat"
                          @input="setSimpleSelect"
                          label="label"
                          :options="selectsData.contracts__child_seat"
                        >
                          <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                      </div>
                    </div>

                    <div
                      v-if="
                        dataForSelect.dataChildSeat.label ==
                          'необходимо детское кресло' ||
                        dataForSelect.dataChildSeat[0]?.label ==
                          'необходимо детское кресло'
                      "
                    >
                      <span class="fw-bold"> Количество кресел </span>
                      <BaseSelect
                        placeholder=""
                        selectLabel=""
                        deselectLabel=""
                        v-model="dataForSelect.dataChildSeatCount"
                        @input="setMainSelect"
                        id="child-seats"
                        label="label"
                        :options="contractChildSeatCount"
                      >
                        <div slot="no-options">Пока нет опций</div>
                      </BaseSelect>
                    </div>
                    <div v-if="modeOpenModal == 'edit'">
                      <span class="fw-bold"> Комментарии </span>
                      <div class="d-flex gap-10 align-items-center my-15">
                        <div
                          class="btn btn-sm btn-primary"
                          @click="openModalForCreate('commentsModal')"
                        >
                          Добавить
                        </div>
                        <div
                          class="btn btn-sm btn-opposite"
                          @click="deleteActionAndModal"
                          v-if="contractCommentsData"
                        >
                          Удалить
                        </div>
                      </div>
                      <BaseReuseTable
                        :props-data="contractCommentsData"
                        v-if="
                          modeOpenModal == 'edit' && contractCommentsData.length
                        "
                        :props-columns-setting="fieldsTableCommentsContracts"
                        :selectRowActivateFlag="true"
                        nameForAction="commentsModal"
                      >
                      </BaseReuseTable>
                    </div>
                    <BaseLinkToDoc
                      v-if="
                        modeOpenModal == 'edit' && dataForModal.contracts__hash
                      "
                      path="contracts/pdf/carfiz/"
                      title="Договор аренды + акты"
                      :hash="dataForModal.contracts__hash"
                    ></BaseLinkToDoc>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div>
                    <div style="background-color: #52a562">
                      <span class="fw-bold text-white">Конец аренды</span>
                    </div>

                    <div class="mt-10">
                      <div class="d-flex gap-10">
                        <div>
                          <span> Дата </span>
                          <div>
                            <BaseDatePicker
                              v-model="dateTimeSelect.dateEndSelect"
                              type="date"
                              class="wp-100"
                              lang="ru"
                              value-type="format"
                              format="YYYY-MM-DD"
                              @change="changeDate"
                            ></BaseDatePicker>
                          </div>
                        </div>
                        <div>
                          <span> Время </span>
                          <div>
                            <BaseDatePicker
                              v-model="dateTimeSelect.timeEndSelect"
                              type="time"
                              class="wp-100"
                              lang="ru"
                              value-type="format"
                              format="HH:mm"
                              @change="changeDate"
                            ></BaseDatePicker>
                          </div>
                        </div>
                      </div>
                      <span>Продление</span>
                      <BaseSelect
                        placeholder=""
                        selectLabel=""
                        deselectLabel=""
                        v-model="dataSimpleSelect.dataProlongation"
                        label="label"
                        @input="setSimpleSelect"
                        :options="selectsData.contracts__prolongation"
                      >
                        <div slot="no-options">Пока нет опций</div>
                      </BaseSelect>
                      <span>Возврат</span>
                      <BaseSelect
                        placeholder=""
                        selectLabel=""
                        deselectLabel=""
                        v-model="dataForSelect.dataDeliveryEnd"
                        @input="setMainSelect"
                        id="deliveryEnd"
                        label="place_deliveries__address"
                        :options="dataDeliveryOptions"
                      >
                        <div slot="no-options">Пока нет опций</div>
                      </BaseSelect>
                      <div>
                        <BaseTextarea
                          placeholder=""
                          title=""
                          @onInput="updateValue"
                          :value="dataForModal.contracts__end_delivery_comment"
                          name-sign="Комментарий"
                          input-name="contracts__end_delivery_comment"
                        >
                        </BaseTextarea>
                      </div>
                      <span class="fw-bold">Показания при выдаче</span>
                      <div class="d-flex gap-10 mt-10">
                        <BaseInput
                          placeholder=""
                          title=""
                          @onInput="updateValue"
                          inputType="number"
                          :value="dataForModal.contracts__end_odometr"
                          name-sign="Одометр"
                          input-name="contracts__end_odometr"
                        >
                        </BaseInput>
                        <BaseInput
                          placeholder=""
                          title=""
                          @onInput="updateValue"
                          inputType="number"
                          :value="dataForModal.contracts__end_fuel"
                          name-sign="Топливо"
                          input-name="contracts__end_fuel"
                        >
                        </BaseInput>
                      </div>
                      <div v-if="washingData">
                        <span class="fw-bold">Осмотр при сдаче</span>
                        <div v-for="(item, index) in washingData" :key="index">
                          <span>{{ item.washing_prices__type }}</span>
                          <BaseSelect
                            placeholder=""
                            selectLabel=""
                            deselectLabel=""
                            v-model="
                              dataForWashingEndSelect[item.washing_prices__id]
                            "
                            @input="setEndWashingSelect"
                            :id="item.washing_prices__id"
                            label="label"
                            :options="selectsData.contract_washings__end_state"
                          >
                            <div slot="no-options">Пока нет опций</div>
                          </BaseSelect>
                        </div>
                      </div>
                      <div class="mt-10">
                        <BaseCheckbox
                          @onCheckeds="updateValue"
                          input-name="contracts__end_is_done"
                          :checked="dataForModal.contracts__end_is_done"
                          >Авто возвращено
                        </BaseCheckbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end gap-10">
                <div class="btn btn-sm btn-primary" @click="sendDataFromModal">
                  Сохранить
                </div>
              </div>
            </div>
          </v-tab>
          <v-tab
            title="Расчеты"
            v-if="modeOpenModal == 'edit'"
            data-name="calculation"
          >
            <div class="pt-15" v-if="calculationsData.length">
              <BaseReuseTable
                :props-data="calculationsData"
                :props-columns-setting="fieldsTableСalculations"
                nameForAction="calculations"
              >
              </BaseReuseTable>
              <div class="mt-10 d-flex justify-content-between">
                <span class="fw-bold">Итого:</span>
                <span class="">{{ totalPriceCalculation }} руб.</span>
              </div>
            </div>
          </v-tab>
        </vue-tabs>
      </Modal>
      <Modal nameForAction="acceptDelete">
        <p class="text-center">Вы действительно хотите удалить?</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">Да</div>
        </div>
      </Modal>
      <Modal nameForAction="commentsModal">
        <BaseTextarea
          placeholder=""
          title=""
          @onInput="createCommentData"
          value=""
          name-sign="Комментарий"
          input-name="comment"
        >
        </BaseTextarea>
        <div class="d-flex gap-10 align-items-center mt-15">
          <div class="btn btn-sm btn-primary" @click="saveCommentToContract">
            Сохранить
          </div>
          <div class="btn btn-sm btn-opposite" @click="closeCommentModal">
            Отмена
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import TransportVehiclesService from '@/api/TransportVehiclesService.js'
import EventBus from '@/event-bus.js'
import ClientsService from '@/api/ClientsService'
import Modal from '@/components/Modals.vue'
import SelectsService from '@/api/SelectsService.js'
import WashingsService from '@/api/WashingsService.js'
import ContractCommentsService from '@/api/ContractCommentsService.js'
import ContractCalculationsService from '@/api/ContractCalculationsService.js'
import OrdersService from '@/api/OrdersService.js'
import DeliveryService from '@/api/DeliveryService.js'
export default {
  components: {
    Modal,
    VueTabs,
    VTab
  },

  props: {},
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      editData: {},
      activeSelectRow: null,
      dataSimpleSelect: { dataProlongation: [] },
      dataClientOptions: [],
      modeOpenModal: 'create',
      dataForSelect: {
        dataTS: [],
        dataDeliveryStart: [],
        dataDeliveryEnd: [],
        dataHasDriver: [],
        dataInsurance: [],
        dataChildSeat: [],
        dataChildSeatCount: []
      },
      dataTSOptions: [],
      dataDeliveryOptions: [],
      dateTimeSelect: {
        dateEndSelect: '',
        dateStartSelect: '',
        timeEndSelect: '',
        timeStartSelect: '',
        dateTariffFrom: ''
      },
      selectsData: {
        vehicle_cars__drive: [],
        contracts__insurance: [],
        contracts__child_seat: [],
        contracts__prolongation: [],
        contracts__child_seat_count: [
          {
            label: 0,
            value: 0
          },
          {
            label: 1,
            value: 1
          },
          {
            label: 2,
            value: 2
          },
          {
            label: 3,
            value: 3
          },
          {
            label: 4,
            value: 4
          }
        ]
      },
      contractChildSeatCount: [],
      washingData: null,
      contractCommentsData: [],
      createdComment: '',
      dataForWashingStartSelect: [],
      dataForWashingEndSelect: [],
      calculationsData: [],
      totalPriceCalculation: 0,
      savedID: null,
      activateTab: ''
    }
  },
  methods: {
    handleTabChange(tabIndex, newTab, oldTab) {
      if (newTab.$attrs['data-name'] == 'calculation') {
        ContractCalculationsService.getAll(this.savedID)
          .then((data) => {
            this.calculationsData = [...data.data.data.rows]
            data.data.data.rows.forEach((elem, index) => {
              this.totalPriceCalculation += Number(
                elem.contract_calculations__total
              )
            })
          })
          .catch((err) => console.log(err))
        // this.savedID = this.dataForModal.contracts__id
        // this.sendDataFromModal(() => {
        //     ContractCalculationsService.getAll(this.savedID).then(data => {
        //         console.log(data)
        //         this.calculationsData = [...data.data.data.rows]
        //         data.data.data.rows.forEach((elem, index) => {
        //             this.totalPriceCalculation += Number(elem.contract_calculations__total)
        //         })
        //     }).catch(err => console.log(err))
        // })
      }
    },
    sendDataFromModal() {
      if (this.modeOpenModal == 'edit') {
        this.editContractsMultiple({
          id: this.savedID,
          dataEdit: this.dataForModal
        })
        console.log('edit')
        // EventBus.$emit("closeModal", { nameForAction: 'contractsModal' })
        // callback()
      } else {
        console.log(this.dataForModal)
        this.dataForModal.contracts__tariff_from =
          this.dateTimeSelect.dateTariffFrom
        if (
          this.dateTimeSelect.timeStartSelect &&
          this.dateTimeSelect.dateStartSelect
        ) {
          this.dataForModal.contracts__starts =
            this.dateTimeSelect.dateStartSelect +
            ` ` +
            this.dateTimeSelect.timeStartSelect
        }
        if (
          this.dateTimeSelect.timeEndSelect &&
          this.dateTimeSelect.dateEndSelect
        ) {
          this.dataForModal.contracts__ends =
            this.dateTimeSelect.dateEndSelect +
            ` ` +
            this.dateTimeSelect.timeEndSelect
        }
        this.createContractsData(this.dataForModal)
        EventBus.$emit('closeModal', { nameForAction: 'contractsModal' })
      }
    },
    closeCommentModal() {
      EventBus.$emit('closeModal', { nameForAction: 'commentsModal' })
      this.createdComment = ''
    },
    saveCommentToContract() {
      console.log(this.dataForModal.contracts__id)
      if (this.createdComment) {
        ContractCommentsService.create({
          contract_comments__contract_id: this.dataForModal.contracts__id,
          contract_comments__comment: this.createdComment
        })
          .then((data) => {
            console.log(data)
            this.$toast.success('Данные успешно сохранены')
            EventBus.$emit('closeModal', { nameForAction: 'commentsModal' })
            this.contractCommentsData.unshift(data.data.data.rows[0])
            this.createdComment = ''
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.$toast.error('Заполните поле "Комментарий"')
      }
    },
    createCommentData(data) {
      this.createdComment = data.value
    },
    setStartWashingSelect(data, id) {
      this.dataForModal.start_washing.push({
        [data.key]: data.value,
        contract_washings__washing_price_id: id
      })
      console.log(this.dataForModal.start_washing)
    },
    setEndWashingSelect(data, id) {
      this.dataForModal.end_washing.push({
        [data.key]: data.value,
        contract_washings__washing_price_id: id
      })
      console.log(this.dataForModal.start_washing)
    },
    setSimpleSelect(data, id) {
      console.log(data, id)
      this.dataForModal[data.key] = data.value
      console.log(this.dataForModal)
    },
    changeDate(e) {
      console.log(e)
      console.log(this.dateTimeSelect)
    },

    setMainSelect(data, key) {
      switch (key) {
        case 'ts':
          // this.loadCost()
          // vehicle_places__id
          DeliveryService.getAll(data.vehicle_places__id)
            .then((data) => {
              console.log(data)
              this.dataDeliveryOptions = data.data.data.rows
              this.dataDeliveryOptions.unshift({
                place_deliveries__address: 'Самовывоз',
                place_deliveries__id: 0
              })
              this.dataForSelect.dataDeliveryStart = []
              this.dataForSelect.dataDeliveryEnd = []
            })
            .catch((err) => {
              console.log(err)
            })
          this.loadWashingData(data.vehicles__type_id)
          this.dataForModal.contracts__vehicle_id = data.vehicles__id
          break
        case 'deliveryStart':
          this.dataForModal.contracts__start_delivery_id =
            data.place_deliveries__id
          break
        case 'deliveryEnd':
          this.dataForModal.contracts__end_delivery_id =
            data.place_deliveries__id
          break
        case 'clients':
          console.log(data)
          this.dataForModal.contracts__client_id = data.clients__id
          break
        case 'driver-one':
          this.dataForModal.contracts__client_driver1_id = data.clients__id
          break
        case 'driver-one':
          this.dataForModal.contracts__client_driver2_id = data.clients__id
          break
        case 'child-seats':
          this.dataForModal.contracts__child_seat_count =
            data.contracts__child_seat_count
          break
      }
    },
    loadWashingData(filed, dataFromTable) {
      // data.vehicles__type_id
      WashingsService.getAll(filed)
        .then((data) => {
          console.log(data)
          this.washingData = data.data.data.rows
          // this.dataForWashingSelect.length = data.data.data.rows.length
          data.data.data.rows.forEach((elem, index) => {
            // if (dataFromTable) {
            //     let startIndexed = dataFromTable.start_washing.findIndex(x => x.contract_washings__washing_price_id == elem.washing_prices__id)
            //     let endIndex = dataFromTable.end_washing.findIndex(x => x.contract_washings__washing_price_id == elem.washing_prices__id)
            // }
            let startIndexed = dataFromTable.start_washing.findIndex(
              (x) =>
                x.contract_washings__washing_price_id == elem.washing_prices__id
            )
            let endIndex = dataFromTable.end_washing.findIndex(
              (x) =>
                x.contract_washings__washing_price_id == elem.washing_prices__id
            )
            console.log(startIndexed, endIndex)
            this.dataForWashingStartSelect[elem.washing_prices__id] =
              dataFromTable && startIndexed >= 0
                ? [
                    {
                      label:
                        dataFromTable.start_washing[startIndexed]
                          .contract_washings__start_state,
                      ...dataFromTable.start_washing[startIndexed]
                    }
                  ]
                : []
            this.dataForWashingEndSelect[elem.washing_prices__id] =
              dataFromTable && endIndex >= 0
                ? [
                    {
                      label:
                        dataFromTable.end_washing[endIndex]
                          .contract_washings__end_state,
                      ...dataFromTable.end_washing[endIndex]
                    }
                  ]
                : []
          })
          // state.finesData.findIndex(x => x.fines__id == data.id)
          if (dataFromTable) {
            console.log(
              this.dataForWashingStartSelect,
              this.dataForWashingEndSelect
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadCommentsData(filedID) {
      // data.vehicles__type_id
      ContractCommentsService.getAll(filedID)
        .then((data) => {
          console.log(data)
          this.contractCommentsData = []
          this.contractCommentsData.push(...data.data.data.rows)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // sendDataFromModal() {
    //     if (this.dataForModal.contracts__id) {
    //         console.log(this.dataForModal);
    //         this.editContractsMultiple({ id: this.dataForModal.contracts__id, dataEdit: this.dataForModal })
    //         // EventBus.$emit("closeModal", { nameForAction: 'contractsModal' })
    //     } else {
    //         console.log(this.dataForModal);
    //         this.dataForModal.contracts__tariff_from = this.dateTimeSelect.dateTariffFrom
    //         if (this.dateTimeSelect.timeStartSelect && this.dateTimeSelect.dateStartSelect) {
    //             this.dataForModal.contracts__starts = this.dateTimeSelect.dateStartSelect + ` ` + this.dateTimeSelect.timeStartSelect
    //         }
    //         if (this.dateTimeSelect.timeEndSelect && this.dateTimeSelect.dateEndSelect) {
    //             this.dataForModal.contracts__ends = this.dateTimeSelect.dateEndSelect + ` ` + this.dateTimeSelect.timeEndSelect
    //         }
    //         this.createContractsData(this.dataForModal)
    //         EventBus.$emit("closeModal", { nameForAction: 'contractsModal' })
    //     }
    // },

    clickLoadData(numPage) {
      this.getContractsAll({
        params: {
          limit: 10,
          page: numPage
        }
      })
    },
    openModalForCreate(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create'
      })
    },
    deleteActionAndModal() {
      if (this.activeSelectRow) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDelete',
          mode: 'create'
        })
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    deleteRow(nameModal) {
      this.deleteContractsData(this.activeSelectRow)
      this.activeSelectRow = null
      EventBus.$emit('closeModal', { nameForAction: nameModal })
    },

    updateValue(data) {
      this.dataForModal[data.name] = data.value
      console.log(this.dataForModal[data.name])
      console.log(this.dataForModal)
    },

    prepareKeysForModal(payloadData) {
      this.updatedValues = {}
      this.dataForModal = {}
      if (!payloadData) {
        this.modeOpenModal = 'create'
        for (const key in this.contractsData[0]) {
          this.dataForModal[key] = null
          this.dataForModal.start_washing = []
          this.dataForModal.end_washing = []
          this.dataForModal.contracts__start_is_done = 0
          this.dataForModal.contracts__end_is_done = 0
        }
        this.dataForModal.contract_statuses__status = 'в резерве'
      } else {
        this.modeOpenModal = 'edit'
        this.dataForModal = Object.assign({}, payloadData)
        this.savedID = this.dataForModal.contracts__id
        console.log(this.savedID)
        ;(this.dateTimeSelect = {
          dateEndSelect: payloadData.contracts__ends.split(' ')[0],
          dateStartSelect: payloadData.contracts__starts.split(' ')[0],
          timeEndSelect: payloadData.contracts__ends.split(' ')[1],
          timeStartSelect: payloadData.contracts__starts.split(' ')[1],
          dateTariffFrom: payloadData.contracts__tariff_from.split(' ')[0]
        }),
          // contracts__vehicle_id
          DeliveryService.getAll(payloadData.vehicle_places__id)
            .then((data) => {
              console.log(data)
              this.dataDeliveryOptions = data.data.data.rows
              this.dataForSelect.dataDeliveryStart = [
                this.dataDeliveryOptions.find(
                  (delivery) =>
                    delivery.place_deliveries__id ===
                    payloadData.contracts__start_delivery_id
                )
              ]
              this.dataForSelect.dataDeliveryEnd = [
                this.dataDeliveryOptions.find(
                  (delivery) =>
                    delivery.place_deliveries__id ===
                    payloadData.contracts__end_delivery_id
                )
              ]

              //
              console.log(this.dataDeliveryOptions)
              // contracts__end_delivery_id
              // contracts__start_delivery_id
            })
            .catch((err) => {
              console.log(err)

              //                         {
              //     "place_deliveries__address": "Гагарина",
              //     "place_deliveries__id": 5,
              //     "place_deliveries__part_1_cost": 1000,
              //     "place_deliveries__part_1_ends": "19:00:00",
              //     "place_deliveries__part_1_name": "Дневной",
              //     "place_deliveries__part_1_starts": "12:00:00",
              //     "place_deliveries__part_2_cost": 2000,
              //     "place_deliveries__part_2_ends": "11:59:00",
              //     "place_deliveries__part_2_name": "Ночной",
              //     "place_deliveries__part_2_starts": "19:01:00",
              //     "place_deliveries__place_id": 2
              // }
            })
        ;(this.dataForSelect = {
          dataTS: [
            {
              vehicles__name: payloadData.vehicles__name,
              vehicles__gosnumber: payloadData.vehicles__gosnumber,
              vehicles__id: payloadData.vehicles__id
            }
          ],
          dataDeliveryStart: [
            {
              place_deliveries__address:
                payloadData.contracts__start_delivery_comment
            }
          ],
          dataDeliveryEnd: [
            {
              place_deliveries__address:
                payloadData.contracts__end_delivery_comment
            }
          ],
          dataClient: [
            {
              clients__phone: payloadData.clients__phone,
              clients__name: payloadData.clients__name,
              clients__id: payloadData.clients__id
            }
          ],
          dataInsurance: [{ label: payloadData.contracts__insurance }],
          dataChildSeat: [
            {
              label: payloadData.contracts__child_seat,
              value: payloadData.contracts__child_seat
            }
          ],
          dataChildSeatCount: [
            { label: payloadData.contracts__child_seat_count }
          ],
          dataDriverOne: [
            this.dataClientOptions.find(
              (client) =>
                client.clients__id === payloadData.contracts__client_driver1_id
            )
          ],
          dataDriverTwo: [
            this.dataClientOptions.find(
              (client) =>
                client.clients__id === payloadData.contracts__client_driver2_id
            )
          ]
        }),
          console.log(this.dataClientOptions)
        this.dataSimpleSelect = {
          dataProlongation: [{ label: payloadData.contracts__prolongation }]
        }
        this.loadWashingData(
          this.dataForModal.vehicles__type_id,
          this.dataForModal
        )
        this.loadCommentsData(this.dataForModal.contracts__id)
      }
    },

    closeModal(data) {
      this.activeSelectRow = {}
      this.dataForModal = {}
      this.dataForSelect = {
        dataTS: [],
        dataDeliveryStart: [],
        dataDeliveryEnd: [],
        dataHasDriver: [],
        dataInsurance: [],
        dataChildSeat: []
      }
      this.dateTimeSelect = {
        dateEndSelect: '',
        dateStartSelect: '',
        timeEndSelect: '',
        timeStartSelect: '',
        dateTariffFrom: ''
      }
      this.dataSimpleSelect = { dataProlongation: [] }
      ;(this.washingData = null),
        (this.totalPriceCalculation = 0),
        (this.calculationsData = []),
        (this.activateTab = 'Главная')
      this.savedID = null
      // this.contractCommentsData.length = 0
      // this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
      //     element.value = ''
      // })
    },
    btnHandlerCloseModal(nameModal) {
      EventBus.$emit('closeModal', { nameForAction: 'contractsModal' })
    },

    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('contractsModule', [
      'getContractsAll',
      'createContractsData',
      'editContractsMultiple',
      'deleteContractsData',
      'setContractsRoute'
    ])
  },
  mounted() {
    EventBus.$on('openModal', (payload) => {
      this.editData = payload.data
      console.log(payload.data)
      if (!(payload.nameForAction == 'commentsModal')) {
        this.prepareKeysForModal(payload.data)
      }
    })
    EventBus.$on('selectTableRows', (payload) => {
      this.activeSelectRow = payload.data.contracts__id
    })
    EventBus.$on('deselectTableRows', (payload) => {
      this.activeSelectRow = null
    })
  },

  created() {
    this.getContractsAll({
      params: {
        page: 1,
        limit: 10
      }
    })

    TransportVehiclesService.getAll({
      params: {
        vehicleType: 'car'
      }
    })
      .then((data) => {
        this.dataTSOptions = data.data.data.rows
      })
      .catch((err) => {
        console.log(err)
      })

    SelectsService.getAll()
      .then((data) => {
        this.selectsData = this.prepareLabelsForDefaultSelects(
          data.data.data.rows,
          'allSelects',
          true
        )
      })
      .catch((e) => console.log(e))

    ClientsService.getAll()
      .then((data) => {
        console.log(data)
        this.dataClientOptions = data.data.data.rows
      })
      .catch((err) => {
        console.log(err)
      })

    for (let index = 0; index <= 10; index++) {
      this.contractChildSeatCount.push({ label: index, value: index })
    }
  },
  computed: {
    ...mapState('contractsModule', [
      'contractsData',
      'contractsLoadStatus',
      'paginationContractsStateParams'
    ]),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('contractsModule', ['numPages']),

    fieldsTableContracts() {
      return [
        { title: '№', field: 'contracts__num', headerFilter: 'input' },
        {
          title: 'Статус',
          field: 'contract_statuses__status',
          headerFilter: 'input'
        },
        { title: 'Автомобиль', field: 'vehicles__name', headerFilter: 'input' },
        {
          title: 'Договор создан',
          field: 'contracts__created_at',
          headerFilter: 'input'
        },
        { title: 'Менеджер', field: 'employees__name', headerFilter: 'input' },
        {
          title: 'Дата начала',
          field: 'contracts__starts',
          headerFilter: 'input'
        },
        {
          title: 'Дата конца',
          field: 'contracts__ends',
          headerFilter: 'input'
        },
        {
          title: 'Баланс клиента',
          field: 'clients__balance',
          headerFilter: 'input'
        },
        { title: 'Клиент', field: 'clients__name', headerFilter: 'input' }
      ]
    },
    // <div v-if="showChildSeat">
    //                                     <span class="fw-bold">
    //                                         Количество кресел
    //                                         {{ dataForSelect.dataChildSeat }}
    //                                         <!-- {{ dataForSelect.dataChildSeat[0]}} {{ dataForSelect.dataChildSeat }} -->
    //                                     </span>
    //                                     <BaseSelect placeholder="" selectLabel="" deselectLabel=""
    //                                         v-model="dataForSelect.dataChildSeatCount" @input="setMainSelect"
    //                                         id="child-seats" label="label" :options="contractChildSeatCount">
    //                                         <div slot="no-options">Пока нет опций</div>
    //                                     </BaseSelect>
    //                                 </div>

    showChildSeat() {
      console.log(this.dataForSelect.dataChildSeat.length > 0)
      return this.dataForSelect.dataChildSeat.length > 0
    },
    fieldsTableCommentsContracts() {
      return [
        {
          title: 'Дата ',
          field: 'contract_comments__created_at',
          headerFilter: 'input'
        },
        { title: 'Автор', field: 'employees__name', headerFilter: 'input' },
        {
          title: 'Комментарий',
          field: 'contract_comments__comment',
          headerFilter: 'input'
        }
      ]
    },
    fieldsTableСalculations() {
      return [
        // { title: 'Дата ', field: "contract_calculations__created_at", headerFilter: "input" },
        {
          title: 'Наименование',
          field: 'contract_calculations__name',
          headerFilter: 'input'
        },
        {
          title: 'Кол-во',
          field: 'contract_calculations__count',
          headerFilter: 'input'
        },
        {
          title: 'Стоимость',
          field: 'contract_calculations__cost',
          headerFilter: 'input'
        },
        {
          title: 'Сумма',
          field: 'contract_calculations__total',
          headerFilter: 'input'
        }
      ]
      //     "contract_calculations__calculation_type_id": 1,
      // "contract_calculations__contract_id": 53,
      // "contract_calculations__cost": "3640",
      // "contract_calculations__count": 24,
      // "contract_calculations__created_at": "2023-09-28 05:40:04",
      // "contract_calculations__id": 482,
      // "contract_calculations__name": "Стоимость аренды",
      // "contract_calculations__total": "87360",
      // "contract_calculations__updated_at": "2023-09-28 08:40:04"
    }
  }
}
</script>
