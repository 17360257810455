<template>
  <BaseModal
    name="refuelingVehicleModalSingle"
    width="420"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="closeModalAndClearData"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div
      v-if="stateLoading"
      class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
      style="top: 0; left: 0; z-index: 500"
    >
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
    <div
      class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
      :style="{ 'min-height': `${minHeight}px` }"
    >
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          @click="$modal.hide('refuelingVehicleModalSingle')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p class="fw-bold mb-30">
          {{ dataLang['vehicle_modal_service_modals_title_5'] }}
        </p>
        <div class="wp-100" ref="cost">
          <BaseInputNew
            placeholder=""
            :width="'146'"
            class="mb-10 wp-100"
            signClass="fsc-14"
            input-name="cost"
            @input="updateValue"
            :horizontalTitle="true"
            :value="dataForEmit.cost"
            :name-sign="dataLang['vehicle_modal_service_modals_cost']"
          >
          </BaseInputNew>
        </div>

        <div
          class="bg-system-secondary fsc-14 fw-semi-bold text-center py-5 wp-100 text-dark my-15"
        >
          {{ dataLang['vehicle_modal_service_modals_warning1'] }}
        </div>
        <div class="d-flex justify-content-end gap-15">
          <div
            class="base-button px-20 rounded-10"
            @click="$modal.hide('refuelingVehicleModalSingle')"
          >
            {{ dataLang['vehile_modal_btn_reset'] }}
          </div>
          <div
            @click="sendData"
            class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            {{ dataLang['vehile_modal_btn_save'] }}
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { Modal } from 'bootstrap'
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import EventBus from '@/event-bus.js'
// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  props: {
    nameForAction: {
      type: String,
    },
    modalName: {},
    centered: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
    },
    stateLoading: {
      type: Boolean,
      default: false,
    },
    optionalParams: {
      type: Object,
    },
  },
  data() {
    return {
      thisModal: null,
      dataForEmit: {
        vehicle_services__service_type_id: 5,
        //   vehicles__odometr: null,
        cost: null,
        //   payed_to: '',
        //   vehicle_services__text: '',
        // interval: null,
      },
    }
  },
  methods: {
    updateValue(data) {
      this.dataForEmit[data.name] = data.value
      this.$refs[data.name].classList.remove('input-error')
    },

    sendData() {
      ObjectService.createService(this.optionalParams.id, this.dataForEmit)
        .then((data) => {
          console.log(data)
          this.closeModalAndClearData()
          this.$toast.success('Запись добавлена')
          Object.keys(this.dataForEmit).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.remove('input-error')
            }
          })
          this.$modal.hide('refuelingVehicleModalSingle')
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error(err.response.data.message)
          Object.keys(err.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
        })
    },

    closeModalAndClearData() {
      this.dataForEmit.vehicles__odometr = null
      this.dataForEmit.cost = null
      this.dataForEmit.payed_to = ''
      this.dataForEmit.vehicle_services__text = ''
      this.dataForEmit.interval = null
    },
  },

  computed: {
    ...mapState('modalStackModule', ['modalStackPosition']),
    ...mapState('translationModule', ['dataLang']),
  },
}
</script>
