<template>
  <BaseModal
    name="addCommentModal"
    width="420"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="beforeClose"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div class="py-20 px-30 rounded-25 bg-white">
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          @click="$modal.hide('addCommentModal')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p class="fw-bold mb-30 text-center">
          {{ dataLang['vehicle_modal_comments_modals_text1'] }}
        </p>
        <div
          class="d-flex align-items-center justify-content-between mb-10 wp-100"
          ref="vehicle_comments__is_critical"
        >
          <span class="fsc-14 text-muted input-title">
            {{ dataLang['vehicle_modal_comments_modals_text2'] }}
          </span>
          <BaseSelect
          v-if="dataLang"
            style="width: 183px"
            @input="setSelected"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            v-model="selectLabel"
            label="label"
            :options="listOptions"
          >
            <template slot=noOptions>{{ dataLang['select_empty'] }}</template>
            <template slot=noResult>{{
              dataLang['select_search_empty']
            }}</template>
            <template slot=selectedLabel>{{
              dataLang['select_selected']
            }}</template>
            <template slot=deselectLabel>{{
              dataLang['select_unselect']
            }}</template>
          </BaseSelect>
        </div>
        <div class="wp-100 mb-15" ref="vehicle_comments__text">
          <BaseTextarea
            placeholder=""
            class="wp-100 fsc-14 mb-5"
            signClass="fsc-14 text-muted"
            input-name="vehicle_comments__text"
            @onInput="updateValue"
            :minHeight="'160'"
            inputClass="fsc-14"
            :name-sign="dataLang['vehicle_modal_comments_modals_text3']"
            :value="dataForEmit.vehicle_comments__text"
          >
          </BaseTextarea>
        </div>
        <div class="d-flex justify-content-end gap-15">
          <div
            class="base-button px-20 rounded-10"
            @click="$modal.hide('addCommentModal')"
          >
            {{ dataLang['vehile_modal_btn_reset'] }}
          </div>
          <div
            @click="sendData"
            class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            {{ dataLang['vehile_modal_btn_save'] }}
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import EventBus from '@/event-bus.js'
// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  props: {
    stateLoading: {
      type: Boolean,
      default: false,
    },
    optionalParams: {
      type: Object,
    },
  },
  data() {
    return {
        selectLabel: '',
      dataForEmit: {
        vehicle_comments__text: null,
        vehicle_comments__is_critical: null
      },
    }
  },
  methods: {
    setSelected(data) {
      console.log(data.key, data.value)
      this.dataForEmit.vehicle_comments__is_critical = data.value
      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
    },
    beforeClose(event) {
      this.dataForEmit.vehicle_comments__text = null
      this.dataForEmit.vehicle_comments__is_critical = null
      this.selectLabel = ''
      Object.keys(this.dataForEmit).forEach((elem, index) => {
        if (this.$refs[elem]) {
          this.$refs[elem].classList.remove('input-error')
        }
      })
    },
    updateValue(data) {
      this.dataForEmit.vehicle_comments__text = data.value
      this.$refs[data.name].classList.remove('input-error')
    },

    sendData() {
      ObjectService.createComment(this.optionalParams.id, this.dataForEmit)
        .then((data) => {
          console.log(data)
          this.$toast.success('Запись добавлена')
          Object.keys(this.dataForEmit).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.remove('input-error')
            }
          })
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.$modal.hide('addCommentModal')
          this.dataForEmit.vehicle_comments__text = null
          this.dataForEmit.vehicle_comments__is_critical = null
          this.selectLabel = ''
        })
        .catch((err) => {
          console.log(err)
          Object.keys(err.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
          this.$toast.error(err.response.data.message)
        })
    },
  },
  mounted() {},
  beforeDestroy() {},

  computed: {
    listOptions() {
      return [
        {
          label: this.dataLang['no'],
          value: false,
          key: 'vehicle_comments__is_critical',
        },
        {
          label: this.dataLang['yes'],
          value: true,
          key: 'vehicle_comments__is_critical',
        },
      ]
    },

    ...mapState('translationModule', ['dataLang']),
  },
}
</script>
