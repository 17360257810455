<template>
    <div class="base-loader">
        <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
    </div>
</template>
  
  
<script>
export default {
    components: {

    },
    props: {
        inputName: {
            type: String,
        },
        nameSign: {
            type: String,
            default: ''
        },

    },
    methods: {
        onInput(event) {
            this.$emit('onInput', { name: this.inputName, value: event.target.value })
        }
    },
    mounted() {

    }
};
</script>
      