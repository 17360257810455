<template>
    <div>
        <BaseTopLine></BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="bidsFinesData" :props-columns-setting="fieldsTableBidsFines"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="bidsFinesModal" v-if="bidsFinesLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getBidsFinesAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },

        deleteRow(nameModal) {
            this.deleteBidsFinesData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },



        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },


        ...mapActions("bidsFinesModule", ["getBidsFinesAll", 'createBidsFinesData', 'editBidsFinesMultiple', 'deleteBidsFinesData']),


    },
    mounted() {
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.companies__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getBidsFinesAll({ params: { 
            page: 1, limit: 10,
        }})
    },
    computed: {
        ...mapState("bidsFinesModule", ["bidsFinesData", "bidsFinesLoadStatus", 'paginationBidsFinesStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("bidsFinesModule", ["numPages"]),


        fieldsTableBidsFines() {
            return [
                { title: 'ID', field: "request_services__id", headerFilter: "input" },
                { title: 'Наименование', field: "request_services__name", headerFilter: "input"},
                { title: 'Описание', field: "request_services__description", headerFilter: "input" },
                { title: 'Дата создания', field: "request_services__created_at", headerFilter: "input" }
            ]
        }
    },
};
</script>
    
    