<template>
  <div class="base-layout">
    <TheHeader v-once></TheHeader>
    <main class="d-flex flex-column ">
      <router-view></router-view>
    </main>
    <TheFooter></TheFooter>
  </div>
</template>
  
  
<script>
import { Tooltip } from "bootstrap";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  components: {
    TheHeader,
    TheFooter,
  },

  props: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions("translationModule", ["getTranslation"]),
  },
  created() {
    //if (!localStorage.getItem('translations')) {
      this.getTranslation(1)
    //}
  },
  mounted() {

    
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
};
</script>
  
  