<template>
  <div>
    <BaseTopLine>
      <div class="d-flex gap-10 align-items-center mt-15"></div>
    </BaseTopLine>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
          <span>Город*</span>

          <BaseSelect
            v-model="city"
            selectLabel="Выбрано"
            deselectLabel="Убрать выбор"
            :options="cities"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Выберите город"
            label="cities__name"
            @input="setSelected"
            track-by="cities__id"
            :preselect-first="true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length"
                v-show="!isOpen"
                >{{ values.length }} Выбрано</span
              ></template
            >
          </BaseSelect>
          <!-- <BaseSelect placeholder="" selectLabel="" id="" deselectLabel="" @open="onOpen" :multiple="true"
                        v-model="city" label="cities__name" @input="setSelected" :options="cities">
                        <div slot="no-options">Пока нет опций</div>
                    </BaseSelect> -->
        </div>
        <div class="col-lg-3">
          <span> Дата и время </span>
          <div>
            <BaseDatePicker
              v-model="dateSelect"
              type="datetime"
              range
              lang="ru"
              value-type="format"
              format="YYYY-MM-DD HH:mm:ss"
            ></BaseDatePicker>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="btn btn-sm btn-primary" @click="loadFilteredData">
            {{ dataLang['register_btn_filter_on'] }}
          </div>
        </div>
      </div>
      <div>
        <span v-if="!items.length"> Данных нет </span>
        <div class="timeline-calendar" ref="rootTimelineContainer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { Timeline } from 'vis-timeline/standalone'

import ChessService from '@/api/ChessService.js'
import CitiesService from '@/api/CitiesService.js'
import EventBus from '@/event-bus.js'
export default {
  components: {
    Timeline
  },

  props: {},
  data() {
    return {
      items: [],
      groups: [{ id: 0 }],

      options: {
        orientation: 'top',
        timeAxis: { scale: 'day', step: 1 },
        zoomable: true,
        locale: 'ru',
        min: '2018-12-01',
        start: '2023-06-05',
        end: '2023-07-04',
        horizontalScroll: true,
        /* itemsAlwaysDraggable: true,
                zoomMin: 1000, //for days 7 instead of 6?
                horizontalScroll: true,
                minHeight: "500px",
                editable: {
                  remove: true, // delete an item by tapping the delete button top right
                  overrideItems: false, // allow these options to override item.editable
                }, */
        onUpdate: function (item, callback) {
          item.content = prompt('Edit items text:', item.content)
          if (item.content != null) {
            callback(item) // send back adjusted item
          } else {
            callback(null) // cancel updating the item
          }
        },
        onMove: function (item, callback) {
          console.log(item, callback)
          if (item.content != null) {
            callback(item) // send back adjusted item
          } else {
            callback(null) // cancel updating the item
          }
        }
      },
      dateSelect: [],
      city: [],
      cities: [],
      contracts: [],
      activeSelectRow: null,
      selectedSities: '',
      loadChess: true
    }
  },
  methods: {
    setSelected(listSities) {
      let selctedSities = listSities.map(function (elem) {
        return elem['cities__id']
      })
      this.selectedSities = selctedSities.join()
      console.log(this.selectedSities)
    },
    onOpen(e) {
      console.log(e)
    },
    filterHandler() {},

    loadFilteredData() {
      let params = {}
      if (this.dateSelect[0] && this.dateSelect.length > 0) {
        this.dateSelect = this.dateSelect.map((el) => el.replace('+', ' '))
        params.starts = this.dateSelect[0]
        params.ends = this.dateSelect[1]
        console.log(params)
      }
      if (this.selectedSities) {
        params.cities = this.selectedSities
      }
      console.log(this.dateSelect)
      ChessService.getAll({
        params
      })
        .then((data) => {
          ;(this.groups = []),
            (this.items = []),
            this.timeline.setGroups(this.groups)
          this.timeline.setItems(this.items)
          let datas = data.data.data
          console.log(datas)
          datas.forEach((element, indexVehicle) => {
            console.log(element, element.vehicle)
            this.groups.push({
              id: element.vehicle.vehicles__id,
              content: `${element.vehicle.vehicles__id} ${element.vehicle.vehicles__name} ${element.vehicle.vehicle_places__name} ${element.vehicle.vehicles__gosnumber}`,
              className: 'timeline-group'
            })
            element.contracts.forEach((el, indexContracts) => {
              this.items.push({
                id: indexContracts + Math.random(),
                content: `Договор №${el.contracts__num} От:${el.contracts__starts} По:${el.contracts__ends}`,
                start: el.contracts__starts,
                end: el.contracts__ends,
                group: element.vehicle.vehicles__id
              })
            })
          })

          this.timeline.setOptions(this.options)
          this.timeline.setGroups(this.groups)
          this.timeline.setItems(this.items)
          console.log(this.groups, this.items)
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error('Ошибка загрузки')
        })
    }
    // ...mapActions("priceModule", ["getPriceAll", 'createPriceData', 'editPriceMultiple', 'deletePriceData', 'setPriceRoute']),
  },
  mounted() {
    // let timeline = new Timeline(document.querySelector(".timeline-calendar"));
    this.timeline = new Timeline(document.querySelector('.timeline-calendar'))
    this.loadFilteredData()
    // timeline.setOptions(this.options);
    // timeline.setGroups(this.groups);
    // timeline.setItems(this.items);
  },

  created() {
    // this.getPriceAll({ params: { page: 1, limit: 10 } })

    CitiesService.getAll({
      params: {
        limit: 2000
      }
    })
      .then((data) => {
        this.cities = data.data.data.rows
      })
      .catch((e) => console.log(e))
      .finally((data) => {})
  },
  computed: {
    // ...mapState("priceModule", ["priceData", "priceLoadStatus", 'paginationPriceStateParams']),
    // ...mapState("translationModule", ["dataLang"]),
    // ...mapGetters("priceModule", ["numPages"]),
  }
}
</script>
