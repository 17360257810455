<template>
  <div class="top-line container-fluid">
    <div class="top-line__layout align-items-center">
      <!-- <div class="top-line__base-num">{{currentNumPage}}</div> -->
      <div class="top-line__inner-title py-10">
        <h1 class="fsc-16 fw-normal">
           {{ currentNamePage }}
        </h1>
        <div
          class="top-line__round-tooltip top-line-round-tooltip fsc-16"
          data-bs-toggle="tooltip"
          title="Видеоруководство по разделу. Кликните чтобы начать"
        >
          <!-- <div data-bs-toggle="modal" data-bs-target="#exampleModal">
            <font-awesome-icon :icon="['fas', 'circle-info']" />
          </div> -->
        </div>
        <!-- <Teleport to="body">
          <Modals centered title="Заказы" size="xl">
            <template v-slot:main-content>
              <div class="responsive-img box-aspect box-aspect--full">
                <iframe
                  class="responsive-img__image"
                  src="https://www.youtube.com/embed/P9SxHWnlSYE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div> </template
          ></Modals>
        </Teleport> -->
      </div>
      <a href="" class="top-line__desc-link"> Посмотреть видеоинструкцию </a>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Modals from '@/components/Modals.vue'
import EventBus from '@/event-bus'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  components: {
    Modals,
  },
  data() {
    return {
      currentMenu: {},
    }
  },
  methods: {},

  created() {},

  watch: {
    $route(to, from) {
      this.currentMenu = this.menuDataByKey[this.$route.name]
    },
  },

  mounted() {
    console.log(this.menuDataByKey)
    let interval = setInterval(() => {
      if (this.menuDataByKey[this.$route.name]) {
        this.currentMenu = this.menuDataByKey[this.$route.name]
        clearInterval(interval)
      }
    }, 10)
  },

  computed: {
    ...mapState('menuModule', ['menuDataByKey', 'loadStatus']),

    currentNamePage() {
      // console.log(this.currentMenu)
      if (this.currentMenu) return this.currentMenu.title
      return null
    },

    currentNumPage() {
      if (this.currentMenu) return this.currentMenu.position
      return null
    },
  },
}
</script>
