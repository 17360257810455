<template>
    <div>
        <BaseTopLine></BaseTopLine>
        <div class="p-20">
           <table class="table table-bordered" v-if="this.summaryData.info">
                <tbody>
                    <tr v-for="(item, index) in this.summaryData.info" :key="index">
                        <td>{{ dataLang[index] }}</td>
                        <td>{{ item }}</td>
                    </tr>
                </tbody>
           </table>
           <h3 v-if="!this.summaryData.info">Статистика пока отсутствует</h3>
        </div>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
        };
    },
    methods: {
        
        ...mapActions("summaryModule", ["getSummaryAll"]),
    },
    mounted() {},

    created() {
        this.getSummaryAll({ params: { page: 1, limit: 10 }})
    },

    computed: {
        ...mapState("summaryModule", ["summaryData", "summaryLoadStatus"]),
        ...mapState("translationModule", ["dataLang"]),
    },
};
</script>
    
    