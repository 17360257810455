<template>
    <form class="access-layout">
      <div class="access-wrapper" ref="wrapperTape" >
        <div class="access-tape">
          <div class="access-block">
            <span class="access-block__title">Авторизация</span>
            <div>
              <div class="mb-10 mt-10">
                <input placeholder="Логин*" v-model="login" type="text" class="form-control wp-100" />
              </div>
              <div class="mb-10">
                <input placeholder="Пароль*" v-model="password" type="password" class="form-control wp-100" />
              </div>
              <!-- <BaseCheckbox :onchecked="false" @oncheckeds="toggleSwitch">У меня есть промокод</BaseCheckbox> -->
              <span class="access-block__desc access-block__desc--warn-active" ref="descreg"></span>
              <span class="access-block__desc access-block__desc--warn-active" v-if="authMessage">{{ authMessage }}</span>
              <div class="mt-5">
                <div class="btn btn-primary py-10 wp-100" @click="forwardAuth">
                  Войти
                </div>
              </div>
              <div class="d-flex flex-column gap-10 justify-content-center">
                <a href="/reg">Регистрация</a>
                <a href="/recovery-password">Восстановление пароля</a>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </form>
  </template>
      
      
  <script>
  import { mapActions, mapState, mapMutations } from "vuex";
  export default {
    components: {},
    props: {},
    data() {
      return {
        login: null,
        password: null,
        enterCodeAccess: null,
      };
    },
    methods: {
      
      ...mapActions("accessModule", ["reg", "auth"]),
      ...mapMutations("accessModule", ["auth_status"]),
      forwardAuth() {
        if (this.login && this.password) {
          this.$refs.descreg.textContent = ''
          this.auth({
            login: this.login,
            password: this.password,
          });
        } else {
          this.auth_status('')
          this.$refs.descreg.textContent = 'Заполните все обязательные поля'
        }
      },
      // async nextStep() {
      //   let response = await fetch('/settings', {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/json;charset=utf-8',
      //       'Authorization': 'Bearer axios'
      //     },
      //   });
      //   let data = await response.json()
      //   console.log(data)
      // }
    },
    mounted() {
      //  this.auth({
      //   phone: "902518018552",
      //   password: "e270bb464f",
      // }); 
      // this.reg({
      //   phone: "90251801855223",
      //   email: "it@agelarrwewqe223.ru",
      // });
  
  
      // this.nextStep()
    },
    computed: {
      ...mapState("accessModule", ['statusAuth',
        'statusReg',
        'loggedStatus',
        'authMessage',
        'regMessage']),
    }
  };
  </script>
      
      