import GlobalSettingService from '@/api/GlobalSettingService.js'
import Vue from 'vue'
export const globalSettingModule = {
  namespaced: true,
  state: () => ({
    dataSettings: {},
    loadStatus: ''
  }),

  getters: {},
  mutations: {
    get_all(state, data) {
      Object.assign(state.dataSettings, data)
    },

    update_setting(state, data) {
      let obj = {}
      obj[data.key] = data.value

      state.dataSettings = Object.assign({}, state.dataSettings, obj)
    },

    load_request(state, status) {
      state.loadStatus = 'request'
    },

    load_success(state) {
      state.loadStatus = 'success'
    },
    load_error(state) {
      state.loadStatus = 'error'
    }
  },
  actions: {
    getAll({ commit }, paramsAndCollback) {
      commit('load_request')
      GlobalSettingService.getAll()
        .then((data) => {
          console.log(data.data.data.rows[0])
          commit('get_all', data.data.data.rows[0])
          commit('load_request')
          if (paramsAndCollback.callback)
            paramsAndCollback.callback(data.data.data.rows[0])
          commit('load_success')
        })
        .catch((error) => {
          console.log(error)
          commit('load_request')
          commit('load_error')
        })
    },
    updateSettings({ commit }, updateDataSettings) {
      GlobalSettingService.updateSettings(JSON.stringify(updateDataSettings))
        .then((data) => {
          console.log(data)
          Vue.$toast.success('Данные сохранены')
        })
        .catch((error) => {
          console.log(error.response.data.message)
          Vue.$toast.error(error.response.data.message)
        })
    }
  }
}
