import http from "@/http-common.js";

class OrdersService {

  basePathApiService = "/orders";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
  getOrderPrice(params) {
    return http.get(this.basePathApiService + '/cost', params);
  } 
  getOne(id) {
    return http.get(this.basePathApiService + `/${id}`);
  }
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(id, editData) {
    return http.put(this.basePathApiService + `/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
  updateStatus(id, data){
    return http.patch(this.basePathApiService + `/${id}/update-status`, data);
  }
}
export default new OrdersService();
