import http from "@/http-common.js";

class AboutUser {
  basePathApiService = "/whoami";
 
  getAllAboutUser() {
    return http.get(this.basePathApiService);
  } 
//   create(createData) {
//     return http.post(this.basePathApiService, createData);
//   }
//   editMultiple(id, editData) {
//     return http.put(this.basePathApiService + `/${id}`, editData);
//   }
//   delete(id) {
//     return http.delete(this.basePathApiService + `/${id}`);
//   }
}
export default new AboutUser();
