<template>
  <BaseModal
    name="placeEditModal"
    :clickToClose="false"
    :shiftY="0.5"
    :adaptive="true"
    :minHeight="680"
    height="auto"
    :resizable="true"
    :width="1400"
    classes="bg-transparent shadow-none"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <!-- 875 -->

    <div class="mx-auto" style="width: 1280px">
      <!-- <BaseLoader v-if="modalInfo.loading"></BaseLoader> -->
      <BaseLoader v-if="localLoading"></BaseLoader>
      <div class="bg-system-main-block">
        <div
          class="bg-system-secondary py-10 px-30 d-flex gap-30 justify-content-between"
          v-if="dataForModal"
        >
          <div class="fsc-12 fw-semi-bold">
            Площадка №{{ dataForModal.vehicle_places__id }}
          </div>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('placeEditModal')"
          ></button>
        </div>
        <div class="pb-10">
          <div class="">
            <div class="row px-25">
              <div class="col-6">
                <div class="system-block h-100">
                  <div class="fsc-12 fw-semi-bold mb-10">
                    {{ dataLang.city_delivery_register_text_22 }}
                  </div>
                  <div
                    class="mb-5 d-flex justify-content-between align-items-center"
                    ref="vehicle_places__city_id"
                  >
                    <span class="fsc-11 input-title" style="color: #666666">{{
                      dataLang.city_delivery_register_text_8
                    }}</span>
                    <BaseSelect
                      placeholder=""
                      style="width: 297px"
                      selectLabel=""
                      deselectLabel=""
                      @input="
                        (data) =>
                          setSimpleSelect({
                            key: 'vehicle_places__city_id',
                            value: data.cities__id
                          })
                      "
                      v-model="selectsFromModal.cities"
                      disabled
                      :options="selectsData.cities"
                      label="cities__name"
                    >
                      <template slot="noOptions">{{
                        dataLang['select_empty']
                      }}</template>
                      <template slot="noResult">{{
                        dataLang['select_search_empty']
                      }}</template
                      ><template slot="selectedLabel">{{
                        dataLang['select_selected']
                      }}</template>
                      <template slot="deselectLabel">{{
                        dataLang['select_unselect']
                      }}</template>
                    </BaseSelect>
                  </div>
                  <div class="mb-5" ref="vehicle_places__address">
                    <BaseInputNew
                      placeholder=""
                      width="297"
                      class="lh-1"
                      input-name="vehicle_places__address"
                      maxLength="100"
                      @input="updateValueCitiesCenter"
                      :horizontalTitle="true"
                      signClass="text-muted fsc-11"
                      inputClass=""
                      :value="valueAddress"
                      @selectData="onSelectFromAddress"
                      :additionalParams="{}"
                      :name-sign="dataLang.city_delivery_register_text_38"
                      :options="addressOptions"
                      selectKey="formatted_address"
                      selectFocusmethod="input"
                    >
                    </BaseInputNew>
                  </div>
                  <div class="mb-5" ref="vehicle_places__name">
                    <BaseInputNew
                      placeholder=""
                      class="lh-1"
                      input-name="vehicle_places__name"
                      maxLength="100"
                      @input="updateValue"
                      :horizontalTitle="true"
                      width="297"
                      signClass="fsc-11"
                      inputClass=""
                      :value="dataForModal.vehicle_places__name"
                      :additionalParams="{}"
                      :name-sign="dataLang.city_delivery_register_text_10"
                    >
                    </BaseInputNew>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-5"
                    ref="vehicle_places__work_hours_start"
                  >
                    <span class="fsc-11 input-title" style="color: #666666">{{
                      dataLang.city_delivery_register_text_23
                    }}</span>
                    <BaseDatePicker
                      v-model="selectTime.start"
                      type="time"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      id="vehicle_places__work_hours_start"
                      style="width: 297px"
                      value-type="format"
                      :format="`HH:mm`"
                      @change="
                        (date) =>
                          updateValue({
                            name: 'vehicle_places__work_hours_start',
                            value: date
                          })
                      "
                    >
                    </BaseDatePicker>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-5"
                    ref="vehicle_places__work_hours_end"
                  >
                    <span class="fsc-11 input-title" style="color: #666666">{{
                      dataLang.city_delivery_register_text_24
                    }}</span>
                    <BaseDatePicker
                      v-model="selectTime.end"
                      type="time"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      id="vehicle_places__work_hours_end"
                      style="width: 297px"
                      value-type="format"
                      :format="`HH:mm`"
                      @change="
                        (date) =>
                          updateValue({
                            name: 'vehicle_places__work_hours_end',
                            value: date
                          })
                      "
                    >
                    </BaseDatePicker>
                  </div>
                  <div class="mb-5" ref="vehicle_places__phone">
                    <BaseInputNew
                      placeholder=""
                      class="lh-1"
                      width="297"
                      input-name="vehicle_places__phone"
                      @input="updateValue"
                      :horizontalTitle="true"
                      signClass=" fsc-11"
                      inputClass=""
                      inputRef="vehicle_places__phone"
                      inputMask="+{7}(000)000-00-00"
                      maxLength="16"
                      :value="dataForModal.vehicle_places__phone"
                      :additionalParams="{}"
                      :name-sign="dataLang.city_delivery_register_text_11"
                    >
                    </BaseInputNew>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mb-10"
                    ref="vehicle_places__is_open"
                  >
                    <span class="fsc-11 input-title" style="color: #666666">{{
                      dataLang.city_delivery_register_text_12
                    }}</span>
                    <BaseSelect
                      placeholder=""
                      style="width: 297px"
                      selectLabel=""
                      deselectLabel=""
                      @input="
                        (data) =>
                          updateValue({
                            name: 'vehicle_places__is_open',
                            value: data.value
                          })
                      "
                      v-model="selectsFromModal.countries"
                      :options="[
                        { label: 'Открытая', value: 1 },
                        { label: 'Закрытая', value: 0 }
                      ]"
                      label="label"
                    >
                      <template slot="noOptions">{{
                        dataLang['select_empty']
                      }}</template>
                      <template slot="noResult">{{
                        dataLang['select_search_empty']
                      }}</template
                      ><template slot="selectedLabel">{{
                        dataLang['select_selected']
                      }}</template>
                      <template slot="deselectLabel">{{
                        dataLang['select_unselect']
                      }}</template>
                    </BaseSelect>
                  </div>
                  <div class="fsc-12 fw-semi-bold mb-35">
                    {{ dataLang.city_delivery_register_text_39 }}
                  </div>

                  <div
                    class="d-flex justify-content-between mb-5"
                    v-for="(item, index) in dataForModal.capacities"
                    :key="index"
                  >
                    <div>
                      <BaseCheckboxV2
                        v-model="
                          dataForModal.capacities[listIndex[index] - 1]
                            .place_capacity__is_active
                        "
                        placeholder=""
                        @oncheckeds="
                          (data) =>
                            updateValue({
                              ...data,
                              ...dataForModal.capacities[listIndex[index] - 1],
                              type: 'capacities'
                            })
                        "
                        input-name="place_capacity__is_active"
                        >{{
                          dataLang[
                            dataForModal.capacities[listIndex[index] - 1]
                              .vehicle_types__code
                          ]
                        }}
                      </BaseCheckboxV2>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="">
                        <BaseInputNew
                          placeholder=""
                          class="lh-1"
                          input-name="place_capacity__capacity"
                          maxLength="100"
                          @input="updateValue"
                          :horizontalTitle="true"
                          width="70"
                          signClass="fsc-11 text-center"
                          inputClass="text-center"
                          :value="
                            dataForModal.capacities[listIndex[index] - 1]
                              .place_capacity__capacity
                          "
                          :additionalParams="{
                            ...dataForModal.capacities[listIndex[index] - 1],
                            type: 'capacities'
                          }"
                          :name-sign="dataLang.city_delivery_register_text_29"
                        >
                        </BaseInputNew>
                      </div>
                      <div
                        class="fsc-11"
                        style="
                          width: 70px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          height: 26px;
                        "
                      >
                        {{
                          dataForModal.capacities[listIndex[index] - 1].percent
                        }}%
                      </div>
                      <div
                        class="fsc-11"
                        style="
                          width: 70px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          height: 26px;
                        "
                      >
                        {{
                          dataForModal.capacities[listIndex[index] - 1]
                            .countMyObjects
                        }}/<span style="color: #0056af">{{
                          dataForModal.capacities[listIndex[index] - 1]
                            .countNotMyObjects
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="system-block p-0 h-100">
                  <div
                    class="fsc-12 fw-semi-bold gap-10 p-15 d-flex align-items-center"
                  >
                    {{ dataLang.city_delivery_register_text_40 }}
                    <label class="form-switch ps-0">
                      <input
                        type="checkbox"
                        :checked="showDeliveres"
                        @input="
                          (e) => {
                            updateValue({
                              value: e.target.checked,
                              name: 'vehicle_places__is_delivery'
                            })
                            showDeliveres = e.target.checked
                          }
                        "
                      />
                      <i></i>
                    </label>
                  </div>
                  <table
                    class="table-delivery"
                    style="margin: 0 -1px 0 -1px"
                    v-if="showDeliveres"
                  >
                    <tr>
                      <td>Место</td>
                      <td>
                        <div class="table-delivery__box">
                          <BaseCheckboxV2
                            v-model="
                              dataForModal.vehicle_places__is_delivery_worktime
                            "
                            placeholder=""
                            class="d-block"
                            @oncheckeds="updateValue"
                            input-name="vehicle_places__is_delivery_worktime"
                          >
                            {{ '' }} </BaseCheckboxV2
                          >Рабочее время
                        </div>
                      </td>
                      <td>
                        <div class="table-delivery__box">
                          <BaseCheckboxV2
                            v-model="
                              dataForModal.vehicle_places__is_delivery_not_worktime
                            "
                            class="d-block"
                            placeholder=""
                            @oncheckeds="updateValue"
                            input-name="vehicle_places__is_delivery_not_worktime"
                          >
                            {{ '' }} </BaseCheckboxV2
                          >Нерабочее время
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in dataForModal.deliveries"
                      :key="index"
                    >
                      <td>{{ item.place_deliveries__address }}</td>
                      <td>
                        <BaseInputNew
                          placeholder=""
                          class="lh-1"
                          input-name="place_deliveries__part_1_cost"
                          maxLength="100"
                          @input="updateValue"
                          :horizontalTitle="true"
                          signClass="fsc-11 text-center"
                          inputClass="text-center wp-100 border-0"
                          :value="item.place_deliveries__part_1_cost"
                          :additionalParams="{ type: 'delivery', ...item }"
                          name-sign=""
                        >
                        </BaseInputNew>
                      </td>
                      <td>
                        <BaseInputNew
                          placeholder=""
                          class="lh-1"
                          input-name="place_deliveries__part_2_cost"
                          maxLength="100"
                          @input="updateValue"
                          :horizontalTitle="true"
                          signClass="fsc-11 text-center"
                          inputClass="text-center wp-100 border-0"
                          :value="item.place_deliveries__part_2_cost"
                          :additionalParams="{ type: 'delivery', ...item }"
                          name-sign=""
                        >
                        </BaseInputNew>
                      </td>
                    </tr>
                  </table>
                  <div class="fsc-12 p-15 d-flex align-items-center">
                    <BaseTextarea
                      placeholder=""
                      :disabled="true"
                      class="fsc-11 wp-100"
                      inputClass="fsc-11"
                      @onInput="updateValue"
                      minHeight="214"
                      :maxLength="499"
                      :resize="false"
                      input-name="vehicle_places__comment"
                      :value="dataForModal.vehicle_places__comment"
                      name-sign="
            Комментарий
              "
                    >
                    </BaseTextarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end gap-35 px-30 mt-10">
            <div
              class="bg-danger px-15 py-10 flex-grow-1 fsc-12 fw-bold text-white d-flex align-items-center gap-5"
              v-if="dataForModal?.clients__is_blacklisted"
              v-html="dataLang['clients_register_text_95']"
            ></div>
            <div class="d-flex gap-10">
              <div
                class="base-button base-button--action py-10 px-30 text-uppercase"
                @click="$modal.hide('placeEditModal')"
              >
                <span class="fsc-14">{{
                  dataLang['orders_model_edit__btn_text5']
                }}</span>
              </div>
              <div
                class="base-button base-button--action py-10 px-30 text-uppercase"
                @click="sendData"
              >
                <span class="fsc-14">{{
                  dataLang['orders_model_edit__btn_text6']
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import GeneralService from '@/api/GeneralService'
import EventBus from '@/event-bus'

export default {
  components: {},
  props: {},
  data() {
    return {
      showDeliveres: false,
      updatedValues: {},
      currentOpenedId: '',
      dataForModal: {},
      newTab: null,
      oldTab: null,
      mainInfo: {},
      localLoading: true,
      cities: [],
      selectsFromModal: {
        countries: [],
        region: [],
        time: [],
        integrator: [],
        cities: []
      },
      selectTime: { start: [], end: [] },
      listIndex: [1, 8, 2, 4, 3, 7, 5, 6],
      selectsData: {
        countries: [],
        region: [],
        cities: [],
        time: [],
        places: []
      },
      deliveryUpdateData: [],
      capacitiesUpdateData: [],
      timeout: null,
      valueAddress: '',
      addressOptions: []
    }
  },
  methods: {
    updateValueCitiesCenter(e) {
      this.valueAddress = e.value
      this.updatedValues.vehicle_places__address = e.value
      this.addressOptions = []
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        let data = await fetch(
          `https://suggest-maps.yandex.ru/v1/suggest?text=${e.value}&apikey=be9de750-f591-4271-82d1-4846630211d1&print_address=1&types=country,house,house,district,geo,street,locality&lang=ru&results=15`
        )
        let dataFromJson = await data.json()
        dataFromJson.results.forEach((elem, index) => {
          this.addressOptions.push(elem.address)
        })
      }, 400)
    },
    onSelectFromAddress(e) {
      console.log(e)
      this.valueAddress = e.value.formatted_address
      this.updatedValues.vehicle_places__address = e.value.formatted_address
    },
    updateValue(data) {
      let indexedDelivery
      let indexedCapacities
      //   capacitiesUpdateData
      if (data.type && data.type == 'delivery') {
        indexedDelivery = this.deliveryUpdateData.findIndex(
          (x) => x.place_deliveries__id == data.place_deliveries__id
        )
        if (indexedDelivery !== -1) {
          this.deliveryUpdateData[indexedDelivery][data.name] = data.value
          console.log(true, this.deliveryUpdateData)
        } else {
          this.deliveryUpdateData.push({
            place_deliveries__id: data.place_deliveries__id
          })
          console.log(false, this.deliveryUpdateData)
        }
        console.log(false, this.deliveryUpdateData)
      }
      if (data.type && data.type == 'capacities') {
        indexedCapacities = this.capacitiesUpdateData.findIndex(
          (x) =>
            x.place_capacity__vehicle_type_id ==
            data.place_capacity__vehicle_type_id
        )
        if (indexedCapacities !== -1) {
          this.capacitiesUpdateData[indexedCapacities][data.name] = data.value
          console.log(true, this.capacitiesUpdateData)
        } else {
          this.capacitiesUpdateData.push({
            place_capacity__vehicle_type_id:
              data.place_capacity__vehicle_type_id,
            [data.name]: data.value
            // data.name == 'place_capacity__is_active' &&  ?
            //   place_capacity__is_active: data.place_capacity__is_active : ""
          })
          console.log(false, this.capacitiesUpdateData)
        }
        console.log(false, this.indexedCapacities)
      }
      if (!data.type) {
        this.updatedValues[data.name] = data.value
        console.log(data, this.updatedValues)
      }

      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
    },
    setSimpleSelect(data, id) {
      this.updatedValues[data.key] = data.value
      console.log(data)
      if (this.$refs[data.key]) {
        this.$refs[data.key].classList.remove('input-error')
      }
    },
    sendData() {
      this.localLoading = true
      GeneralService.edit(`/places/${this.currentOpenedId}`, {
        ...this.updatedValues,
        ...(this.capacitiesUpdateData.length && {
          capacities: this.capacitiesUpdateData
        }),
        ...(this.deliveryUpdateData.length && {
          deliveries: this.deliveryUpdateData
        })
      })
        .then((data) => {
          console.log(data)
          EventBus.$emit('updateContentData')
          this.$modal.hide('placeEditModal')
          this.$toast.success(data.data.message)
          this.updatedValues = {}
          this.capacitiesUpdateData = []
          this.deliveryUpdateData = []
        })
        .catch((error) => {
          console.log(error)
          this.localLoading = false
          Object.keys(error.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
          this.$toast.error(error.response.data.message)
        })
    },
    beforeOpen(data) {
      this.currentOpenedId = data.params.id
      Promise.all([
        GeneralService.get('/cities', {params: {
            limit: 5000
          }})
          .then((data) => {
            console.log(data)
            this.selectsData.cities = data.data.data.rows
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          })
      ]).then(() => {
        GeneralService.get(`/places/${data.params.id}?joins=delivery,capacity`)
          .then((data) => {
            this.dataForModal = data.data.rows[0]
            this.localLoading = false
            this.valueAddress = this.dataForModal.vehicle_places__address
            this.selectsFromModal.cities = this.selectsData.cities.find(
              (x) => x.cities__id == this.dataForModal.vehicle_places__city_id
            )
            console.log(this.selectsFromModal.cities, this.dataForModal);
            this.selectTime.start =
              this.dataForModal.vehicle_places__work_hours_start
            this.selectTime.end =
              this.dataForModal.vehicle_places__work_hours_end
            this.selectsFromModal.countries = this.dataForModal
              .vehicle_places__is_open
              ? { label: 'Открытая', value: 1 }
              : { label: 'Закрытая', value: 0 }
            console.log(this.dataForModal, 232323232323)
            this.showDeliveres = this.dataForModal.vehicle_places__is_delivery
          })
          .catch((e) => {
            console.log(e)
          })
      })
    },
    beforeClose() {
      this.updatedValues = {}
      this.dataForModal = {}
      this.localLoading = true
      ;(this.deliveryUpdateData = []), (this.capacitiesUpdateData = [])
      this.currentOpenedId = null
      this.selectsFromModal = {
        countries: [],
        region: [],
        time: [],
        integrator: [],
        cities: []
      }
      this.showDeliveres = false
      this.selectTime = { start: [], end: [] }
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    }
  },
  mounted() {},
  created() {},
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting'])
  }
}
</script>

<style lang="scss" scoped>
.table-delivery {
  text-align: center;
  font-size: 11px;
  width: 100%;

  & tr:first-child td {
    min-width: 150px;
    padding-top: 5px;
  }

  td {
    border: 1px solid #cacaca;
  }

  tr:nth-child(2n) {
    background: #f5f5f5;
    /* Цвет фона */
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding-left: 0;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: white;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #f5f5f5;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #23b14d;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #f5f5f5 !important;
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}
</style>
