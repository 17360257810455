<template>
  <BaseModal
    name="modalSmsPresets"
    width="600"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="() => {}"
    @before-open="loadData"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div
      class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
      :style="{}"
    >
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          @click="$modal.hide('modalSmsPresets')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p class="fw-bold mb-30 text-center">
          {{ dataLang['orders_page_btn_text1'] }}
        </p>
        <BaseInputNew
          placeholder=""
          width="400"
          class="mb-10 lh-1 wp-100"
          input-name="sms_presets__name"
          @input="updateValue"
          :horizontalTitle="false"
          value=""
          :additionalParams="{}"
          :name-sign="'Название'"
        />
        <BaseInputNew
          placeholder=""
          class="mb-10 lh-1 wp-100"
          width="400"
          input-name="sms_presets__text"
          @input="updateValue"
          :horizontalTitle="true"
          signClass="lh-1"
          value=""
          :additionalParams="{}"
          :name-sign="'Текст'"
        />
        <div class="d-flex justify-content-end gap-15 mt-15">
          <div
            @click="acceptDelete"
            class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            <!-- {{ dataLang['vehile_modal_btn_reset'] }} -->
            Удалить
          </div>
          <div
            @click="sendData"
            class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            {{ dataLang['vehile_modal_btn_save'] }}
          </div>
        </div>
        <div class="d-flex wp-100 mt-15">
          <ve-table
            style="width: 100%"
            :columns="columns"
            :table-data="tableData"
            :border-x="true"
            :border-y="true"
            row-key-field-name="sms_presets__id"
            ref="mainTable"
            :eventCustomOption="eventCustomOption"
            :checkbox-option="{
              hideSelectAll: true,
              selectedRowChange: selectedRowChange
            }"
            v-if="!localLoading"
            :clickHighlight="false"
            :cell-style-option="cellStyleOption"
            :rowStyleOption="{
              hoverHighlight: true
            }"
            :cellSelectionOption="{ enable: false }"
          />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import GeneralService from '@/api/GeneralService.js'
import { VeTable } from 'vue-easytable'
import BidsService from '@/api/BidsService'
import EventBus from '@/event-bus.js'
import OwnersService from '@/api/OwnersService'

// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  components: {
    VeTable
  },
  props: {
    stateLoading: {
      type: Boolean,
      default: false
    },
    optionalParams: {
      type: Object
    },
    typeReestr: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      selectLabel: '',
      listOptions: [],
      owner: [],
      localLoading: false,
      tableData: [],
      dataForEmit: {},
      activeSelectRowsMultiple: [],
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {}
          }
        }
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {}
      }
    }
  },
  methods: {
    loadData() {
      this.localLoading = true
      GeneralService.get(`/presets/sms`, {params: {
              where: JSON.stringify([{"field":"sms_presets__category_id","value":this.typeReestr}])
            }})
        .then((data) => {
          this.tableData = data.data.data.rows
          this.localLoading = false
        })
        .catch((e) => {
          console.log(e)
        })
    },
    acceptDelete() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.delete(`/presets/sms/` + this.activeSelectRowsMultiple)
          .then((data) => {
            this.$toast.success(data.data.message)
            this.localLoading = false
            this.loadData()
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    beforeClose(event) {
      this.dataForEmit = {}
    },
    updateValue(data) {
      this.dataForEmit[data.name] = data.value
      //   this.$refs[data.name].classList.remove('input-error')
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    sendData() {
      if (Object.keys(this.dataForEmit).length) {
        this.localLoading = true
        GeneralService.editOrCreate(`/presets/sms`, {
          ...this.dataForEmit,
          sms_presets__category_id: this.typeReestr
        })
          .then((data) => {
            this.loadData()
            this.$toast.success(data.data.message)
            this.dataForEmit = {}
            this.localLoading = false
          })
          .catch((e) => {
            this.$toast.success(e.response.data.message)
          })
      }
    }
  },
  mounted() {},
  created() {},
  beforeDestroy() {},

  computed: {
    ...mapState('translationModule', ['dataLang']),

    //     {
    //     "sms_presets__category_id": 2,
    //     "sms_presets__company_id": 2,
    //     "sms_presets__created_at": "2024-01-19 08:36:52",
    //     "sms_presets__id": 6,
    //     "sms_presets__name": "третий",
    //     "sms_presets__text": "Не пользуйтесь нашими услугами",
    //     "sms_presets__updated_at": "2024-01-19 11:36:52"
    // }

    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: '5%',
          align: 'center'
        },
        {
          field: 'sms_presets__name',
          key: '12',
          title: 'Название',
          align: 'left',
          width: '20%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },
        {
          field: 'sms_presets__text',
          key: '122',
          title: 'Текст',
          align: 'left',
          width: '75%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        }
      ]
    }
  }
}
</script>
