var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"aboutClientTab",staticClass:"container-fluid px-0 py-10 position-relative",staticStyle:{"height":"615px"}},[_c('p',{staticClass:"mt-10 mb-10 fsc-14 fw-bold"},[_vm._v(" "+_vm._s(_vm.dataLang['clients_register_text_82'])+" ")]),(_vm.localLoading)?_c('BaseLoader'):_vm._e(),(!_vm.localLoading)?_c('div',{staticClass:"d-flex justify-content-between align-items-center pb-15 gap-10"},[_c('div',{staticClass:"d-flex gap-5 align-items-center"},[_c('div',{staticClass:"base-button base-button--action py-10 gap-15",on:{"click":function($event){return _vm.$modal.show('AddAboutClientsModal')}}},[_c('img',{attrs:{"src":require(`@/assets/icons/add.svg`),"width":"13.5","height":"13.5"}}),_c('span',{staticClass:"fsc-14"},[_vm._v(" "+_vm._s(_vm.dataLang['clients_register_text_83']))])]),_c('div',{staticClass:"base-button base-button--action py-10 gap-15",on:{"click":_vm.acceptDelete}},[_c('img',{attrs:{"src":require(`@/assets/icons/adddel.svg`),"width":"13.5","height":"13.5"}}),_c('span',{staticClass:"fsc-14"},[_vm._v(" "+_vm._s(_vm.dataLang['clients_register_text_84']))])])])]):_vm._e(),(!_vm.localLoading)?_c('ve-table',{ref:"serviceTable",staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"table-data":_vm.dataTable,"border-x":true,"border-y":true,"sort-option":_vm.sortOption,"row-key-field-name":"client_about__id","checkbox-option":{
      hideSelectAll: true,
      selectedRowChange: _vm.selectedRowChange,
      selectedAllChange: _vm.selectedAllChange
    },"clickHighlight":false,"rowStyleOption":{
      hoverHighlight: true
    },"cellSelectionOption":{ enable: false }}}):_vm._e(),(!_vm.localLoading)?_c('AddAboutClientsModal',{attrs:{"optionalParams":{ id: _vm.tabInfo }}}):_vm._e(),_c('Teleport',{attrs:{"to":"body"}},[_c('SimpleModal',{attrs:{"size":"md","minHeight":320,"nameForAction":"acceptDeleteNote","modal-title":_vm.dataLang['vehicle_modal_service_modals_title_8']},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"fsc-20 mb-30"},[_vm._v(" "+_vm._s(_vm.dataLang['vehicle_modal_service_modals_warning4'])+" ")]),_c('div',{staticClass:"d-flex justify-content-center gap-30 mt-10 px-30 py-10"},[_c('div',{staticClass:"base-button py-15",on:{"click":_vm.closeDeleteModal}},[_vm._v(" "+_vm._s(_vm.dataLang['vehile_modal_btn_reset'])+" ")]),_c('div',{staticClass:"btn-primary btn fsc-14 ws-nowrap",on:{"click":_vm.deleteRow}},[_vm._v(" "+_vm._s(_vm.dataLang['vehile_modal_btn_delete_ok'])+" ")])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }