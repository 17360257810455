import http from "@/http-common.js";

class ContractsService {

  basePathApiService = "/contracts";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(id, editData) {
    delete editData.contracts__id
    delete editData.clients__id
    delete editData.employees__id
    delete editData.vehicle_places__id
    delete editData.vehicles__id
    delete editData.contracts__company_id
    delete editData.contracts__num
    delete editData.contracts__type
    return http.put(this.basePathApiService + `/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
 
}
export default new ContractsService();
