
<template>
  <div class="file-load" :class="[classes, computedClasses]">
    <label class="file-load__conteiner">
      <input type="file" @input="uploadFile" :accept="accept" />
      <div class="file-load__preview">
        <img v-if="preview" :src="require(`@/assets/${preview}`)" alt="" />
      </div>
      <a :href="fileData.result" target="_blank" class="file-load__pdf-loaded">
        <img :src="require(`@/assets/icons/pdf.png`)" width="40" height="50" />
      </a>
      <p
        v-if="!typeOfLoadedFile"
        :class="classForSingLoad"
        class="text-decoration-underline fsc-11 position-absolute text-center wp-100"
        style="top: calc(50% - 10px)"
      >
        Загрузить
      </p>
      <p
        class="file-load__current-position fsc-11"
        v-if="indexForPrint && !typeOfLoadedFile"
      >
        {{ indexForPrint }}
      </p>
    </label>
    <div @click="clickOnImage" v-if="!typeOfLoadedFile">
      <img :src="fileData.result" class="file-load__image-loaded" />
    </div>

    <div
      class="file-load__btn-del file-load__btn-del--hover"
      @click="removeFile"
    ></div>
  </div>
</template>

<script>
import { get, set } from 'lodash'

export default {
  components: {},
  props: {
    accept: {
      type: String
    },
    filePreview: {
      type: String
    },
    maxWeight: {
      type: Number
    },
    classForSingLoad: {
      type: String
    },
    maxXSize: {
      type: Number
    },
    maxYSize: {
      type: Number
    },
    additionalParams: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessageMaxWeight: {
      type: String
    },
    classes: {
      type: String
    },
    indexForPrint: {},
    preview: {
      type: String
    },
    typeOFFormData: {
      type: String,
      default: 'file'
    },
    specialType: {
      type: String
    }
  },
  data() {
    return {
      fileData: {
        result: this.filePreview,
        ...(this.filePreview && {
          type: this.filePreview.split('.').at(-1)
        })
      }
    }
  },
  methods: {
    clickOnImage() {
      this.$emit('clickOnImage')
    },
    removeFile(e) {
      ;(this.fileData = {
        result: '',
        type: ''
      }),
        this.$emit('remove-file', { ...this.additionalParams })
    },
    uploadFile(e) {
      let file = e.target.files[0]
      console.log(file)

      let formDataForSend = new FormData()
      formDataForSend.append(this.typeOFFormData, file)

      if (file.size / 1000 > this.maxWeight) {
        this.$toast.error(this.errorMessageMaxWeight)
        this.$emit('error-load', {
          ...this.additionalParams
        })
        return
      }
      let reader = new FileReader()
      this.typeFile = file.type.split('/').at(-1)
      console.log(this.typeFile)
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.fileData = {
          result: reader.result,
          type: file.type.split('/').at(-1)
        }
        this.$emit('load-file', {
          ...this.additionalParams,
          file: this.fileData.result,
          fileInFormData: formDataForSend,
          localFile: file
        })
      }
    }
  },
  mounted() {},

  created() {},
  computed: {
    computedClasses: {
      deep: true,
      get() {
        return {
          'file-load--file-loaded-pdf':
            this.fileData.type && this.fileData.type.split('/').at(-1) == 'pdf',
          'file-load--file-loaded-image':
            this.fileData.type && this.fileData.type.split('/').at(-1) !== 'pdf'
        }
      }
    },
    typeOfLoadedFile() {
      return this.fileData.type && this.fileData.type.split('/').at(-1) == 'pdf'
    }
  }
}
</script>
