<template>
  <div
    class="modal fade new-modal"
    tabindex="-1"
    :ref="nameForAction"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog position-relative"
      :class="[centered === false ? '' : 'modal-dialog-centered', defineSize()]"
    >
      <div
        v-if="stateLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{ 'min-height': `${minHeight}px` }"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30">
            {{ dataLang['vehicle_modal_service_btn_bids_parts'] }}
          </p>

          <div class="wp-100 mb-15" ref="request_services__text">
            <BaseTextarea
              placeholder=""
              class="wp-100 fsc-14 mb-5"
              signClass="fsc-14 text-muted"
              input-name="request_services__text"
              @onInput="updateValue"
              :minHeight="'160'"
              inputClass="fsc-14"
              :value="dataForEmit.request_services__text"
            >
            </BaseTextarea>
            <span class="text-muted fw-light fsc-14">{{
              dataLang['vehicle_modal_service_modals_warning5']
            }}</span>
          </div>

          <div class="d-flex justify-content-end gap-15">
            <div
              class="base-button px-20 rounded-10"
              @click="closeModalAndClearData()"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
            <div
              @click="sendData"
              class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
            >
              {{ dataLang['vehile_modal_btn_save'] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { mapActions, mapState, mapMutations } from 'vuex'
import BidsService from '@/api/BidsService.js'
import EventBus from '@/event-bus.js'
// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  props: {
    nameForAction: {
      type: String,
    },
    modalName: {},
    centered: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
    },
    stateLoading: {
      type: Boolean,
      default: false,
    },
    optionalParams: {
      type: Object,
    },
  },
  data() {
    return {
      thisModal: null,
      dataForEmit: {
        request_services__type_service_id: 2,
        request_services__text: '',
        vehicleId: this.optionalParams.id,
        // interval: null,
      },
    }
  },
  methods: {
    updateValue(data) {
      this.dataForEmit[data.name] = data.value
      this.$refs[data.name].classList.remove('input-error')
    },

    sendData() {
      BidsService.create(this.dataForEmit)
        .then((data) => {
          console.log(data)
          this.closeModalAndClearData()
          this.$toast.success('Запись добавлена')
          Object.keys(this.dataForEmit).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.remove('input-error')
            }
          })
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error(err.response.data.message)
          Object.keys(err.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
        })
    },

    closeModalAndClearData() {
      this.thisModal.hide()
      this.dataForEmit.request_services__text = ''
    },

    ...mapMutations('modalStackModule', [
      'modal_stack_add',
      'modal_stack_remove',
    ]),

    defineSize() {
      switch (this.size) {
        case 'sm':
          return 'modal-sm'
        case 'lg':
          return 'modal-lg'
        case 'xl':
          return 'modal-xl'
        case 'xxl':
          return 'modal-xxl'
      }
      return
    },
  },
  mounted() {
    this.thisModal = new Modal(this.$refs[this.nameForAction])
    EventBus.$on('openModal', (payload) => {
      if (payload.nameForAction === this.nameForAction) {
        this.modal_stack_add()
        this.thisModal.show()
        this.thisModal._element.style.zIndex = 1055 + this.modalStackPosition
        this.thisModal._backdrop._element.style.zIndex =
          1054 + this.modalStackPosition
      }
    })
    EventBus.$on('closeModal', (payload) => {
      if (payload.nameForAction === this.nameForAction) {
        this.thisModal.hide()
        //console.log(this.modalStackPosition)
      }
    })
    this.$refs[this.nameForAction].addEventListener('hide.bs.modal', () => {
      this.$emit('closeModal', { nameForAction: this.nameForAction })
      Object.keys(this.dataForEmit).forEach((elem, index) => {
        if (this.$refs[elem]) {
          this.$refs[elem].classList.remove('input-error')
        }
      })
      this.modal_stack_remove()
    })
  },
  beforeDestroy() {
    EventBus.$off('openModal')
    EventBus.$off('closeModal')
  },

  computed: {
    ...mapState('modalStackModule', ['modalStackPosition']),
    ...mapState('translationModule', ['dataLang']),
  },
}
</script>
