
import MenuService from "@/api/MenuService.js";
import Vue from "vue";
export const menuModule = {
  namespaced: true,
  state: () => ({
    menuData: [],
    menuDataByKey: {},
    loadStatus: '',
  }),

  getters: {},
  mutations: {
    get_all(state, data) {
      state.menuData = [...data]
    },
    
    load_request(state, status) {
      state.loadStatus = 'request'
    },
    load_success(state) {
      state.loadStatus = 'success'
    },
    load_error(state) {
      state.loadStatus= 'error'
    },

    load_menu_by_key(state, menu, index){
      let obj = {};
      obj[menu.menu__code] = {
        "title": menu.menu__item,
        "position": menu.menu__number,
      };
     
      Object.assign(state.menuDataByKey,obj);
    }
  },
  actions: { 
    getAll({ commit }) {
      commit("load_request");
      MenuService.getAll()
        .then((data) => {
          commit("get_all", data.data.data);
          commit("load_success");
        })
        .catch((error) => {
          commit("load_error");
        });
    },
    
    setMenuByKey({ commit }, menu) {
      commit("load_menu_by_key", menu);
      return true;
    }
  },
};
