<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>

      <div class="overflow-auto mt-15">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="vehicles__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          :checkbox-option="{
            hideSelectAll: false
          }"
          v-if="!localLoading"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>
    <div v-if="!localLoading">
      <gallery
        :images="gelleryList"
        :index="galleryExeples"
        @close="onCloseGallery"
      ></gallery>
    </div>
    <BaseModal
      name="selectTime"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="
        () => {
          copiedData = {}
        }
      "
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white h-100"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('selectTime')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input pb-60"
        >
          <p class="fw-bold mb-20 text-center" v-if="copiedData.modType == 3">
            {{ dataLang['vehicle_table_moderation_text13'] }}
          </p>

          <p class="fw-bold mb-20 text-center" v-if="copiedData.modType == 1">
            {{ dataLang['vehicle_table_moderation_text15'] }}
          </p>
          {{ prichina }}
          <div class="wp-100 mb-20" v-if="copiedData.modType == 1">


            <BaseTextarea
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="fsc-11"
            inputClass="fsc-11"
            @onInput="updateValue"
            minHeight="45"
            :maxLength="499"
            :resize="false"
            input-name="text"
            :value="prichina"
            :name-sign="
              dataLang[
                'vehicle_table_moderation_text14'
              ]
            "
          >
          </BaseTextarea>
          </div>
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('selectTime')"
                class="btn btn-sm btn-opposite text-uppercase px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehicle_table_moderation_text11'] }}
              </div>
              <div
                @click="acceptModeration"
                class="btn btn-sm btn-primary px-20 text-uppercase rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehicle_table_moderation_text12'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'

import Vue from 'vue'

import VueGallery from 'vue-gallery'
export default {
  components: {
    VeTable,
    gallery: VueGallery
  },

  data() {
    return {
      dataTimeSelect: { orders_from: [], orders_to: [] },
      selectsData: {
        types: [],
        statuses: [],
        ads: [],
        koef: []
      },
      prichina: '',
      dataForDatePicker: { orders__ends: [] },
      indexForOpenImage: null,
      gelleryList: [],
      selectsFilter: {
        wanted: []
      },

      copiedData: {},
      selects: [],

      selectsOptions: {
        type: [
          {
            value: 'clients_type_fiz',
            label: 'Частное (физическое) лицо'
          }
        ]
      },
      selectedType: {
        value: 'clients_type_fiz',
        label: 'Частное (физическое) лицо'
      },
      galleryExeples: null,
      messageError: [],
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      activePagePagination: {},
      rowStyleOption: {
        stripe: true
      },
      updateValueFilterObject: {},
      comissionState: false,
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {},
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (
            column.field == 'clients__is_wanted' &&
            Boolean(row.clients__is_wanted)
          ) {
            return 'client-status-danger'
          }
        }
      }
    }
  },
  methods: {
    onCloseGallery() {
      this.gelleryList = []
      this.galleryExeples = null
    },
    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues);
    },
    updateValueFilter({ value, name }) {
      if (name == 'clients__fiz_date_birth' && value.length > 9) {
        this.updateValueFilterObject[name] = value
          .split('.')
          .reverse()
          .join('-')
      } else {
        console.log(value, name)
        this.updateValueFilterObject[name] = value
      }
    },
    loadData(page, search) {
      this.localLoading = true
      Promise.all([]).then(() => {
        GeneralService.get(`/objects/moderation/table`)
          .then((data) => {
            console.log(data)
            this.dataTable = data.data.data.rows

            // clients__blacklist_gallery

            console.log(this.gelleryList)
            this.gelleryList = []
            this.localLoading = false
            this.copiedData = {}
            this.$modal.hide('selectTime')
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка')
            this.localLoading = false
            this.resetFilter()
          })
      })
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData(1)
      console.log(this.selectsData, this.$refs.serviceTable)
      this.selectsFilter = {
        wanted: []
      }
      this.dataTimeSelect = {
        orders_from: [],
        orders_to: []
      }
      this.updateValueFilterObject = {}
      this.dataForDatePicker = { orders__ends: [] }
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      console.log(this.$refs.globalDataFilter)
      this.$refs.globalDataFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.mod == 'phone' && { mod: 'phone' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },

    changeComission(data) {
      GeneralService.edit('/tariffs_car/setcomissiontype', {
        company_settings__tariff_table_comission_custom_or_top_cars: data
      })
        .then((data) => {
          this.loadData()
        })
        .catch((e) => console.log(e))
    },
    fillGallery(datas) {
      // row.clients__blacklist_gallery.map((item) => {
      //             if (item.client_blacklist_gallery__file) {
      //               this.gelleryList.push(item.client_blacklist_gallery__file)
      //             }
      //           })

      //           this.galleryExeples = 0
      //           console.log(this.galleryExeples)
      console.log(datas)
      GeneralService.get(`/objects/${datas.vehicles__id}/images`)
        .then((data) => {
          console.log(data)
          for (const key in data.data.data.rows) {
            if (data.data.data.rows[key].image.vehicle_gallery__file) {
              this.gelleryList.push(
                data.data.data.rows[key].image.vehicle_gallery__file
              )
            }
          }
          //     data.data.data.rows.forEach((elem) => {
          //     // elem.image
          //     // vehicle_gallery__file
          //     // this.galle
          //     this.gelleryList.push(elem.image.vehicle_gallery__file)
          this.galleryExeples = 0
          console.log(this.galleryExeples, this.gelleryList)
          //   })
        })
        .catch((e) => {
          console.log(e)
        })
    },

    setMod(data, modType) {
      this.copiedData = {
        modType: modType,
        ...data
      }
      this.$modal.show('selectTime')
    },
    acceptModeration() {
      GeneralService.edit(
        `/objects/moderation/${this.copiedData.vehicles__id}`,
        {
          vehicles__moderation_status_id: Number(this.copiedData.modType),
          ...(Object.values(this.updatedValues).length  && { text: this.updatedValues.text })
        }
      )
        .then((datas) => {
          console.log(datas)
          this.prichina = ''
          this.updatedValues = {}
          this.loadData()
        })
        .catch((e) => {
          this.loadData()
          console.log(e)
        })
    },
    prepareLabelsForSelects(data, keyDataFromFetch) {
      let out = []
      data.forEach((elem, index) => {
        out.push({ value: Object.keys(elem)[0], label: Object.values(elem)[0] })
      })
      console.log(out)
      return out
    },

    ...mapActions('clientBlackPagiation', ['getClientBlackAll'])
  },
  mounted() {},
  created() {
    Promise.all([
      //   }),
    ]).then(() => {
      this.loadData(1)
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('clientBlackPagiation', ['paginationClientBlackStateParams']),
    ...mapGetters('clientBlackPagiation', ['numPages']),
    columns() {
      return [
        {
          field: 'vehicles__moderation_status_updated_at',
          key: '12',
          title: this.dataLang['vehicle_table_moderation_text1'],
          align: 'center',
          width: '7%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.vehicles__moderation_status_updated_at
                  ? this.$time(row.vehicles__moderation_status_updated_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(`${this.userSetting.company__tempalte_date}`)
                  : ''}
              </span>
            )
          }
        },

        {
          field: 'companies__name',
          key: '13',
          title: this.dataLang['vehicle_table_moderation_text2'],
          align: 'center',
          width: '7%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },
        {
          field: 'cities__name',
          key: '14',
          title: this.dataLang['vehicle_table_moderation_text10'],
          align: 'center',
          width: '10%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },
        {
          field: 'employees__name',
          key: '15',
          title: this.dataLang['vehicle_table_moderation_text3'],
          align: 'center',
          width: '15%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },
        {
          field: 'vehicle_types__code',
          key: '1532',
          title: this.dataLang['vehicle_table_moderation_text4'],
          align: 'center',
          width: '6%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },
        {
          field: 'vehicles__id',
          key: '162',
          title: this.dataLang['vehicle_table_moderation_text5'],
          align: 'center',
          width: '6%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },
        {
          field: 'vehicles__name',
          key: '16',
          title: this.dataLang['vehicle_table_moderation_text6'],
          align: 'center',
          width: '12%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },

        {
          field: '',
          key: '1543',
          title: this.dataLang['vehicle_table_moderation_text7'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <img
                onClick={() => {
                  this.fillGallery(row)
                }}
                style={'cursor: pointer'}
                src={require(`@/assets/icons/icon-gallery.svg`)}
              />
            )
          }
        },
        {
          field: 'vehicles__namew',
          key: '1153',
          title: '',
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },

          renderBodyCell: ({ row, column, rowIndex }, h) => {
            // Пожалуйста, срочно свяжитесь с инициатором и помогите провести задержание этого клиента! Не говорите клиенту про розыск!
            return (
              <div
                class={'btn btn-sm fsc-11'}
                style={'background-color: #23B14D'}
                onClick={() => {
                  this.setMod(row, 3)
                }}
              >
                {this.dataLang['vehicle_table_moderation_text8']}
              </div>
            )
          }
        },

        {
          field: 'vehicles__named',
          key: '15532',
          title: '',
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },

          renderBodyCell: ({ row, column, rowIndex }, h) => {
            // Пожалуйста, срочно свяжитесь с инициатором и помогите провести задержание этого клиента! Не говорите клиенту про розыск!
            return (
              <div
                class={'btn btn-danger btn-sm fsc-11'}
                onClick={() => {
                  this.setMod(row, 1)
                }}
              >
                {this.dataLang['vehicle_table_moderation_text9']}
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>

<style>
.client-status-danger {
  background-color: #ff2400 !important;
  color: white !important;
}
</style>
