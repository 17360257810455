import http from "@/http-common.js";

class ChecklistService {

  basePathApiService = "/checklist/progress";
  basePathApiServiceDone = "/checklist/progress/donelist";
 
  getAll(type, params) {
    return http.get(this.basePathApiService,  params);
  } 
  getAllDone(params) {
    return http.get(this.basePathApiServiceDone,  params);
  }
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(id, editData) {
    return http.put(this.basePathApiService + '/comment' +`/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }

}
export default new ChecklistService();
