import http from "@/http-common.js";

class FundsService {

  basePathApiService = "/finreestr-funds";
 
  getOne(id, params = null){
    return http.get(this.basePathApiService + `/${id}`, params);
  }

  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 

  getTransitFunds(selectedFund){
    // let promise = null;
    // if(selectedFund){
    //   promise = this.getOne(selectedFund.id, 
    //     {
    //       params: {
    //         "fields": "finreestr_funds__id,finreestr_funds__name, finreestr_funds__is_coming",
    //         "alias": 0
    //       }
    //     }
    //   )
    // }else {
    //   promise = new Promise((resolve, reject) => {
    //     resolve();
    //   })
    // }

    // return promise.then((data) => {
      let params = {
        "fields": "finreestr_funds__id,finreestr_funds__name,finreestr_funds__is_coming",
      }

      // if(data && data?.data?.data?.rows && data.data.data.rows.length){
      //   console.log(data.data.data)
      //   params.where = JSON.stringify([
      //     {field:"is_coming", value: !Number(data.data.data.rows[0].finreestr_funds__is_coming)}
      //   ])
      // }

      // console.log(params)
      return this.getAll({
        params: params
      });
    // })
  } 

  getComingFunds(){
    return this.getAll({
      params: {
        "where": JSON.stringify([
          {"field":"finreestr_funds__is_coming", "value":1}
        ]),
        "fields": "finreestr_funds__id,finreestr_funds__name, finreestr_funds__is_coming",
        
      }
    })
  } 

  getSpendFunds(){
    return this.getAll({
      params: {
        "where": JSON.stringify([
          {"field":"finreestr_funds__is_coming", "value":0}
        ]),
        "fields": "finreestr_funds__id,finreestr_funds__name, finreestr_funds__is_coming",
       
      }
    })
  }

  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  
  editMultiple(id, editData) {
    return http.put(this.basePathApiService + `/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
 
}
export default new FundsService();
