<template>
  <BaseModal
    name="modalSendSomeSMS"
    width="420"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="clearData"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none overflow-hidden"
  >
    <div
      v-if="localLoading"
      class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
      style="top: 0; left: 0; z-index: 500"
    >
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
    <div
      class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
      :style="{}"
    >
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          @click="$modal.hide('modalSendSomeSMS')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p class="fw-bold mb-30 text-center">
          {{ dataLang['orders_page_btn_text3'] }}
        </p>

        <div class="d-flex flex-column align-items-center">
          <div>
            <BaseInputNew
              placeholder=""
              :width="'295'"
              class="mb-10 gap-10"
              input-name="phone"
              horizontal="true"
              @input="updateValue"
              inputRef="phone"
              inputMask="+{7}(000)000-00-00"
              value=""
              maxLength="16"
              :name-sign="dataLang['bids_text2']"
            >
            </BaseInputNew>
            <BaseTextarea
              placeholder=""
              class="wp-100 fsc-14 mb-5"
              signClass="fsc-11 text-muted"
              input-name="text"
              @onInput="updateValue"
              :minHeight="'160'"
              inputClass="fsc-11"
              :name-sign="dataLang['orders_page_btn_text88']"
              value=""
            >
            </BaseTextarea>
          </div>
          <div class="mb-10 fsc-14">
            <span class="fw-semi-bold"
              >{{ dataLang['orders-page__table__header_order_text14'] }}:
            </span>
            <span
              >{{ smsStat.countSymbols }}
              {{ dataLang['orders-page__modal_sms'] }}
              {{ smsStat.countSms }} SMS</span
            >
          </div>
          <div class="d-flex justify-content-end gap-15">
            <div
              @click="$modal.hide('modalSendSomeSMS')"
              class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
            <div
              @click="sendData"
              class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
            >
              {{ dataLang['vehile_modal_btn_save'] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import GeneralService from '@/api/GeneralService.js'
export default {
  components: {},

  props: {},
  data() {
    return {
      updatedValues: {},
      localLoading: false,
      smsStat: {
        countSymbols: 0,
        countSms: 0
      }
    }
  },
  methods: {
    clearData() {
      this.updatedValues = {}
    },
    sendData() {
      if (this.updatedValues.text && this.updatedValues.phone) {
        this.localLoading = true
        GeneralService.editOrCreate('/sms', this.updatedValues)
          .then((data) => {
            this.$toast.success(data.data.message)
            this.$modal.hide('modalSendSomeSMS')
            this.localLoading = false
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message)
          })
      }
    },
    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
      if (data.name == 'text') {
        this.updateSmsStat(data.value)
      }
    },
    updateSmsStat(symbols) {
      this.smsStat.countSymbols = symbols.length
      this.smsStat.countSms = Math.ceil(symbols.length / 70)
    }
  },
  mounted() {},

  created() {},
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting'])
  }
}
</script>
