import http from '@/http-common.js'

class ObjectsService {
  basePathApiService = '/objects'
  getAll(filterAndPagination) {
    console.log(filterAndPagination.params);
    return http.get(this.basePathApiService + filterAndPagination.url, {
      params: {
        ...filterAndPagination.params
      },
    })
  }
  getOne(id) {
    return http.get(this.basePathApiService + `/${id}`)
  }

  createService(id, data) {
    return http.post(this.basePathApiService + `/${id}/services`, data)
  }
  createNote(id, data) {
    return http.post(this.basePathApiService + `/${id}/notes`, data)
  }
  createComment(id, data) {
    return http.post(this.basePathApiService + `/${id}/comments`, data)
  }
  update(id, updateData) {
    return http.put(this.basePathApiService + `/${id}`, updateData)
  }
  
  delete(url, parentId, ids) {
    console.log(url, parentId, ids);
    return http.delete(this.basePathApiService + `/${parentId}/${url}/${ids}`)
  }
  toExcel(id, filterAndPagination) {
    return http.get(this.basePathApiService + `/${id}/services/xlsx`, {
      params: {
        ...filterAndPagination
      },
      responseType: 'blob',
    })
  }
}
export default new ObjectsService()






