import http from "@/http-common.js";

class BidsService {

  basePathApiService = "/bids";

  getAll(params) {
    return http.get(this.basePathApiService, params);
  }
  create(data) {
    return http.post(this.basePathApiService, data);
  }
  delete(ids) {
    return http.delete(this.basePathApiService + `/${ids}`);
  }

}
export default new BidsService();
