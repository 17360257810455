<template>
  <BaseModal
    name="TariffModalCreate"
    :width="innerWidth > 1920 ? '1600' : '1060'"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="() => {}"
    @before-open="loadData"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div class="bg-system-main-block p-30 position-relative">
      <BaseLoader v-if="localLoading" style="z-index: 1000"></BaseLoader>
      <p class="fw-bold mb-5">
        {{ dataLang['tariff_register_text_97'] }}
      </p>
      <div class="d-flex gap-10 align-items-center mb-5">
        <div class="base-button base-button--action" @click="addNewGroup">
          <img
            :src="require(`@/assets/icons/add.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14">{{ dataLang['registry_ads_text1'] }}</span>
        </div>
        <div class="base-button base-button--action" @click="deleteGroup">
          <img
            :src="require(`@/assets/icons/adddel.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14">{{ dataLang['registry_ads_text2'] }}</span>
        </div>
      </div>
      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="dataTable"
        :border-x="true"
        :border-y="true"
        class="ve-table-hide"
        row-key-field-name="tariff_groups_for_car__id"
        ref="mainTable"
        :eventCustomOption="eventCustomOption"
        :checkbox-option="checkboxOption"
        :clickHighlight="false"
        :cell-style-option="cellStyleOption"
        :editOption="editOption"
        :rowStyleOption="{
          hoverHighlight: false
        }"
        :cellSelectionOption="{ enable: true }"
      >
      </ve-table>
      <div class="d-flex gap-10 justify-content-end mt-15">
        <div
          class="base-button base-button--action py-10 px-30 text-uppercase"
          @click="sendData"
        >
          <span class="fsc-14">{{ dataLang['tariff_register_text_86'] }}</span>
        </div>
        <div
          class="base-button base-button--action py-10 px-30 text-uppercase"
          @click="$modal.hide('TariffModalCreate')"
        >
          <span class="fsc-14">{{ dataLang['tariff_register_text_87'] }}</span>
        </div>
      </div>
    </div>
    <BaseModal
      name="acceptDeleteWithPin"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="pincodeModalHandler"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteWithPin')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">Внимание!</p>

          <div class="d-flex flex-column align-items-center">
            <!-- <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('acceptDeleteWithPin')"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div> -->
            <p class="text-center">{{ messageError }}</p>
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              :input-name="'pincode'"
              @input="(data) => (pincode = data.value)"
              value=""
              name-sign=""
            >
            </BaseInputNew>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('acceptDeleteWithPin')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="deleteGroup"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                Удалить
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import GeneralService from '@/api/GeneralService.js'
import { VeTable } from 'vue-easytable'
import BidsService from '@/api/BidsService'
import EventBus from '@/event-bus.js'
import OwnersService from '@/api/OwnersService'
import { Tooltip } from 'bootstrap'

export default {
  components: {
    VeTable
  },
  props: {},
  data() {
    return {
      localLoading: true,
      updatedValues: [],
      dataTable: [],
      innerWidth: window.innerWidth,
      activeSelectRowsMultiple: [],
      pincode: null,
      messageError: '',
      checkboxOption: {
        hideSelectAll: true,
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          console.log(selectedRowKeys)
          this.activeSelectRowsMultiple = selectedRowKeys
        },
        // selected all change event
        selectedAllChange: ({ row, isSelected, selectedRowKeys }) => {
          console.log(selectedRowKeys)
          this.activeSelectRowsMultiple = selectedRowKeys
        }
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              // console.log(event, row, rowIndex)
              // this.$modal.show('EditsTariffModal', {
              //   id: row.tariff_for_car__id
              // })
            }
          }
        }
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {}
      },
      editOption: {
        beforeStartCellEditing: ({ row, column, cellValue }) => {
          console.log(this.userSetting.company_lc)
          if (row.tariff_groups_for_car__is_eternal) {
            if (this.userSetting.company_lc == 1) {
              return true
            } else {
              return false
            }
          }
          return true
        },
        beforeCellValueChange: ({ row, column, changeValue }) => {
          if (
            (column.field !== 'tariff_groups_for_car__name') ==
            !/^\d+$/.test(changeValue)
          ) {
            this.$toast.error('Для вообда доступны только цифры')
            return false
          }
        },
        cellValueChange: ({ row, column }) => {
          let indexed = this.updatedValues.findIndex(
            (x) => x.id == row.tariff_groups_for_car__id
          )
          if (indexed !== -1) {
            this.updatedValues[indexed][column.field] =
              column.field == 'tariff_groups_for_car__name'
                ? row[column.field]
                : Number(row[column.field])
          } else {
            this.updatedValues.push({
              id: row.tariff_groups_for_car__id,
              [column.field]:
                column.field == 'tariff_groups_for_car__name'
                  ? row[column.field]
                  : Number(row[column.field])
            })
          }
          console.log(this.updatedValues)
          console.log('cellValueChange row::', row)
          console.log('cellValueChange column::', column)
        }
      }
    }
  },
  methods: {
    loadData(params) {
      this.localLoading = true
      GeneralService.get(`/tariffs_groups_car`)
        .then((data) => {
          console.log(data)
          this.updatedValues = []
          this.dataTable = data.data.rows.reverse()
          this.localLoading = false
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message)
        })

      //   /tariffs_groups_car
    },
    sendData() {
      if (this.updatedValues.length) {
        GeneralService.edit(`/tariffs_groups_car`, this.updatedValues)
          .then((data) => {
            EventBus.$emit('updateContentDataTariff')
            this.$toast.success(data.data.message)
            this.$modal.hide('TariffModalCreate')
            this.loadData()
          })
          .catch((e) => {
            this.loadData()
            this.$toast.error(e.response.data.message)
          })
      } else {
        this.$modal.hide('TariffModalCreate')
      }
    },
    addNewGroup() {
      this.localLoading = true
      Promise.all([
        this.updatedValues.length
          ? GeneralService.edit(`/tariffs_groups_car`, this.updatedValues)
              .then((data) => {
                EventBus.$emit('updateContentDataTariff')
                this.$toast.success(data.data.message)
                this.loadData()
              })
              .catch((e) => {
                this.loadData()
                this.$toast.error(e.response.data.message)
              })
          : ''
      ]).then(
        GeneralService.editOrCreate(`/tariffs_groups_car`)
          .then(() => {
            EventBus.$emit('updateContentDataTariff')
            this.loadData()
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      )
    },
    deleteGroup() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.delete(
          `/tariffs_groups_car/${this.activeSelectRowsMultiple}`,
          {
            ...(this.pincode && { pincode: this.pincode })
          }
        )
          .then(() => {
            EventBus.$emit('updateContentDataTariff')
            this.$modal.hide('acceptDeleteWithPin')
            this.loadData()
          })
          .catch((e) => {
            console.log(e.response.status)
            if (e.response.status == 409) {
              this.$modal.show('acceptDeleteWithPin')
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
            } else if (e.response.status == 400) {
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
              this.$modal.hide('acceptDeleteWithPin')
            }
          })
      } else {
        this.$toast.error('Выберите хотя бы одно поле')
      }
    },
    beforeClose(event) {
      this.currentOpened = null
      this.dataForModal = {}
      this.updatedValues = {}
      this.activeSelectRowsMultiple = []
    },
    updateValue(data) {
      this.updatedValues[data.name] = data.value
      //   this.$refs[data.name].classList.remove('input-error')
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.messageError = ''
    }
  },
  mounted() {
    console.log(this.innerWidth)
  },
  created() {},
  beforeDestroy() {},

  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    columns() {
      return [
        {
          field: '',
          key: 'a',
          type: 'checkbox',
          title: '',
          width: '3%',
          operationColumn: true,
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span></span>
          }
        },
        {
          title: this.dataLang['tariff_register_text_6'],
          children: [
            {
              title: this.dataLang['tariff_register_text_46'],
              align: 'left',
              children: [
                {
                  field: 'tariff_groups_for_car__name',
                  key: 'tariff_groups_for_car__name',
                  title: '',
                  align: 'left',
                  width: '3%',
                  edit: true,
                  // tariff_groups_for_car__is_eternal
                  // edit: this.userSetting.company__lc == 1,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_47'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t1',
                      key: 'tariff_groups_for_car__koef_t1',
                      title: this.dataLang['tariff_register_text_65'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_48'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t2',
                      key: 'tariff_groups_for_car__koef_t2',
                      title: this.dataLang['tariff_register_text_66'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_49'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t3',
                      key: 'tariff_groups_for_car__koef_t3',
                      title: this.dataLang['tariff_register_text_67'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_50'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t4',
                      key: '12234',
                      title: this.dataLang['tariff_register_text_68'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_51'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t5',
                      key: '11232',
                      title: this.dataLang['tariff_register_text_69'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_52'],
              children: [
                {
                  title: '300 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t6',
                      key: '34312',
                      title: this.dataLang['tariff_register_text_70'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_53'],
              children: [
                {
                  title: '225 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t7',
                      key: '15432',
                      title: this.dataLang['tariff_register_text_71'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_54'],
              children: [
                {
                  title: '225 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t8',
                      key: '1234',
                      title: this.dataLang['tariff_register_text_72'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_55'],
              children: [
                {
                  title: '125 ' + this.dataLang['tariff_register_text_64'],
                  children: [
                    {
                      field: 'tariff_groups_for_car__koef_t9',
                      key: '1245',
                      title: this.dataLang['tariff_register_text_73'],
                      align: 'center',
                      width: '3%',
                      edit: true,
                      ellipsis: {
                        showTitle: true,
                        lineClamp: 1
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_7'],
          children: [
            {
              title: this.dataLang['tariff_register_text_56'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_km_up',
                  key: '1762',
                  title: this.dataLang['tariff_register_text_74'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_8'],
          children: [
            {
              title: this.dataLang['tariff_register_text_57'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_fr',
                  key: '1552',
                  title: this.dataLang['tariff_register_text_75'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_58'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_day_fr',
                  key: '12325',
                  title: this.dataLang['tariff_register_text_76'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_44'],
          children: [
            {
              title: this.dataLang['tariff_register_text_59'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_sub_1',
                  key: '1242',
                  title: this.dataLang['tariff_register_text_77'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                },

                {
                  field: 'tariff_groups_for_car__koef_sub_3',
                  key: '1212',
                  title: this.dataLang['tariff_register_text_78'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                },
                {
                  field: 'tariff_groups_for_car__koef_sub_6',
                  key: '12332',
                  title: this.dataLang['tariff_register_text_79'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                },
                {
                  field: 'tariff_groups_for_car__koef_sub_9',
                  key: '12172',
                  title: this.dataLang['tariff_register_text_80'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                },
                {
                  field: 'tariff_groups_for_car__koef_sub_12',
                  key: '12334',
                  title: this.dataLang['tariff_register_text_81'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_45'],
          children: [
            {
              title: this.dataLang['tariff_register_text_60'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_buy_12',
                  key: '12423',
                  title: this.dataLang['tariff_register_text_82'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_61'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_buy_24',
                  key: '12122',
                  title: this.dataLang['tariff_register_text_83'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            },
            {
              title: this.dataLang['tariff_register_text_62'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_buy_36',
                  key: '123332',
                  title: this.dataLang['tariff_register_text_84'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.dataLang['tariff_register_text_11'],
          children: [
            {
              title: this.dataLang['tariff_register_text_33'],
              children: [
                {
                  field: 'tariff_groups_for_car__koef_drive',
                  key: '1245233',
                  title: this.dataLang['tariff_register_text_85'],
                  align: 'center',
                  width: '3%',
                  edit: true,
                  ellipsis: {
                    showTitle: true,
                    lineClamp: 1
                  }
                }
              ]
            }
          ]
        }
      ]
    }
    //     {
    //     "sms_presets__category_id": 2,
    //     "sms_presets__company_id": 2,
    //     "sms_presets__created_at": "2024-01-19 08:36:52",
    //     "sms_presets__id": 6,
    //     "sms_presets__name": "третий",
    //     "sms_presets__text": "Не пользуйтесь нашими услугами",
    //     "sms_presets__updated_at": "2024-01-19 11:36:52"
    // }
  }
}
</script>
