import AdSourceService from '@/api/AdSourceService.js'
import Vue from 'vue'
export const adSourceModule = {
  namespaced: true,
  state: () => ({
    adSourceData: [],
    errorList: {},
    adSourceLoadStatus: 'request',
    paginationAdSourceStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: (state) =>
      Math.ceil(
        state.paginationAdSourceStateParams.total /
          state.paginationAdSourceStateParams.limit
      )
  },
  mutations: {
    get_all(state, data) {
      state.adSourceData = [...data]
    },

    create(state, data) {
      state.adSourceData.unshift(data[0])
    },

    edit_singular(state, data) {
      let indexed = state.adSourceData.findIndex((x) => x.ads__id == data.id)
      Object.assign(state.adSourceData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.adSourceData.findIndex((x) => x.ads__id == id)
      state.adSourceData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationAdSourceStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.adSourceLoadStatus = 'request'
    },
    load_success(state) {
      state.adSourceLoadStatus = 'success'
    },
    load_error(state) {
      state.adSourceLoadStatus = 'error'
    },
    update_error_list(state, errors) {
      state.errorList = errors
    }
  },
  actions: {
    clearErrorList({ commit, state }, params) {
      commit('update_error_list', {})
    },

    getAdSourceAll({ commit }, params) {
      commit('load_request')

      AdSourceService.getAll(params)
        .then((data) => {
          commit('update_pagination', data.data.data)
          commit('get_all', data.data.data.rows)
          commit('load_success')
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error(error.response.data.message)
          commit('load_error')
        })
    },

    createAdSourceData({ commit }, { data, callback }) {
      commit('update_error_list', {})
      AdSourceService.create(data)
        .then((response_data) => {
          if (callback) {
            callback()
          }
          console.log(response_data)
          commit('create', response_data.data.data.rows)
          commit('load_success')
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          commit('update_error_list', error.response.data.errors)
          commit('load_error')
          console.log(error)
          Vue.$toast.error(error.response.data.message)
          Object.values(error.response.data.errors).forEach((element) => {
            console.log(element);
            // state.adSourceData.find(x => x.ads__id == element.data.id).ads__name
            Vue.$toast.error(element[0])
          })
        })
    },

    editAdSourceSingular({ commit }, { id, dataEdit, callback }) {
      commit('load_request')
      commit('update_error_list', {})
      console.log(dataEdit)
      AdSourceService.edit(id, dataEdit)
        .then((data) => {
          if (callback) {
            callback()
          }
          commit('edit_singular', { id, dataEdit })
          commit('load_success')
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          commit('update_error_list', error.response.data.errors)
          commit('load_error')
          Vue.$toast.error(error.response.data.message)
          Object.values(error.response.data.errors).forEach((element) => {
            // state.adSourceData.find(x => x.ads__id == element.data.id).ads__name
            Vue.$toast.error(element[0])
          })
        })
    },

    deleteAdSourceData({ commit, dispatch, state }, ids) {
      commit('load_request')
      AdSourceService.delete(ids)
        .then((data) => {
          dispatch('getAdSourceAll', {
            params: {
              page: 1,
              limit: 100
            }
          })
          commit('load_success')
          Vue.$toast.success(data.data.message)
        })
        .catch((error) => {
          console.log(error)
          commit('load_error')
          //   [ ads__id
          //     {
          //         "message": "Есть заказы с этим источником",
          //         "data": {
          //             "id": "65",
          //             "countOrders": 31
          //         }
          //     },
          //     {
          //         "message": "Есть заказы с этим источником",
          //         "data": {
          //             "id": "10",
          //             "countOrders": 4
          //         }
          //     },
          //     {
          //         "message": "Источник помечен как неудаляемый",
          //         "data": {
          //             "id": "3"
          //         }
          //     }
          // ]
          error.response.data.errors.forEach((element) => {
            // state.adSourceData.find(x => x.ads__id == element.data.id).ads__name
            Vue.$toast.error(`${
              state.adSourceData.find((x) => x.ads__id == element.data.id)
                .ads__name
            } ||
             ${element.message}

             `)
          })
          dispatch('getAdSourceAll', {
            params: {
              page: 1,
              limit: 100
            }
          })
        })
    }
  }
}
