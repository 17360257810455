<template>
  <div class="h-100" ref="settingTab" v-if="!loadingState && dataForModal">
    <p class="fw-semi-bold fsc-24 text-center mb-10">
      {{ dataLang['register_objects_car_text_9'] }}
    </p>
    <div class="row gx-20">
      <div class="col-3 h-100">
        <div class="d-flex flex-column  h-100">
          <div class="system-block border mb-10 p-15  h-100">
            <p class="fw-semi-bold fsc-14 text-center mb-15">
              {{ dataLang['register_objects_car_text_10'] }}
            </p>
            <div class="mb-15">
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_14'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_16']
                }}</span>
              </div>
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_15'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_16']
                }}</span>
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 mb-15 border-disabled"
              style="
                background-color: #e8feee;
                height: 135px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_is_no_driver"
                  v-model="dataForModal.vehicles__rent_city_is_no_driver"
                  signClass="fsc-12 text-dark fw-bold"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  boxClass="bg-white"
                  name="vehicles__rent_city_is_no_driver"
                  >{{
                    dataLang['vehicle_modal_settings_text2']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_city_no_driver_min_days"
                class=""
                v-if="dataForModal?.vehicles__rent_city_is_no_driver"
                style="width: 208px"
              >
                <BaseInputNew
                  placeholder=""
                  width="39"
                  input-name="vehicles__rent_city_no_driver_min_days"
                  @input="updateValue"
                  signClass="fsc-12 text-dark"
                  :horizontalTitle="true"
                  class="flex-row-reverse"
                  :value="dataForModal.vehicles__rent_city_no_driver_min_days"
                  :additionalParams="{}"
                  :name-sign="dataLang['register_objects_car_text_23']"
                >
                </BaseInputNew>
              </div>
              <div
                ref="vehicles__rent_city_is_subscription"
                class=""
                v-if="dataForModal?.vehicles__rent_city_is_no_driver"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_is_subscription"
                  v-model="dataForModal.vehicles__rent_city_is_subscription"
                  signClass="fsc-12 text-dark"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  boxClass="bg-white"
                  name="vehicles__rent_city_is_subscription"
                  >{{
                    dataLang['vehicle_modal_settings_text40']
                  }}</BaseCheckboxV2
                >
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 mb-15 border-disabled"
              style="
                background-color: #E3F0FF;
                height: 136px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_is_with_driver"
                  v-model="dataForModal.vehicles__rent_city_is_with_driver"
                  signClass="fsc-12 text-dark fw-bold"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  name="isActive"
                  boxClass="bg-white"
                  >{{
                    dataLang['vehicle_modal_settings_text5']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_city_with_driver_min_hour"
                class=""
                v-if="dataForModal?.vehicles__rent_city_is_with_driver"
              >
                <BaseInputNew
                  placeholder=""
                  width="39"
                  input-name="vehicles__rent_city_with_driver_min_hour"
                  @input="updateValue"
                  signClass="fsc-12 text-dark"
                  :horizontalTitle="true"
                  class="flex-row-reverse"
                  style="width: 210px"
                  :value="dataForModal.vehicles__rent_city_with_driver_min_hour"
                  :additionalParams="{}"
                  :name-sign="dataLang['register_objects_car_text_25']"
                >
                </BaseInputNew>
              </div>
              <div
                ref="vehicles__rent_city_with_driver_km_in_hour"
                class=""
                v-if="dataForModal?.vehicles__rent_city_is_with_driver"
              >
                <BaseInputNew
                  :width="'39'"
                  input-name="vehicles__rent_city_with_driver_km_in_hour"
                  @input="updateValue"
                  signClass="fsc-12 text-dark"
                  :horizontalTitle="true"
                  class="flex-row-reverse"
                  style="width: 214px"
                  :value="
                    dataForModal.vehicles__rent_city_with_driver_km_in_hour
                  "
                  :additionalParams="{}"
                  :name-sign="dataLang['register_objects_car_text_26']"
                >
                </BaseInputNew>
              </div>
              <div
                ref="vehicles__rent_city_with_driver_plus_hour"
                class=""
                v-if="dataForModal?.vehicles__rent_city_is_with_driver"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_with_driver_plus_hour"
                  v-model="
                    dataForModal.vehicles__rent_city_with_driver_plus_hour
                  "
                  signClass="fsc-12 text-dark"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  boxClass="bg-white"
                  >{{
                    dataLang['register_objects_car_text_27']
                  }}</BaseCheckboxV2
                >
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 border-disabled"
              style="
                background-color: #FFF5D9;
                height: 135px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_is_buyout"
                  v-model="dataForModal.vehicles__rent_city_is_buyout"
                  signClass="fsc-12 text-dark fw-bold"
                  boxClass="bg-white"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  name="isActive"
                  >{{
                    dataLang['vehicle_modal_settings_text9']
                  }}</BaseCheckboxV2
                >
              </div>

              <div
                ref="vehicles__rent_city_is_buyout_12
"
                v-if="dataForModal?.vehicles__rent_city_is_buyout"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_is_buyout_12
"
                  v-model="dataForModal.vehicles__rent_city_is_buyout_12"
                  boxClass="bg-white"
                  signClass="fsc-12 text-dark"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  >{{
                    dataLang['vehicle_modal_settings_text10']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_city_is_buyout_24"
                v-if="dataForModal?.vehicles__rent_city_is_buyout"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_is_buyout_24"
                  v-model="dataForModal.vehicles__rent_city_is_buyout_24"
                  signClass="fsc-12 text-dark"
                  boxClass="bg-white"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  >{{
                    dataLang['vehicle_modal_settings_text11']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_city_is_buyout_36"
                class=""
                v-if="dataForModal?.vehicles__rent_city_is_buyout"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_city_is_buyout_36"
                  v-model="dataForModal.vehicles__rent_city_is_buyout_36"
                  signClass="fsc-12 text-dark"
                  boxClass="bg-white"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  >{{
                    dataLang['vehicle_modal_settings_text12']
                  }}</BaseCheckboxV2
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 h-100">
        <div class="d-flex flex-column">
          <div class="system-block border mb-10 p-15">
            <p class="fw-semi-bold fsc-14 text-center mb-15">
              {{ dataLang['register_objects_car_text_11'] }}
            </p>
            <div class="mb-15">
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_14'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_17']
                }}</span>
              </div>
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_15'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_17']
                }}</span>
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 mb-15 border-disabled"
              style="
                background-color: #e8feee;
                height: 135px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_is_no_driver"
                  v-model="dataForModal.vehicles__rent_sgs_is_no_driver"
                  signClass="fsc-12 text-dark fw-bold"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  boxClass="bg-white"
                  name="vehicles__rent_sgs_is_no_driver"
                  >{{
                    dataLang['vehicle_modal_settings_text2']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_sgs_no_driver_min_days"
                class=""
                v-if="dataForModal?.vehicles__rent_sgs_is_no_driver"
              >
                <BaseInputNew
                  placeholder=""
                  width="39"
                  input-name="vehicles__rent_sgs_no_driver_min_days"
                  @input="updateValue"
                  signClass="fsc-12 text-dark"
                  :horizontalTitle="true"
                  class="flex-row-reverse"
                  style="width: 208px"
                  :value="dataForModal.vehicles__rent_sgs_no_driver_min_days"
                  :additionalParams="{}"
                  :name-sign="dataLang['register_objects_car_text_23']"
                >
                </BaseInputNew>
              </div>
              <div
                ref="vehicles__rent_sgs_is_subscription"
                class=""
                v-if="dataForModal?.vehicles__rent_sgs_is_no_driver"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_is_subscription"
                  v-model="dataForModal.vehicles__rent_sgs_is_subscription"
                  signClass="fsc-12 text-dark"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  boxClass="bg-white"
                  name="vehicles__rent_sgs_is_subscription"
                  >{{
                    dataLang['vehicle_modal_settings_text40']
                  }}</BaseCheckboxV2
                >
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 mb-15 border-disabled"
              style="
                background-color: #E3F0FF;
                height: 136px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_is_with_driver"
                  v-model="dataForModal.vehicles__rent_sgs_is_with_driver"
                  signClass="fsc-12 text-dark fw-bold"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  name="isActive"
                  boxClass="bg-white"
                  >{{
                    dataLang['vehicle_modal_settings_text5']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_sgs_with_driver_min_hour"
                class=""
                v-if="dataForModal?.vehicles__rent_sgs_is_with_driver"
              >
                <BaseInputNew
                  placeholder=""
                  width="39"
                  input-name="vehicles__rent_sgs_with_driver_min_hour"
                  @input="updateValue"
                  signClass="fsc-12 text-dark"
                  :horizontalTitle="true"
                  class="flex-row-reverse"
                  style="width: 210px"
                  :value="dataForModal.vehicles__rent_sgs_with_driver_min_hour"
                  :additionalParams="{}"
                  :name-sign="dataLang['register_objects_car_text_25']"
                >
                </BaseInputNew>
              </div>
              <div
                ref="vehicles__rent_sgs_with_driver_km_in_hour"
                class=""
                v-if="dataForModal?.vehicles__rent_sgs_is_with_driver"
              >
                <BaseInputNew
                  :width="'39'"
                  input-name="vehicles__rent_sgs_with_driver_km_in_hour"
                  @input="updateValue"
                  signClass="fsc-12 text-dark"
                  :horizontalTitle="true"
                  class="flex-row-reverse"
                  style="width: 214px"
                  :value="
                    dataForModal.vehicles__rent_sgs_with_driver_km_in_hour
                  "
                  :additionalParams="{}"
                  :name-sign="dataLang['register_objects_car_text_26']"
                >
                </BaseInputNew>
              </div>
              <div
                ref="vehicles__rent_sgs_with_driver_plus_hour"
                class=""
                v-if="dataForModal?.vehicles__rent_sgs_is_with_driver"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_with_driver_plus_hour"
                  v-model="
                    dataForModal.vehicles__rent_sgs_with_driver_plus_hour
                  "
                  signClass="fsc-12 text-dark "
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  boxClass="bg-white"
                  >{{
                    dataLang['register_objects_car_text_27']
                  }}</BaseCheckboxV2
                >
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 border-disabled"
              style="
                background-color: #FFF5D9;
                height: 135px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_is_buyout"
                  v-model="dataForModal.vehicles__rent_sgs_is_buyout"
                  signClass="fsc-12 text-dark fw-bold"
                  boxClass="bg-white"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  name="isActive"
                  >{{
                    dataLang['vehicle_modal_settings_text9']
                  }}</BaseCheckboxV2
                >
              </div>

              <div
                ref="vehicles__rent_sgs_is_buyout_12
"
                v-if="dataForModal?.vehicles__rent_sgs_is_buyout"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_is_buyout_12
"
                  v-model="dataForModal.vehicles__rent_sgs_is_buyout_12"
                  boxClass="bg-white"
                  signClass="fsc-12 text-dark"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  >{{
                    dataLang['vehicle_modal_settings_text10']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_sgs_is_buyout_24"
                v-if="dataForModal?.vehicles__rent_sgs_is_buyout"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_is_buyout_24"
                  v-model="dataForModal.vehicles__rent_sgs_is_buyout_24"
                  signClass="fsc-12 text-dark"
                  boxClass="bg-white"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  >{{
                    dataLang['vehicle_modal_settings_text11']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_sgs_is_buyout_36"
                class=""
                v-if="dataForModal?.vehicles__rent_sgs_is_buyout"
              >
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgs_is_buyout_36"
                  v-model="dataForModal.vehicles__rent_sgs_is_buyout_36"
                  signClass="fsc-12 text-dark"
                  boxClass="bg-white"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  >{{
                    dataLang['vehicle_modal_settings_text12']
                  }}</BaseCheckboxV2
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 ">
        <div class="d-flex flex-column  h-100">
          <div class="system-block border mb-10 p-15  h-100">
            <p class="fw-semi-bold fsc-14 text-center mb-15">
              {{ dataLang['register_objects_car_text_12'] }}
            </p>
            <div class="mb-15">
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_14'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_17']
                }}</span>
              </div>
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_15'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_18']
                }}</span>
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 mb-15 border-disabled"
              style="
                background-color: #E8FEEE;
                height: 135px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseCheckboxV2
                  placeholder=""
                  input-name="vehicles__rent_sgf_is_no_driver"
                  v-model="dataForModal.vehicles__rent_sgf_is_no_driver"
                  signClass="fsc-12 text-dark"
                  @oncheckeds="updateValue"
                  :additionalParams="{}"
                  boxClass="bg-white"
                  name="vehicles__rent_sgf_is_no_driver"
                  >{{
                    dataLang['vehicle_modal_settings_text2']
                  }}</BaseCheckboxV2
                >
              </div>
              <div
                ref="vehicles__rent_sgf_no_driver_min_days"
                class=""
                v-if="dataForModal?.vehicles__rent_sgf_is_no_driver"
              >
                <BaseInputNew
                  placeholder=""
                  width="39"
                  input-name="vehicles__rent_sgf_no_driver_min_days"
                  @input="updateValue"
                  signClass="fsc-12 text-dark"
                  :horizontalTitle="true"
                  class="flex-row-reverse"
                  style="width: 208px"
                  :value="dataForModal.vehicles__rent_sgf_no_driver_min_days"
                  :additionalParams="{}"
                  :name-sign="dataLang['register_objects_car_text_23']"
                >
                </BaseInputNew>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 ">
        <div class="d-flex flex-column  h-100">
          <div class="system-block border mb-10 p-15  h-100">
            <p class="fw-semi-bold fsc-14 text-center mb-15">
              {{ dataLang['register_objects_car_text_13'] }}
            </p>
            <div class="mb-15">
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_14'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_17']
                }}</span>
              </div>
              <div class="fsc-11">
                <span>{{ dataLang['register_objects_car_text_15'] }}</span> -
                <span class="text-muted">{{
                  dataLang['register_objects_car_text_19']
                }}</span>
              </div>
            </div>
            <div
              class="px-15 d-flex flex-column gap-5 rounded-10 mb-15 border-disabled"
              style="
                background-color: #e8feee;
                height: 135px;
                padding-top: 12px;
                padding-bottom: 12px;
              "
            >
              <div class="">
                <BaseRadio
                  :value="0"
                  class="mb-5"
                  signClass="fsc-11"
                  v-model="dataForModal.vehicles__rent_sgt"
                  input-name="vehicles__rent_sgt"
                  @change="updateValue"
                  >{{ dataLang['register_objects_car_text_31'] }}</BaseRadio
                >
                <BaseRadio
                  :value="1"
                  class="mb-5"
                  signClass="fsc-11"
                  v-model="dataForModal.vehicles__rent_sgt"
                  input-name="vehicles__rent_sgt"
                  @change="updateValue"
                  >{{ dataLang['register_objects_car_text_32'] }}</BaseRadio
                >
                <BaseRadio
                  :value="2"
                  class="mb-5"
                  signClass="fsc-11"
                  v-model="dataForModal.vehicles__rent_sgt"
                  input-name="vehicles__rent_sgt"
                  @change="updateValue"
                  >{{ dataLang['register_objects_car_text_33'] }}</BaseRadio
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ObjectsService from '@/api/ObjectsService'

import EventBus from '@/event-bus.js'
import AddNewTriesModal from '@/components/vehicles-page-single-modal-inner/AddNewTriesModal.vue'
import TransportVehiclesService from '@/api/TransportVehiclesService.js'
import httpInstance from '@/http-common.js'
//   import ObjectsService from '@/api/ObjectsService'
//   import EventBus from '@/event-bus.js'
//   import SingleModal from '@/components/SingleModal.vue'
export default {
  components: {
    AddNewTriesModal
  },
  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      statusVehicle: '',
      loadingState: true,
      dataForModal: {},
      updatedValues: {}
    }
  },
  methods: {
    changeStatusVehicle() {
      console.log(12121)
      this.$emit('loadingModal', true)
      TransportVehiclesService.update(this.currentOpenedId, {
        vehicles__moderation_status_id: 2
      })
        .then((data) => {
          this.$toast.success(data.data.message)
          this.dataForModal.vehicles__moderation_status_id = 2
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.$emit('loadingModal', false)
        })
        .catch((err) => {
          this.$toast.err(err.response.data.message)
        })
    },
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      if (Object.keys(this.updatedValues).length) {
        ObjectsService.update(this.currentOpenedId, {
          ...this.updatedValues
        })
          .then((data) => {
            console.log(data)
            this.$toast.success(data.data.message)
            callback(true, nameTab, oldTab, newTab, flagIsClose)

            EventBus.$emit('contentLoaded')
            EventBus.$emit('updateContentData')
          })
          .catch((error) => {
            callback(false, nameTab, oldTab, newTab)
            this.$toast.error(error.response.data.message)
          })
      } else {
        callback(true, nameTab, oldTab, newTab)
      }
    },
    updateValue(data) {
      console.log(data)
      this.updatedValues[data.name] = data.value
      this.dataForModal[data.name] = data.value
      //   this.findAndReplaceDataForSend(data, data.name, data.value)

      //   if (data.once == 'first') {
      //     this.triesListForSend[1].isActive = 1
      //     this.triesListForSend[0].isActive = 0
      //   } else {
      //     this.triesListForSend[0].isActive = 1
      //     this.triesListForSend[1].isActive = 0
      //   }

      //   if (this.$refs[data.name]) {
      //     this.$refs[data.name].classList.remove('input-error')
      //   }
    },

    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },
  mounted() {},
  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)

    Promise.all([
      ObjectsService.getAll({
        url: `/${this.currentOpenedId}?fields=
        vehicles__moderation_status_id`
      })
        .then((data) => {
          console.log(data)
          this.statusVehicle = data.data.data.rows[0]
        })
        .catch((err) => {
          console.log(err)
        }),
      ObjectsService.getAll({ url: `/${this.currentOpenedId}` })
        .then((data) => {
          console.log(data)
          this.dataForModal = data.data.data.rows[0]
        })
        .catch((err) => {
          // console.log(err)
        })
    ]).then(() => {
      console.log('This end')
      this.$emit('loadingModal', false)
      this.loadingState = false
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('transportVehiclesModule', ['numPages'])
  }
}
</script>

<style scoped>
.bg-greensetting {
  background-color: rgba(35, 177, 77, 1);
}
</style>
