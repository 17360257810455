<template>
  <div
    class="modal fade new-modal"
    tabindex="-1"
    :ref="nameForAction"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog position-relative"
      :class="[centered === false ? '' : 'modal-dialog-centered', defineSize()]"
    >
      <div
        v-if="stateLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{ 'min-height': `${minHeight}px` }"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import EventBus from '@/event-bus.js'
// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  props: {
    nameForAction: {
      type: String,
    },
    modalName: {},
    centered: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
    },
    stateLoading: {
      type: Boolean,
      default: false,
    },
    optionalParams: {
      type: Object,
    },
    modalTitle: {
        type: String
    }
  },
  data() {
    return {
      thisModal: null,
    }
  },
  methods: {
    ...mapMutations('modalStackModule', [
      'modal_stack_add',
      'modal_stack_remove',
    ]),

    defineSize() {
      switch (this.size) {
        case 'sm':
          return 'modal-sm'
        case 'lg':
          return 'modal-lg'
        case 'xl':
          return 'modal-xl'
        case 'xxl':
          return 'modal-xxl'
      }
      return
    },
  },
  mounted() {
    this.thisModal = new Modal(this.$refs[this.nameForAction])
    EventBus.$on('openModal', (payload) => {
      if (payload.nameForAction === this.nameForAction) {
        this.modal_stack_add()
        this.thisModal.show()
        this.thisModal._element.style.zIndex = 1055 + this.modalStackPosition
        this.thisModal._backdrop._element.style.zIndex =
          1054 + this.modalStackPosition
      }
    })
    EventBus.$on('closeModal', (payload) => {
      if (payload.nameForAction === this.nameForAction) {
        this.thisModal.hide()
        //console.log(this.modalStackPosition)
      }
    })
    this.$refs[this.nameForAction].addEventListener('hide.bs.modal', () => {
      this.$emit('closeModal', { nameForAction: this.nameForAction })
      this.modal_stack_remove()
    })
  },
  beforeDestroy() {
    EventBus.$off('openModal')
    EventBus.$off('closeModal')
  },

  computed: {
    ...mapState('modalStackModule', ['modalStackPosition']),
    ...mapState('translationModule', ['dataLang']),
  },
}
</script>
