<template>
    <div ref="tableActionLink">
      <slot></slot>
    </div>
</template>
    
    
<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import EventBus from '@/event-bus.js'
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  components: {},

  props: ['propsData', 'groupBy','nameForAction', 'modalActivateFlag', 'selectRowActivateFlag', 'selectMultiple', 'propsColumnsSetting', 'callbackForModal', 'loadToMore'],
  data() {
    return {
      tabulator: null,
      tableData: this.propsData,
      tableActionLink: this.nameForAction,
      someData: [],
      loadData: false,
    }
  },
  methods: {
    openModalCallback(e, row) {
      EventBus.$emit("openModal", { data: row.getData(), nameForAction: this.nameForAction, mode: 'edit' });
    },
    selectRow(row) {
      console.log(row, this.nameForAction);
      EventBus.$emit("selectTableRows", { data: row.getData(), nameForAction: this.nameForAction });
    },
    deselectRow(row) {
      console.log(row, this.nameForAction);
      EventBus.$emit("deselectTableRows", { data: row.getData(), nameForAction: this.nameForAction });
    }
  },
  updated() {
  },
  created() {
  },
  mounted() {
    this.tabulator = new Tabulator(this.$refs.tableActionLink, {
      dataTree: true,
      layout: "fitColumns",
      selectable: !this.selectMultiple ? 1 : 'true',
      resizableColumnFit: false,
      columnHeaderVertAlign:"middle",
      data: this.someData, //link data to table
      reactiveData: true, //enable data reactivity
      // autoColumns: true,
      // pagination: "local",
      // paginationSize: 6,
      // paginationSizeSelector: [10, 20, 50, 80, 120],
      movableColumns: true,
      //paginationCounter: "rows",
      columns: this.propsColumnsSetting,
      groupBy: this.groupBy

    })
    if (this.callbackForModal) {
      this.tabulator.on("rowDblClick", this.callbackForModal);
    }
    if (this.modalActivateFlag) {
      this.tabulator.on("rowDblClick", this.openModalCallback);
    }
    if (this.selectRowActivateFlag) {
      this.tabulator.on("rowSelected", this.selectRow);
      this.tabulator.on("rowDeselected", this.deselectRow);
    }
  },
  beforeDestroy() {
    EventBus.$off("deselectTableRows", this.deselectRow)
    EventBus.$off("selectTableRows", this.selectRow)
  },
  computed: {
    ...mapState("translationModule", ["dataLang"]),
  },
  watch: {
    propsData: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        this.someData = val
        if(val.length)
          this.loadData = true;
        else this.loadData = false;
      }
    }
  }
};
</script>
    