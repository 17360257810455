<template>
  <div class="container-fluid px-0 py-10 position-relative" style="height: 615px" ref="aboutClientTab">
    <p class="mt-10 mb-10 fsc-14 fw-bold">
      {{ dataLang['clients_register_text_82'] }}
    </p>
    <BaseLoader v-if="localLoading"></BaseLoader>
    <div v-if="!localLoading" class="d-flex justify-content-between align-items-center pb-15 gap-10">
      <div class="d-flex gap-5 align-items-center">
        <div
          class="base-button base-button--action py-10 gap-15"
          @click="$modal.show('AddAboutClientsModal')"
        >
          <img
            :src="require(`@/assets/icons/add.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14">
            {{ dataLang['clients_register_text_83'] }}</span
          >
        </div>
        <div
          class="base-button base-button--action py-10 gap-15"
          @click="acceptDelete"
        >
          <img
            :src="require(`@/assets/icons/adddel.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14">
            {{ dataLang['clients_register_text_84'] }}</span
          >
        </div>
      </div>
    </div>

    <ve-table
      style="width: 100%"
      :columns="columns"
      :table-data="dataTable"
      :border-x="true"
      :border-y="true"
      :sort-option="sortOption"
      ref="serviceTable"
      row-key-field-name="client_about__id"
      :checkbox-option="{
        hideSelectAll: true,
        selectedRowChange: selectedRowChange,
        selectedAllChange: selectedAllChange
      }"
      v-if="!localLoading"
      :clickHighlight="false"
      :rowStyleOption="{
        hoverHighlight: true
      }"
      :cellSelectionOption="{ enable: false }"
    />
    <AddAboutClientsModal v-if="!localLoading" :optionalParams="{ id: tabInfo }"></AddAboutClientsModal>
    <Teleport to="body"
      ><SimpleModal
        size="md"
        :minHeight="320"
        nameForAction="acceptDeleteNote"
        :modal-title="dataLang['vehicle_modal_service_modals_title_8']"
      >
        <template v-slot:content
          ><p class="fsc-20 mb-30">
            {{ dataLang['vehicle_modal_service_modals_warning4'] }}
          </p>
          <div class="d-flex justify-content-center gap-30 mt-10 px-30 py-10">
            <div class="base-button py-15" @click="closeDeleteModal">
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
            <div class="btn-primary btn fsc-14 ws-nowrap" @click="deleteRow">
              {{ dataLang['vehile_modal_btn_delete_ok'] }}
            </div>
          </div>
        </template>
      </SimpleModal></Teleport
    >
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import ObjectsService from '@/api/ObjectsService'
import EventBus from '@/event-bus.js'
import GeneralService from '@/api/GeneralService'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AddBalanceModal from './AddBalanceModal.vue'
import WriteOffClientsModal from './WriteOffClientsModal.vue'
import BackBalanceClientsModal from './BackBalanceClientsModal.vue'
import AddAboutClientsModal from './AddAboutClientsModal.vue'
import SimpleModal from '@/components/vehicles-page-single-modal-inner/SimpleModal.vue'
export default {
  components: {
    VeTable,
    AddBalanceModal,
    WriteOffClientsModal,
    BackBalanceClientsModal,
    AddAboutClientsModal,
    SimpleModal
    // AddNewNoteModalInNotesTab,
    // ZapchastiVehicleModalSingle,
    // SimpleModal
  },

  props: {
    modalInfo: {
      type: Object
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      tabInfo: this.modalInfo,
      computedAllPage: 1,
      activePagePagination: {},

      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        }
      },
      dataForTab: {},
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      }
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    async loadExel() {
      let dataFrom = await GeneralService.toSpecExcel(
        `/clients/${this.modalInfo.id}/table/balance/xlsx`
      )
      console.log(dataFrom)
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
      console.log(this.activeSelectRowsMultiple)
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys)
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    acceptDelete() {
      console.log(this.activeSelectRowsMultiple)
      if (this.activeSelectRowsMultiple) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDeleteNote',
          mode: 'create'
        })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    closeDeleteModal() {
      EventBus.$emit('closeModal', {
        data: null,
        nameForAction: 'acceptDeleteNote',
        mode: 'create'
      })
      this.activeSelectRowsMultiple = []
    },

    deleteRow() {
      this.localLoading = true
      EventBus.$emit('closeModal', {
        data: null,
        nameForAction: 'acceptDeleteNote',
        mode: 'create'
      })
      GeneralService.delete(
        `/clients/${this.tabInfo.id}/about/${this.activeSelectRowsMultiple}`
      )
        .then((data) => {
          this.$toast.success(data.data.message)
          this.activeSelectRowsMultiple = []
          this.loadData()
          this.localLoading = false
        })
        .catch((err) => {
          console.log(err);
          this.activeSelectRowsMultiple = []
          this.$toast.error(err.response.data.message)
        })
    },
    openModalSingle(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create'
      })
    },
    loadData(newNumPage) {
      this.localLoading = true
      this.urlToQuaries.params.page = newNumPage
      GeneralService.get(this.urlToQuaries.url, {
        params: this.urlToQuaries.params
      })
        .then((data) => {
          this.localLoading = false
          this.activePagePagination = data.data.data
          this.computedAllPage = data.data.data.total / data.data.data.limit
          this.dataTable = data.data.data.rows
          this.activeSelectRowsMultiple = null
        })
        .catch((e) => console.log(e))
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData()
      this.$refs.serviceTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    sortChange(params) {
      console.log(params)
      this.dataTable.sort((a, b) => {
        if (params.vehicle_services__created_at) {
          if (params.vehicle_services__created_at === 'asc') {
            console.log('asc')
            return (
              a.vehicle_services__created_at - b.vehicle_services__created_at
            )
          } else if (params.vehicle_services__created_at === 'desc') {
            console.log('desc')
            return (
              b.vehicle_services__created_at - a.vehicle_services__created_at
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_services__odometr) {
          if (params.vehicle_services__odometr === 'asc') {
            console.log('asc')
            return a.vehicle_services__odometr - b.vehicle_services__odometr
          } else if (params.vehicle_services__odometr === 'desc') {
            console.log('desc')
            return b.vehicle_services__odometr - a.vehicle_services__odometr
          } else {
            return 0
          }
        }
        if (params.vehicle_services__text) {
          if (params.vehicle_services__text === 'asc') {
            console.log('asc')
            return a.vehicle_services__text - b.vehicle_services__text
          } else if (params.vehicle_services__text === 'desc') {
            console.log('desc')
            return b.vehicle_services__text - a.vehicle_services__text
          } else {
            return 0
          }
        }
        if (params.vehicle_services__price) {
          if (params.vehicle_services__price === 'asc') {
            console.log('asc')
            return a.vehicle_services__price - b.vehicle_services__price
          } else if (params.vehicle_services__price === 'desc') {
            console.log('desc')
            return b.vehicle_services__price - a.vehicle_services__price
          } else {
            return 0
          }
        }
        if (params.employees__name) {
          if (params.employees__name === 'asc') {
            console.log('asc')
            return a.employees__name - b.employees__name
          } else if (params.employees__name === 'desc') {
            console.log('desc')
            return b.employees__name - a.employees__name
          } else {
            return 0
          }
        }
      })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.mod && { mod: data.mod })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter)
    }
  },
  mounted() {
    this.loadData()
    dayjs.extend(utc)
    EventBus.$on('updateContentData', () => {
      this.loadData()
    })
  },
  created() {
    GeneralService.get(
      `/clients/${this.tabInfo.id}?joins=statistics,verifications,documents`
    )
      .then((data) => {
        this.dataForTab = data.data.data.rows[0]
        console.log(this.dataForTab)
      })
      .catch((e) => {
        console.log(e)
      })
    // GeneralService.get(`/clients/${this.tabInfo.id}/table/balance`)
    //   .then((data) => {
    //     this.tableData = data.data.data.rows[0]
    //     this.localLoading = false
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
    //   POST /clients{id}/balance
    // GeneralService.editOrCreate(`/clients/${this.tabInfo.id}/balance`, {
    //   amount: 5001,
    //   type: 'manager+',
    //   finreestr_funds__id: 27
    // })
    //   .then((data) => {
    //     this.dataTable = data.data.data.rows[0]
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
    //   GeneralService.get(`/finreestr-funds`, {
    //     params: {
    //         where: JSON.stringify([{"field":"finreestr_funds__is_coming","value":1}]),
    //         fields: 'finreestr_funds__id,finreestr_funds__name,+finreestr_funds__is_coming'
    //     }
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
    //   /finreestr-funds?where=[{"field":"finreestr_funds__is_coming","value":1}]&fields=finreestr_funds__id,finreestr_funds__name,+finreestr_funds__is_comin
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    checkboxOption() {
      return {
        hideSelectAll: true,
        selectedRowChange: this.selectedRowChange,
        selectedAllChange: this.selectedAllChange,
        selectedRowKeys: []
      }
    },
    urlToQuaries() {
      return {
        url: `/clients/${this.tabInfo.id}/table/about?joins=employees`,
        params: {
          limit: 10,
          page: 1,
          ...(this.filter.length && { where: JSON.stringify(this.filter) })
        }
      }
    },
    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: '3%',
          align: 'center'
        },
        {
          field: 'client_about__created_at',
          key: '1',
          title: this.dataLang['clients_register_text_85'],
          align: 'left',
          width: '15%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.client_about__created_at
                  ? dayjs(row.client_about__created_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(
                        `${this.userSetting.company__tempalte_date} HH:mm`
                      )
                  : ''}
              </span>
            )
          }
        },

        {
          field: 'client_about__text',
          key: '25',
          title: this.dataLang['clients_register_text_70'],
          align: 'left',
          width: '67%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={''}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'employees__name',
          key: '31',
          title: this.dataLang['clients_register_text_86'],
          align: 'left',
          width: '15%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>
