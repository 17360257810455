import http from "@/http-common.js";

class SbService {

  basePathApiService = "/anketa-security";
  
  getOne(id) {
    return http.get(this.basePathApiService + `/${id}`);
  }

  getSb1All(params) {
    let p = params?.params;

    if(p){
      p.typeSB = "sb1";
    }


    return http.get(this.basePathApiService, params);
  } 

  getSb2All(params) {
    let p = params?.params;

    if(p){
      p.typeSB = "sb2";
    }


    return http.get(this.basePathApiService, params);
  }

  allow(id, flag){
    return http.post(this.basePathApiService + `/${id}/result-check`, {"allow":flag});
  }

}
export default new SbService();
