import http from "@/http-common.js";

class ChecklistAdminService {

  basePathApiServiceOne = "/checklist/scripts";
 
  getAllScripts(params) {
    return http.get(this.basePathApiServiceOne, params);
  } 
  createScripts(createData) {
    return http.post(this.basePathApiServiceOne, createData);
  }
  editMultipleScripts(id, editData) {
    return http.put(this.basePathApiServiceOne + `/${id}`, editData);
  }
  deleteScripts(id) {
    return http.delete(this.basePathApiServiceOne + `/${id}`);
  }

}
export default new ChecklistAdminService();
