<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="modal"
  >
    <div
      class="modal-dialog modal-dialog-scrollable"
      :class="[centered === false ? '' : 'modal-dialog-centered', defineSize()]"
    >
      <div class="modal-base-content modal-content">
        <span class="modal-base-content__title">{{ title }}</span>
        <div class="modal-content-dynamic">
          <slot name="main-content"></slot>
        </div>
        <div class="modal-content-controls">
          <div class="btn btn-secondary btn-sm">
            Скинуть ссылку на заполнение анкеты
          </div>
          <div class="btn btn-secondary btn-sm">Перенести в ожидание</div>
          <div class="btn btn-secondary btn-sm">Нет в наличии + СМС</div>
          <div class="btn btn-secondary btn-sm">Отправить на проверку СБ</div>
          <div class="btn btn-secondary btn-sm">Сохранить</div>
          <div class="btn btn-secondary btn-sm">Закрыть</div>
        </div>
      </div>
    </div>
  </div>
</template>
        
        <script>
import { Modal } from "bootstrap";
export default {
  props: {
    centered: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: "",
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modal: null,
    };
  },
  methods: {
    defineSize() {
      switch (this.size) {
        case "sm":
          return "modal-sm";
        case "lg":
          return "modal-lg";
        case "xl":
          return "modal-xl";
        case "xxl":
          return "modal-xxl";
      }
      return;
    },
    modalShow() {
      this.modal.show();
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal, {
      backdrop: this.backdrop,
    });
  },
};
</script>
        