import http from "@/http-common.js";
import AccessService from "@/api/AccessService.js";
import router from "@/router/index.js";
export const accessModule = {
  namespaced: true,
  state: () => ({
    statusAuth: "",
    statusReg: false,
    statusAuth: false,
    loggedStatus: false,
    loadingNow: false,
    token: "",
    authMessage: "",
    regMessage: "",
    errorsData: {}
  }),

  getters: {},
  mutations: {
    auth_request(state, status) {
      state.loadingNow = status
    },
    auth_success(state) {
      state.statusAuth = true
    },
    auth_error(state, status) {
      state.statusAuth = false
    },
    auth_status(state, status) {
      state.authMessage = status
    },
    reg_request(state, status) {},
    reg_success(state, status) {
      state.statusReg = true
    },
    reg_error(state, data, status) {
      state.statusReg = false
      state.errorsData = data
    },
    reg_status(state, status) {
      state.regMessage = status
    },
    logout(state) {},
  },
  actions: {
    auth({ commit }, {authData, callback}) {
      commit("auth_request", "loading");
      AccessService.auth(JSON.stringify(authData))
        .then((data) => {
          commit("auth_success");
          commit("auth_status", '');
          localStorage.setItem('user-token', JSON.stringify(data.data.data.token))
          http.defaults.headers.common["X-Employee-Token"] = JSON.parse(localStorage.getItem('user-token'))
          router.push('/orders')
          callback('success')
        })
        .catch((data) => {
          console.log(data)
          commit("auth_error");
          callback('error', data.response.data.message, data.response.data.errors)
          commit("auth_status", data.response.data.message);
        });
    },
    reg({ commit }, {authData, callback}) {
      commit("auth_request", "loading");
      AccessService.reg(JSON.stringify(authData))
        .then((data = data.data.message) => {
          console.log(data.data);
          callback('success')
          commit("reg_success");
          commit("reg_status", '');
          commit("auth_request", "success")

          // localStorage.setItem('user-token', JSON.stringify(data.data.data.token))
          // http.defaults.headers.common["X-Employee-Token"] = JSON.parse(localStorage.getItem('user-token'))
        })
        .catch((data) => {
          callback('error', data.response.data.message, data.response.data.errors)
          if(data !== undefined){
            console.log(data)
            commit("reg_error", data.response.data.message);
            commit("reg_status", data.response.data.errors);

          }

        });
    },
    logout({ commit }) {
      /* commit("logout");
      localStorage.removeItem("token");
      console.log("logout");

      delete http.defaults.headers.common.Authorization; */
    },
  },
};
