import http from "@/http-common.js";

class TranslationService {
  basePathApiService = "/translations/";
  getTranslate(langID, params = null) {
    return http.get(this.basePathApiService + langID, params);
  }
  
}
export default new TranslationService();
