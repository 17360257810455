
import FinesService from "@/api/FinesService.js";
import Vue from "vue";
export const finesModule = {
  namespaced: true,
  state: () => ({
    finesData: [],
    finesLoadStatus: 'request',
    paginationFinesStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationFinesStateParams.total / state.paginationFinesStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.finesData = [...data];
    },
    create(state, data) {
      state.finesData.unshift(data[0])
    },
    edit_multiple(state, data) {
      let indexed = state.finesData.findIndex(x => x.fines__id == data.id)
      Object.assign(state.finesData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.finesData.findIndex(x => x.fines__id == id)
      state.finesData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationFinesStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.finesLoadStatus= 'request'
    },
    load_success(state) {
      state.finesLoadStatus= 'success'
    },
    load_error(state) {
      state.finesLoadStatus= 'error'
    },
  },
  actions: {
   

    getFinesAll({ commit }, params) {
      commit("load_request");
      console.log( FinesService)
      FinesService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createFinesData({ commit }, createData) {
      
        FinesService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editFinesMultiple({ commit }, {id, dataEdit}) {
        FinesService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteFinesData({ commit }, id) {
      // commit("load_request");
      FinesService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
