<template>
    <div>
        <BaseTopLine>
            <div class="d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('tasksModal')">Добавить</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div>
            </div>
        </BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="TasksData" :props-columns-setting="fieldsTableTasks"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="tasksModal" v-if="TasksLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="tasksModal" @closeModal="closeModal">
                <div class="p-10">
                    <BaseInput placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.tasks__task"
                        name-sign="Задача" input-name="tasks__task">
                    </BaseInput>
                    <BaseTextarea placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.tasks__description"
                        name-sign="Описание" input-name="tasks__description">
                    </BaseTextarea>
                    
                    <div class="d-flex gap-10 mt-15">
                        <div class="btn btn-sm btn-primary" @click="sendDataFromModal">{{
                            dataLang.form_btn__btn_save }}</div>
                        <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                            dataLang.form_btn__btn_caneсl }}</div>
                    </div>
                </div>
            </Modal>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
export default {
    components: {
        Modal
    },

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getTasksAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },
        openModalForCreate(nameModal) {
            EventBus.$emit("openModal", { data: null, nameForAction: nameModal, mode: 'create' })
        },


        deleteActionAndModal() {
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },
        deleteRow(nameModal) {
            this.deleteTasksData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },


        sendDataFromModal() {
            console.log(this.dataForModal);
            if (this.dataForModal.tasks__id) {
                console.log(this.updatedValues);
                this.editTasksMultiple({ id: this.dataForModal.tasks__id, dataEdit: this.updatedValues })
                EventBus.$emit("closeModal", { nameForAction: 'tasksModal' })
            } else {
                this.createTasksData(this.updatedValues)
                // this.$refs[tasksModal].$el.querySelectorAll('input').forEach(elem => {
                //     elem.value = ''
                // })
                
                EventBus.$emit("closeModal", { nameForAction: 'tasksModal' })
            }
        },


        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },

        prepareKeysForModal(payloadData) {
            this.updatedValues = {}
            this.dataForModal = {}
            if (!payloadData) {
                for (const key in this.TasksData[0]) {
                    this.dataForModal[key] = null
                }
            } else {
                this.dataForModal = Object.assign({}, payloadData)
            }

        },

        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
            // this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
            //     element.value = ''
            // })
        },
        btnHandlerCloseModal(nameModal) {
            EventBus.$emit("closeModal", { nameForAction: 'tasksModal' })
        },


        ...mapActions("tasksModule", ["getTasksAll", 'createTasksData', 'editTasksMultiple', 'deleteTasksData', 'setTasksRoute']),


    },
    mounted() {
      
        EventBus.$on("openModal", (payload) => {
            this.editData = payload.data
            console.log(payload.data);
            this.prepareKeysForModal(payload.data)
        });
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.tasks__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
    
        
        this.getTasksAll({ params: { page: 1, limit: 10 }})
    },
    computed: {
        ...mapState("tasksModule", ["TasksData", "TasksLoadStatus", 'paginationTasksStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("tasksModule", ["numPages"]),


        fieldsTableTasks() {
            return [
                { title: 'ID', field: "tasks__id", headerFilter: "input" },
                { title: 'Задача', field: "tasks__task", headerFilter: "input" },
                { title: 'Описание', field: "tasks__description", headerFilter: "input" },
                { title: 'Дата создания', field: "tasks__created_at", headerFilter: "input" },
            ]
        }
    },
};
</script>
    
    