<template>
    <div>
        <div class="mt-20">
            <span class="fw-bold">Типы проверок СБ</span>
            <BaseReuseTable class="mt-10" :props-data="secureGroupsData" :props-columns-setting="fieldsTableSecureGroups"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="secureGroupsModal" v-if="secureGroupsLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="secureGroupsModal" size="xl">
                <div class="p-10">
                    <vue-tabs>
                        <!-- <v-tab title="Поля клиента">
                            <SecureSBClientsFieldsSettings></SecureSBClientsFieldsSettings>
                        </v-tab> -->
                        <v-tab title="Вопросы">
                            <SecureSBQuestionsSettings></SecureSBQuestionsSettings>
                        </v-tab>
                    </vue-tabs>
                    
                    <!-- <div class="d-flex gap-10 mt-15">
                        <div class="btn btn-sm btn-primary" @click="sendDataFromModal">{{
                            dataLang.form_btn__btn_save }}</div>
                        <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                            dataLang.form_btn__btn_caneсl }}</div>
                    </div> -->
                </div>
            </Modal>
        </Teleport>
    </div>
</template>

<script>
    import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
    import { mapActions, mapState, mapMutations } from "vuex";
    import Modal from '@/components/Modals.vue'
    import EventBus from '@/event-bus.js'
    import SecureSBClientsFieldsSettings from "@/components/global_settings/SecureSBClientsFieldsSettings.vue";
    import SecureSBQuestionsSettings from "@/components/global_settings/SecureSBQuestionsSettings.vue";
    export default {
        components:{
            VueTabs, VTab,
            Modal,
            EventBus,
            SecureSBClientsFieldsSettings,
            SecureSBQuestionsSettings
        },

        data(){
            return {
                
            }
        },

        created(){
            this.getSecureGroupsAll({params:{
                orderBy: "id",
                orderDir: "ASC"
            }});
        },

        methods: {
            ...mapActions("secureGroupsModule", ["getSecureGroupsAll"]),
            ...mapMutations("secureGroupsModule", ["open_group"])
            
        },

        computed:{
            ...mapState("secureGroupsModule", ["secureGroupsData", "secureGroupsLoadStatus"]),
            ...mapState("translationModule", ["dataLang"]),

            fieldsTableSecureGroups(){
                return [
                    { title: 'ID', field: "secur_groups__id", headerFilter: "input", width:100},
                    { title: 'Тип проверки', field: "secur_groups__name", headerFilter: "input" },
                ]
            }
        },

        mounted() {   
            EventBus.$on("openModal", (payload) => {
                if (payload.nameForAction === "secureGroupsModal") {
                    this.open_group(payload.data.secur_groups__id);
                }
            });
        },
    }
</script>