
import DeliveryService from "@/api/DeliveryService.js";
import Vue from "vue";
export const deliveryModule = {
  namespaced: true,
  state: () => ({
    deliveryData: [],
    deliveryLoadStatus: 'request',
    paginationStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationStateParams.total / state.paginationStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.deliveryData = data;
      // state.ownersData = data.map((item, i) => Object.assign({}, item, state.ownersData[i]));
    },
    create(state, data) {
      state.deliveryData.unshift(data)
    },
    edit_multiple(state, data) {
      let indexed = state.deliveryData.findIndex(x => x.place_deliveries__id == data.id)
      Object.assign(state.deliveryData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.deliveryData.findIndex(x => x.place_deliveries__place_id == id)
      state.deliveryData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.deliveryLoadStatus= 'request'
    },
    load_success(state) {
      state.deliveryLoadStatus= 'success'
    },
    load_error(state) {
      state.deliveryLoadStatus= 'error'
    },
  },
  actions: {

    getDeliveryAll({ commit }, id) {
      commit("load_request");
      DeliveryService.getAll(id)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createDeliveryData({ commit }, createData) {
      
        DeliveryService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", createData);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editDeliveryMultiple({ commit }, {id, dataEdit}) {
        DeliveryService.update(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteDeliveryData({ commit }, id) {
      // commit("load_request");
      DeliveryService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
