
import BookPhonesService from "@/api/BookPhonesService.js";
import Vue from "vue";
export const bookPhonesModule = {
  namespaced: true,
  state: () => ({
    BookPhonesData: [],
    BookPhonesLoadStatus: 'request',
    paginationBookPhonesStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationBookPhonesStateParams.total / state.paginationBookPhonesStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.BookPhonesData = [...data];
    },
    create(state, data) {
      state.BookPhonesData.unshift(data[0])
    },
    edit_multiple(state, data) {
      let indexed = state.BookPhonesData.findIndex(x => x.book_contacts__id == data.id)
      Object.assign(state.BookPhonesData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.BookPhonesData.findIndex(x => x.book_contacts__id == id)
      state.BookPhonesData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationBookPhonesStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.BookPhonesLoadStatus= 'request'
    },
    load_success(state) {
      state.BookPhonesLoadStatus= 'success'
    },
    load_error(state) {
      state.BookPhonesLoadStatus= 'error'
    },
  },
  actions: {
   
    setBookPhonesRoute({ commit }, route){
        BookPhonesService.setRoute(route);
    },

    getBookPhonesAll({ commit }, params) {
      commit("load_request");
      
      BookPhonesService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createBookPhonesData({ commit }, createData) {
      
        BookPhonesService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editBookPhonesMultiple({ commit }, {id, dataEdit}) {
        BookPhonesService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteBookPhonesData({ commit }, id) {
      // commit("load_request");
      BookPhonesService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },
    
  },
};
