<template>
  <div>
    <BaseTopLine></BaseTopLine>
    <div class="px-20 pt-10">
      <!-- <div class="base-button px-20 rounded-10" @click="actionForEditTable">
          {{ dataLang['registry_ads_text2'] }}
        </div> -->
    </div>
    <div class="p-20 position-relative">
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div class="d-flex gap-15 mb-15 align-items-start">
        <div>
          <span>{{ dataLang['table_translations_text1'] }}</span>
          <BaseSelect
            style="width: 225px"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            @input="(e) => setSimpleSelect(e, 'lang')"
            v-model="addedSelectsData.lang"
            id="lang"
            label="languages__name"
            :options="selectsData.langs"
          >
            <template slot="noOptions">{{ dataLang['select_empty'] }}</template>
            <template slot="noResult">{{
              dataLang['select_search_empty']
            }}</template
            ><template slot="selectedLabel">{{
              dataLang['select_selected']
            }}</template>
            <template slot="deselectLabel">{{
              dataLang['select_unselect']
            }}</template>
          </BaseSelect>
        </div>
        <div>
          <span>{{ dataLang['table_translations_text2'] }}</span>
          <BaseSelect
            style="width: 225px"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            @input="(e) => setSimpleSelect(e, 'group')"
            v-model="addedSelectsData.group"
            id="group"
            label="translation_groups__description"
            :options="selectsData.groups"
          >
            <template slot="noOptions">{{ dataLang['select_empty'] }}</template>
            <template slot="noResult">{{
              dataLang['select_search_empty']
            }}</template
            ><template slot="selectedLabel">{{
              dataLang['select_selected']
            }}</template>
            <template slot="deselectLabel">{{
              dataLang['select_unselect']
            }}</template>
          </BaseSelect>
        </div>
        <div>
          <span>{{ dataLang['table_translations_text3'] }}</span>
          <BaseSelect
            style="width: 225px"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            @input="(e) => setSimpleSelect(e, 'subgroup')"
            v-model="addedSelectsData.subgroup"
            id="subgroup"
            label="translation_groups__description"
            :options="selectsData.subgroups"
          >
            <template slot="noOptions">{{ dataLang['select_empty'] }}</template>
            <template slot="noResult">{{
              dataLang['select_search_empty']
            }}</template
            ><template slot="selectedLabel">{{
              dataLang['select_selected']
            }}</template>
            <template slot="deselectLabel">{{
              dataLang['select_unselect']
            }}</template>
          </BaseSelect>
        </div>
        <div class="btn btn-sm btn-thrity text-white fsc-14" @click="loadData">
          {{ dataLang['register_btn_filter_on'] }}
        </div>
        <div
          class="btn btn-sm btn-thrity text-white fsc-14"
          @click="resetLoadData"
        >
          {{ dataLang['register_btn_filter_off'] }}
        </div>
      </div>
      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="dataTable"
        v-if="dataTable"
        :border-x="true"
        :border-y="true"
        ref="refTable"
        row-key-field-name="translations__id"
        :editOption="editOption"
        :rowStyleOption="{
          hoverHighlight: true
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
export default {
  components: {
    VeTable
  },

  props: {},
  data() {
    return {
      selectsData: {
        langs: [],
        groups: [],
        subgroups: []
      },
      addedSelectsData: {
        lang: [],
        group: [],
        subgroup: []
      },
      selectedLang: '',
      filterOptions: [],

      updatedValues: {},
      isReadedShow: true,
      dataForModal: {},
      textForModal: null,
      activeSelectRow: null,
      dataTable: null,
      activeSelectRowsMultiple: [],
      currentOpenIdModal: null,
      localLoading: true,
      limitPaginationValue: 10,
      paginationStateParams: {
        limit: 10,
        page: 1
      },
      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        }
      },
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('showInfoNotice')
              this.textForModal = row
            }
          }
        }
      },

      editOption: {
        afterCellValueChange: ({ row, column, changeValue }) => {
          // http://api.yandexrent.development/translations-edit
          console.log('afterCellValueChange')
          console.log('row::', row)
          console.log('column::', column)
          console.log('changeValue::', changeValue)

          //                         {
          //     "translations__code": "notification_page_text13",
          //     "translations__description": "Кнопка в таблице",
          //     "translations__group": 72,
          //     "translations__id": 1052,
          //     "translations__sub_group": 79,
          //     "translations__translation_key": "Просмотреноwdwdwd",
          //     "translations__translation_value": null
          // }
          GeneralService.editOrCreate(`/translations-edit`, {

            translations__code: row.translations__code,
            translations__translation_value:
              row.translations__translation_value,
            translations__lang_code: this.selectedLang
          })
            .then((data) => {
              this.$toast.success(data.data.message)
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
  },
  methods: {
    beforeClose() {},
    beforeOpen() {},

    loadData() {
      if (this.selectedLang) {
        // /translations-edit/?languageCode=ru&where=[{"field":"translations__group","value":72},{"field":"translations__sub_group","value":17}]
        GeneralService.get(`/translations-edit`, {
          params: {
            languageCode: this.selectedLang,
            where: JSON.stringify(this.filterOptions)
          }
        })
          .then((data) => {
            console.log(data)
            this.dataTable = data.data.data.rows
            // this.selectsData.langs = data.data.languages.rows
            //   console.log(data.data.languages.rows)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    resetLoadData() {
      this.selectedLang = ''
      for (const key in this.addedSelectsData) {
        this.addedSelectsData[key] = []
      }
      this.filterOptions = []
      this.dataTable = null
    },
    setSimpleSelect(e, nameSelect) {
      console.log(e)
      this.dataTable = null
      switch (nameSelect) {
        case 'lang':
          this.selectedLang = e.languages__code
          this.addedSelectsData.group = []
          this.addedSelectsData.subgroup = []
          this.filterOptions = []
          //   /translations-edit/?languageCode=ru&where=[{"field":"translations__group","value":72},{"field":"translations__sub_group","value":17}]

          break
        case 'group':
          this.addedSelectsData.subgroup = []
          this.editFilter(e, 'translations__group')

          GeneralService.get(
            `/translation-groups/${e.translation_groups__id}/subgroups`
          )
            .then((data) => {
              this.selectsData.subgroups = data.data.data.rows
              //   console.log(data.data.languages.rows)
            })
            .catch((err) => {
              console.log(err)
            })
          break
        case 'subgroup':
          this.editFilter(e, 'translations__sub_group')
          //   GeneralService.get(`/translation-groups/${this.group_id}/subgroups`)
          //     .then((data) => {
          //       this.selectsData.langs = data.data.languages.rows
          //       //   console.log(data.data.languages.rows)
          //     })
          //     .catch((err) => {
          //       console.log(err)
          //     })
          break
      }
      console.log(e)
    },
    editFilter(e, fieldName) {
      let indexed = this.filterOptions.findIndex((x) => x.field == fieldName)
      console.log(indexed);
      if (indexed >= 0) {
        this.filterOptions[indexed].value = e.translation_groups__id
      } else {
        this.filterOptions.push({
          field: fieldName,
          value: e.translation_groups__id
        })
      }
    },
    changeShowMessages(type) {
      this.isReadedShow = type
      this.clickLoadData(1)
    },
    updateLimitValue(data) {
      console.log(data)
      this.limitPaginationValue = data
      this.clickLoadData(1)
    },
    editAllAndOne(listOrOne) {
      this.localLoading = true
      NoticesService.editOrCreateMultiple(listOrOne, {
        statusCode: this.isReadedShow
          ? 'notification_status_read'
          : 'notification_status_new'
      })
        .then((data) => {
          console.log(data)
          this.localLoading = false
          this.$toast.success(data.data.message)
          this.clickLoadData(1)
        })
        .catch((error) => {
          console.log(error.response.data.errors)
        })
      this.$modal.hide('showInfoNotice')
    },
    actionForEditTable() {
      if (this.activeSelectRowsMultiple.length) {
        // this.$modal.show('showInfoNotice')
        console.log()
        this.editOrCreateAllAndOne(this.activeSelectRowsMultiple)
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    // deleteRow(nameModal) {
    //   this.deleteTransportVehiclesData(this.activeSelectRowsMultiple)
    //   this.activeSelectRow = null
    //   this.$modal.hide('acceptDeletes')
    // },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
      console.log(this.activeSelectRowsMultiple)
    },
    clickLoadData(numPage) {
      this.limitPaginationValue = 10
      this.localLoading = true
      NoticesService.getAll({
        params: {
          limit: this.limitPaginationValue,
          page: numPage,
          where: JSON.stringify([
            {
              field: 'notices_statuses__status',
              value: this.isReadedShow
                ? 'notification_status_new'
                : 'notification_status_read'
            }
          ])
        }
      })
        .then((data) => {
          console.log(data)
          this.localLoading = false
          this.dataTable = data.data.data.rows
          this.paginationStateParams = Object.assign(
            this.paginationStateParams,
            data.data.data
          )
        })
        .catch((error) => {
          console.log(error.response.data.errors)
        })
    },

    deleteRow(nameModal) {
      this.localLoading = true
      NoticesService.delete(this.activeSelectRowsMultiple)
        .then((data) => {
          console.log(data)
          this.$toast.success(data.data.message)

          NoticesService.getAll({
            params: {
              where: JSON.stringify([
                { field: 'request_services__type_service_id', value: 2 }
              ])
            }
          })
            .then((data) => {
              console.log(data)
              this.localLoading = false
              this.dataTable = data.data.data.rows
            })
            .catch((error) => {
              console.log(error.response.data.errors)
            })
        })
        .catch((error) => {
          console.log(error.response.data.errors)
          //   this.validateData(error.response.data.errors)
          //   this.$toast.error(error.response.data.message)
          //   callback(false, nameTab, oldTab, newTab)
        })

      this.activeSelectRowsMultiple = []
      this.$modal.hide('acceptDeletes')
      //   this.localLoading = false
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
    }

    // ...mapActions('bidsOsagoModule', [
    //   'getBidsOsagoAll',
    //   'createBidsOsagoData',
    //   'editBidsOsagoMultiple',
    //   'deleteBidsOsagoData',
    // ]),
  },
  mounted() {
    dayjs.extend(utc)
  },

  created() {
    // ?where=[{"field":"request_services__type_service_id","value":8}]
    Promise.all([
      GeneralService.get('/languages')
        .then((data) => {
          this.selectsData.langs = data.data.languages.rows
          //   console.log(data.data.languages.rows)
        })
        .catch((err) => {
          console.log(err)
        }),
      GeneralService.get('/translation-groups')
        .then((data) => {
          this.selectsData.groups = data.data.data.rows
        })
        .catch((err) => {
          console.log(err)
        })
    ]).then(() => {
      this.localLoading = false
    })
  },

  computed: {
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('translationModule', ['dataLang']),
    numPages() {
      return this.paginationStateParams.total / this.paginationStateParams.limit
    },
    columns() {
      return [


        {
          field: 'translations__translation_key',
          key: '2',
          title: this.dataLang['table_translations_text5'],
          align: 'left',
          width: '33%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        },
        {
          field: 'translations__translation_value',
          key: '3',
          title: this.dataLang['table_translations_text6'],
          align: 'left',
          sortBy: '',
          edit: true,
          width: '33%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }

        },
        {
          field: 'translations__description',
          key: '4',
          title: this.dataLang['table_translations_text7'],
          align: 'left',
          sortBy: '',

          width: '33%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
        }
      ]
    }
    // ...mapState('bidsOsagoModule', [
    //   'bidsOsagoData',
    //   'bidsOsagoLoadStatus',
    //   'paginationbidsOsagoStateParams',
    // ]),
    // ...mapState('translationModule', ['dataLang']),
    // ...mapGetters('bidsOsagoModule', ['numPages']),
  }
}
</script>
