import http from "@/http-common.js";

class BidsOsagoService {

  basePathApiService = "/bids-fines";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 

}
export default new BidsOsagoService();
