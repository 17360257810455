import { render, staticRenderFns } from "./Sb1Page.vue?vue&type=template&id=3b3f835c&"
import script from "./Sb1Page.vue?vue&type=script&lang=js&"
export * from "./Sb1Page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports