<template>
  <div class="container-fluid px-0" style="height: 600px" ref="vehicleTab">
    <div class="d-flex gap-10">
      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="$modal.show('addMilage')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_odometr'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="$modal.show('oilDvsVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_oil_dvs'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="$modal.show('kppDvsVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_oil_kpp'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="$modal.show('otherVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_oil_other'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="$modal.show('tolingVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_to'] }}</span
        >
      </div>

      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="loadExel"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_xlsx'] }}</span
        >
      </div>

      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="$modal.show('refuelingVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_petrol'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="$modal.show('washingVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_washing'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action"
        style="width: 85px"
        @click="acceptDelete"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_delete'] }}</span
        >
      </div>
      <div
        class="d-flex rounded-5 justify-content-center align-items-center text-center bg-primary cursor-pointer"
        style="width: 101px"
        @click="openModalSingle('zapchastiVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_bids_parts'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action disabled"
        style="width: 143px"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_bids_slesar'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action disabled"
        style="width: 143px"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_bids_kuzov'] }}</span
        >
      </div>
    </div>
    <div class="d-flex justify-content-between mt-15 mb-5">
      <div class="d-flex gap-15 align-items-center">
        <div
          class="btn btn-sm btn-thrity text-white fsc-14"
          @click="loadData(1)"
        >
          {{ dataLang['register_btn_filter_on'] }}
        </div>
        <div
          class="text-decoration-underline fsc-14 text-dark"
          style="cursor: pointer"
          @click="resetFilter"
        >
          {{ dataLang['register_btn_filter_off'] }}
        </div>
      </div>
      <BasePagination
        :page-count="computedAllPage"
        :pagination-params="activePagePagination"
        :showLimitInput="false"
        @updateLimitValue="updateLimitValue"
        :page-range="10"
        :margin-pages="2"
        :break-view-class="''"
        :click-handler="loadData"
        :prev-text="''"
        :next-text="''"
        :container-class="'custom-pagination'"
        :page-class="'custom-pagination__item'"
        :pageLinkClass="'custom-pagination__item-link'"
        v-if="!localLoading"
      >
      </BasePagination>
    </div>
    <ve-table
      style="width: 100%"
      :columns="columns"
      :table-data="dataTable"
      :border-x="true"
      :border-y="true"
      :sort-option="sortOption"
      ref="serviceTable"
      row-key-field-name="vehicle_services__id"
      :checkbox-option="{
        hideSelectAll: true,
        selectedRowChange: selectedRowChange
      }"
      v-if="!localLoading"
      :clickHighlight="false"
      :rowStyleOption="{
        hoverHighlight: true
      }"
      :cellSelectionOption="{ enable: false }"
    />
    <MilageVehicleModalSingle
      size="md"
      :optionalParams="{ id: currentOpenedId }"
      :minHeight="320"
      :contentLoaded="loadData"
      @click="$modal.show('addMilage')"
    />
    <OilDvsVehicleModalSingle
      size="md"
      :optionalParams="{ id: currentOpenedId }"
      :minHeight="320"
      :contentLoaded="loadData"
      nameForAction="oilDvsVehicleModalSingle"
    />
    <KppDvsVehicleModalSingle
      size="md"
      :optionalParams="{ id: currentOpenedId }"
      :minHeight="320"
      :contentLoaded="loadData"
      nameForAction="kppDvsVehicleModalSingle"
    />
    <OtherVehicleModalSingle
      size="md"
      :optionalParams="{ id: currentOpenedId }"
      :minHeight="320"
      :contentLoaded="loadData"
      nameForAction="otherVehicleModalSingle"
    />
    <TolingVehicleModalSingle
      size="md"
      :optionalParams="{ id: currentOpenedId }"
      :minHeight="320"
      :contentLoaded="loadData"
      nameForAction="tolingVehicleModalSingle"
    />
    <RefuelingVehicleModalSingle
      size="md"
      :optionalParams="{ id: currentOpenedId }"
      :minHeight="320"
      :contentLoaded="loadData"
      nameForAction="refuelingVehicleModalSingle"
    />
    <WashingVehicleModalSingle
      size="md"
      :optionalParams="{ id: currentOpenedId }"
      :minHeight="320"
      :contentLoaded="loadData"
      nameForAction="washingVehicleModalSingle"
    />
    <Teleport to="body">
      <ZapchastiVehicleModalSingle
        size="md"
        :optionalParams="{ id: currentOpenedId }"
        :minHeight="320"
        :contentLoaded="loadData"
        nameForAction="zapchastiVehicleModalSingle"
      />
      <SimpleModal
        size="md"
        :minHeight="320"
        nameForAction="acceptDeletes"
        :modal-title="dataLang['vehicle_modal_service_modals_title_8']"
      >
        <template v-slot:content
          ><p class="fsc-20 mb-30">
            {{ dataLang['vehicle_modal_service_modals_warning4'] }}
          </p>
          <div class="d-flex justify-content-center gap-30 mt-10 px-30 py-10">
            <div class="base-button py-15" @click="closeDeleteModal">
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
            <div class="btn-primary btn fsc-14 ws-nowrap" @click="deleteRow">
              {{ dataLang['vehile_modal_btn_delete_ok'] }}
            </div>
          </div>
        </template>
      </SimpleModal>
    </Teleport>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import ObjectsService from '@/api/ObjectsService'
import EventBus from '@/event-bus.js'
import MilageVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/MilageVehicleModalSingle.vue'
import OilDvsVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/OilDvsVehicleModalSingle.vue'
import KppDvsVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/KppDvsVehicleModalSingle.vue'
import OtherVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/OtherVehicleModalSingle.vue'
import RefuelingVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/RefuelingVehicleModalSingle.vue'
import TolingVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/TolingVehicleModalSingle.vue'
import WashingVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/WashingVehicleModalSingle.vue'
import ZapchastiVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/ZapchastiVehicleModalSingle.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SimpleModal from '@/components/vehicles-page-single-modal-inner/SimpleModal.vue'
import SelectsService from '@/api/SelectsService'

export default {
  components: {
    VeTable,
    MilageVehicleModalSingle,
    OilDvsVehicleModalSingle,
    KppDvsVehicleModalSingle,
    OtherVehicleModalSingle,
    RefuelingVehicleModalSingle,
    WashingVehicleModalSingle,
    TolingVehicleModalSingle,
    ZapchastiVehicleModalSingle,
    SimpleModal
  },

  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      selectsData: {
        types: []
      },
      selectsFilter: {
        type: []
      },
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      computedAllPage: 1,
      activePagePagination: {},
      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        }
      },
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      limitPaginationValue: 10
    }
  },
  methods: {
    updateLimitValue(data) {
      this.limitPaginationValue = data
      this.loadData()
      // this.getTransportVehiclesAll({
      //   page: 1,
      //   limit: this.limitPaginationValue,
      // })
    },
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      EventBus.$emit('contentLoaded')
      EventBus.$emit('updateContentData')
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    async loadExel() {
      let dataFrom = await ObjectsService.toExcel(this.currentOpenedId, {
        where: JSON.stringify(this.filter)
      })
      console.log(dataFrom)
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    acceptDelete() {
      if (this.activeSelectRowsMultiple.length) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDeletes',
          mode: 'create'
        })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    closeDeleteModal() {
      EventBus.$emit('closeModal', {
        data: null,
        nameForAction: 'acceptDeletes',
        mode: 'create'
      })
    },

    deleteRow() {
      this.$emit('loadingModal', true)
      this.closeDeleteModal()
      ObjectsService.delete(
        'services',
        this.currentOpenedId,
        this.activeSelectRowsMultiple
      )
        .then((data) => {
          console.log(data)
          this.loadData()
          this.activeSelectRowsMultiple = []

          EventBus.$emit('updateContentData')
          this.getHealth(this.currentOpenedId)
          this.getTitle(this.currentOpenedId)
        })
        .catch((err) => {
          this.$emit('loadingModal', false)
          console.log(err)
          this.activeSelectRowsMultiple = []
          this.$toast.error(err.response.data.message)
        })
    },
    openModalSingle(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create'
      })
    },
    loadData(newNumPage) {
      this.$emit('loadingModal', true)
      this.urlToQuaries.params.page = newNumPage
      Promise.all([
        ObjectsService.getAll(this.urlToQuaries)
          .then((data) => {
            this.$emit('loadingModal', false)
            this.activePagePagination = data.data.data
            this.computedAllPage = data.data.data.total / data.data.data.limit
            this.dataTable = data.data.data.rows
            this.activeSelectRowsMultiple = []
          })
          .catch((e) => console.log(e)),
        ObjectsService.getAll({ url: '/services/types' })
          .then((data) => {
            console.log(data)
            this.selectsData.types = data.data.data.rows
            // this.$emit('loadingModal', false)
            // this.activePagePagination = data.data.data
            // this.computedAllPage = data.data.data.total / data.data.data.limit
            // this.dataTable = data.data.data.rows
            // this.activeSelectRowsMultiple = []
          })
          .catch((e) => console.log(e))
        // SelectsService.getOne('types')
        //   .then((data) => {
        //     this.selectsData = this.prepareLabelsForDefaultSelects(
        //       data.data.data.rows,
        //       'allSelects',
        //       true
        //     )
        //     console.log(this.selectsData)
        //   })
        //   .catch((e) => console.log(e))
      ]).then(() => {
        this.localLoading = false
      })
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData()
      console.log(this.selectsData, this.$refs.serviceTable)
      this.selectsFilter = {
        type: []
      }
      this.$refs.serviceTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    sortChange(params) {
      console.log(params)
      this.dataTable.sort((a, b) => {
        if (params.vehicle_services__created_at) {
          if (params.vehicle_services__created_at === 'asc') {
            console.log('asc')
            return (
              a.vehicle_services__created_at - b.vehicle_services__created_at
            )
          } else if (params.vehicle_services__created_at === 'desc') {
            console.log('desc')
            return (
              b.vehicle_services__created_at - a.vehicle_services__created_at
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_services__odometr) {
          if (params.vehicle_services__odometr === 'asc') {
            console.log('asc')
            return a.vehicle_services__odometr - b.vehicle_services__odometr
          } else if (params.vehicle_services__odometr === 'desc') {
            console.log('desc')
            return b.vehicle_services__odometr - a.vehicle_services__odometr
          } else {
            return 0
          }
        }
        if (params.vehicle_services__text) {
          if (params.vehicle_services__text === 'asc') {
            console.log('asc')
            return a.vehicle_services__text - b.vehicle_services__text
          } else if (params.vehicle_services__text === 'desc') {
            console.log('desc')
            return b.vehicle_services__text - a.vehicle_services__text
          } else {
            return 0
          }
        }
        if (params.vehicle_services__price) {
          if (params.vehicle_services__price === 'asc') {
            console.log('asc')
            return a.vehicle_services__price - b.vehicle_services__price
          } else if (params.vehicle_services__price === 'desc') {
            console.log('desc')
            return b.vehicle_services__price - a.vehicle_services__price
          } else {
            return 0
          }
        }
        if (params.employees__name) {
          if (params.employees__name === 'asc') {
            console.log('asc')
            return a.employees__name - b.employees__name
          } else if (params.employees__name === 'desc') {
            console.log('desc')
            return b.employees__name - a.employees__name
          } else {
            return 0
          }
        }
      })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter)
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },
  mounted() {
    EventBus.$on('contentLoaded', () => {
      this.loadData()
    })
    dayjs.extend(utc)
  },
  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)
    this.loadData(1)
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    urlToQuaries() {
      return {
        url: `/${this.currentOpenedId}/services`,
        params: {
          limit: this.limitPaginationValue,
          page: 1,
          ...(this.filter.length && { where: JSON.stringify(this.filter) })
        }
      }
    },
    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: 5,
          align: 'center'
        },
        {
          field: 'vehicle_services__created_at',
          key: '1',
          title: this.dataLang['vehicle_modal_service_table_header_date'],
          align: 'left',
          width: '10%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'vehicle_services__created_at'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.vehicle_services__created_at
                  ? dayjs(row.vehicle_services__created_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(
                        `${this.userSetting.company__tempalte_date} HH:mm`
                      )
                  : ''}
              </span>
            )
          }
        },

        {
          field: 'vehicle_services__odometr',
          key: '2',
          title: this.dataLang['vehicle_modal_service_table_header_odometr'],
          align: 'left',
          width: '8%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'vehicle_service_types__name',
          key: '3',
          title: this.dataLang['vehicle_modal_service_table_header_type'],
          align: 'left',
          width: 70,
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },

          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <span>{column.title}</span>
                <BaseSelect
                  vModel={this.selectsFilter.type}
                  onInput={(e, w) => {
                    let indexCurrentFilter = this.filter.findIndex(
                      (x) => x.field == 'vehicle_services__service_type_id'
                    )
                    console.log(indexCurrentFilter)
                    if (indexCurrentFilter >= 0) {
                      console.log(
                        true,
                        this.filter[indexCurrentFilter],
                        e.vehicle_service_types__id
                      )
                      this.filter[indexCurrentFilter].value =
                        e.vehicle_service_types__id
                    } else {
                      this.filter.push({
                        field: 'vehicle_services__service_type_id',
                        value: e.vehicle_service_types__id
                      })
                    }
                  }}
                  style={'width: 100%'}
                  placeholder={''}
                  id={'vehicle_places__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  customLabel={({ vehicle_service_types__name }) => {
                    return `${vehicle_service_types__name}`
                  }}
                  options={this.selectsData.types}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'vehicle_services__text',
          key: '4',
          title: this.dataLang['vehicle_modal_service_table_header_text'],
          align: 'left',
          width: 70,
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'vehicle_services__text'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'vehicle_services__price',
          key: '5',
          title: this.dataLang['vehicle_modal_service_table_header_cost'],
          align: 'left',
          width: '8%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'employees__name',
          key: '6',
          title: this.dataLang['vehicle_modal_service_table_header_employee'],
          align: 'left',
          width: 70,
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>
