<template>
  <div>
    <BaseTopLine>
      <div class="d-flex gap-10 align-items-center"></div>
    </BaseTopLine>
    <div class="px-10">
      <div
        class="d-flex justify-content-between align-items-center pt-15 pb-10"
      >
        <div class="d-flex gap-10 align-items-center">
          <div class="base-button base-button--action" @click="checkClient">
            <img
              :src="require(`@/assets/icons/add.svg`)"
              width="13.5"
              height="13.5"
            />
            <span class="fsc-14">{{ dataLang['registry_ads_text1'] }}</span>
          </div>
          <div
            class="base-button base-button--action"
            @click="deleteActionAndModal"
          >
            <img
              :src="require(`@/assets/icons/adddel.svg`)"
              width="13.5"
              height="13.5"
            />
            <span class="fsc-14">{{ dataLang['registry_ads_text2'] }}</span>
          </div>
          <div class="base-button base-button--action" @click="toArchive">
            <img
              :src="require(`@/assets/icons/to-folder.svg`)"
              width="16"
              height="16"
            />
            <span class="fsc-14"> В архив</span>
          </div>
          <div class="base-button base-button--action" @click="duplicateRows">
            <img
              :src="require(`@/assets/icons/document-copy.svg`)"
              width="16"
              height="16"
            />
            <span class="fsc-14"> Дублировать</span>
          </div>
          <div
            class="base-button base-button--action"
            @click="
              toExcelTransportVehiclesData({ type: 'car', where: filter })
            "
          >
            <img
              :src="require(`@/assets/icons/to-exel.svg`)"
              width="16"
              height="16"
            />
            <span class="fsc-14">в Excel</span>
          </div>
        </div>
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="clickLoadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationStateParams"
          @updateLimitValue="updateLimitValue"
          :page-range="10"
          :margin-pages="2"
          :break-view-class="''"
          :click-handler="clickLoadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="position-relative">
        <BaseLoader
          v-if="transportVehiclesLoadStatus == 'request'"
        ></BaseLoader>
        <div class="transport-table">
          <ve-table
            style="width: 100%"
            :rowStyleOption="rowStyleOption"
            :cellSelectionOption="cellSelectionOption"
            :columns="columns"
            :table-data="transportVehiclesData"
            :border-x="true"
            :border-y="true"
            :checkbox-option="checkboxOption"
            row-key-field-name="rowKey"
            :sort-option="sortOption"
            v-if="transportVehiclesLoadStatus == 'success' && loadAllSelect"
            :cell-style-option="cellStyleOption"
            :eventCustomOption="eventCustomOption"
            ref="mainTable"
          />
        </div>
        <!-- <BaseReuseTable
          :props-data="transportVehiclesData"
          :props-columns-setting="fieldsTableTollRoads"
          :selectRowActivateFlag="true"
          :selectMultiple="true"
          :modalActivateFlag="true"
          nameForAction="vehicleSettingModal"
          v-if="transportVehiclesLoadStatus == 'success'"
        >
        </BaseReuseTable> -->
      </div>
    </div>
    <BaseModal
      name="vehicleSettingModal"
      width="1280"
      :clickToClose="false"
      :shiftY="0.5"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      :adaptive="true"
      :minHeight="800"
      classes="bg-white shadow-none"
    >
      <div class="position-relative d-flex flex-column h-100">
        <div
          v-if="loadingModal"
          class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
          style="top: 0; left: 0; z-index: 500"
        >
          <svg class="spinner" viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
        <div
          class="d-flex justify-content-between px-30 py-10 align-items-center"
        >
          <div class="d-flex text-dark">
            <span
              class="fsc-12 fw-medium lh-1"
              v-if="modeOpenModal == 'create'"
            >
              Добавление объекта в категорию
              <span class="fsc-12 fw-bolder">"Легковой"</span>
            </span>
            <div
              class="d-flex gap-30 align-items-center fsc-12"
              v-if="lineTitle && modeOpenModal == 'edit'"
            >
              <div class="d-flex align-items-center gap-5">
                <span class="fw-medium lh-1">{{ lineTitle.name }}</span
                ><span class="fw-bolder">{{ lineTitle.gosnumber }}</span>
                <span class="fw-medium text-muted" v-if="lineTitle.created_at"
                  >({{
                    dayInstanse(lineTitle.created_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(`${this.userSetting.company__tempalte_date}`)
                  }})</span
                >
              </div>
              <div class="d-flex align-items-center gap-5">
                <span class="fw-medium">{{
                  dataLang['vehile_modal_title_odometr']
                }}</span>
                <span class="fw-bolder lh-1">{{ lineTitle.odomert }}</span
                ><span
                  class="fw-medium text-muted"
                  v-if="lineTitle.odometrNextDate"
                  >({{
                    dayInstanse(lineTitle.odometrNextDate)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(`${this.userSetting.company__tempalte_date}`)
                  }})</span
                >
              </div>
              <div class="d-flex align-items-center gap-5">
                <span class="fw-medium">{{
                  dataLang['vehile_modal_title_id_vehicle']
                }}</span>
                <span class="fw-bolder lh-1">{{ lineTitle.idObject }}</span>
              </div>
              <div class="d-flex align-items-center gap-5">
                <span class="fw-medium">{{
                  dataLang['vehile_modal_title_id_owner']
                }}</span>
                <span class="fw-bolder lh-1">{{ lineTitle.idOwner }}</span>
              </div>
              <div class="d-flex align-items-center gap-5">
                <span class="fw-medium">{{
                  dataLang['vehile_modal_title_id_place']
                }}</span>
                <span class="fw-bolder lh-1">{{ lineTitle.idPlace }}</span>
              </div>
              <div class="d-flex align-items-center gap-5">
                <span class="fw-medium">{{
                  dataLang['vehile_modal_title_rayting']
                }}</span>
                <span class="fw-bolder lh-1">{{ lineTitle.rayting }}</span>
              </div>
              <div class="d-flex align-items-center gap-5 ms-130">
                <span class="fw-medium">{{
                  dataLang['vehile_modal_title_status']
                }}</span>
                <span class="fw-bolder lh-1" :class="[lineTitle.statusClass]">{{
                  lineTitle.status
                }}</span>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close p-0"
            @click="$modal.hide('vehicleSettingModal')"
          ></button>
        </div>
        <div class="d-flex flex-column flex-grow-1" v-if="modalOpened">
          <vue-tabs
            v-model="activateTab"
            @tab-change="handleTabChange"
            class="flex-grow-1"
          >
            <v-tab
              :title="dataLang['vehicles_page__modal__tab_object__name']"
              data-name="vehicleTab"
            >
              <div class="px-30 pt-10 pb-5">
                <VehicleTab
                  ref="vehicleTab"
                  :modeOpenModal="modeOpenModal"
                  @reloadData="clickLoadData(1)"
                  @loadingModal="loadingModalHandler"
                  @changeErrorInTab="changeErrorInTab"
                  :currentOpenedId="currentOpenedId"
                  v-if="listTabs.vehicleTab"
                ></VehicleTab>
              </div>
            </v-tab>
            <v-tab
              :title="dataLang['vehicles_page__modal__tab_service__name']"
              data-name="servicesTab"
              v-if="modeOpenModal == 'edit'"
            >
              <div class="px-30 pt-10 pb-5">
                <ServicesTab
                  ref="servicesTab"
                  :modeOpenModal="modeOpenModal"
                  v-if="listTabs.servicesTab"
                  @loadingModal="loadingModalHandler"
                  :currentOpenedId="currentOpenedId"
                ></ServicesTab>
              </div>
            </v-tab>
            <v-tab
              :title="dataLang['vehicles_page__modal__tab_notes__name']"
              data-name="notesTab"
              v-if="modeOpenModal == 'edit'"
            >
              <div class="px-30 pt-10 pb-5">
                <NotesTab
                  ref="notesTab"
                  :modeOpenModal="modeOpenModal"
                  v-if="listTabs.notesTab"
                  @loadingModal="loadingModalHandler"
                  :currentOpenedId="currentOpenedId"
                ></NotesTab>
              </div>
            </v-tab>
            <v-tab
              :title="dataLang['vehicles_page__modal__tab_security__name']"
              data-name="securityTab"
              v-if="modeOpenModal == 'edit'"
              ><div class="px-30 pt-10 pb-5">
                <SecurityTab
                  ref="securityTab"
                  :modeOpenModal="modeOpenModal"
                  v-if="listTabs.securityTab"
                  @loadingModal="loadingModalHandler"
                  :currentOpenedId="currentOpenedId"
                ></SecurityTab>
              </div>
            </v-tab>
            <v-tab
              :title="dataLang['vehicles_page__modal__tab_tires__name']"
              data-name="tiresTab"
              v-if="modeOpenModal == 'edit'"
            >
              <TiresTab
                ref="tiresTab"
                :modeOpenModal="modeOpenModal"
                v-if="listTabs.tiresTab"
                @loadingModal="loadingModalHandler"
                :currentOpenedId="currentOpenedId"
              ></TiresTab>
            </v-tab>
            <v-tab
              :title="dataLang['vehicles_page__modal__tab_files__name']"
              data-name="filesTab"
              v-if="modeOpenModal == 'edit'"
            >
              <FilesTab
                ref="filesTab"
                :modeOpenModal="modeOpenModal"
                v-if="listTabs.filesTab"
                @loadingModal="loadingModalHandler"
                @forceReload="forceReload"
                :currentOpenedId="currentOpenedId"
              ></FilesTab>
            </v-tab>

            <v-tab
              :title="dataLang['vehicles_page__modal__tab_rayting__name']"
              data-name="raytingTab"
              v-if="modeOpenModal == 'edit'"
            >
              <RaytingTab
                ref="raytingTab"
                :modeOpenModal="modeOpenModal"
                v-if="listTabs.raytingTab"
                @loadingModal="loadingModalHandler"
                :currentOpenedId="currentOpenedId"
              ></RaytingTab>
            </v-tab>

            <v-tab
              :title="dataLang['vehicles_page__modal__tab_rent__name']"
              data-name="settingTab"
              v-if="modeOpenModal == 'edit'"
            >
              <div class="px-30 pt-10 pb-5">
                <SettingTab
                  ref="settingTab"
                  :modeOpenModal="modeOpenModal"
                  v-if="listTabs.settingTab"
                  @loadingModal="loadingModalHandler"
                  :currentOpenedId="currentOpenedId"
                ></SettingTab>
              </div>
            </v-tab>

            <v-tab
              :title="dataLang['vehicles_page__modal__tab_comments__name']"
              data-name="commentsTab"
              v-if="modeOpenModal == 'edit'"
            >
              <div class="px-30 py-10">
                <CommentsTab
                  ref="commentsTab"
                  :modeOpenModal="modeOpenModal"
                  v-if="listTabs.commentsTab"
                  @loadingModal="loadingModalHandler"
                  :currentOpenedId="currentOpenedId"
                ></CommentsTab>
              </div>
            </v-tab>
          </vue-tabs>
          <div
            class="line-state"
            v-if="this.lineHealthVehicle && modeOpenModal == 'edit'"
          >
            <div
              v-for="(item, index) of this.lineHealthVehicle"
              :key="index"
              class="line-state__badge"
              :class="[item.class]"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="d-flex justify-content-end gap-30 mt-5 px-30 pb-5">
            <div
              class="base-button"
              @click="$modal.hide('vehicleSettingModal')"
            >
              Отменить
            </div>
            <div class="base-button" @click="sendData">Сохранить и закрыть</div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="acceptDelete"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDelete')"
          ></button>
        </div>
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">
            {{ dataLang.confirm_btn__btn_yes }}
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import CitiesService from '@/api/CitiesService.js'
import SelectsService from '@/api/SelectsService'
import Modal from '@/components/Modals.vue'
import NewModal from '@/components/NewModal.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from '@/components/ui/BaseTextarea.vue'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import VehicleTab from './transport-vehicles-tabs-modal/VehicleTab.vue'
import ServicesTab from './transport-vehicles-tabs-modal/ServicesTab.vue'
import SecurityTab from './transport-vehicles-tabs-modal/SecurityTab.vue'
import TiresTab from './transport-vehicles-tabs-modal/TiresTab.vue'
import FilesTab from './transport-vehicles-tabs-modal/FilesTab.vue'
import RaytingTab from './transport-vehicles-tabs-modal/RaytingTab.vue'
import NotesTab from './transport-vehicles-tabs-modal/NotesTab.vue'
import CommentsTab from './transport-vehicles-tabs-modal/CommentsTab.vue'
import SettingTab from './transport-vehicles-tabs-modal/SettingTab.vue'
import BaseInputVue from '@/components/ui/BaseInput.vue'
import PlacesService from '@/api/PlacesService'
// import AddIcon from '../assets/icons/add.svg'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import GeneralService from '@/api/GeneralService'
export default {
  components: {
    Modal,
    NewModal,
    VehicleTab,
    BaseTextarea,
    VueTabs,
    VTab,
    VeTable,
    ServicesTab,
    SecurityTab,
    TiresTab,
    NotesTab,
    CommentsTab,
    FilesTab,
    RaytingTab,
    SettingTab
  },

  props: {},
  data() {
    return {
      dayInstanse: dayjs,
      newTab: null,
      oldTab: null,
      activeCurrentTab: '',
      errorStateInTab: false,
      loadingModal: false,
      loadAllSelect: false,

      listTabs: {
        vehicleTab: true,
        servicesTab: false,
        securityTab: false,
        tiresTab: false,
        notesTab: false,
        commentsTab: false,
        filesTab: false,
        raytingTab: false,
        settingTab: false
      },
      options: ['list', 'of', 'options'],
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        }
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          return 'table-header-cell-class'
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (
            column.field ===
            Object.keys(row.settings).find((x) => x == column.field)
          ) {
            return row.settings[column.field]
          }

          // vehicles__agregator_state
        }
      },
      checkboxOption: {
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          console.log(selectedRowKeys)
          this.activeSelectRowsMultiple = selectedRowKeys
        },
        // selected all change event
        selectedAllChange: ({ row, isSelected, selectedRowKeys }) => {
          console.log(selectedRowKeys)
          this.activeSelectRowsMultiple = selectedRowKeys
        }
      },

      selectsFilter: {
        city: [],
        place: [],
        status: [],
        color: [],
        date: [],
        override: [],
        dvs: [],
        kpp: [],
        osago: [],
        kasko: [],
        to: [],
        tries: [],
        zam: [],
        comment: [],
        withoutdriver: [],
        withdriver: [],
        buyout: [],
        ygs: [],
        ygf: [],
        ygt: [],
        rayting: []
      },

      updatedValues: {},
      dataForModal: {},
      editData: {},
      activeSelectRow: null,
      activeSelectRowsMultiple: [],
      activateTab: null,
      modeOpenModal: 'create',
      modalOpened: false,
      cities: [],
      places: [],
      selectsData: {
        vehicles__color: [],
        filter_overdue: [],
        filter_yesno: [],
        filter_tires: [],
        filter_rayting: []
      },
      filter: [],
      currentOpenedId: null,
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('vehicleSettingModal', { mode: 'edit' })
              this.modalOpened = true
              // this.activateTab =
              //   this.dataLang['vehicles_page__modal__tab_object__name']
              this.currentOpenedId = row.vehicles__id
            }
          }
        }
      },
      limitPaginationValue: 10
    }
  },
  methods: {
    checkClient() {
      GeneralService.get(`/caniuse/createObject?objectType=car`)
        .then((data) => {
          this.$modal.show('vehicleSettingModal', { mode: 'create' })
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
        })
    },
    updateLimitValue(data) {
      this.limitPaginationValue = data
      this.getTransportVehiclesAll({
        page: 1,
        limit: this.limitPaginationValue
      })
    },
    forceReload() {
      console.log(this.activeCurrentTab)
      this.listTabs[this.activeCurrentTab] = false
      setTimeout(() => {
        this.listTabs[this.activeCurrentTab] = true
      }, 1)
    },
    beforeOpen(event) {
      console.log(event)
      this.activeCurrentTab = 'vehicleTab'
      if (
        event.name == 'vehicleSettingModal' &&
        event.params.mode == 'create'
      ) {
        this.modeOpenModal = 'create'
      } else {
        // this.activateTab = this.dataLang['vehicles_page__modal__tab_object__name']
        this.modeOpenModal = 'edit'
      }
      this.modalOpened = true
    },
    beforeClose(event) {
      this.modalOpened = false
      this.activateTab = null

      this.activeCurrentTab = ''
      this.listTabs = {
        vehicleTab: true,
        servicesTab: false,
        securityTab: false,
        tiresTab: false,
        notesTab: false,
        commentsTab: false,
        filesTab: false,
        raytingTab: false,
        settingTab: false
      }
    },
    sendData() {
      this.$refs[this.activeCurrentTab].sendData(
        this.assignedTabChange,
        '',
        '',
        '',
        true
      )
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      if (oldTab.title !== newTab.title) {
        this.loadingModal = true
        this.listTabs[oldTab.$attrs['data-name']] = true
        this.activeCurrentTab = newTab.$attrs['data-name']
        console.log(oldTab.title, newTab.title, this.listTabs)
        this.$refs[oldTab.$attrs['data-name']].sendData(
          this.assignedTabChange,
          oldTab.title,
          oldTab,
          newTab
        )
      }
    },

    assignedTabChange(
      isForward,
      linkToTabHasName,
      oldTab,
      newTab,
      flagIsClose
    ) {
      console.log(flagIsClose)
      if (this.modeOpenModal == 'edit') {
        if (newTab && oldTab) {
          if (isForward) {
            this.listTabs[newTab.$attrs['data-name']] = true
            this.listTabs[oldTab.$attrs['data-name']] = false
          } else {
            this.activateTab = linkToTabHasName
            this.listTabs[newTab.$attrs['data-name']] = false
            this.listTabs[oldTab.$attrs['data-name']] = true
          }
        } else {
          if (flagIsClose) {
            this.$modal.hide('vehicleSettingModal')
          }
        }
      } else {
        if (isForward) {
          this.$modal.hide('vehicleSettingModal')
        } else {
        }
      }

      this.loadingModal = false
    },
    changeErrorInTab(state) {
      this.errorStateInTab = state
    },
    loadingModalHandler(stateLoading) {
      this.loadingModal = stateLoading
    },

    sortChange(params) {
      console.log(params)
      this.transportVehiclesData.sort((a, b) => {
        if (params.vehicles__id) {
          if (params.vehicles__id === 'asc') {
            console.log('asc')
            return a.vehicles__id - b.vehicles__id
          } else if (params.vehicles__id === 'desc') {
            console.log('desc')
            return b.vehicles__id - a.vehicles__id
          } else {
            return 0
          }
        }
        if (params.cities__name) {
          if (params.cities__name === 'asc') {
            console.log('asc')
            return a.cities__name - b.cities__name
          } else if (params.cities__name === 'desc') {
            console.log('desc')
            return b.cities__name - a.cities__name
          } else {
            return 0
          }
        }
        if (params.vehicle_places__address) {
          if (params.vehicle_places__address === 'asc') {
            console.log('asc')
            return a.vehicle_places__address - b.vehicle_places__address
          } else if (params.vehicle_places__address === 'desc') {
            console.log('desc')
            return b.vehicle_places__address - a.vehicle_places__address
          } else {
            return 0
          }
        }
        if (params.vehicle_places__address) {
          if (params.vehicle_status__status === 'asc') {
            console.log('asc')
            return a.vehicle_status__status - b.vehicle_status__status
          } else if (params.vehicle_status__status === 'desc') {
            console.log('desc')
            return b.vehicle_status__status - a.vehicle_status__status
          } else {
            return 0
          }
        }
        if (params.vehicles__name) {
          if (params.vehicles__name === 'asc') {
            console.log('asc')
            return a.vehicles__name - b.vehicles__name
          } else if (params.vehicles__name === 'desc') {
            console.log('desc')
            return b.vehicles__name - a.vehicles__name
          } else {
            return 0
          }
        }
        if (params.vehicles__year_release) {
          if (params.vehicles__year_release === 'asc') {
            console.log('asc')
            return a.vehicles__year_release - b.vehicles__year_release
          } else if (params.vehicles__year_release === 'desc') {
            console.log('desc')
            return b.vehicles__year_release - a.vehicles__year_release
          } else {
            return 0
          }
        }
        if (params.vehicles__gosnumber) {
          if (params.vehicles__gosnumber === 'asc') {
            console.log('asc')
            return a.vehicles__gosnumber - b.vehicles__gosnumber
          } else if (params.vehicles__gosnumber === 'desc') {
            console.log('desc')
            return b.vehicles__gosnumber - a.vehicles__gosnumber
          } else {
            return 0
          }
        }
        if (params.vehicles__color) {
          if (params.vehicles__color === 'asc') {
            console.log('asc')
            return a.vehicles__color - b.vehicles__color
          } else if (params.vehicles__color === 'desc') {
            console.log('desc')
            return b.vehicles__color - a.vehicles__color
          } else {
            return 0
          }
        }
        if (params.vehicles__odometr_next_date) {
          if (params.vehicles__odometr_next_date === 'asc') {
            console.log('asc')
            return a.vehicles__odometr_next_date - b.vehicles__odometr_next_date
          } else if (params.vehicles__odometr_next_date === 'desc') {
            console.log('desc')
            return b.vehicles__odometr_next_date - a.vehicles__odometr_next_date
          } else {
            return 0
          }
        }
        if (params.vehicles__odometr) {
          if (params.vehicles__odometr === 'asc') {
            console.log('asc')
            return a.vehicles__odometr - b.vehicles__odometr
          } else if (params.vehicles__odometr === 'desc') {
            console.log('desc')
            return b.vehicles__odometr - a.vehicles__odometr
          } else {
            return 0
          }
        }
        if (params.vehicle_cars__oil_dvs_next_mileage) {
          if (params.vehicle_cars__oil_dvs_next_mileage === 'asc') {
            console.log('asc')
            return (
              a.vehicle_cars__oil_dvs_next_mileage -
              b.vehicle_cars__oil_dvs_next_mileage
            )
          } else if (params.vehicle_cars__oil_dvs_next_mileage === 'desc') {
            console.log('desc')
            return (
              b.vehicle_cars__oil_dvs_next_mileage -
              a.vehicle_cars__oil_dvs_next_mileage
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_cars__oil_kpp_next_mileage) {
          if (params.vehicle_cars__oil_kpp_next_mileage === 'asc') {
            console.log('asc')
            return (
              a.vehicle_cars__oil_kpp_next_mileage -
              b.vehicle_cars__oil_kpp_next_mileage
            )
          } else if (params.vehicle_cars__oil_kpp_next_mileage === 'desc') {
            console.log('desc')
            return (
              b.vehicle_cars__oil_kpp_next_mileage -
              a.vehicle_cars__oil_kpp_next_mileage
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_cars__oil_kpp_next_mileage) {
          if (params.vehicle_cars__oil_kpp_next_mileage === 'asc') {
            console.log('asc')
            return (
              a.vehicle_cars__oil_kpp_next_mileage -
              b.vehicle_cars__oil_kpp_next_mileage
            )
          } else if (params.vehicle_cars__oil_kpp_next_mileage === 'desc') {
            console.log('desc')
            return (
              b.vehicle_cars__oil_kpp_next_mileage -
              a.vehicle_cars__oil_kpp_next_mileage
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_cars__oil_kpp_next_mileage) {
          if (params.vehicle_cars__oil_kpp_next_mileage === 'asc') {
            console.log('asc')
            return (
              a.vehicle_cars__oil_kpp_next_mileage -
              b.vehicle_cars__oil_kpp_next_mileage
            )
          } else if (params.vehicle_cars__oil_kpp_next_mileage === 'desc') {
            console.log('desc')
            return (
              b.vehicle_cars__oil_kpp_next_mileage -
              a.vehicle_cars__oil_kpp_next_mileage
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_osago__policy_to) {
          if (params.vehicle_osago__policy_to === 'asc') {
            console.log('asc')
            return a.vehicle_osago__policy_to - b.vehicle_osago__policy_to
          } else if (params.vehicle_osago__policy_to === 'desc') {
            console.log('desc')
            return b.vehicle_osago__policy_to - a.vehicle_osago__policy_to
          } else {
            return 0
          }
        }
        if (params.vehicle_kasko__policy_to) {
          if (params.vehicle_kasko__policy_to === 'asc') {
            console.log('asc')
            return a.vehicle_kasko__policy_to - b.vehicle_kasko__policy_to
          } else if (params.vehicle_kasko__policy_to === 'desc') {
            console.log('desc')
            return b.vehicle_kasko__policy_to - a.vehicle_kasko__policy_to
          } else {
            return 0
          }
        }
        if (params.vehicles__next_to_date) {
          if (params.vehicles__next_to_date === 'asc') {
            console.log('asc')
            return a.vehicles__next_to_date - b.vehicles__next_to_date
          } else if (params.vehicles__next_to_date === 'desc') {
            console.log('desc')
            return b.vehicles__next_to_date - a.vehicles__next_to_date
          } else {
            return 0
          }
        }
        if (params.vehicle_tires__season) {
          if (params.vehicle_tires__season === 'asc') {
            console.log('asc')
            return a.vehicle_tires__season - b.vehicle_tires__season
          } else if (params.vehicle_tires__season === 'desc') {
            console.log('desc')
            return b.vehicle_tires__season - a.vehicle_tires__season
          } else {
            return 0
          }
        }
        if (params.vehicles__notes_count) {
          if (params.vehicles__notes_count === 'asc') {
            console.log('asc')
            return a.vehicles__notes_count - b.vehicles__notes_count
          } else if (params.vehicles__notes_count === 'desc') {
            console.log('desc')
            return b.vehicles__notes_count - a.vehicles__notes_count
          } else {
            return 0
          }
        }
        if (params.vehicles__comment_count) {
          if (params.vehicles__comment_count === 'asc') {
            console.log('asc')
            return a.vehicles__comment_count - b.vehicles__comment_count
          } else if (params.vehicles__comment_count === 'desc') {
            console.log('desc')
            return b.vehicles__comment_count - a.vehicles__comment_count
          } else {
            return 0
          }
        }
        if (params.vehicles__ygsf_city_no_driver) {
          if (params.vehicles__ygsf_city_no_driver === 'asc') {
            console.log('asc')
            return (
              a.vehicles__ygsf_city_no_driver - b.vehicles__ygsf_city_no_driver
            )
          } else if (params.vehicles__ygsf_city_no_driver === 'desc') {
            console.log('desc')
            return (
              b.vehicles__ygsf_city_no_driver - a.vehicles__ygsf_city_no_driver
            )
          } else {
            return 0
          }
        }
        if (params.vehicles__ygsf_city_with_driver) {
          if (params.vehicles__ygsf_city_with_driver === 'asc') {
            console.log('asc')
            return (
              a.vehicles__ygsf_city_with_driver -
              b.vehicles__ygsf_city_with_driver
            )
          } else if (params.vehicles__ygsf_city_with_driver === 'desc') {
            console.log('desc')
            return (
              b.vehicles__ygsf_city_with_driver -
              a.vehicles__ygsf_city_with_driver
            )
          } else {
            return 0
          }
        }
        if (params.vehicles__ygsf_city_buyout) {
          if (params.vehicles__ygsf_city_buyout === 'asc') {
            console.log('asc')
            return a.vehicles__ygsf_city_buyout - b.vehicles__ygsf_city_buyout
          } else if (params.vehicles__ygsf_city_buyout === 'desc') {
            console.log('desc')
            return b.vehicles__ygsf_city_buyout - a.vehicles__ygsf_city_buyout
          } else {
            return 0
          }
        }
        if (params.vehicles__ygs_world) {
          if (params.vehicles__ygs_world === 'asc') {
            console.log('asc')
            return a.vehicles__ygs_world - b.vehicles__ygs_world
          } else if (params.vehicles__ygs_world === 'desc') {
            console.log('desc')
            return b.vehicles__ygs_world - a.vehicles__ygs_world
          } else {
            return 0
          }
        }
        if (params.vehicles__ygf_world) {
          if (params.vehicles__ygf_world === 'asc') {
            console.log('asc')
            return a.vehicles__ygf_world - b.vehicles__ygf_world
          } else if (params.vehicles__ygf_world === 'desc') {
            console.log('desc')
            return b.vehicles__ygf_world - a.vehicles__ygf_world
          } else {
            return 0
          }
        }
        if (params.vehicles__ygt_world) {
          if (params.vehicles__ygt_world === 'asc') {
            console.log('asc')
            return a.vehicles__ygt_world - b.vehicles__ygt_world
          } else if (params.vehicles__ygt_world === 'desc') {
            console.log('desc')
            return b.vehicles__ygt_world - a.vehicles__ygt_world
          } else {
            return 0
          }
        }
        if (params.vehicles__rayting) {
          if (params.vehicles__rayting === 'asc') {
            console.log('asc')
            return a.vehicles__rayting - b.vehicles__rayting
          } else if (params.vehicles__rayting === 'desc') {
            console.log('desc')
            return b.vehicles__rayting - a.vehicles__rayting
          } else {
            return 0
          }
        }
        // else if (params.weight) {
        //   if (params.weight === 'asc') {
        //     return a.weight - b.weight
        //   } else if (params.weight === 'desc') {
        //     return b.weight - a.weight
        //   } else {
        //     return 0
        //   }
        // }
      })
    },

    resetFilter() {
      // this.selectsData
      this.filter = []
      for (const key in this.selectsFilter) {
        this.selectsFilter[key] = []
      }
      this.getTransportVehiclesAll({
        page: 1,
        limit: this.limitPaginationValue
      })
      console.log(this.selectsData, this.$refs.mainTable)
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    setSelected(e, w) {
      console.log(e, w)
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
    },

    toArchive() {
      if (this.activeSelectRowsMultiple.length) {
        this.toArchiveTransportVehiclesMultiple(this.activeSelectRowsMultiple)
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },

    duplicateRows() {
      if (this.activeSelectRowsMultiple.length) {
        this.duplicateTransportVehiclesMultiple(this.activeSelectRowsMultiple)
        this.clickLoadData(1)
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },

    selectRangeTableRows(payload) {
      this.activeSelectRowsMultiple = payload.data.map((x) => x.vehicles__id)
      console.log(this.activeSelectRowsMultiple)
    },
    clickLoadData(numPage) {
      console.log(333333333)
      this.getTransportVehiclesAll({
        limit: this.limitPaginationValue,
        page: numPage,
        type: 'car',
        ...(this.filter.length && { where: JSON.stringify(this.filter) })
      })
    },

    async loadDoc() {
      let datas = await fetch(
        'http://api.yandexrent.development/vehicles/table/convert/xlsx?type=car&where=[]',
        {
          headers: {
            'X-Employee-Token':
              'dsAuUj8c7q7R2zqfX6ciWkm9EBnnWazCitsqovUd0aNf3SoqxTmGtRap7IKteERi8Kiiu+v6qU14swaBWz9/uIL0TXS7HV/33pzGHsfChvZbiOzW8rbl+ZSXZfoRwj7KLe0exzsj1Cg3ajGCagZenyLQKTWxSTiHkLq6zeA1VMI0ST/XFWechXbypnOPJ1YUzPWM9yEuAUeTqhUXPue9Qw=='
          }
        }
      )
    },

    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    deleteRow(nameModal) {
      this.deleteTransportVehiclesData(this.activeSelectRowsMultiple)
      this.activeSelectRow = null
      this.$modal.hide('acceptDelete')
      this.clickLoadData(1)
      {
        /* EventBus.$emit('closeModal', { nameForAction: 'acceptDelete' }) */
      }
    },
    prepareKeysForModal(payloadData) {
      this.updatedValues = {}
      this.dataForModal = {}
      if (!payloadData) {
        for (const key in this.TollRoadsData[0]) {
          this.dataForModal[key] = null
        }
      } else {
        this.dataForModal = Object.assign({}, payloadData)
      }
    },

    closeModal(data) {
      this.activeSelectRow = {}
      this.dataForModal = {}
      // this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
      //     element.value = ''
      // })
      this.modalOpened = false
    },
    btnHandlerCloseModal() {
      EventBus.$emit('closeModal', { nameForAction: 'vehicleSettingModal' })
    },
    customHeaderFilter(headerValue, rowValue, rowData, filterParams) {
      return rowData.name == filterParams.name && rowValue < headerValue //must return a boolean, true if it passes the filter.
    },
    prepareLabelsForDefaultSelects(data) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
        })
      }
      return data
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('transportVehiclesModule', [
      'getHealth',
      'getTitle',
      'getTransportVehiclesAll',
      'editTransportVehiclesMultiple',
      'createTransportVehiclesData',
      'deleteTransportVehiclesData',
      'downloadTransportVehiclesExel',
      'toArchiveTransportVehiclesMultiple',
      'duplicateTransportVehiclesMultiple',
      'toExcelTransportVehiclesData'
    ])
  },
  mounted() {
    this.dayInstanse.extend(utc)
    EventBus.$on('updateContentData', () => {
      console.log(1231123213)
      this.clickLoadData(1)
      this.getHealth(this.currentOpenedId)
      this.getTitle(this.currentOpenedId)
    })
  },

  created() {
    this.getTransportVehiclesAll({ page: 1, limit: 10 })
    this.loadAllSelect = false
    Promise.all([
      CitiesService.getAll({
        params: {
          limit: 3000
        }
      })
        .then((data) => {
          this.cities = data.data.data.rows
          console.log(this.cities)
        })
        .catch((e) => console.log(e)),
      PlacesService.getAllWithoutID({
        params: {
          limit: 3000
        }
      })
        .then((data) => {
          this.places = data.data.places.rows
          console.log(this.cities)
        })
        .catch((e) => console.log(e)),
      SelectsService.getAll()
        .then((data) => {
          this.selectsData = this.prepareLabelsForDefaultSelects(
            data.data.data.rows,
            'allSelects',
            true
          )
          console.log(this.selectsData)
        })
        .catch((e) => console.log(e))
    ]).then(() => {
      this.loadAllSelect = true
    })
  },
  computed: {
    ...mapState('transportVehiclesModule', [
      'transportVehiclesData',
      'transportVehiclesLoadStatus',
      'paginationStateParams',
      'lineHealthVehicle',
      'lineTitle'
    ]),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('transportVehiclesModule', ['numPages']),

    columns() {
      return [
        {
          field: '',
          key: 'aa',
          type: 'checkbox',
          title: '',
          align: 'center',
          width: 30
        },
        {
          title: 'Данные ТС',
          children: [
            {
              field: 'vehicles__id',
              key: '1',
              title: 'ID',
              align: 'center',
              width: 70,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'vehicles__id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'cities__name',
              key: '2',
              title: 'Город',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              align: 'center',
              width: '10%',
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.city}
                      onInput={(e, w) => {
                        if (!this.filter.find((x) => x.field == 'cities__id')) {
                          this.filter.push({
                            field: 'cities__id',
                            value: e.cities__id
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'cities__id'
                            )
                          ].value = e.cities__id
                        }
                      }}
                      style={'width: 100%'}
                      label={'cities__name'}
                      placeholder={''}
                      id={'cities__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ cities__name, cities__region }) => {
                        return `${cities__name} (${cities__region})`
                      }}
                      options={this.cities}
                    ></BaseSelect>
                  </div>
                )
              }
            },
            {
              field: 'vehicle_places__name',
              key: '3',
              title: 'Площадка',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              align: 'center',
              width: '10%',
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.place}
                      onInput={(e, w) => {
                        if (
                          !this.filter.find(
                            (x) => x.field == 'vehicles__place_id'
                          )
                        ) {
                          this.filter.push({
                            field: 'vehicles__place_id',
                            value: e.vehicle_places__id
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'vehicles__place_id'
                            )
                          ].value = e.vehicle_places__id
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({
                        vehicle_places__address,
                        vehicle_places__name
                      }) => {
                        return `${vehicle_places__name}`
                      }}
                      options={this.places}
                    ></BaseSelect>
                  </div>
                )
              }
            },

            {
              field: 'vehicles__name',
              key: '5',
              title: 'Марка/Модель',
              align: 'center',
              width: '10%',
              sortBy: '',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'vehicles__name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'vehicles__year_release',
              key: '6',
              title: 'Год',
              align: 'center',
              width: 40,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseInputNew
                      placeholder=''
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'vehicles__year_release'}
                      value={''}
                      nameSign={''}
                      inputRef={'vehicles__year_release'}
                      inputMask={'0000'}
                    />
                  </div>
                )
              }
            },
            {
              field: 'vehicles__gosnumber',
              key: '7',
              title: 'Госномер',
              align: 'center',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseInputNew
                      placeholder=''
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'vehicles__gosnumber'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'vehicles__color',
              key: '8',
              title: 'Цвет',
              align: 'center',
              width: 70,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.color}
                      onInput={(e, w) => {
                        if (
                          !this.filter.find((x) => x.field == 'vehicles__color')
                        ) {
                          this.filter.push({
                            field: 'vehicles__color',
                            value: e.value
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'vehicles__color'
                            )
                          ].value = e.value
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.vehicles__color}
                    ></BaseSelect>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: 'Актуальность пробега',
          children: [
            {
              field: 'vehicles__odometr_next_date',
              key: '11',
              title: 'Дата',
              align: 'center',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.date}
                      onInput={(e, w) => {
                        let indexCurrentFilter = this.filter.findIndex(
                          (x) => x.field == 'vehicles__odometr_next_date'
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__odometr_next_date',
                              value: 's_nowDateTime',
                              operator: '<='
                            }
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__odometr_next_date',
                              value: 's_nowDateTime',
                              operator: '>'
                            }
                          } else if (
                            e.value == 'filter_select_unselected_overdue'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          this.filter.push({
                            field: 'vehicles__odometr_next_date',
                            value: 's_nowDateTime',
                            operator:
                              e.value == 'filter_select_overdue' ? '<=' : '>'
                          })
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_overdue}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {this.dayInstanse(row.vehicles__odometr_next_date)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(`${this.userSetting.company__tempalte_date}`)}
                  </span>
                )
              }
            },
            {
              field: 'vehicles__odometr',
              key: '12',
              title: 'Пробег',
              align: 'center',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: 'События по пробегу',
          children: [
            {
              field: 'vehicle_cars__oil_dvs_next_mileage',
              key: '21',
              title: 'Масло ДВС',
              align: 'center',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.dvs}
                      onInput={(e, w) => {
                        let today = new Date()
                        today.toISOString().split('T')[0]
                        let fullTime = today.toISOString().split('T')[0]
                        console.log(fullTime)
                        let indexCurrentFilter = this.filter.findIndex((x) =>
                          x.condition.includes(
                            'vehicle_cars__oil_dvs_next_mileage'
                          )
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_overdue') {
                            this.filter[indexCurrentFilter] = {
                              condition:
                                'vehicles__odometr>vehicle_cars__oil_dvs_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            }
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter[indexCurrentFilter] = {
                              condition:
                                'vehicles__odometr<vehicle_cars__oil_dvs_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            }
                          } else if (
                            e.value == 'filter_select_unselected_overdue'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          if (e.value == 'filter_select_overdue') {
                            this.filter.push({
                              condition:
                                'vehicles__odometr>vehicle_cars__oil_dvs_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            })
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter.push({
                              condition:
                                'vehicles__odometr<vehicle_cars__oil_dvs_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            })
                          } else if (
                            e.value == 'filter_select_unselected_overdue'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_overdue}
                    ></BaseSelect>
                  </div>
                )
              }
            },
            {
              field: 'vehicle_cars__oil_kpp_next_mileage',
              key: '22',
              title: 'Масло КПП',
              align: 'center',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.kpp}
                      onInput={(e, w) => {
                        let today = new Date()
                        today.toISOString().split('T')[0]
                        let fullTime = today.toISOString().split('T')[0]
                        console.log(fullTime)
                        let indexCurrentFilter = this.filter.findIndex((x) =>
                          x.condition.includes(
                            'vehicle_cars__oil_kpp_next_mileage'
                          )
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_overdue') {
                            this.filter[indexCurrentFilter] = {
                              condition:
                                'vehicles__odometr>vehicle_cars__oil_kpp_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            }
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter[indexCurrentFilter] = {
                              condition:
                                'vehicles__odometr<vehicle_cars__oil_kpp_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            }
                          } else if (
                            e.value == 'filter_select_unselected_overdue'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          if (e.value == 'filter_select_overdue') {
                            this.filter.push({
                              condition:
                                'vehicles__odometr>vehicle_cars__oil_kpp_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            })
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter.push({
                              condition:
                                'vehicles__odometr<vehicle_cars__oil_kpp_next_mileage',
                              field: 'vehicle_cars__is_active_oil_control',
                              value: 1
                            })
                          }
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_overdue}
                    ></BaseSelect>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: 'События по датам',
          children: [
            {
              field: 'vehicle_osago__policy_to',
              key: '31',
              title: 'ОСАГО',
              align: 'center',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.osago}
                      onInput={(e, w) => {
                        let today = new Date()
                        today.toISOString().split('T')[0]
                        let fullTime = today.toISOString().split('T')[0]
                        console.log(fullTime)
                        let indexCurrentFilter = this.filter.findIndex(
                          (x) => x.field == 'vehicle_osago__policy_to'
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicle_osago__policy_to',
                              value: 's_nowDateTime',
                              operator: '<='
                            }
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicle_osago__policy_to',
                              value: 's_nowDateTime',
                              operator: '>'
                            }
                          } else if (
                            e.value == 'filter_select_unselected_overdue'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          this.filter.push({
                            field: 'vehicle_osago__policy_to',
                            value: 's_nowDateTime',
                            operator:
                              e.value == 'filter_select_overdue' ? '<=' : '>'
                          })
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_overdue}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_osago__policy_to
                      ? this.dayInstanse(row.vehicle_osago__policy_to)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(`${this.userSetting.company__tempalte_date}`)
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'vehicle_kasko__policy_to',
              key: '32',
              title: 'КАСКО',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.kasko}
                      onInput={(e, w) => {
                        let today = new Date()
                        today.toISOString().split('T')[0]
                        let fullTime = today.toISOString().split('T')[0]
                        console.log(fullTime)
                        let indexCurrentFilter = this.filter.findIndex(
                          (x) => x.field == 'vehicle_kasko__policy_to'
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicle_kasko__policy_to',
                              value: fullTime,
                              operator: '<'
                            }
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicle_kasko__policy_to',
                              value: fullTime,
                              operator: '>'
                            }
                          } else if (
                            e.value == 'filter_select_unselected_overdue'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          this.filter.push({
                            field: 'vehicle_kasko__policy_to',
                            value: fullTime,
                            operator:
                              e.value == 'filter_select_overdue' ? '<' : '>'
                          })
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_overdue}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_kasko__policy_to
                      ? this.dayInstanse(row.vehicle_kasko__policy_to)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(`${this.userSetting.company__tempalte_date}`)
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'vehicles__to_next_date',
              key: '33',
              title: 'ОФ.ТО',
              width: 60,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.to}
                      onInput={(e, w) => {
                        let indexCurrentFilter = this.filter.findIndex(
                          (x) => x.field == 'vehicles__to_next_date'
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__to_next_date',
                              value: 's_nowDateTime',
                              operator: '<='
                            }
                          } else if (e.value == 'filter_select_not_overdue') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__to_next_date',
                              value: 's_nowDateTime',
                              operator: '>'
                            }
                          } else if (
                            e.value == 'filter_select_unselected_overdue'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          this.filter.push({
                            field: 'vehicles__to_next_date',
                            value: 's_nowDateTime',
                            operator:
                              e.value == 'filter_select_overdue' ? '<=' : '>'
                          })
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_overdue}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicles__to_next_date
                      ? this.dayInstanse(row.vehicles__to_next_date)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(`${this.userSetting.company__tempalte_date}`)
                      : ''}
                  </span>
                )
              }
            }
          ]
        },

        {
          title: 'Контроль',
          children: [
            {
              field: 'vehicle_tires__season',
              key: '41',
              title: 'Ш',
              align: 'center',
              width: 40,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.tries}
                      onInput={(e, w) => {
                        let indexCurrentFilter = this.filter.findIndex(
                          (x) => x.field == 'vehicle_tires__season'
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_tires_summer') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicle_tires__season',
                              value: 'vehicle_tires_season_summer'
                            }
                          } else if (e.value == 'filter_select_tires_winter') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicle_tires__season',
                              value: 'vehicle_tires_season_winter'
                            }
                          } else if (
                            e.value == 'filter_select_tires_unselected'
                          ) {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          let selectNow
                          console.log(e.value)
                          if (e.value == 'filter_select_tires_summer') {
                            selectNow = {
                              field: 'vehicle_tires__season',
                              value: 'vehicle_tires_season_summer'
                            }
                          } else {
                            selectNow = {
                              field: 'vehicle_tires__season',
                              value: 'vehicle_tires_season_winter'
                            }
                          }
                          this.filter.push(selectNow)
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_tires}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_tires__season
                      ? row.vehicle_tires__season[0]
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'vehicles__notes_count',
              key: '51',
              title: 'З',
              align: 'center',
              width: 40,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.zam}
                      onInput={(e, w) => {
                        let indexCurrentFilter = this.filter.findIndex(
                          (x) => x.field == 'vehicles__notes_count'
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_yes') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__notes_count',
                              value: 0,
                              operator: '>'
                            }
                          } else if (e.value == 'filter_select_no') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__notes_count',
                              value: 0
                            }
                          } else if (e.value == 'filter_select_unselected') {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          this.filter.push({
                            field: 'vehicles__notes_count',
                            value: 0,
                            ...(e.value == 'filter_select_yes' && {
                              operator: '>'
                            })
                          })
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_yesno}
                    ></BaseSelect>
                  </div>
                )
              }
            },
            {
              field: 'vehicles__comment_count',
              key: '52',
              title: 'КK',
              align: 'center',
              width: 40,
              sortBy: '',
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.comment}
                      onInput={(e, w) => {
                        let indexCurrentFilter = this.filter.findIndex(
                          (x) => x.field == 'vehicles__comment_count'
                        )

                        if (indexCurrentFilter >= 0) {
                          if (e.value == 'filter_select_yes') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__comment_count',
                              value: 0,
                              operator: '>'
                            }
                          } else if (e.value == 'filter_select_no') {
                            this.filter[indexCurrentFilter] = {
                              field: 'vehicles__comment_count',
                              value: 0
                            }
                          } else if (e.value == 'filter_select_unselected') {
                            delete this.filter[indexCurrentFilter]
                          }
                        } else {
                          this.filter.push({
                            field: 'vehicles__comment_count',
                            value: 0,
                            ...(e.value == 'filter_select_yes' && {
                              operator: '>'
                            })
                          })
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      id={'vehicle_places__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      customLabel={({ label }) => {
                        return `${label}`
                      }}
                      options={this.selectsData.filter_yesno}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>{row.vehicles__comment_count == 0 ? 'Нет' : 'Да'}</span>
                )
              }
            }
          ]
        },

        {
          title: 'R',
          key: '81',
          field: 'vehicles__rayting',
          align: 'center',
          width: 40,
          sortBy: '',
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <span>{column.title}</span>
                <BaseSelect
                  vModel={this.selectsFilter.rayting}
                  onInput={(e, w) => {
                    let clearFilterRating = () => {
                      this.filter.forEach((elem, index) => {
                        if (elem.field == 'vehicles__rayting') {
                          this.filter.splice(index, 2)
                        }
                      })
                      console.log(this.filter)
                    }

                    if (e.value == 'filter_select_rayting_5') {
                      clearFilterRating()
                      this.filter.push(
                        {
                          field: 'vehicles__rayting',
                          value: 5
                        },
                        {}
                      )
                    } else if (e.value == 'filter_select_rayting_4') {
                      clearFilterRating()
                      this.filter.push(
                        {
                          field: 'vehicles__rayting',
                          value: 4,
                          operator: '>='
                        },
                        { field: 'vehicles__rayting', value: 5, operator: '<' }
                      )
                    } else if (e.value == 'filter_select_rayting_3') {
                      clearFilterRating()
                      this.filter.push(
                        {
                          field: 'vehicles__rayting',
                          value: 3,
                          operator: '>='
                        },
                        { field: 'vehicles__rayting', value: 4, operator: '<' }
                      )
                    } else if (e.value == 'filter_select_rayting_2') {
                      clearFilterRating()
                      this.filter.push(
                        {
                          field: 'vehicles__rayting',
                          value: 2,
                          operator: '>='
                        },
                        { field: 'vehicles__rayting', value: 3, operator: '<' }
                      )
                    } else if (e.value == 'filter_select_rayting_1') {
                      clearFilterRating()
                      this.filter.push(
                        {
                          field: 'vehicles__rayting',
                          value: 1,
                          operator: '>='
                        },
                        { field: 'vehicles__rayting', value: 2, operator: '<' }
                      )
                    } else if (e.value == 'filter_select_rayting_0') {
                      clearFilterRating()
                      this.filter.push(
                        {
                          field: 'vehicles__rayting',
                          value: 1,
                          operator: '<'
                        },
                        {}
                      )
                    } else if (e.value == 'filter_select_rayting_unselected') {
                      if (
                        this.filter.findIndex(
                          (x) => x.field == 'vehicles__rayting'
                        ) !== -1
                      ) {
                        clearFilterRating()
                      }
                    }
                  }}
                  style={'width: 100%'}
                  placeholder={''}
                  id={'vehicle_places__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  customLabel={({ label }) => {
                    return `${label}`
                  }}
                  options={this.selectsData.filter_rayting}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'vehicle_status__status',
          key: '4',
          title: 'Статус',
          align: 'center',
          width: 30,
          sortBy: '',
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <span>{column.title}</span>
                <BaseSelect
                  vModel={this.selectsFilter.status}
                  onInput={(e, w) => {
                    if (
                      !this.filter.find((x) => x.field == 'vehicles__status_id')
                    ) {
                      this.filter.push({
                        field: 'vehicles__status_id',
                        value: e.status__value
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'vehicles__status_id'
                        )
                      ].value = e.status__value
                    }
                  }}
                  style={'width: 100%'}
                  placeholder={''}
                  id={'label'}
                  selectLabel={''}
                  deselectLabel={''}
                  customLabel={({ status }) => {
                    return `${status} `
                  }}
                  options={[
                    {
                      status: 'Карантин',
                      status__value: 2,
                      field: 'vehicles__status_id'
                    },
                    {
                      status: 'Готов',
                      status__value: 1,
                      field: 'vehicles__status_id'
                    },
                    {
                      status: 'Архив',
                      status__value: 3,
                      field: 'vehicles__status_id'
                    },
                    {
                      status: 'Модерация',
                      status__value: 4,
                      field: 'vehicles__status_id'
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'vehicles__agregator_state',
          key: '42221',
          title: this.dataLang['register_objects_car_text_4'],
          align: 'center',
          width: 30,
          sortBy: '',
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <span>{column.title}</span>
                <BaseSelect
                  vModel={this.selectsFilter.agregatorState}
                  onInput={(e, w) => {
                    if (
                      !this.filter.find((x) => x.field == 'vehicles__agregator_state')
                    ) {
                      this.filter.push({
                        field: 'vehicles__agregator_state',
                        value: e.status__value
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'vehicles__agregator_state'
                        )
                      ].value = e.status__value
                    }
                  }}
                  style={'width: 100%'}
                  placeholder={''}
                  id={'label'}
                  selectLabel={''}
                  deselectLabel={''}
                  customLabel={({ status }) => {
                    return `${status} `
                  }}
                  options={[
                    {
                      status: 'Отображается',
                      status__value: true,
                      field: 'vehicles__agregator_state'
                    },
                    {
                      status: 'Не отображается',
                      status__value: false,
                      field: 'vehicles__agregator_state'
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>
