import http from "@/http-common.js";

class SummaryService {

  basePathApiService = "/statistics";
 
  get(params) {
    return http.get(this.basePathApiService, params);
  } 
}
export default new SummaryService();
