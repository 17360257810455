<template>
  <div class="auth-layout">
    <div class="auth-layout-loader" v-if="loading">
      <svg
        viewBox="0 0 50 50"
        v-if="
          ($route.path == '/auth' || $route.path == '/recovery-password') &&
          statusLoading !== 'success'
        "
        class="spinner"
      >
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
          class="path"
        ></circle>
      </svg>
      <div
        class="auth-layout-loader__desc"
        v-if="$route.path == '/recovery-password' && statusLoading == 'success'"
      >
        На вашу почту отправлены данные для входа.<br />
        Проверьте почту и вернитесь на форму авторизации
      </div>
      <div
        class="auth-layout-loader__desc"
        v-if="$route.path == '/reg' && statusLoading !== 'success'"
      >
        Пожалуйста подождите.<br />Аккаунт создается
      </div>
      <div
        class="auth-layout-loader__desc"
        v-if="$route.path == '/reg' && statusLoading == 'success'"
      >
        Аккаунт зарегистрирован. На вашу почту отправлены данные для входа.<br />
        Проверьте почту и вернитесь на форму авторизации
      </div>
      <RouterLink
        to="/auth"
        class="auth-button text-dark"
        v-if="
          ($route.path == '/reg' || $route.path == '/recovery-password') &&
          statusLoading == 'success'
        "
        >Прочитано</RouterLink
      >
    </div>
    <div class="auth-layout__left-side">
      <img alt="logo" :src="require(`@/assets/sirius.png`)" />
    </div>
    <div class="auth-layout__right-side">
      <div class="auth-layout__to-side">
        <div class="auth-list-link">
          <ul>
            <li>
              <router-link to="/auth">Авторизация</router-link>
            </li>
            <li>
              <router-link to="/reg">Регистрация</router-link>
            </li>
            <li>
              <router-link to="/recovery-password"
                >Восстановление пароля</router-link
              >
            </li>
          </ul>
        </div>
        <div v-if="$route.path == '/auth'">
          <div class="auth-list-input">
            <input
              type="text"
              v-model="authData.login"
              placeholder="E-mail (логин)"
              class="auth-input"
            />
            <input
              type="password"
              v-model="authData.password"
              placeholder="Пароль"
              class="auth-input"
            />
          </div>
          <div class="auth-desc-text" ref="descreg"></div>
          <div class="auth-controls-list-under-input d-flex flex-column align-items-center">
            <div class="auth-button" @click="forwardAuth">Войти</div>
            <div class="text-center text-white fsc-14 mt-20">
              Поддержка
              <a
                class="text-decoration-underline text-white"
                href="mailto:support@sirius.rent"
                >support@sirius.rent</a
              >
            </div>
          </div>
        </div>
        <div v-if="$route.path == '/recovery-password'">
          <div class="auth-list-input">
            <input
              type="text"
              v-model="recoveryData.login"
              placeholder="E-mail (логин)"
              class="auth-input"
              @input="
                () => {
                  $refs.descreg.textContent = ''
                }
              "
            />
          </div>
          <div
            class="auth-desc-text"
            style="width: 250px; margin: 0 auto 15px"
            ref="descreg"
          ></div>
          <div class="auth-controls-list-under-input d-flex flex-column align-items-center">
            <div class="auth-button" @click="forwardRecovery">
              Получить новый пароль
            </div>
            <div class="text-center text-white fsc-14 mt-20">
              Поддержка
              <a
                class="text-decoration-underline text-white"
                href="mailto:support@sirius.rent"
                >support@sirius.rent</a
              >
            </div>
          </div>
        </div>
        <div v-if="$route.path == '/reg'">
          <div class="auth-list-input">
            <BaseSelect
              class="spec-new-select"
              placeholder="Страна"
              selectLabel=""
              deselectLabel=""
              @input="
                (data) => {
                  getSelectData(data, 'region')
                  clearValidation('country_id')
                }
              "
              v-model="registData.country"
              :options="optionSelect.countries"
              label="countries__name"
            >
            <template #noOptions><span></span></template>
            </BaseSelect>
            <div ref="country_id" class="auth-reg-field">
              Указание страны обязательно
            </div>
            <BaseSelect
              class="spec-new-select"
              placeholder="Регион"
              selectLabel=""
              deselectLabel=""
              @input="
                (data) => {
                  getSelectData(data, 'city')
                  clearValidation('region_id')
                }
              "
              v-model="registData.region"
              :options="optionSelect.regions"
              label="regions__name"
            >
            <template #noOptions>Выберите страну</template>
            </BaseSelect>
            <div ref="region_id" class="auth-reg-field">
              Указание региона обязательно
            </div>
            <BaseSelect
              class="spec-new-select"
              placeholder="Город"
              selectLabel=""
              deselectLabel=""
              @input="
                (data) => {
                  clearValidation('city_id')
                }
              "
              v-model="registData.city"
              :options="optionSelect.cities"
              label="cities__name"
            >
            <template #noOptions>Выберите регион</template>
            </BaseSelect>
            <div ref="city_id" class="auth-reg-field">
              Указание города обязательно
            </div>
            <input
              type="text"
              v-model="registData.login"
              placeholder="E-mail (логин)"
              class="auth-input"
              @input="() => clearValidation('login')"
            />
            <div ref="login" class="auth-reg-field">
              Указание электронной почты обязательно
            </div>
            <!-- <input
              type="text"
              v-model="registData.phone"
              placeholder="Телефон*"
              ref="phone"
              maxlength="16"
              class="auth-input"
              @input="() => clearValidation('phone')"
            /> -->
            <BaseInputNew
              placeholder="Телефон (для sms)"
              class=""
              input-name="clients__fiz_doc_gims_year"
              @input="(data) => registData.phone = data.value"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass="auth-input"
              value=""
              :additionalParams="{}"
              inputRef="clients__phone"
              maxLength="16"
              inputMask="+{7}(000)000-00-00"
              name-sign=""
            >
            </BaseInputNew>
            <div ref="phone" class="auth-reg-field">
              Указание номера телефона обязательно
            </div>
          </div>
          <div class="auth-desc-text" ref="descreg"></div>
          <div class="auth-desc-reg-about">
            *Потом можно поменять все, кроме страны.
          </div>
          <div
            class="auth-controls-list-under-input d-flex flex-column align-items-center"
          >
            <div class="auth-button" @click="forwardReg">Регистрация</div>
            <div class="text-center text-white fsc-14 mt-20">
              Поддержка
              <a
                class="text-decoration-underline text-white"
                href="mailto:support@sirius.rent"
                >support@sirius.rent</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccessService from '@/api/AccessService'
import GeneralService from '@/api/GeneralService'
import IMask from 'imask'
import { RouterLink } from 'vue-router'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      statusLoading: '',
      authData: {
        login: null,
        password: null
      },
      recoveryData: {
        login: null
      },
      registData: {
        login: '',
        phone: '',
        country: [],
        region: [],
        city: []
      },
      optionSelect: {
        countries: [],
        regions: [],
        cities: []
      }
    }
  },
  methods: {
    ...mapActions('accessModule', ['reg', 'auth']),
    ...mapMutations('accessModule', ['auth_status']),
    clearValidation(name) {
      this.$refs[name].classList.remove('d-block')
      this.$refs.descreg.textContent = ''
    },

    forwardAuth() {
      if (this.authData.login && this.authData.password) {
        this.loading = true
        this.$refs.descreg.textContent = ''
        this.auth({
          authData: {
            login: this.authData.login,
            password: this.authData.password
          },
          callback: (stat, message, errors) => {
            if (stat == 'error' && message) {
              this.$refs.descreg.textContent = message
              this.loading = false
            }
            if (stat == 'error' && errors) {
              Object.keys(errors).forEach((elem, index) => {
                this.$refs[elem].classList.add('d-block')
              })
              this.loading = false
            }
            if (stat == 'success') {
              this.loading = false
            }
            console.log(stat, message, errors)
          }
        })
      } else {
        this.auth_status('')
        this.$refs.descreg.textContent = 'Все поля являются обязательными'
      }
    },
    forwardReg() {
      // } +7(902)111-12-94 12211232@wdwdw.ru
      this.loading = true
      this.statusLoading = ''
      this.$refs.descreg.textContent = ''
      this.reg({
        authData: {
          login: this.registData.login,
          phone: this.registData.phone,
          country_id: this.registData.country.countries__id,
          region_id: this.registData.region.regions__id,
          city_id: this.registData.city.cities__id
        },
        callback: (stat, message, errors) => {
          if (stat == 'error' && message) {
            this.$refs.descreg.textContent = message
            this.loading = false
          }
          if (stat == 'error' && errors) {
            Object.keys(errors).forEach((elem, index) => {
              this.$refs[elem].classList.add('d-block')
            })
            this.loading = false
          }
          if (stat == 'success') {
            this.statusLoading = 'success'
          }
          console.log(stat, message, errors)
        }
      })
    },
    forwardRecovery() {
      this.loading = true
      this.$refs.descreg.textContent = ''
      AccessService.passwordRecovery({ login: this.recoveryData.login })
        .then((data) => {
          console.log(data)
          this.statusLoading = 'success'
        })
        .catch((err) => {
          console.log(err)
          this.$refs.descreg.textContent = err.response.data.message
          this.loading = false
        })
    },
    getSelectData(data, kind) {
      switch (kind) {
        case 'region':
          this.registData.region = []
          this.registData.city = []
          GeneralService.get('/regions', {
            params: {
              where: JSON.stringify([
                { field: 'regions__country_id', value: data.countries__id }
              ])
            }
          }).then((data) => {
            console.log(data.data.data.rows)
            this.optionSelect.regions = data.data.data.rows
          })
          break
        case 'city':
          this.registData.city = []
          GeneralService.get('/cities', {
            params: {
              where: JSON.stringify([
                { field: 'cities__region_id', value: data.regions__id }
              ])
            }
          }).then((data) => {
            console.log(data.data.data.rows)
            this.optionSelect.cities = data.data.data.rows
          })
          break
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.descreg.textContent = ''
    this.statusLoading = ''
    this.loading = false
    this.registData
    for (const key in this.registData) {
      this.registData[key] = null
    }
    next()
  },
  computed: {
    ...mapState('accessModule', [
      'statusAuth',
      'statusReg',
      'loggedStatus',
      'authMessage',
      'regMessage'
    ])
  },
  created() {
    GeneralService.get('/countries').then((data) => {
      console.log(data.data.data.rows)
      this.optionSelect.countries = data.data.data.rows
    })
    // GeneralService.get('/cities').then((data) => {
    //   console.log(data.data.data.rows)
    //   this.optionSelect.cities = data.data.data.rows
    // })
    // GeneralService.get('/regions', {
    //   params: {}
    // }).then((data) => {
    //   console.log(data.data.data.rows)
    //   this.optionSelect.regions = data.data.data.rows
    // })
  }
}
</script>

<style lang="scss">
.auth-layout-loader {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  transition: background-color 0.3s ease;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  &__desc {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: white;
  }

  .spinner {
    position: static;
  }
}

.auth-desc-reg-about {
  font-size: 14px;
  line-height: 17.5px;
  text-align: center;
  margin-bottom: 30px;
  color: white;
}

.auth-reg-field {
  color: #fb5959;
  font-size: 14px;
  text-align: center;
  display: none;
}

.auth-desc-text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  color: #fb5959;
}

.auth-list-input {
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-bottom: 30px;
}

.auth-input {
  width: 100% !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  outline: 0 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  padding: 9px !important;
  padding-left: 6px !important;
  color: white !important;
}

.auth-controls-list-under-input {
  display: flex;
  justify-content: center;
}

.auth-button {
  padding: 8px 50px;
  background-color: #f6ee25;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

.auth-input::placeholder {
  color: white !important
}

.auth-layout {
  display: flex;
  height: 100vh;

  &__left-side {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 300px;
    }
  }

  &__right-side {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0c63aa;
    flex-direction: column;
  }
}

.auth-list-link {
  margin-bottom: 15px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 20px;
  }

  li a {
    display: block;
    padding-bottom: 7px;
    font-size: 14px;
    color: white;
    line-height: 21px;
  }

  li a.router-link-active {
    border-bottom: 3px solid #f6ee25;
  }
}
</style>
