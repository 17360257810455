import http from "@/http-common.js";

class JobtitlesService {

  basePathApiService = "/jobtitles";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(id, editData) {
    return http.patch(this.basePathApiService + `/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }

}
export default new JobtitlesService();
