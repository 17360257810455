import PlacesService from '@/api/PlacesService.js'
import Vue from 'vue'
export const placesModule = {
  namespaced: true,
  state: () => ({
    placesData: [],
    placesLoadStatus: 'request',
    paginationStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: (state) =>
      Math.ceil(
        state.paginationStateParams.total / state.paginationStateParams.limit
      )
  },
  mutations: {
    get_all(state, data) {
      state.placesData = [...data]
    },
    create(state, data) {
      state.placesData.unshift(...data)
    },
    edit_multiple(state, data) {
      let indexed = state.placesData.findIndex(
        (x) => x.vehicle_places__id == data.id
      )
      Object.assign(state.placesData[indexed], data.datas)
    },

    delete_data(state, id) {
      let indexed = state.placesData.findIndex(
        (x) => x.vehicle_places__id == id
      )
      state.placesData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.placesLoadStatus = 'request'
    },
    load_success(state) {
      state.placesLoadStatus = 'success'
    },
    load_error(state) {
      state.placesLoadStatus = 'error'
    }
  },
  actions: {
    getPlacesAll({ commit }, id) {
      commit('load_request')
      PlacesService.getAll(id)
        .then((data) => {
          commit('update_pagination', data.data.data)
          commit('get_all', data.data.data.rows)
          commit('load_success')
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки')
          commit('load_error')
        })
    },

    getPlacesAllWithoutID({ commit }) {
      commit('load_request')
      PlacesService.getAllWithoutID()
        .then((data) => {
          commit('update_pagination', data.data.data)
          commit('get_all', data.data.data.rows)
          commit('load_success')
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки')
          commit('load_error')
        })
    },

    createPlacesData({ commit, dispatch }, createData) {
      PlacesService.create(createData)
        .then((data) => {
          console.log(data.data.data.rows)
          commit('create', data.data.data.rows)
          Vue.$toast.success('Запись добавлена')
          dispatch('getPlacesAllWithoutID')
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки')
        })
    },

    editPlacesMultiple({ commit }, { id, data }) {
      let datas = data
      PlacesService.update(id, data)
        .then((datasd) => {
          commit('edit_multiple', { id, datas })
          Vue.$toast.success('Данные успешно обновлены')
          dispatch('getPlacesAllWithoutID')
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки')
        })
    },

    deletePlacesData({ commit }, id) {
      PlacesService.delete(id)
        .then((data) => {
          console.log(data)
          commit('delete_data', id)
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error(error.response.data.message)
        })
    }
  }
}
