
import SecureSettingsService from "@/api/SecureSettingsService.js";
import Vue from "vue";
export const secureQuestionsModule = {
  namespaced: true,
  state: () => ({
    secureQuestionsData: [],
    secureQuestionsLoadStatus: 'request',
    paginationsecureQuestionsStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationsecureQuestionsStateParams.total / state.paginationsecureQuestionsStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      console.log(data)
      state.secureQuestionsData = [...data];
    },

    create_question(state, data){
      data.forEach(item => { 
        state.secureQuestionsData.unshift(item)
      })
    },

    delete_question_data(state, id){
      let indexed = state.secureQuestionsData.findIndex(x => x.secur_questions__id == id)
      state.secureQuestionsData.splice(indexed, 1)
    },

    load_request(state, status) {
      state.secureQuestionsLoadStatus = 'request'
    },

    load_success(state) {
      state.secureQuestionsLoadStatus = 'success'
    },
    load_error(state) {
      state.secureQuestionsLoadStatus = 'error'
    },
  },
  actions: {
    getSecureQuestionsAll({ commit }, params) {
      commit("load_request");
      SecureSettingsService.getQuestions(params)
        .then((data) => {
         // commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createSecureQuestion({commit}, dataCreate){
      SecureSettingsService.createQuestion(dataCreate.id, dataCreate.data)
        .then((data) => {
          commit("create_question", data.data.data.rows);
          Vue.$toast.success(data.data.message)
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },


    deleteSecurQuestionsData({ commit }, dataOnDelete) {
      // commit("load_request");
      SecureSettingsService.deleteQuestion(dataOnDelete.idGroup, dataOnDelete.idQuestion)
        .then((data) => {
          console.log(data)
          commit("delete_question_data", dataOnDelete.idQuestion);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },
  }
};
