<template>
  <BaseModal
    name="addNewTriesModal"
    width="420"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="beforeClose"
    @before-open="beforeOpen"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div class="py-20 px-30 rounded-25 bg-white">
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          @click="$modal.hide('addNewTriesModal')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p class="fw-bold mb-15 text-center">
          {{ dataLang['vehicle_modal_tires_modals_title'] }}
        </p>
        <div class="mb-10" ref="size">
          <BaseInputNew
            :placeholder="placeholderForInput"
            :width="'100'"
            class="wp-100"
            signClass="fsc-14"
            input-name="size"
            @input="updateValue"
            :horizontalTitle="true"
            :value="dataForEmit.size"
            inputRef="size"
            inputMask="000/00/00"
            :name-sign="dataLang['vehicle_modal_tires_text4']"
          >
          </BaseInputNew>
        </div>
        <div class="d-flex gap-15 mb-15 align-items-end">
          <div class="d-flex flex-column">
            <div ref="summer" class="mb-10">
              <BaseCheckboxV2
                placeholder=""
                class=""
                input-name="summer"
                v-model="dataForEmit.summer"
                signClass="fsc-14"
                @oncheckeds="updateValue"
                >{{
                  dataLang['vehicle_modal_tires_modals_text1']
                }}</BaseCheckboxV2
              >
            </div>
            <div ref="winter" class="">
              <BaseCheckboxV2
                placeholder=""
                class=""
                input-name="winter"
                v-model="dataForEmit.winter"
                signClass="fsc-14 "
                @oncheckeds="updateValue"
                >{{
                  dataLang['vehicle_modal_tires_modals_text2']
                }}</BaseCheckboxV2
              >
            </div>
          </div>
          <div ref="thorns" class="">
            <BaseCheckboxV2
              placeholder=""
              class=""
              input-name="thorns"
              v-model="dataForEmit.thorns"
              signClass="fsc-14"
              @oncheckeds="updateValue"
              >{{ dataLang['vehicle_modal_tires_text9'] }}</BaseCheckboxV2
            >
          </div>
        </div>

        <div class="wp-100 mb-15" ref="comment">
          <BaseTextarea
            placeholder=""
            class="wp-100 fsc-14 mb-5"
            signClass="fsc-14 text-muted"
            input-name="comment"
            @onInput="updateValue"
            :minHeight="'160'"
            inputClass="fsc-14"
            :name-sign="dataLang['vehicle_modal_comments_modals_text3']"
            :value="dataForEmit.comment"
          >
          </BaseTextarea>
        </div>
        <div
          class="text-center fw-semi-bold fsc-14 py-5 bg-system-secondary wp-100 mb-15"
        >
          {{ dataLang['vehicle_modal_tires_modals_text3'] }}
        </div>
        <div class="d-flex justify-content-end gap-15">
          <div
            class="base-button px-20 rounded-10"
            @click="$modal.hide('addNewTriesModal')"
          >
            {{ dataLang['vehile_modal_btn_reset'] }}
          </div>
          <div
            @click="sendData"
            class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            {{ dataLang['vehile_modal_btn_save'] }}
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import BidsService from '@/api/BidsService'
import EventBus from '@/event-bus.js'
import OwnersService from '@/api/OwnersService'

// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  components: {},
  props: {
    stateLoading: {
      type: Boolean,
      default: false
    },
    optionalParams: {
      type: Object
    }
  },
  data() {
    return {
      selectLabel: '',
      listOptions: [],
      owner: [],
      placeholderForInput: '000/00/00',
      dataForEmit: {
        summer: false,
        winter: false,
        thorns: false,
        comment: '',
        size: '',
        request_services__type_service_id: 7,
        vehicleId: this.optionalParams.id
      }
    }
  },
  methods: {
    changeDate(e) {
      this.$refs.date.classList.remove('input-error')
    },
    setSelected(data) {
      console.log(data.vehicle_owners__id)
      this.dataForEmit.ownerId = data.vehicle_owners__id
      this.$refs.ownerId.classList.remove('input-error')
    },
    beforeOpen(e) {
      this.placeholderForInput = e.params.vehicle_tires__size
      console.log(this.placeholderForInput)
    },
    beforeClose(event) {
      // this.dataForEmit.vehicle_comments__text = null
      // this.dataForEmit.vehicle_comments__is_critical = null
      // this.selectLabel = ''
      this.dataForEmit = {
        summer: false,
        winter: false,
        thorns: false,
        comment: '',
        size: '',
        request_services__type_service_id: 7,
        vehicleId: this.optionalParams.id
      }
      this.owner = []
      Object.keys(this.dataForEmit).forEach((elem, index) => {
        if (this.$refs[elem]) {
          this.$refs[elem].classList.remove('input-error')
        }
      })
    },
    updateValue(data) {
      this.dataForEmit[data.name] = data.value
      this.$refs[data.name].classList.remove('input-error')
    },

    sendData() {
      BidsService.create(this.dataForEmit)
        .then((data) => {
          console.log(data)
          this.$toast.success('Запись добавлена')
          Object.keys(this.dataForEmit).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.remove('input-error')
            }
          })
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.dataForEmit = {
            summer: false,
            winter: false,
            thorns: false,
            comment: '',
            size: '',
            request_services__type_service_id: 7,
            vehicleId: this.optionalParams.id
          }
          this.owner = []
          this.$modal.hide('addNewTriesModal')
        })
        .catch((err) => {
          console.log(err)
          Object.keys(err.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
          this.$toast.error(err.response.data.message)
        })
    }
  },
  mounted() {},
  created() {
    OwnersService.getAll()
      .then((data) => {
        this.listOptions = data.data.data.rows
      })
      .catch((err) => {
        this.$toast.error(err.response.data.message)
      })
  },
  beforeDestroy() {},

  computed: {
    ...mapState('translationModule', ['dataLang'])
  }
}
</script>
