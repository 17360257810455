<template>
  <div>
    <BaseTopLine> </BaseTopLine>
    <div class="p-20">
      <BasePagination
        :page-count="numPages"
        :page-range="3"
        :margin-pages="2"
        :break-view-class="'d-none'"
        :click-handler="clickLoadData"
        :prev-text="'Назад'"
        :next-text="'Далее'"
        :container-class="'pagination pagination-sm'"
        :page-class="'page-item'"
        :pageLinkClass="'page-link'"
      >
      </BasePagination>

      <BaseReuseTable
        :props-data="sb2Data"
        :props-columns-setting="fieldsTableSb2"
        class="secur-clients-ankets-table"
        :selectRowActivateFlag="true"
        :modalActivateFlag="true"
        :callbackForModal="callbackForModal"
        nameForAction="sb2Modal"
        v-if="sb2LoadStatus == 'success'"
      >
      </BaseReuseTable>
    </div>
    <Teleport to="body">
      <Modal nameForAction="sb2Modal" @closeModal="closeModal" size="xl">
        <div class="p-10">
          <div class="fw-bold mb-10">Проверка</div>
          <!-- secur_clients_ankets__mod_data -->
          <!-- {
    {
    "orders__cost": "",
    "orders__ends": null,
    "vehicles__id": 3,
    "orders__starts": null,
    "vehicles__name": "Ferrari FF",
    "orders__duration": "0 сут.",
    "orders__type_rent": null,
    "orders__end_city_id": null,
    "orders__vehicle_type": null,
    "orders__end_city_name": null,
    "orders__start_city_id": null,
    "orders__start_city_name": null
} -->
          <div
            class="d-flex gap-20 flex-wrap mb-15"
            v-if="dataOrder?.vehicles__name"
          >
            <div>
              <p class="fsc-12 mb-5">Имя клиента</p>
              {{ dataOrder?.clients__name }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Телефон клиента</p>
              {{ dataOrder?.clients__phone }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Тип лица</p>
              {{ dataLang[dataOrder?.secur_groups__name] }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Стоимость</p>
              {{ dataOrder?.orders__cost }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Начало</p>
              {{
                dataOrder?.orders__starts
                  ? $time(dataOrder?.orders__starts).format(
                      `${this.userSetting.company__tempalte_date} HH:mm`
                    )
                  : ''
              }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Конец</p>
              {{
                dataOrder?.orders__ends
                  ? $time(dataOrder?.orders__ends).format(
                      `${this.userSetting.company__tempalte_date} HH:mm`
                    )
                  : ''
              }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Объект</p>
              {{ dataOrder?.vehicles__name }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Длиельность</p>
              {{ dataOrder?.orders__duration }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Тип аренды</p>
              {{ dataLang[dataOrder?.orders__type_rent] }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Вид ТС</p>
              {{ dataLang[dataOrder?.orders__vehicle_type] }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Город начала</p>
              {{ dataOrder?.orders__start_city_name }}
            </div>
            <div>
              <p class="fsc-12 mb-5">Город завершения</p>
              {{ dataOrder?.orders__end_city_name }}
            </div>
          </div>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(item, idx) in clientAnswers" :key="idx">
                <td>{{ item.secur_clients_fields__label }}</td>
                <td>
                  <span
                    v-if="
                      item.secur_clients_answers__answer_text.slice(0, 4) !==
                      'http'
                    "
                    >{{ compData(item.secur_clients_fields__client_field, item.secur_clients_answers__answer_text)  }}
                  </span>

                  <img
                    v-else
                    @click="index = idx - 1"
                    width="200"
                    :src="item.secur_clients_answers__answer_text"
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <gallery
            v-if="loadGallery"
            :images="images"
            :index="index"
            @close="index = null"
          ></gallery>
          <!-- <div
      class="image"
      v-for="(image, imageIndex) in images"
      :key="imageIndex"
      @click="index = imageIndex"
      :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
    ></div> -->
          <div class="d-flex justify-content-end gap-10 mt-15">
            <div
              class="btn btn-sm btn-primary"
              v-if="statusId == 2"
              @click="sendDataFromModal(true)"
            >
              Допуск
            </div>
            <div
              class="btn btn-sm btn-opposite"
              v-if="statusId == 2"
              @click="sendDataFromModal(false)"
            >
              Отказ
            </div>
            <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">
              {{ dataLang.form_btn__btn_caneсl }}
            </div>
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Modal from '@/components/Modals.vue'
import SbService from '@/api/SbService.js'
import EventBus from '@/event-bus.js'
import VueGallery from 'vue-gallery'
import GeneralService from '@/api/GeneralService'
export default {
  components: {
    Modal,
    gallery: VueGallery
  },

  props: {},
  data() {
    return {
      images: [],
      index: null,

      activeSelectRowObject: {},
      clientAnswers: [],
      statusId: '',
      loadGallery: false,
      dataOrder: null
    }
  },
  methods: {
    compData(fieldData, value) {
      if (
        fieldData == 'clients__fiz_date_birth' ||
        fieldData == 'clients__fiz_doc_vu_date'
      ) {
        return this.$time(value).format(`${this.userSetting.company__tempalte_date}`)
      } else {
        return value
      }
    },
    clickLoadData(numPage) {
      this.getSb2All({
        params: {
          limit: 1000,
          page: numPage
        }
      })
    },

    ...mapActions('sb2Module', ['getSb2All']),
    ...mapMutations('sb2Module', ['edit_sb2_multiple']),

    callbackForModal(e, row) {
      this.activeSelectRowObject = row

      // .catch((e) => {
      //   this.modalInfo.loading = false
      //   this.$toast.error(e.response.data.message)
      // })

      this.statusId = row.getData().secur_clients_ankets__status_id
      console.log(e, row.getData())
      SbService.getOne(row.getData().secur_clients_ankets__id).then((data) => {
        this.clientAnswers =
          data.data.data.rows[0]['secur_clients_ankets__answers']
        this.dataOrder =
          data.data.data.rows[0]?.secur_clients_ankets__mod_data?.order

        this.dataOrder = { ...this.dataOrder, ...data.data.data.rows[0] }

        // this.images = this.clientAnswers.filter((elem, index) => {
        //     if (elem.secur_clients_answers__answer_text.slice(0, 4) == 'http') {
        //         return elem.secur_clients_answers__answer_text
        //     }
        // })
        // GeneralService.get(
        //   `http://api.yandexrent.development/anketa-security/${
        //     row.getData().secur_clients_ankets__id
        //   }`
        // ).then((data) => {
        //   console.log(data)
        //   this.dataOrder =
        //     data.data.data.rows[0]?.secur_clients_ankets__mod_data?.order
        // }) clients__name clients__name clients__phone
        this.clientAnswers.forEach((elem, index) => {
          if (elem.secur_clients_answers__answer_text.slice(0, 4) == 'http') {
            this.images.push(elem.secur_clients_answers__answer_text)
          }
        })
        this.loadGallery = true
        console.log(this.images)
      })
    },

    closeModal() {
      // this.activeSelectRowObject = {};
      // this.clientAnswers = [];
      this.statusId = ''
      this.loadGallery = false
      this.dataOrder = null
    },

    sendDataFromModal(flag) {
      EventBus.$emit('closeModal', { nameForAction: 'sb2Modal' })
      let rowData = Object.assign({}, this.activeSelectRowObject.getData())
      let anketaId =
        this.activeSelectRowObject.getData().secur_clients_ankets__id
      SbService.allow(anketaId, flag).then((data) => {
        console.log(1231231)
        let editedData = data.data.data.rows[0]

        this.edit_sb2_multiple({ id: anketaId, dataEdit: editedData })
        this.activeSelectRowObject.update(editedData)
        this.activeSelectRowObject.reformat()
        this.activeSelectRowObject
          .getElement()
          .classList.remove(rowData.secur_clients_ankets_status__style_class)
        this.$toast.success(data.data.message)
      })
    },

    btnHandlerCloseModal(nameModal) {
      EventBus.$emit('closeModal', { nameForAction: 'sb2Modal' })
    }
  },

  mounted() {},

  created() {
    this.getSb2All({ params: { page: 1, limit: 1000 } })
  },
  computed: {
    ...mapState('sb2Module', [
      'sb2Data',
      'sb2LoadStatus',
      'paginationSb2StateParams'
    ]),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('sb2Module', ['numPages']),
    ...mapState('userSettingModule', ['userSetting']),

    fieldsTableSb2() {
      return [
        {
          title: 'ID',
          field: 'secur_clients_ankets__id',
          headerFilter: 'input'
        },
        {
          title: 'Дата',
          field: 'secur_clients_ankets__created_at',
          headerFilter: 'input'
        },
        {
          title: 'Клиент',
          field: 'secur_clients_ankets__client_id',
          headerFilter: 'input'
        },
        { title: 'ФИО Клиента', field: 'clients__name', headerFilter: 'input' },
        {
          title: 'Тип проверки',
          field: 'secur_groups__name',
          headerFilter: 'input'
        },
        {
          title: 'Статус',
          field: 'secur_clients_ankets_status__status',
          headerFilter: 'input',
          hozAlign: 'center',
          mutator: (value, data) => {
            return {
              status: value,
              styleClass: data.secur_clients_ankets_status__style_class
            }
          },
          formatter: (cell) => {
            let value = cell.getValue()

            if (value.styleClass)
              cell.getRow().getElement().classList.add(value.styleClass)
            return value.status
          }
        }
      ]
    }
  }
}
</script>
