<template>
    <div>
        <BaseTopLine>

        </BaseTopLine>
        <div class="p-20">
            <BasePagination  :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="sb1Data" :props-columns-setting="fieldsTableSb1" class="secur-clients-ankets-table"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="Sb1Modal" v-if="sb1LoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
    </div>
</template>


<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
export default {
    components: {
        Modal
    },

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getSb1All({params:{
                    limit: 1000,
                    page: numPage
                }
            })
        },


        ...mapActions("sb1Module", ["getSb1All"]),


    },

    mounted() {},

    created() {
        this.getSb1All({ params: { page: 1, limit: 1000 }})
    },
    computed: {
        ...mapState("sb1Module", ["sb1Data", "sb1LoadStatus", 'paginationSb1StateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("sb1Module", ["numPages"]),


        fieldsTableSb1() {
            return [
                { title: 'ID', field: "secur_clients_ankets__id", headerFilter: "input" },
                { title: 'Дата', field: "secur_clients_ankets__created_at", headerFilter: "input" },
                { title: 'Клиент', field: "secur_clients_ankets__client_id", headerFilter: "input" },
                { title: 'ФИО Клиента', field: "clients__name", headerFilter: "input" },
                { title: 'Тип проверки', field: "secur_groups__name", headerFilter: "input" },
                {
                    title: "Статус", field: "secur_clients_ankets_status__status", headerFilter: "input", hozAlign: "center", mutator: (value, data) => {
                        return { "status": value, "styleClass": data.secur_clients_ankets_status__style_class };
                    }, formatter: (cell) => {
                        let value = cell.getValue();

                        if(value.styleClass)
                            cell.getRow().getElement().classList.add(value.styleClass);
                        return value.status;
                    }
                },
            ]
        }
    },
};
</script>

