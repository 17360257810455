import http from "@/http-common.js";

class AdSourceService {

  basePathApiService = "/ads";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
  create(data) {
    return http.post(this.basePathApiService, data);
  }
  edit(id, data) {
    return http.put(this.basePathApiService + `/${id}`, data);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
}

export default new AdSourceService();
