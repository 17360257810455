<template>
  <header class="header">
    <div class="header__top header-top">
      <div class="d-flex align-items-center gap-30">
        <router-link v-once to="/" class="header__logo header-logo">
          <picture>
            <source
              media="(min-width: 992px)"
              :srcset="require(`@/assets/sirius.png`)"
              type="image/svg+xml"
            />
            <img :src="require(`@/assets/logo-mobile.svg`)" alt="SIRIUS LOGO" />
          </picture>
          <!-- <p class="fs-7 mb-0 text-body-color">

        </p> -->
        </router-link>
        <span class="fsc-14">
          {{ aboutUser.crm_build_info }}
        </span>
      </div>
      <div class="d-flex gap-15">
        <!-- <router-link :to="{ path: '/checklist' }" class="header-link-state">
          <span>Чек-лист</span>

          <div
            class="header-link-state__round-num header-link-state__round-num--danger"
          >
            {{ aboutUser.employee_checklist_count }}
          </div>
        </router-link>
        <router-link :to="{ path: '/tasks' }" class="header-link-state">
          <span>Задачи</span>

          <div
            class="header-link-state__round-num header-link-state__round-num--danger"
          >
            99
          </div>
        </router-link>
        <router-link :to="{ path: '/development' }" class="header-link-state">
          <span>Приказы</span>

          <div
            class="header-link-state__round-num header-link-state__round-num--accept"
          >
            99
          </div>
        </router-link> -->
        <router-link
          :to="{ path: '/company_notifications' }"
          class="header-link-state"
        >
          <span>Система</span>

          <div
            class="header-link-state__round-num header-link-state__round-num--neutral"
          >
            {{ countNotices }}
          </div>
        </router-link>
      </div>
      <!-- <div class="d-flex gap-15">
        <router-link
          :to="{ path: '/development' }"
          class="btn btn-sm btn-danger text-white fw-medium fsc-16"
        >
          <span>Поддержка (999)</span>
        </router-link>
      </div> -->
      <div class="d-flex align-items-center gap-50">
        <a
          href="https://t.me/siriuscrm"
          target="_blank"
          class="btn btn-sm btn-danger text-white"
          >Поддержка</a
        >
        <div class="d-flex gap-30">
          <div class="d-flex flex-column gap-5">
            <div class="d-flex fsc-16">
              <span>ЛС: &nbsp;</span
              ><span class="fw-bolder"> {{ aboutUser.company_lc }}</span>
            </div>
            <div class="d-flex fsc-16 align-items-center">
              <span>Баланс: &nbsp;</span
              ><span class="fw-bolder"> {{ aboutUser.company_balance }} р</span>
              <a
                href="https://dep.rent/"
                target="_blank"
                class="bg-primary d-flex ms-5"
                style="border-radius: 3px; width: 18px; height: 18px"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  style="margin: auto"
                >
                  <path
                    d="M0.173248 4.69659V3.3034H3.3034V0.191345H4.71468V3.3034H7.82675V4.69659H4.71468V7.80865H3.3034V4.69659H0.173248Z"
                    fill="black"
                  /></svg
              ></a>
            </div>
          </div>
          <div class="d-flex flex-column gap-5">
            <div class="d-flex">
              <div class="d-flex align-items-center gap-5">
                <span class="fsc-16 fw-bolder"
                  >{{ aboutUser.employee_name }}
                </span>
                <div
                  class="header-state-admin"
                  v-if="aboutUser.employee_is_admin"
                >
                  A
                </div>
              </div>
            </div>
            <div class="d-flex fsc-16">
              <span class="text-muted link-decorator">Моя учетная запись</span>
            </div>
          </div>
        </div>
        <div class="h-100">
          <div
            class="base-button text-capitalize fsc-16 fw-semi-bold py-10 px-25"
            @click="signOut"
          >
            Выйти
          </div>
        </div>
      </div>
      <!-- <div class="p-5 fs-8 bg-opposite d-flex gap-10 flex-wrap">
            <div>
                <span class="fw-bold">Компания</span>
                <p class="mb-0">{{ aboutUser.company__name }}</p>
            </div>
            <div>
                <span class="fw-bold">Сотрудник</span>
                <p class="mb-0">{{ aboutUser.employee_name }} </p>
            </div>
            <div>
                <span class="fw-bold">Баланс</span>
                <p class="mb-0">{{ aboutUser.company_balance }}</p>
            </div>
            <div>
                <span class="fw-bold">Лицевой счет</span>
                <p class="mb-0">{{ aboutUser.company_lc }}</p>
            </div>
            <div class="d-flex flex-column">
                <span class="fw-bold">Чеклист</span>
                <router-link :to="{path: '/checklist'}" class="mb-0">{{ aboutUser.employee_checklist_count }}</router-link>
            </div>
        </div> -->

      <!-- <div class="header__services header-services">


      </div> -->
    </div>
    <TheMenu :openMenu="isActive" @closeMenu="closeMainMenu"></TheMenu>
  </header>
</template>

<script>
import TheMenu from '@/components/TheMenu.vue'
import AboutUser from '@/api/AboutUser.js'
import SSEEventSource from '@/sse-instance'
import { mapActions, mapState, mapMutations } from 'vuex'
import GeneralService from '@/api/GeneralService'
export default {
  components: { TheMenu },
  data() {
    return {
      isActive: false,
      aboutUser: {},
      count_notices: 0
    }
  },
  methods: {
    closeMainMenu() {
      this.isActive = false
    },
    signOut() {
      localStorage.removeItem('user-token')
      localStorage.removeItem('translations')
      this.$router.push('/auth')
    },
    toggleMenu() {
      this.isActive = !this.isActive
    },
    updateUserInfo() {
      AboutUser.getAllAboutUser()
        .then((data) => {
          this.aboutUser = data.data.data
          console.log(this.aboutUser)
          this.getUserSetting(data.data.data)
        })
        .then((err) => {
          console.log(err, 3333)
        })
    },
    ...mapActions('userSettingModule', [
      'getUserSetting',
      'setUserNotification'
    ])
  },
  created() {
    // this.getAll()
    this.updateUserInfo()
    SSEEventSource.addEventListener('newNotification', (event) => {
      this.setUserNotification()
      let infoMessage = JSON.parse(event.data)
      console.log(JSON.parse(event.data).message, infoMessage)
      this.$toast.info(infoMessage.message)
    })
    this.setUserNotification()
  },
  computed: {
    ...mapState('userSettingModule', ['userSetting', 'countNotices'])
  }
}
</script>

<style>
.v-toast__item--info {
  background-color: #0056af !important;
}
</style>
