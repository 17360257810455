import http from "@/http-common.js";

class PasswordService {
  basePathesApiService = {
    "\/book\/admin-passwords":"/book-passwords-admin",
    "\/book\/passwords":"/book-passwords",
  }

 // basePathApiService = "/book-passwords-admin";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(id, editData) {
    return http.put(this.basePathApiService + `/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
  setRoute(route){
    this.basePathApiService = this.basePathesApiService[route];
  }
}
export default new PasswordService();
