
import ChecklistAdminService from "@/api/ChecklistAdminService.js";
import Vue from "vue";
export const checklistAdminModule = {
  namespaced: true,
  state: () => ({
    checklistData: [],
    checklistLoadStatus: 'request',
    paginationChecklistStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationChecklistStateParams.total / state.paginationChecklistStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.checklistData = [...data];
    },
    create(state, data) {
      state.checklistData.unshift(data[0])
    },
    edit_multiple(state, data) {
      let indexed = state.checklistData.findIndex(x => x.checklist_scripts__id == data.id)
      Object.assign(state.checklistData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.checklistData.findIndex(x => x.checklist_scripts__id == id)
      state.checklistData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationChecklistStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.checklistLoadStatus= 'request'
    },
    load_success(state) {
      state.checklistLoadStatus= 'success'
    },
    load_error(state) {
      state.checklistLoadStatus= 'error'
    },
  },
  actions: {

    getChecklistAll({ commit }, params) {
      commit("load_request");
      ChecklistAdminService.getAllScripts(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createChecklistData({ commit }, createData) {
      ChecklistAdminService.createScripts(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editChecklistMultiple({ commit }, {id, dataEdit}) {
      ChecklistAdminService.editMultipleScripts(id, dataEdit)
        .then((data) => {
            console.log(data);
          commit("edit_multiple", {id, dataEdit: data.data.data.rows[0]} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          console.log(error.response.data.message);
          Vue.$toast.error(error.response.data.message)
        });
    },


    deleteChecklistData({ commit }, id) {
      ChecklistAdminService.deleteScripts(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
