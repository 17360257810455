import axios from "axios";
import router from "./router";
import store from "./store";

let API_PATH;
switch (process.env.VUE_APP_MODE_BUILD) {
  case 'development':
    API_PATH = 'http://api.yandexrent.development'
    break;
  case 'production':
    API_PATH = 'https://api.yandexrent.ru'
    break;
  case 'testing':
    API_PATH = 'https://api.yandex-rent.ru'
    break;
  case 'development-testing':
    API_PATH = 'http://api.yandexrent.development'
    break;
}

console.log(process.env);

// switch (process.env.MODE_BUILD) {
//   case 'development':
//     API_PATH = 'http://localhost:8081'
//     break;
//   case 'production':
//     API_PATH = 'http://api.yandexrent.development'
//     break;
// }


// "phone":"456456456",
// "password":"76c4a096d1"


const httpInstance = axios.create({
  baseURL: API_PATH,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    "Authorization": process.env.VUE_APP_API_TOKEN,
  },
});
if (localStorage.getItem('user-token')) {
  httpInstance.defaults.headers.common["X-Employee-Token"] = JSON.parse(localStorage.getItem('user-token')) 
}

/* 
httpInstance.interceptors.request.use(
  function (config) {
    if (config.url != "auth/") {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.common["Authorization"] = token;
      } else {
        router.push("auth/");
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpInstance.interceptors.response.use(
  (response) => {
    console.log(response.data);
    return response;
  },
  function (error) {
    if (error.response.status > 400) {
      router.replace({ path: "/auth" });
    }
    return Promise.reject(error);
  }
); */
export default httpInstance;
