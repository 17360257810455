var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.inputRef,staticClass:"base-container-input--new",class:[
    _vm.horizontalTitle
      ? 'd-flex align-items-center gap-5 justify-content-between'
      : '',
    _vm.options.length ? 'position-relative' : ''
  ]},[(_vm.nameSign)?_c('div',{staticClass:"base-container-input-title"},[_c('span',{staticClass:"input-title",class:[_vm.disabled ? 'text-disabled' : '', _vm.signClass ? _vm.signClass : '']},[_vm._v(_vm._s(_vm.nameSign))])]):_vm._e(),_c('input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.outsideClickHandler),expression:"outsideClickHandler"}],staticClass:"base-input--new",class:_vm.inputClass,style:({ width: `${_vm.width}px` }),attrs:{"type":_vm.inputType,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"paste":_vm.onInput,"focus":function($event){return _vm.openSelect(true)}}}),_c('div',{staticClass:"base-input-options",class:{
      'base-input-options--show': _vm.selectOpened
    }},_vm._l((_vm.options),function(item,index){return _c('div',{key:index,staticClass:"base-input-options__item",on:{"click":() => {
          _vm.$emit('selectData', {
            name: _vm.inputName,
            value: item,
            type: 'select',
            ..._vm.additionalParams
          })
        }}},[_vm._v(" "+_vm._s(item?.[_vm.selectKey])+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }