import http from "@/http-common.js";

class GlobalSettingService {
  basePathApiService = "/settings";
  updateSettings(updateDataSettings) {
    return http.put(this.basePathApiService, updateDataSettings);
  }
  getAll() {
    return http.get(this.basePathApiService);
  } 
}
export default new GlobalSettingService();
