var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[_c('BaseTopLine'),_c('div',{staticClass:"position-relative h-100 container-fluid",staticStyle:{"min-height":"250px"}},[(_vm.localLoading)?_c('BaseLoader'):_vm._e(),_c('div',{staticClass:"overflow-auto mt-15"},[(!_vm.localLoading)?_c('ve-table',{ref:"mainTable",staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"table-data":_vm.dataTable,"border-x":true,"border-y":true,"row-key-field-name":"vehicles__id","eventCustomOption":_vm.eventCustomOption,"checkbox-option":{
          hideSelectAll: false
        },"clickHighlight":false,"cell-style-option":_vm.cellStyleOption,"rowStyleOption":{
          hoverHighlight: true
        },"cellSelectionOption":{ enable: false }}}):_vm._e()],1)],1),(!_vm.localLoading)?_c('div',[_c('gallery',{attrs:{"images":_vm.gelleryList,"index":_vm.galleryExeples},on:{"close":_vm.onCloseGallery}})],1):_vm._e(),_c('BaseModal',{attrs:{"name":"selectTime","width":"420","clickToClose":false,"shiftY":0.5,"adaptive":true,"height":"295","classes":"bg-transparent shadow-none"},on:{"before-close":() => {
        _vm.copiedData = {}
      }}},[_c('div',{staticClass:"modal-base-content modal-content py-20 px-30 rounded-25 bg-white h-100",style:({})},[_c('div',{staticClass:"d-flex justify-content-end"},[_vm._t("modal-header"),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.hide('selectTime')}}})],2),_c('div',{staticClass:"modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input pb-60"},[(_vm.copiedData.modType == 3)?_c('p',{staticClass:"fw-bold mb-20 text-center"},[_vm._v(" "+_vm._s(_vm.dataLang['vehicle_table_moderation_text13'])+" ")]):_vm._e(),(_vm.copiedData.modType == 1)?_c('p',{staticClass:"fw-bold mb-20 text-center"},[_vm._v(" "+_vm._s(_vm.dataLang['vehicle_table_moderation_text15'])+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.prichina)+" "),(_vm.copiedData.modType == 1)?_c('div',{staticClass:"wp-100 mb-20"},[_c('BaseTextarea',{staticClass:"fsc-11",attrs:{"placeholder":"","disabled":true,"width":'225',"inputClass":"fsc-11","minHeight":"45","maxLength":499,"resize":false,"input-name":"text","value":_vm.prichina,"name-sign":_vm.dataLang[
              'vehicle_table_moderation_text14'
            ]},on:{"onInput":_vm.updateValue}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"d-flex justify-content-end gap-15"},[_c('div',{staticClass:"btn btn-sm btn-opposite text-uppercase px-20 rounded-10 d-flex align-items-center justify-content-center",on:{"click":function($event){return _vm.$modal.hide('selectTime')}}},[_vm._v(" "+_vm._s(_vm.dataLang['vehicle_table_moderation_text11'])+" ")]),_c('div',{staticClass:"btn btn-sm btn-primary px-20 text-uppercase rounded-10 d-flex align-items-center justify-content-center",on:{"click":_vm.acceptModeration}},[_vm._v(" "+_vm._s(_vm.dataLang['vehicle_table_moderation_text12'])+" ")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }