import http from "@/http-common.js";

class GeneralService {


  get(url, params) {
    return http.get(url, params);
  }
  editOrCreate(url, body,params) {
    return http.post(url, body, params );
  }
  edit(url, body, params) {
    return http.put(url, body, params);
  }
  patch(url, body) {
    return http.patch(url, body );
  }
  delete(url, params) {
    return http.delete(url, {params: {...params}});
  }
  toExcel(url, params) {
    return http.get(this.basePathApiService + `/${id}/services/xlsx`, {
      params: {
        ...filterAndPagination
      },
      responseType: 'blob',
    })
  }
  toSpecExcel(url, params) {
    return http.get(url, {
      params: params,
      responseType: 'blob',
    })
  }

}
export default new GeneralService();
