<template>
    <div>
        <BaseTopLine></BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="analiticsData" :props-columns-setting="fieldsTableAnalitics"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="AnaliticsModal" v-if="analiticsLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getAnaliticsAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },

        deleteRow(nameModal) {
            this.deleteAnaliticsData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },



        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },


        ...mapActions("analiticsModule", ["getAnaliticsAll", 'createAnaliticsData', 'editAnaliticsMultiple', 'deleteAnaliticsData']),


    },
    mounted() {
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.companies__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getAnaliticsAll({ params: { 
            page: 1, limit: 10,
        }})
    },
    computed: {
        ...mapState("analiticsModule", ["analiticsData", "analiticsLoadStatus", 'paginationAnaliticsStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("analiticsModule", ["numPages"]),


        fieldsTableAnalitics() {
            return [
                { title: 'Транспорт', field: "vehicle.vehicles__name", headerFilter: "input" },
                { title: 'Процент загрузки', field: "loading.procent", headerFilter: "input", formatter: (cell) => {
                    return cell.getValue()+"%";
                }},
                { title: 'Кол-во дней', field: "loading.days", headerFilter: "input" },
                { title: 'Период', field: "loading.period", headerFilter: "input" }
            ]
        }
    },
};
</script>
    
    