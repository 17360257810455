<template>
    <div>
        <div><h3>{{title}}</h3></div>
        <div style="display: flex; justify-content: center;position: relative;">
            <img src="/img/development.jpg" class="development-img">
        </div>
    </div>
</template>
    
    
<script>

export default {
    props: {
        title:{
            default:"Страница будет разработна в скором времени..."
        }
    },
};

</script>
    
<style>

    .development-img{
        width: 54%;
    }

    @media (max-width:768px){
        .development-img{
            width: 100%;
        }
    }
</style>