<template>
    <div class="container-fluid">
        <h1>Доступ к этой странице запрещен</h1>
    </div>
</template>
    
    
<script>
import Development from "@/components/Development.vue";
export default {
    props: {},
    components:{
        Development
    }
};

</script>
