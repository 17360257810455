
let API_PATH;
switch (process.env.VUE_APP_MODE_BUILD) {
  case 'development':
    API_PATH = 'http://sse.yandexrent.development'
    break;
  case 'production':
    API_PATH = 'https://sse.yandexrent.com'
    break;
  case 'testing':
    API_PATH = 'https://sse.yandex-rent.ru'
    break;
  case 'development-testing':
    API_PATH = 'http://sse.yandexrent.development'
    break;
}

export default new EventSource(API_PATH + `/?token=${JSON.parse(localStorage.getItem('user-token'))}`);