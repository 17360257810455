var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[(_vm.statusEdit)?[_c('div',{staticClass:"mt-40"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._l((_vm.editData),function(item){return (
              item.secur_clients_fields__type_field !== 'secur_field_file'
            )?_c('div',{ref:item.secur_clients_fields__name,refInFor:true,staticClass:"col-12 col-md-6 mb-5 mt-10"},[_c('BaseInput',{attrs:{"placeholder":item.secur_clients_fields__placeholder,"title":"","maxLength":_vm.prepareMountLenght(item.secur_clients_fields__name),"value":_vm.prepareValue(
                  item.secur_clients_fields__name,
                  item.secur_clients_answers__answer_text
                ),"input-ref":item.secur_clients_fields__name +
                item.secur_clients_fields__label,"name-sign":item.secur_clients_fields__label,"input-name":item.secur_clients_fields__name,"input-mask":item.secur_clients_fields__mask},on:{"onInput":_vm.updateValue}})],1):_vm._e()}),_c('div',{staticClass:"speccontainer",staticStyle:{"display":"flex","gap":"15px","flex-wrap":"wrap","padding-top":"20px"}},_vm._l((_vm.galleryList),function(item,index){return _c('div',{ref:item.secur_clients_fields__name,refInFor:true,staticClass:"specnewbox newfileupload",staticStyle:{"padding-bottom":"8px","flex-shrink":"0","flex-grow":"1","min-width":"200px"}},[_c('UploadFileWithPreview',{key:item.secur_clients_answers__id,staticStyle:{"height":"150px"},attrs:{"accept":"image/png,image/jpg,image/jpeg","max-weight":10000,"max-x-size":1000,"max-y-size":750,"classForSingLoad":"fsc-16","preview":"image.svg","additionalParams":{
                  keyData: item.secur_clients_answers__id,
                  name: item.secur_clients_fields__name
                },"type-of-form-data":"file","error-message-max-weight":_vm.dataLang['error_message_max_file_size'],"classes":"file-load--gallery ws-nowrap","file-preview":item.secur_clients_answers__answer_text},on:{"load-file":(params) => _vm.uploadFile(params),"remove-file":(params) => _vm.deleteFile(params)}}),_c('div',[_vm._v(_vm._s(item.secur_clients_fields__label))])],1)}),0)],2),_c('div',{staticClass:"mt-20 mb-20"},[_c('div',{staticClass:"p-10 wp-100 btn btn-sm btn-primary",on:{"click":_vm.sendAnketa}},[_vm._v(" Отправить ")])])])]:_vm._e(),(_vm.statusFormFilling)?[_vm._m(1)]:_vm._e()],2),_c('Teleport',{attrs:{"to":"body"}},[_c('Modal',{attrs:{"nameForAction":"FormTypeFaceClients"}},[_c('div',{staticClass:"p-10"},[_c('div',[_c('h3',[_vm._v("Кто вы?")]),_c('span',[_vm._v("Выберите тип лица")]),_c('BaseSelect',{attrs:{"options":_vm.selectFaceOptions,"placeholder":"","label":"label"},on:{"select":_vm.setFace},model:{value:(_vm.selectedFaceOption),callback:function ($$v) {_vm.selectedFaceOption=$$v},expression:"selectedFaceOption"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Пока нет опций")])])],1)])]),_c('Modal',{attrs:{"nameForAction":"FormSuccessSecure"}},[_c('div',{staticClass:"p-10"},[_c('div',[_vm._v(" Анкета отправлена на проверку в СБ. "),_c('br'),_vm._v(" Результат будет в течение 30 минут. "),_c('br'),_vm._v(" Ожидайте. ")])])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-30 text-center"},[_c('h1',{staticClass:"fsc-16 fsc-md-24 mb-10 fw-bold"},[_vm._v(" Анкета клиента по аренде транспорта ")]),_c('p',{staticClass:"fsc-12 fsc-lg-16"},[_vm._v(" Заполните все поля и нажмите кнопку отправить ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"align-items-center d-flex h1 mt-1",staticStyle:{"height":"80vh"}},[_vm._v(" Анкета отправлена на проверку в СБ. "),_c('br'),_vm._v(" Результат будет в течение 30 минут. "),_c('br'),_vm._v(" Ожидайте. ")])
}]

export { render, staticRenderFns }