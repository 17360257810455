<template>
  <div>
    <BaseTopLine>
      <div class="d-flex gap-10 align-items-center mt-20">
        <div class="btn btn-sm btn-primary" @click="openModalForCreate">
          {{ dataLang.vehicle_examples__btn__btn_add_model }}
        </div>
        <!-- <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">{{
                    dataLang.vehicle_examples__btn__btn_delete_model }}</div> -->
        <div class="btn btn-sm btn-primary" @click="openMarksModal">
          {{ dataLang.vehicle_marks_btn_btn_add_mark }}
        </div>
        <!-- @click="openMarksModal" -->
      </div>
    </BaseTopLine>
    <div class="p-10">
      <vue-tabs @tab-change="handleTabChange">
        <v-tab
          title="Легковой транспорт"
          ref="car"
          data-type="car"
          data-type-id="1"
        >
          <BasePagination
            class="mt-20"
            :pagination-params="paginationStateParams"
            @updateLimitValue="updateLimitValue"
            :page-count="numPages"
            :page-range="3"
            :margin-pages="1"
            :break-view-class="'d-none'"
            :click-handler="clickLoadData"
            :prev-text="'Назад'"
            :next-text="'Далее'"
            :container-class="'custom-pagination'"
            :page-class="'custom-pagination__item'"
            :pageLinkClass="'custom-pagination__item-link'"
          >
          </BasePagination>
          <div class="py-10 position-relative">
            <BaseLoader v-if="loadStatus == 'request'"></BaseLoader>
            <BaseReuseTable
              :props-data="vehicleTypeAndCharacteristics"
              v-if="loadStatus == 'success'"
              :props-columns-setting="fieldsTableExamples"
              nameForAction="car"
            >
            </BaseReuseTable>
            <!-- <BaseReuseTable :props-data="vehicleTypeAndCharacteristics" v-if="loadStatus == 'success'"
                            :props-columns-setting="fieldsTableExamples" :selectRowActivateFlag="true"
                            :modalActivateFlag="true" nameForAction="car">
                        </BaseReuseTable> -->
          </div>
        </v-tab>
      </vue-tabs>
    </div>
    <Teleport to="body">
      <Modal nameForAction="car" size="lg">
        <div v-if="this.vehicleTypeAndCharacteristics">
          <div class="gap-10" ref="carModal">
            <div class="mb-5 mt-10">
              <span>{{ dataLang?.vehicle_marks__name }}</span>
              <BaseSelect
                :options="vehicleMarksSelectOptions"
                placeholder=""
                label="vehicle_marks__name"
                @select="updateMark"
                v-model="vehicleMarksSelectedOption"
              >
                <div slot="no-options">Пока нет опций</div>
              </BaseSelect>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                @onInput="updateValue"
                ref="vehicle_examples__model"
                input-name="vehicle_examples__model"
                :value="dataForModal.vehicle_examples__model"
                :name-sign="dataLang?.vehicle_examples__name"
              >
              </BaseInput>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="car_examples__body_generation"
                input-name="car_examples__body_generation"
                @onInput="updateValue"
                :value="dataForModal.car_examples__body_generation"
                :name-sign="dataLang?.car_examples__body_generation"
              >
              </BaseInput>
            </div>
            <!--
                            <div class="mb-5 mt-10">
                                <BaseInput placeholder=""  ref="car_examples__body_year_starts" input-name="car_examples__body_year_starts" @onInput="updateValue"
                                    :value="dataForModal.car_examples__body_year_starts" :name-sign="dataLang?.car_examples__body_year_starts">
                                </BaseInput>
                            </div> -->
            <!-- <div class="mb-5 mt-10">
                                <BaseInput placeholder=""  ref="car_examples__body_year_starts" input-name="car_examples__body_year_starts" @onInput="updateValue"
                                    :value="dataForModal.car_examples__body_year_starts" :name-sign="dataLang?.car_examples__body_year_starts">
                                </BaseInput>
                            </div> -->
            <!-- <div class="mb-5 mt-10">
                                <BaseInput placeholder=""  ref="car_examples__body_year_ends" input-name="car_examples__body_year_ends" @onInput="updateValue"
                                    :value="dataForModal.car_examples__body_year_ends" :name-sign="dataLang?.car_examples__body_year_ends">
                                </BaseInput>
                            </div> -->
            <div class="mb-5 mt-10">
              <span>{{ dataLang?.car_examples__body_type }}</span>
              <BaseSelect
                :options="bodyTypesCarsSelectOptions"
                @select="updateSelectValue"
                placeholder=""
                label="label"
                v-model="bodyTypesCarsSelectedOption"
              >
                <div slot="no-options">Пока нет опций</div>
              </BaseSelect>
            </div>
            <!-- <div class="mb-5 mt-10">
                <span>{{ dataLang?.car_examples__vehicle_class }}</span>
                <BaseSelect
                  :options="vehicleClassCarsSelectOptions"
                  @select="updateSelectValue"
                  placeholder=""
                  label="label"
                  v-model="vehicleClassCarsSelectedOption"
                >
                  <div slot="no-options">Пока нет опций</div>
                </BaseSelect>
              </div> -->
            <div class="mb-5 mt-10">
              <!-- <BaseInput
                  placeholder=""
                  ref="car_examples__width"
                  input-name="car_examples__width"
                  @onInput="updateValue"
                  :value="dataForModal.car_examples__width"
                  :name-sign="dataLang?.car_examples__width"
                >
                </BaseInput> -->
            </div>

            <!-- <BaseInput
                class="mb-5 mt-10"
                placeholder=""
                ref="car_examples__height"
                input-name="car_examples__height"
                @onInput="updateValue"
                :value="dataForModal.car_examples__height"
                :name-sign="dataLang?.car_examples__height"
              >
              </BaseInput>

              <BaseInput
                class="mb-5 mt-10"
                placeholder=""
                ref="car_examples__length"
                input-name="car_examples__length"
                @onInput="updateValue"
                :value="dataForModal.car_examples__length"
                :name-sign="dataLang?.car_examples__length"
              >
              </BaseInput> -->
            <BaseInput
              class="mb-5 mt-10"
              placeholder=""
              ref="car_examples__count_doors"
              input-name="car_examples__count_doors"
              @onInput="updateValue"
              :value="dataForModal.car_examples__count_doors"
              :name-sign="dataLang?.car_examples__count_doors"
            >
            </BaseInput>
            <BaseInput
              class="mb-5 mt-10"
              placeholder=""
              ref="car_examples__count_places"
              input-name="car_examples__count_places"
              @onInput="updateValue"
              :value="dataForModal.car_examples__count_places"
              :name-sign="dataLang?.car_examples__count_places"
            >
            </BaseInput>
            <BaseInput
              class="mb-5 mt-10"
              placeholder=""
              ref="car_examples__trunk_volume"
              input-name="car_examples__trunk_volume"
              @onInput="updateValue"
              :value="dataForModal.car_examples__trunk_volume"
              :name-sign="dataLang?.car_examples__trunk_volume"
            >
            </BaseInput>
            <BaseInput
              class="mb-5 mt-10"
              placeholder=""
              ref="car_examples__ground_clearance"
              input-name="car_examples__ground_clearance"
              @onInput="updateValue"
              :value="dataForModal.car_examples__ground_clearance"
              :name-sign="dataLang?.car_examples__ground_clearance"
            >
            </BaseInput>
            <!-- <BaseInput
                class="mb-5 mt-10"
                placeholder=""
                ref="car_examples__curb_weight"
                input-name="car_examples__curb_weight"
                @onInput="updateValue"
                :value="dataForModal.car_examples__curb_weight"
                :name-sign="dataLang?.car_examples__curb_weight"
              >
              </BaseInput>
              <BaseInput
                class="mb-5 mt-10"
                placeholder=""
                ref="car_examples__full_weight"
                input-name="car_examples__full_weight"
                @onInput="updateValue"
                :value="dataForModal.car_examples__full_weight"
                :name-sign="dataLang?.car_examples__full_weight"
              >
              </BaseInput> -->
            <BaseInput
              class="mb-5 mt-10"
              placeholder=""
              ref="car_examples__tank_capacity"
              input-name="car_examples__tank_capacity"
              @onInput="updateValue"
              :value="dataForModal.car_examples__tank_capacity"
              :name-sign="dataLang?.car_examples__tank_capacity"
            >
            </BaseInput>
          </div>

          <div class="d-flex gap-10 mt-15">
            <div class="btn btn-sm btn-primary" @click="sendDataFromModal">
              {{ dataLang.form_btn__btn_save }}
            </div>
            <div class="btn btn-sm btn-opposite" @click="canselModal">
              {{ dataLang.form_btn__btn_caneсl }}
            </div>
          </div>
        </div>
      </Modal>
    </Teleport>
    <Teleport to="body">
      <Modal nameForAction="acceptDelete">
        <p class="text-center">Вы действительно хотите удалить?</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">Да</div>
        </div>
      </Modal>
    </Teleport>
    <Teleport to="body">
      <Modal nameForAction="marksModal" size="xl">
        <p class="fs-5 fw-bold">
          {{ dataLang.vehicle_marks_btn_btn_add_mark }}
        </p>
        <div class="mb-20 d-flex gap-10 flex-column flex-md-row">
          <BaseInput
            class="wp-50"
            placeholder=""
            title="Default Title"
            @onInput="newMarkNameDefault"
            :value="newMarkName"
            :name-sign="
              dataLang.vehicle_marks__table_header__vehicle_marks__name
            "
          >
          </BaseInput>

          <VueFileAgent
            class="wp-50"
            ref="vueFileAgent"
            :theme="'list'"
            :multiple="false"
            :deletable="true"
            :meta="true"
            :accept="'image/*'"
            :maxSize="'10MB'"
            :maxFiles="1"
            :helpText="'Выберите изображение'"
            :errorText="{
              type: 'Only images',
              size: 'Files should not exceed 10MB in size'
            }"
            @select="filesSelected($event)"
            @beforedelete="onBeforeDelete($event)"
            @delete="fileDeleted($event)"
            v-model="fileRecords"
          ></VueFileAgent>
        </div>
        <div class="btn btn-sm btn-primary mb-15" @click="submitMarks">
          {{ dataLang.vehicle_marks_btn_btn_add_mark }}
        </div>

        <BaseReuseTable
          v-if="loadStatus == 'success'"
          :props-columns-setting="fieldsTableMarks"
          :props-data="vehicleMarks"
        >
        </BaseReuseTable>
      </Modal>
    </Teleport>
  </div>
</template>

<script>
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import { TimeScale } from 'chart.js'
import SelectsService from '@/api/SelectsService'
import Vue from 'vue'
import VehicleMarksService from '@/api/VehicleMarksService'
export default {
  // TODO нужно сделать вывод крутилки по типу модуля, от которого идет именение стейта. Взять имя модуля или что-то такое
  components: {
    VueTabs,
    VTab,
    Modal
  },

  props: {},
  data() {
    return {
      fileRecords: null,
      dataMarksModal: {},
      myData: null,
      dataForModal: {},
      activeTab: 'car',
      vehicleTypeId: 1,
      editData: {},
      updatedValues: {},
      activeSelectRow: null,
      newMarkName: null,
      bodyTypesCarsSelectOptions: [],
      bodyTypesCarsSelectedOption: null,
      vehicleClassCarsSelectOptions: [],
      vehicleClassCarsSelectedOption: null,
      vehicleMarksSelectOptions: [],
      vehicleMarksSelectedOption: null,
      limitPaginationValue: 100
    }
  },
  methods: {
    newMarkNameDefault(data) {
      this.newMarkName = data.value
    },
    updateLimitValue(data) {
      this.limitPaginationValue = data
      this.getAll({
        vehicleType: this.activeTab,
        limit: this.limitPaginationValue,
        page: this.numPage
      })
      //   this.getTransportVehiclesAll({
      //     page: 1,
      //     limit: this.limitPaginationValue,
      //   })
    },
    submitMarks() {
      // console.log(this.fileRecords);
      if (this.newMarkName.trim() || this.fileRecords) {
        this.createOneMark({
          file: this.fileRecords.file,
          type: 'mark',
          newName: this.newMarkName
        })
        this.newMarkNameDefault({ value: '' })
        this.fileRecords = []
        EventBus.$emit('closeModal', {
          data: null,
          nameForAction: 'marksModal',
          mode: 'create'
        })
      } else {
        Vue.$toast.error('Заполните все поля для марки')
      }
    },
    filesSelected(e) {
      console.log(e, this.fileRecords)
    },
    onBeforeDelete(e) {
      this.fileRecords = null
    },
    fileDeleted(e) {
      this.fileRecords = null
    },
    openMarksModal() {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: 'marksModal',
        mode: 'create'
      })
    },
    deleteActionAndModal() {
      if (this.activeSelectRow) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDelete',
          mode: 'create'
        })
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    deleteRow() {
      this.deleteOne(this.activeSelectRow)
      this.closeModal('acceptDelete')
      this.activeSelectRow = null
    },
    canselModal() {
      this.closeModal()
    },
    updateValue(data) {
      this.updatedValues[data.name] = data.value
    },

    updateMark(data) {
      console.log(data)
      this.updatedValues['vehicle_examples__mark_id'] = data.vehicle_marks__id
    },

    updateSelectValue(data) {
      this.updatedValues[data.field] = data.value
    },

    openModalForCreate() {
      ;(this.updatedValues = {}),
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: this.activeTab,
          mode: 'create'
        })
    },
    closeModal(nameModal = this.activeTab) {
      EventBus.$emit('closeModal', { nameForAction: nameModal })
      this.$refs[`${this.activeTab}Modal`]
        .querySelectorAll('input')
        .forEach((element) => {
          element.value = ''
        })
    },

    sendDataFromModal() {
      if (this.dataForModal.car_examples__id) {
        console.log('edit')
        this.editOne({
          id: this.dataForModal.vehicle_examples__id,
          data: this.updatedValues
        })
        EventBus.$emit('closeModal', { nameForAction: this.activeTab })
      } else {
        console.log('create')
        console.log(this.updatedValues)
        this.createOne(
          Object.assign(this.updatedValues, {
            vehicle_examples__vehicle_type: this.activeTab
          })
        )
        EventBus.$emit('closeModal', { nameForAction: this.activeTab })
        this.closeModal()
        this.$refs.car.$el.querySelectorAll('input').forEach((elem) => {
          elem.value = ''
        })
      }
    },
    clickLoadData(numPage) {
      this.getAll({
        vehicleType: this.activeTab,
        limit: this.limitPaginationValue,
        page: numPage
      })
    },
    ...mapActions('vehicleTypeAndCharacteristicsModule', [
      'getAll',
      'editOne',
      'createOne',
      'deleteOne'
    ]),
    ...mapActions('vehicleMarksModule', ['getAllMarks', 'createOneMark']),
    ...mapActions('storageFileModule', ['uploadFiles']),
    handleTabChange(tabIndex, newTab, oldTab) {
      if (!(newTab.$attrs['data-type'] == this.activeTab)) {
        this.activeTab = newTab.$attrs['data-type']
        this.vehicleTypeId = newTab.$attrs['data-type-id']
        this.getAll({
          vehicleType: newTab.$attrs['data-type'],
          limit: 10,
          page: 1
        })
      }
    },
    prepareKeysForModal(payloadData) {
      this.updatedValues = {}
      this.dataForModal = {}
      if (!payloadData) {
        console.log('prepareKeysFetch')
        for (const key in this.vehicleTypeAndCharacteristics[0]) {
          this.dataForModal[key] = null
        }
      } else {
        if (this.editData) {
          this.bodyTypesCarsSelectedOption =
            this.bodyTypesCarsSelectOptions.find(
              (bodyTypes) =>
                bodyTypes.label == this.editData.car_examples__body_type
            )

          this.vehicleClassCarsSelectedOption =
            this.vehicleClassCarsSelectOptions.find((vehicleClass) => {
              return (
                vehicleClass.label == this.editData.car_examples__vehicle_class
              )
            })

          this.vehicleMarksSelectedOption = this.vehicleMarksSelectOptions.find(
            (mark) =>
              mark.vehicle_marks__id == this.editData.vehicle_examples__mark_id
          )
        }
        this.dataForModal = Object.assign({}, payloadData)
      }
    }
  },

  mounted() {
    EventBus.$on('selectTableRows', (payload) => {
      this.activeSelectRow = payload.data.vehicle_examples__id
    })

    // this.prepareKeysForModal()
    EventBus.$on('openModal', (payload) => {
      this.dataForModal = {}
      this.editData = payload.data
      console.log(payload)

      this.prepareKeysForModal(payload.data)
    })

    SelectsService.getOneByField('car_examples__body_type').then(
      (bodyTypesCarsSelectOptionsData) => {
        this.bodyTypesCarsSelectOptions =
          bodyTypesCarsSelectOptionsData.data.data.rows
        if (this.editData) {
          console.log(this.editData)
          this.bodyTypesCarsSelectedOption =
            bodyTypesCarsSelectOptionsData.data.data.rows.find(
              (bodyTypes) =>
                bodyTypes.label == this.editData.car_examples__body_type
            )
        }
      }
    )

    SelectsService.getOneByField('car_examples__vehicle_class').then(
      (vehicleClassCarsSelectOptionsData) => {
        this.vehicleClassCarsSelectOptions =
          vehicleClassCarsSelectOptionsData.data.data.rows
        if (this.editData) {
          this.vehicleClassCarsSelectedOption =
            vehicleClassCarsSelectOptionsData.data.data.rows.find(
              (vehicleClass) => {
                console.log(
                  vehicleClass.label,
                  this.editData,
                  this.editData.car_examples__vehicle_class
                )
                return (
                  vehicleClass.label ==
                  this.editData.car_examples__vehicle_class
                )
              }
            )
        }
      }
    )

    VehicleMarksService.getAll({
      params: { fields: 'vehicle_marks__name, vehicle_marks__id' }
    }).then((vehicleMarksSelectOptionsData) => {
      console.log(vehicleMarksSelectOptionsData.data.data.rows)
      this.vehicleMarksSelectOptions =
        vehicleMarksSelectOptionsData.data.data.rows
      if (this.editData) {
        this.vehicleMarksSelectedOption =
          vehicleMarksSelectOptionsData.data.data.rows.find(
            (mark) =>
              mark.marks__id == this.editData.car_examples__vehicle_class
          )
      }
    })
  },
  created() {
    this.getAll({ vehicleType: this.activeTab, page: 1, limit: 100 })
    this.getAllMarks()

    SelectsService.getAll()
      .then((data) => {
        console.log(data.data.data)
      })
      .catch((error) => {})
  },
  computed: {
    ...mapState('vehicleTypeAndCharacteristicsModule', [
      'vehicleTypeAndCharacteristics',
      'loadStatus',
      'paginationStateParams'
    ]),
    ...mapState('vehicleMarksModule', ['vehicleMarks']),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('vehicleTypeAndCharacteristicsModule', ['numPages']),

    fieldsTableMarks() {
      return [
        {
          title:
            this.dataLang['vehicle_marks_table_header__vehicle_marks__logo'],
          field: 'vehicle_marks__logo',
          formatter: 'image',
          formatterParams: {
            height: '50px',
            width: '50px'
          }
        },
        {
          title:
            this.dataLang['vehicle_marks__table_header__vehicle_marks__name'],
          field: 'vehicle_marks__name',
          headerFilter: 'input',
          isShow: false
        },
        {
          title:
            this.dataLang['vehicle_marks__table_header__vehicle_marks__id'],
          field: 'vehicle_marks__id',
          headerFilter: 'input',
          isShow: false
        }
      ]
    },

    fieldsTableExamples() {
      return [
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__vehicle_examples__id'
            ],
          field: 'car_examples__id',
          headerFilter: 'input',
          isShow: false
        },
        {
          title: this.dataLang['vehicle_marks__name'],
          field: 'vehicle_marks__name',
          headerFilter: 'input',
          isShow: true
        },
        {
          title: this.dataLang['vehicle_examples__name'],
          field: 'vehicle_examples__model',
          headerFilter: 'input',
          isShow: true
        },
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__body_generation'
            ],
          field: 'car_examples__body_generation',
          headerFilter: 'input',
          isShow: true
        },
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__body_type'
            ],
          field: 'car_examples__body_type',
          headerFilter: 'input',
          isShow: true
        },
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__count_doors'
            ],
          field: 'car_examples__count_doors',
          headerFilter: 'input',
          isShow: true
        },
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__count_places'
            ],
          field: 'car_examples__count_places',
          headerFilter: 'input',
          isShow: true
        },
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__trunk_volume'
            ],
          field: 'car_examples__trunk_volume',
          headerFilter: 'input',
          isShow: true
        },
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__ground_clearance'
            ],
          field: 'car_examples__ground_clearance',
          headerFilter: 'input',
          isShow: true
        },
        
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__tank_capacity'
            ],
          field: 'car_examples__tank_capacity',
          headerFilter: 'input',
          isShow: true
        },
        {
          title:
            this.dataLang[
              'vehicle_examples__table_header__car_examples__trunk_volume'
            ],
          field: 'car_examples__trunk_volume',
          headerFilter: 'input',
          isShow: true
        }
      ]
    }
  }
}
</script>
