import TransportVehiclesService from '@/api/TransportVehiclesService.js'
import Vue from 'vue'
export const transportVehiclesModule = {
  namespaced: true,
  state: () => ({
    transportVehiclesData: [],
    transportVehiclesLoadStatus: 'request',
    lineHealthVehicle: {},
    lineTitle: {},
    paginationStateParams: {
      count: 0,
      limit: 10,
      total: 0,
      page: 0,
    },
  }),

  getters: {
    numPages: (state) =>
      Math.ceil(
        state.paginationStateParams.total / state.paginationStateParams.limit
      ),
  },
  mutations: {
    get_all(state, data) {
      state.transportVehiclesData = [...data]
    },
    create(state, data) {
      state.transportVehiclesData.unshift(data)
    },
    edit_multiple(state, data) {
      console.log(data)
      let indexed = state.transportVehiclesData.findIndex(
        (x) => x.vehicles__id == data.id
      )
      console.log(state.transportVehiclesData[indexed], indexed, 'edit index')
      state.transportVehiclesData[indexed] = Object.assign(
        state.transportVehiclesData[indexed],
        data.data.rows[0]
      )
    },

    delete_data(state, id) {
      let indexed = state.transportVehiclesData.findIndex(
        (x) => x.place_deliveries__place_id == id
      )
      state.transportVehiclesData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationStateParams = { total, count, limit, page }
    },
    update_line_health_vehicle(state, data) {
      state.lineHealthVehicle = data
    },
    update_title(state, data) {
      state.lineTitle = data
    },

    load_request(state, status) {
      state.transportVehiclesLoadStatus = 'request'
    },
    load_success(state) {
      state.transportVehiclesLoadStatus = 'success'
    },
    load_error(state) {
      state.transportVehiclesLoadStatus = 'error'
    },
  },
  actions: {
    getTransportVehiclesAll({ commit }, params) {
      commit('load_request')
      console.log(params)
      TransportVehiclesService.getAll({ params: params })
        .then((data) => {
          console.log(data.data.data);
          commit('update_pagination', data.data.data)
          commit(
            'get_all',
            data.data.data.rows.map((x) => {
              x.rowKey = x.vehicles__id
              return x
            })
          )
          commit('load_success')
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки')
          commit('load_error')
        })
    },

    downloadTransportVehiclesExel({ commit }, params) {
      TransportVehiclesService.downloadExel({ params: params })
        .then((data) => {
          console.log(data)
          // commit('update_pagination', data.data.data)
          // commit('get_all', data.data.data.rows)
          // commit('load_success')
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки')
          commit('load_error')
        })
    },

    createTransportVehiclesData({ commit }, { data, callback }) {
      TransportVehiclesService.create(data)
        .then((data) => {
          console.log(data)
          // commit('create', data.data.data.rows[0])
          callback()
          Vue.$toast.success('Запись добавлена')

          return true
        })
        .catch((error) => {
          console.log(error.response.data.errors)
          callback(error.response.data.errors)
          Vue.$toast.error(error.response.data.message)
          return false
        })
    },

    editTransportVehiclesMultiple({ commit }, { id, data, callback, typeOfClose}) {
      TransportVehiclesService.update(id, data)
        .then((data) => {
          console.log(data.data.data)
          callback()
          Vue.$toast.success('Данные успешно обновлены')
          return true
        })
        .catch((error) => {
          console.log(error)
          callback(error.response.data.errors, typeOfClose)
          Vue.$toast.error('Ошибка загрузки')
          return false
        })
    },
    getHealth({ commit }, id) {
      if (id) {TransportVehiclesService.getHealth(id)
        .then((data) => {
          console.log(data);
          commit('update_line_health_vehicle', data.data.data.rows)
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки строки здоровья')
        })}

    },
    getTitle({ commit }, id) {
      if (id) {
        TransportVehiclesService.getTitle(id)
        .then((data) => {
          console.log(data);
          commit('update_title', data.data.data.rows[0])
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error('Ошибка загрузки строки здоровья')
        })
      }

    },
    toArchiveTransportVehiclesMultiple({ commit, dispatch, getters }, listIds) {
      commit('load_request')
      TransportVehiclesService.toArchive(listIds.join())
        .then((data) => {
          console.log(data.data.data)
          // commit('edit_multiple', { id: id, data: data.data.data })
          dispatch('getTransportVehiclesAll', {
            limit: 100,
            page: getters.numPages,
            type: 'car',
          })
          commit('load_success')
          Vue.$toast.success(data.data.message)
        })
        .catch((error) => {
          console.log(error)
          dispatch('getTransportVehiclesAll', {
            limit: 100,
            page: getters.numPages,
            type: 'car',
          })
          commit('load_error')
          Vue.$toast.error(error.response.data.message)
        })
    },
    duplicateTransportVehiclesMultiple({ commit, dispatch, getters }, listIds) {
      commit('load_request')
      TransportVehiclesService.duplicate(listIds.join())
        .then((data) => {
          console.log(data)
          // commit('edit_multiple', { id: id, data: data.data.data })
          dispatch('getTransportVehiclesAll', {
            limit: 10,
            page: 1,
            type: 'car',
          })
          commit('load_success')
          Vue.$toast.success(data.data.message)
        })
        .catch((error) => {
          console.log(error)
          dispatch('getTransportVehiclesAll', {
            limit: 10,
            page: 1,
            type: 'car',
          })
          commit('load_error')
          Vue.$toast.error(error.response.data.message)
        })
    },

    deleteTransportVehiclesData({ commit, dispatch, getters }, listIds) {
      commit('load_request')
      TransportVehiclesService.deleteMultiple(listIds.join())
        .then((data) => {
          console.log(data)
          dispatch('getTransportVehiclesAll', {
            limit: 10,
            page: 1,
            type: 'car',
          })
          commit('load_success')
          Vue.$toast.success(data.data.message)
        })
        .catch((error) => {
          dispatch('getTransportVehiclesAll', {
            limit: 10,
            page: 1,
            type: 'car',
          })
          commit('load_error')
          console.log(error)
          Vue.$toast.error(error.response.data.message)
        })
    },
    async toExcelTransportVehiclesData({ commit, dispatch, getters }, filter) {
      commit('load_request')
      let dataFrom = await TransportVehiclesService.toExcel(filter)
      console.log(dataFrom);
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
      commit('load_success')
      // .then(
      //   (data) => data.blob()
      //   //   if (blob != null) {
      //   //     const filename = new Date().toISOString();
      //   //     const url = window.URL.createObjectURL(blob);
      //   //     const a = document.createElement('a');
      //   //     a.href = url;
      //   //     a.download = `${filename}.xlsx`;
      //   //     document.body.appendChild(a);
      //   //     a.click();
      //   //     a.remove();
      //   //   }
      // )
      // .then((data) => {
      //   console.log(data)
      // })
      // .catch((error) => {
      //   commit('load_error')
      //   console.log(error)
      //   Vue.$toast.error(error.response.data.message)
      // })
    },
  },

  // async loadDoc() {
  //   let datas = await fetch('http://api.yandexrent.development/vehicles/table/convert/xlsx?type=car&where=[]', {
  //     headers: {
  //       'X-Employee-Token':
  //         'dsAuUj8c7q7R2zqfX6ciWkm9EBnnWazCitsqovUd0aNf3SoqxTmGtRap7IKteERi8Kiiu+v6qU14swaBWz9/uIL0TXS7HV/33pzGHsfChvZbiOzW8rbl+ZSXZfoRwj7KebAf7kNN9k1GWko5lDDE+dHq5iwaugZ5BxC6U+Gtvsb+N3lD7aNOkWDUKy5FOAnszt5amEUyfq2GXxLXNJ39jg==',
  //     },
  //   });
  //   //   Здесь нужно присвоить ответ для реактивного отобраения в шаблоне
  //   // Например: this.resultTest = await datas.json()
  //   const blob = await datas.blob();
  //   if (blob != null) {
  //     const filename = new Date().toISOString();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `${filename}.xlsx`;
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //   }
  // },
}
