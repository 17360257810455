
import SecureSettingsService from "@/api/SecureSettingsService.js";
import Vue from "vue";
export const secureGroupsModule = {
  namespaced: true,
  state: () => ({
    secureGroupsData: [],
    secureOpenGroupId: 0,
    secureGroupsLoadStatus: 'request',
    paginationSecureGroupsStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationSecureGroupsStateParams.total / state.paginationSecureGroupsStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      console.log(data)
      state.secureGroupsData = [...data];
    },

    load_request(state, status) {
      state.secureGroupsLoadStatus = 'request'
    },
    load_success(state) {
      state.secureGroupsLoadStatus = 'success'
    },
    load_error(state) {
      state.secureGroupsLoadStatus = 'error'
    },

    open_group(state, id){
      state.secureOpenGroupId = id;
    }
  },
  actions: {
    getSecureGroupsAll({ commit }, params) {
      commit("load_request");
      SecureSettingsService.getGroups(params)
        .then((data) => {
         // commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

   
  }
};
