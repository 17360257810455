import http from "@/http-common.js";

class DeliveryService {
  basePathApiService = "/deliveries";
  getAll(id) {
    return http.get(this.basePathApiService + `/place/` + id);
  } 
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  update(id, updateData) {
    return http.put(this.basePathApiService + `/${id}`, updateData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
}
export default new DeliveryService();
