import http from '@/http-common.js'
// import url from 'url'

class TransportVehiclesService {
  basePathApiService = '/objects'
  getAll(vehicleTypeAndFilter) {
    return http.get(this.basePathApiService + '/car/table', vehicleTypeAndFilter)
  }

  getSimpleAll(vehicleTypeAndFilter) {
    return http.get(this.basePathApiService, vehicleTypeAndFilter)
  }

  // getAllUnqueOneEntityCar(vehicleTypeAndFilter) {
  //   return http.get("/vehicles-cars/register/", vehicleTypeAndFilter);
  // }

  getImagesGallery(id) {
    return http.get(this.basePathApiService + `/${id}/images`)
  }
  downloadExel(params) {
    return http.get('/vehicles/table/convert/xlsx', params)
  }
  create(createData) {
    return http.post(this.basePathApiService, createData)
  }
  getHealth(id) {
    return http.get( this.basePathApiService + `/${id}/health`)
  }
  getTitle(id) {
    return http.get(this.basePathApiService + `/${id}/title`)
  }
  update(id, updateData) {
    return http.put(this.basePathApiService + `/${id}`, updateData)
  }
  duplicate(listIds) {
    return http.put(this.basePathApiService + `/copy/` + listIds)
  }
  toExcel(filter) {
    // const params = new URLSearchParams(filter);
    return http.get(this.basePathApiService + `/car/table/xlsx`, {
      params: {
        type: filter.type,
        where: JSON.stringify(filter.where),
      },
      responseType: 'blob',
    })
  }
  toArchive(listIds) {
    return http.put(this.basePathApiService + `/toarchive/` + listIds + '?pincode=1234')
  }
  deleteMultiple(listIds) {
    return http.delete(this.basePathApiService + `/${listIds}`)
  }
}
export default new TransportVehiclesService()
