<template>
    <div>
        <BaseTopLine></BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="companiesYandexrentData" :props-columns-setting="fieldsTableCompaniesYandexrent"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="companiesYandexrentModal" v-if="companiesYandexrentLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getCompaniesYanderentAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },

        deleteRow(nameModal) {
            this.deleteCompaniesYandexrentData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },



        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },


        ...mapActions("companiesYandexrentModule", ["getCompaniesYandexrentAll", 'createCompaniesYandexrentData', 'editCompaniesYandexrentMultiple', 'deleteCompaniesYandexrentData']),


    },
    mounted() {
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.companies__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getCompaniesYandexrentAll({ params: { 
            page: 1, limit: 10,
        }})
    },
    computed: {
        ...mapState("companiesYandexrentModule", ["companiesYandexrentData", "companiesYandexrentLoadStatus", 'paginationCompaniesYandexrentStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("companiesYandexrentModule", ["numPages"]),


        fieldsTableCompaniesYandexrent() {
            return [
                { title: 'ID', field: "companies__id", headerFilter: "input" },
                { title: 'Компания', field: "companies__name", headerFilter: "input" },
                { title: 'Баланс', field: "companies__balance", headerFilter: "input"},
                { title: 'Дата регистрации', field: "companies__created_at", headerFilter: "input" }
            ]
        }
    },
};
</script>
    
    