import http from "@/http-common.js";

class ClientsAnketaSecurService {

  basePathApiService = "/client-anketa-security";
 
  getOne(key) {
    return http.get(this.basePathApiService + `/${key}`);
  } 

  
  update(key, data){
    return http.put(this.basePathApiService + `/${key}/update`, data);
  }

  updateFields(key, data){
    return http.put(this.basePathApiService + `/${key}/update-fields`, data);
  }
}
export default new ClientsAnketaSecurService();
