<template>
    <div>
        <BaseTopLine></BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="integrationsData" :props-columns-setting="fieldsTableIntegrations"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="IntegrationsModal" v-if="integrationsLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getEmployessAdminAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },

        deleteRow(nameModal) {
            this.deleteIntegrationsData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },



        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },


        ...mapActions("integrationsModule", ["getIntegrationsAll", 'createIntegrationsData', 'editIntegrationsMultiple', 'deleteIntegrationsData']),


    },
    mounted() {
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.companies__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getIntegrationsAll({ params: { 
            page: 1, limit: 10,
        }})
    },
    computed: {
        ...mapState("integrationsModule", ["integrationsData", "integrationsLoadStatus", 'paginationIntegrationsStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("integrationsModule", ["numPages"]),


        fieldsTableIntegrations() {
            return [
                { title: 'ID', field: "companies__id", headerFilter: "input" },
                { title: 'Компания', field: "companies__name", headerFilter: "input" },
                { title: 'Баланс', field: "companies__balance", headerFilter: "input"},
                { title: 'Дата регистрации', field: "companies__created_at", headerFilter: "input" }
            ]
        }
    },
};
</script>
    
    