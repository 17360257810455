<template>
  <div>
    <BaseTopLine></BaseTopLine>
    <div class="p-20" v-if="loadStatus == 'success' && loadUserSetting">
      <vue-tabs>
        <v-tab title="Реквизиты">
          <BaseLoader v-if="loadStatus == 'request'"></BaseLoader>
          <div class="d-flex flex-wrap gap-20 pt-20">
            <div class="block-action">
              <span class="fw-bold">{{ dataLang?.set_req_group1 }}</span>
              <!-- <div class="mb-5 mt-10">
                <span>Компонент для загрузки фоток и файлов</span>
                <div class="mb-10">
                  <a :href="dataSettings.vehicles__pts_file" v-if="dataSettings.vehicles__pts_file">
                  {{ dataSettings.vehicles__pts_file }}</a>
                  <div class="d-flex gap-10"><label class="btn btn-sm btn-primary"
                      :class="{ disabled: dataSettings.vehicles__pts_file }">Добавить файл
                      <input type="file" class="d-none" @change="uploadFile('PTS', 'vehicles__pts_file')" ref="PTS"></label>
                    <div class="btn btn-sm btn-opposite" :class="{ disabled: !dataSettings.vehicles__pts_file }"
                      @click="delFile('vehicles__pts_file')">
                      Удалить файл</div>
                  </div>
                </div> -->

              <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_company_name"
                  input-name="company_settings__set_req_company_name"
                  :value="dataSettings.company_settings__set_req_company_name"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_company_name"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_address_ur"
                  input-name="company_settings__set_req_address_ur"
                  :value="dataSettings.company_settings__set_req_address_ur"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_address_ur"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_address_fact"
                  input-name="company_settings__set_req_address_fact"
                  :value="dataSettings.company_settings__set_req_address_fact"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_address_fact"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_inn"
                  input-name="company_settings__set_req_inn"
                  :value="dataSettings.company_settings__set_req_inn"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_inn"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_kpp"
                  input-name="company_settings__set_req_kpp"
                  :value="dataSettings.company_settings__set_req_kpp"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_kpp"
                ></BaseInput>
              </div>

              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_ogrn"
                  input-name="company_settings__set_req_ogrn"
                  :value="dataSettings.company_settings__set_req_ogrn"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_ogrn"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_rs"
                  input-name="company_settings__set_req_rs"
                  :value="dataSettings.company_settings__set_req_rs"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_rs"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_bank"
                  input-name="company_settings__set_req_bank"
                  :value="dataSettings.company_settings__set_req_bank"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_bank"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_bik"
                  input-name="company_settings__set_req_bik"
                  :value="dataSettings.company_settings__set_req_bik"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_bik"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_ks"
                  input-name="company_settings__set_req_ks"
                  :value="dataSettings.company_settings__set_req_ks"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_ks"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  :placeholder="
                    dataSettings.company_settings__set_req_phone_mask
                  "
                  input-ref="company_settings__set_req_phone"
                  input-name="company_settings__set_req_phone"
                  :value="dataSettings.company_settings__set_req_phone"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_phone"
                  :max-length="16"
                  :input-mask="true"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_email"
                  input-name="company_settings__set_req_email"
                  :value="dataSettings.company_settings__set_req_email"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_email"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_gen_dir"
                  input-name="company_settings__set_req_gen_dir"
                  :value="dataSettings.company_settings__set_req_gen_dir"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_gen_dir"
                ></BaseInput>
              </div>
              <!-- <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_bik"
                  input-name="company_settings__set_req_gen_dir_rp"
                  :value="dataSettings.company_settings__set_req_gen_dir_rp"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_gen_dir_rp"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_gen_dir_dp"
                  :value="dataSettings.company_settings__set_req_gen_dir_dp"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_gen_dir_dp"
                ></BaseInput>
              </div> -->
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_gen_dir_io"
                  :value="dataSettings.company_settings__set_req_gen_dir_io"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_gen_dir_io"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_buhgalter"
                  :value="dataSettings.company_settings__set_req_buhgalter"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_buhgalter"
                ></BaseInput>
              </div>
              <!-- <div class="mb-5">
                <BaseInput
                  placeholder=""

                  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_wapp"
                  :value="dataSettings.company_settings__set_req_wapp"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_wapp"
                ></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput
                  placeholder=""

                  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_telegram"
                  :value="dataSettings.company_settings__set_req_telegram"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_telegram"
                ></BaseInput>
              </div> -->
              <!-- <div class="mb-5">
                <BaseInput placeholder=""  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_vladelets_sms"
                  :value="dataSettings.company_settings__set_req_vladelets_sms" @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_vladelets_sms"></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput placeholder=""  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_director_email"
                  :value="dataSettings.company_settings__set_req_director_email" @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_director_email"></BaseInput>
              </div>
              <div class="mb-5">
                <BaseInput placeholder=""  ref="company_settings__set_req_gen_dir_dp"
                  input-name="company_settings__set_req_director_email"
                  :value="dataSettings.company_settings__set_req_director_sms" @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_req_director_sms"></BaseInput>
              </div> -->
            </div>
            <div>
              <div class="block-action">
                <span class="fw-bold"> {{ dataLang?.set_req_group2 }}</span>
                <div class="d-flex flex-wrap gap-20 pt-20">
                  <div>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_vladelets_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_vladelets_sms"
                      :value="
                        dataSettings.company_settings__set_req_vladelets_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_vladelets_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_director_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_director_sms"
                      :value="
                        dataSettings.company_settings__set_req_director_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_director_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_sequrity_dep_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_sequrity_dep_sms"
                      :value="
                        dataSettings.company_settings__set_req_sequrity_dep_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_sequrity_dep_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_tex_dep_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_tex_dep_sms"
                      :value="
                        dataSettings.company_settings__set_req_tex_dep_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_tex_dep_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_fin_dep_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_fin_dep_sms"
                      :value="
                        dataSettings.company_settings__set_req_fin_dep_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_fin_dep_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_ruk_prodag_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_ruk_prodag_sms"
                      :value="
                        dataSettings.company_settings__set_req_ruk_prodag_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_ruk_prodag_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_dir_razvit_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_dir_razvit_sms"
                      :value="
                        dataSettings.company_settings__set_req_dir_razvit_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_dir_razvit_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_otd_kadry_sms"
                      :input-mask="true"
                      input-name="company_settings__name"
                      :value="
                        dataSettings.company_settings__set_req_otd_kadry_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_otd_kadry_sms
                      "
                    ></BaseInput>

                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_office_manag_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_office_manag_sms"
                      :value="
                        dataSettings.company_settings__set_req_office_manag_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_office_manag_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_settings__set_req_control_kach_sms"
                      :input-mask="true"
                      input-name="company_settings__set_req_control_kach_sms"
                      :value="
                        dataSettings.company_settings__set_req_control_kach_sms
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_control_kach_sms
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder="+7(__) __-__-__"
                      input-ref="company_setting_1"
                      :input-mask="true"
                      input-name="company_setting_1"
                      :value="dataSettings.company_setting_1"
                      @onInput="updateValue"
                      :name-sign="dataLang?.company_setting_1"
                    ></BaseInput>
                  </div>
                  <div>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_vladelets_email"
                      input-name="company_settings__set_req_vladelets_email"
                      :value="
                        dataSettings.company_settings__set_req_vladelets_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_vladelets_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_director_email"
                      input-name="company_settings__set_req_director_email"
                      :value="
                        dataSettings.company_settings__set_req_director_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_director_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_sequrity_dep_email"
                      input-name="company_settings__set_req_sequrity_dep_email"
                      :value="
                        dataSettings.company_settings__set_req_sequrity_dep_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_sequrity_dep_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_tex_dep_email"
                      input-name="company_settings__set_req_tex_dep_email"
                      :value="
                        dataSettings.company_settings__set_req_tex_dep_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_tex_dep_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_fin_dep_email"
                      input-name="company_settings__set_req_fin_dep_email"
                      :value="
                        dataSettings.company_settings__set_req_fin_dep_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_fin_dep_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_ruk_prodag_email"
                      input-name="company_settings__set_req_ruk_prodag_email"
                      :value="
                        dataSettings.company_settings__set_req_ruk_prodag_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_ruk_prodag_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_dir_razvit_email"
                      input-name="company_settings__set_req_dir_razvit_email"
                      :value="
                        dataSettings.company_settings__set_req_dir_razvit_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_dir_razvit_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_otd_kadry_email"
                      input-name="company_settings__set_req_otd_kadry_email"
                      :value="
                        dataSettings.company_settings__set_req_otd_kadry_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_otd_kadry_email
                      "
                    ></BaseInput>
                    <BaseInput
                      input-ref="company_setting_2"
                      input-name="company_setting_2"
                      :value="dataSettings.company_setting_2"
                      @onInput="updateValue"
                      :name-sign="dataLang?.company_setting_2"
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_office_manag_email"
                      input-name="company_settings__set_req_office_manag_sms"
                      :value="
                        dataSettings.company_settings__set_req_office_manag_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_office_manag_email
                      "
                    ></BaseInput>
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_req_control_kach_email"
                      input-name="company_settings__set_req_control_kach_sms"
                      :value="
                        dataSettings.company_settings__set_req_control_kach_email
                      "
                      @onInput="updateValue"
                      :name-sign="
                        dataLang?.company_settings__set_req_control_kach_email
                      "
                    ></BaseInput>
                  </div>
                </div>
              </div>
              <div class="block-action mt-20">
                <div>
                  <span>Часовой пояс системы</span>
                  <BaseSelect
                    :options="selectOptionsUTC()"
                    :custom-label="(elem) => 'UTC ' + elem"
                    placeholder=""
                    v-model="selectedOptionUTC"
                  >
                    <div slot="no-options">Пока нет опций</div>
                  </BaseSelect>
                </div>
                <!-- <div class="mb-5">
                  <BaseInput
                    placeholder=""

                    ref="company_settings__set_req_phone_mask"
                    input-name="company_settings__set_req_phone_mask"
                    :value="dataSettings.company_settings__set_req_phone_mask"
                    @onInput="updateValue"
                    name-sign="Телефонная маска системы"
                  ></BaseInput>
                </div> -->
              </div>
              <div class="mb-5 mt-10">
                <span>Файл лого</span>
                <div class="mb-10 mt-10">
                  <a
                    :href="dataSettings.company_settings__set_doc_file_logo"
                    v-show="dataSettings.company_settings__set_doc_file_logo"
                    target="_blank"
                  >
                    {{ dataSettings.company_settings__set_doc_file_logo }}</a
                  >
                  <div class="d-flex gap-10">
                    <label
                      class="btn btn-sm btn-primary"
                      :class="{
                        disabled:
                          dataSettings.company_settings__set_doc_file_logo
                      }"
                      >Добавить файл
                      <input
                        type="file"
                        class="d-none"
                        @change="
                          uploadFile(
                            $event,
                            'company_settings__set_doc_file_logo'
                          )
                        "
                        ref="company_settings__set_doc_file_logo"
                    /></label>
                    <div
                      class="btn btn-sm btn-opposite"
                      :class="{
                        disabled:
                          !dataSettings.company_settings__set_doc_file_logo
                      }"
                      @click="delFile('company_settings__set_doc_file_logo')"
                    >
                      Удалить файл
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="block-action mt-20">
                <span class="fw-bold">{{ dataLang?.set_req_group3 }}</span>
                <BaseInput placeholder=""  ref="company_settings__set_req_otzyv_yandex"
                input-name="company_settings__set_req_otzyv_yandex" :value="dataSettings.company_settings__set_req_otzyv_yandex" @onInput="updateValue"
                :name-sign="dataLang?.company_settings__set_req_otzyv_yandex"></BaseInput>
                <BaseInput placeholder=""  ref="company_settings__set_req_otzyv_google"
                input-name="company_settings__set_req_control_kach_sms" :value="dataSettings.company_settings__set_req_otzyv_google" @onInput="updateValue"
                :name-sign="dataLang?.company_settings__set_req_otzyv_google"></BaseInput>
                <BaseInput placeholder=""  ref="company_settings__set_req_otzyv_bonus"
                input-name="company_settings__set_req_control_kach_sms" :value="dataSettings.company_settings__set_req_otzyv_bonus" @onInput="updateValue"
                :name-sign="dataLang?.company_settings__set_req_otzyv_bonus"></BaseInput>
                <BaseCheckboxV2 placeholder=""  ref="company_settings__set_req_otzyv_uvedoml" class="mt-20"
                input-name="company_settings__set_req_otzyv_uvedoml" v-model="dataSettings.company_settings__set_req_otzyv_uvedoml" @oncheckeds="updateValue">
                  {{ dataLang?.company_settings__set_req_otzyv_uvedoml }}
                </BaseCheckboxV2> -->
              <!-- </div> -->
            </div>
          </div>
        </v-tab>

        <v-tab title="Договоры">
          <div class="d-flex flex-wrap gap-20 pt-20">
            <div class="block-action">
              <span class="fw-bold">{{ dataLang?.set_doc_group1 }}</span>
              <div>
                <div class="mb-5 mt-10">
                  <span>Файл офeрты</span>
                  <div class="mb-10 mt-10">
                    <a
                      :href="dataSettings.company_settings__set_doc_file_offer"
                      v-show="dataSettings.company_settings__set_doc_file_offer"
                      target="_blank"
                    >
                      {{ dataSettings.company_settings__set_doc_file_offer }}</a
                    >
                    <div class="d-flex gap-10">
                      <label
                        class="btn btn-sm btn-primary"
                        :class="{
                          disabled:
                            dataSettings.company_settings__set_doc_file_offer
                        }"
                        >Добавить файл
                        <input
                          type="file"
                          class="d-none"
                          @change="
                            uploadFile(
                              $event,
                              'company_settings__set_doc_file_offer'
                            )
                          "
                          ref="company_settings__set_doc_file_offer"
                      /></label>
                      <div
                        class="btn btn-sm btn-opposite"
                        :class="{
                          disabled:
                            !dataSettings.company_settings__set_doc_file_offer
                        }"
                        @click="delFile('company_settings__set_doc_file_offer')"
                      >
                        Удалить файл
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="dataSettings.company_settings__set_doc_file_offer">
                  <ul class="list-style-none">
                    <li>Скопируйте эту ссылку на файл оферты</li>
                    <li>
                      Зайдите на
                      <a href="http://qrcoder.ru/">http://qrcoder.ru/</a>
                    </li>
                    <li>
                      Вставьте ссылку в строчку, выберете цифру "6" и нажмите
                      "создать QR код", далее кликните на QR код - правой
                      клавишей-сохранить как (сохраните куда-нибудь картинку QR)
                    </li>
                    <li>Полученную картинку загрузите сюда:</li>
                  </ul>

                  <div class="mb-5 mt-10">
                    <span>Файл qrcode</span>
                    <div class="mb-10 mt-10">
                      <a
                        :href="
                          dataSettings.company_settings__set_doc_file_qrcode
                        "
                        v-show="
                          dataSettings.company_settings__set_doc_file_qrcode
                        "
                        target="_blank"
                      >
                        {{
                          dataSettings.company_settings__set_doc_file_qrcode
                        }}</a
                      >
                      <div class="d-flex gap-10">
                        <label
                          class="btn btn-sm btn-primary"
                          :class="{
                            disabled:
                              dataSettings.company_settings__set_doc_file_qrcode
                          }"
                          >Добавить файл
                          <input
                            type="file"
                            class="d-none"
                            @change="
                              uploadFile(
                                $event,
                                'company_settings__set_doc_file_qrcode'
                              )
                            "
                            ref="company_settings__set_doc_file_qrcode"
                        /></label>
                        <div
                          class="btn btn-sm btn-opposite"
                          :class="{
                            disabled:
                              !dataSettings.company_settings__set_doc_file_qrcode
                          }"
                          @click="
                            delFile('company_settings__set_doc_file_qrcode')
                          "
                        >
                          Удалить файл
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="mb-5 mt-40">
                <BaseInput
                  placeholder=""

                  ref="company_settings__set_doc_depozit"
                  input-name="company_settings__set_doc_depozit"
                  :value="dataSettings.company_settings__set_doc_depozit"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_depozit"
                ></BaseInput>
              </div>
              <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""

                  ref="company_settings__set_doc_depozit_back"
                  input-name="company_settings__set_doc_depozit_back"
                  :value="dataSettings.company_settings__set_doc_depozit_back"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_depozit_back"
                ></BaseInput>
              </div>
              <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""

                  ref="company_settings__set_doc_probeg"
                  input-name="company_settings__set_doc_probeg"
                  :value="dataSettings.company_settings__set_doc_probeg"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_probeg"
                ></BaseInput>
              </div>
              <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""

                  ref="company_settings__set_doc_toplivo_1l"
                  input-name="company_settings__set_doc_toplivo_1l"
                  :value="dataSettings.company_settings__set_doc_toplivo_1l"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_toplivo_1l"
                ></BaseInput>
              </div> -->
              <!-- <div class="mb-5 mt-10">
                <BaseInput placeholder=""  ref="company_settings__set_doc_predoplata"
                  input-name="company_settings__set_doc_predoplata" :value="dataSettings.company_settings__set_doc_predoplata" @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_predoplata"></BaseInput>
              </div> -->
              <!-- <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""

                  ref="company_settings__set_doc_shtraf"
                  input-name="company_settings__set_doc_shtraf"
                  :value="dataSettings.company_settings__set_doc_shtraf"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_shtraf"
                ></BaseInput>
              </div> -->
              <div class="mb-5 mt-20 d-flex gap-10 align-items-center">
                <BaseCheckboxV2
                  placeholder=""
                  ref="company_settings__set_doc_avtodelete_fl"
                  input-name="company_settings__set_doc_avtodelete_fl"
                  v-model="dataSettings.company_settings__set_doc_avtodelete_fl"
                  @oncheckeds="updateValue"
                >
                  {{ dataLang?.company_settings__set_doc_avtodelete_fl }}
                </BaseCheckboxV2>
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_doc_avtodelete_fl_count"
                  class="wp-10"
                  input-name="company_settings__set_doc_avtodelete_fl_count"
                  :value="
                    dataSettings.company_settings__set_doc_avtodelete_fl_count
                  "
                  @onInput="updateValue"
                ></BaseInput>
              </div>
              <div class="mb-5 mt-10 d-flex gap-10 align-items-center">
                <BaseCheckboxV2
                  placeholder=""
                  ref="company_settings__set_doc_avtodelete_ul"
                  input-name="company_settings__set_doc_avtodelete_ul"
                  v-model="dataSettings.company_settings__set_doc_avtodelete_ul"
                  @oncheckeds="updateValue"
                >
                  {{ dataLang?.company_settings__set_doc_avtodelete_ul }}
                </BaseCheckboxV2>
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_doc_avtodelete_ul_count"
                  class="wp-10"
                  input-name="company_settings__set_doc_avtodelete_ul_count"
                  :value="
                    dataSettings.company_settings__set_doc_avtodelete_ul_count
                  "
                  @onInput="updateValue"
                ></BaseInput>
              </div>
            </div>
            <div class="block-action">
              <span class="fw-bold">{{ dataLang?.set_doc_group2 }}</span>
              <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_doc_premiya_n"
                  input-name="company_settings__set_doc_premiya_n"
                  :value="dataSettings.company_settings__set_doc_premiya_n"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_premiya_n"
                ></BaseInput>
              </div>
              <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_doc_premiya_p"
                  input-name="company_settings__set_doc_premiya_p"
                  :value="dataSettings.company_settings__set_doc_premiya_p"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_premiya_p"
                ></BaseInput>
              </div>
              <div class="mb-5 mt-10">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_doc_premiya_strax"
                  input-name="company_settings__set_doc_premiya_strax"
                  :value="dataSettings.company_settings__set_doc_premiya_strax"
                  @onInput="updateValue"
                  :name-sign="dataLang?.company_settings__set_doc_premiya_strax"
                ></BaseInput>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Клиенты">
          <!-- <div class="block-action mt-20">
            <span class="fw-bold">{{ dataLang?.set_client_group1 }}</span>
            <div class="mb-5 mt-10">
              <BaseInput
                class="align-items-center flex-row gap-10 wp-15 white-space-pre"
                placeholder=""

                ref="company_settings__set_client_72hous"
                input-name="company_settings__set_client_72hous"
                :value="dataSettings.company_settings__set_client_72hous"
                @onInput="updateValue"
                :name-sign="dataLang?.company_settings__set_client_72hous"
              ></BaseInput>
            </div>

            <div class="mb-5 mt-10">
              <BaseInput
                class="align-items-center flex-row gap-10 wp-15 white-space-pre"
                placeholder=""

                ref="company_settings__set_client_168hous"
                input-name="company_settings__set_client_168hous"
                :value="dataSettings.company_settings__set_client_168hous"
                @onInput="updateValue"
                :name-sign="dataLang?.company_settings__set_client_168hous"
              ></BaseInput>
            </div>
          </div> -->
          <div class="block-action mt-20">
            <div
              class="align-items-center d-flex flex-row gap-10 white-space-pre"
            >
              <span class="fw-bold">{{
                dataLang?.company_settings__set_client_avto_delete
              }}</span>
              <div class="mb-5 mt-10 wp-5">
                <BaseInput
                  placeholder=""
                  ref="company_settings__set_client_avto_delete"
                  input-name="company_settings__set_client_avto_delete"
                  :value="dataSettings.company_settings__set_client_avto_delete"
                  @onInput="updateValue"
                ></BaseInput>
              </div>
            </div>
          </div>
          <div class="block-action mt-20">
            <span class="fw-bold">{{ dataLang?.set_client_group2 }}</span>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="company_settings__set_client_days_last_doc"
                input-name="company_settings__set_client_days_last_doc"
                :value="dataSettings.company_settings__set_client_days_last_doc"
                @onInput="updateValue"
                inputStyle="width: 100px"
                :name-sign="
                  dataLang?.company_settings__set_client_days_last_doc
                "
              ></BaseInput>
            </div>
          </div>
          <div class="block-action mt-20">
            <span class="fw-bold">{{ dataLang?.set_client_group3 }}</span>
            <div class="mb-5 mt-10">
              <BaseCheckboxV2
                placeholder=""
                ref="company_settings__set_client_is_avtoreset_verif"
                class="mt-20"
                input-name="company_settings__set_client_is_avtoreset_verif"
                v-model="
                  dataSettings.company_settings__set_client_is_avtoreset_verif
                "
                @oncheckeds="updateValue"
              >
                {{ dataLang?.company_settings__set_client_is_avtoreset_verif }}
              </BaseCheckboxV2>
            </div>
            <div class="mb-5 mt-10">
              <BaseCheckboxV2
                placeholder=""
                ref="company_settings__set_client_is_intervalreset_verif"
                class="mt-20"
                input-name="company_settings__set_client_is_intervalreset_verif"
                inputStyle="width: 100px"
                v-model="
                  dataSettings.company_settings__set_client_is_intervalreset_verif
                "
                @oncheckeds="updateValue"
              >
                {{
                  dataLang?.company_settings__set_client_is_intervalreset_verif
                }}
              </BaseCheckboxV2>
            </div>
            <div class="mb-5 mt-10 wp-5 mx-30">
              <BaseInput
                placeholder=""
                ref="company_settings__verification_count_days"
                input-name="company_settings__verification_count_days"
                :value="dataSettings.company_settings__verification_count_days"
                @onInput="updateValue"
                inputStyle="width: 100px"
              ></BaseInput>
            </div>
          </div>
          <div class="block-action mt-20">
            <div class="mb-5">
              <BaseCheckboxV2
                placeholder=""
                ref="company_settings__set_client_sms_negative_balans"
                class="mt-20"
                input-name="company_settings__set_client_sms_negative_balans"
                v-model="
                  dataSettings.company_settings__set_client_sms_negative_balans
                "
                @oncheckeds="updateValue"
              >
                <span class="fw-bold">{{
                  dataLang?.company_settings__set_client_sms_negative_balans
                }}</span>
              </BaseCheckboxV2>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="company_settings__set_client_sms_negative_balans_text"
                input-name="company_settings__set_client_sms_negative_balans_text"
                :value="
                  dataSettings.company_settings__set_client_sms_negative_balans_text
                "
                @onInput="updateValue"
                ><span
                  >{{
                    dataLang[
                      'company_settings__set_client_sms_negative_balans_text'
                    ]
                  }}
                  ({{
                    countersList
                      .company_settings__set_client_sms_negative_balans_text
                      .length
                  }}
                  символа,
                  {{
                    Math.ceil(
                      countersList
                        .company_settings__set_client_sms_negative_balans_text
                        .length / 70
                    )
                  }}
                  SMS)</span
                >
              </BaseInput>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="company_settings__set_client_sms_negative_balans_povtory"
                input-name="company_settings__set_client_sms_negative_balans_povtory"
                inputStyle="width: 100px"
                :value="
                  dataSettings.company_settings__set_client_sms_negative_balans_povtory
                "
                @onInput="updateValue"
                :name-sign="
                  dataLang?.company_settings__set_client_sms_negative_balans_povtory
                "
              ></BaseInput>
            </div>
            <div class="mb-5 mt-10">
              <span>{{
                dataLang[
                  'company_settings__set_client_sms_negative_balans_time'
                ]
              }}</span
              ><br />
              <BaseDatePicker
                v-model="
                  dateList.company_settings__set_client_sms_negative_balans_time
                "
                type="time"
                class="fsc-11"
                lang="ru"
                style="width: 250px"
                value-type="format"
                :time-title-format="userSetting.company__tempalte_date"
                :format="`HH:mm`"
                @change="
                  changeDate(
                    $event,
                    'company_settings__set_client_sms_negative_balans_time'
                  )
                "
              ></BaseDatePicker>
              <!-- <BaseInput
                placeholder=""

                ref="company_settings__set_client_sms_negative_balans_time"
                input-name="company_settings__set_client_sms_negative_balans_time"
                :value="
                  dataSettings.company_settings__set_client_sms_negative_balans_time
                "
                @onInput="updateValue"
                :name-sign="
                  dataLang?.company_settings__set_client_sms_negative_balans_time
                "
              ></BaseInput> -->
            </div>
          </div>
          <div class="block-action mt-20">
            <div class="mb-5">
              <BaseCheckboxV2
                placeholder=""
                ref="company_settings__set_client_sms_over_doc"
                class="mt-20"
                input-name="company_settings__set_client_sms_over_doc"
                v-model="dataSettings.company_settings__set_client_sms_over_doc"
                @oncheckeds="updateValue"
              >
                <span class="fw-bold">{{
                  dataLang?.company_settings__set_client_sms_over_doc
                }}</span>
              </BaseCheckboxV2>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="company_settings__set_client_sms_over_doc_text"
                input-name="company_settings__set_client_sms_over_doc_text"
                :value="
                  dataSettings.company_settings__set_client_sms_over_doc_text
                "
                @onInput="updateValue"
              >
                <span
                  >{{
                    dataLang['company_settings__set_client_sms_over_doc_text']
                  }}
                  ({{
                    countersList.company_settings__set_client_sms_over_doc_text
                      .length
                  }}
                  символа,
                  {{
                    Math.ceil(
                      countersList
                        .company_settings__set_client_sms_over_doc_text.length /
                        70
                    )
                  }}
                  SMS)</span
                >
              </BaseInput>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="company_settings__set_client_sms_over_doc_interval"
                inputStyle="width: 100px"
                input-name="company_settings__set_client_sms_over_doc_interval"
                :value="
                  dataSettings.company_settings__set_client_sms_over_doc_interval
                "
                @onInput="updateValue"
                :name-sign="
                  dataLang?.company_settings__set_client_sms_over_doc_interval
                "
              ></BaseInput>
            </div>
            <div class="mb-5 mt-10">
              <span>{{
                dataLang['company_settings__set_client_sms_over_doc_time']
              }}</span
              ><br />
              <BaseDatePicker
                v-model="
                  dateList.company_settings__set_client_sms_over_doc_time
                "
                type="time"
                class="fsc-11"
                lang="ru"
                style="width: 250px"
                value-type="format"
                :time-title-format="userSetting.company__tempalte_date"
                :format="`HH:mm`"
                @change="
                  changeDate(
                    $event,
                    'company_settings__set_client_sms_over_doc_time'
                  )
                "
              ></BaseDatePicker>
              <!-- <BaseInput
                placeholder=""

                ref="company_settings__set_client_sms_over_doc_time"
                input-name="company_settings__set_client_sms_over_doc_time"
                :value="
                  dataSettings.company_settings__set_client_sms_over_doc_time
                "
                @onInput="updateValue"
                :name-sign="
                  dataLang?.company_settings__set_client_sms_over_doc_time
                "
              ></BaseInput> -->
            </div>
          </div>
          <div class="block-action mt-20">
            <div class="mb-5">
              <BaseCheckboxV2
                placeholder=""
                ref="company_settings__set_client_sms_dr"
                class="mt-20"
                input-name="company_settings__set_client_sms_dr"
                v-model="dataSettings.company_settings__set_client_sms_dr"
                @oncheckeds="updateValue"
              >
                <span class="fw-bold">{{
                  dataLang?.company_settings__set_client_sms_dr
                }}</span>
              </BaseCheckboxV2>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="company_settings__set_client_sms_dr_text"
                input-name="company_settings__set_client_sms_dr_text"
                :value="dataSettings.company_settings__set_client_sms_dr_text"
                @onInput="updateValue"
              >
                <span
                  >{{
                    dataLang['company_settings__set_client_sms_dr_text']
                  }}
                  ({{
                    countersList.company_settings__set_client_sms_dr_text.length
                  }}
                  символа,
                  {{
                    Math.ceil(
                      countersList.company_settings__set_client_sms_dr_text
                        .length / 70
                    )
                  }}
                  SMS)</span
                >
              </BaseInput>
            </div>
            <div class="mb-5 mt-10">
              <span>{{
                dataLang['company_settings__set_client_sms_over_doc_time']
              }}</span
              ><br />
              <BaseDatePicker
                v-model="dateList.company_settings__set_client_sms_dr_time"
                type="time"
                class="fsc-11"
                lang="ru"
                style="width: 250px"
                value-type="format"
                :time-title-format="userSetting.company__tempalte_date"
                :format="`HH:mm`"
                @change="
                  changeDate($event, 'company_settings__set_client_sms_dr_time')
                "
              ></BaseDatePicker>
              <!-- <BaseInput
                placeholder=""

                ref="company_settings__set_client_sms_dr_time"
                input-name="company_settings__set_client_sms_dr_time"
                :value="dataSettings.company_settings__set_client_sms_dr_time"
                @onInput="updateValue"
                :name-sign="dataLang?.company_settings__set_client_sms_dr_time"
              ></BaseInput> -->
            </div>
          </div>

          <div class="block-action mt-20">
            <div class="mb-5">
              <BaseCheckboxV2
                placeholder=""
                ref="company_settings__set_client_sms_dr7"
                class="mt-20"
                input-name="company_settings__set_client_sms_dr7"
                v-model="dataSettings.company_settings__set_client_sms_dr7"
                @oncheckeds="updateValue"
              >
                <span class="fw-bold">{{
                  dataLang?.company_settings__set_client_sms_dr7
                }}</span>
              </BaseCheckboxV2>
            </div>
            <div class="mb-5 mt-10">
              <BaseInput
                placeholder=""
                ref="company_settings__set_client_sms_dr7_text"
                input-name="company_settings__set_client_sms_dr7_text"
                :value="dataSettings.company_settings__set_client_sms_dr7_text"
                @onInput="updateValue"
              >
                <span
                  >{{
                    dataLang['company_settings__set_client_sms_dr7_text']
                  }}
                  ({{
                    countersList.company_settings__set_client_sms_dr7_text
                      .length
                  }}
                  символа,
                  {{
                    Math.ceil(
                      countersList.company_settings__set_client_sms_dr7_text
                        .length / 70
                    )
                  }}
                  SMS)</span
                >
              </BaseInput>
            </div>
            <div class="mb-5 mt-10">
              <span>{{
                dataLang['company_settings__set_client_sms_dr7_time']
              }}</span
              ><br />
              <BaseDatePicker
                v-model="dateList.company_settings__set_client_sms_dr7_time"
                type="time"
                class="fsc-11"
                lang="ru"
                style="width: 250px"
                value-type="format"
                :time-title-format="userSetting.company__tempalte_date"
                :format="`HH:mm`"
                @change="
                  changeDate(
                    $event,
                    'company_settings__set_client_sms_dr7_time'
                  )
                "
              ></BaseDatePicker>
              <!-- <BaseInput
                placeholder=""

                ref="company_settings__set_client_sms_dr7_time"
                input-name="company_settings__set_client_sms_dr7_time"
                :value="dataSettings.company_settings__set_client_sms_dr7_time"
                @onInput="updateValue"
                :name-sign="dataLang?.company_settings__set_client_sms_dr7_time"
              ></BaseInput> -->
            </div>
          </div>
        </v-tab>

        <v-tab title="Заказы">
          <Development class="pt-20" :title="titleDevelopment"></Development>
        </v-tab>

        <v-tab title="Финансы">
          <div class="d-flex flex-wrap gap-20 pt-20">
            <FundsSettings></FundsSettings>
          </div>
        </v-tab>

        <v-tab title="Транспорт">
          <div class="block-action mt-20">
            <div class="d-flex gap-10 align-items-center">
              <div>Актуализация проега, суток от последнего обновления</div>
              <BaseInput
                placeholder=""
                ref="company_settings__set_vehicle_upmileage"
                class="wp-5"
                input-name="company_settings__set_vehicle_upmileage"
                :value="dataSettings.company_settings__set_vehicle_upmileage"
                @onInput="updateValue"
              ></BaseInput>
            </div>
          </div>
          <div class="block-action mt-20">
            <span class="fw-bold">Данные по умолчанию</span>
            <div class="mb-5 mt-10 d-flex gap-10 align-items-center">
              <div>Интервал замены масла ДВС</div>
              <BaseInput
                placeholder=""
                ref="company_settings__set_vehicle_interval_to_dvs"
                input-name="company_settings__set_vehicle_interval_to_dvs"
                :value="
                  dataSettings.company_settings__set_vehicle_interval_to_dvs
                "
                @onInput="updateValue"
              ></BaseInput>
            </div>
            <div class="mb-5 mt-10 d-flex gap-10 align-items-center">
              <div>Интервал замены масла АКПП</div>
              <BaseInput
                placeholder=""
                ref="company_settings__set_vehicle_interval_to_akpp"
                input-name="company_settings__set_vehicle_interval_to_akpp"
                :value="
                  dataSettings.company_settings__set_vehicle_interval_to_akpp
                "
                @onInput="updateValue"
              ></BaseInput>
            </div>
          </div>
          <div class="block-action mt-20">
            <div class="mb-5">
              <BaseCheckboxV2
                placeholder=""
                ref="company_settings__set_vehicle_satatus_tire_rp"
                input-name="company_settings__set_vehicle_satatus_tire_rp"
                v-model="
                  dataSettings.company_settings__set_vehicle_satatus_tire_rp
                "
                @oncheckeds="updateValue"
              >
                <span class="fw-bold">Сезоный шиномантаж</span>
              </BaseCheckboxV2>
            </div>
            <div
              class="mb-5 mt-10 d-flex gap-10 align-items-center disble-year"
            >
              <div>Дата смены на летнюю резину</div>
              <BaseDatePicker
                class="wp-10"
                lang="ru"
                v-model="setVehicleSummerTireRpDate"
                alue-type="format"
                type="date"
                format="DD MMM"
                no-title
                popup-class="disabled-year"
                @change="
                  changeDate(
                    $event,
                    'company_settings__set_vehicle_summer_tire_rp_date'
                  )
                "
              ></BaseDatePicker>
            </div>
            <div
              class="mb-5 mt-10 d-flex gap-10 align-items-center disble-year"
            >
              <div>Дата смены на зимнюю резину</div>
              <BaseDatePicker
                class="wp-10"
                popup-class="disabled-year"
                lang="ru"
                v-model="setVehicleWinterTireRpDate"
                alue-type="format"
                format="DD MMM"
                @change="
                  changeDate(
                    $event,
                    'company_settings__set_vehicle_winter_tire_rp_date'
                  )
                "
              ></BaseDatePicker>
            </div>
          </div>
        </v-tab>

        <v-tab title="Безопасность">
          <div class="block-action mt-20">
            <div class="d-flex gap-10">
              <!-- <div> -->
              <!-- <div class="block-action">
                  <div class="fw-bold mt-10">Тип входа в систему</div>
                  <div class="mb-5 mt-10">
                    <BaseRadio value="one-time" v-model="dataSettings.company_settings__set_security_type_password" input-name="company_settings__set_security_type_password">
                      Разовый пароль
                    </BaseRadio>
                  </div>
                  <div class="mb-5 mt-10">
                    <BaseRadio value="static" v-model="dataSettings.company_settings__set_security_type_password" input-name="company_settings__set_security_type_password">
                      Постоянный пароль
                    </BaseRadio>
                  </div>
                </div> -->
              <div class="block-action">
                <div class="fw-bold mt-10">
                  Пароль на все операции под замком
                </div>
                <div class="mb-5 mt-10">
                  <BaseInput
                    placeholder=""
                    ref="company_settings__set_security_pass_for_forbidden"
                    class="align-items-center flex-row gap-10 white-space-pre"
                    input-name="company_settings__set_security_pass_for_forbidden"
                    :value="
                      dataSettings.company_settings__set_security_pass_for_forbidden
                    "
                    @onInput="updateValue"
                    name-sign="Пароль"
                  ></BaseInput>
                </div>
              </div>
              <!-- </div> -->

              <div class="">
                <div class="block-action">
                  <div class="mb-5 mt-10">
                    <BaseInput
                      placeholder=""
                      ref="company_settings__set_security_idle_time"
                      class="align-items-center flex-row gap-10 white-space-pre"
                      input-name="company_settings__set_security_idle_time"
                      :value="
                        dataSettings.company_settings__set_security_idle_time
                      "
                      @onInput="updateValue"
                      name-sign="Разлогинить пользователя при бездействии, минут"
                    ></BaseInput>
                  </div>

                  <div class="mb-5 mt-20">
                    <div class="btn btn-sm btn-primary">
                      Разлогинить пользователей crm сейчас
                    </div>
                  </div>
                </div>

                <div class="block-action mb-5 mt-10 py-40">
                  <BaseCheckboxV2
                    placeholder=""
                    ref="company_settings__set_security_allow_only_admin_auth"
                    input-name="company_settings__set_security_allow_only_admin_auth"
                    v-model="
                      dataSettings.company_settings__set_security_allow_only_admin_auth
                    "
                    @oncheckeds="updateValue"
                  >
                    Запретить вход всем, кроме админа
                  </BaseCheckboxV2>
                </div>
              </div>
              <div class="block-action">
                <div class="btn btn-sm btn-primary mt-10 mb-5">
                  История входа в систему за последние 30 суток
                </div>
              </div>
            </div>
          </div>
          <div class="block-action mt-20">
            <div class="fw-bold mt-10">
              Выбор службы безопасности для проверок клиентов
            </div>
            <div class="mb-5 mt-10">
              <BaseRadio
                inputName="company_settings__type_security"
                name="company_settings__type_security"
                :value="'yandexrent_security_client'"
                @change="updateValue"
                v-model="dataSettings.company_settings__type_security"
              >
                СБ 1 (SIRIUS)
              </BaseRadio>
            </div>
            <div class="mb-5 mt-10">
              <BaseRadio
                inputName="company_settings__type_security"
                name="company_settings__type_security"
                :value="'base_security_client'"
                @change="updateValue"
                v-model="dataSettings.company_settings__type_security"
              >
                СБ 2 (ВНУТРЕННЯЯ)
              </BaseRadio>

              <!-- <BaseRadio
                  :value="2"
                  class="mb-5"
                  signClass="fsc-11"
                  v-model="dataForModal.vehicles__rent_sgt"
                  input-name="vehicles__rent_sgt"
                  @change="updateValue"
                  >{{ dataLang['register_objects_car_text_33'] }}</BaseRadio
                > -->
            </div>
            <SecureSBGroupsSettings></SecureSBGroupsSettings>
          </div>
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import ModalAll from '@/components/ModalAll.vue'
import BaseInput from '@/components/ui/BaseInput.vue'
import FundsSettings from '@/components/global_settings/FundsSettings.vue'
import SecureSBGroupsSettings from '@/components/global_settings/SecureSBGroupsSettings.vue'
import TheTimeline from '@/components/TheTimeline.vue'
import LineChart from '@/components/Chart.vue'
import Development from '@/components/Development.vue'
import ImageUploadPreview from '@/components/ImageUploadPreview.vue'
import StorageFileService from '@/api/StorageFileService.js'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import { mapActions, mapState, mapMutations } from 'vuex'
import { forEach } from 'lodash'
import BaseCheckboxV2 from '@/components/ui/BaseCheckboxV2.vue'
export default {
  components: {
    BaseInput,
    TheTimeline,
    ModalAll,
    LineChart,
    ImageUploadPreview,
    VueTabs,
    VTab,
    Development,
    BaseCheckboxV2,
    FundsSettings,
    SecureSBGroupsSettings
  },

  props: {},
  data() {
    return {
      countersList: {
        company_settings__set_client_sms_negative_balans_text: '',
        company_settings__set_client_sms_over_doc_text: '',
        company_settings__set_client_sms_dr_text: '',
        company_settings__set_client_sms_dr7_text: ''
      },
      dateList: {
        company_settings__set_client_sms_negative_balans_time: '',
        company_settings__set_client_sms_over_doc_time: '',
        company_settings__set_client_sms_dr_time: '',
        company_settings__set_client_sms_dr7_time: ''
      },
      updatedValues: {},
      timeout: null,
      monthSelectOption: [],
      daySelectOption: [],
      titleDevelopment: 'Раздел будет разработан в скором времени...'
    }
  },

  methods: {
    changeDate(date, name) {
      if (!date) date = ''
      this.updateValue({ name: name, value: date })
    },
    uploadFile(e, key) {
      let formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('type', 'file')
      StorageFileService.upload(formData)
        .then((data) => {
          console.log(data)
          this.update_setting({ key: key, value: data.data.data.url })
          this.updateValue({ name: key, value: data.data.data.url })
        })
        .catch((err) => {
          console.log(err)
          throw new Error(err)
        })
    },

    delFile(name) {
      // this.dataSettings[name] = null
      this.update_setting({ key: name, value: null })
      this.updateValue({ name: name, value: '' })
    },
    ...mapMutations('globalSettingModule', ['update_setting']),
    ...mapActions('globalSettingModule', ['getAll', 'updateSettings']),
    updateValue(data) {
      console.log(data)
      if (data.name in this.countersList) {
        this.countersList[data.name] = data.value
      }
      this.updatedValues[data.name] = data.value
      this.dataSettings[data.name] = data.value
      console.log(this.updatedValues)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.requestToPath, 2000)
    },
    requestToPath() {
      this.updateSettings(this.updatedValues)
      this.updatedValues = {}
    },

    selectOptionsUTC() {
      let options = []
      for (let i = -12; i <= 12; i++) {
        if (i > 0) i = '+' + i
        else i = i + ''
        options.push(i)
      }
      console.log(options)

      return options
    }
    // debounce(data, callback, delay) {
    //   let timeout;
    //   return function () {
    //     clearTimeout(timeout);
    //     timeout = setTimeout(callback, delay);
    //   }
    // }
  },

  mounted() {
    this.$root.$i18n.locale = 'en'
    this.getAll({
      callback: (data) => {
        Object.assign(this.dateList, data)
        for (const key in data) {
          if (key in this.countersList && data[key] !== null) {
            this.countersList[key] = data[key]
            console.log(this.countersList[key])
          }
        }
      }
    })

    console.log(this.dataLang)
  },

  computed: {
    ...mapState('globalSettingModule', ['dataSettings', 'loadStatus']),
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting', 'loadUserSetting']),


    setVehicleSummerTireRpDate: {
      get() {
        if (this.dataSettings.company_settings__set_vehicle_summer_tire_rp_date)
          return new Date(
            this.dataSettings.company_settings__set_vehicle_summer_tire_rp_date
          )
        return ''
      },

      set(v) {
        if (!v) v = ''
        this.update_setting({
          key: 'company_settings__set_vehicle_summer_tire_rp_date',
          value: v
        })
      }
    },

    setVehicleWinterTireRpDate: {
      get() {
        if (this.dataSettings.company_settings__set_vehicle_winter_tire_rp_date)
          return new Date(
            this.dataSettings.company_settings__set_vehicle_winter_tire_rp_date
          )
        return ''
      },

      set(v) {
        if (!v) v = ''
        this.update_setting({
          key: 'company_settings__set_vehicle_winter_tire_rp_date',
          value: v
        })
      }
    },

    selectedOptionUTC: {
      get() {
        return this.dataSettings.company_settings__set_req_uts
      },

      set(v) {
        if (!v) v = ''
        this.update_setting({ key: 'company_settings__set_req_uts', value: v })
        this.updateValue({ name: 'company_settings__set_req_uts', value: v })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .mx-btn-current-year {
//         display: none !important;
//       }

// .mx-btn-current-year {
//     display: none !important;
//   }
// .mx-btn-icon-double-right,
// .mx-btn-icon-double-left {
//     display: none !important;
//   }

// .mx-calendar-panel-month .mx-calendar-header {
//     display: none !important;
//   }
</style>
