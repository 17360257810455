<template>
  <div class="container-fluid px-0" style="height: 600px" ref="vehicleTab">
    <p class="fw-semi-bold fsc-14 mb-10">
      {{ dataLang['vehicle_modal_notes_title'] }}
    </p>
    <div class="d-flex justify-content-between align-items-center pb-10 gap-10">
      <div class="d-flex gap-5">
        <div
          class="base-button base-button--action py-15 gap-15"
          @click="$modal.show('addNoteModal')"
        >
          <img
            :src="require(`@/assets/icons/add.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14"> Добавить</span>
        </div>
        <div
          class="base-button base-button--action py-15 gap-15"
          @click="acceptDelete"
        >
          <img
            :src="require(`@/assets/icons/adddel.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14"> Удалить</span>
        </div>
      </div>
      <div
        class="text-center fsc-10 p-15 bg-system-secondary"
        style="color: black"
      >
        {{ dataLang['vehicle_modal_notes_warning'] }}
      </div>
      <div
        class="d-flex rounded-5 justify-content-center align-items-center text-center bg-primary cursor-pointer h-100"
        style="width: 101px; padding: 10px"
        @click="openModalSingle('zapchastiVehicleModalSingle')"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_bids_parts'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action disabled flex-shrink-0 py-10"
        style="width: 143px"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_bids_slesar'] }}</span
        >
      </div>
      <div
        class="base-button base-button--action disabled flex-shrink-0 py-10"
        style="width: 143px"
      >
        <span class="fsc-12 fw-medium lh-sm py-5">
          {{ dataLang['vehicle_modal_service_btn_bids_kuzov'] }}</span
        >
      </div>
    </div>
    <!-- <div class="d-flex justify-content-between mt-15 mb-5">
      <div class="d-flex gap-15 align-items-center">
        <div
          class="btn btn-sm btn-thrity text-white fsc-14"
          @click="loadData(1)"
        >
          {{ dataLang['register_btn_filter_on'] }}
        </div>
        <div
          class="text-decoration-underline fsc-14 text-dark"
          style="cursor: pointer"
          @click="resetFilter"
        >
          {{ dataLang['register_btn_filter_off'] }}
        </div>
      </div>
    </div> -->
    <ve-table
      style="width: 100%"
      :columns="columns"
      :table-data="dataTable"
      :border-x="true"
      :border-y="true"
      :sort-option="sortOption"
      ref="serviceTable"
      row-key-field-name="vehicle_notes__id"
      :checkbox-option="{
        hideSelectAll: true,
        selectedRowChange: selectedRowChange,
        selectedAllChange: selectedAllChange,
      }"
      v-if="!localLoading"
      :clickHighlight="false"
      :rowStyleOption="{
        hoverHighlight: true,
      }"
      :cellSelectionOption="{ enable: false }"
    />
    <AddNewNoteModalInNotesTab
      :optionalParams="{ id: currentOpenedId }"
    ></AddNewNoteModalInNotesTab>
    <Teleport to="body">
      <ZapchastiVehicleModalSingle
        size="md"
        :optionalParams="{ id: currentOpenedId }"
        :minHeight="320"
        :contentLoaded="loadData"
        nameForAction="zapchastiVehicleModalSingle"
      />
      <SimpleModal
        size="md"
        :minHeight="320"
        nameForAction="acceptDeleteNote"
        :modal-title="dataLang['vehicle_modal_service_modals_title_8']"
      >
        <template v-slot:content
          ><p class="fsc-20 mb-30">
            {{ dataLang['vehicle_modal_service_modals_warning4'] }}
          </p>
          <div class="d-flex justify-content-center gap-30 mt-10 px-30 py-10">
            <div class="base-button py-15" @click="closeDeleteModal">
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
            <div class="btn-primary btn fsc-14 ws-nowrap" @click="deleteRow">
              {{ dataLang['vehile_modal_btn_delete_ok'] }}
            </div>
          </div>
        </template>
      </SimpleModal>
    </Teleport>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import ObjectsService from '@/api/ObjectsService'
import EventBus from '@/event-bus.js'
import MilageVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/MilageVehicleModalSingle.vue'
import OilDvsVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/OilDvsVehicleModalSingle.vue'
import KppDvsVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/KppDvsVehicleModalSingle.vue'
import OtherVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/OtherVehicleModalSingle.vue'
import RefuelingVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/RefuelingVehicleModalSingle.vue'
import TolingVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/TolingVehicleModalSingle.vue'
import WashingVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/WashingVehicleModalSingle.vue'
import ZapchastiVehicleModalSingle from '@/components/vehicles-page-single-modal-inner/ZapchastiVehicleModalSingle.vue'
import AddNewNoteModalInNotesTab from '@/components/vehicles-page-single-modal-inner/AddNewNoteModalInNotesTab.vue'
import SimpleModal from '@/components/vehicles-page-single-modal-inner/SimpleModal.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
export default {
  components: {
    VeTable,
    AddNewNoteModalInNotesTab,
    ZapchastiVehicleModalSingle,
    SimpleModal,
  },

  props: {
    modeOpenModal: {
      type: String,
      default: 'create',
    },

    currentOpenedId: {
      type: Number,
    },
  },
  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      computedAllPage: 1,
      activePagePagination: {},

      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        },
      },
      rowStyleOption: {
        stripe: true,
      },
      cellSelectionOption: {
        enable: false,
      },
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    async loadExel() {
      let dataFrom = await ObjectsService.toExcel(this.currentOpenedId, {
        where: JSON.stringify(this.filter),
      })
      console.log(dataFrom)
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
      console.log(this.activeSelectRowsMultiple)
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys)
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    acceptDelete() {
      console.log(this.activeSelectRowsMultiple)
      if (this.activeSelectRowsMultiple) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDeleteNote',
          mode: 'create',
        })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    closeDeleteModal() {
      EventBus.$emit('closeModal', {
        data: null,
        nameForAction: 'acceptDeleteNote',
        mode: 'create',
      })
      this.activeSelectRowsMultiple = []
    },

    deleteRow() {
      this.$emit('loadingModal', true)
      ObjectsService.delete(
        'notes',
        this.currentOpenedId,
        this.activeSelectRowsMultiple
      )
        .then((data) => {
          console.log(data)
          this.loadData()
          this.activeSelectRowsMultiple = []
          this.closeDeleteModal()
        })
        .catch((err) => {
          this.$emit('loadingModal', false)
          console.log(err)
          this.activeSelectRowsMultiple = []
          this.$toast.error(err.response.data.message)
        })
    },
    openModalSingle(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create',
      })
    },
    loadData(newNumPage) {
      this.$emit('loadingModal', true)
      this.urlToQuaries.params.page = newNumPage
      ObjectsService.getAll(this.urlToQuaries)
        .then((data) => {
          this.$emit('loadingModal', false)
          this.localLoading = false
          this.activePagePagination = data.data.data
          this.computedAllPage = data.data.data.total / data.data.data.limit
          this.dataTable = data.data.data.rows
          this.activeSelectRowsMultiple = null
        })
        .catch((e) => console.log(e))
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData()
      console.log(this.selectsData, this.$refs.serviceTable)
      this.$refs.serviceTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    sortChange(params) {
      console.log(params)
      this.dataTable.sort((a, b) => {
        if (params.vehicle_services__created_at) {
          if (params.vehicle_services__created_at === 'asc') {
            console.log('asc')
            return (
              a.vehicle_services__created_at - b.vehicle_services__created_at
            )
          } else if (params.vehicle_services__created_at === 'desc') {
            console.log('desc')
            return (
              b.vehicle_services__created_at - a.vehicle_services__created_at
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_services__odometr) {
          if (params.vehicle_services__odometr === 'asc') {
            console.log('asc')
            return a.vehicle_services__odometr - b.vehicle_services__odometr
          } else if (params.vehicle_services__odometr === 'desc') {
            console.log('desc')
            return b.vehicle_services__odometr - a.vehicle_services__odometr
          } else {
            return 0
          }
        }
        if (params.vehicle_services__text) {
          if (params.vehicle_services__text === 'asc') {
            console.log('asc')
            return a.vehicle_services__text - b.vehicle_services__text
          } else if (params.vehicle_services__text === 'desc') {
            console.log('desc')
            return b.vehicle_services__text - a.vehicle_services__text
          } else {
            return 0
          }
        }
        if (params.vehicle_services__price) {
          if (params.vehicle_services__price === 'asc') {
            console.log('asc')
            return a.vehicle_services__price - b.vehicle_services__price
          } else if (params.vehicle_services__price === 'desc') {
            console.log('desc')
            return b.vehicle_services__price - a.vehicle_services__price
          } else {
            return 0
          }
        }
        if (params.employees__name) {
          if (params.employees__name === 'asc') {
            console.log('asc')
            return a.employees__name - b.employees__name
          } else if (params.employees__name === 'desc') {
            console.log('desc')
            return b.employees__name - a.employees__name
          } else {
            return 0
          }
        }
      })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter)
    },
    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle']),
  },
  mounted() {
    EventBus.$on('contentLoaded', () => {
      this.loadData()
    })
    dayjs.extend(utc)
  },
  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)
    this.loadData(1)
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    checkboxOption() {
      return {
        hideSelectAll: true,
        selectedRowChange: this.selectedRowChange,
        selectedAllChange: this.selectedAllChange,
        selectedRowKeys: [],
      }
    },
    urlToQuaries() {
      return {
        url: `/${this.currentOpenedId}/notes`,
        params: {
          limit: 15,
          page: 1,
          ...(this.filter.length && { where: JSON.stringify(this.filter) }),
        },
      }
    },
    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: '3%',
          align: 'center',
        },
        {
          field: 'vehicle_notes__created_at',
          key: '1',
          title: this.dataLang['vehicle_modal_notes_table_header_date'],
          align: 'left',
          width: '10%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1,
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.vehicle_notes__created_at
                  ? dayjs(row.vehicle_notes__created_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(
                        `${this.userSetting.company__tempalte_date} HH:mm`
                      )
                  : ''}
              </span>
            )
          },
        },

        {
          field: 'vehicle_notes__note',
          key: '2',
          title: this.dataLang['vehicle_modal_notes_table_header_note'],
          align: 'left',
          width: 70,
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1,
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                {/* <div style={'height: 19.5px'}></div> */}
              </div>
            )
          },
        },
        {
          field: 'employees__name',
          key: '3',
          title: this.dataLang['vehicle_modal_notes_table_header_employee'],
          align: 'left',
          width: '10%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1,
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          },
        },
      ]
    },
  },
}
</script>
