import http from "@/http-common.js";

class ContractCalculationsService {

  basePathApiService = "/contract-calculations/";
 
  getAll(id) {
    return http.get(this.basePathApiService + id);
  } 

}
export default new ContractCalculationsService();
