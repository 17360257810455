<template>
  <div class="container-fluid px-0" style="height: 615px" ref="balanceTab">
    <div
      class="d-flex justify-content-between align-items-center pb-15 pt-15 gap-10"
    >
      <div class="d-flex gap-5 align-items-center">
        <div class="d-flex gap-20 align-items-center me-20">
          <span class="fw-semi-bold fsc-14">{{
            dataLang['clients_register_text_62']
          }}</span>
          <span class="fsc-20" :style="{
            color: dataForTab?.clients__balance >= 0 ? '#23b14d' : 'rgb(255, 36, 0)'
          }">{{
            dataForTab?.clients__balance
          }}</span>
        </div>
        <div
          class="base-button base-button--action py-10 gap-15"
          @click="$modal.show('AddBalanceModal')"
        >
          <span class="fsc-14">
            {{ dataLang['clients_register_text_63'] }}</span
          >
        </div>
        <div
          class="base-button base-button--action py-10 gap-15"
          @click="$modal.show('WriteOffClientsModal')"
        >
          <span class="fsc-14">
            {{ dataLang['clients_register_text_64'] }}</span
          >
        </div>
        <div
          class="base-button base-button--action py-10 gap-15"
          @click="$modal.show('BackBalanceClientsModal')"
        >
          <span class="fsc-14">
            {{ dataLang['clients_register_text_65'] }}</span
          >
        </div>
        <div
          class="base-button base-button--action py-10 gap-5"
          @click="loadExel"
        >
          <img
            :src="require(`@/assets/icons/to-exel.svg`)"
            width="16"
            height="16"
          />
          <span class="fsc-14">
            {{ dataLang['clients_register_text_67'] }}</span
          >
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-15">
      <div class="d-flex gap-15 align-items-center">
        <div
          class="btn btn-sm btn-thrity text-white fsc-14"
          @click="loadData(1)"
        >
          {{ dataLang['register_btn_filter_on'] }}
        </div>
        <div
          class="text-decoration-underline fsc-14 text-dark"
          style="cursor: pointer"
          @click="resetFilter"
        >
          {{ dataLang['register_btn_filter_off'] }}
        </div>
      </div>
      <BasePagination
        :page-count="computedAllPage"
        :pagination-params="activePagePagination"
        :page-range="10"
        :showLimitInput="false"
        :margin-pages="0"
        :break-view-class="''"
        :click-handler="loadData"
        :prev-text="''"
        :next-text="''"
        :container-class="'custom-pagination'"
        :page-class="'custom-pagination__item'"
        :pageLinkClass="'custom-pagination__item-link'"
      >
      </BasePagination>
    </div>

    <ve-table
      style="width: 100%"
      :columns="columns"
      :table-data="dataTable"
      :border-x="true"
      :border-y="true"
      :sort-option="sortOption"
      ref="serviceTable"
      row-key-field-name="client_balance__id"
      :checkbox-option="{
        hideSelectAll: true,
        selectedRowChange: selectedRowChange,
        selectedAllChange: selectedAllChange
      }"
      v-if="!localLoading"
      :clickHighlight="false"
      :rowStyleOption="{
        hoverHighlight: true
      }"
      :cellSelectionOption="{ enable: false }"
    />
    <AddBalanceModal :optionalParams="{ id: tabInfo }"></AddBalanceModal>
    <WriteOffClientsModal
      :optionalParams="{ id: tabInfo }"
    ></WriteOffClientsModal>
    <BackBalanceClientsModal
      :optionalParams="{ id: tabInfo }"
    ></BackBalanceClientsModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import ObjectsService from '@/api/ObjectsService'
import EventBus from '@/event-bus.js'
import GeneralService from '@/api/GeneralService'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AddBalanceModal from './AddBalanceModal.vue'
import WriteOffClientsModal from './WriteOffClientsModal.vue'
import BackBalanceClientsModal from './BackBalanceClientsModal.vue'
export default {
  components: {
    VeTable,
    AddBalanceModal,
    WriteOffClientsModal,
    BackBalanceClientsModal
    // AddNewNoteModalInNotesTab,
    // ZapchastiVehicleModalSingle,
    // SimpleModal
  },

  props: {
    modalInfo: {
      type: Object
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      tabInfo: this.modalInfo,
      computedAllPage: 1,
      activePagePagination: {},

      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        }
      },
      dataForTab: {},
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      }
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    async loadExel() {
      let dataFrom = await GeneralService.toSpecExcel(
        `/clients/${this.modalInfo.id}/table/balance/xlsx`
      )
      console.log(dataFrom)
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
      console.log(this.activeSelectRowsMultiple)
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys)
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    acceptDelete() {
      console.log(this.activeSelectRowsMultiple)
      if (this.activeSelectRowsMultiple) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDeleteNote',
          mode: 'create'
        })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    closeDeleteModal() {
      EventBus.$emit('closeModal', {
        data: null,
        nameForAction: 'acceptDeleteNote',
        mode: 'create'
      })
      this.activeSelectRowsMultiple = []
    },

    deleteRow() {
      this.$emit('loadingModal', true)
      ObjectsService.delete(
        'notes',
        this.currentOpenedId,
        this.activeSelectRowsMultiple
      )
        .then((data) => {
          console.log(data)
          this.loadData()
          this.activeSelectRowsMultiple = []
          this.closeDeleteModal()
        })
        .catch((err) => {
          this.$emit('loadingModal', false)
          console.log(err)
          this.activeSelectRowsMultiple = []
          this.$toast.error(err.response.data.message)
        })
    },
    openModalSingle(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create'
      })
    },
    loadData(newNumPage) {
      this.urlToQuaries.params.page = newNumPage
      GeneralService.get(this.urlToQuaries.url, {
        params: this.urlToQuaries.params
      })
        .then((data) => {
          this.localLoading = false
          this.activePagePagination = data.data.data
          this.computedAllPage = data.data.data.total / data.data.data.limit
          this.dataTable = data.data.data.rows
          this.activeSelectRowsMultiple = null
        })
        .catch((e) => console.log(e))
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData()
      this.$refs.serviceTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    sortChange(params) {
      console.log(params)
      this.dataTable.sort((a, b) => {
        if (params.vehicle_services__created_at) {
          if (params.vehicle_services__created_at === 'asc') {
            console.log('asc')
            return (
              a.vehicle_services__created_at - b.vehicle_services__created_at
            )
          } else if (params.vehicle_services__created_at === 'desc') {
            console.log('desc')
            return (
              b.vehicle_services__created_at - a.vehicle_services__created_at
            )
          } else {
            return 0
          }
        }
        if (params.vehicle_services__odometr) {
          if (params.vehicle_services__odometr === 'asc') {
            console.log('asc')
            return a.vehicle_services__odometr - b.vehicle_services__odometr
          } else if (params.vehicle_services__odometr === 'desc') {
            console.log('desc')
            return b.vehicle_services__odometr - a.vehicle_services__odometr
          } else {
            return 0
          }
        }
        if (params.vehicle_services__text) {
          if (params.vehicle_services__text === 'asc') {
            console.log('asc')
            return a.vehicle_services__text - b.vehicle_services__text
          } else if (params.vehicle_services__text === 'desc') {
            console.log('desc')
            return b.vehicle_services__text - a.vehicle_services__text
          } else {
            return 0
          }
        }
        if (params.vehicle_services__price) {
          if (params.vehicle_services__price === 'asc') {
            console.log('asc')
            return a.vehicle_services__price - b.vehicle_services__price
          } else if (params.vehicle_services__price === 'desc') {
            console.log('desc')
            return b.vehicle_services__price - a.vehicle_services__price
          } else {
            return 0
          }
        }
        if (params.employees__name) {
          if (params.employees__name === 'asc') {
            console.log('asc')
            return a.employees__name - b.employees__name
          } else if (params.employees__name === 'desc') {
            console.log('desc')
            return b.employees__name - a.employees__name
          } else {
            return 0
          }
        }
      })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.mod && { mod: data.mod })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter)
    },
    loadGlobal() {
      GeneralService.get(
        `/clients/${this.tabInfo.id}?joins=statistics,verifications,documents`
      )
        .then((data) => {
          this.dataForTab = data.data.data.rows[0]
          console.log(this.dataForTab)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },
  mounted() {
    this.loadData()
    dayjs.extend(utc)
    EventBus.$on('updateContentData', () => {
      this.loadGlobal()
      this.loadData()
    })
  },
  created() {
    this.loadGlobal()
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    checkboxOption() {
      return {
        hideSelectAll: true,
        selectedRowChange: this.selectedRowChange,
        selectedAllChange: this.selectedAllChange,
        selectedRowKeys: []
      }
    },
    urlToQuaries() {
      return {
        url: `/clients/${this.tabInfo.id}/table/balance`,
        params: {
          limit: 10,
          page: 1,
          ...(this.filter.length && { where: JSON.stringify(this.filter) })
        }
      }
    },
    columns() {
      return [
        {
          field: 'client_balance__created_at',
          key: '1',
          title: this.dataLang['clients_register_text_68'],
          align: 'left',
          width: '10%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like', mod: 'datetimeUTC' }}
                  inputName={'client_balance__created_at'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.client_balance__created_at
                  ? dayjs(row.client_balance__created_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(
                        `${this.userSetting.company__tempalte_date} HH:mm`
                      )
                  : ''}
              </span>
            )
          }
        },

        {
          field: 'client_balance__amount',
          key: '25',
          title: this.dataLang['clients_register_text_69'],
          align: 'left',
          width: '10%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'client_balance__amount'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'client_balance__comment',
          key: '31',
          title: this.dataLang['clients_register_text_70'],
          align: 'left',
          width: '40%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'client_balance__comment'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'client_balance__creator',
          key: '34',
          title: this.dataLang['clients_register_text_71'],
          align: 'left',
          width: '10%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'client_balance__comment'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'client_balance__fin_id',
          key: '23',
          title: this.dataLang['clients_register_text_72'],
          align: 'left',
          width: '10%',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'client_balance__fin_id'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>
