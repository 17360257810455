
import BidsPartsService from "@/api/BidsPartsService.js";
import Vue from "vue";
export const bidsPartsModule = {
  namespaced: true,
  state: () => ({
    bidsPartsData: [],
    bidsPartsLoadStatus: 'request',
    paginationPromocodesStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationPromocodesStateParams.total / state.paginationPromocodesStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.bidsPartsData = [...data];
    },
    create(state, data) {
      state.bidsPartsData.unshift(data[0])
    },
    edit_multiple(state, data) {
      let indexed = state.bidsPartsData.findIndex(x => x.request_service__id == data.id)
      Object.assign(state.bidsPartsData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.bidsPartsData.findIndex(x => x.request_service__id == id)
      state.bidsPartsData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationPromocodesStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.bidsPartsLoadStatus= 'request'
    },
    load_success(state) {
      state.bidsPartsLoadStatus= 'success'
    },
    load_error(state) {
      state.bidsPartsLoadStatus= 'error'
    },
  },
  actions: {
   

    getBidsPartsAll({ commit }, params) {
      commit("load_request");
    
      BidsPartsService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createBidsPartsData({ commit }, createData) {
      
        BidsPartsService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editBidsPartsMultiple({ commit }, {id, dataEdit}) {
        BidsPartsService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteBidsPartsData({ commit }, id) {
      // commit("load_request");
      BidsPartsService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
