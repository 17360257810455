import http from "@/http-common.js";

class WashingsService {
  basePathApiService = "/washings/vehicletype/";

  getAll(vehicleTypeId) {
    return http.get(this.basePathApiService + vehicleTypeId);
  } 
  
  
}
export default new WashingsService();
