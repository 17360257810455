<template>
  <div class="px-30 py-10 h-100" ref="raytingTab">
    <div class="row gx-15">
      <div class="col-6">
        <div class="system-block h-100">
          <p class="fw-bolder fsc-12 mb-10 text-dark">
            {{ dataLang['vehicle_modal_rayting_text5'] }}
          </p>
          <span class="fsc-24"> Нет оценок </span>
        </div>
      </div>
      <div class="col-6">
        <div class="system-block h-100">
          <div class="row gx-30 mb-15">
            <div class="col-6">
              <div
                class="bg-confirmation d-flex justify-content-center align-items-center rounded-5 text-center fw-bold text-white"
                style="height: 195px; font-size: 96px"
              >
                {{ currentRayting }}
              </div>
            </div>
            <div class="col-6">
              <div
                class="d-flex align-items-center text-dark fw-semi-bold"
                style="height: 195px; font-size: 50px; line-height: 62.5px"
              >
                {{ dataLang['vehicle_modal_rayting_text1'] }}
              </div>
            </div>
          </div>
          <p class="fsc-14 mb-30">
            {{ dataLang['vehicle_modal_rayting_text2'] }}
          </p>
          <div class="bg-system-secondary px-30 py-15 text-dark fsc-14">
            <p class="mb-0 fw-semi-bold">
              {{ dataLang['vehicle_modal_rayting_text3'] }}
            </p>
            <span>{{ dataLang['vehicle_modal_rayting_text4'] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ObjectsService from '@/api/ObjectsService'

import AddNewTriesModal from '@/components/vehicles-page-single-modal-inner/AddNewTriesModal.vue'
import httpInstance from '@/http-common.js'
//   import ObjectsService from '@/api/ObjectsService'
//   import EventBus from '@/event-bus.js'
//   import SingleModal from '@/components/SingleModal.vue'
export default {
  components: {
    AddNewTriesModal
  },
  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      loadingState: true,

      currentRayting: 0
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },
  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)

    Promise.all([
      ObjectsService.getAll({
        url: `/${this.currentOpenedId}?fields=vehicles__rayting`
      })
        .then((data) => {
          console.log(data)
          this.currentRayting = data.data.data.rows[0].vehicles__rayting
        })
        .catch((err) => {
          // console.log(err)
        })
    ]).then(() => {
      console.log('This end')
      this.$emit('loadingModal', false)
      this.loadingState = false
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang'])
  }
}
</script>
