import http from "@/http-common.js";

class VehicleMarksService {
  basePathApiService = "/marks";
  getOne(id) {
    return http.get(this.basePathApiService + id);
  }
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  deleteOne(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
}
export default new VehicleMarksService();
