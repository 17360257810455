
import FundsSettingsService from "@/api/FundsSettingsService.js";
import Vue from "vue";
export const fundsSettingsModule = {
  namespaced: true,
  state: () => ({
    fundsSettingsData: [],
    fundsSettingsLoadStatus: 'request',
  }),

  getters: {
   
  },

  mutations: {
    get_all(state, data) {
      state.fundsSettingsData = [...data];
    },

    load_request(state, status) {
      state.fundsSettingsLoadStatus= 'request'
    },
    load_success(state) {
      state.fundsSettingsLoadStatus= 'success'
    },
    load_error(state) {
      state.fundsSettingsLoadStatus= 'error'
    },
  },

  actions: {
    getFundsSettingsAll({ commit }) {
        commit("load_request");
        FundsSettingsService.getAll({ params : {orderBy:"finreestr_funds__id", orderDir: "ASC"}})
          .then((data) => {
            commit("get_all", data.data.data.rows);
            commit("load_request");
            commit("load_success");
          })
          .catch((error) => {
            console.log(error)
            commit("load_request");
            commit("load_error");
          });
    },

    updateFundsSettingsNames({commit}, updateData){
        FundsSettingsService.updateNames(updateData).then(data => {
            Vue.$toast.success('Данные сохранены');
        })
        .catch((error) => {
            Vue.$toast.error(error.response.data.message);
        });
    }
  },
};
