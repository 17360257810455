<template>
    <div>
        <div class="access-block__desc access-block__desc--warn-active" v-if="errorsData" v-for="error in errorsData">
            {{ error }}
        </div>
    </div>
</template>
  
  
<script>
  export default {
    components: {
  
    },
    props: {
      errorsData: {},
    },


  };
</script>
      