
import CompaniesYandexrentService from "@/api/CompaniesYandexrentService.js";
import Vue from "vue";
export const companiesYandexrentModule = {
  namespaced: true,
  state: () => ({
    companiesYandexrentData: [],
    companiesYandexrentLoadStatus: 'request',
    paginationPromocodesStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationPromocodesStateParams.total / state.paginationPromocodesStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.companiesYandexrentData = [...data];
    },
    create(state, data) {
      state.companiesYandexrentData.unshift(data[0])
    },
    edit_multiple(state, data) {
      let indexed = state.companiesYandexrentData.findIndex(x => x.CompaniesYandexrent__id == data.id)
      Object.assign(state.companiesYandexrentData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.companiesYandexrentData.findIndex(x => x.CompaniesYandexrent__id == id)
      state.companiesYandexrentData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationPromocodesStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.companiesYandexrentLoadStatus= 'request'
    },
    load_success(state) {
      state.companiesYandexrentLoadStatus= 'success'
    },
    load_error(state) {
      state.companiesYandexrentLoadStatus= 'error'
    },
  },
  actions: {
   

    getCompaniesYandexrentAll({ commit }, params) {
      commit("load_request");
    
      CompaniesYandexrentService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createCompaniesYandexrentData({ commit }, createData) {
      
        CompaniesYandexrentService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editCompaniesYandexrentMultiple({ commit }, {id, dataEdit}) {
        CompaniesYandexrentService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteCompaniesYandexrentData({ commit }, id) {
      // commit("load_request");
      CompaniesYandexrentService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
