<template>
    <div>
        <BaseTopLine>
            <div class="d-flex gap-10 align-items-center">
                <!-- <div class="btn btn-sm btn-primary" @click="openModalForCreate('academyModal')">Добавить</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div> -->
            </div>
        </BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="AcademyData" :props-columns-setting="fieldsTableAcademy"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="academyModal" v-if="AcademyLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="academyModal" @closeModal="closeModal">
                <div class="p-10">
                    <BaseInput placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.Academy__code"
                        name-sign="Код" input-name="Academy__code">
                    </BaseInput>
                    <BaseTextarea placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.Academy__description"
                        name-sign="Описание" input-name="Academy__description">
                    </BaseTextarea>
                    <BaseInput placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.Academy__starts"
                        name-sign="Дата начала" input-name="Academy__starts" input-type="datetime-local">
                    </BaseInput>
                    <BaseInput placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.Academy__ends"
                        name-sign="Дата конца" input-name="Academy__ends" input-type="datetime-local">
                    </BaseInput>
                    
                    <div class="d-flex gap-10 mt-15">
                        <div class="btn btn-sm btn-primary" @click="sendDataFromModal">{{
                            dataLang.form_btn__btn_save }}</div>
                        <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                            dataLang.form_btn__btn_caneсl }}</div>
                    </div>
                </div>
            </Modal>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getAcademyAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },
        openModalForCreate(nameModal) {
            EventBus.$emit("openModal", { data: null, nameForAction: nameModal, mode: 'create' })
        },


        deleteActionAndModal() {
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },
        deleteRow(nameModal) {
            this.deleteAcademyData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },


        sendDataFromModal() {
            console.log(this.dataForModal);
            if (this.dataForModal.Academy__id) {
                console.log(this.updatedValues);
                this.editAcademyMultiple({ id: this.dataForModal.Academy__id, dataEdit: this.updatedValues })
                EventBus.$emit("closeModal", { nameForAction: 'academyModal' })
            } else {
                this.createAcademyData(this.updatedValues)
                // this.$refs[academyModal].$el.querySelectorAll('input').forEach(elem => {
                //     elem.value = ''
                // })
                
                EventBus.$emit("closeModal", { nameForAction: 'academyModal' })
            }
        },


        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },

        prepareKeysForModal(payloadData) {
            this.updatedValues = {}
            this.dataForModal = {}
            if (!payloadData) {
                for (const key in this.AcademyData[0]) {
                    this.dataForModal[key] = null
                }
            } else {
                this.dataForModal = Object.assign({}, payloadData)
            }

        },

        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
            // this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
            //     element.value = ''
            // })
        },
        btnHandlerCloseModal(nameModal) {
            EventBus.$emit("closeModal", { nameForAction: 'academyModal' })
        },


        ...mapActions("academyModule", ["getAcademyAll", 'createAcademyData', 'editAcademyMultiple', 'deleteAcademyData', 'setAcademyRoute']),


    },
    mounted() {
      
        EventBus.$on("openModal", (payload) => {
            this.editData = payload.data
            console.log(payload.data);
            this.prepareKeysForModal(payload.data)
        });
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.Academy__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getAcademyAll({ params: { page: 1, limit: 10 }})
    },
    computed: {
        ...mapState("academyModule", ["AcademyData", "AcademyLoadStatus", 'paginationAcademyStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("academyModule", ["numPages"]),


        fieldsTableAcademy() {
            return [
                { title: 'ID', field: "docs__id", headerFilter: "input" },
                { title: 'Наименование', field: "docs__name", headerFilter: "input" },
                { title: 'Описание', field: "docs__description", headerFilter: "input" },
                { title: 'Ссылка', field: "docs__link", headerFilter: "input", formatter:"link", formatterParams: {
                    target:"_blank"
                }},
            ]
        }
    },
};
</script>
    
    