<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div
      class="bg-system-primary py-10 px-15 border-top d-flex justify-content-between align-items-end"
      ref="globalDataFilter"
    >
      <div class="">
        <div class="d-flex gap-10">
          <div class="d-flex justify-content-between align-items-center gap-5">
            <span class="fsc-11 text-white input-title">{{
              dataLang['city_delivery_register_text_25']
            }}</span>

            <BaseSelect
              style="width: 173px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="resetSimpleFilter"
              v-model="selectedType"
              class="new-special-select"
              :options="selectsOptions?.type"
              label="label"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <BaseInputNew
            placeholder=""
            width="100"
            class="gap-5"
            v-if="selectedType.value !== 'clients_type_fiz'"
            input-name="clients__ur_inn"
            :horizontalTitle="true"
            signClass="text-white"
            inputClass="bg-transparent text-white"
            value=""
            @input="updateValueFilter"
            :additionalParams="{}"
            inputRef="clients__ur_inn"
            inputMask="0000000000000000"
            maxLength="10"
            :name-sign="dataLang['clients_register_text_159']"
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            v-if="selectedType.value == 'clients_type_fiz'"
            width="290"
            class="gap-5"
            input-name="clients__name"
            @input="updateValueFilter"
            :horizontalTitle="true"
            signClass="text-white"
            inputClass="bg-transparent text-white"
            value=""
            :additionalParams="{}"
            :name-sign="dataLang['clients_register_text_129']"
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            width="100"
            class="gap-5"
            v-if="selectedType.value == 'clients_type_fiz'"
            input-name="clients__fiz_date_birth"
            @input="updateValueFilter"
            :horizontalTitle="true"
            signClass="text-white"
            inputClass="bg-transparent text-white"
            value=""
            :additionalParams="{}"
            inputRef="clients__fiz_date_birth"
            inputMask="00.00.0000"
            maxLength="10"
            :name-sign="dataLang['clients_register_text_125']"
          >
          </BaseInputNew>
          <div
            class="btn btn-sm btn-primary text-black fsc-11 px-30"
            @click="loadData(1, true)"
          >
            {{ dataLang['clients_register_text_106'] }}
          </div>
        </div>
      </div>
      <div style="height: 30px; width: 1px; background-color: white"></div>
      <div class="d-flex align-items-center gap-10">
        <BaseInputNew
          placeholder=""
          width="130"
          class="gap-5"
          input-name="clients__phone"
          @input="updateValueFilter"
          :horizontalTitle="true"
          signClass="text-white"
          inputClass="bg-transparent text-white"
          value=""
          inputRef="clients__phone"
          inputMask="+{7}(000)000-00-00"
          maxLength="16"
          :additionalParams="{}"
          :name-sign="dataLang['clients_register_text_127']"
        >
        </BaseInputNew>
        <div
          class="btn btn-sm btn-primary text-black fsc-11 px-30"
          @click="loadData(1, true)"
        >
          {{ dataLang['clients_register_text_106'] }}
        </div>
      </div>
    </div>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>
      <!-- <div
            v-if="localLoading"
            class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
            style="top: 0; left: 0; z-index: 500"
          >
            <svg class="spinner" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div> -->
      <p class="text-end mb-0 mt-15">
        {{ dataLang['clients_register_text_128'] }}
      </p>
      <div class="d-flex justify-content-between align-items-center mt-5 mb-5">
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationClientBlackStateParams"
          :showLimitInput="false"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="overflow-auto">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="clients__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          :checkbox-option="{
            hideSelectAll: false
          }"
          v-if="!localLoading"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>
    <div
      class="py-10 text-white fsc-12 text-center mt-20"
      style="background-color: #ff2400"
    >
      {{ dataLang['clients_register_text_138'] }}
    </div>
    <div v-if="!localLoading">
      <gallery
        :images="gelleryList"
        :index="galleryExeples"
        @close="onCloseGallery"
      ></gallery>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'
import SmsPresetsModal from './orders-modal/SmsPresetsModal.vue'
import EventBus from '@/event-bus.js'
import SendSomeSms from '@/components/reuse_modal/SendSomeSms.vue'
import { bootstrap, Tooltip } from 'bootstrap'
import EditsTariffModal from './tarrifs-modal/EditsTariffModal.vue'
import TariffModalCreate from './tarrifs-modal/TariffModalCreate.vue'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'

import Vue from 'vue'

import VueGallery from 'vue-gallery'
export default {
  components: {
    VeTable,
    gallery: VueGallery
  },

  data() {
    return {
      dataTimeSelect: { orders_from: [], orders_to: [] },
      selectsData: {
        types: [],
        statuses: [],
        ads: [],
        koef: []
      },
      dataForDatePicker: { orders__ends: [] },
      indexForOpenImage: null,
      gelleryList: [],
      selectsFilter: {
        wanted: []
      },
      selects: [],

      selectsOptions: {
        type: [
          {
            value: 'clients_type_fiz',
            label: 'Частное (физическое) лицо'
          }
        ]
      },
      selectedType: {
        value: 'clients_type_fiz',
        label: 'Частное (физическое) лицо'
      },
      galleryExeples: null,
      messageError: [],
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      activePagePagination: {},
      rowStyleOption: {
        stripe: true
      },
      updateValueFilterObject: {},
      comissionState: false,
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {},
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (
            column.field == 'clients__is_wanted' &&
            Boolean(row.clients__is_wanted)
          ) {
            return 'client-status-danger'
          }
        }
      }
    }
  },
  methods: {
    onCloseGallery() {
      this.gelleryList = []
      this.galleryExeples = null
    },
    updateValueFilter({ value, name }) {
      if (name == 'clients__fiz_date_birth' && value.length > 9) {
        this.updateValueFilterObject[name] = value
          .split('.')
          .reverse()
          .join('-')
      } else {
        console.log(value, name)
        this.updateValueFilterObject[name] = value
      }
    },
    loadData(page, search) {
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/selects/field/clients_types_face`)
          .then((data) => {
            console.log(data)
            this.selectsOptions.type = this.prepareLabelsForSelects(
              data.data.data.rows.clients_types_face
            )
            console.log(this.selectsOptions.type)
          })
          .catch((e) => {})
      ]).then(() => {
        GeneralService.get(`/clients/table/black${search ? '/search' : ''}`, {
          params: {
            ...(this.filter.length && { where: JSON.stringify(this.filter) }),
            ...this.updateValueFilterObject
          }
        })
          .then((data) => {
            console.log(data)
            if (search) {
              this.$toast.success(data.data.message)
            }
            this.getClientBlackAll(data.data.data)
            this.dataTable = data.data.data.rows

            // clients__blacklist_gallery

            console.log(this.gelleryList)
            this.gelleryList = []
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка')
            this.localLoading = false
            this.resetFilter()
          })
      })
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData(1)
      console.log(this.selectsData, this.$refs.serviceTable)
      this.selectsFilter = {
        wanted: []
      }
      this.dataTimeSelect = {
        orders_from: [],
        orders_to: []
      }
      this.updateValueFilterObject = {}
      this.dataForDatePicker = { orders__ends: [] }
      console.log(this.$refs.globalDataFilter)
      this.$refs.globalDataFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },

    resetSimpleFilter() {
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.updateValueFilterObject = {}
      console.log(this.$refs.globalDataFilter)
      this.$refs.globalDataFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.mod == 'phone' && { mod: 'phone' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },

    changeComission(data) {
      GeneralService.edit('/tariffs_car/setcomissiontype', {
        company_settings__tariff_table_comission_custom_or_top_cars: data
      })
        .then((data) => {
          this.loadData()
        })
        .catch((e) => console.log(e))
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    prepareLabelsForSelects(data, keyDataFromFetch) {
      let out = []
      data.forEach((elem, index) => {
        out.push({ value: Object.keys(elem)[0], label: Object.values(elem)[0] })
      })
      console.log(out)
      return out
    },

    ...mapActions('clientBlackPagiation', ['getClientBlackAll'])
  },
  mounted() {},
  created() {
    Promise.all([
      //   }),
    ]).then(() => {
      this.loadData(1)
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('clientBlackPagiation', ['paginationClientBlackStateParams']),
    ...mapGetters('clientBlackPagiation', ['numPages']),
    columns() {
      return [
        {
          field: 'clients__name',
          key: '12',
          title: this.dataLang['clients_register_text_129'],
          align: 'center',
          width: '10%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div class={'h-100'}>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'min-width: 120px'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'clients__name'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },

        {
          field: 'clients__fiz_date_birth',
          key: '13',
          title: this.dataLang['clients_register_text_130'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'min-width: 60px'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'clients__fiz_date_birth'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.clients__fiz_date_birth
                  ? this.$time(row.clients__fiz_date_birth)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(`${this.userSetting.company__tempalte_date}`)
                  : ''}
              </span>
            )
          }
        },
        {
          field: 'clients__phone',
          key: '14',
          title: this.dataLang['clients_register_text_131'],
          align: 'left',
          width: '8%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'min-width: 60px'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'clients__phone'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'companies__name',
          key: '15',
          title: this.dataLang['clients_register_text_132'],
          align: 'center',
          width: '10%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'companies__phone',
          key: '1532',
          title: this.dataLang['clients_register_text_133'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'clients__blacklist_comment',
          key: '16',
          title: this.dataLang['clients_register_text_134'],
          align: 'center',
          width: '18%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },

        {
          field: '',
          key: '1543',
          title: this.dataLang['clients_register_text_135'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let isShow = false
            row.clients__blacklist_gallery.map((item) => {
              if (item.client_blacklist_gallery__file) {
                isShow = true
              }
            })
            return (
              <img
                class={`cursor-pointer ${!isShow ? 'd-none' : ''}`}
                onClick={() => {
                  row.clients__blacklist_gallery.map((item) => {
                    if (item.client_blacklist_gallery__file) {
                      this.gelleryList.push(item.client_blacklist_gallery__file)
                    }
                  })

                  this.galleryExeples = 0
                  console.log(this.galleryExeples)
                }}
                src={require(`@/assets/icons/icon-gallery.svg`)}
              />
            )
          }
        },
        {
          field: 'clients__amount_damge',
          key: '1153',
          title: this.dataLang['clients_register_text_136'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },

        {
          field: 'clients__is_wanted',
          key: '1553',
          title: this.dataLang['clients_register_text_137'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },

          renderBodyCell: ({ row, column, rowIndex }, h) => {
            // Пожалуйста, срочно свяжитесь с инициатором и помогите провести задержание этого клиента! Не говорите клиенту про розыск!
            return (
              <span
                class={'d-flex align-items-center justify-content-center'}
                style={'height: 25px'}
                title={
                  row.clients__is_wanted
                    ? this.dataLang['clients_register_text_170']
                    : this.dataLang['no']
                }
              >
                {row.clients__is_wanted
                  ? this.dataLang['yes']
                  : this.dataLang['no']}
              </span>
            )
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.wanted}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find((x) => x.field == 'clients__is_wanted')
                    ) {
                      this.filter.push({
                        field: 'clients__is_wanted',
                        value: e.value
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'clients__is_wanted'
                        )
                      ].value = e.value
                    }
                  }}
                  style={'min-width: 40px'}
                  label={'label'}
                  placeholder={''}
                  id={'tariff_groups_for_car__name'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    { label: 'Да', value: 1 },
                    { label: 'Нет', value: 0 }
                  ]}
                ></BaseSelect>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>

<style>
.client-status-danger {
  background-color: #ff2400 !important;
  color: white !important;
}
</style>
