export const modalStackModule = {
    namespaced: true,
    state: () => ({
      modalStackPosition: 0,
      modalStackAll: 0,
    }),

    mutations:{
        modal_stack_add(state){
            state.modalStackPosition += 1;
            state.modalStackAll += 1;
        },

        modal_stack_remove(state){
            state.modalStackAll -= 1;
            if(state.modalStackAll == 0){
                state.modalStackPosition = 0;
            }
        }
    }
}