import http from "@/http-common.js";

class ContractCommentsService {

  basePathApiService = "/contract-comments";
 
  create(params) {
    return http.post(this.basePathApiService, params);
  } 
  getAll(id) {
    return http.get(this.basePathApiService + `/contract/` + id);
  } 
  delete(id) {
    return http.get(this.basePathApiService + id)
  }

}
export default new ContractCommentsService();
