<template>
  <form class="access-layout">
    <div class="access-wrapper" ref="wrapperTape">
      <div class="access-tape">
        <div class="access-block">
          <span class="access-block__title">Восстановление пароля</span>
          <div>
            <div class="mb-10 mt-10">
              <input placeholder="Логин*" v-model="login" type="text" class="form-control wp-100" />
            </div>
            <span class="access-block__desc " v-if="successMessage">{{ successMessage }}</span>
            <span class="access-block__desc access-block__desc--warn-active" v-if="authMessage">{{ authMessage }}</span>
            <div class="mt-5">
              <div class="btn btn-primary py-10 wp-100" @click="forwardAuth">
                Получить новый пароль
              </div>
            </div>
            <div class="d-flex flex-column gap-10 justify-content-center">
              <a href="/reg">Регистрация</a>
              <a href="/auth">К авторизации</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</template>
      
      
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AccessService from "@/api/AccessService";
export default {
  components: {},
  props: {},
  data() {
    return {
      login: null,
      successMessage: null,
      authMessage: null
    };
  },
  methods: {

    ...mapActions("accessModule", ["reg", "auth"]),
    ...mapMutations("accessModule", ["auth_status"]),
    forwardAuth() {

      AccessService.passwordRecovery({ login: this.login }).then(data => {
        console.log(data);
        this.authMessage = null
        this.successMessage = data.data.message
        this.login = null
      }).catch(err => {
        console.log(err);
        this.successMessage = null
        this.authMessage = err.response.data.message
      })
      
      // if (this.login && this.password) {
      //   this.$refs.descreg.textContent = ''
      //   this.auth({
      //     login: this.login,

      //   });
      // } else {
      //   this.auth_status('')
      //   this.$refs.descreg.textContent = 'Заполните все обязательные поля'
      // }
    },

  },
  mounted() {

  },
  computed: {
    // ...mapState("accessModule", ['statusAuth',
    //   'statusReg',
    //   'loggedStatus',
    //   'authMessage',
    //   'regMessage']),
  }
};
</script>
      
      