import Vue from 'vue'
import Vuex from 'vuex'
import { accessModule } from '@/store/modules/access-module.js'
import { globalSettingModule } from '@/store/modules/global-setting-module.js'
import { translationModule } from '@/store/modules/translation-module.js'
import { vehicleTypeAndCharacteristicsModule } from '@/store/modules/vehicle-type-and-characteristics-module.js'
import { vehicleMarksModule } from '@/store/modules/vehicle-marks-module.js'
import { storageFileModule } from '@/store/modules/storage-file-module.js'
import { menuModule } from '@/store/modules/menu-module.js'
import { ownersModule } from '@/store/modules/owners-module.js'
import { deliveryModule } from '@/store/modules/delivery-module.js'
import { placesModule } from '@/store/modules/places-module.js'
import { citiesModule } from '@/store/modules/cities-module.js'
import { transportVehiclesModule } from '@/store/modules/transport-vehicles-module.js'
import { passwordsModule } from '@/store/modules/password-module.js'
import { bookPhonesModule } from '@/store/modules/book-phones-module.js'
import { promocodesAdminModule } from '@/store/modules/promocodes-admin-module.js'
import { promocodesModule } from '@/store/modules/promocodes-module.js'
import { tasksModule } from '@/store/modules/tasks-module.js'
import { academyModule } from '@/store/modules/academy-module.js'
import { orgpolicyModule } from '@/store/modules/orgpolicy-module.js'
import { finesModule } from '@/store/modules/fines-module.js'
import { tollRoadsModule } from '@/store/modules/toll-roads-module.js'
import { ordersModule } from '@/store/modules/orders-module.js'
import { priceModule } from '@/store/modules/price-module.js'
import { clientsModule } from '@/store/modules/clients-module.js'
import { finreestrModule } from '@/store/modules/finreestr-module.js'
import { fundsModule } from '@/store/modules/funds-module.js'
import { fundsSettingsModule } from '@/store/modules/funds-settings-module.js'
import { contractsModule } from '@/store/modules/contracts-module.js'
import { summaryModule } from '@/store/modules/summary-module.js'
import { employeesAdminModule } from '@/store/modules/employees-admin-module.js'
import { integrationsModule } from '@/store/modules/integrations-module.js'
import { analiticsModule } from '@/store/modules/analitics-module.js'
import { bidsModule } from '@/store/modules/bids-module.js'
import { bidsOsagoModule } from '@/store/modules/bids-osago-module.js'
import { bidsPartsModule } from '@/store/modules/bids-parts-module.js'
import { bidsFinesModule } from '@/store/modules/bids-fines-module.js'
import { integrationsYandexrentModule } from '@/store/modules/integrations-yandexrent-module.js'
import { companiesYandexrentModule } from '@/store/modules/companies-yandexrent-module.js'
import { fullSummaryModule } from '@/store/modules/full-summary-module.js'
import { secureGroupsModule } from '@/store/modules/secure-groups-module.js'
import { secureClientsFieldsModule } from '@/store/modules/secure-clients-fields-module.js'
import { secureQuestionsModule } from '@/store/modules/secure-questions-module.js'
import { modalStackModule } from '@/store/modules/modal-stack-module.js'
import { sb1Module } from '@/store/modules/sb1-module.js'
import { sb2Module } from '@/store/modules/sb2-module.js'
import { jobtitlesModule } from '@/store/modules/jobtitles-module.js'
import { checklistAdminModule } from '@/store/modules/checklist-admin-module.js'
import { checklistModule } from '@/store/modules/checklist-module.js'
import { adSourceModule } from '@/store/modules/ad-source-module.js'
import { userSettingModule } from '@/store/modules/user-setting-module.js'
import { orderPagiation } from '@/store/modules/order-pagination.js'
import { clientPagiation } from '@/store/modules/clients-pagination.js'
import { citiesPagiation } from '@/store/modules/super-admin-regions-pagination-module.js'
import { clientBlackPagiation } from '@/store/modules/client-black-pagination.js'
import { finesPagination } from '@/store/modules/fine-pagination.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    clientBlackPagiation,
    citiesPagiation,
    orderPagiation,
    accessModule,
    globalSettingModule,
    translationModule,
    vehicleTypeAndCharacteristicsModule,
    vehicleMarksModule,
    storageFileModule,
    menuModule,
    ownersModule,
    deliveryModule,
    placesModule,
    citiesModule,
    transportVehiclesModule,
    passwordsModule,
    bookPhonesModule,
    promocodesAdminModule,
    promocodesModule,
    tasksModule,
    academyModule,
    orgpolicyModule,
    finesModule,
    tollRoadsModule,
    ordersModule,
    priceModule,
    clientsModule,
    finreestrModule,
    fundsModule,
    contractsModule,
    summaryModule,
    employeesAdminModule,
    integrationsModule,
    analiticsModule,
    bidsModule,
    bidsOsagoModule,
    bidsFinesModule,
    bidsPartsModule,
    integrationsYandexrentModule,
    companiesYandexrentModule,
    fullSummaryModule,
    fundsSettingsModule,
    secureGroupsModule,
    secureClientsFieldsModule,
    secureQuestionsModule,
    modalStackModule,
    sb1Module,
    sb2Module,
    jobtitlesModule,
    checklistAdminModule,
    checklistModule,
    adSourceModule,
    userSettingModule,
    clientPagiation,
    finesPagination
  }
})
