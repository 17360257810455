<template>
  <div>
    <BaseTopLine></BaseTopLine>
    <div class="position-relative">
      <BaseLoader v-if="localLoading"></BaseLoader>

      <VueTabs v-model="activateTab" @tab-change="handleTabChange">
        <VTab :title="dataLang['cities_register_text_1']" data-name="cities">
          <div
            class="px-30"
            v-if="dataLang['cities_register_text_1'] == activateTab"
          >
            <div class="d-flex gap-10 align-items-center py-15">
              <div
                class="base-button base-button--action"
                @click="$modal.show('citiesModal')"
              >
                <img
                  :src="require(`@/assets/icons/add.svg`)"
                  width="13.5"
                  height="13.5"
                />
                <span class="fsc-14">{{
                  dataLang['cities_register_text_4']
                }}</span>
              </div>
              <div
                class="base-button base-button--action"
                @click="toArchive('cities')"
              >
                <img
                  :src="require(`@/assets/icons/to-exel.svg`)"
                  width="18"
                  height="18"
                />
                <span class="fsc-14">
                  {{ dataLang['cities_register_text_5'] }}</span
                >
              </div>
            </div>
            <div class="d-flex justify-content-between mb-15">
              <div class="d-flex gap-15 align-items-center">
                <div
                  class="btn btn-sm btn-thrity text-white fsc-14"
                  @click="clickLoadData(1, 'cities')"
                >
                  {{ dataLang['register_btn_filter_on'] }}
                </div>
                <div
                  class="text-decoration-underline fsc-14 text-dark"
                  style="cursor: pointer"
                  @click="resetFilter('cities')"
                >
                  {{ dataLang['register_btn_filter_off'] }}
                </div>
              </div>
              <BasePagination
                :page-count="numPagesCities"
                :pagination-params="paginationCitiesStateParams"
                :showLimitInput="false"
                @updateLimitValue="() => {}"
                :page-range="10"
                :margin-pages="0"
                :break-view-class="''"
                :click-handler="(num) => clickLoadData(num, 'cities')"
                :prev-text="''"
                :next-text="''"
                :container-class="'custom-pagination'"
                :page-class="'custom-pagination__item'"
                :pageLinkClass="'custom-pagination__item-link'"
              ></BasePagination>
            </div>

            <ve-table
              style="width: 100%"
              :columns="columns"
              :table-data="dataTable"
              :border-x="true"
              :border-y="true"
              ref="refTable"
              row-key-field-name="cities__id"
              :eventCustomOption="eventCustomOption"
              v-if="!localLoading"
              :clickHighlight="false"
              :rowStyleOption="{
                hoverHighlight: true
              }"
              :cellSelectionOption="{ enable: false }"
            />
          </div>
          <BaseModal
            name="citiesModal"
            width="420"
            :clickToClose="false"
            @before-open="beforeOpen"
            @before-close="beforeClose"
            :shiftY="0.5"
            :adaptive="true"
            height="auto"
            classes="bg-transparent shadow-none overflow-auto"
          >
            <div class="bg-white rounded-25 py-25 px-20">
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn-close"
                  @click="$modal.hide('citiesModal')"
                ></button>
              </div>
              <p class="text-center fw-semi-bold mb-15">
                {{ dataLang.cities_register_text_10 }}
              </p>
              <div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                  ref="cities__country_id"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_8
                  }}</span>
                  <BaseSelect
                    style="width: 253px"
                    placeholder=""
                    selectLabel=""
                    deselectLabel=""
                    @input="
                      (data) =>
                        setSimpleSelect({
                          key: 'cities__country_id',
                          value: data.countries__id
                        })
                    "
                    v-model="selectsFromModal.countries"
                    :options="selectsData.countries"
                    label="countries__name"
                  >
                    <template slot="noOptions">{{
                      dataLang['select_empty']
                    }}</template>
                    <template slot="noResult">{{
                      dataLang['select_search_empty']
                    }}</template
                    ><template slot="selectedLabel">{{
                      dataLang['select_selected']
                    }}</template>
                    <template slot="deselectLabel">{{
                      dataLang['select_unselect']
                    }}</template>
                  </BaseSelect>
                </div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_9
                  }}</span>
                  <BaseSelect
                    style="width: 253px"
                    placeholder=""
                    selectLabel=""
                    deselectLabel=""
                    noOption=""
                    @input="
                      (data) =>
                        setSimpleSelect({
                          key: 'cities__region_id',
                          value: data.regions__id
                        })
                    "
                    v-model="selectsFromModal.region"
                    :options="selectsData.region"
                    label="regions__name"
                  >
                    <template slot="noOptions">{{
                      dataLang['select_empty']
                    }}</template>
                    <template slot="noResult">{{
                      dataLang['select_search_empty']
                    }}</template
                    ><template slot="selectedLabel">{{
                      dataLang['select_selected']
                    }}</template>
                    <template slot="deselectLabel">{{
                      dataLang['select_unselect']
                    }}</template>
                  </BaseSelect>
                </div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                  ref="cities__name"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_10
                  }}</span>
                  <BaseInputNew
                    placeholder=""
                    width="253"
                    class="lh-1"
                    input-name="cities__name"
                    maxLength="100"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    :value="dataForModal.cities__name"
                    :additionalParams="{}"
                    name-sign=""
                  >
                  </BaseInputNew>
                </div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_20
                  }}</span>
                  <BaseInputNew
                    placeholder=""
                    width="253"
                    class="lh-1"
                    input-name="cities__center_address"
                    maxLength="100"
                    @input="updateValueCitiesCenter"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    :value="valueAddress"
                    @selectData="onSelectFromAddress"
                    :additionalParams="{}"
                    name-sign=""
                    :options="addressOptions"
                    selectKey="formatted_address"
                    selectFocusmethod="input"
                  >
                  </BaseInputNew>
                </div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                  ref="cities__utc"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_11
                  }}</span>
                  <BaseSelect
                    style="width: 253px"
                    placeholder=""
                    selectLabel=""
                    deselectLabel=""
                    @input="
                      (data) =>
                        setSimpleSelect({
                          key: 'cities__utc',
                          value: data.time
                        })
                    "
                    v-model="selectsFromModal.time"
                    :options="selectsData.time"
                    :custom-label="({ time }) => 'UTC' + time"
                  >
                    <template slot="noOptions">{{
                      dataLang['select_empty']
                    }}</template>
                    <template slot="noResult">{{
                      dataLang['select_search_empty']
                    }}</template
                    ><template slot="selectedLabel">{{
                      dataLang['select_selected']
                    }}</template>
                    <template slot="deselectLabel">{{
                      dataLang['select_unselect']
                    }}</template>
                  </BaseSelect>
                </div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_12
                  }}</span>
                  <BaseInputNew
                    placeholder=""
                    width="253"
                    class="lh-1"
                    input-name="cities__subdomain"
                    maxLength="100"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    :value="dataForModal?.cities__subdomain"
                    :additionalParams="{}"
                    name-sign=""
                  >
                  </BaseInputNew>
                </div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_13
                  }}</span>
                  <BaseInputNew
                    placeholder="+7(___)___-__ -__"
                    width="253"
                    class="lh-1"
                    input-name="cities__phone"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    inputRef="cities__phone"
                    maxLength="16"
                    inputMask="+{7}(000)000-00-00"
                    :value="dataForModal?.cities__phone"
                    :additionalParams="{}"
                    name-sign=""
                  >
                  </BaseInputNew>
                </div>
                <div
                  class="d-flex justify-content-between mb-10 align-items-center"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.cities_register_text_16
                  }}</span>
                  <BaseSelect
                    style="width: 253px"
                    placeholder=""
                    selectLabel=""
                    deselectLabel=""
                    openDirection="top"
                    @input="
                      (data) =>
                        setSimpleSelect({
                          key: 'cities__integrator_id',
                          value: data.integrators__id
                        })
                    "
                    v-model="selectsFromModal.integrator"
                    :options="selectsData.integrator"
                    label="company_settings__set_req_company_name"
                  >
                    <template slot="noOptions">{{
                      dataLang['select_empty']
                    }}</template>
                    <template slot="noResult">{{
                      dataLang['select_search_empty']
                    }}</template
                    ><template slot="selectedLabel">{{
                      dataLang['select_selected']
                    }}</template>
                    <template slot="deselectLabel">{{
                      dataLang['select_unselect']
                    }}</template>
                  </BaseSelect>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-10 gap-30">
                <div
                  class="base-button px-20 rounded-5 text-uppercase"
                  @click="sendData(openedType, currentOpenedId)"
                >
                  Создать
                </div>
                <div
                  class="base-button px-20 rounded-5 text-uppercase"
                  @click="$modal.hide('citiesModal')"
                >
                  ОТМЕНА
                </div>
              </div>
            </div>
          </BaseModal>
        </VTab>
        <VTab :title="dataLang['cities_register_text_2']" data-name="regions">
          <div
            style="background-color: #0056af"
            class="py-10 d-flex justify-content-center gap-10 mb-15 linenew"
            v-if="dataLang['cities_register_text_2'] == activateTab"
          >
            <div
              class="d-flex justify-content-between align-items-center gap-5"
              ref="cities__country_id"
            >
              <span class="fsc-11 input-title text-white">{{
                dataLang.cities_register_text_8
              }}</span>
              <BaseSelect
                style="width: 130px"
                placeholder=""
                selectLabel=""
                deselectLabel=""
                class="new-special-select"
                @input="
                  (data) =>
                    setSimpleSelect({
                      key: 'regions__country_id',
                      value: data.countries__id
                    })
                "
                v-model="selectsFromModal.countries"
                :options="selectsData.countries"
                label="countries__name"
              >
                <template slot="noOptions">{{
                  dataLang['select_empty']
                }}</template>
                <template slot="noResult">{{
                  dataLang['select_search_empty']
                }}</template
                ><template slot="selectedLabel">{{
                  dataLang['select_selected']
                }}</template>
                <template slot="deselectLabel">{{
                  dataLang['select_unselect']
                }}</template>
              </BaseSelect>
            </div>
            <div
              class="d-flex justify-content-between align-items-center gap-5"
              ref="regions__name"
            >
              <span class="fsc-11 input-title text-white">{{
                dataLang.cities_register_text_17
              }}</span>
              <BaseInputNew
                placeholder=""
                width="312"
                class="lh-1"
                input-name="regions__name"
                maxLength="100"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="fsc-11"
                inputClass="bg-transparent text-white"
                value=""
                :additionalParams="{}"
                name-sign=""
              >
              </BaseInputNew>
            </div>
            <div
              @click="sendDataFromOtherTab('regions')"
              class="btn btn-sm btn-primary px-20 rounded-5 fsc-11 d-flex align-items-center justify-content-center"
            >
              Добавить
            </div>
          </div>
          <div class="px-30">
            <div class="d-flex justify-content-between mb-15">
              <div class="d-flex gap-15 align-items-center">
                <div
                  class="btn btn-sm btn-thrity text-white fsc-14"
                  @click="clickLoadData(1, 'regions')"
                >
                  {{ dataLang['register_btn_filter_on'] }}
                </div>
                <div
                  class="text-decoration-underline fsc-14 text-dark"
                  style="cursor: pointer"
                  @click="resetFilter('regions')"
                >
                  {{ dataLang['register_btn_filter_off'] }}
                </div>
                <div
                  class="base-button base-button--action"
                  @click="toArchive('cities')"
                >
                  <img
                    :src="require(`@/assets/icons/to-exel.svg`)"
                    width="18"
                    height="18"
                  />
                  <span class="fsc-14">
                    {{ dataLang['cities_register_text_5'] }}</span
                  >
                </div>
              </div>
              <BasePagination
                :page-count="numPagesCities"
                :pagination-params="paginationCitiesStateParams"
                :showLimitInput="false"
                @updateLimitValue="() => {}"
                :page-range="10"
                :margin-pages="0"
                :break-view-class="''"
                :click-handler="(num) => clickLoadData(num, 'regions')"
                :prev-text="''"
                :next-text="''"
                :container-class="'custom-pagination'"
                :page-class="'custom-pagination__item'"
                :pageLinkClass="'custom-pagination__item-link'"
              ></BasePagination>
            </div>

            <ve-table
              style="width: 100%"
              :columns="columnsRegion"
              :table-data="dataTable"
              :border-x="true"
              :border-y="true"
              ref="refTable"
              row-key-field-name="cities__id"
              v-if="!localLoading"
              :clickHighlight="false"
              :rowStyleOption="{
                hoverHighlight: true
              }"
              :cellSelectionOption="{ enable: false }"
            />
          </div>
        </VTab>
        <VTab :title="dataLang['cities_register_text_3']" data-name="countries">
          <div v-if="dataLang['cities_register_text_3'] == activateTab">
            <div
              style="background-color: #0056af"
              class="py-10 d-flex justify-content-center gap-10 mb-15 linenew"
            >
              <div
                class="d-flex justify-content-between align-items-center gap-5"
                ref="countries__name"
              >
                <span class="fsc-11 input-title text-white">{{
                  dataLang.cities_register_text_18
                }}</span>
                <BaseInputNew
                  placeholder=""
                  width="130"
                  class="lh-1"
                  input-name="countries__name"
                  maxLength="100"
                  @input="updateValue"
                  :horizontalTitle="true"
                  signClass="fsc-11"
                  inputClass="bg-transparent text-white"
                  value=""
                  :additionalParams="{}"
                  name-sign=""
                >
                </BaseInputNew>
              </div>
              <div
                class="d-flex justify-content-between align-items-center gap-5"
                ref="countries__phone_mask"
              >
                <span class="fsc-11 input-title text-white">{{
                  dataLang.cities_register_text_19
                }}</span>
                <BaseInputNew
                  placeholder=""
                  width="130"
                  class="lh-1"
                  input-name="countries__phone_mask"
                  maxLength="100"
                  @input="updateValue"
                  :horizontalTitle="true"
                  signClass="fsc-11"
                  inputClass="bg-transparent text-white"
                  value=""
                  :additionalParams="{}"
                  name-sign=""
                >
                </BaseInputNew>
              </div>

              <div
                @click="sendDataFromOtherTab('countries')"
                class="btn btn-sm btn-primary px-20 rounded-5 fsc-11 d-flex align-items-center justify-content-center"
              >
                Добавить
              </div>
            </div>
            <div
              class=""
              v-if="dataLang['cities_register_text_3'] == activateTab"
            >
              <div
                style="background-color: #0056af"
                class="py-10 d-flex justify-content-center gap-10 mb-15"
                v-if="dataLang['cities_register_text_2'] == activateTab"
              >
                <div
                  class="d-flex justify-content-between align-items-center gap-5"
                  ref="cities__country_id"
                >
                  <span class="fsc-11 input-title text-white">{{
                    dataLang.cities_register_text_8
                  }}</span>
                  <BaseSelect
                    style="width: 130px"
                    placeholder=""
                    selectLabel=""
                    deselectLabel=""
                    class="new-special-select"
                    @input="
                      (data) =>
                        setSimpleSelect({
                          key: 'regions__country_id',
                          value: data.countries__id
                        })
                    "
                    v-model="selectsFromModal.countries"
                    :options="selectsData.countries"
                    label="countries__name"
                  >
                    <template slot="noOptions">{{
                      dataLang['select_empty']
                    }}</template>
                    <template slot="noResult">{{
                      dataLang['select_search_empty']
                    }}</template
                    ><template slot="selectedLabel">{{
                      dataLang['select_selected']
                    }}</template>
                    <template slot="deselectLabel">{{
                      dataLang['select_unselect']
                    }}</template>
                  </BaseSelect>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center gap-5"
                  ref="regions__name"
                >
                  <span class="fsc-11 input-title text-white">{{
                    dataLang.cities_register_text_17
                  }}</span>
                  <BaseInputNew
                    placeholder=""
                    width="312"
                    class="lh-1"
                    input-name="regions__name"
                    maxLength="100"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="fsc-11"
                    inputClass="bg-transparent text-white"
                    value=""
                    :additionalParams="{}"
                    name-sign=""
                  >
                  </BaseInputNew>
                </div>
                <div
                  @click="sendDataFromOtherTab('countries')"
                  class="btn btn-sm btn-primary px-20 rounded-5 fsc-11 d-flex align-items-center justify-content-center"
                >
                  Добавить
                </div>
              </div>
              <div class="px-30">
                <div class="d-flex justify-content-between mb-15">
                  <div class="d-flex gap-15 align-items-center">
                    <div
                      class="btn btn-sm btn-thrity text-white fsc-14"
                      @click="clickLoadData(1, 'countries')"
                    >
                      {{ dataLang['register_btn_filter_on'] }}
                    </div>
                    <div
                      class="text-decoration-underline fsc-14 text-dark"
                      style="cursor: pointer"
                      @click="resetFilter('countries')"
                    >
                      {{ dataLang['register_btn_filter_off'] }}
                    </div>
                    <div
                      class="base-button base-button--action"
                      @click="toArchive('countries')"
                    >
                      <img
                        :src="require(`@/assets/icons/to-exel.svg`)"
                        width="18"
                        height="18"
                      />
                      <span class="fsc-14">
                        {{ dataLang['cities_register_text_5'] }}</span
                      >
                    </div>
                  </div>
                  <BasePagination
                    :page-count="numPagesCities"
                    :pagination-params="paginationCitiesStateParams"
                    :showLimitInput="false"
                    @updateLimitValue="() => {}"
                    :page-range="10"
                    :margin-pages="0"
                    :break-view-class="''"
                    :click-handler="(num) => clickLoadData(num, 'countries')"
                    :prev-text="''"
                    :next-text="''"
                    :container-class="'custom-pagination'"
                    :page-class="'custom-pagination__item'"
                    :pageLinkClass="'custom-pagination__item-link'"
                  ></BasePagination>
                </div>

                <ve-table
                  style="width: 100%"
                  :columns="columnsCountries"
                  :table-data="dataTable"
                  :border-x="true"
                  :border-y="true"
                  ref="refTable"
                  row-key-field-name="countries__id"
                  v-if="!localLoading"
                  :clickHighlight="false"
                  :rowStyleOption="{
                    hoverHighlight: true
                  }"
                  :cellSelectionOption="{ enable: false }"
                />
              </div>
            </div>
          </div>
        </VTab>
      </VueTabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import NoticesService from '@/api/NoticesService.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import GeneralService from '@/api/GeneralService'
export default {
  components: {
    VeTable,
    VTab,
    VueTabs
  },

  props: {},
  data() {
    return {
      updatedValues: {},
      activeSelectRow: null,
      dataTable: null,
      dataForModal: {},
      dataTableRegion: null,
      localLoading: true,
      activateTab: '',
      limitPaginationValue: 100,
      addressOptions: [],
      valueAddress: '',
      filter: [],
      selectsData: {
        countries: [],
        region: [],
        time: [],
        integrator: []
      },
      selectsFilter: {
        countries: [],
        region: [],
        time: [],
        integrator: []
      },
      selectsFromModal: {
        countries: [],
        region: [],
        time: [],
        integrator: []
      },
      openedType: 'create',
      currentOpenedId: null,
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.currentOpenedId = row.cities__id
              this.openedType = 'edit'

              //               {
              //     "cities__country_id": 1,
              //     "cities__region_id": 1,
              //     "cities__name": "Адыгейй",
              //     "cities__center_address": "1231212",
              //     "cities__utc": "-7",
              //     "cities__subdomain": "123213123",
              //     "cities__phone": "+7(123)123-12-31",
              //     "cities__integrator_id": 2
              // }
              GeneralService.get(
                `/cities?where=[{"field":"cities__id","value":${row.cities__id}}]`
              )
                .then((data) => {
                  this.dataForModal = data.data.data.rows[0]
                  this.valueAddress =
                    data.data.data.rows[0].cities__center_address
                  this.selectsFromModal.countries =
                    this.selectsData.countries.find((x) => {
                      return (
                        x.countries__id ==
                        data.data.data.rows[0].cities__country_id
                      )
                    })
                  this.selectsFromModal.region = this.selectsData.region.find(
                    (x) => {
                      return (
                        x.regions__id ==
                        data.data.data.rows[0].cities__region_id
                      )
                    }
                  )
                  console.log(this.selectsFromModal.region)
                  this.selectsFromModal.time = this.selectsData.time.find(
                    (x) => {
                      return x.time == data.data.data.rows[0].cities__utc
                    }
                  )
                  this.selectsFromModal.integrator =
                    this.selectsData.integrator.find((x) => {
                      return (
                        x.integrators__id ==
                        data.data.data.rows[0].cities__integrator_id
                      )
                    })
                })
                .catch((error) => {
                  console.log(error)
                })
              this.$modal.show('citiesModal', {
                mode: 'edit',
                id: row.cities__id
              })
            }
          }
        }
      },
      timeout: null
    }
  },
  methods: {
    updateValueCitiesCenter(e) {
      this.valueAddress = e.value
      this.updatedValues.cities__center_address = e.value
      this.addressOptions = []
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        let data = await fetch(
          `https://suggest-maps.yandex.ru/v1/suggest?text=${e.value}&apikey=be9de750-f591-4271-82d1-4846630211d1&print_address=1&types=country,house,house,district,geo,street,locality&lang=ru&results=15`
        )
        let dataFromJson = await data.json()
        dataFromJson.results.forEach((elem, index) => {
          this.addressOptions.push(elem.address)
        })
      }, 400)
    },
    onSelectFromAddress(e) {
      console.log(e);
      this.valueAddress = e.value.formatted_address
      this.updatedValues.cities__center_address = e.value.formatted_address
    },
    sendDataFromOtherTab(type) {
      GeneralService.editOrCreate(`/${type}`, {
        ...this.updatedValues
      })
        .then((data) => {
          console.log(data)
          this.clickLoadData(1, type)
          this.$modal.hide('citiesModal')
          this.updatedValues = {}
          this.$toast.success(data.data.message)
          for (const key in this.selectsFromModal) {
            this.selectsFromModal[key] = []
          }
          document.querySelectorAll('.linenew').forEach((element) => {
            element.querySelectorAll('input').forEach((elem) => {
              elem.value = ''
            })
          })
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
          for (const key in this.selectsFromModal) {
            this.selectsFromModal[key] = []
          }
        })
    },
    beforeClose() {
      for (const key in this.selectsFromModal) {
        this.selectsFromModal[key] = []
      }
      this.updatedValues = {}
      this.openedType = 'create'
      this.currentOpenedId = null
    },
    beforeOpen() {},
    sendData(type, id) {
      if (type == 'create') {
        GeneralService.editOrCreate('/cities', {
          ...this.updatedValues
        })
          .then((data) => {
            console.log(data)
            this.clickLoadData(1, 'cities')
            this.$modal.hide('citiesModal')
            this.$toast.success(data.data.message)
            this.updatedValues = {}
          })
          .catch((error) => {
            Object.keys(error.response.data.errors).forEach((elem, index) => {
              if (this.$refs[elem]) {
                this.$refs[elem].classList.add('input-error')
              }
            })
            this.$toast.error(error.response.data.message)
          })
      } else {
        // for (const key in this.updatedValues) {
        //   if (!this.updatedValues[key]) {
        //     delete this.updatedValues[key]
        //   }
        // }
        if (Object.keys(this.updatedValues).length) {
          GeneralService.edit(`/cities/${id}`, {
            ...this.updatedValues
          })
            .then((data) => {
              console.log(data)
              this.$modal.hide('citiesModal')
              this.$toast.success(data.data.message)
              this.updatedValues = {}
              this.clickLoadData(1, 'cities')
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((elem, index) => {
                if (this.$refs[elem]) {
                  this.$refs[elem].classList.add('input-error')
                }
              })
              this.$toast.error(error.response.data.message)
            })
        } else {
          this.$modal.hide('citiesModal')
        }
      }
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      this.updatedValues = {}
      this.filter = []
      this.clickLoadData(1, newTab.$attrs['data-name'])
    },

    async toArchive(type) {
      let dataFrom = await GeneralService.toSpecExcel(
        `/${type}/table/main/xlsx?limit=5000`,
        {
          params: {
            where: JSON.stringify(this.filter)
          }
        }
      )
      console.log(dataFrom)
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    clickLoadData(numPage, reestrType) {
      this.limitPaginationValue = 100
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/${reestrType}/table/main`, {
          params: {
            limit: this.limitPaginationValue,
            page: numPage,
            where: JSON.stringify(this.filter)
          }
        })
          .then((data) => {
            console.log(data)
            this.dataTable = data.data.data.rows
            this.getCitiesAll(data.data.data)
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          }),
        GeneralService.get('/countries')
          .then((data) => {
            console.log(data)
            this.selectsData.countries = data.data.data.rows
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          }),
        GeneralService.get('/regions')
          .then((data) => {
            console.log(data)
            this.selectsData.region = data.data.data.rows
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          }),
        GeneralService.get(
          'integrators?joins=company&fields=integrators__id,company_settings__set_req_company_name'
        )
          .then((data) => {
            console.log(data)
            this.selectsData.integrator = data.data.data.rows
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          })
      ]).then(() => {
        let timeOpt = () => {
          let options = []
          for (let i = -12; i <= 12; i++) {
            if (i > 0) i = '+' + i
            else i = i + ''
            options.push({ time: i })
          }
          console.log(options)

          return options
        }
        this.selectsData.time = timeOpt()
        this.localLoading = false
      })
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
    },
    setSimpleSelect(data, id) {
      this.updatedValues[data.key] = data.value
      if (this.$refs[data.key]) {
        this.$refs[data.key].classList.remove('input-error')
      }
    },
    resetFilter(reestrType) {
      this.filter = []
      this.selectsFilter = {
        countries: [],
        region: [],
        time: [],
        integrator: []
      }
      this.clickLoadData(1, reestrType)
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },
    ...mapActions('citiesPagiation', ['getCitiesAll'])
  },
  mounted() {
  },

  created() {
    this.clickLoadData(1, 'cities')

    // GeneralService.get(
    //   'https://suggest-maps.yandex.ru/v1/suggest?text=бурдж&apikey=be9de750-f591-4271-82d1-4846630211d1'
    // )
    //   .then((data) => {
    //     console.log(data)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })

    // fetch(
    //   `https://suggest-maps.yandex.ru/v1/suggest?text=бурдж&apikey=be9de750-f591-4271-82d1-4846630211d1`
    // )
    //   .then((data) => {
    //     console.log(data)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  },

  computed: {
    ...mapState('userSettingModule', ['userSetting', 'countNotices']),
    ...mapState('translationModule', ['dataLang']),
    ...mapState('citiesPagiation', ['paginationCitiesStateParams']),
    ...mapGetters('citiesPagiation', ['numPagesCities']),
    numPages() {
      return this.paginationStateParams.total / this.paginationStateParams.limit
    },
    columnsCountries() {
      return [
        {
          field: 'countries__name',
          title: this.dataLang['cities_register_text_8'],
          key: '41',
          align: 'left',
          width: '70%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'countries__name'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },

        {
          field: 'countries__phone_mask',
          title: this.dataLang['cities_register_text_19'],
          align: 'left',
          key: '24',
          width: '30%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        }
      ]
    },
    columnsRegion() {
      return [
        {
          field: 'countries__name',
          title: this.dataLang['cities_register_text_8'],
          key: '41',
          align: 'left',
          width: '30%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.countries}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find((x) => x.field == 'regions__country_id')
                    ) {
                      this.filter.push({
                        field: 'regions__country_id',
                        value: e.countries__id
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'regions__country_id'
                        )
                      ].value = e.countries__id
                    }
                  }}
                  style={'width: 100%'}
                  label={'countries__name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.countries}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'regions__name',
          title: this.dataLang['cities_register_text_9'],
          align: 'left',
          key: '24',
          width: '70%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'regions__name'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        }
      ]
    },
    columns() {
      return [
        {
          field: 'countries__name',
          title: this.dataLang['cities_register_text_8'],
          key: '41',
          align: 'left',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.countries}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find((x) => x.field == 'cities__country_id')
                    ) {
                      this.filter.push({
                        field: 'cities__country_id',
                        value: e.countries__id
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'cities__country_id'
                        )
                      ].value = e.countries__id
                    }
                  }}
                  style={'width: 100%'}
                  label={'countries__name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.countries}
                ></BaseSelect>
              </div>
            )
          }
        },

        {
          field: 'regions__name',
          title: this.dataLang['cities_register_text_9'],
          align: 'left',
          key: '24',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.region}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find((x) => x.field == 'cities__region_id')
                    ) {
                      this.filter.push({
                        field: 'cities__region_id',
                        value: e.regions__id
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'cities__region_id'
                        )
                      ].value = e.regions__id
                    }
                  }}
                  style={'width: 100%'}
                  label={'regions__name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.region}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'cities__name',
          title: this.dataLang['cities_register_text_10'],
          align: 'left',
          key: '22',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'cities__name'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'cities__center_address',
          title: this.dataLang['cities_register_text_20'],
          align: 'left',

          key: '45',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
          //   renderBodyCell: ({ row, column, rowIndex }, h) => {
          //     return <span>{row.notices__text}</span>
          //   }
        },
        {
          field: 'cities__utc',
          title: this.dataLang['cities_register_text_11'],
          align: 'left',

          key: '323',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.time}
                  onInput={(e, w) => {
                    console.log(e)
                    if (!this.filter.find((x) => x.field == 'cities__utc')) {
                      this.filter.push({
                        field: 'cities__utc',
                        value: e.time,
                        mod: 'valueUTC'
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex((x) => x.field == 'cities__utc')
                      ].value = e.time
                    }
                  }}
                  style={'width: 100%'}
                  customLabel={({ time }) => 'UTC' + time}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.time}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'cities__subdomain',
          title: this.dataLang['cities_register_text_12'],
          align: 'left',
          key: '545',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'cities__phone',
          title: this.dataLang['cities_register_text_13'],
          align: 'left',
          key: '5456',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'cities__count_places',
          title: this.dataLang['cities_register_text_14'],
          align: 'left',
          key: '114',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'cities__count_objects',
          title: this.dataLang['cities_register_text_15'],
          align: 'left',
          key: '4343',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'company_settings__set_req_company_name',
          title: this.dataLang['cities_register_text_16'],
          align: 'left',
          key: '5321',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.integrator}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find(
                        (x) => x.field == 'cities__integrator_id'
                      )
                    ) {
                      this.filter.push({
                        field: 'cities__integrator_id',
                        value: e.integrators__id
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'cities__integrator_id'
                        )
                      ].value = e.integrators__id
                    }
                  }}
                  style={'width: 100%'}
                  label={'company_settings__set_req_company_name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.integrator}
                ></BaseSelect>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>
