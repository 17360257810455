
import VehicleTypeAndCharacteristicsService from "@/api/VehicleTypeAndCharacteristicsService.js";
import Vue from "vue";
export const vehicleTypeAndCharacteristicsModule = {
  namespaced: true,
  state: () => ({
    vehicleTypeAndCharacteristics: [],
    loadStatus: 'request',
    paginationStateParams: {
      count: 0,
      limit: 10,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationStateParams.total / state.paginationStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.vehicleTypeAndCharacteristics = [...data]
    },
    create_one(state, data) {
      data.forEach(item => {
        state.vehicleTypeAndCharacteristics.unshift(item)
      })
    },
    delete_one(state, data) {
      let indexed = state.vehicleTypeAndCharacteristics.findIndex(x => x.vehicle_examples__id == data);
      state.vehicleTypeAndCharacteristics.splice(indexed, 1)
    },
    update_pagination(state, { total, count, limit, page }) {
      state.paginationStateParams = { total, count, limit, page }
    },
    load_request(state, status) {
      state.loadStatus = 'request'
    },
    load_success(state) {
      state.loadStatus = 'success'
    },
    load_error(state) {
      state.loadStatus = 'error'
    },
  },
  actions: {
    getAll({ state, commit }, { page, limit, vehicleType }) {
      console.log(page, limit, vehicleType);
      commit("load_request");
      VehicleTypeAndCharacteristicsService.getAll({
        params: {
          vehicleType: vehicleType,
          page: page ? page : state.page,
          limit: limit ? limit : state.limit
        }
      })
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error)
          commit("load_error");
        });
    },
    createOne({ commit }, data) {
      VehicleTypeAndCharacteristicsService.create(data)
        .then((data) => {
          console.log(data)
          Vue.$toast.success('Данные сохранены');
          commit("create_one", data.data.data.rows);
        })
        .catch((error) => {
          console.log(error)
          Vue.$toast.error(error.data.data.message);
          commit("load_error");
        });
    },
    editOne({ commit }, payload) {
      VehicleTypeAndCharacteristicsService.update(payload.id, payload.data)
        .then((data) => {
          console.log(data.data.data)
          Vue.$toast.success('Данные сохранены');
          // commit("edit_one", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error)
          commit("load_error");
        });
    },
    deleteOne({ commit }, params) {
      VehicleTypeAndCharacteristicsService.deleteOne(params)
        .then((data) => {
          commit("delete_one", params);
          Vue.$toast.success('Данные удалены')
        })
        .catch((error) => {
          console.log(error)
          commit("load_error");
        });
    },
  },
};
