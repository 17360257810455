<template>
  <BaseModal
    name="orderVideo"
    width="420"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="beforeClose"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div class="py-20 px-30 rounded-25 bg-white">
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          @click="$modal.hide('orderVideo')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p
          class="fw-bold text-center"
          v-html="dataLang['vehicle_modal_files_text27']"
        ></p>
        <div class="wp-100 mb-15">
          <BaseTextarea
            placeholder=""
            class="wp-100 fsc-14 mb-5"
            signClass="fsc-14 text-muted"
            input-name="comment"
            @onInput="updateValue"
            :minHeight="'160'"
            inputClass="fsc-14"
            :name-sign="dataLang['vehicle_modal_comments_modals_text3']"
            :value="dataForEmit.comment"
          >
          </BaseTextarea>
        </div>
        <div class="d-flex justify-content-end gap-15">
          <div
            class="base-button px-20 rounded-10"
            @click="$modal.hide('orderVideo')"
          >
            {{ dataLang['vehicle_modal_files_text28'] }}
          </div>
          <div
            @click="sendData"
            class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            {{ dataLang['vehicle_modal_files_text29'] }}
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import BidsService from '@/api/BidsService'
import EventBus from '@/event-bus.js'
import OwnersService from '@/api/OwnersService'

// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  components: {},
  props: {
    stateLoading: {
      type: Boolean,
      default: false
    },
    optionalParams: {
      type: Object
    }
  },
  data() {
    return {
      dataForEmit: {
        comment: '',
        request_services__type_service_id: 9,
        vehicleId: this.optionalParams.id
      }
    }
  },
  methods: {
    updateValue(data) {
      this.dataForEmit[data.name] = data.value
    },
    beforeClose(event) {
      this.dataForEmit = {
        comment: '',
        request_services__type_service_id: 9,
        vehicleId: this.optionalParams.id
      }
    },

    sendData() {
      BidsService.create(this.dataForEmit)
        .then((data) => {
          console.log(data)
          this.$toast.success('Запись добавлена')

          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.dataForEmit = {
            comment: '',
            request_services__type_service_id: 9,
            vehicleId: this.optionalParams.id
          }
          this.$modal.hide('orderVideo')
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
    }
  },
  mounted() {},
  created() {},
  beforeDestroy() {},

  computed: {
    ...mapState('translationModule', ['dataLang'])
  }
}
</script>
