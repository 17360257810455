<template>
  <div>
    <BaseModal
      name="addMilage"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="closeModalAndClearData"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="stateLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{ 'min-height': `${minHeight}px` }"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('addMilage')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30">
            {{ dataLang['vehicle_modal_service_modals_title_1'] }}
          </p>
          <div ref="vehicles__odometr">
            <BaseInputNew
              placeholder=""
              :width="'200'"
              class="mb-30"
              input-name="vehicles__odometr"
              @input="updateValue"
              :horizontalTitle="true"
              :value="dataForEmit.vehicles__odometr"
              :name-sign="dataLang['vehicle_modal_service_modals_odometr']"
            >
            </BaseInputNew>
          </div>
          <div class="d-flex justify-content-end gap-15">
            <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('addMilage')"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
            <div
              @click="sendData(false)"
              class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
            >
              {{ dataLang['vehile_modal_btn_save'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="acceptUpdateMilage"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="closeModalAndClearData"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="stateLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{ 'min-height': `${minHeight}px` }"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptUpdateMilage')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center" v-html="errorMessage">
          </p>
          <div class="d-flex justify-content-end gap-15">
            <!-- <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('acceptUpdateMilage')"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div> -->
            <div
              @click="$modal.hide('acceptUpdateMilage')"
              class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
            <div
              @click="sendData(true)"
              class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
            >
              {{ dataLang['vehile_modal_btn_save'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import EventBus from '@/event-bus.js'
// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  props: {
    nameForAction: {
      type: String
    },
    modalName: {},
    centered: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    size: {
      type: String,
      default: ''
    },
    minHeight: {
      type: Number
    },
    stateLoading: {
      type: Boolean,
      default: false
    },
    optionalParams: {
      type: Object
    }
  },
  data() {
    return {
      thisModal: null,
      errorMessage: '',
      dataForEmit: {
        vehicle_services__service_type_id: 7,
        vehicles__odometr: null
      }
    }
  },
  methods: {
    updateValue(data) {
      this.dataForEmit.vehicles__odometr = data.value
      this.$refs[data.name].classList.remove('input-error')
    },

    closeModalAndClearData() {
      this.dataForEmit.vehicles__odometr = null
      this.$refs['vehicles__odometr'].classList.remove('input-error')
    },

    sendData(isConfirm) {
      ObjectService.createService(this.optionalParams.id, {...this.dataForEmit, ...(isConfirm && { confirmed: true })})
        .then((data) => {
          console.log(data)
          this.$toast.success('Запись добавлена')
          Object.keys(this.dataForEmit).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.remove('input-error')
            }
          })
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.dataForEmit.vehicles__odometr = null
          this.$modal.hide('addMilage')
          this.$modal.hide('acceptUpdateMilage')
        })
        .catch((err) => {
          console.log(err)
          if (err.response.status == 409) {
            this.$modal.show('acceptUpdateMilage')
            this.errorMessage = err.response.data.message
          } else {
            this.$toast.error(err.response.data.message)
          }
          Object.keys(err.response.data.errors).forEach((elem, index) => {
            console.log(elem, this.$refs[elem]);
            if (this.$refs[elem]) {
              console.log(elem, 'withtrue');
              this.$refs[elem].classList.add('input-error')
            }
          })

        })
    }
  },
  mounted() {},
  beforeDestroy() {},

  computed: {
    ...mapState('modalStackModule', ['modalStackPosition']),
    ...mapState('translationModule', ['dataLang'])
  }
}
</script>
