
import OrdersService from "@/api/OrdersService.js";
import Vue from "vue";
export const ordersModule = {
  namespaced: true,
  state: () => ({
    ordersData: [],
    ordersLoadStatus: 'request',
    paginationOrdersStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationOrdersStateParams.total / state.paginationOrdersStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.ordersData = [...data];
    },
    create(state, data) {
      state.ordersData.unshift(data[0])
    },
    edit_order_multiple(state, data) {
      let indexed = state.ordersData.findIndex(x => x.orders__id == data.id)
      Object.assign(state.ordersData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.ordersData.findIndex(x => x.orders__id == id)
      state.ordersData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationOrdersStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.ordersLoadStatus= 'request'
    },
    load_success(state) {
      state.ordersLoadStatus= 'success'
    },
    load_error(state) {
      state.ordersLoadStatus= 'error'
    },
  },
  actions: {
   

    getOrdersAll({ commit }, params) {
      commit("load_request");
      console.log( OrdersService)
      OrdersService.getAll(params.params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
          if (params.callback) {
            params.callback()
          }
          
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },


    createOrdersData({ commit }, createData) {
      
        OrdersService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editOrdersMultiple({ commit }, {id, dataEdit}) {
        OrdersService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_order_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

 
    deleteOrdersData({ commit }, id) {
      // commit("load_request");
      OrdersService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },
    
    setOrderStatusConsultation({commit}, id){
      OrdersService.updateStatus(id, {status:5})
        .then((data) => {
          commit("edit_multiple", {id:id, data:data.data.data.rows} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    }
  },
};
