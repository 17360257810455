import http from "@/http-common.js";


class SelectsService {
  basePathApiService = "/selects";
  getAll() {
    return http.get(this.basePathApiService);
  } 
  getOne(field) {
    return http.get(this.basePathApiService + '/field/' + field);
  } 

  getOneByField(field) {
    return http.get(this.basePathApiService + '/field/' + field).then(data => {
       if(data.data.data.rows && data.data.data.rows[field]){
          data.data.data.rows = data.data.data.rows[field];
          let rows = [];
          data.data.data.rows.forEach(item => {
            for(let key in item){
                rows.push({
                  value: key,
                  label: item[key],
                  field: field
                })
            }
          });

          data.data.data.rows = rows;

          return new Promise((resolve, reject) => {
            resolve(data)
          })
       }
    })
  } 
//   create(createData) {
//     return http.post(this.basePathApiService, createData);
//   }
//   update(id, updateData) {
//     return http.put(this.basePathApiService + `/${id}`, updateData);
//   }
//   deleteOne(id) {
//     return http.delete(this.basePathApiService + `/${id}`);
//   }
  
}
export default new SelectsService();
