<template>
    <BaseModal
      name="BackBalanceClientsModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="beforeClose"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="py-20 px-30 rounded-25 bg-white" style="min-height: 320px">
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('BackBalanceClientsModal')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input mt-35"
        >
          <p class="fw-bold mb-15 text-center">
            {{ dataLang['clients_register_text_200'] }}
          </p>
          <p class="text-center mb-15 fsc-14">{{ dataLang['clients_register_text_199'] }}</p>
          <div class="wp-100 mb-15" ref="amount">
            <BaseInputNew
              placeholder=""
              :width="'261'"
              class="wp-100"
              signClass="fsc-14"
              input-name="amount"
              @input="(data) => updateValue({value: Number(data.value), name: data.name })"
              :horizontalTitle="true"
              :value="dataForEmit.amount"
              :name-sign="dataLang['clients_register_text_140']"
            >
            </BaseInputNew>
          </div>

          <!-- <div class="wp-100 mb-15" ref="comment">
            <BaseTextarea
              placeholder=""
              class="wp-100 fsc-14 mb-5"
              signClass="fsc-14 text-muted"
              input-name="comment"
              @onInput="updateValue"
              :minHeight="'160'"
              inputClass="fsc-14"
              :name-sign="dataLang['vehicle_modal_comments_modals_text3']"
              :value="dataForEmit.comment"
            >
            </BaseTextarea>
          </div> -->

          <div class="d-flex justify-content-end gap-15">
            <div
              class="base-button px-20 rounded-5 text-uppercase"
              @click="$modal.hide('BackBalanceClientsModal')"
            >
              {{ dataLang['clients_register_text_142'] }}
            </div>
            <div
              @click="sendData"
              class="btn btn-sm btn-primary px-20 rounded-5 d-flex align-items-center justify-content-center text-uppercase"
            >
              {{ dataLang['clients_register_text_143'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </template>

  <script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import ObjectService from '@/api/ObjectsService.js'
  import BidsService from '@/api/BidsService'
  import EventBus from '@/event-bus.js'
  import GeneralService from '@/api/GeneralService'

  // TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
  export default {
    components: {},
    props: {
      stateLoading: {
        type: Boolean,
        default: false
      },
      optionalParams: {
        type: Object
      }
    },
    data() {
      return {
        listOptions: [],
        finreestr_funds__id: [],
        dataForEmit: {
          amount: null,
          type: 'managerBack',
        }

        //       {
        //    "request_services__type_service_id": 1,
        //    "vehicleId":128,
        //    "date":"2020-05-05",
        //    "ownerId":1,
        //    "city":"Орехово",
        //    "nolimit":true,
        //    “comment”: “текст”
        // }
      }
    },
    methods: {
      setSelected(data) {
        console.log(data)
        this.dataForEmit.finreestr_funds__id = data.finreestr_funds__id
        this.$refs.finreestr_funds__id.classList.remove('input-error')
      },
      beforeClose(event) {
        // this.dataForEmit.vehicle_comments__text = null
        // this.dataForEmit.vehicle_comments__is_critical = null
        // this.selectLabel = ''
        this.dataForEmit = {
          amount: null,
          type: 'managerBack',
        }
        Object.keys(this.dataForEmit).forEach((elem, index) => {
          if (this.$refs[elem]) {
            this.$refs[elem].classList.remove('input-error')
          }
        })
      },
      updateValue(data) {
        this.dataForEmit[data.name] = data.value
        this.$refs[data.name].classList.remove('input-error')
      },

      sendData() {
        GeneralService.editOrCreate(
          `/clients/${this.optionalParams.id.id}/balance`,
          this.dataForEmit
        )
          .then((data) => {
            console.log(data)
            this.$toast.success('Запись добавлена')
            Object.keys(this.dataForEmit).forEach((elem, index) => {
              if (this.$refs[elem]) {
                this.$refs[elem].classList.remove('input-error')
              }
            })
            EventBus.$emit('contentLoaded')
            EventBus.$emit('updateContentData')
            this.dataForEmit = {
              amount: null,
              type: 'managerBack'
            }
            this.$modal.hide('BackBalanceClientsModal')
          })
          .catch((err) => {
            console.log(err)
            Object.keys(err.response.data.errors).forEach((elem, index) => {
              if (this.$refs[elem]) {
                this.$refs[elem].classList.add('input-error')
              }
            })
            this.$toast.error(err.response.data.message)
          })
      }
    },
    mounted() {},
    created() {
    },
    beforeDestroy() {},

    computed: {
      ...mapState('translationModule', ['dataLang']),
      ...mapState('userSettingModule', ['userSetting'])
    }
  }
  </script>
