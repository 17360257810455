<template>
  <div>
    <BaseTopLine></BaseTopLine>
    <div class="px-20 pt-10">
      <!-- <div class="base-button px-20 rounded-10" @click="actionForEditTable">
        {{ dataLang['registry_ads_text2'] }}
      </div> -->
    </div>
    <div class="p-20 position-relative">
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div class="d-flex gap-15 fw-semi-bold mb-15">
        <div
          class="py-10 px-15 cursor-pointer"
          :class="[isReadedShow ? 'bg-primary' : '']"
          @click="changeShowMessages(true)"
        >
          {{ dataLang['notification_page_text1'] }}
        </div>
        <div
          class="py-10 px-15 cursor-pointer"
          :class="[!isReadedShow ? 'bg-primary' : '']"
          @click="changeShowMessages(false)"
        >
          {{ dataLang['notification_page_text2'] }}
        </div>
      </div>
      <div class="d-flex justify-content-between mb-15">
        <div class="base-button px-20 rounded-10" @click="actionForEditTable">
          <span v-if="isReadedShow">{{
            dataLang['notification_page_text3']
          }}</span>
          <span v-if="!isReadedShow">{{
            dataLang['notification_page_text11']
          }}</span>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationStateParams"
          :showLimitInput="false"
          @updateLimitValue="() => {}"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="clickLoadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        ></BasePagination>
      </div>

      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="dataTable"
        :border-x="true"
        :border-y="true"
        ref="refTable"
        row-key-field-name="notices__id"
        :checkbox-option="{
          hideSelectAll: false,
          selectedRowChange: selectedRowChange,
          selectedAllChange: selectedAllChange
        }"
        :eventCustomOption="eventCustomOption"
        v-if="!localLoading"
        :clickHighlight="false"
        :rowStyleOption="{
          hoverHighlight: true
        }"
        :cellSelectionOption="{ enable: false }"
      />
    </div>

    <BaseModal
      name="showInfoNotice"
      width="450"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="beforeClose"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="py-20 px-30 rounded-25 bg-white">
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('showInfoNotice')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-semi-bold mb-15 text-center" v-if="isReadedShow">
            {{ dataLang['notification_page_text9'] }}
          </p>
          <p class="fw-semi-bold mb-15 text-center" v-if="!isReadedShow">
            {{ dataLang['notification_page_text20'] }}
          </p>
          <div class="d-flex flex-wrap fsc-14 mb-15">
            <div class="wp-50 fw-medium mb-15">
              {{ dataLang['notification_page_text4'] }}
            </div>
            <div class="wp-50 mb-15">
              {{
                $time(textForModal?.notices__created_at)
                  .utc(true)
                  .utcOffset(Number(userSetting.company__utc))
                  .format(
                    `${userSetting.company__tempalte_date} ${userSetting.company__tempalte_time}`
                  )
              }}
            </div>
            <div class="wp-50 fw-medium">
              {{ dataLang['notification_page_text5'] }}
            </div>
            <div class="wp-50" v-if="textForModal?.notices__updated_at">
              {{
                $time(textForModal?.notices__updated_at)
                  .utc(true)
                  .utcOffset(Number(userSetting.company__utc))
                  .format(
                    `${userSetting.company__tempalte_date} ${userSetting.company__tempalte_time}`
                  )
              }}
            </div>
          </div>
          <div class="wp-100">
            <span class="fsc-14">{{
              dataLang['notification_page_text7']
            }}</span>
            <div class="bg-system-secondary py-10 px-15">
              <BaseScrollbar style="max-height: 160px">
                <div class="pr-10">
                  {{ textForModal?.notices__text }}
                </div></BaseScrollbar
              >
            </div>
          </div>

          <div class="d-flex justify-content-end gap-15 mt-15">
            <div
              class="base-button px-20 rounded-10"
              @click="editAllAndOne(textForModal?.notices__id)"
            >
              <span v-if="isReadedShow">{{
                dataLang['notification_page_text3']
              }}</span>
              <span v-if="!isReadedShow">{{
                dataLang['notification_page_text11']
              }}</span>
            </div>
            <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('showInfoNotice')"
            >
              {{ dataLang['notification_page_text12'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="acceptDeletes"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeletes')"
          ></button>
        </div>
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">
            {{ dataLang.confirm_btn__btn_yes }}
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import NoticesService from '@/api/NoticesService.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
export default {
  components: {
    VeTable
  },

  props: {},
  data() {
    return {
      updatedValues: {},
      isReadedShow: true,
      dataForModal: {},
      textForModal: null,
      editData: {},
      activeSelectRow: null,
      dataTable: null,
      activeSelectRowsMultiple: [],
      currentOpenIdModal: null,
      localLoading: true,
      limitPaginationValue: 100,
      paginationStateParams: {
        limit: 100,
        page: 1
      },
      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        }
      },
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('showInfoNotice')
              this.textForModal = row
            }
          }
        }
      }
    }
  },
  methods: {
    beforeClose() {},
    beforeOpen() {},

    changeShowMessages(type) {
      this.isReadedShow = type
      this.clickLoadData(1)
    },
    updateLimitValue(data) {
      console.log(data)
      this.limitPaginationValue = data
      this.clickLoadData(1)
    },
    editAllAndOne(listOrOne) {
      this.localLoading = true
      NoticesService.editMultiple(listOrOne, {
        statusCode: this.isReadedShow
          ? 'notification_status_read'
          : 'notification_status_new'
      })
        .then((data) => {
          console.log(data)
          this.localLoading = false
          this.$toast.success(data.data.message)
          this.clickLoadData(1)
        })
        .catch((error) => {
          console.log(error.response.data.errors)
        })
      this.$modal.hide('showInfoNotice')
    },
    actionForEditTable() {
      if (this.activeSelectRowsMultiple.length) {
        // this.$modal.show('showInfoNotice')
        console.log()
        this.editAllAndOne(this.activeSelectRowsMultiple)
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    // deleteRow(nameModal) {
    //   this.deleteTransportVehiclesData(this.activeSelectRowsMultiple)
    //   this.activeSelectRow = null
    //   this.$modal.hide('acceptDeletes')
    // },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
      console.log(this.activeSelectRowsMultiple)
    },
    selectedAllChange({ isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
      console.log(this.activeSelectRowsMultiple)
    },
    clickLoadData(numPage) {
      this.limitPaginationValue = 100
      this.localLoading = true
      NoticesService.getAll({
        params: {
          limit: this.limitPaginationValue,
          page: numPage,
          where: JSON.stringify([
            {
              field: 'notices_statuses__status',
              value: this.isReadedShow
                ? 'notification_status_new'
                : 'notification_status_read'
            }
          ])
        }
      })
        .then((data) => {
          console.log(data)
          this.localLoading = false
          this.dataTable = data.data.data.rows
          this.paginationStateParams = Object.assign(
            this.paginationStateParams,
            data.data.data
          )
        })
        .catch((error) => {
          console.log(error.response.data.errors)
        })
      this.setUserNotification()
    },

    deleteRow(nameModal) {
      this.localLoading = true
      NoticesService.delete(this.activeSelectRowsMultiple)
        .then((data) => {
          console.log(data)
          this.$toast.success(data.data.message)

          NoticesService.getAll({
            params: {
              where: JSON.stringify([
                { field: 'request_services__type_service_id', value: 2 }
              ])
            }
          })
            .then((data) => {
              console.log(data)
              this.localLoading = false
              this.dataTable = data.data.data.rows
            })
            .catch((error) => {
              console.log(error.response.data.errors)
            })
        })
        .catch((error) => {
          console.log(error.response.data.errors)
          //   this.validateData(error.response.data.errors)
          //   this.$toast.error(error.response.data.message)
          //   callback(false, nameTab, oldTab, newTab)
        })

      this.activeSelectRowsMultiple = []
      this.$modal.hide('acceptDeletes')
      //   this.localLoading = false
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
    },

    ...mapActions('userSettingModule', [
      'getUserSetting',
      'setUserNotification'
    ])
  },
  mounted() {
    dayjs.extend(utc)
  },

  created() {
    // ?where=[{"field":"request_services__type_service_id","value":8}]
    this.clickLoadData(1)
    // this.getBidsOsagoAll({
    //   params: {
    //     page: 1,
    //     limit: 10,
    //   },
    // })
  },

  computed: {
    ...mapState('userSettingModule', ['userSetting', 'countNotices']),
    ...mapState('translationModule', ['dataLang']),
    numPages() {
      return this.paginationStateParams.total / this.paginationStateParams.limit
    },
    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: '3%',
          align: 'center'
        },
        {
          field: 'notices__id',
          key: '42',
          title: 'ID',
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
          // renderBodyCell: ({ row, column, rowIndex }, h) => {
          //   return <span>{row.notices__text}</span>
          // }
        },
        // {
        //   field: 'request_services__id',
        //   key: '1',
        //   title: this.dataLang['bids_text2'],
        //   align: 'left',
        //   sortBy: '',
        //   ellipsis: {
        //     showTitle: true,
        //     lineClamp: 1
        //   },
        //   renderHeaderCell: ({ column }, h) => {
        //     return (
        //       <div>
        //         <div class={'text-center'}>{column.title}</div>
        //       </div>
        //     )
        //   }
        // },

        {
          field: 'notices__created_at',
          key: '2',
          title: this.dataLang['notification_page_text5'],
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.notices__created_at
                  ? dayjs(row.notices__created_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(
                        `${this.userSetting.company__tempalte_date} HH:mm`
                      )
                  : ''}
              </span>
            )
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={''}>
                  {this.isReadedShow
                    ? this.dataLang['notification_page_text4']
                    : this.dataLang['notification_page_text5']}
                </div>
              </div>
            )
          }
        },
        {
          field: 'notices_categories__description',
          key: '3',
          title: this.dataLang['notification_page_text6'],
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
          //   renderHeaderCell: ({ column }, h) => {
          //     return (
          //       <div>
          //         <div class={'text-center'}>{column.title}</div>
          //       </div>
          //     )
          //   },
        },
        {
          field: 'notices__text',
          key: '4',
          title: this.dataLang['notification_page_text7'],
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.notices__text}</span>
          }
        },
        {
          field: 'employees__name',
          key: '5',
          title: this.dataLang['notification_page_text8'],
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>
                  {this.isReadedShow
                    ? 'Нажмите'
                    : this.dataLang['notification_page_text8']}
                </div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'d-flex justify-content-center'}>
                {this.isReadedShow ? (
                  <div
                    class={'base-button px-20 rounded-5 fsc-11 py-5 px-10'}
                    on-click={() => this.editAllAndOne(row.notices__id)}
                  >
                    Просмотрено
                  </div>
                ) : (
                  row.employees__name
                )}
              </div>
            )
          }
        }
      ]
    }
    // ...mapState('bidsOsagoModule', [
    //   'bidsOsagoData',
    //   'bidsOsagoLoadStatus',
    //   'paginationbidsOsagoStateParams',
    // ]),
    // ...mapState('translationModule', ['dataLang']),
    // ...mapGetters('bidsOsagoModule', ['numPages']),
  }
}
</script>
