<template>
    <div class="position-relative">
      <BaseTopLine> </BaseTopLine>
      <div
        class="position-relative h-100 container-fluid"
        style="min-height: 250px"
      >
        <BaseLoader v-if="localLoading"></BaseLoader>
        <div
          class="d-flex justify-content-between align-items-center mt-15 mb-10"
        >
          <div class="d-flex gap-15 align-items-center">
            <div
              class="base-button base-button--action"
              @click="deleteActionAndModal"
            >
              <img
                :src="require(`@/assets/icons/adddel.svg`)"
                width="13.5"
                height="13.5"
              />
              <span class="fsc-14">{{
                dataLang['reestr_objects_archive_text1']
              }}</span>
            </div>
            <div
              class="base-button base-button--action"
              @click="onShowModalForRestore"
            >
              <img
                :src="require(`@/assets/icons/document-previous.svg`)"
                width="13.5"
                height="13.5"
              />
              <span class="fsc-14">{{
                dataLang['reestr_objects_archive_text2']
              }}</span>
            </div>
          </div>
        </div>
        <div class="overflow-auto" style="min-height: 400px">
          <div class="d-flex gap-15 align-items-center mb-10">
            <!-- <div
              class="btn btn-sm btn-thrity text-white fsc-14"
              @click="loadData()"
            >
              {{ dataLang['register_btn_filter_on'] }}
            </div> -->
            <div
              class="text-decoration-underline fsc-14 text-dark"
              style="cursor: pointer"
              @click="resetFilter"
            >
              {{ dataLang['register_btn_filter_off'] }}
            </div>
          </div>
          <!-- :checkbox-option="{
              hideSelectAll: true,
              selectedRowChange: selectedRowChange
            }" -->
          <ve-table
            style="width: 100%"
            :columns="columns"
            :table-data="dataTable"
            :border-x="true"
            :border-y="true"
            row-key-field-name="vehicles__id"
            ref="mainTable"
            :eventCustomOption="eventCustomOption"
            v-if="!localLoading"
            :radio-option="{
              selectedRowChange: selectedRowChange
            }"
            :clickHighlight="false"
            :cell-style-option="{}"
            :rowStyleOption="{
              hoverHighlight: true
            }"
            :cellSelectionOption="{ enable: false }"
          >
          </ve-table>
        </div>
      </div>
      <BaseModal
        name="acceptDelete"
        width="420"
        :clickToClose="false"
        :shiftY="0.5"
        :adaptive="true"
        height="auto"
        classes="bg-transparent shadow-none"
      >
        <div class="bg-white rounded-25 py-25 px-20">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              @click="$modal.hide('acceptDelete')"
            ></button>
          </div>
          <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
          <div class="d-flex justify-content-center mt-10">
            <div class="btn btn-sm btn-danger" @click="deleteRow">
              {{ dataLang.confirm_btn__btn_yes }}
            </div>
          </div>
        </div>
      </BaseModal>
      <BaseModal
        name="acceptDeleteWithPin"
        width="420"
        :clickToClose="false"
        :shiftY="0.5"
        @before-close="pincodeModalHandler"
        :adaptive="true"
        height="auto"
        classes="bg-transparent shadow-none"
      >
        <div
          v-if="localLoading"
          class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
          style="top: 0; left: 0; z-index: 500"
        >
          <svg class="spinner" viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
        <div
          class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
          :style="{}"
        >
          <div class="d-flex justify-content-end">
            <slot name="modal-header"></slot>
            <button
              type="button"
              class="btn-close"
              @click="$modal.hide('acceptDeleteWithPin')"
            ></button>
          </div>
          <div
            class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
          >
            <p class="fw-bold mb-30 text-center">Внимание!</p>

            <div class="d-flex flex-column align-items-center">
              <!-- <div
                class="base-button px-20 rounded-10"
                @click="$modal.hide('acceptDeleteWithPin')"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div> -->
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="'pincode'"
                @input="(data) => (pincode = data.value)"
                value=""
                name-sign=""
              >
              </BaseInputNew>
              <div class="d-flex justify-content-end gap-15">
                <div
                  @click="$modal.hide('acceptDeleteWithPin')"
                  class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
                >
                  {{ dataLang['vehile_modal_btn_reset'] }}
                </div>
                <div
                  @click="deleteRow"
                  class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
                >
                  {{ dataLang['vehile_modal_btn_save'] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseModal>

      <template>
        <BaseModal
          name="modalForRestore"
          width="420"
          height="320"
          :clickToClose="false"
          :shiftY="0.5"
          @before-close="onCloseModalForRestore"
          :adaptive="true"
          classes="bg-transparent shadow-none"
        >
          <div
            class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white h-100 position-relative overflow-hidden"
            :style="{}"
          >
            <BaseLoader v-if="localLoadingModal"></BaseLoader>
            <div class="d-flex justify-content-end">
              <slot name="modal-header"></slot>
              <button
                type="button"
                class="btn-close"
                @click="$modal.hide('modalForRestore')"
              ></button>
            </div>
            <div
              class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
            >
              <p class="fw-bold mb-30 text-center">
                {{ dataLang['reestr_objects_archive_text12'] }}
              </p>
              <div class="wp-100" v-if="selectsDataForModal.places.length">
                <span class="fsc-11">{{
                  dataLang['reestr_objects_archive_text13']
                }}</span>
                <BaseSelect
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                  openDirection="bottom"
                  v-model="selectsFromModal.places"
                  :options="selectsDataForModal.places"
                  :maxHeight="100"
                  label="vehicle_places__name"
                  :optionHeight="25"
                >
                  <template slot="noOptions">{{
                    dataLang['select_empty']
                  }}</template>
                  <template slot="noResult">{{
                    dataLang['select_search_empty']
                  }}</template
                  ><template slot="selectedLabel">{{
                    dataLang['select_selected']
                  }}</template>
                  <template slot="deselectLabel">{{
                    dataLang['select_unselect']
                  }}</template>
                </BaseSelect>
              </div>
              <div class="d-flex justify-content-end gap-15 mt-15">
                <div
                  @click="$modal.hide('modalForRestore')"
                  class="btn btn-sm btn-opposite px-20 rounded-10 py-10 fsc-14 d-flex align-items-center justify-content-center text-uppercase"
                >
                  <!-- {{ dataLang['vehile_modal_btn_reset'] }} -->
                  {{ dataLang['reestr_objects_archive_text14'] }}
                </div>
                <div
                  @click="onSaveObjectFromArchive"
                  class="btn btn-sm btn-primary px-20 fsc-14 rounded-10 py-10 d-flex align-items-center justify-content-center text-uppercase"
                >
                  {{ dataLang['reestr_objects_archive_text2'] }}
                </div>
              </div>
            </div>
          </div>
        </BaseModal>
      </template>
    </div>
  </template>

  <script>
  import { mapActions, mapState, mapGetters } from 'vuex'
  import { VeTable } from 'vue-easytable'
  import GeneralService from '@/api/GeneralService'

  export default {
    components: {
      VeTable
    },

    data() {
      return {
        localLoading: true,
        dataTable: [],
        activeSelectRowsMultiple: [],
        filter: [],
        updatedValues: {},
        computedAllPage: 1,
        currentPage: 1,
        nameValue: '',
        activePagePagination: {},
        currentId: null,
        localLoadingModal: false,
        pincode: '',
        copiedDataForFilter: [],
        selectsDataForModal: {
          places: []
        },
        selectsFromModal: {
          places: []
        },
        selectsData: {
          objects_types: []
        },
        selectsFilter: { objects_types: [] },
        eventCustomOption: {},
        filterParams: {}
      }
    },
    methods: {
      selectedRowChange({ row, isSelected, selectedRowKeys }) {
        this.activeSelectRowsMultiple = [row.vehicles__id]
        console.log(this.activeSelectRowsMultiple)
      },
      saveIntegrator() {},

      // @before-close="onCloseModalForRestore"
      //     @before-open="onShowModalForRestore"

      onCloseModalForRestore() {
        this.selectsDataForModal.places = []
        this.selectsFromModal.places = []
        this.activeSelectRowsMultiple = []
        this.loadData()
      },

      onShowModalForRestore() {
        if (this.activeSelectRowsMultiple.length) {
          this.$modal.show('modalForRestore')
        } else {
          this.$toast.error(this.dataLang['reestr_objects_archive_text15'])
        }
      },

      onSaveObjectFromArchive() {
        this.localLoadingModal = true
        GeneralService.edit(
          `/objects/fromarchive/${this.activeSelectRowsMultiple[0]}`,
          {
            ...(this.selectsFromModal && {
              vehicles__place_id: this.selectsFromModal.places.vehicle_places__id
            })
          }
        )
          .then((data) => {
            console.log(data);
            console.log(data.data.data.places)
            if ('places' in data.data.data) {
              this.$toast.info(data.data.message)
              this.selectsDataForModal.places = data.data.data.places
            } else {
              this.$modal.hide('modalForRestore')
              this.$toast.success(data.data.message)
            }
            this.localLoadingModal = false
          })
          .catch((e) => {
            console.log(e)
            this.localLoadingModal = false
            this.$toast.error(e.response.data.message)
          })
      },

      updateValue(data) {
        this.updatedValues[data.name] = data.value
        console.log(this.updatedValues)
        if (this.$refs[data.name]) {
          this.$refs[data.name].classList.remove('input-error')
        }
      },

      resetFilter() {
        this.dataTable = this.copiedDataForFilter
        this.selectsFilter.objects_types = []
        this.$refs.mainTable.$el
          .querySelectorAll('input.base-input--new')
          .forEach((elem, index) => {
            elem.value = ''
          })
        this.filterParams = {}
      },

      createorEdit() {
        if (this.currentId) {
          GeneralService.edit(`/integrators/${this.currentId}`, {
            ...this.updatedValues
          })
            .then((data) => {
              this.$modal.hide('addIntegrator')
              this.loadData(1)
              console.log(data)
            })
            .catch((e) => {
              this.loadData(1)
              console.log(e)
            })
        } else {
          GeneralService.editOrCreate(`/integrators`, {
            ...this.updatedValues
          })
            .then((data) => {
              this.$modal.hide('addIntegrator')
              this.loadData(1)
              console.log(data)
            })
            .catch((e) => {
              this.loadData(1)
              console.log(e)
            })
        }
      },


  // On click
  // Navigate to: "Авторизация / Данные введены";
  // Animate: Smart animate;
  // Spring: { mass: 1, stiffness: 400, damping: 30 };




      closemodal() {
        this.currentId = null
        this.updatedValues = {}
        this.activeSelectRowsMultiple = []
      },
      deleteActionAndModal() {
        if (this.activeSelectRowsMultiple.length) {
          this.$modal.show('acceptDelete')
        } else {
          this.$toast.error(this.dataLang['reestr_objects_archive_text15'])
        }
      },
      pincodeModalHandler() {
        this.pincode = ''
        this.activeSelectRowsMultiple = []
      },
      deleteRow(nameModal) {
        GeneralService.delete(`/objects/` + this.activeSelectRowsMultiple, {
          ...(this.pincode && { pincode: this.pincode })
        })
          .then((data) => {
            this.loadData()
            console.log(data)
            this.$toast.success(data.data.message)
            this.$modal.hide('acceptDeleteWithPin')
            this.$modal.hide('acceptDelete')
          })
          .catch((e) => {
            console.log(e)
            if (e.response.status == 409) {
              this.$modal.show('acceptDeleteWithPin')
              this.$toast.error(e.response.data.message)
            } else if (e.response.status == 400) {
              this.$toast.error(e.response.data.message)
            }
          })
      },
      loadData() {
        this.localLoading = true
        Promise.all([
          GeneralService.get(`/objects/types`)
            .then((data) => {
              // this.selects.push(data.data.data.rows)
              this.selectsData = Object.assign(this.selectsData, {
                objects_types: data.data.data.rows
              })
            })
            .catch((e) => {
              console.log(e)
            })
        ]).then(() => {
          GeneralService.get(`/objects/table/archive`)
            .then((data) => {
              console.log(data)
              this.dataTable = data.data.data.rows
              this.copiedDataForFilter = data.data.data.rows
              this.localLoading = false
            })
            .catch((e) => {
              console.log(e)
              this.$toast.error('Неизвестная ошибка')
            })
        })
      },
      fillFilter(data) {
        this.filterParams[data.name] = String(data.value)
        if (data.value.length) {
          // this.filterParams[data.name]
          this.dataTable = this.dataTable.filter((x) => {
            return (
              String(x[data.name]) == String(data.value) ||
              String(x[data.name]).includes(String(data.value))
            )
          })

          // this.copiedDataForFilter.forEach((el, index) => {
          //   // this.dataTable = this.copiedDataForFilter.filter((x) => {
          //   Object.keys(this.filterParams).forEach((elemKey) => {
          //     return this.filterParams[elemKey] == String(el[elemKey])
          //   })
          //   // })
          //   // Object.keys(el).forEach((elemKey) => {
          //   //   this.dataTable = this.dataTable.filter(() => {
          //   //     return this.filterParams[elemKey] == String(el[elemKey])
          //   //   })
          //   // })
          // })
        } else {
          delete this.filterParams[data.name]
          this.dataTable = this.copiedDataForFilter
        }
      }
    },
    mounted() {},
    created() {
      Promise.all([
        //   }),
      ]).then(() => {
        this.loadData()
        //   GeneralService.editOrCreate(`/integrators`, {
        //     integrators__company_id: 1,
        //     integrators__percent: 2
        //   })
        //     .then((data) => {
        //       console.log(data)
        //     })
        //     .catch((e) => {
        //       console.log(e)
        //       this.$toast.error('Неизвестная ошибка')
        //     })
      })
    },
    computed: {
      ...mapState('translationModule', ['dataLang']),
      ...mapState('userSettingModule', ['userSetting']),
      columns() {
        return [
          {
            field: '',
            key: 'a',

            type: 'radio',
            title: '',
            width: 5,
            align: 'center'
          },
          {
            field: 'vehicle_types__code',
            key: '12',
            title: this.dataLang['reestr_objects_archive_text5'],
            align: 'center',
            width: '25%',
            ellipsis: {
              showTitle: true,
              lineClamp: 1
            },

            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return this.dataLang[row.vehicle_types__code]
            },
            renderHeaderCell: ({ column }, h) => {
              return (
                <div>
                  <div class={'text-center'}>{column.title}</div>
                  <BaseSelect
                    vModel={this.selectsFilter.objects_types}
                    onInput={(e, w) => {
                      this.fillFilter({
                        value: e.vehicle_types__code,
                        name: 'vehicle_types__code'
                      })
                    }}
                    style={'width: 100%'}
                    customLabel={({ vehicle_types__code }) => {
                      return this.dataLang[vehicle_types__code]
                    }}
                    placeholder={''}
                    id={'ads__id'}
                    selectLabel={''}
                    deselectLabel={''}
                    options={this.selectsData.objects_types}
                  ></BaseSelect>
                </div>
              )
            }
          },

          {
            field: 'vehicles__id',
            key: '13',
            title: this.dataLang['reestr_objects_archive_text6'],
            align: 'center',
            width: '12%',

            ellipsis: {
              showTitle: true,
              lineClamp: 1
            },
            renderHeaderCell: ({ column }, h) => {
              return (
                <div>
                  <div class={'text-center'}>{column.title}</div>

                  <BaseInputNew
                    placeholder=''
                    style={'width: 100%'}
                    disabled={false}
                    onInput={this.fillFilter}
                    inputName={'vehicles__id'}
                    value={''}
                    nameSign={''}
                  />
                </div>
              )
            }
          },
          {
            field: 'vehicles__name',
            key: '132',
            title: this.dataLang['reestr_objects_archive_text7'],
            align: 'center',
            width: '12%',

            ellipsis: {
              showTitle: true,
              lineClamp: 1
            },
            renderHeaderCell: ({ column }, h) => {
              return (
                <div>
                  <div class={'text-center'}>{column.title}</div>

                  <BaseInputNew
                    placeholder=''
                    style={'width: 100%'}
                    disabled={false}
                    onInput={this.fillFilter}
                    inputName={'vehicles__name'}
                    value={''}
                    nameSign={''}
                  />
                </div>
              )
            }
          },
          {
            field: 'vehicles__year_release',
            key: '133',
            title: this.dataLang['reestr_objects_archive_text8'],
            align: 'center',
            width: '12%',

            ellipsis: {
              showTitle: true,
              lineClamp: 1
            }
          },
          {
            field: 'vehicles__created_at',
            key: '134',
            title: this.dataLang['reestr_objects_archive_text9'],
            align: 'center',
            width: '12%',

            ellipsis: {
              showTitle: true,
              lineClamp: 1
            },
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return (
                <span>
                  {row.vehicles__created_at
                    ? this.$time(row.vehicles__created_at)
                        .utc(true)
                        .utcOffset(Number(this.userSetting.company__utc))
                        .format(
                          `${this.userSetting.company__tempalte_date} HH:mm`
                        )
                    : ''}
                </span>
              )
            }
          },
          {
            field: 'vehicles__archived_at',
            key: '135',
            title: this.dataLang['reestr_objects_archive_text10'],
            align: 'center',
            width: '12%',

            ellipsis: {
              showTitle: true,
              lineClamp: 1
            },
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return row.vehicles__archived_at
                ? this.$time(row.vehicles__archived_at).format(
                    `${this.userSetting.company__tempalte_date}`
                  )
                : ''
            }
          },
          {
            field: 'vehicles__active_duration',
            key: '136',
            title: this.dataLang['reestr_objects_archive_text11'],
            align: 'center',
            width: '12%',

            ellipsis: {
              showTitle: true,
              lineClamp: 1
            }
          }
        ]
      }
    }
  }
  </script>

  <style>
  .client-status-danger {
    background-color: #ff2400 !important;
    color: white !important;
  }
  </style>
