<template>
  <BaseModal
    name="clientsModal"
    :clickToClose="false"
    :shiftY="0.5"
    :adaptive="true"
    :minHeight="680"
    height="auto"
    :resizable="true"
    :width="1400"
    classes="bg-transparent shadow-none"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <!-- 875 -->

    <div class="mx-auto" style="width: 1280px">
      <!-- <BaseLoader v-if="modalInfo.loading"></BaseLoader> -->
      <div class="bg-system-main-block">
        <div
          class="bg-system-secondary py-10 px-30 d-flex gap-30"
          v-if="dataForModal"
        >
          <div class="d-flex justify-content-between flex-grow-1 fsc-11">
            <div class="d-flex align-items-center flex-grow-1">
              <span class="fw-bold">{{ dataForHeader?.clientName }}</span>
              &nbsp; (ID {{ dataForHeader?.clientId }} от
              {{
                $time(dataForHeader.clientCreatedAt)
                  .utc(true)
                  .utcOffset(Number(this.userSetting.company__utc))
                  .format(`${this.userSetting.company__tempalte_date}`)
              }})

              {{ dataForHeader.clientPhone }} &nbsp;

              {{ dataLang['clients_register_text_1'] }}&nbsp;
              <span class="fw-bold">{{
                dataForHeader.verifications?.yandexrent?.daysToEnd
              }}</span>
              &nbsp; {{ dataLang['clients_register_text_2'] }}&nbsp;
              <span class="fw-bold">{{
                dataForHeader.verifications?.company?.daysToEnd
              }}</span>
              &nbsp; {{ dataLang['clients_register_text_404'] }}:&nbsp;
              <span class="fw-bold">{{
                dataForHeader.statistics?.contractsCount
              }}</span>
              &nbsp; {{ dataLang['clients_register_text_42'] }}&nbsp;
              <span class="fw-bold">{{
                dataForHeader.statistics?.payments
              }}</span>
              &nbsp; {{ dataLang['clients_register_text_36'] }}:&nbsp;
              <span class="fw-bold">{{ dataForHeader.clientBalance }}</span>
              &nbsp; {{ dataLang['clients_register_text_12'] }}:&nbsp;
              <span class="fw-bold">{{ dataForHeader.clientReting }}</span>
              &nbsp;
            </div>
            <div class="d-flex align-items-center">
              {{ dataLang['clients_register_text_7'] }} &nbsp;
              <span
                class="fw-bold"
                v-html="
                  !dataForHeader.clientIsArchived
                    ? dataLang['clients_register_text_8']
                    : dataLang['clients_register_text_9']
                "
              ></span>
              &nbsp;
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('clientsModal')"
          ></button>
        </div>
        <div class="pb-10">
          <VueTabs v-model="activateTab" @tab-change="handleTabChange">
            <VTab
              :title="dataLang['clients_register_text_10']"
              data-name="mainTab"
            >
              <div class="px-30">
                <MainTab
                  ref="mainTab"
                  v-if="listTabs.mainTab"
                  :modalInfo="modalInfo"
                ></MainTab>
              </div>
            </VTab>
            <VTab
              :title="dataLang['clients_register_text_11']"
              data-name="balanceTab"
            >
              <div class="px-30">
                <BalanceTab
                  ref="balanceTab"
                  v-if="listTabs.balanceTab"
                  :modalInfo="modalInfo"
                ></BalanceTab>
              </div>
            </VTab>
            <VTab
              :title="dataLang['clients_register_text_12']"
              data-name="ratingTab"
            >
              <div class="px-30">
                <RatingTab
                  ref="ratingTab"
                  v-if="listTabs.ratingTab"
                  :modalInfo="modalInfo"
                ></RatingTab>
              </div>
            </VTab>
            <VTab
              :title="dataLang['clients_register_text_13']"
              data-name="aboutClientTab"
              ><div class="px-30">
                <AboutClientTab
                  ref="aboutClientTab"
                  v-if="listTabs.aboutClientTab"
                  :modalInfo="modalInfo"
                ></AboutClientTab></div
            ></VTab>
            <VTab
              :title="dataLang['clients_register_text_14']"
              data-name="blackListTab"
            >
              <div class="px-30">
                <BlackListClientsTab
                  ref="blackListTab"
                  v-if="listTabs.blackListTab"
                  :modalInfo="modalInfo"
                ></BlackListClientsTab>
              </div>
            </VTab>
          </VueTabs>
          <div class="d-flex justify-content-end gap-35 px-30">
            <div
              class="bg-danger px-15 py-10 flex-grow-1 fsc-12 fw-bold text-white d-flex align-items-center gap-5"
              v-if="dataForModal?.clients__is_blacklisted"
              v-html="dataLang['clients_register_text_95']"
            ></div>
            <div class="d-flex gap-10">
              <div
                class="base-button base-button--action py-10 px-30 text-uppercase"
                @click="$modal.hide('clientsModal')"
              >
                <span class="fsc-14">{{
                  dataLang['orders_model_edit__btn_text5']
                }}</span>
              </div>
              <div
                class="base-button base-button--action py-10 px-30 text-uppercase"
                @click="sendData"
              >
                <span class="fsc-14">{{
                  dataLang['orders_model_edit__btn_text6']
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import GeneralService from '@/api/GeneralService'
import MainTab from './MainTab.vue'
import SelectsService from '@/api/SelectsService.js'
import EventBus from '@/event-bus.js'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import BalanceTab from './BalanceTab.vue'
import RatingTab from './RatingTab.vue'
import AboutClientTab from './AboutClientTab.vue'
import BlackListClientsTab from './BlackListClientsTab.vue'
export default {
  components: {
    VueTabs,
    VTab,
    MainTab,
    BalanceTab,
    RatingTab,
    AboutClientTab,
    BlackListClientsTab
  },
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      dataForHeader: {},
      newTab: null,
      oldTab: null,
      mainInfo: {},
      modalInfo: {
        modalOpened: false,
        id: null,
        loading: true
      },
      listTabs: {
        mainTab: false,
        balanceTab: false,
        ratingTab: false,
        aboutClientTab: false,
        blackListTab: false
      },
      activeCurrentTab: 'mainTab',
      activateTab: null
    }
  },
  methods: {
    sendData() {
      this.$refs[this.activeCurrentTab].sendData(
        this.assignedTabChange,
        '',
        '',
        '',
        true
      )
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      if (oldTab.title !== newTab.title) {
        this.listTabs[oldTab.$attrs['data-name']] = true
        this.activeCurrentTab = newTab.$attrs['data-name']
        this.$refs[oldTab.$attrs['data-name']].sendData(
          this.assignedTabChange,
          oldTab.title,
          oldTab,
          newTab
        )
      }
    },
    assignedTabChange(
      isForward,
      linkToTabHasName,
      oldTab,
      newTab,
      flagIsClose
    ) {
      console.log(flagIsClose)
      if (newTab && oldTab) {
        if (isForward) {
          this.listTabs[newTab.$attrs['data-name']] = true
          this.listTabs[oldTab.$attrs['data-name']] = false
          this.modalInfo.loading = false
        } else {
          this.activateTab = linkToTabHasName
          this.listTabs[newTab.$attrs['data-name']] = false
          this.listTabs[oldTab.$attrs['data-name']] = true
          this.modalInfo.loading = false
        }
      } else {
        if (flagIsClose) {
          this.$modal.hide('clientsModal')
        }
      }
    },
    getInfo() {
      GeneralService.get(`/clients/${this.modalInfo.id}/title`)
        .then((data) => {
          this.dataForHeader = data.data.data
          console.log(this.dataForHeader)
        })
        .catch((e) => {
          console.log(e)
        })
      GeneralService.get(`/clients/${this.modalInfo.id}`)
        .then((data) => {
          console.log(data)
          this.dataForModal = data.data.data.rows[0]
        })
        .catch((e) => {
          console.log(e)
        })
    },
    beforeOpen(data) {
      this.modalInfo.modalOpened = true
      this.modalInfo.id = data.params?.id
      this.listTabs.mainTab = true
      this.getInfo()
    },
    beforeClose() {
      this.updatedValues = {}
      this.dataForModal = {}

      this.mainInfo = {}
      ;(this.modalInfo = {
        modalOpened: false,
        id: null,
        loading: true
      }),
        (this.activeCurrentTab = 'mainTab')
      this.activateTab = null
      Object.keys(this.listTabs).forEach((el, index) => {
        this.listTabs[el] = false
      })
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    }
  },
  mounted() {
    EventBus.$on('finishLoad', () => {
      this.modalInfo.loading = false
    })
    EventBus.$on('closeModal', () => {
      this.$modal.hide('clientsModal')
    })
    EventBus.$on('updateMainModalComponent', () => {
      this.getInfo()
    })
  },
  created() {},
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting'])
  }
}
</script>
