<template>
    <div>
        <BaseTopLine>
            <div class="d-flex gap-10 align-items-center mt-15">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('priceModal')">Редактировать коэффициенты
                </div>
                <!-- <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div> -->
            </div>
        </BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'"
                :click-handler="clickLoadData" :prev-text="'Назад'" :next-text="'Далее'"
                :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="priceData" :props-columns-setting="fieldsTablePrice" :selectRowActivateFlag="true"
                :modalActivateFlag="true" nameForAction="editCoeffModal" v-if="priceLoadStatus == 'success'">
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="priceModal" size="xl" @closeModal="closeModal">
                <div class="p-10">
                    <div class="d-flex gap-15 flex-column flex-lg-row">
                        <div class="wp-100 wp-xl-50">
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_t1" name-sign="Т1"
                                input-name="company_settings__coeff_t1">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_t2" name-sign="Т2"
                                input-name="company_settings__coeff_t2">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_t3" name-sign="Т3"
                                input-name="company_settings__coeff_t3">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_t4" name-sign="Т4"
                                input-name="company_settings__coeff_t4">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_t6" name-sign="Т5"
                                input-name="company_settings__coeff_t6">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_t6" name-sign="Т6"
                                input-name="company_settings__coeff_t6">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_hour" name-sign="С водителем"
                                input-name="company_settings__coeff_hour">
                            </BaseInput>
                        </div>
                        <div class="wp-100 wp-xl-50">
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_buyout_12" name-sign="Выкуп 12"
                                input-name="company_settings__coeff_buyout_12">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_buyout_24" name-sign="Выкуп 24"
                                input-name="company_settings__coeff_buyout_24">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_buyout_36" name-sign="Выкуп 36"
                                input-name="company_settings__coeff_buyout_36">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_fr_0" name-sign="ФР-0"
                                input-name="company_settings__coeff_fr_0">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_fr_dtp" name-sign="ФР-ДТП"
                                input-name="company_settings__coeff_fr_dtp">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_fr_ugon" name-sign="ФР-УГОН"
                                input-name="company_settings__coeff_fr_ugon">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_fr_total" name-sign="ФР-ТОТАЛ"
                                input-name="company_settings__coeff_fr_total">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue"
                                :value="tarrifs.company_settings__coeff_overrun" name-sign="Перепробег"
                                input-name="company_settings__coeff_overrun">
                            </BaseInput>
                        </div>
                    </div>
                    <div class="d-flex gap-10 mt-15">
                        <div class="btn btn-sm btn-primary" @click="sendDataFromModal">{{
                            dataLang.form_btn__btn_save }}</div>
                        <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal('priceModal')">{{
                            dataLang.form_btn__btn_caneсl }}</div>
                    </div>
                </div>
            </Modal>
            <Modal nameForAction="editCoeffModal" @closeModal="closeModal">
                <div class="p-10">
                    <!-- "company_settings__coeff_buyout_12": "274",
                "company_settings__coeff_buyout_24": "440",
                "company_settings__coeff_buyout_36": "606",
                "company_settings__coeff_fr_0": "1000",
                "company_settings__coeff_fr_dtp": "15",
                "company_settings__coeff_fr_total": "7",
                "company_settings__coeff_fr_ugon": "6",  company_settings__coeff_overrun-->
                    <BaseInput placeholder="" title="" @onInput="updateValue"
                        :value="dataForModal.vehicle_tariffs__coeff_cost" name-sign="Коэффицент стоимости"
                        input-name="vehicle_tariffs__coeff_cost">
                    </BaseInput>

                    <div class="d-flex gap-10 mt-15">
                        <div class="btn btn-sm btn-primary" @click="sendDataFromModal">{{
                            dataLang.form_btn__btn_save }}</div>
                        <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal('editCoeffModal')">{{
                            dataLang.form_btn__btn_caneсl }}</div>
                    </div>
                </div>
            </Modal>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import GlobalSettingService from '@/api/GlobalSettingService.js'
import EventBus from '@/event-bus.js'
export default {
    components: {
        Modal
    },

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            tarrifs: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getPriceAll({
                limit: 10,
                page: numPage
            }
            )
        },
        openModalForCreate(nameModal) {
            EventBus.$emit("openModal", { nameForAction: nameModal, mode: 'create' })
        },


        deleteActionAndModal() {
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { nameForAction: "acceptDelete", mode: 'create' });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },
        deleteRow(nameModal) {
            this.deletePriceData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },


        sendDataFromModal() {
            console.log(this.dataForModal);
            if (this.dataForModal.vehicle_tariffs__id) {
                console.log(this.updatedValues);
                this.editPriceMultiple({ id: this.dataForModal.vehicle_tariffs__id, dataEdit: this.updatedValues })
                this.clickLoadData(this.paginationPriceStateParams.page)
                EventBus.$emit("closeModal", { nameForAction: 'editCoeffModal' })
            }
            else {
                GlobalSettingService.updateSettings(this.updatedValues).then((data) => {
                    this.clickLoadData(this.paginationPriceStateParams.page)
                    this.$toast.success('Данные успешно сохранены')
                }).catch(err => this.$toast.error('Ошибка загрузки'))
                EventBus.$emit("closeModal", { nameForAction: 'priceModal' })
            }
        },


        updateValue(data) {
            this.updatedValues[data.name] = data.value
            this.tarrifs[data.name] = data.value
            console.log(this.updatedValues);
        },

        prepareKeysForModal(payloadData) {
            this.updatedValues = {}
            this.dataForModal = {}
            if (!payloadData) {
                for (const key in this.priceData[0]) {
                    this.dataForModal[key] = null
                }
            } else {
                this.dataForModal = Object.assign({}, payloadData)
            }

        },

        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
        },
        btnHandlerCloseModal(nameModal) {
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },


        ...mapActions("priceModule", ["getPriceAll", 'createPriceData', 'editPriceMultiple', 'deletePriceData', 'setPriceRoute']),


    },
    mounted() {

        EventBus.$on("openModal", (payload) => {
            this.editData = payload.data
            console.log(payload.data);
            this.prepareKeysForModal(payload.data)
        });
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.book_price__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getPriceAll({ params: { page: 1, limit: 10 } })
        GlobalSettingService.getAll().then((data) => {
            this.tarrifs = data.data.data.rows[0]
        }).catch((err) => this.$toast.error('Тарифы из настроек не загрузиись'))
    },
    computed: {
        ...mapState("priceModule", ["priceData", "priceLoadStatus", 'paginationPriceStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("priceModule", ["numPages"]),

        fieldsTablePrice() {

            // {
            //     "vehicle_tariffs__buyout_12": "6390",
            //     "vehicle_tariffs__buyout_24": "3980",
            //     "vehicle_tariffs__buyout_36": "2890",
            //     "vehicle_tariffs__coeff_cost": "1",
            //     "vehicle_tariffs__company_id": 2,
            //     "vehicle_tariffs__dtp": 116670,
            //     "vehicle_tariffs__fr_0": "1750",
            //     "vehicle_tariffs__id": 41,
            //     "vehicle_tariffs__overrun": 20,
            //     "vehicle_tariffs__per_hour": "3500",
            //     "vehicle_tariffs__pledge": null,
            //     "vehicle_tariffs__t1": 4380,
            //     "vehicle_tariffs__t2": 4060,
            //     "vehicle_tariffs__t3": 3880,
            //     "vehicle_tariffs__t4": 3190,
            //     "vehicle_tariffs__t5": 2920,
            //     "vehicle_tariffs__t6": 2700,
            //     "vehicle_tariffs__total": 250000,
            //     "vehicle_tariffs__ugon": 291670,
            //     "vehicle_tariffs__vehicle_id": 41,
            //     "vehicles__color": "color_black",
            //     "vehicles__company_id": 2,
            //     "vehicles__cost": 1750000,
            //     "vehicles__created_at": "2023-10-06 09:30:23",
            //     "vehicles__engine": null,
            //     "vehicles__engine_power": null,
            //     "vehicles__entrance_at": null,
            //     "vehicles__exit_at": null,
            //     "vehicles__fines_paid_until": null,
            //     "vehicles__flow": null,
            //     "vehicles__gosnumber": "\u0432961\u043c\u0441",
            //     "vehicles__has_driver": "vehicle_no_driver",
            //     "vehicles__hours_at_day": null,
            //     "vehicles__id": 41,
            //     "vehicles__is_active_fines": null,
            //     "vehicles__is_open": 0,
            //     "vehicles__mark_id": null,
            //     "vehicles__mileage_end": null,
            //     "vehicles__mileage_in": null,
            //     "vehicles__model": null,
            //     "vehicles__name": "Volkswagen Polo",
            //     "vehicles__odometr": null,
            //     "vehicles__owner_id": null,
            //     "vehicles__place_id": 1,
            //     "vehicles__power": null,
            //     "vehicles__pts": null,
            //     "vehicles__pts_date": null,
            //     "vehicles__pts_file": null,
            //     "vehicles__status_id": 1,
            //     "vehicles__sts": null,
            //     "vehicles__sts_date": null,
            //     "vehicles__sts_file": null,
            //     "vehicles__type_engine": null,
            //     "vehicles__type_id": 1,
            //     "vehicles__updated_at": "2023-10-06 09:30:23",
            //     "vehicles__vin": null,
            //     "vehicles__year_release": null
            // },

            return [
                { title: 'ID', field: "vehicle_tariffs__id", headerFilter: "input" },
                {
                    title: 'Транспорт', field: "vehicles__name", headerFilter: "input", mutator: (value, data) => {
                        return `${value} (id${data.vehicles__id})`
                    }
                },
                { title: 'Госномер', field: "vehicles__gosnumber", headerFilter: "input" },
                { title: 'Цена', field: "vehicles__cost", headerFilter: "input" },
                { title: 'Кф. Стоимости', field: "vehicle_tariffs__coeff_cost", headerFilter: "input" },
                { title: 'T1', field: "vehicle_tariffs__t1", headerFilter: "input" },
                { title: 'T2', field: "vehicle_tariffs__t2", headerFilter: "input" },
                { title: 'T3', field: "vehicle_tariffs__t3", headerFilter: "input" },
                { title: 'T4', field: "vehicle_tariffs__t4", headerFilter: "input" },
                { title: 'T5', field: "vehicle_tariffs__t5", headerFilter: "input" },
                { title: 'T6', field: "vehicle_tariffs__t6", headerFilter: "input" },
                { title: 'С водителем', field: "vehicle_tariffs__per_hour", headerFilter: "input" },
                { title: 'Перепробег', field: "vehicle_tariffs__overrun", headerFilter: "input" },
                { title: 'ФР-ДТП', field: "vehicle_tariffs__dtp", headerFilter: "input" },
                { title: 'ФР-УГОН', field: "vehicle_tariffs__ugon", headerFilter: "input" },
                { title: 'ФР-ТОТАЛ', field: "vehicle_tariffs__total", headerFilter: "input" },
                { title: 'ФР-0', field: "vehicle_tariffs__fr_0", headerFilter: "input" },
                { title: 'Выкуп 12', field: "vehicle_tariffs__buyout_12", headerFilter: "input" },
                { title: 'Выкуп 24', field: "vehicle_tariffs__buyout_24", headerFilter: "input" },
                { title: 'Выкуп 36', field: "vehicle_tariffs__buyout_36", headerFilter: "input" },




                // "vehicle_tariffs__total": 250000,
                //     "vehicle_tariffs__ugon": 291670,
                //  "vehicle_tariffs__buyout_12": "6390",
                //     "vehicle_tariffs__buyout_24": "3980",
                //     "vehicle_tariffs__buyout_36": "2890",
                //     "vehicle_tariffs__coeff_cost": "1",
                //     "vehicle_tariffs__company_id": 2,
                //     "vehicle_tariffs__dtp": 116670,
                //     "vehicle_tariffs__fr_0": "1750",
            ]
        }
    },
};
</script>
    
    