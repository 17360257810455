<template>
  <div class="px-30 py-10 h-100" ref="triesTab">
    <div class="row gx-30" v-if="!loadingState">
      <div class="col-4">
        <div class="system-block">
          <p class="fw-bolder fsc-12 mb-10 text-dark">
            {{ dataLang['vehicle_modal_tires_modals_text1'] }}
          </p>
          <div class="wp-100 mb-15">
            <BaseRadioLikeCheckbox
              v-if="triesList"
              placeholder=""
              class="mb-10"
              input-name="isActive"
              v-model="triesList[0].isActive"
              signClass="fsc-12 fw-bolder text-dark"
              @oncheckeds="updateValue"
              :additionalParams="{ ...triesList[1], once: 'first' }"
              name="isActive"
              >{{
                dataLang['vehicle_modal_tires_text1']
              }}</BaseRadioLikeCheckbox
            >
          </div>

          <div ref="vehicle_tires__name" class="mb-10">
            <BaseInputNew
              placeholder=""
              :width="'284'"
              input-name="vehicle_tires__name"
              @input="updateValue"
              :horizontalTitle="true"
              :value="triesList[0].vehicle_tires__name"
              :additionalParams="triesList[0]"
              :name-sign="dataLang['vehicle_modal_tires_text3']"
            >
            </BaseInputNew>
          </div>
          <div ref="vehicle_tires__size" class="mb-10">
            <BaseInputNew
              placeholder="000/00/00"
              :width="'284'"
              input-name="vehicle_tires__size"
              @input="updateValue"
              :horizontalTitle="true"
              :value="triesList[0].vehicle_tires__size"
              :additionalParams="triesList[0]"
              inputRef="size1"
              inputMask="000/00/00"
              :name-sign="dataLang['vehicle_modal_tires_text4']"
              maxLength="9"
            >
            </BaseInputNew>
          </div>
          <div class="d-flex gap-5 mb-10 align-items-center">
            <div
              ref="vehicle_tires__bought_at"
              class="d-flex gap-5 justify-content-between align-items-center"
            >
              <span class="text-muted fsc-12">
                {{ dataLang['vehicle_modal_tires_text5'] }}
              </span>
              <div>
                <BaseDatePicker
                  v-model="triesList[0].vehicle_tires__bought_at"
                  type="date"
                  class="fsc-11 idiots-class-for-input-date"
                  lang="ru"
                  id="vehicle_tires__bought_at"
                  style="width: 100px"
                  value-type="format"
                  :format="userSetting.company__tempalte_date"
                  @change="
                    (date) =>
                      changeDate(date, 'vehicle_tires__bought_at', triesList[0])
                  "
                ></BaseDatePicker>
              </div>
            </div>
            <div ref="vehicle_tires__odometr">
              <BaseInputNew
                placeholder=""
                :width="'100'"
                input-name="vehicle_tires__odometr"
                @input="updateValue"
                :horizontalTitle="true"
                :value="triesList[0].vehicle_tires__odometr"
                :additionalParams="triesList[0]"
                :name-sign="dataLang['vehicle_modal_tires_text6']"
              >
              </BaseInputNew>
            </div>
          </div>
          <div ref="vehicle_tires__is_another_disks" class="wp-100">
            <BaseCheckboxV2
              placeholder=""
              class="mb-10"
              input-name="vehicle_tires__is_another_disks"
              v-model="triesList[0].vehicle_tires__is_another_disks"
              signClass="fsc-12 "
              @oncheckeds="updateValue"
              :additionalParams="triesList[0]"
              >{{ dataLang['vehicle_modal_tires_text7'] }}</BaseCheckboxV2
            >
          </div>
          <a
            target="_blank"
            :href="`${API_PATH}/objects/${currentOpenedId}/tires/${triesList[0].vehicle_tires__id}/sticker?employee-token=${currentUserToken}`"
            class="base-button base-button--action py-15 gap-15 mb-10"
          >
            <span class="fsc-14">
              {{ dataLang['vehicle_modal_tires_text10'] }}
            </span>
          </a>
          <div
            @click="$modal.show('addNewTriesModal', {...triesList[0]})"
            class="text-center fsc-14 rounded-5 py-15 bg-primary cursor-pointer text-dark"
          >
            {{ dataLang['vehicle_modal_tires_text11'] }}
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="system-block">
          <p class="fw-bolder fsc-12 mb-10 text-dark">
            {{ dataLang['vehicle_modal_tires_modals_text2'] }}
          </p>
          <div class="wp-100 mb-15">
            <BaseRadioLikeCheckbox
              placeholder=""
              class="mb-10"
              v-if="triesList"
              input-name="isActive"
              v-model="triesList[1].isActive"
              signClass="fsc-12 fw-bolder text-dark"
              @oncheckeds="updateValue"
              :additionalParams="{ ...triesList[1], once: 'second' }"
              name="isActive"
              >{{
                dataLang['vehicle_modal_tires_text1']
              }}</BaseRadioLikeCheckbox
            >
          </div>

          <div ref="vehicle_tires__name" class="mb-10">
            <BaseInputNew
              placeholder=""
              :width="'284'"
              input-name="vehicle_tires__name"
              @input="updateValue"
              :horizontalTitle="true"
              :value="triesList[1].vehicle_tires__name"
              :additionalParams="triesList[1]"
              :name-sign="dataLang['vehicle_modal_tires_text3']"
            >
            </BaseInputNew>
          </div>
          <div ref="vehicle_tires__size" class="mb-10">
            <BaseInputNew
              placeholder="000/00/00"
              :width="'284'"
              input-name="vehicle_tires__size"
              @input="updateValue"
              :horizontalTitle="true"
              :value="triesList[1].vehicle_tires__size"
              :additionalParams="triesList[1]"
              inputRef="size2"
              inputMask="000/00/00"
              :name-sign="dataLang['vehicle_modal_tires_text4']"
              maxLength="9"
            >
            </BaseInputNew>
          </div>
          <div class="d-flex gap-5 mb-10 align-items-center">
            <div
              ref="vehicle_tires__bought_at"
              class="d-flex gap-5 justify-content-between align-items-center"
            >
              <span class="text-muted fsc-12">
                {{ dataLang['vehicle_modal_tires_text5'] }}
              </span>
              <div>
                <BaseDatePicker
                  v-model="triesList[1].vehicle_tires__bought_at"
                  type="date"
                  class="fsc-11 idiots-class-for-input-date"
                  lang="ru"
                  id="vehicle_tires__bought_at"
                  style="width: 100px"
                  value-type="format"
                  :format="userSetting.company__tempalte_date"
                  @change="
                    (date) =>
                      changeDate(date, 'vehicle_tires__bought_at', triesList[1])
                  "
                ></BaseDatePicker>
              </div>
            </div>
            <div ref="vehicle_tires__odometr">
              <BaseInputNew
                placeholder=""
                :width="'100'"
                input-name="vehicle_tires__odometr"
                @input="updateValue"
                :horizontalTitle="true"
                :value="triesList[1].vehicle_tires__odometr"
                :additionalParams="triesList[1]"
                :name-sign="dataLang['vehicle_modal_tires_text6']"
              >
              </BaseInputNew>
            </div>
          </div>
          <div class="d-flex">
            <div ref="vehicle_tires__is_another_disks" class="wp-60">
              <BaseCheckboxV2
                placeholder=""
                class="mb-10"
                input-name="vehicle_tires__is_another_disks"
                v-model="triesList[1].vehicle_tires__is_another_disks"
                signClass="fsc-12 "
                :additionalParams="triesList[1]"
                @oncheckeds="updateValue"
                >{{ dataLang['vehicle_modal_tires_text8'] }}</BaseCheckboxV2
              >
            </div>
            <div ref="vehicle_tires__is_thorns" class="wp-40">
              <BaseCheckboxV2
                placeholder=""
                class="mb-10"
                input-name="vehicle_tires__is_thorns"
                v-model="triesList[1].vehicle_tires__is_thorns"
                signClass="fsc-12 "
                :additionalParams="triesList[1]"
                @oncheckeds="updateValue"
                >{{ dataLang['vehicle_modal_tires_text9'] }}</BaseCheckboxV2
              >
            </div>
          </div>

          <a
            target="_blank"
            :href="`${API_PATH}/objects/${currentOpenedId}/tires/${
              triesList[1].vehicle_tires__id
            }/sticker?employee-token=${JSON.stringify(currentUserToken)}`"
            class="base-button base-button--action py-15 gap-15 mb-10"
          >
            <span class="fsc-14">
              {{ dataLang['vehicle_modal_tires_text10'] }}
            </span>
          </a>
          <div
            @click="$modal.show('addNewTriesModal', {...triesList[1]})"
            class="text-center fsc-14 rounded-5 py-15 bg-primary cursor-pointer text-dark"
          >
            {{ dataLang['vehicle_modal_tires_text11'] }}
          </div>
        </div>
      </div>
    </div>
    <AddNewTriesModal
      :optionalParams="{ id: currentOpenedId }"
    ></AddNewTriesModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ObjectsService from '@/api/ObjectsService'

import AddNewTriesModal from '@/components/vehicles-page-single-modal-inner/AddNewTriesModal.vue'
import httpInstance from '@/http-common.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import EventBus from '@/event-bus'
import customParseFormat from 'dayjs/plugin/customParseFormat'
//   import ObjectsService from '@/api/ObjectsService'
//   import EventBus from '@/event-bus.js'
//   import SingleModal from '@/components/SingleModal.vue'
export default {
  components: {
    AddNewTriesModal
  },
  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      loadingState: true,
      triesListForSend: [],
      triesList: [],
      API_PATH: '',
      updatedValues: {},
      currentActiveTires: ''
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      if (Object.keys(this.updatedValues).length) {
        ObjectsService.update(this.currentOpenedId, {
          ...{
            ...(this.currentActiveTires && {
              vehicle_cars__tires_id: this.currentActiveTires
            }),
            tires: this.triesListForSend
          }
        })
          .then((data) => {
            console.log(data)
            this.$toast.success(data.data.message)
            EventBus.$emit('contentLoaded')
            EventBus.$emit('updateContentData')
            callback(true, nameTab, oldTab, newTab, flagIsClose)
          })
          .catch((error) => {
            this.$toast.error(error.response.data.message)
            callback(false, nameTab, oldTab, newTab)
          })
      } else {
        EventBus.$emit('contentLoaded')
        EventBus.$emit('updateContentData')
        callback(true, nameTab, oldTab, newTab, flagIsClose)
      }
    },
    changeDate(date, nameField, additionalParams) {
      this.updatedValues[nameField] = date
      this.findAndReplaceDataForSend(
        additionalParams,
        nameField,
        dayjs(date, `${this.userSetting.company__tempalte_date}`)
          .utc(true)
          .utcOffset(Number(this.userSetting.company__utc))
          .format(`YYYY-MM-DD HH:mm:ss`)
      )
      this.updatedValues[nameField] = date
    },
    updateValue(data, www) {
      this.findAndReplaceDataForSend(data, data.name, data.value)
      console.log(data, this.triesListForSend)
      this.updatedValues[data.name] = data.value

      //
      if (data.once == 'first') {
        this.currentActiveTires = data.vehicle_tires__id
        this.triesListForSend[1].isActive = 1
        this.triesListForSend[0].isActive = 0
      } else {
        this.currentActiveTires = data.vehicle_tires__id
        this.triesListForSend[0].isActive = 1
        this.triesListForSend[1].isActive = 0
      }

      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
    },
    findAndReplaceDataForSend(objectForCompare, field, rewriteData) {
      console.log(objectForCompare)
      let inSend = this.triesListForSend.findIndex(
        (x) => x.id == objectForCompare.vehicle_tires__id
      )
      // vehicle_tires__size
      if (inSend !== -1) {
        this.triesListForSend[inSend][field] = rewriteData
      }
      if (inSend !== -1 && field == 'vehicle_tires__size') {
        this.triesList[inSend]['vehicle_tires__size'] = rewriteData
      }
    },
    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },
  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)
    switch (process.env.VUE_APP_MODE_BUILD) {
      case 'development':
        this.API_PATH = 'http://api.yandexrent.development'
        break
      case 'production':
        this.API_PATH = 'https://api.yandexrent.ru'
        break
      case 'testing':
        this.API_PATH = 'https://api.yandex-rent.ru'
        break
      case 'development-testing':
        this.API_PATH = 'http://api.yandexrent.development'
        break
    }
    Promise.all([
      ObjectsService.getAll({ url: `/${this.currentOpenedId}/tires` })
        .then((data) => {
          console.log(data)

          data.data.data.rows.forEach((element) => {
            this.triesList.push({
              ...element,
              vehicle_tires__bought_at: dayjs(
                element.vehicle_tires__bought_at
              ).format(`${this.userSetting.company__tempalte_date}`)
            })
            this.triesListForSend.push({
              id: element.vehicle_tires__id,
              isActive: element.isActive
            })
            console.log(element)
          })
          console.log(this.triesList)
        })
        .catch((err) => {
          // console.log(err)
        })
    ]).then(() => {
      console.log('This end')
      this.$emit('loadingModal', false)
      this.loadingState = false
    })
  },
  mounted() {
    dayjs.extend(utc)
    dayjs.extend(customParseFormat)
  },
  computed: {
    currentDomain() {
      return 'https://' + location.hostname
    },
    currentUserToken() {
      return JSON.parse(localStorage.getItem('user-token'))
    },
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('transportVehiclesModule', ['numPages']),
    ...mapState('userSettingModule', ['userSetting'])
  }
}
</script>
