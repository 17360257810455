<template>
  <!-- <footer v-once class="footer container-fluid">
    <div class="footer-contacts">
      <span class="footer-contacts__name">Тех.поддержка</span>
      <a href="tel:+7(495)138-28-60" class="footer-contacts__tel-number"
        >+7(495)138-28-60</a
      >
    </div>
    <p class="footer__desc">

    </p>
  </footer> -->
</template>

    <script>
export default {};
</script>
