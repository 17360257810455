import http from "@/http-common.js";

class EmployeeAccessService {
  basePathApiService = "/employee-rules";
  getAll(id) {
    return http.get(this.basePathApiService +  '/' + id);
  } 
  update(createData) {
    return http.post(this.basePathApiService, createData);
  }
//   update(id, updateData) {
//     return http.put(this.basePathApiService + `/${id}`, updateData);
//   }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
}
export default new EmployeeAccessService();
