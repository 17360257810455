<template>
  <div>
    <BaseTopLine></BaseTopLine>
    <div class="px-20 pt-10">
      <div class="base-button px-20 rounded-10" @click="deleteActionAndModal">
        {{ dataLang['registry_ads_text2'] }}
      </div>
    </div>
    <div class="p-20 position-relative">
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <!-- <BasePagination
        :page-count="numPages"
        :page-range="3"
        :margin-pages="2"
        :break-view-class="'d-none'"
        :click-handler="clickLoadData"
        :prev-text="'Назад'"
        :next-text="'Далее'"
        :container-class="'pagination pagination-sm'"
        :page-class="'page-item'"
        :pageLinkClass="'page-link'"
      >
      </BasePagination> -->

      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="dataTable"
        :border-x="true"
        :border-y="true"
        :sort-option="sortOption"
        ref="serviceTable"
        row-key-field-name="request_services__id"
        :checkbox-option="{
          hideSelectAll: true,
          selectedRowChange: selectedRowChange,
          selectedAllChange: selectedAllChange
        }"
        :eventCustomOption="eventCustomOption"
        v-if="!localLoading"
        :clickHighlight="false"
        :rowStyleOption="{
          hoverHighlight: true
        }"
        :cellSelectionOption="{ enable: false }"
      />
    </div>

    <BaseModal
      name="showInfoBidOsago"
      width="450"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="beforeClose"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="py-20 px-30 rounded-25 bg-white">
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('showInfoBidOsago')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">
            {{ dataLang['bids_text5'] }}
          </p>

          <div
            class="text-center fw-semi-bold fsc-14 py-5 bg-system-secondary wp-100 mb-15"
            v-html="textForModal"
          ></div>
          <div class="d-flex justify-content-end gap-15">
            <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('showInfoBidOsago')"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="acceptDeletes"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeletes')"
          ></button>
        </div>
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">
            {{ dataLang.confirm_btn__btn_yes }}
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import BidsService from '@/api/BidsService.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
export default {
  components: {
    VeTable
  },

  props: {},
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      textForModal: null,
      editData: {},
      activeSelectRow: null,
      dataTable: null,
      activeSelectRowsMultiple: [],
      localLoading: true,
      sortOption: {
        sortChange: (params) => {
          console.log('sortChange::', params)
          this.sortChange(params)
        }
      },
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('showInfoBidOsago')
              this.textForModal = row.request_services__text + row.request_services__crm_text
              console.log(this.dataForModal)
            }
          }
        }
      }
    }
  },
  methods: {
    beforeClose() {},
    beforeOpen() {},

    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDeletes')
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    // deleteRow(nameModal) {
    //   this.deleteTransportVehiclesData(this.activeSelectRowsMultiple)
    //   this.activeSelectRow = null
    //   this.$modal.hide('acceptDeletes')
    // },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
      console.log(this.activeSelectRowsMultiple)
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys)
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    clickLoadData(numPage) {
      this.getBidsOsagoAll({
        params: {
          limit: 10,
          page: numPage
        }
      })
    },

    deleteRow(nameModal) {
      this.localLoading = true
      this.activeSelectRowsMultiple.forEach((el, index) => {
        let indexed = this.dataTable.findIndex(
          (x) => x.request_services__id == el
        )
        delete this.dataTable[indexed]
      })
      BidsService.delete(this.activeSelectRowsMultiple)
        .then((data) => {
          console.log(data)
          this.$toast.success(data.data.message)

          BidsService.getAll({
            params: {
              where: JSON.stringify([
                { field: 'request_services__type_service_id', value: 1 }
              ])
            }
          })
            .then((data) => {
              console.log(data)
              this.localLoading = false
              this.dataTable = data.data.data.rows
            })
            .catch((error) => {
              console.log(error.response.data.errors)
            })
        })
        .catch((error) => {
          console.log(error.response.data.errors)
          //   this.validateData(error.response.data.errors)
          //   this.$toast.error(error.response.data.message)
          //   callback(false, nameTab, oldTab, newTab)
        })
      this.localLoading = false
      this.activeSelectRowsMultiple = []
      this.$modal.hide('acceptDeletes')
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
    }

    // ...mapActions('bidsOsagoModule', [
    //   'getBidsOsagoAll',
    //   'createBidsOsagoData',
    //   'editBidsOsagoMultiple',
    //   'deleteBidsOsagoData',
    // ]),
  },
  mounted() {
    dayjs.extend(utc)
  },

  created() {
    // ?where=[{"field":"request_services__type_service_id","value":8}]
    BidsService.getAll({
      params: {
        where: JSON.stringify([
          { field: 'request_services__type_service_id', value: 1 }
        ])
      }
    })
      .then((data) => {
        console.log(data)
        this.localLoading = false
        this.dataTable = data.data.data.rows
      })
      .catch((error) => {
        console.log(error.response.data.errors)
      })
    // this.getBidsOsagoAll({
    //   params: {
    //     page: 1,
    //     limit: 10,
    //   },
    // })
  },
  //   {
  //   "request_services__company_id": 2,
  //   "request_services__created_at": "2024-01-10 12:23:20",
  //   "request_services__crm_text": "\n        СЛУЖЕБНАЯ ИНФОРМАЦИЯ. <br>\n        =================================================\n        Компания (id) - 2 <br>\n        Компания (название) - ООО \"А7\" <br>\n        Компания (телефон) - +7(495)138-28-60 <br>\n        Компания (email) - grishka2500@gmail.com <br>\n        =================================================\n        Сотрудник (id) - 2 <br>\n        Сотрудник (фио) - Администратор компании <br>\n        Сотрудник (телефон) -  <br>\n        Сотрудник (email) - it@agelar.ru <br>\n        =================================================\n        \n        Транспорт (id) - 224 <br>\n        Транспорт (марка модель) - Ferrari FF <br>\n        Транспорт (госномер) - У662ХК790 <br>\n        Транспорт (год) - 2013 <br>\n        Транспорт (vin) - апрапапр <br>\n        =================================================        \n        \n        Владелец (id) - 1 <br>\n        Владелец (тип) - физ <br>\n        Владелец (имя/название) - Собственник Игорь Петрович <br>\n        Владелец (адрес) - г Город ул Улица 55 <br>\n        Владелец (телефон) - +7(000)000-00-000 <br>\n        =================================================        \n        ",
  //   "request_services__id": 63,
  //   "request_services__text": "\n        Дата начала полиса - 2024-09-01 <br>\n        Город - fghfgh <br>\n        Неограниченное кол-во водителей - Да <br>\n        =================================================        \n        \n        Коментарий <br>\n        fghfghh <br>\n        =================================================        \n        ",
  //   "request_services__type_service_id": 1,
  //   "request_services__updated_at": null,
  //   "request_service_types__description": "Заявки на осаго",
  //   "request_service_types__id": 1,
  //   "request_service_types__model": null,
  //   "request_service_types__name": "bid_osago"
  // }

  computed: {
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('translationModule', ['dataLang']),
    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: '3%',
          align: 'center'
        },
        {
          field: 'request_services__id',
          key: '1',
          title: this.dataLang['bids_text2'],
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },

        {
          field: 'request_services__created_at',
          key: '2',
          title: this.dataLang['bids_text3'],
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.request_services__created_at
                  ? dayjs(row.request_services__created_at)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(`${this.userSetting.company__tempalte_date} HH:mm`)
                  : ''}
              </span>
            )
          }
          //   renderHeaderCell: ({ column }, h) => {
          //     return (
          //       <div>
          //         <div class={'text-center'}>{column.title}</div>

          //         {/* <div style={'height: 19.5px'}></div> */}
          //       </div>
          //     )
          //   },
        },
        {
          field: 'request_service_types__description',
          key: '3',
          title: this.dataLang['bids_text4'],
          align: 'left',
          sortBy: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          }
          //   renderHeaderCell: ({ column }, h) => {
          //     return (
          //       <div>
          //         <div class={'text-center'}>{column.title}</div>
          //       </div>
          //     )
          //   },
        },
        // {
        //   field: 'request_services__text',
        //   key: '4',
        //   title: this.dataLang['bids_text5'],
        //   align: 'left',
        //   sortBy: '',
        //   ellipsis: {
        //     showTitle: true,
        //     lineClamp: 1
        //   },
        //   renderBodyCell: ({ row, column, rowIndex }, h) => {
        //     return <span>{row.request_services__text}</span>
        //   }
        // }
      ]
    }
    // ...mapState('bidsOsagoModule', [
    //   'bidsOsagoData',
    //   'bidsOsagoLoadStatus',
    //   'paginationbidsOsagoStateParams',
    // ]),
    // ...mapState('translationModule', ['dataLang']),
    // ...mapGetters('bidsOsagoModule', ['numPages']),
  }
}
</script>
