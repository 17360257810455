import http from "@/http-common.js";

class TasksService {
  basePathApiService = "/tasks";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  editMultiple(id, editData) {
    return http.put(this.basePathApiService + `/${id}`, editData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
}
export default new TasksService();
