<template>
    <div>
        <BaseTopLine></BaseTopLine>
        <div class="p-20">
            <div class="chart-container">
                <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" 
                   />
            </div>
        </div>
    </div>
</template>
    
    
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

import EventBus from '@/event-bus.js'

export default {
    components: {
        LineChartGenerator
    },
//     {
//     "statistics__company_id": 2,
//     "statistics__created_at": "2023-09-14",
//     "statistics__id": 1,
//     "info": {
//         "statistics__stat_1": "4",
//         "statistics__stat_2": "0",
//         "statistics__stat_3": "2",
//         "statistics__stat_4": "0",
//         "statistics__stat_5": "3"
//     }
// }
    props: {},
    data() {
        return {
            fullSummary: [

            ],

            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'ddd',
                    
                ],
                datasets: [
                    {
                        
                        // backgroundColor: '#f87979',
                        data: [40, 39, 10, 40, 39, 80, 40, 11]
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    methods: {

        ...mapActions("fullSummaryModule", ["getFullSummaryAll"]),
    },
    mounted() {
        // this.fullSummaryData.forEach((elem, index) => {
        //     console.log(elem);
        // })
        console.log(this.fullSummaryData);
    },

    created() {
        this.getFullSummaryAll(
            // { params: { page: 1, limit: 10 }}
        )
    },

    computed: {
        ...mapState("fullSummaryModule", ["fullSummaryData", "fullSummaryLoadStatus"]),
        ...mapState("translationModule", ["dataLang"]),
    },
};
</script>
    
    