var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseTopLine'),_c('div',{staticClass:"px-20 pt-10"},[_c('div',{staticClass:"base-button px-20 rounded-10",on:{"click":_vm.deleteActionAndModal}},[_vm._v(" "+_vm._s(_vm.dataLang['registry_ads_text2'])+" ")])]),_c('div',{staticClass:"p-20 position-relative"},[(_vm.localLoading)?_c('div',{staticClass:"position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white",staticStyle:{"top":"0","left":"0","z-index":"500"}},[_c('svg',{staticClass:"spinner",attrs:{"viewBox":"0 0 50 50"}},[_c('circle',{staticClass:"path",attrs:{"cx":"25","cy":"25","r":"20","fill":"none","stroke-width":"5"}})])]):_vm._e(),(!_vm.localLoading)?_c('ve-table',{ref:"serviceTable",staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"table-data":_vm.dataTable,"border-x":true,"border-y":true,"sort-option":_vm.sortOption,"row-key-field-name":"request_services__id","checkbox-option":{
        hideSelectAll: true,
        selectedRowChange: _vm.selectedRowChange,
        selectedAllChange: _vm.selectedAllChange
      },"eventCustomOption":_vm.eventCustomOption,"clickHighlight":false,"rowStyleOption":{
        hoverHighlight: true
      },"cellSelectionOption":{ enable: false }}}):_vm._e()],1),_c('BaseModal',{attrs:{"name":"showInfoBidOsago","width":"450","clickToClose":false,"shiftY":0.5,"adaptive":true,"height":"auto","classes":"bg-transparent shadow-none"},on:{"before-close":_vm.beforeClose}},[_c('div',{staticClass:"py-20 px-30 rounded-25 bg-white"},[_c('div',{staticClass:"d-flex justify-content-end"},[_vm._t("modal-header"),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.hide('showInfoBidOsago')}}})],2),_c('div',{staticClass:"modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"},[_c('p',{staticClass:"fw-bold mb-30 text-center"},[_vm._v(" "+_vm._s(_vm.dataLang['bids_text5'])+" ")]),_c('div',{staticClass:"text-center fw-semi-bold fsc-14 py-5 bg-system-secondary wp-100 mb-15",domProps:{"innerHTML":_vm._s(_vm.textForModal)}}),_c('div',{staticClass:"d-flex justify-content-end gap-15"},[_c('div',{staticClass:"base-button px-20 rounded-10",on:{"click":function($event){return _vm.$modal.hide('showInfoBidOsago')}}},[_vm._v(" "+_vm._s(_vm.dataLang['vehile_modal_btn_reset'])+" ")])])])])]),_c('BaseModal',{attrs:{"name":"acceptDeletes","width":"420","clickToClose":false,"shiftY":0.5,"adaptive":true,"height":"auto","classes":"bg-transparent shadow-none"}},[_c('div',{staticClass:"bg-white rounded-25 py-25 px-20"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.hide('acceptDeletes')}}})]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.dataLang.confirm_dialog_delete))]),_c('div',{staticClass:"d-flex justify-content-center mt-10"},[_c('div',{staticClass:"btn btn-sm btn-danger",on:{"click":_vm.deleteRow}},[_vm._v(" "+_vm._s(_vm.dataLang.confirm_btn__btn_yes)+" ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }