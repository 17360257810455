<template>
  <div class="position-relative">
    <BaseLoader v-if="localLoading"></BaseLoader>
    <BaseTopLine> </BaseTopLine>
    <div class="position-relative h-100 container-fluid">
      <div>
        <div class="d-flex justify-content-between py-15">
          <div class="d-flex gap-10 align-items-center">
            <div
              class="base-button base-button--action"
              @click="toAnketa('yandexrent_security_client')"
              style="padding: 10px 8px"
            >
              <span class="fsc-11" style="line-height: 13px">{{
                dataLang['clients_register_text_99']
              }}</span>
            </div>
            <div
              class="base-button base-button--action"
              @click="toAnketa('base_security_client')"
              style="padding: 10px 8px"
            >
              <span class="fsc-11" style="line-height: 13px">{{
                dataLang['clients_register_text_100']
              }}</span>
            </div>
            <div
              class="base-button base-button--action"
              @click="acceptDelete"
              style="padding: 10px 8px"
            >
              <span class="fsc-11" style="line-height: 13px"
                >{{ dataLang['clients_register_text_84'] }} (PIN)</span
              >
            </div>

            <div
              class="base-button base-button--action"
              style="padding: 10px 8px"
              @click="$modal.show('modalSmsPresets')"
            >
              <span class="fsc-11" style="line-height: 13px">{{
                dataLang['orders_page_btn_text1']
              }}</span>
            </div>

            <div
              class="base-button base-button--action"
              style="padding: 10px 8px"
              @click="$modal.show('modalSendSomeSMS')"
            >
              <span class="fsc-11" style="line-height: 13px">{{
                dataLang['orders_page_btn_text3']
              }}</span>
            </div>
            <div
              class="base-button base-button--action"
              @click="toExcel"
              style="padding: 10px 8px"
            >
              <img
                :src="require(`@/assets/icons/to-exel.svg`)"
                width="13.5"
                height="13.5"
              />
              <span class="fsc-11" style="line-height: 13px">{{
                dataLang['clients_register_text_104']
              }}</span>
            </div>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <input
              type="text"
              class="border border-system-component rounded-5 fsc-11"
              ref="globalFilter"
              style="padding: 10px 8px; width: 297px; line-height: 13px"
              :placeholder="dataLang['clients_register_text_105']"
              @input="
                (e) =>
                  fillFilter({
                    value: e.target.value,
                    name: 'OR',
                    type: 'input'
                  })
              "
            />
            <div
              class="base-button base-button--action"
              @click="loadData(1)"
              style="padding: 10px 8px"
            >
              <span class="fsc-11" style="line-height: 13px">{{
                dataLang['clients_register_text_106']
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-15 mb-5">
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationClientsStateParams"
          :showLimitInput="false"
          @updateLimitValue="updateLimitValue"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="dataTable"
        :border-x="true"
        :border-y="true"
        row-key-field-name="clients__id"
        ref="mainTable"
        :eventCustomOption="eventCustomOption"
        :checkbox-option="{
          hideSelectAll: false,
          selectedRowChange: selectedRowChange,
          selectedAllChange: selectedAllChange
        }"
        v-if="!localLoading"
        :clickHighlight="false"
        :cell-style-option="cellStyleOption"
        :rowStyleOption="{
          hoverHighlight: true
        }"
        :cellSelectionOption="{ enable: false }"
      >
      </ve-table>
    </div>
    <BaseModal
      name="acceptDeleteWithPin"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="pincodeModalHandler"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteWithPin')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">Внимание</p>

          <div class="d-flex flex-column align-items-center">
            <!-- <div
                class="base-button px-20 rounded-10"
                @click="$modal.hide('acceptDeleteWithPin')"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div> -->
            <p class="text-center">{{ messageError }}</p>
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              :input-name="'pincode'"
              @input="(data) => (pincode = data.value)"
              value=""
              name-sign=""
            >
            </BaseInputNew>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('acceptDeleteWithPin')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="changeMethod(currentMethodType)"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                Выполнить
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <SmsPresetsModal :type-reestr="2" />
    <SendSomeSms />
    <ClientsModal></ClientsModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import SelectsService from '@/api/SelectsService'
import GeneralService from '@/api/GeneralService'
import SmsPresetsModal from './orders-modal/SmsPresetsModal.vue'
import EventBus from '@/event-bus.js'
import SendSomeSms from '@/components/reuse_modal/SendSomeSms.vue'
import SSEEventSource from '@/sse-instance.js'
import ClientsModal from './clients-modal/ClientsModal.vue'
export default {
  components: {
    VeTable,
    SendSomeSms,
    ClientsModal,
    SmsPresetsModal
  },

  props: {},
  data() {
    return {
      params: {
        page: 1
      },
      pincode: '',
      messageError: [],
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      activePagePagination: {},
      currentOpenedId: null,
      mainModalOpened: false,
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.currentOpenedId = row.orders__id
              this.$modal.show('clientsModal', {
                id: row.clients__id
              })
            }
          }
        }
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          //   if (column.field == 'order_statuses__status') {
          //     return row.order_statuses__status
          //   }
        }
      },
      limitPaginationValue: 10
    }
  },
  methods: {
    updateLimitValue(data) {
      this.limitPaginationValue = data

      this.loadData(1)
    },
    toAnketa(type) {
      this.localLoading = true
      console.log(this.activeSelectRowsMultiple)
      if (this.activeSelectRowsMultiple.length == 1) {
        GeneralService.editOrCreate('/anketa-security', {
          clientsIds: this.activeSelectRowsMultiple[0],
          typeSecurity: type
        })
          .then((data) => {
            this.localLoading = false
            this.activeSelectRowsMultiple = []
            this.$toast.success(data.data.message)
            this.loadData(1)
          })
          .catch((e) => {
            console.log(e)
            this.activeSelectRowsMultiple = []
            this.$toast.error(e.response.data.errors[0].message)
            this.loadData(1)
            // console.log(e.response.status)
            // if (e.response.status == 409) {
            //   this.currentMethodType = 'delete'
            //   this.$modal.show('acceptDeleteWithPin', { type: 'delete' })
            //   this.messageError = e.response.data.message
            //   this.$toast.error(e.response.data.message)
            // } else if (e.response.status == 400) {
            //   this.messageError = e.response.data.message
            //   this.$toast.error(e.response.data.message)
            //   this.$modal.hide('acceptDeleteWithPin')
            // }
          })
      } else if (this.activeSelectRowsMultiple.length == 0) {
        this.$toast.error('Выберите хотя-бы одно поле')
        this.localLoading = false
      } else {
        this.$toast.error('Можно отправить не более одного клиента на проверку')
        this.localLoading = false
      }
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys)
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    changeMethod(type) {
      if (type == 'delete') {
        this.acceptDelete()
      } else {
        this.toExcel()
      }
    },
    acceptDelete() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.delete('/clients/' + this.activeSelectRowsMultiple, {
          ...(this.pincode && { pincode: this.pincode })
        })
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            console.log(data)
            this.loadData(1)
            this.$toast.success(data.data.message)
            this.$modal.hide('acceptDeleteWithPin')
          })
          .catch((e) => {
            console.log(e.response.status)
            if (e.response.status == 409) {
              this.currentMethodType = 'delete'
              this.$modal.show('acceptDeleteWithPin', { type: 'delete' })
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
            } else if (e.response.status == 400) {
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
              this.$modal.hide('acceptDeleteWithPin')
            }
          })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    toExcel() {
      console.log(this.pincode)
      GeneralService.toSpecExcel('/clients/table/active/xlsx', {
        ...(this.pincode && { pincode: this.pincode })
      })
        .then((data) => {
          // this.selects.push(data.data.data.rows)
          console.log(data)
          this.pincode = ''
          this.$modal.hide('acceptDeleteWithPin')
          const filename = new Date().toISOString()
          const url = window.URL.createObjectURL(data.data)
          const a = document.createElement('a')
          a.href = url
          a.download = `${filename}.xlsx`
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        .catch((e) => {
          console.log(e)
          if (e.response.status == 409) {
            this.currentMethodType = 'excel'
            this.$modal.show('acceptDeleteWithPin', { type: 'excel' })
            this.messageError = e.response.data.message
          } else if (e.response.status == 400) {
            this.messageError = e.response.data.message
            this.$modal.hide('acceptDeleteWithPin')
            this.$toast.error(e.response.data.message)
          }
        })
    },
    loadData(newNumPage) {
      this.localLoading = true
      this.currentPage = newNumPage
      Promise.all([
        GeneralService.get('/clients/table/active', {
          params: {
            ...this.paginationClientsStateParams,
            ...(this.filter.length && { where: JSON.stringify(this.filter) })
          }
        })
          .then((data) => {
            this.getClientsAll(data.data.data)
            this.activePagePagination = data.data.data
            this.computedAllPage = data.data.data.total / data.data.data.limit
            this.dataTable = data.data.data.rows
            this.activeSelectRowsMultiple = []
            this.localLoading = false
          })
          .catch((e) => console.log(e))
      ]).then(() => {})
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData(1)
      this.$refs.globalFilter.value = ''
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    fillFilter(data) {
      if (data.name !== 'OR') {
        let newValueFilter =
          data.type == 'like' ? `;percent;${data.value};percent;` : data.value
        if (!this.filter.find((x) => x.field == data.name)) {
          this.filter.push({
            field: data.name,
            value: newValueFilter,
            ...(data.type == 'like' && { operator: 'like' }),
            ...(data.mod && { mod: 'datetimeUTC' })
          })
        } else {
          this.filter[
            this.filter.findIndex((x) => x.field == data.name)
          ].value = newValueFilter
        }
      }

      if (data.name == 'OR') {
        this.filter[this.filter.findIndex((x) => 'OR' in x)].OR = [
          {
            field: 'clients__id',
            value: `;percent;${data.value};percent;`,
            operator: 'like'
          },
          {
            field: 'clients__name',
            value: `;percent;${data.value};percent;`,
            operator: 'like'
          },
          {
            field: 'clients__phone',
            value: `;percent;${data.value};percent;`,
            operator: 'like'
          }
        ]
        console.log(this.filter)
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
      this.messageError = ''
      this.currentMethodType = ''
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('clientPagiation', ['getClientsAll'])
  },
  mounted() {
    EventBus.$on('updateContentData', () => {
      this.loadData(1)
    })
    EventBus.$on('closeModal', () => {
      this.$modal.hide('clientsModal')
    })
    this.filter.push({
      OR: [
        {
          field: 'clients__id',
          value: `;percent;;percent;`,
          operator: 'like'
        },
        {
          field: 'clients__name',
          value: `;percent;;percent;`,
          operator: 'like'
        },
        {
          field: 'clients__phone',
          value: `;percent;;percent;`,
          operator: 'like'
        }
      ]
    })
  },
  created() {
    this.loadData(1)
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('clientPagiation', ['paginationClientsStateParams']),
    ...mapGetters('clientPagiation', ['numPages']),
    columns() {
      return [
        {
          field: '',
          key: 'a',

          type: 'checkbox',
          title: '',
          width: '3%',
          align: 'center'
        },
        {
          field: 'clients__id',
          key: '12',
          title: this.dataLang['clients_register_text_107'],
          align: 'left',
          width: '5%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'clients__id'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },

        {
          field: 'clients__balance',
          key: '13',
          title: this.dataLang['clients_register_text_108'],
          align: 'left',
          width: '5%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'clients__name',
          key: '14',
          title: this.dataLang['clients_register_text_109'],
          align: 'left',
          width: '10%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'input' }}
                  inputName={'clients__name'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'clients__fiz_date_birth',
          key: '15',
          title: this.dataLang['clients_register_text_110'],
          align: 'left',
          width: '8%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like', mod: 'datetimeUTC' }}
                  inputName={'clients__fiz_date_birth'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.clients__fiz_date_birth
                  ? this.$time(row.clients__fiz_date_birth).format(
                      `${this.userSetting.company__tempalte_date}`
                    )
                  : ''}
              </span>
            )
          }
        },
        {
          field: 'sb_1_days',
          key: '16',
          title: this.dataLang['clients_register_text_111'],
          align: 'left',
          width: '4%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'sb_2_days',
          key: '222',
          title: this.dataLang['clients_register_text_112'],
          align: 'left',
          width: '4%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'days_without_orders',
          key: '23',
          title: this.dataLang['clients_register_text_113'],
          align: 'left',
          width: '4%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'contracts_count',
          key: '24',
          title: this.dataLang['clients_register_text_114'],
          align: 'left',
          width: '4%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'payments_total',
          key: '25',
          title: this.dataLang['clients_register_text_115'],
          align: 'left',
          width: '6%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'clients__reting',
          key: '33',
          title: this.dataLang['clients_register_text_116'],
          align: 'left',
          width: '3%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'clients__reting'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },
        {
          field: 'clients__next_call_date',
          key: '103',
          title: this.dataLang['clients_register_text_117'],
          align: 'left',
          width: '6%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like', mod: 'datetimeUTC' }}
                  inputName={'clients__next_call_date'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.clients__next_call_date
                  ? this.$time(row.clients__next_call_date).format(
                      `${this.userSetting.company__tempalte_date} HH:mm`
                    )
                  : ''}
              </span>
            )
          }
        },
        {
          field: 'last_comment_text',
          key: '102',
          title: this.dataLang['clients_register_text_118'],
          align: 'left',
          width: '15%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'last_comment_date',
          key: '1012',
          title: this.dataLang['clients_register_text_119'],
          align: 'left',
          width: '6%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.last_comment_date
                  ? this.$time(row.last_comment_date)
                      .utc(true)
                      .utcOffset(Number(this.userSetting.company__utc))
                      .format(
                        `${this.userSetting.company__tempalte_date} HH:mm`
                      )
                  : ''}
              </span>
            )
          }
        },
        {
          field: 'last_comment_employee',
          key: '101',
          title: this.dataLang['clients_register_text_120'],
          align: 'left',
          width: '6%',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'last_comment_employee'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>
