<template>
  <div class="timeline-calendar" ref="rootTimelineContainer"></div>
</template>
    
    <script>
import { Timeline } from "vis-timeline/standalone";

export default {
  // TODO На шахматке сделать оторажение блокировки и проставление дат блокироваки. Никакие другие данные редактировать нельзя
  data() {
    return {
      items: [
        {
          id: 1,
          content: "item 1",
          start: "2018-12-20 19:26:15",
          end: "2019-04-19 19:26:15",
          group: 0,
        },
        {
          id: 2,
          content: "item 2",
          start: "2018-12-14 19:26:15",
          end: "2019-04-19 19:26:15",
          group: 0,
        },
        {
          id: 3,
          content: "item 3",
          start: "2018-12-18 19:26:15",
          end: "2019-04-19 19:26:15",
          group: 1,
        },
        {
          id: 4,
          content: "item 4",
          start: "2018-12-16 19:26:15",
          end: "2019-04-19 19:26:15",
          group: 2,
        },
        {
          id: 5,
          content: "item 5",
          start: "2018-12-25 19:26:15",
          end: "2019-04-19 19:26:15",
          group: 2,
        },
        {
          id: 6,
          content: "item 6",
          start: "2022-04-27 19:26:15",
          end: "2023-06-24 19:26:15",
          group: 1,
        },
      ],
      groups: [
        {
          id: 0,
          content: "Management",
          className: "timeline-group",
        },
        {
          id: 1,
          content: "Product Owner",
          className: "timeline-group",
        },
        {
          id: 2,
          content:
            "2) (Арт.1402) toyota land cruiser 200 Т218МА797 [AT] на Владыкино 5741 DELIVERY +7(968)666-08-50",
          className: "timeline-group",
        },
      ],
      options: {
        orientation: "top",
        timeAxis: { scale: "day", step: 1 },
        zoomable: false,
        min: "2018-12-01",
        start: "2023-06-05",
        end: "2023-07-04",
        horizontalScroll: true,
        /* itemsAlwaysDraggable: true,
        zoomMin: 1000, //for days 7 instead of 6?
        horizontalScroll: true,
        minHeight: "500px",
        editable: {
          remove: true, // delete an item by tapping the delete button top right
          overrideItems: false, // allow these options to override item.editable
        }, */
        onUpdate: function (item, callback) {
          item.content = prompt("Edit items text:", item.content);
          if (item.content != null) {
            callback(item); // send back adjusted item
          } else {
            callback(null); // cancel updating the item
          }
        },
        onMove: function (item, callback) {
          console.log(item, callback);
          if (item.content != null) {
            callback(item); // send back adjusted item
          } else {
            callback(null); // cancel updating the item
          }
        },
      },
    };
  },
  mounted() {
    // Create a Timeline
    let timeline = new Timeline(document.querySelector(".timeline-calendar"));
    timeline.setOptions(this.options);
    timeline.setGroups(this.groups);
    timeline.setItems(this.items);
  },
};
</script>
    