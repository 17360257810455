<template>
  <div
    class="gap-10 pb-10 pt-10 d-flex position-relative"
    style="max-height: 615px; min-height: 615px"
  >
    <BaseLoader v-if="loadingsStateComponent.main"></BaseLoader>
    <div class="flex-grow-1">
      <div class="system-block position-relative px-10 pt-5 pb-5 h-100">
        <div v-if="!loadingsStateComponent.main">
          <div class="inner-decorate mb-5">
            <span v-if="!dataForTab.clients__is_ur_face">{{
              dataLang['clients_register_text_15']
            }}</span>
            <span v-if="dataForTab.clients__is_ur_face">{{
              dataLang['clients_register_text_155']
            }}</span>
          </div>
          <div ref="clients__name">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__name"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__name"
              :additionalParams="{}"
              :name-sign="
                !dataForTab.clients__is_ur_face
                  ? dataLang['clients_register_text_16']
                  : dataLang['clients_register_text_156']
              "
            >
            </BaseInputNew>
          </div>
          <div ref="clients__ur_fio" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__ur_fio"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__ur_fio"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_157']"
            >
            </BaseInputNew>
          </div>
          <div
            ref="clients__ur_action_on"
            v-if="dataForTab.clients__is_ur_face"
          >
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__ur_action_on"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__ur_action_on"
              :additionalParams="{}"
              :name-sign="dataLang['form_ur__vehicle_owners_ur__acts_on']"
            >
            </BaseInputNew>
          </div>

          <div ref="clients__ur_inn" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__ur_inn"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__ur_inn"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_159']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__ur_kpp" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__ur_kpp"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__ur_kpp"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_160']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__ur_ogrn" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__ur_ogrn"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__ur_ogrn"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_161']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__rs" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__rs"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__rs"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_162']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__bank" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__bank"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__bank"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_25']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__bik" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__bik"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__bik"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_24']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__ur_ks" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__ur_ks"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__ur_ks"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_163']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__email" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__email"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__email"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_18']"
            >
            </BaseInputNew>
          </div>

          <div ref="clients__phone">
            <BaseInputNew
              placeholder=""
              width="210"
              class="lh-1 gap-10 mb-5"
              v-if="!dataForTab.clients__is_ur_face"
              input-name="clients__phone"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              inputRef="clients__phone"
              maxLength="16"
              inputMask="+{7}(000)000-00-00"
              :value="dataForTab.clients__phone"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_131']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__email" v-if="!dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__email"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__email"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_18']"
            >
            </BaseInputNew>
          </div>
          <div
            ref="clients__fiz_date_birth"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__fiz_date_birth"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputRef="clients__garant_pho2ne2222"
              maxLength="10"
              inputMask="00.00.0000"
              inputClass=""
              :value="checkDate(dataForTab?.clients__fiz_date_birth)"
              :additionalParams="{
                dateInput: true
              }"
              :name-sign="dataLang['clients_register_text_19']"
            >
            </BaseInputNew>
          </div>
          <div
            ref="clients__fiz_place_birth"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__fiz_place_birth"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__fiz_place_birth"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_20']"
            >
            </BaseInputNew>
          </div>
          <div
            ref="clients__fiz_passport"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__fiz_passport"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark ws-nowrap"
              inputClass=""
              :value="dataForTab.clients__fiz_passport"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_21']"
            >
            </BaseInputNew>
          </div>
          <div
            ref="clients__fiz_place_of_residence"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__fiz_place_of_residence"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__fiz_place_of_residence"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_22']"
            >
            </BaseInputNew>
          </div>
          <div class="inner-decorate mb-5">
            <span
              v-html="
                !dataForTab.clients__is_ur_face
                  ? dataLang['clients_register_text_23']
                  : dataLang['clients_register_text_165']
              "
            ></span>
          </div>
          <div ref="clients__garant_fio" v-if="dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__garant_fio"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__garant_fio"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_16']"
            >
            </BaseInputNew>
          </div>
          <div
            ref="clients__garant_phone"
            v-if="dataForTab.clients__is_ur_face"
          >
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__garant_phone"
              @input="updateValue"
              :horizontalTitle="true"
              inputRef="clients__garant_phone222"
              maxLength="16"
              inputMask="+{7}(000)000-00-00"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__garant_phone"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_172']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__phone">
            <BaseInputNew
              placeholder=""
              width="210"
              class="lh-1 gap-10 mb-5"
              v-if="dataForTab.clients__is_ur_face"
              input-name="clients__phone"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              inputRef="clients__phone"
              maxLength="16"
              inputMask="+{7}(000)000-00-00"
              :value="dataForTab.clients__phone"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_164']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__rs" v-if="!dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__rs"
              inputRef="clients__garant_phone222222"
              maxLength="15"
              inputMask="000000000000000"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__rs"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_1299']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__bik" v-if="!dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__bik"
              inputRef="clients__garant_phone22222"
              maxLength="15"
              inputMask="000000000000000"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__bik"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_24']"
            >
            </BaseInputNew>
          </div>
          <div ref="clients__bank" v-if="!dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__bank"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__bank"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_25']"
            >
            </BaseInputNew>
          </div>
          <div
            class="inner-decorate mb-5"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <span>{{ dataLang['clients_register_text_26'] }}</span>
          </div>

          <div ref="clients__garant_fio" v-if="!dataForTab.clients__is_ur_face">
            <BaseInputNew
              placeholder=""
              width="210"
              class="mb-5 lh-1 gap-10"
              input-name="clients__garant_fio"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              :value="dataForTab.clients__garant_fio"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_16']"
            >
            </BaseInputNew>
          </div>
          <div
            ref="clients__garant_phone"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <BaseInputNew
              placeholder=""
              width="210"
              class="lh-1 gap-10 mb-5"
              input-name="clients__garant_phone"
              @input="updateValue"
              :horizontalTitle="true"
              signClass="text-dark"
              inputClass=""
              inputRef="clients__garant_phone2"
              maxLength="16"
              inputMask="+{7}(000)000-00-00"
              :value="dataForTab.clients__garant_phone"
              :additionalParams="{}"
              :name-sign="dataLang['clients_register_text_17']"
            >
            </BaseInputNew>
          </div>
          <div
            class="inner-decorate mb-5"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <span>{{ dataLang['clients_register_text_27'] }}</span>
          </div>
          <div
            class="d-flex mb-5 justify-content-between gap-5"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <div ref="clients__fiz_doc_vu_number" class="wp-100">
              <BaseInputNew
                placeholder=""
                width="78"
                class="lh-1"
                input-name="clients__fiz_doc_vu_number"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_vu_number"
                :additionalParams="{}"
                :name-sign="dataLang['clients_register_text_28']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_vu_date">
              <BaseInputNew
                placeholder=""
                width="71"
                class="lh-1"
                input-name="clients__fiz_doc_vu_date"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputRef="vehicles__sts_date3"
                inputClass=""
                inputMask="00.00.0000"
                maxLength="10"
                :value="checkDate(dataForTab?.clients__fiz_doc_vu_date)"
                :additionalParams="{}"
                :name-sign="dataLang['clients_register_text_32']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_vu_year">
              <BaseInputNew
                placeholder=""
                width="44"
                class="lh-1"
                input-name="clients__fiz_doc_vu_year"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_vu_year"
                :additionalParams="{}"
                inputRef="clients__fiz_do2c_vu_year"
                inputMask="0000"
                maxLength="4"
                :name-sign="dataLang['clients_register_text_33']"
              >
              </BaseInputNew>
            </div>
          </div>
          <div
            class="d-flex mb-5 justify-content-between gap-5"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <div ref="clients__fiz_doc_ppla_number" class="wp-100">
              <BaseInputNew
                placeholder=""
                width="78"
                class="lh-1"
                input-name="clients__fiz_doc_ppla_number"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_ppla_number"
                :additionalParams="{}"
                :name-sign="dataLang['clients_register_text_29']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_ppla_date">
              <BaseInputNew
                placeholder=""
                width="71"
                class="lh-1"
                input-name="clients__fiz_doc_ppla_date"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputRef="vehicle2s__sts_date"
                inputClass=""
                inputMask="00.00.0000"
                maxLength="10"
                :value="checkDate(dataForTab?.clients__fiz_doc_ppla_date)"
                :additionalParams="{}"
                :name-sign="dataLang['clients_register_text_32']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_ppla_year">
              <BaseInputNew
                placeholder=""
                width="44"
                class="lh-1"
                input-name="clients__fiz_doc_ppla_year"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_ppla_year"
                :additionalParams="{}"
                inputMask="0000"
                inputRef="clients__fiz33_do2c_vu_year"
                maxLength="4"
                :name-sign="dataLang['clients_register_text_33']"
              >
              </BaseInputNew>
            </div>
          </div>
          <div
            class="d-flex mb-5 justify-content-between gap-5"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <div ref="clients__fiz_doc_pplh_number" class="wp-100">
              <BaseInputNew
                placeholder=""
                width="78"
                class="lh-1"
                input-name="clients__fiz_doc_pplh_number"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_pplh_number"
                :additionalParams="{}"
                :name-sign="dataLang['clients_register_text_30']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_pplh_date">
              <BaseInputNew
                placeholder=""
                width="71"
                class="lh-1"
                input-name="clients__fiz_doc_pplh_date"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                inputMask="00.00.0000"
                inputRef="vehicles__sts_da2te"
                maxLength="10"
                :value="checkDate(dataForTab?.clients__fiz_doc_pplh_date)"
                :additionalParams="{}"
                :name-sign="dataLang['clients_register_text_32']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_pplh_year">
              <BaseInputNew
                placeholder=""
                width="44"
                class="lh-1"
                input-name="clients__fiz_doc_pplh_year"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_pplh_year"
                :additionalParams="{}"
                inputMask="0000"
                inputRef="clients__fiz_do2c_v2u_year"
                maxLength="4"
                :name-sign="dataLang['clients_register_text_33']"
              >
              </BaseInputNew>
            </div>
          </div>
          <div
            class="d-flex justify-content-between gap-5"
            v-if="!dataForTab.clients__is_ur_face"
          >
            <div ref="clients__fiz_doc_gims_number" class="wp-100">
              <BaseInputNew
                placeholder=""
                width="78"
                class="lh-1"
                input-name="clients__fiz_doc_gims_number"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_gims_number"
                :additionalParams="{}"
                :name-sign="dataLang['clients_register_text_31']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_gims_date">
              <BaseInputNew
                placeholder=""
                width="71"
                class="lh-1"
                input-name="clients__fiz_doc_gims_date"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="checkDate(dataForTab?.clients__fiz_doc_gims_date)"
                :additionalParams="{}"
                inputMask="00.00.0000"
                inputRef="vehicles__sts_da3te"
                maxLength="10"
                :name-sign="dataLang['clients_register_text_32']"
              >
              </BaseInputNew>
            </div>
            <div ref="clients__fiz_doc_gims_year">
              <BaseInputNew
                placeholder=""
                width="44"
                class="lh-1"
                input-name="clients__fiz_doc_gims_year"
                @input="updateValue"
                :horizontalTitle="true"
                signClass="text-dark"
                inputClass=""
                :value="dataForTab.clients__fiz_doc_gims_year"
                :additionalParams="{}"
                inputMask="0000"
                inputRef="clients__1fiz_do2c_vu_year"
                maxLength="4"
                :name-sign="dataLang['clients_register_text_33']"
              >
              </BaseInputNew>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="h-auto"
      style="width: 129px; height: 584px"
      v-if="!dataForTab.clients__is_ur_face && !loadingsStateComponent.main"
    >
      <div
        class="system-block h-100 position-relative px-10 pb-10"
        style="padding-top: 8px"
      >
        <div class="fw-bold fsc-12 mb-10">
          {{ dataLang['clients_register_text_34'] }}
        </div>
        <UploadFileWithPreview
          style="height: 62px"
          :style="{
            'margin-bottom': galleryList.length - 1 == index ? '0' : '5px'
          }"
          v-for="(item, index) in galleryList"
          :key="item.client_document_types__position"
          file-preview=""
          accept="image/png,image/jpg,image/jpeg,.pdf,application/pdf"
          :max-weight="10000"
          :max-x-size="1000"
          :max-y-size="750"
          preview="image.svg"
          :additionalParams="{
            keyData: item.client_document_types__position,
            doc_key: item.clients_documents__document_key
          }"
          type-of-form-data="clientDoc"
          :error-message-max-weight="dataLang['error_message_max_file_size']"
          :indexForPrint="signsGallery[index]"
          classes="file-load--gallery ws-nowrap"
          @load-file="(params) => loadfile(params)"
          @clickOnImage="clickOnImage(item.client_document_types__position - 1)"
          @remove-file="(params) => removefile(params)"
          :file-preview="item.clients_documents__file"
        >
        </UploadFileWithPreview>
      </div>
    </div>
    <div
      class="h-auto"
      style="width: 342px; height: 584px"
      v-if="!loadingsStateComponent.main"
    >
      <div
        class="p-10 rounded-5 h-100 px-10 pt-5 pb-5"
        style="border: 1px solid #0056af"
      >
        <div class="fsc-11 fw-bold">
          {{ dataLang['clients_register_text_36'] }}
        </div>
        <div
          class="d-flex justify-content-center align-items-center fsc-20 text-white mb-5"
          style="padding: 8px"
          :style="{
            'background-color':
              dataForTab.clients__balance > -1 ? '#23B14D' : '#FF2400'
          }"
        >
          {{ dataForTab.clients__balance }}
        </div>
        <div class="fsc-11 fw-bold">
          {{ dataLang['clients_register_text_37'] }} &nbsp;
        </div>
        <div class="d-flex justify-content-between fsc-12">
          <div class="">
            <div class="d-flex">
              {{ dataLang['clients_register_text_404'] }} &nbsp;
              <span class="fw-bold">{{
                dataForTab.statistics?.contractsCount
              }}</span>
            </div>
            <div class="d-flex">
              {{ dataLang['clients_register_text_40'] }} &nbsp;
              <span class="fw-bold">{{
                dataForTab.clients__amount_damge
              }}</span>
            </div>
            <div class="d-flex">
              {{ dataLang['clients_register_text_42'] }} &nbsp;
              <span class="fw-bold">{{
                dataForTab.statistics?.finesCount
              }}</span>
            </div>
          </div>
          <div class="text-end">
            <div class="">
              {{ dataLang['clients_register_text_39'] }} &nbsp;
              <span class="fw-bold">{{
                dataForTab.statistics?.contractsKm
              }}</span>
            </div>
            <div class="">
              {{ dataLang['clients_register_text_41'] }} &nbsp;
              <span class="fw-bold">{{ dataForTab.statistics?.loss }}</span>
            </div>
            <div class="">
              {{ dataLang['clients_register_text_43'] }} &nbsp;
              <span class="fw-bold"
                >{{ dataForTab.statistics?.daysAfterLastOrder }}
                {{ dataLang['clients_register_text_44'] }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="base-button flex-grow-1 base-button--action"
          style="padding: 8px"
        >
          <img
            :src="require(`@/assets/icons/pdf.svg`)"
            width="12"
            height="16"
          />
          <span class="fsc-11">{{ dataLang['clients_register_text_45'] }}</span>
        </div>
        <span class="fsc-11 fw-bold">
          {{ dataLang['clients_register_text_46'] }}
        </span>
        <div class="d-flex" style="gap: 8px; height: 58px">
          <div class="d-flex wp-50 flex-column">
            <div
              class="text-white fsc-11 d-flex justify-content-center align-items-center gap-10"
              style="height: 40px"
              :style="{
                'background-color': dataForTab.verifications?.yandexrent.status
                  ? '#23B14D'
                  : '#cacaca'
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                v-if="dataForTab.verifications?.yandexrent.status"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.70711 9.78771L15 0.430786L16.4142 1.85474L5.70711 12.6356L0 6.8892L1.41421 5.46524L5.70711 9.78771Z"
                  fill="white"
                />
              </svg>
              {{ dataLang['clients_register_text_47'] }}
            </div>
            <span
              class="text-center fsc-11"
              v-if="dataForTab.verifications?.yandexrent.daysToEnd"
              >{{ dataLang['clients_register_text_49'] }}
              {{ dataForTab.verifications?.yandexrent.daysToEnd }}
              {{ dataLang['clients_register_text_44'] }}</span
            >
          </div>
          <div class="d-flex wp-50 flex-column">
            <div
              class="text-white fsc-11 d-flex justify-content-center align-items-center gap-10"
              style="height: 40px"
              :style="{
                'background-color': dataForTab.verifications?.company.status
                  ? '#23B14D'
                  : '#cacaca'
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                v-if="dataForTab.verifications?.company.status"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.70711 9.78771L15 0.430786L16.4142 1.85474L5.70711 12.6356L0 6.8892L1.41421 5.46524L5.70711 9.78771Z"
                  fill="white"
                /></svg
              >{{ dataLang['clients_register_text_48'] }}
            </div>
            <span class="text-center fsc-11"
              >{{ dataLang['clients_register_text_49'] }}
              {{ dataForTab.verifications?.company.daysToEnd }}
              {{ dataLang['clients_register_text_44'] }}</span
            >
          </div>
        </div>
        <div
          ref="clients__is_pledge_only"
          class="position-relative d-flex gap-10 align-items-center"
          style="background-color: rgba(29, 30, 44, 1); padding: 5px 8px"
        >
          <div class="bg-white">
            <BaseCheckboxV2
              placeholder=""
              class=""
              input-name="clients__is_pledge_only"
              v-model="dataForTab.clients__is_pledge_only"
              signClass="d-none"
              @oncheckeds="updateValue"
            >
            </BaseCheckboxV2>
          </div>
          <span class="fsc-11 text-white fw-bold">{{
            dataLang['clients_register_text_50']
          }}</span>
          <img
            :src="require(`@/assets/icons/lock.svg`)"
            width="10"
            height="10"
            style="position: absolute; top: 5px; right: 5px"
          />
        </div>
        <span class="fsc-11 fw-bold" v-if="dataForTab?.clients__next_call_date">
          {{ dataLang['clients_register_text_51'] }}
        </span>
        <div
          class="d-flex justify-content-center py-5 align-items-center fsc-20 text-white mb-5 rounded-5"
          style="background-color: #0056af"
          v-if="dataForTab?.clients__next_call_date"
        >
          {{
            dataForTab.clients__next_call_date
              ? $time(dataForTab.clients__next_call_date).format(
                  `${userSetting.company__tempalte_date} HH:mm`
                )
              : ''
          }}
        </div>
        <span class="fsc-11 fw-bold">
          {{ dataLang['clients_register_text_53'] }}
        </span>
        <div class="d-flex mb-10" style="gap: 8px">
          <div
            class="base-button flex-grow-1 base-button--action"
            @click="sendPerezvon('hour')"
            style="padding: 4px"
          >
            <span class="fsc-11">Через час</span>
          </div>
          <div
            class="base-button flex-grow-1 base-button--action"
            @click="sendPerezvon('day')"
            style="padding: 4px"
          >
            <span class="fsc-11">Через сутки</span>
          </div>
          <div
            class="base-button flex-grow-1 base-button--action"
            @click="$modal.show('selectTime')"
            style="padding: 4px"
          >
            <span class="fsc-11">Свой вариант</span>
          </div>
        </div>
        <div class="text-muted fsc-12 text-center">
          {{ dataLang['clients_register_text_56'] }}
        </div>
        <span class="fsc-11 fw-bold">{{
          dataLang['clients_register_text_57']
        }}</span>
        <div class="d-flex gap-5" style="">
          <BaseSelect
            style="flex-grow: 1"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            @input="(data) => (senderSmsPreset = data)"
            v-model="dataFromSelect.sms_presets"
            :options="selectsOptions.presets"
            label="sms_presets__name"
          >
            <template slot="noOptions">{{ dataLang['select_empty'] }}</template>
            <template slot="noResult">{{
              dataLang['select_search_empty']
            }}</template
            ><template slot="selectedLabel">{{
              dataLang['select_selected']
            }}</template>
            <template slot="deselectLabel">{{
              dataLang['select_unselect']
            }}</template>
          </BaseSelect>
          <div
            class="base-button base-button--action"
            style="min-height: 26px"
            @click="sendSms"
          >
            <span class="fsc-11">Отпр.</span>
          </div>
        </div>
        <span class="fsc-11">{{ dataLang['clients_register_text_59'] }}</span>
        <div class="d-flex gap-5">
          <BaseInputNew
            placeholder=""
            width="100%"
            class="lh-1 flex-grow-1"
            input-name="someSmsToSend"
            maxLength="500"
            @input="({ value }) => (someSmsToSend = value)"
            :horizontalTitle="true"
            signClass="text-dark"
            inputClass=""
            :value="someSmsToSend"
            :additionalParams="{}"
            name-sign=""
          >
          </BaseInputNew>
          <div
            class="base-button base-button--action"
            style="min-height: 26px"
            @click="sendSmsSome"
          >
            <span class="fsc-11">Отпр.</span>
          </div>
        </div>
        <div class="text-end fsc-9 me-55 mt-5">
          {{ someSmsToSend.length }}/500
        </div>
      </div>
    </div>

    <div class="h-auto" style="width: 387px; height: 584px">
      <div class="system-block h-100 position-relative px-10 pt-5 pb-10">
        <BaseLoader v-if="loadingsStateComponent.comment"></BaseLoader>
        <span class="fsc-11 fw-bold">{{
          dataLang['vehicles_page__modal__tab_comments__name']
        }}</span>
        <div class="d-flex gap-10 mt-15 mb-10">
          <BaseInputNew
            placeholder=""
            width="100%"
            class="wp-100"
            input-name="newComment"
            @input="(data) => (newCommentValue = data)"
            :horizontalTitle="true"
            ref="commentInput"
            value=""
            :additionalParams="{}"
            name-sign=""
          >
          </BaseInputNew>
          <div
            class="base-button base-button--action px-5 py-5"
            style="min-height: 26px; height: 26px"
            @click="sendComment"
          >
            <img
              :src="require(`@/assets/icons/add.svg`)"
              width="12"
              height="12"
            />
          </div>
        </div>
        <BaseScrollbar style="max-height: 470px">
          <div class="comments">
            <div class="comments-base d-flex flex-column gap-10">
              <!-- {
    "client_comments__client_id": 2,
    "client_comments__comment": "Автокомментарий. Установлено новое время для перезвона - 2024-04-12 22:50:13",
    "client_comments__company_id": 51,
    "client_comments__created_at": "2024-04-12 14:50:13",
    "client_comments__employee_id": 59,
    "client_comments__id": 5,
    "client_comments__is_fixed": 0,
    "client_comments__updated_at": "2024-04-12 14:50:13"
} -->
              <div
                v-for="(comment, index) of commentsList"
                :key="comment.client_comments__id"
                class="comment d-flex gap-5 justify-content-between border border-1 border-disabled"
              >
                <div class="comment-info d-flex flex-column gap-0">
                  <p
                    class="comment-info__heading text-black fw-normal fsc-11 lh-sm m-0"
                  >
                    {{
                      $time(comment.client_comments__created_at)
                        .utc(true)
                        .utcOffset(Number(userSetting.company__utc))
                        .format(`${userSetting.company__tempalte_date} HH:mm`)
                    }}
                    <!-- {{ comment.employees__name }} -->
                  </p>
                  <p
                    class="comment-info__content text-black fw-medium fsc-12 lh-sm m-0"
                  >
                    {{ comment.client_comments__comment }}
                  </p>
                </div>

                <div class="comment-actions d-flex h-100">
                  <div
                    :class="[]"
                    class="comment-actions__pin d-flex justify-content-center align-items-center rounded-5 cursor-pointer"
                    @click="
                      () => {
                        fixComment(
                          comment.client_comments__id,
                          !comment.client_comments__is_fixed
                        )
                        comment.client_comments__is_fixed =
                          !comment.client_comments__is_fixed
                      }
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        :fill="[
                          comment.client_comments__is_fixed
                            ? '#23B14D'
                            : 'rgba(49, 48, 61, 1)'
                        ]"
                        d="M10.6749 2.22974L14.2316 5.69537C14.2787 5.74122 14.312 5.7958 14.3316 5.85402L14.5475 6.06994C14.7064 6.22889 14.7064 6.48659 14.5475 6.64554L12.2451 8.94794C12.0861 9.10689 11.8284 9.10689 11.6695 8.94794L11.0939 8.37234C11.0409 8.31936 10.955 8.31936 10.902 8.37234L10.6566 8.61779C10.574 8.70037 10.5535 8.82654 10.6058 8.931L11.3464 10.4124C11.4248 10.5691 11.3941 10.7583 11.2702 10.8822L9.36709 12.7853C9.20814 12.9442 8.95044 12.9442 8.79149 12.7853L6.68096 10.6747L2.41192 14.9438C2.25297 15.1027 1.99527 15.1027 1.83632 14.9438L1.45258 14.56C1.29364 14.4011 1.29364 14.1434 1.45258 13.9844L5.72162 9.71541L3.61109 7.60488C3.45214 7.44593 3.45214 7.18822 3.61109 7.02928L5.51419 5.12617C5.63807 5.0023 5.82731 4.97159 5.98401 5.04993L7.46537 5.79061C7.56983 5.84284 7.696 5.82237 7.77858 5.73978L8.02402 5.49434C8.07701 5.44136 8.07701 5.35546 8.02402 5.30247L7.44842 4.72687C7.28948 4.56793 7.28948 4.31022 7.44842 4.15127L9.75083 1.84887C9.90977 1.68992 10.1675 1.68992 10.3264 1.84887L10.5746 2.09704C10.615 2.13744 10.6484 2.18218 10.6749 2.22974ZM12.9412 6.33209L10.0269 3.49247L9.27116 4.24721C9.16519 4.35317 9.16519 4.52498 9.27116 4.63094L9.84676 5.20654C9.95273 5.31251 9.95273 5.48431 9.84676 5.59027L7.83216 7.60488C7.72619 7.71084 7.55439 7.71084 7.44842 7.60488L6.86777 7.02422C6.84715 7.0036 6.82334 6.98644 6.79725 6.9734L6.16443 6.65699C6.05997 6.60475 5.9338 6.62523 5.85122 6.70781L5.43382 7.12521C5.32786 7.23117 5.32786 7.40298 5.43382 7.50894L8.88743 10.9625C8.99339 11.0685 9.16519 11.0685 9.27116 10.9625L9.68855 10.5452C9.77114 10.4626 9.79161 10.3364 9.73938 10.2319L9.42297 9.59912C9.40993 9.57303 9.39277 9.54922 9.37214 9.5286L8.79149 8.94794C8.68553 8.84198 8.68553 8.67018 8.79149 8.56421L10.8061 6.54961C10.9121 6.44364 11.0839 6.44364 11.1898 6.54961L11.7654 7.12521C11.8714 7.23117 12.0432 7.23117 12.1492 7.12521L12.9412 6.33209Z"
                      />
                      <path
                        :fill="[
                          comment.client_comments__is_fixed
                            ? '#23B14D'
                            : 'rgba(49, 48, 61, 1)'
                        ]"
                        d="M12.9412 6.33209L10.0269 3.49247L9.27116 4.24721C9.16519 4.35317 9.16519 4.52498 9.27116 4.63094L9.84676 5.20654C9.95273 5.31251 9.95273 5.48431 9.84676 5.59027L7.83216 7.60488C7.72619 7.71084 7.55439 7.71084 7.44842 7.60488L6.86777 7.02422C6.84715 7.0036 6.82334 6.98644 6.79725 6.9734L6.16443 6.65699C6.05997 6.60475 5.9338 6.62523 5.85122 6.70781L5.43382 7.12521C5.32786 7.23117 5.32786 7.40298 5.43382 7.50894L8.88743 10.9625C8.99339 11.0685 9.16519 11.0685 9.27116 10.9625L9.68855 10.5452C9.77114 10.4626 9.79161 10.3364 9.73938 10.2319L9.42297 9.59912C9.40993 9.57303 9.39277 9.54922 9.37214 9.5286L8.79149 8.94794C8.68553 8.84198 8.68553 8.67018 8.79149 8.56421L10.8061 6.54961C10.9121 6.44364 11.0839 6.44364 11.1898 6.54961L11.7654 7.12521C11.8714 7.23117 12.0432 7.23117 12.1492 7.12521L12.9412 6.33209Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="comment-actions__delete d-flex justify-content-center align-items-center cursor-pointer"
                    @click="delComment(comment.client_comments__id)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2.66983 1.70071C2.40222 1.4331 1.96833 1.4331 1.70071 1.70071C1.4331 1.96833 1.4331 2.40222 1.70071 2.66983L7.03088 8L1.70071 13.3302C1.4331 13.5978 1.4331 14.0317 1.70071 14.2993C1.96833 14.5669 2.40222 14.5669 2.66983 14.2993L8 8.96912L13.3301 14.2992C13.5977 14.5668 14.0316 14.5668 14.2992 14.2992C14.5668 14.0316 14.5668 13.5977 14.2992 13.3301L8.96912 8L14.2993 2.66983C14.5669 2.40222 14.5669 1.96833 14.2993 1.70071C14.0317 1.4331 13.5978 1.4331 13.3302 1.70071L8 7.03088L2.66983 1.70071Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BaseScrollbar>
      </div>
    </div>
    <gallery :images="images" :index="index" @close="index = null"></gallery>
    <BaseModal
      name="acceptChangeWithPin"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="pincodeModalHandler"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptChangeWithPin')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">ВНИМАНИЕ</p>

          <div class="d-flex flex-column align-items-center">
            <!-- <div
                class="base-button px-20 rounded-10"
                @click="$modal.hide('acceptDeleteWithPin')"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div> -->
            <p class="text-center">
              Вы внесли изменение, требующее пароля администратора. Введите этот
              пароль или изменение не применится.
            </p>
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              :input-name="'pincode'"
              @input="(data) => (pincode = data.value)"
              value=""
              name-sign=""
            >
            </BaseInputNew>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('acceptChangeWithPin')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="sendData"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_save'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="selectTime"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="() => (selectTime = [])"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('selectTime')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">Выберите время</p>

          <div class="d-flex flex-column align-items-center">
            <BaseDatePicker
              v-model="selectTime"
              type="datetime"
              class="fsc-11 idiots-class-for-input-date mb-20"
              lang="ru"
              id="vehicle_tiwres__bought_at"
              style="width: 200px"
              value-type="format"
              :format="`${userSetting.company__tempalte_date} HH:mm`"
              @change="(date) => (selectTime = date)"
            >
            </BaseDatePicker>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('selectTime')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="sendPerezvon('custom')"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_save'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import UploadFileWithPreview from '@/components/UploadFileWithPreview.vue'
import GeneralService from '@/api/GeneralService'
import MainTab from './MainTab.vue'
import SelectsService from '@/api/SelectsService.js'
import EventBus from '@/event-bus.js'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import StorageFileService from '@/api/StorageFileService'
import VueGallery from 'vue-gallery'
export default {
  components: {
    VueTabs,
    VTab,
    MainTab,
    UploadFileWithPreview,
    gallery: VueGallery
  },

  props: {
    modalInfo: {
      type: Object
    }
  },
  data() {
    return {
      selectTime: [],
      pincode: '',
      signsGallery: [
        'Паспорт 1 разв.',
        'Паспорт 2 разв.',
        'Селфи с паспортом',
        'В/У лицо',
        'В/У оборот',
        'PPL (A)',
        'PPL (H)',
        'ГИМС'
      ],
      images: [],
      index: null,
      dataFromSelect: {
        orders__type_rent: [],
        ads: [],
        objects_types: [],
        orders__body_generation: [],
        loadingsStateComponent: [],
        cities__end_name: [],
        cities__start_name: [],
        sms_presets: [],
        client: []
      },
      dataFromSelect: {
        orders__type_rent: [],
        ads: [],
        objects_types: [],
        orders__body_generation: [],
        loadingsStateComponent: [],
        cities__end_name: [],
        cities__start_name: [],
        sms_presets: [],
        client: []
      },
      selectsOptions: {},
      senderSmsPreset: {},
      updatedValues: {},
      dataForTab: {},
      tabInfo: this.modalInfo,
      newCommentValue: {},
      messageError: [],
      galleryList: [
        {
          clients_documents__client_id: null,
          clients_documents__document_key: 'clients_documents__image_1',
          clients_documents__image_1: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 1
        },
        {
          clients_documents__client_id: 9,
          clients_documents__document_key: 'clients_documents__image_2',
          clients_documents__image_2: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 2
        },
        {
          clients_documents__client_id: null,
          clients_documents__document_key: 'clients_documents__image_3',
          clients_documents__image_3: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 3
        },
        {
          clients_documents__client_id: null,
          clients_documents__document_key: 'clients_documents__image_4',
          clients_documents__image_4: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 4
        },
        {
          clients_documents__client_id: null,
          clients_documents__document_key: 'clients_documents__image_5',
          clients_documents__image_5: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 5
        },
        {
          clients_documents__client_id: null,
          clients_documents__document_key: 'clients_documents__image_6',
          clients_documents__image_6: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 6
        },
        {
          clients_documents__client_id: null,
          clients_documents__document_key: 'clients_documents__image_7',
          clients_documents__image_7: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 7
        },
        {
          clients_documents__client_id: null,
          clients_documents__document_key: 'clients_documents__image_8',
          clients_documents__image_8: '',
          clients_documents__file: '',
          clients_documents__id: null,
          client_document_types__position: 8
        }
      ],
      someSmsToSend: '',

      loadingsStateComponent: {
        comment: false,
        deferred: false,
        main: false
      },
      //   modalInfo: {
      //     modalOpened: false,
      //     id: null,
      //     loading: true
      //   },
      commentsList: []
    }
  },
  methods: {
    checkDate(date) {
      if (date) {
        return date.split('-').reverse().join('.')
      } else {
        return ''
      }
    },
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      console.log('changetab', callback, nameTab, oldTab, newTab, flagIsClose)
      // this.loadingsStateComponent.main = true
      if (this.updatedValues?.clients__fiz_date_birth) {
        this.updatedValues.clients__fiz_date_birth =
          this.updatedValues.clients__fiz_date_birth
            .split('.')
            .reverse()
            .join('-')
      } else {
        delete this.updatedValues.clients__fiz_date_birth
      }
      if (this.updatedValues?.clients__fiz_doc_ppla_date) {
        this.updatedValues.clients__fiz_doc_ppla_date =
          this.updatedValues.clients__fiz_doc_ppla_date
            .split('.')
            .reverse()
            .join('-')
      } else {
        delete this.updatedValues.clients__fiz_doc_ppla_date
      }
      if (this.updatedValues?.clients__fiz_doc_pplh_date) {
        this.updatedValues.clients__fiz_doc_pplh_date =
          this.updatedValues.clients__fiz_doc_pplh_date
            .split('.')
            .reverse()
            .join('-')
      } else {
        delete this.updatedValues.clients__fiz_doc_pplh_date
      }
      if (this.updatedValues?.clients__fiz_doc_vu_date) {
        this.updatedValues.clients__fiz_doc_vu_date =
          this.updatedValues.clients__fiz_doc_vu_date
            .split('.')
            .reverse()
            .join('-')
      } else {
        delete this.updatedValues.clients__fiz_doc_vu_date
      }
      if (this.updatedValues?.clients__fiz_doc_gims_date) {
        this.updatedValues.clients__fiz_doc_gims_date =
          this.updatedValues.clients__fiz_doc_gims_date
            .split('.')
            .reverse()
            .join('-')
      } else {
        delete this.updatedValues.clients__fiz_doc_gims_date
      }
      if (Object.keys(this.updatedValues).length) {
        GeneralService.edit(
          `/clients/${this.modalInfo.id}`,
          Object.assign(this.updatedValues, {
            ...(this.pincode && { pincode: this.pincode })
          })
        )
          .then((data) => {
            this.$toast.success(data.data.message)

            EventBus.$emit('contentLoaded')
            EventBus.$emit('updateContentData')
            // this.loadingsStateComponent.main = false
            this.$modal.hide('acceptChangeWithPin')
            callback(true, nameTab, oldTab, newTab, flagIsClose)
          })
          .catch((e) => {
            // this.loadingsStateComponent.main = false
            if (e.response.status == 409) {
              this.$modal.show('acceptChangeWithPin', { type: 'change' })
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
              callback(false, nameTab, oldTab, newTab)
            } else if (e.response.status == 400) {
              setTimeout(() => {
                Object.keys(e.response.data.errors).forEach((elem, index) => {
                  console.log(this.$refs)
                  if (this.$refs[elem]) {
                    console.log(elem)
                    this.$refs[elem].classList.add('input-error')
                  }
                })
              }, 10)
              this.$modal.hide('acceptDeleteWithPin')
              this.$toast.error(e.response.data.message)
              callback(false, nameTab, oldTab, newTab)
            }
          })
      } else {
        EventBus.$emit('contentLoaded')
        EventBus.$emit('updateContentData')
        callback(true, '', oldTab, newTab, flagIsClose)
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
      this.messageError = ''
    },
    loadfile(params) {
      this.loadingsStateComponent.main = true
      console.log(params)
      this.loadingState = true
      StorageFileService.upload(params.fileInFormData)
        .then((data) => {
          // this.galleryList[params.keyData - 1].client_blacklist_gallery__file =
          //   data.data.data.url
          //   clients_documents__image_8
          this.galleryList[params.keyData - 1][params.doc_key] =
            data.data.data.url
          GeneralService.edit(`/clients/${this.tabInfo.id}`, {
            documents: [
              {
                [params.doc_key]: data.data.data.url
              }
            ]
          }).then((data) => {
            this.$toast.success(data.data.message)
            this.loadingsStateComponent.main = false
            this.loadingMainInfo()
          })
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error(err.response.data.message)
        })
    },
    removefile(params) {
      this.loadingsStateComponent.main = true
      this.galleryList[params.keyData - 1].client_blacklist_gallery__file = ''
      GeneralService.delete(
        `/clients/${this.tabInfo.id}/document/${params.doc_key}`
      )
        .then((data) => {
          this.loadingsStateComponent.main = false
          this.loadingMainInfo()
          // this.loadMainInfo()
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
    },
    clickOnImage(index) {
      console.log(index, this.images)
      this.index = index
    },
    sendSmsSome() {
      console.log(
        this.updatedValues.clients__phone || this.dataForTab.clients__phone
      )
      if (this.dataForTab.clients__phone || this.updatedValues.clients__phone) {
        GeneralService.editOrCreate(`/sms`, {
          phone:
            this.updatedValues.clients__phone || this.dataForTab.clients__phone,
          text: this.someSmsToSend
          // (this.dataForTab.clients__phone || this.updatedValues.clients__phone)
        })
          .then((data) => {
            this.$toast.success(data.data.message)
            this.someSmsToSend = ''
            this.modalInfo.loading = false
            // EventBus.$emit('closeModal')
            EventBus.$emit('contentLoaded')
            EventBus.$emit('updateContentData')
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message)
            this.someSmsToSend = ''
          })
      }
    },
    sendPerezvon(type) {
      console.log(type)
      let date
      if (type == 'day') {
        date = this.$time()
          .utc(true)
          .utcOffset(Number(this.userSetting.company__utc))
          .add(1, 'day')
          .format(`YYYY-MM-DD HH:mm:ss`)
        console.log(date)
      } else if (type == 'custom') {
        console.log(this.selectTime, typeof this.selectTime)
        date = this.$time(
          this.selectTime, 'DD.MM.YYYY HH:mm'
        ).utc(true)
          .utcOffset(Number(this.userSetting.company__utc))
          .format(`YYYY-MM-DD HH:mm`)
        console.log(date)
      } else {
        date = this.$time()
          .utc(true)
          .utcOffset(Number(this.userSetting.company__utc))
          .add(1, 'hour')
          .format(`YYYY-MM-DD HH:mm:ss`)
        console.log(date)
      }
      GeneralService.edit(`/clients/${this.modalInfo.id}`, {
        clients__next_call_date: date
      }).then((data) => {
        this.$toast.success(data.data.message)
        this.modalInfo.loading = false
        this.selectTime = []
        EventBus.$emit('closeModal')
        EventBus.$emit('contentLoaded')
        EventBus.$emit('updateContentData')
      })
    },

    loadComments() {
      GeneralService.get(`/clients/${this.modalInfo.id}/comments`)
        .then((data) => {
          this.commentsList = data.data.data.rows
          this.loadingsStateComponent.comment = false
        })
        .catch((e) => {
          console.log(e)
        })
    },
    sendSms() {
      if (
        (this.dataForTab.clients__phone || this.updatedValues.clients__phone) &&
        Object.values(this.senderSmsPreset).length
      ) {
        this.loadingsStateComponent.deferred = true
        console.log(this.senderSmsPreset)
        GeneralService.editOrCreate(`/sms`, {
          phone:
            this.dataForTab.clients__phone || this.updatedValues.clients__phone,
          text: this.senderSmsPreset.sms_presets__text
        })
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            this.$toast.success(data.data.message)
            this.loadingsStateComponent.deferred = false
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        this.$toast.error('Не введен номер телефона или не выбран пресет')
      }
    },
    delComment(id) {
      GeneralService.delete(`/clients/${this.modalInfo.id}/comments/${id}`)
        .then((data) => {
          // this.selects.push(data.data.data.rows)
          this.$toast.success(data.data.message)
          this.loadingsStateComponent.comment = false
          this.loadComments()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    fixComment(id, fix) {
      GeneralService.edit(`/clients/${this.modalInfo.id}/comments/${id}`, {
        client_comments__is_fixed: fix
      })
        .then((data) => {
          // this.selects.push(data.data.data.rows)
          this.$toast.success(data.data.message)
          this.loadingsStateComponent.comment = false
          this.loadComments()
          this.newCommentValue = {}
        })
        .catch((e) => {
          console.log(e)
        })
    },
    sendComment() {
      if (this.newCommentValue?.value) {
        this.loadingsStateComponent.comment = true
        GeneralService.editOrCreate(`/clients/${this.modalInfo.id}/comments`, {
          client_comments__comment: this.newCommentValue.value
        })
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            this.$toast.success(data.data.message)
            this.loadingsStateComponent.comment = false
            this.loadComments()
            this.newCommentValue = {}
            console.log(this.$refs.commentInput)
            this.$refs.commentInput.$el.querySelector('input').value = ''
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        this.$toast.error('Напишите комментарий')
      }
    },
    beforeClose() {
      this.updatedValues = {}
      this.dataForModal = {}
      this.modalInfo.modalOpened = false
      for (const key in this.dataFromSelect) {
        this.dataFromSelect[key] = []
      }
      this.dateForDeferredOrder = []
      for (const key in this.dataForDatePicker) {
        this.dataForDatePicker[key] = []
      }
      this.mainObject = ''
      this.clientName = ''
      this.finishCost = 0
      // this.$refs.objectName.$el.querySelector(input).textContent = ''
      this.diffByDate = 0
    },
    updateValue(data) {
      console.log(data)
      this.updatedValues[data.name] = data.value
      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
      // if (data.name == 'clients__phone') {
      //   clearTimeout(this.timeout)
      //   this.timeout = setTimeout(() => this.findClientInfo(data.value), 2000)
      // }
      // if (data.name == 'orders__vehicle_id') {
      //   this.dataForModal.orders__vehicle_id = data.value
      //   clearTimeout(this.timeout)
      //   this.timeout = setTimeout(
      //     () => this.findObjectAndOrderInfo(data.value),
      //     2000
      //   )
      // }
      // if (this.$refs[data.name]) {
      //   this.$refs[data.name].classList.remove('input-error')
      // }
    },
    setSimpleSelect(data, name) {
      if (this.$refs[name]) {
        this.$refs[name].classList.remove('input-error')
      }
      console.log(data, name)
      this.updatedValues[name ? name : data.key] = data.value
      console.log(this.updatedValues)
      delete this.updatedValues.clients_types_face
      if (name == 'orders__vehicle_type') {
        // /selects/body-generation/{vehicleTypeId}
        GeneralService.get(
          `/selects/body-generation/${data.fallback}?orderBy=vehicles_body_generation__position&orderDir=ASC`
        )
          .then((data) => {
            this.selectsOptions = Object.assign(this.selectsOptions, {
              car_body_type: data.data.data.rows
            })
          })
          .catch((e) => {
            console.log(e)
          })
      }
      if (data.key == 'clients_types_face') {
        console.log(12312312, data)
        this.updatedValues.clients__is_ur_face =
          data.value == 'clients_type_fiz' ? false : true
        this.dataForModal.clients__is_ur_face =
          data.value == 'clients_type_fiz' ? false : true
      }
      if (this.$refs[data.key]) {
        this.$refs[data.key].classList.remove('input-error')
      }
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    loadingMainInfo() {
      this.loadingsStateComponent.main = true
      Promise.all([
        GeneralService.get(
          `/clients/${this.tabInfo.id}?joins=statistics,verifications,documents`
        )
          .then((data) => {
            this.dataForTab = data.data.data.rows[0]
            this.galleryList.forEach((element, index) => {
              let indexed = data.data.data.rows[0].documents.findIndex(
                (x) =>
                  x.client_document_types__position ==
                  element.client_document_types__position
              )
              if (indexed >= 0) {
                this.galleryList[index] =
                  data.data.data.rows[0].documents[indexed]
              }
            })
            this.galleryList.forEach((element, index) => {
              this.images.push(element.clients_documents__file)
            })
            console.log(this.galleryList, this.images)
          })
          .catch((e) => {
            console.log(e)
          }),
        GeneralService.get(`/presets/sms`, {
          params: {
            where: JSON.stringify([
              { field: 'sms_presets__category_id', value: 2 }
            ])
          }
        })
          .then((data) => {
            Object.assign(this.selectsOptions, {
              presets: data.data.data.rows
            })
          })
          .catch((e) => {
            console.log(e)
          })
      ]).then(() => {
        EventBus.$emit('finishLoad')
        this.loadingsStateComponent.main = false
      })
    }
  },
  mounted() {},
  created() {
    this.loadComments()
    this.loadingMainInfo()
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting'])
  }
}
</script>
