import http from "@/http-common.js";

class VehicleTypeAndCharacteristicsService {
  basePathApiService = "/examples";
  getOne(id) {
    return http.get(this.basePathApiService + id);
  }
  getAll(vehicleTypeAndFilter) {
    return http.get(this.basePathApiService, vehicleTypeAndFilter);
  } 
  getAllByParams(params) {
    return http.get('/models', params);
  }
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  update(id, updateData) {
    return http.put(this.basePathApiService + `/${id}`, updateData);
  }
  deleteOne(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }
  
}
export default new VehicleTypeAndCharacteristicsService();
