<template>
  <div class="base-container-input" :ref="inputRef">
    <div class="base-container-input-title" v-if="nameSign">
      <span class="input-title" :class="signClass" v-html="nameSign"></span>
    </div>
    <textarea
      class="base-input"
      :class="inputClass"
      @input="onInput"
      :value="value"
      :style="`min-height:${minHeight ? minHeight + 'px' : ''}; resize:${!resize ? 'none' : 'both'}`"
      :placeholder="placeholder"
      :maxlength="maxLength"

    >
    </textarea>
  </div>
</template>

<script>
import IMask from 'imask'
export default {
  components: {},
  props: {
    inputRef: {
      default: null,
    },
    inputName: {
      type: String,
    },
    nameSign: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      default: '',
    },
    inputClass: {
      type: String,
    },
    inputType: {
      type: String,
      default: 'text',
    },

    disabled: {
      default: false,
    },

    inputMask: {
      default: false,
    },
    signClass: {
      type: String,
    },
    minHeight: {
      type: String,
    },
    maxLength: {
      type: Number
    },
    resize: {
      type: Boolean,
      default: true,
    }
  },

  methods: {
    onInput(event) {
      this.$emit('onInput', { name: this.inputName, value: event.target.value })
    },
  },

  mounted() {
    if (this.inputMask) {
      let element = null
      let maskOptions = {}
      if (typeof this.inputMask === 'string') {
        element = this.$refs[this.inputRef].querySelector('input')
        maskOptions = {
          mask: this.inputMask,
        }
      } else {
        element = this.$refs[this.inputRef].querySelector('input')
        maskOptions = {
          mask: '+{7}(000)000-00-00',
        }
      }

      const mask = IMask(element, maskOptions)
    }
  },
}
</script>

<!-- <template>
    <div class="base-container-input">
      <div class="base-container-input-title" v-if="nameSign">
        <span>{{ nameSign }}</span>
      </div>
      <textarea class="base-input" @input="onInput" :placeholder="placeholder">
        {{value}}
      </textarea>
    </div>
  </template>


  <script>
  export default {
    components: {

    },
    props: {
      inputName: {
        type: String,
      },
      nameSign: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      value: '',
    },
    methods: {
      onInput(event) {
        this.$emit('onInput', {name: this.inputName, value: event.target.value})
      }
    },
    mounted() {

    }
  };
  </script>
       -->
