import http from "@/http-common.js";

class FundsSettingsService {

  basePathApiService = "/finreestr-funds";

  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 

  updateNames(editData) {
    return http.post(this.basePathApiService + `/names-update`, editData);
  }
}

export default new FundsSettingsService();
