<template>
  <div>
    <BaseTopLine>
      <!-- <div class="d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('checklistModal')">Добавить сценарии</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить сценарий</div>
            </div> -->
    </BaseTopLine>
    <div class="p-20">
      <div class="row">
        <div class="col-lg-6">
          <p class="fs-4">Не выполненные</p>
          <BasePagination
            :page-count="numPages"
            :page-range="3"
            :margin-pages="2"
            :break-view-class="'d-none'"
            :click-handler="clickLoadData"
            :prev-text="'Назад'"
            :next-text="'Далее'"
            :container-class="'pagination pagination-sm'"
            :page-class="'page-item'"
            :pageLinkClass="'page-link'"
          >
          </BasePagination>

          <BaseReuseTable
            :props-data="checklistData"
            :props-columns-setting="fieldsTableChecklist"
            :modalActivateFlag="true"
            nameForAction="checklistModal"
            v-if="checklistLoadStatus == 'success' && loadJobTitiles"
          >
          </BaseReuseTable>
        </div>
        <div class="col-lg-6">
          <p class="fs-4">Выполенные</p>
          <BasePagination
            :page-count="numPages"
            :page-range="3"
            :margin-pages="2"
            :break-view-class="'d-none'"
            :click-handler="clickLoadData"
            :prev-text="'Назад'"
            :next-text="'Далее'"
            :container-class="'pagination pagination-sm'"
            :page-class="'page-item'"
            :pageLinkClass="'page-link'"
          >
          </BasePagination>
          <BaseReuseTable
            :props-data="listNotDone"
            :props-columns-setting="fieldsTableChecklistDone"
            nameForAction="checklistModalww"
            v-if="listNotDoneLoad"
          >
          </BaseReuseTable>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <Modal nameForAction="checklistModal" @closeModal="closeModal">
        <div class="p-10">
          <BaseTextarea
            placeholder=""
            title=""
            @onInput="updateValue"
            :value="dataForModal.checklist_progress__comment"
            name-sign="Комментарий"
            input-name="checklist_progress__comment"
          >
          </BaseTextarea>
          <div class="d-flex gap-10 mt-15">
            <div class="btn btn-sm btn-primary" @click="sendDataFromModal">
              {{ dataLang.form_btn__btn_save }}
            </div>
            <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">
              {{ dataLang.form_btn__btn_caneсl }}
            </div>
          </div>
        </div>
      </Modal>
      <Modal nameForAction="acceptDelete">
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">
            Да
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import JobtitlesService from '@/api/JobtitlesService.js'
import SelectsService from '@/api/SelectsService.js'
import ChecklistService from '@/api/ChecklistService.js'
export default {
  components: {
    Modal
  },

  props: {},
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      editData: {},
      dataForSelect: {
        dataPeriod: [],
        dataJobTitle: []
      },
      activeSelectRow: null,
      dateTimeSelect: { timeStart: '' },
      loadJobTitiles: false,
      dataJobTitleOptions: [],
      dataPeriodOptions: [],
      listNotDone: [],
      listNotDoneLoad: false
    }
  },
  methods: {
    clickLoadData(numPage) {
      this.getChecklistAll({
        params: {
          limit: 100,
          page: numPage
        }
      })
    },

    updateValueListAccess(nameFiled) {
      this.dataForModal[nameFiled] = !this.dataForModal[nameFiled]
      console.log(this.dataForModal)
    },
    openModalForCreate(nameModal) {
      EventBus.$emit('openModal', {
        data: null,
        nameForAction: nameModal,
        mode: 'create'
      })
    },

    setSimpleSelect(data, key) {
      console.log(data, key)
      switch (key) {
        case 'period':
          this.dataForModal.checklist_scripts__period = data.value
          console.log(this.dataForModal)
          break

        case 'jobtitle':
          this.dataForModal.checklist_scripts__jobtitle_id =
            data.employee_jobtitles__id
          console.log(this.dataForModal)
          break
      }
    },

    deleteActionAndModal() {
      if (this.activeSelectRow) {
        EventBus.$emit('openModal', {
          data: null,
          nameForAction: 'acceptDelete',
          mode: 'create'
        })
      } else {
        this.$toast.error('Вы не выбрали ни одного поля')
      }
    },
    deleteRow(nameModal) {
      this.deleteChecklistData(this.activeSelectRow)
      this.activeSelectRow = null
      EventBus.$emit('closeModal', { nameForAction: nameModal })
    },

    sendDataFromModal() {
      console.log(this.dataForModal)
      if (this.dataForModal.checklist_progress__id) {
        // if (this.dataForModal.checklist_scripts__time) {
        //     this.dataForModal.checklist_scripts__time = this.dateTimeSelect.timeStart
        // }

        this.editChecklistMultiple({
          id: this.dataForModal.checklist_progress__id,
          dataEdit: {
            checklist_progress__comment:
              this.dataForModal.checklist_progress__comment
          }
        })
        EventBus.$emit('closeModal', { nameForAction: 'checklistModal' })
      } else {
        this.createChecklistData(this.dataForModal)
        // this.$refs[checklistModal].$el.querySelectorAll('input').forEach(elem => {
        //     elem.value = ''
        // })

        EventBus.$emit('closeModal', { nameForAction: 'checklistModal' })
      }
    },

    updateValue(data) {
      this.dataForModal[data.name] = data.value
      console.log(this.dataForModal)
    },

    prepareKeysForModal(payloadData) {
      this.updatedValues = {}
      this.dataForModal = {}
      if (!payloadData) {
        for (const key in this.checklistData[0]) {
          this.dataForModal[key] = null
        }
      } else {
        this.dataForModal = Object.assign({}, payloadData)
        // this.dataForSelect.dataPeriod = this.dataPeriodOptions.find(x => payloadData.checklist_scripts__period == x.value)
        // this.dataForSelect.dataJobTitle = this.dataJobTitleOptions.find(x => payloadData.checklist_scripts__jobtitle_id == x.employee_jobtitles__id)
        // this.dateTimeSelect.timeStart = payloadData.checklist_scripts__time
        //     dataForSelect: {
        //     dataPeriod: [],
        //     dataJobTitle: []
        // },
        // activeSelectRow: null,
        // dateTimeSelect: { timeStart: '' },
      }
    },

    closeModal(data) {
      this.activeSelectRow = null
      this.dataForModal = {}
      for (const key in this.dataForModal) {
        this.dataForModal[key] = ''
      }
      ;(this.dataForSelect = {
        dataPeriod: [],
        dataJobTitle: []
      }),
        (this.activeSelectRow = null),
        (this.dateTimeSelect = { timeStart: '' })
    },
    btnHandlerCloseModal(nameModal) {
      EventBus.$emit('closeModal', { nameForAction: 'checklistModal' })
    },

    handlerForTablue(e, cell) {
      console.log(e, cell.getData())
      this.editChecklistMultiple({
        id: cell.getData().checklist_progress__id,
        dataEdit: {
          checklist_progress__is_done:
            !cell.getData().checklist_progress__is_done
        },
        type: 'status'
      })
      this.listNotDone.unshift(cell.getData())
    },

    ...mapActions('checklistModule', [
      'getChecklistAll',
      'createChecklistData',
      'editChecklistMultiple',
      'deleteChecklistData'
    ])
  },
  mounted() {
    EventBus.$on('openModal', (payload) => {
      this.editData = payload.data
      console.log(payload.data)
      this.prepareKeysForModal(payload.data)
    })
    EventBus.$on('selectTableRows', (payload) => {
      this.activeSelectRow = payload.data.checklist_scripts__id
    })
    EventBus.$on('deselectTableRows', (payload) => {
      this.activeSelectRow = null
    })
  },

  created() {
    this.getChecklistAll({ params: { page: 1, limit: 100 } })

    JobtitlesService.getAll()
      .then((data) => {
        this.dataJobTitleOptions = data.data.data.rows
        this.loadJobTitiles = true
      })
      .catch((err) => {
        console.log(err)
      })
    // SelectsService.getOneByField('checklist_period').then((data) => {
    //     this.dataPeriodOptions = data.data.data.rows
    //     console.log(data);
    // })

    ChecklistService.getAllDone({ params: { page: 1, limit: 100 } }).then(
      (data) => {
        this.listNotDone = data.data.data.rows
        this.listNotDoneLoad = true
        console.log(this.listNotDone)
      }
    )
  },
  computed: {
    ...mapState('checklistModule', [
      'checklistData',
      'checklistLoadStatus',
      'paginationChecklistStateParams'
    ]),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('checklistModule', ['numPages']),

    // page: {
    //     get() {
    //         return this.paginationStateParams.page
    //     },
    //     set(page) {
    //         console.log(page);
    //         this.getAll({page: page , limit: 10})
    //     }
    // },

    fieldsTableChecklist() {
      // {
      //     "checklist_progress__added_at": "2023-10-10",
      //     "checklist_progress__closed_at": null,
      //     "checklist_progress__comment": null,
      //     "checklist_progress__company_id": 2,
      //     "checklist_progress__description": "\u041d\u0435 \u0437\u0430\u0431\u0443\u0434\u044c \u043f\u0440\u043e \u0441\u0430\u043c\u043e\u043a\u0430\u0442\u044b \u0432 \u0441\u0430\u0440\u0430\u0435",
      //     "checklist_progress__employee_id": 3,
      //     "checklist_progress__id": 2,
      //     "checklist_progress__is_done": 0,
      //     "checklist_progress__jobtitle_id": 3,
      //     "checklist_progress__name": "\u041f\u0435\u0440\u0435\u0441\u0447\u0438\u0442\u0430\u0442\u044c \u0432\u0435\u0441\u044c \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442",
      //     "checklist_progress__script_id": null,
      //     "checklist_progress__time": "09:00:00",
      //     "checklist_progress__updated_at": null
      // },

      return [
        { title: 'ID', field: 'checklist_progress__id', headerFilter: 'input' },
        {
          title: 'Наименование',
          field: 'checklist_progress__name',
          headerFilter: 'input'
        },
        {
          title: 'Дата создания',
          field: 'checklist_progress__added_at',
          headerFilter: 'input'
        },
        {
          title: 'Описание',
          field: 'checklist_progress__description',
          headerFilter: 'input'
        },
        {
          title: 'Комментарий',
          field: 'checklist_progress__comment',
          headerFilter: 'input'
        },
        {
          title: 'Для должности',
          field: 'checklist_progress__jobtitle_id',
          headerFilter: 'input',
          formatter: (cell) => {
            let index = this.dataJobTitleOptions.findIndex(
              (item, index) =>
                item.employee_jobtitles__id ==
                cell.getData().checklist_progress__jobtitle_id
            )
            console.log(this.dataJobTitleOptions[index])
            // this.dataJobTitleOptions[index].employee_jobtitles__name
            return this.dataJobTitleOptions[index]
              ? this.dataJobTitleOptions[index].employee_jobtitles__name
              : 'Не определена'
          }
        },
        {
          title: 'Время',
          field: 'checklist_progress__time',
          headerFilter: 'input'
        },
        {
          title: 'Выполнение',
          field: 'checklist_progress__is_done',
          formatter: (cell) => {
            if (cell.getData().checklist_progress__is_done) {
              return '<div class="btn btn-sm btn-primary wp-100">Выполнено</div>'
            } else {
              return '<div class="btn btn-sm btn-danger wp-100">Выполнение</div>'
            }
          },
          cellClick: this.handlerForTablue
        }
      ]
    },
    fieldsTableChecklistDone() {
      // {
      //     "checklist_progress__added_at": "2023-10-10",
      //     "checklist_progress__closed_at": null,
      //     "checklist_progress__comment": null,
      //     "checklist_progress__company_id": 2,
      //     "checklist_progress__description": "\u041d\u0435 \u0437\u0430\u0431\u0443\u0434\u044c \u043f\u0440\u043e \u0441\u0430\u043c\u043e\u043a\u0430\u0442\u044b \u0432 \u0441\u0430\u0440\u0430\u0435",
      //     "checklist_progress__employee_id": 3,
      //     "checklist_progress__id": 2,
      //     "checklist_progress__is_done": 0,
      //     "checklist_progress__jobtitle_id": 3,
      //     "checklist_progress__name": "\u041f\u0435\u0440\u0435\u0441\u0447\u0438\u0442\u0430\u0442\u044c \u0432\u0435\u0441\u044c \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442",
      //     "checklist_progress__script_id": null,
      //     "checklist_progress__time": "09:00:00",
      //     "checklist_progress__updated_at": null
      // },

      return [
        { title: 'ID', field: 'checklist_progress__id', headerFilter: 'input' },
        {
          title: 'Наименование',
          field: 'checklist_progress__name',
          headerFilter: 'input'
        },
        {
          title: 'Дата создания',
          field: 'checklist_progress__added_at',
          headerFilter: 'input'
        },
        {
          title: 'Описание',
          field: 'checklist_progress__description',
          headerFilter: 'input'
        },
        {
          title: 'Комментарий',
          field: 'checklist_progress__comment',
          headerFilter: 'input'
        },
        {
          title: 'Для должности',
          field: 'checklist_progress__jobtitle_id',
          headerFilter: 'input',
          formatter: (cell) => {
            let index = this.dataJobTitleOptions.findIndex(
              (item, index) =>
                item.employee_jobtitles__id ==
                cell.getData().checklist_progress__jobtitle_id
            )
            console.log(this.dataJobTitleOptions[index])
            // this.dataJobTitleOptions[index].employee_jobtitles__name
            return this.dataJobTitleOptions[index]
              ? this.dataJobTitleOptions[index].employee_jobtitles__name
              : 'Не определена'
          }
        },
        {
          title: 'Время',
          field: 'checklist_progress__time',
          headerFilter: 'input'
        }
        // {
        //     title: 'Выполнение', field: "checklist_progress__is_done", formatter: (cell) => {
        //             return 'Выполнено'
        //     },
        //     // cellClick: this.handlerForTablue
        // },
      ]
    }
  }
}
</script>
