<template>
    <div>
        <BaseTopLine>
            <div class="d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('employeesAdminModal')">Добавить</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div>
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('jobTitle')">Добавить должность</div>
            </div>
        </BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'"
                :click-handler="clickLoadData" :prev-text="'Назад'" :next-text="'Далее'"
                :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="employeesAdminData" :props-columns-setting="fieldsTableemployeesAdmin"
                :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="employeesAdminModal"
                v-if="employeesAdminLoadStatus == 'success' && loadJobTitiles">
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="employeesAdminModal" @closeModal="closeModal">
                <vue-tabs>
                    <v-tab title="Создание">
                        <div class="p-10">

                            <span v-if="modeOpenModal == 'create'">Поиск клиента</span>
                            <BaseSelect placeholder="" selectLabel="" v-if="modeOpenModal == 'create'" deselectLabel=""
                                v-model="dataForSelect.dataClient" label="clients__phone" @input="setSimpleSelect"
                                id="clients" :options="dataClientOptions"
                                :custom-label="({ clients__phone, clients__name }) => clients__phone + ' ' + clients__name">
                                <div slot="no-options">Пока нет опций</div>
                            </BaseSelect>
                            <!-- <BaseInput placeholder="" title="" @onInput="updateValue"  v-if="modeOpenModal == 'create'" :value="dataForModal.employees__phone"
                                name-sign="Номер" input-name="employees__phone" inputRef="employees__phone"
                                :inputMask="true">
                            </BaseInput>
                            <BaseInput placeholder="" title="" @onInput="updateValue" :value="dataForModal.employees__name"
                                name-sign="Имя" input-name="employees__name">
                            </BaseInput> -->
                            <span>Должность</span>
                            <BaseSelect placeholder="" selectLabel="" deselectLabel="" v-model="dataForSelect.dataJobTitle"
                                @input="setSimpleSelect" id="jobtitle" :options="dataJobTitleOptions"
                                label="employee_jobtitles__name">
                                <div slot="no-options">Пока нет опций</div>
                            </BaseSelect>
                            <!-- <BaseInput placeholder="" title="" @onInput="updateValue" :value="dataForModal.employees__name"
                                name-sign="Имя" input-name="employees__name">
                            </BaseInput> -->
                            <BaseInput placeholder="" title="" @onInput="updateValueEmail" :value="email" name-sign="Email"
                                input-name="employees__email">
                            </BaseInput>


                            <div class="d-flex gap-10 mt-15">
                                <div class="btn btn-sm btn-primary" @click="sendDataFromModal">{{
                                    dataLang.form_btn__btn_save }}</div>
                                <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                                    dataLang.form_btn__btn_caneсl }}</div>
                            </div>
                        </div>
                    </v-tab>
                    <v-tab title="Доступ к базам" v-if="modeOpenModal == 'edit'">
                        <div class="py-10">
                            <!-- <BaseCheckbox v-for="(item, index) in listAccessPage" :key="index"
                                v-model="1"
                                @onCheckeds="updateValue">
                                wdwdwdw
                            </BaseCheckbox> -->
                            <!-- <BaseCheckbox v-for="(item, index) in listAccessPage" :key="index" title="Default Title"
                                input-name="company_settings__set_doc_avtodelete_fl"
                                :onchecked="Boolean(item.employeeHasRule)" @onCheckeds="updateAccessValue">
                                {{ item.menu__item }} {{ item.employeeHasRule }}
                            </BaseCheckbox> -->
                            <div class="btn btn-sm btn-primary wp-100 mb-20" @click="sendUpdateAccesss">Сохранить</div>
                            <BaseCheckboxV2 placeholder="" v-for="(item, index) in listAccessPage" :key="index"
                                input-name="checkboxx" v-model="listUpdateAccessPage[index].employee_menu_rules__state"
                                @oncheckeds="updateValueListAccess(listUpdateAccessPage[index])">
                                {{ item.menu__item }}
                            </BaseCheckboxV2>
                            <div class="btn btn-sm btn-primary wp-100 mt-20" @click="sendUpdateAccesss">Сохранить</div>
                        </div>
                    </v-tab>
                </vue-tabs>
            </Modal>
            <Modal nameForAction="showPassword">
                <div class="text-center">
                    <div class="fw-bold fs-4">
                        {{ passwordForModal }}
                    </div>
                </div>
            </Modal>
            <Modal nameForAction="jobTitle">

                <BaseInput placeholder="" class="wp-100" title="" :value="newJobTitle" @onInput="updateValueJobtitle"
                    name-sign="Имя" input-name="jobtitle">
                </BaseInput>


                <div class="d-flex gap-10 mb-10 mt-10 align-items-end">
                    <div class="btn btn-sm btn-primary wp-50" @click="sendJobTitle">Добавить</div>
                    <div class="btn btn-sm btn-opposite wp-50" @click="deleteActionAndModal">Удалить</div>
                </div>

                <BaseReuseTable :props-data="dataJobTitleOptions" :props-columns-setting="fieldsTablJobTitle"
                    :selectRowActivateFlag="true" :modalActivateFlag="true" v-if="loadJobTitiles" nameForAction="jobTitle">
                </BaseReuseTable>
            </Modal>

            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import Modal from '@/components/Modals.vue'
import ClientsService from "@/api/ClientsService";
import JobtitlesService from "@/api/JobtitlesService.js";
import EmployeeAccessService from "@/api/EmployeeAccessService.js";
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";

export default {
    components: {
        Modal,
        BaseTextarea, VueTabs, VTab
    },

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            dataForSelect: {
                dataTS: [],
                dataClient: [],
                dataHasDriver: [],
                dataJobTitle: []
            },
            dataClientOptions: [],
            dataJobTitleOptions: [],

            listAccessPage: [],
            listUpdateAccessPage: [],
            listUpdateAccessPageForSend: [],
            activeSelectRow: null,
            loadJobTitiles: false,
            newJobTitle: '',
            email: '',
            modeOpenModal: 'create',
            activeUser: ''
        };
    },
    methods: {
        updateValueEmail(data) {
            this.email = data.value
        },
        updateValueListAccess(data) {
            console.log(data);
            let editedData = data
            editedData.employee_menu_rules__state = !editedData.employee_menu_rules__state
            let findIndexIsExist = this.listUpdateAccessPageForSend.findIndex(x => x.employee_menu_rules__menu_id == editedData.employee_menu_rules__menu_id)
            if (findIndexIsExist !== -1) {
                this.listUpdateAccessPageForSend[findIndexIsExist] = editedData
            } else {
                this.listUpdateAccessPageForSend.push(editedData)
            }

            console.log(this.listUpdateAccessPageForSend);
            // EmployeeAccessService.update(editedData).then(data => {
            //     this.$toast.success('Доступ обновлён')
            // }).catch(err => {
            //     this.$toast.success('Невозможно обновить доступ')
            // })
        },
        sendUpdateAccesss() {
            if (this.listUpdateAccessPageForSend.length) {
                EmployeeAccessService.update(this.listUpdateAccessPageForSend).then(data => {
                this.$toast.success('Доступ обновлён')
            }).catch(err => {
                this.$toast.success('Невозможно обновить доступ')
            })
            } else {
                this.$toast.success('Поменяйте доступ хотя бы у одного раздела')
            }
            
        },
        sendJobTitle() {
            if (this.newJobTitle) {
                JobtitlesService.create({ employee_jobtitles__name: this.newJobTitle.trim() }).then(data => {
                    this.dataJobTitleOptions.unshift(data.data.data.rows[0])
                    this.$toast.success('Должность успешно добавлена')
                    this.newJobTitle = ''
                }).catch(err => {
                    this.$toast.error('Ошибка загрузки')
                    console.log(err);
                })
            }
        },

        updateValueJobtitle(data) {
            this.newJobTitle = data.value
        },
        customLabel({ clients__phone, clients__name }) {
            return clients__phone + " " + clients__name
        },
        setSimpleSelect(data, key) {
            console.log(data, key);
            switch (key) {

                case 'clients':
                    this.dataForModal.employees__name = data.clients__name
                    this.dataForModal.employees__phone = data.clients__phone
                    this.dataForModal.employees__client_id = data.clients__id
                    console.log(this.dataForModal)
                    break;

                case 'jobtitle':
                    this.dataForModal.employees__jobtitle_id = data.employee_jobtitles__id
                    console.log(this.dataForModal);
                    break;
            }
        },
        updateValue(data) {
            this.dataForModal[data.name] = data.value
            console.log(this.dataForModal);
        },
        clickLoadData(numPage) {
            this.getEmployessAdminAll({
                params: {
                    limit: 10,
                    page: numPage
                }
            })
        },
        openModalForCreate(nameModal) {
            EventBus.$emit("openModal", { data: null, nameForAction: nameModal, mode: 'create' })
        },


        deleteActionAndModal() {
            console.log(this.activeSelectRow);
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },
        deleteRow(nameModal) {
            console.log(this.activeSelectRow);
            if (this.activeSelectRow.nameForAction == "employeesAdminModal") {
                console.log('22222222');
                this.deleteEmployeesAdminData(this.activeSelectRow.data.employees__id)
                EventBus.$emit("closeModal", { nameForAction: nameModal })
            } else {
                console.log(this.activeSelectRow.data.employee_jobtitles__id);
                JobtitlesService.delete(this.activeSelectRow.data.employee_jobtitles__id).then(() => {
                    let indexed = this.dataJobTitleOptions.findIndex(x => x.employee_jobtitles__id == this.activeSelectRow.data.employee_jobtitles__id)
                    this.dataJobTitleOptions.splice(indexed, 1)
                    this.$toast.success('Должность успешно удалена')
                    // this.activeSelectRow = null
                    EventBus.$emit("closeModal", { nameForAction: nameModal })
                }).catch(err => { console.log(err); this.$toast.error('Произошла ошибка') })
            }

        },


        sendDataFromModal() {
            // console.log(this.dataForModal);
            if (this.modeOpenModal == 'edit') {
                console.log('edit');
                delete this.dataForModal.employees__client_id
                delete this.dataForModal.employees__id
                delete this.dataForModal.employees__is_email_confirmed
                delete this.dataForModal.employees__last_login_at
                delete this.dataForModal.employees__updated_at
                delete this.dataForModal.employees__is_admin
                //                 {
                //     "employees__company_id": 2,
                //     "employees__created_at": "2023-10-05 12:03:33",
                //     "employees__email": "awdawdawd@gmail.com",
                //     "employees__is_admin": 0,
                //     "employees__is_email_confirmed": 1,
                //     "employees__jobtitle_id": 4,
                //     "employees__last_login_at": null,
                //     "employees__name": "12311111111111",
                //     "employees__phone": "+7(222)222-22-222",
                //     "employees__updated_at": null
                // }
                this.editEmployeesAdminMultiple({ id: this.activeUser, dataEdit: Object.assign(this.dataForModal, { employees__email: this.email }) })
                EventBus.$emit("closeModal", { nameForAction: 'employeesAdminModal' })
            } else {
                console.log(this.dataForModal);
                if (this.email) {
                    console.log(this.email);
                    delete this.dataForModal.employees__id
                    delete this.dataForModal.employees__is_email_confirmed
                    delete this.dataForModal.employees__last_login_at
                    delete this.dataForModal.employees__updated_at
                    delete this.dataForModal.employees__is_admin
                    delete this.dataForModal.employees__company_id
                    delete this.dataForModal.employees__created_at
                    this.createEmployeesAdminData(Object.assign(this.dataForModal, { employees__email: this.email }))
                    EventBus.$emit("closeModal", { nameForAction: 'employeesAdminModal' })
                    EventBus.$emit("openModal", { data: null, nameForAction: 'showPassword', mode: 'create' });
                } else {
                    this.$toast.error('Заполните поле Email')
                }


            }
        },


        updateValue(data) {
            this.updatedValues[data.name] = data.value
            console.log(this.updatedValues);
        },

        prepareKeysForModal(payloadData) {
            this.updatedValues = {}
            this.dataForModal = {}
            if (!payloadData) {
                for (const key in this.employeesAdminData[0]) {
                    this.dataForModal[key] = null
                    this.dataForSelect.dataClient = []
                }
                this.modeOpenModal = 'create'
            } else {
                this.dataForModal = Object.assign({}, payloadData)
                this.modeOpenModal = 'edit'
                console.log(payloadData.employees__id);
                this.activeUser = payloadData.employees__id
                this.email = payloadData.employees__email
                // console.log(this.dataJobTitleOptions.find((item, index) =>  item.employee_jobtitles__id == payloadData.employees__jobtitle_id));
                this.dataForSelect.dataJobTitle = this.dataJobTitleOptions.find((item, index) => item.employee_jobtitles__id == payloadData.employees__jobtitle_id)
                EmployeeAccessService.getAll(payloadData.employees__id).then(data => {
                    data.data.data.forEach(element => {
                        this.listUpdateAccessPage.push(
                            {
                                "employee_menu_rules__employee_id": payloadData.employees__id,
                                "employee_menu_rules__menu_id": element.menu__id,
                                "employee_menu_rules__state": Boolean(element.employeeHasRule)
                            }
                        )


                        this.listAccessPage.push(element)
                        console.log(this.listAccessPage);
                        element.children.forEach(listChildren => {
                            this.listUpdateAccessPage.push(
                                {
                                    "employee_menu_rules__employee_id": payloadData.employees__id,
                                    "employee_menu_rules__menu_id": listChildren.menu__id,
                                    "employee_menu_rules__state": Boolean(listChildren.employeeHasRule)
                                }
                            )
                            this.listAccessPage.push(listChildren)
                        });
                    });

                }).catch(err => { console.log(err); })
            }

        },

        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
            this.email = ''
            // this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
            //     element.value = ''
            // })
            for (const key in this.dataForSelect) {
                this.dataForSelect[key] = []
            }

            this.listAccessPage = []
            this.listUpdateAccessPage = []
            this.listUpdateAccessPageForSend = []


        },
        btnHandlerCloseModal(nameModal) {
            EventBus.$emit("closeModal", { nameForAction: 'employeesAdminModal' })
        },


        ...mapActions("employeesAdminModule", ["getEmployeesAdminAll", 'createEmployeesAdminData', 'editEmployeesAdminMultiple', 'deleteEmployeesAdminData']),
        ...mapActions("jobtitlesModule", ["getJobtitlesAll"]),


    },
    mounted() {

        EventBus.$on("openModal", (payload) => {
            this.editData = payload.data
            console.log(payload.data);
            this.prepareKeysForModal(payload.data)
        });
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });
    },

    created() {
        this.getEmployeesAdminAll({
            params: {
                page: 1, limit: 100,
            }
        })


        JobtitlesService.getAll().then(data => {
            this.dataJobTitleOptions = data.data.data.rows
            this.loadJobTitiles = true
        }).catch(err => { console.log(err); })





        ClientsService.getAll().then(data => {
            this.dataClientOptions = data.data.data.rows
        }).catch(err => { console.log(err); })
    },
    computed: {
        ...mapState("employeesAdminModule", ["employeesAdminData", "employeesAdminLoadStatus", 'paginationemployeesAdminStateParams', 'passwordForModal']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("employeesAdminModule", ["numPages"]),


        fieldsTableemployeesAdmin() {
            return [
                { title: 'ID', field: "employees__id", headerFilter: "input" },
                { title: 'ФИО', field: "employees__name", headerFilter: "input" },
                {
                    title: 'Должность', field: "employees__jobtitle_id", headerFilter: "input",
                    formatter: (cell) => {
                        let index = this.dataJobTitleOptions.findIndex((item, index) => item.employee_jobtitles__id == cell.getData().employees__jobtitle_id)
                        console.log(this.dataJobTitleOptions[index]);
                        // this.dataJobTitleOptions[index].employee_jobtitles__name
                        return this.dataJobTitleOptions[index] ? this.dataJobTitleOptions[index].employee_jobtitles__name : "Не определена"
                    }
                },
                { title: 'Телефон', field: "employees__phone", headerFilter: "input" },
                { title: 'Email', field: "employees__email", headerFilter: "input" },
                { title: 'Дата последнего входа', field: "employees__last_login_at", headerFilter: "input" },
            ]
        },
        fieldsTablJobTitle() {
            return [
                { title: 'ID', field: "employee_jobtitles__id", headerFilter: "input" },
                { title: 'Должность', field: "employee_jobtitles__name", headerFilter: "input" },
            ]
        }
    },
};
</script>
    
    