<template>
  <BaseModal
    name="addNewOsagoModal"
    width="420"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="beforeClose"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div class="py-20 px-30 rounded-25 bg-white">
      <div class="d-flex justify-content-end">
        <slot name="modal-header"></slot>
        <button
          type="button"
          class="btn-close"
          @click="$modal.hide('addNewOsagoModal')"
        ></button>
      </div>
      <div
        class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
      >
        <p class="fw-bold mb-30 text-center">
          {{ dataLang['vehicle_modal_security_modals_osago_title'] }}
        </p>
        <div
          class="d-flex justify-content-between align-items-center wp-100"
          ref="date"
        >
          <span class="text-muted fsc-14 input-title">
            {{ dataLang['vehicle_modal_security_modals_date'] }}
          </span>
          <div>
            <BaseDatePicker
              v-model="dataForEmit.datenew"
              type="date"
              class=""
              lang="ru"
              style="width: 213px"
              value-type="format"
              :format="userSetting.company__tempalte_date"
              @change="(date) => changeDate(date)"
            ></BaseDatePicker>
          </div>
        </div>
        <div class="mb-10 wp-100 mb-10" ref="ownerId">
          <span class="fsc-14 text-muted input-title">
            {{ dataLang['vehicle_modal_security_modals_owner'] }}
          </span>
          <BaseSelect
            class="wp-100"
            @input="setSelected"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            v-model="owner"
            label="vehicle_owners__name"
            :options="listOptions"
          >
            <template slot="noOptions">{{ dataLang['select_empty'] }}</template>
            <template slot="noResult">{{
              dataLang['select_search_empty']
            }}</template>
            <template slot="selectedLabel">{{
              dataLang['select_selected']
            }}</template>
            <template slot="deselectLabel">{{
              dataLang['select_unselect']
            }}</template>
          </BaseSelect>
        </div>
        <div class="wp-100">
          <span class="fsc-14">{{
            dataLang['vehicle_modal_security_modals_text2']
          }}</span>
        </div>

        <div class="wp-100 mt-10" ref="city">
          <BaseInputNew
            placeholder=""
            :width="'145'"
            class="mb-10 wp-100"
            signClass="fsc-14"
            input-name="city"
            @input="updateValue"
            :horizontalTitle="true"
            :value="dataForEmit.city"
            :name-sign="dataLang['vehicle_modal_security_modals_city']"
          >
          </BaseInputNew>
        </div>
        <div ref="nolimit" class="wp-100">
          <BaseCheckboxV2
            placeholder=""
            class="mb-10"
            input-name="nolimit"
            v-model="dataForEmit.nolimit"
            signClass="fsc-14"
            @oncheckeds="updateValue"
            >{{
              dataLang['vehicle_modal_security_modals_driver_nolimit']
            }}</BaseCheckboxV2
          >
        </div>
        <div class="wp-100 mb-15" ref="comment">
          <BaseTextarea
            placeholder=""
            class="wp-100 fsc-14 mb-5"
            signClass="fsc-14 text-muted"
            input-name="comment"
            @onInput="updateValue"
            :minHeight="'160'"
            inputClass="fsc-14"
            :name-sign="dataLang['vehicle_modal_comments_modals_text3']"
            :value="dataForEmit.comment"
          >
          </BaseTextarea>
        </div>
        <div
          class="text-center fw-semi-bold fsc-14 py-5 bg-system-secondary wp-100 mb-15"
        >
          {{ dataLang['vehicle_modal_security_modals_text'] }}
        </div>
        <div class="d-flex justify-content-end gap-15">
          <div
            class="base-button px-20 rounded-10"
            @click="$modal.hide('addNewOsagoModal')"
          >
            {{ dataLang['vehile_modal_btn_reset'] }}
          </div>
          <div
            @click="sendData"
            class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
          >
            {{ dataLang['vehile_modal_btn_save'] }}
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ObjectService from '@/api/ObjectsService.js'
import BidsService from '@/api/BidsService'
import EventBus from '@/event-bus.js'
import OwnersService from '@/api/OwnersService'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
// TODO Сделать тестовую модалку с евентбасом, рефом, открытием по ссылке https://webdevblog.ru/vue-js-kak-ya-vyzyvaju-metod-v-komponente-izvne-komponenta-v-vue-2/
export default {
  components: {},
  props: {
    stateLoading: {
      type: Boolean,
      default: false
    },
    optionalParams: {
      type: Object
    }
  },
  data() {
    return {
      selectLabel: '',
      listOptions: [],
      owner: [],
      dataForEmit: {
        datenew: '',
        ownerId: '',
        nolimit: false,
        comment: '',
        city: '',
        request_services__type_service_id: 1,
        vehicleId: this.optionalParams.id
      },
      dateList: {
        date: ''
      }

      //       {
      //    "request_services__type_service_id": 1,
      //    "vehicleId":128,
      //    "date":"2020-05-05",
      //    "ownerId":1,
      //    "city":"Орехово",
      //    "nolimit":true,
      //    “comment”: “текст”
      // }
    }
  },
  methods: {
    changeDate(e) {
      console.log(e)
      this.dateList.date = e.split('.').reverse().join('-')
      this.$refs.date.classList.remove('input-error')
    },
    setSelected(data) {
      console.log(data.vehicle_owners__id)
      this.dataForEmit.ownerId = data.vehicle_owners__id
      this.$refs.ownerId.classList.remove('input-error')
    },
    beforeClose(event) {
      // this.dataForEmit.vehicle_comments__text = null
      // this.dataForEmit.vehicle_comments__is_critical = null
      // this.selectLabel = ''
      this.dataForEmit = {
        date: '',
        ownerId: '',
        nolimit: false,
        comment: '',
        city: '',
        request_services__type_service_id: 1,
        vehicleId: this.optionalParams.id
      }
      this.owner = []
      Object.keys(this.dataForEmit).forEach((elem, index) => {
        if (this.$refs[elem]) {
          this.$refs[elem].classList.remove('input-error')
        }
      })
    },
    updateValue(data) {
      this.dataForEmit[data.name] = data.value
      this.$refs[data.name].classList.remove('input-error')
    },

    sendData() {
      BidsService.create(Object.assign(this.dataForEmit, this.dateList))
        .then((data) => {
          console.log(data)
          this.$toast.success('Запись добавлена')
          Object.keys(this.dataForEmit).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.remove('input-error')
            }
          })
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.dataForEmit = {
            date: '',
            ownerId: '',
            nolimit: false,
            comment: '',
            city: '',
            request_services__type_service_id: 1,
            vehicleId: this.optionalParams.id
          }
          this.owner = []
          this.$modal.hide('addNewOsagoModal')
        })
        .catch((err) => {
          console.log(err)
          Object.keys(err.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
          this.$toast.error(err.response.data.message)
        })
    }
  },
  mounted() {
    dayjs.extend(utc)
  },
  created() {
    OwnersService.getAll()
      .then((data) => {
        this.listOptions = data.data.data.rows
      })
      .catch((err) => {
        this.$toast.error(err.response.data.message)
      })
  },
  beforeDestroy() {},

  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting'])
  }
}
</script>
