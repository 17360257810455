<template>
  <div class="">
    <BaseTopLine></BaseTopLine>
    <div class="position-relative h-100 container-fluid">
      <BaseLoader v-if="localLoading"></BaseLoader>
      <!-- <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div> -->
      <div>
        <div class="d-flex justify-content-between py-15">
          <div class="d-flex gap-10 align-items-center">
            <div class="base-button base-button--action" @click="acceptDelete">
              <img
                :src="require(`@/assets/icons/adddel.svg`)"
                width="13.5"
                height="13.5"
              />
              <span class="fsc-14">{{
                dataLang['orders_page_btn_text6']
              }}</span>
            </div>
            <!-- <div class="base-button base-button--action">
          <img
            :src="require(`@/assets/icons/add-new.svg`)"
            width="16"
            height="16"
          />
          <span class="fsc-14">{{ dataLang['orders_page_btn_text7'] }}</span>
        </div> -->
            <div class="base-button base-button--action" @click="fromArchive">
              <img
                :src="require(`@/assets/icons/document-previous.svg`)"
                width="18"
                height="18"
              />
              <span class="fsc-14">
                {{ dataLang['orders_page_btn_text8'] }}</span
              >
            </div>
            <!-- <div class="base-button base-button--action" @click="">
          <img
            :src="require(`@/assets/icons/to-exel.svg`)"
            width="16"
            height="16"
          />
          <span class="fsc-14">в Excel</span>
        </div> -->
          </div>
          <div class="d-flex align-items-center gap-15">
            <span class="fsc-11 text-muted">{{
              dataLang['orders_page_text11']
            }}</span>

            <BaseDatePicker
              v-model="dataTimeSelect.orders_from"
              type="date"
              class="fsc-11 idiots-class-for-input-date"
              lang="ru"
              id="vehicle_osago__policy_from"
              style="width: 110px"
              value-type="format"
              :format="userSetting.company__tempalte_date"
              @change="
                (date) =>
                  fillDateFilter({
                    value: date,
                    name: 'orders__created_at',
                    index: 0
                  })
              "
            ></BaseDatePicker>
            <BaseDatePicker
              v-model="dataTimeSelect.orders_to"
              type="date"
              class="fsc-11 idiots-class-for-input-date"
              lang="ru"
              id="vehicle_osago__policy_from"
              style="width: 110px"
              value-type="format"
              :format="userSetting.company__tempalte_date"
              @change="
                (date) =>
                  fillDateFilter({
                    value: date,
                    name: 'orders__created_at',
                    index: 1
                  })
              "
            ></BaseDatePicker>
            <div class="base-button base-button--action" @click="loadData(1)">
              <span class="fsc-14">{{
                dataLang['orders_page_btn_text10']
              }}</span>
            </div>
          </div>
        </div>

        <div class="d-flex gap-15 fsc-11" v-if="!localLoading">
          <div>
            <span
              >{{ dataLang['orders_page_text7'] }}:
              <span class="fw-bold">{{ countOrders }}</span>
            </span>
          </div>
          <div>
            <span
              >{{ dataLang['orders_page_text8'] }}:
              <span class="fw-bold">{{ statistics.new.count }}</span> ({{
                statistics.new.percent.toFixed(1)
              }}%)</span
            >
          </div>
          <div>
            <span
              >{{ dataLang['orders_page_text9'] }}:
              <span class="fw-bold">{{ statistics.permanent.count }}</span> ({{
                statistics.permanent.percent.toFixed(1)
              }}%)</span
            >
          </div>
          <div>
            <span
              >{{ dataLang['orders_page_text10'] }}:
              <span class="fw-bold">{{ statistics.super.count }}</span> ({{
                statistics.super.percent.toFixed(1)
              }}%)</span
            >
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-15 mb-5">
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="computedAllPage"
          :pagination-params="activePagePagination"
          :showLimitInput="true"
          @updateLimitValue="updateLimitValue"
          :page-range="10"
          :margin-pages="2"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="dataTable"
        :border-x="true"
        :border-y="true"
        row-key-field-name="orders__id"
        ref="mainTable"
        :checkbox-option="{
          hideSelectAll: true,
          selectedRowChange: selectedRowChange
        }"
        v-if="!localLoading"
        :clickHighlight="false"
        :cell-style-option="cellStyleOption"
        :rowStyleOption="{
          hoverHighlight: true
        }"
        :cellSelectionOption="{ enable: false }"
      />
    </div>
    <BaseModal
      name="acceptDeleteWithPin"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="pincodeModalHandler"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteWithPin')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">Внимание!</p>

          <div class="d-flex flex-column align-items-center">
            <!-- <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('acceptDeleteWithPin')"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div> -->
            <p class="text-center">{{ messageError }}</p>
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              :input-name="'pincode'"
              @input="(data) => (pincode = data.value)"
              value=""
              name-sign=""
            >
            </BaseInputNew>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('acceptDeleteWithPin')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="acceptDelete"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_save'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import ObjectsService from '@/api/ObjectsService'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SelectsService from '@/api/SelectsService'
import GeneralService from '@/api/GeneralService'

export default {
  components: {
    VeTable
  },

  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      dataTimeSelect: { orders_from: [], orders_to: [] },
      selectsData: {
        types: [],
        statuses: []
      },
      selectsFilter: {
        type: [],
        statuses: []
      },
      params: {
        page: 1
      },
      selects: [],
      statistics: {},
      countOrders: '',
      pincode: '',
      messageError: [],
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      computedAllPage: 1,
      currentPage: 1,
      activePagePagination: {},
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          return row.order_statuses__style_class
        }
      },
      limitPaginationValue: 10
    }
  },
  methods: {
    updateLimitValue(data) {
      this.limitPaginationValue = data
      this.loadData()
      // this.getTransportVehiclesAll({
      //   page: 1,
      //   limit: this.limitPaginationValue,
      // })
    },
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      EventBus.$emit('contentLoaded')
      EventBus.$emit('updateContentData')
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    async loadExel() {
      let dataFrom = await GeneralService.toExcel(this.currentOpenedId, {
        where: JSON.stringify(this.filter)
      })
      console.log(dataFrom)
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    acceptDelete() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.delete('/orders/' + this.activeSelectRowsMultiple, {
          ...(this.pincode && { pincode: this.pincode })
        })
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            console.log(data)
            this.loadData(1)
            this.$toast.success(data.data.message)
            this.$modal.hide('acceptDeleteWithPin')
          })
          .catch((e) => {
            console.log(e.response.status)
            if (e.response.status == 409) {
              this.$modal.show('acceptDeleteWithPin')
              this.messageError = e.response.data.message
            } else if (e.response.status == 400) {
              this.messageError = e.response.data.message
            }
          })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    fromArchive() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.get(
          '/orders/from/archive/' + this.activeSelectRowsMultiple,
          {}
        )
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            console.log(data)
            this.loadData(1)
            this.$toast.success(data.data.message)
          })
          .catch((e) => {
            console.log(e)
            // console.log(e.response.status)
            // if (e.response.status == 409) {
            //   this.$modal.show('acceptDeleteWithPin')
            // }
          })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    // urlToQuaries: {
    //   deep: true,
    //   get() {
    //     return {
    //       url: `/orders/table/archive`,
    //       params: {
    //         limit: this.limitPaginationValue,
    //         page: this.params.page,
    //         ...(this.filter.length && { where: JSON.stringify(this.filter) })
    //       }
    //     }
    //   },
    //   set(newNumPage) {
    //     this.params.page = newNumPage
    //   }
    // },
    // urlToQuaries(newNumPage) {
    //   return {
    //     url: `/orders/table/archive`,
    //     params: {
    //       limit: this.limitPaginationValue,
    //       page: newNumPage ? newNumPage : 1,
    //       ...(this.filter.length && { where: JSON.stringify(this.filter) })
    //     }
    //   }
    // },
    loadData(newNumPage) {
      this.localLoading = true
      this.urlToQuaries.params.page = newNumPage
      Promise.all([
        GeneralService.get(this.urlToQuaries.url, {
          params: this.urlToQuaries.params
        })
          .then((data) => {
            this.countOrders = data.data.data.count
            this.activePagePagination = data.data.data
            this.computedAllPage = data.data.data.total / data.data.data.limit
            this.dataTable = data.data.data.rows
            this.statistics = data.data.data.statistics_by_order
            this.activeSelectRowsMultiple = []
            this.localLoading = true
          })
          .catch((e) => console.log(e))
      ]).then(() => {
        this.localLoading = false
      })
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData(1)
      console.log(this.selectsData, this.$refs.serviceTable)
      this.selectsFilter = {
        type: [],
        statuses: []
      }
      this.dataTimeSelect = {
        orders_from: [],
        orders_to: []
      }
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
      this.messageError = ''
    },
    fillDateFilter(data) {
      // [{"field":"orders__created_at", "value":["2024-01-04", "2024-01-15"], "operator":"between", "mod":"date"}]
      let transformData = data.value
        ? data.value.split('.').reverse().join('-')
        : ''
      if (!this.filter.find((x) => x.field == data.name)) {
        let indexedArray = []
        indexedArray[data.index] = transformData
        console.log('create')
        this.filter.push({
          field: data.name,
          value: indexedArray,
          operator: 'between',
          mod: 'date'
        })
      } else {
        console.log(
          this.filter[this.filter.findIndex((x) => x.field == data.name)].value
        )
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value[
          data.index
        ] = transformData
        console.log('transform')
      }
      console.log(this.filter)
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },
  created() {
    Promise.all([
      SelectsService.getAll()
        .then((data) => {
          this.selectsData.types = this.prepareLabelsForDefaultSelects(
            data.data.data.rows,
            'allSelects',
            true
          )
          console.log(this.selectsData)
        })
        .catch((e) => console.log(e)),
      GeneralService.get('orders/statuses')
        .then((data) => {
          console.log(data)
          this.selectsData.statuses = data.data.data.rows
          console.log(this.selectsData)
          // statuses
        })
        .catch((e) => console.log(e))
    ]).then(() => {
      this.loadData(1)
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    urlToQuaries() {
      return {
        url: `/orders/table/archive`,
        params: {
          limit: this.limitPaginationValue,
          page: this.currentPage,
          ...(this.filter.length && { where: JSON.stringify(this.filter) })
        }
      }
    },
    columns() {
      return [
        {
          title: this.dataLang['orders-page__table__header_order_text1'],
          children: [
            {
              field: '',
              key: 'a',

              type: 'checkbox',
              title: '',
              width: 5,
              align: 'center'
            },
            {
              field: 'orders__id',
              key: '12',
              title: this.dataLang['orders-page__table__header_order_text2'],
              align: 'left',
              width: '3%',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'orders__id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },

            {
              field: 'orders__created_at',
              key: '13',
              title: this.dataLang['orders-page__table__header_order_text3'],
              align: 'left',
              width: '8%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },

              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.orders__created_at
                      ? this.$time(row.orders__created_at)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'ads__name',
              key: '14',
              title: this.dataLang['orders-page__table__header_order_text4'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'ads__name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__type_rent',
              key: '15',
              title: this.dataLang['orders-page__table__header_order_text5'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.type}
                      onInput={(e, w) => {
                        console.log(e)
                        if (
                          !this.filter.find(
                            (x) => x.field == 'orders__type_rent'
                          )
                        ) {
                          this.filter.push({
                            field: 'orders__type_rent',
                            value: e.value
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'orders__type_rent'
                            )
                          ].value = e.value
                        }
                      }}
                      style={'width: 100%'}
                      label={'label'}
                      placeholder={''}
                      id={'cities__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData.types.orders__type_rent}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.orders__type_rent]}</span>
              }
            },
            {
              field: 'orders__vehicle_type',
              key: '16',
              title: this.dataLang['orders-page__table__header_order_text6'],
              align: 'left',
              width: '8%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.orders__vehicle_type]}</span>
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'orders__vehicle_type'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__body_generation',
              key: '222',
              title: this.dataLang['orders-page__table__header_order_text7'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'orders__body_generation'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.orders__body_generation]}</span>
              }
            },
            {
              field: 'orders__start_city_name',
              key: '23',
              title: this.dataLang['orders-page__table__header__order_text8'],
              align: 'left',
              width: 90,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'orders__start_city_name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__end_city_name',
              key: '24',
              title: this.dataLang['orders-page__table__header_order_text9'],
              align: 'left',
              width: 90,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'orders__end_city_name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__starts',
              key: '25',
              title: this.dataLang['orders-page__table__header_order_text10'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.orders__starts
                      ? this.$time(row.orders__starts)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'orders__ends',
              key: '33',
              title: this.dataLang['orders-page__table__header_order_text11'],
              align: 'left',
              width: 90,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.orders__ends
                      ? this.$time(row.orders__ends)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'vehicles__fullname',
              key: '34',
              title: this.dataLang['orders-page__table__header_order_text12'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'vehicles__fullname'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__duration',
              key: '35',
              title: this.dataLang['orders-page__table__header_order_text13'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'orders__cost',
              key: '36',
              title: this.dataLang['orders-page__table__header_order_text14'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['orders-page__table__header_client_text1'],
          children: [
            {
              field: 'clients__phone',
              key: '37',
              title: this.dataLang['orders-page__table__header_client_text2'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'clients__phone'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'clients__fullname',
              key: '121',
              title: this.dataLang['orders-page__table__header_client_text3'],
              align: 'center',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'clients__fullname'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            }
          ]
        },

        {
          title: this.dataLang['orders-page__table__header_timer_text1'],
          children: [
            {
              field: 'order_ext__employee_id',
              key: '123',
              title: this.dataLang['orders-page__table__header_timer_text2'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'order_statuses__status',
              key: '122',
              title: this.dataLang['orders-page__table__header_timer_text3'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.order_statuses__status]}</span>
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <span class={'text-center'}>{column.title}</span>
                    <BaseSelect
                      vModel={this.selectsFilter.statuses}
                      onInput={(e, w) => {
                        console.log(e)
                        if (
                          !this.filter.find(
                            (x) => x.field == 'orders__status_id'
                          )
                        ) {
                          this.filter.push({
                            field: 'orders__status_id',
                            value: e.order_statuses__id
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'orders__status_id'
                            )
                          ].value = e.order_statuses__id
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      customLabel={({ order_statuses__status }) => {
                        return this.dataLang[order_statuses__status]
                      }}
                      id={'order_statuses__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData?.statuses}
                    ></BaseSelect>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['orders-page__table__header_e_text1'],
          children: [
            {
              field: 'order_ext__employee_id',
              key: '124',
              title: this.dataLang['orders-page__table__header_order_text2'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'input' }}
                      inputName={'order_ext__employee_id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            }
          ]
        }
      ]
    }
  }
}
</script>
