<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div
      class="py-10 px-15 d-flex gap-15 align-items-end border-top"
      style="background-color: #1d1e2c"
      ref="topFilter"
    >
      <div class="d-flex gap-5 flex-column">
        <span class="text-white fsc-11">{{
          dataLang['reestr_toll_roads_text7']
        }}</span>
        <div class="d-flex gap-5 align-items-center">
          <div class="d-flex gap-5">
            <BaseDatePicker
              v-model="startDateFilter"
              type="date"
              class="fsc-11 idiots-class-for-input-date top-filter-dark"
              lang="ru"
              id="vehicle_tires__bought_at"
              style="width: 100px"
              value-type="format"
              :format="userSetting.company__tempalte_date"
              :time-title-format="userSetting.company__tempalte_date"
              @change="(date) => (startDateFilter = date)"
            ></BaseDatePicker>
          </div>
          <span class="text-white fsc-11">{{
            dataLang['reestr_toll_roads_text8']
          }}</span>
          <div class="d-flex gap-5">
            <BaseDatePicker
              v-model="endDateFilter"
              type="date"
              class="fsc-11 idiots-class-for-input-date top-filter-dark"
              lang="ru"
              id="vehicle_tires__bought_at"
              :time-title-format="userSetting.company__tempalte_date"
              style="width: 100px"
              value-type="format"
              :format="userSetting.company__tempalte_date"
              @change="(date) => (endDateFilter = date)"
            ></BaseDatePicker>
            <div class="btn btn-sm btn-primary fsc-11" @click="loadData">
              {{ dataLang['reestr_fines_text9'] }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 flex-column">
        <span class="text-white fsc-11">{{
          dataLang['reestr_fines_text7']
        }}</span>
        <div class="d-flex gap-5">
          <BaseInputNew
            placeholder=""
            style="width: 100%"
            @input="topFillFilter"
            inputClass="bg-transparent text-white"
            inputName="vehicles__gosnumber"
            :additionalParams="{ type: 'like' }"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 flex-column">
        <span class="text-white fsc-11">{{
          dataLang['reestr_fines_text8']
        }}</span>
        <div class="d-flex gap-5">
          <BaseInputNew
            placeholder=""
            style="width: 100%"
            @input="topFillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="clients__name"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div
          class="base-button base-button--action d-flex align-items-center"
          @click="attachIntruderOpenModal"
        >
          {{ dataLang['reestr_fines_text10'] }}
        </div>
        <div class="d-flex gap-10">
          <div
            class="btn btn-sm btn-primary fsc-11 d-flex align-items-center justify-content-center"
            @click="checkAcceptCheckbox('oneClickModal')"
          >
            {{ dataLang['reestr_fines_text11'] }}
          </div>
          <div
            class="base-button base-button--action"
            @click="checkAcceptCheckbox('markAsPaidModal')"
          >
            {{ dataLang['reestr_fines_text13'] }}
          </div>
          <div class="base-button base-button--action" @click="toExcel">
            <img
              :src="require(`@/assets/icons/to-exel.svg`)"
              width="16"
              height="16"
            />
            <span class="fsc-11">в Excel</span>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationFinesStateParams"
          :showLimitInput="false"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="overflow-auto" style="min-height: 400px">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="vehicle_toll_roads__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          v-if="!localLoading"
          :checkbox-option="{
            selectedRowChange: selectedRowChange,
            selectedAllChange: selectedAllChange,
            hideSelectAll: false
          }"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>
    <BaseModal
      name="acceptDeleteWithPin"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="pincodeModalHandler"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white rounded-25"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteWithPin')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">Pincode</p>

          <div class="d-flex flex-column align-items-center">
            <!-- <div
                  class="base-button px-20 rounded-10"
                  @click="$modal.hide('acceptDeleteWithPin')"
                >
                  {{ dataLang['vehile_modal_btn_reset'] }}
                </div> -->
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              :input-name="'pincode'"
              @input="(data) => (pincode = data.value)"
              value=""
              name-sign=""
            >
            </BaseInputNew>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('acceptDeleteWithPin')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="markAsPaid"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_save'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="withoutOptionsModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="320"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('withoutOptionsModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_toll_roads_text54'] }}
          </p>
          <div class="d-flex justify-content-center mt-10">
            <div
              class="btn btn-sm btn-primary px-20"
              @click="$modal.hide('withoutOptionsModal')"
            >
              {{ dataLang['reestr_fines_text72'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="attachIntruderModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="320"
      @beforeClose="attachIntruderModalBeforeClose"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('attachIntruderModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_text10'] }}
          </p>
          <div class="d-flex justify-content-center align-items-center mb-10">
            <BaseSelect
              style="width: 340px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              v-model="selectsFilter.clients"
              :options="[
                { clients__id: null, clients__name: 'Не определен' },
                ...selectsData.clients
              ]"
              label="clients__name"
              :maxHeight="100"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div class="d-flex justify-content-center mt-10 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20"
              @click="$modal.hide('attachIntruderModal')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm btn-primary px-20 fsc-14"
              @click="attachIntruder"
            >
              {{ dataLang['reestr_fines_text73'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="markAsPaidModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="320"
      @beforeClose="attachIntruderModalBeforeClose"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('markAsPaidModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_text51'] }}
          </p>
          <div
            class="mb-10 text-center"
            v-html="dataLang['reestr_toll_roads_text52']"
          ></div>
          <div class="d-flex justify-content-center mt-10 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('markAsPaidModal')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm btn-primary px-20 fsc-14 text-uppercase"
              @click="markAsPaid"
            >
              {{ dataLang['reestr_fines_text53'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="cancelFinesModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      @beforeClose="attachIntruderModalBeforeClose"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('cancelFinesModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_text44'] }}
          </p>
          <div class="mb-10" v-html="dataLang['reestr_fines_text45']"></div>
          <div class="d-flex justify-content-center mt-10 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('cancelFinesModal')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 fsc-14 text-uppercase text-white"
              style="background-color: #ff3a20"
              @click="cancelFines"
            >
              {{ dataLang['reestr_fines_text12'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="oneClickModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      @beforeClose="oneClickModalBeforeClose"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('oneClickModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_text32'] }}
          </p>
          <div class="fsc-14">
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ dataLang['reestr_toll_roads_text36'] }}</span>
              <span class="fw-bold">{{ settingOneClick.countSelected }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ dataLang['reestr_fines_text40'] }}</span>
              <span class="fw-bold">{{ settingOneClick.tollRoadsAmount }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ dataLang['reestr_fines_text41'] }}</span>
              <div>
                <span class="fw-bold">{{
                  settingOneClick.companyComission
                }}</span
                >(<span class="fw-bold"
                  >{{ settingOneClick.companyPercent }}%</span
                >)
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ dataLang['reestr_fines_text42'] }}</span>
              <span class="fw-bold">{{ settingOneClick.companyTotal }}</span>
            </div>
          </div>
          <div
            class="d-flex justify-content-center align-items-center p-15 text-white fsc-12 mt-15"
            style="background-color: #0c63aa"
            v-html="transformTextTranslateComputed"
          ></div>
          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('oneClickModal')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="payOneClickSend"
            >
              {{ dataLang['reestr_fines_text34'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="dontForgetFines"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('dontForgetFines')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_text51'] }}
          </p>
          <div class="mb-10 text-center fw-semi-bold" v-html="errorSum"></div>
          <div
            class="mb-10 text-center"
            v-html="dataLang['reestr_toll_roads_text46']"
          ></div>
          <div class="d-flex justify-content-center mt-10 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('dontForgetFines')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <a
              href="https://dep.rent/"
              target="_blank"
              class="btn btn-sm btn-primary px-20 fsc-14 text-uppercase"
              @click="$modal.hide('dontForgetFines')"
            >
              {{ dataLang['reestr_fines_text36'] }}
            </a>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="payDoogModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('payDoogModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['reestr_fines_text51'] }}
          </p>
          <div
            class="mb-10"
            v-html="dataLang['reestr_toll_roads_text448']"
          ></div>
          <div class="d-flex justify-content-center mt-10 gap-10">
            <div
              class="btn btn-sm btn-primary px-20 fsc-14 text-uppercase"
              @click="$modal.hide('payDoogModal')"
            >
              {{ dataLang['reestr_fines_text37'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="doneCancel"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('payDoogModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['reestr_fines_text74'] }}
          </p>
          <div
            class="mb-10 text-center"
            v-html="dataLang['reestr_fines_text75']"
          ></div>
          <div class="d-flex justify-content-center mt-10 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('doneCancel')"
            >
              {{ dataLang['reestr_fines_text52'] }}
            </div>
            <div
              class="btn btn-sm btn-primary px-20 fsc-14 text-uppercase"
              @click="$modal.hide('doneCancel')"
            >
              {{ dataLang['reestr_fines_text53'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <gallery
      :images="gelleryList"
      :index="galleryExeples"
      @close="onCloseGallery"
    ></gallery>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'

import VueGallery from 'vue-gallery'
export default {
  components: {
    VeTable,
    gallery: VueGallery
  },

  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [
        { field: 'vehicle_toll_roads__status_id', value: '2', operator: '!=' }
      ],
      cancelFinesData: {},
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      nameValue: '',
      activePagePagination: {},
      currentId: null,
      localLoadingModal: false,
      pincode: '',
      copiedDataForFilter: [],
      topFilterParams: [],
      galleryExeples: null,
      startDateFilter: '',
      endDateFilter: '',
      selectsDataForModal: {
        places: []
      },
      selectsFromModal: {
        places: []
      },
      selectsData: {
        discount: [],
        statuses: [],
        clients: []
      },
      errorSum: '',
      settingOneClick: {},
      transformTextTranslateComputed: '',
      selectsFilter: { discount: [], statuses: [], clients: [] },
      gelleryList: [],
      eventCustomOption: {},
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (column.field == 'vehicle_toll_roads__date_to_pay') {
            return row.settings?.vehicle_toll_roads__date_to_pay
          }
          if (column.field == 'vehicle_fines_statuses__name') {
            return row.settings?.vehicle_fines_statuses__name
          }
          if (column.field == 'clients__balance') {
            return row.settings?.clients__balance
          }

          //   "vehicle_toll_roads__date_to_pay": "cell-status-danger",
          // "vehicle_fines_statuses__name": "table-text-status-nopayed"
        }
      }
    }
  },
  methods: {
    checkAcceptCheckbox(name) {
      if (this.activeSelectRowsMultiple.length) {
        switch (name) {
          case 'cancelFinesModal':
            this.$modal.show('cancelFinesModal')
            break
          case 'oneClickModal':
            this.payInOneClick()
            break
          default:
            this.$modal.show(name)
            break
        }
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    onCloseGallery() {
      this.gelleryList = []
      this.galleryExeples = null
    },

    onSaveObjectFromArchive() {},
    cancelFines() {
      GeneralService.editOrCreate(`/fines/cancel`, {
        finesIds: this.activeSelectRowsMultiple
      })
        .then((data) => {
          console.log(data)
          this.$modal.hide('doneCancel')
          this.loadData()
        })
        .catch((e) => {
          console.log(e)
          this.$modal.hide('cancelFinesModal')
          if (e.response.data.errorCode == 7) {
            this.$toast.error(this.dataLang['reestr_fines_text76'])
          }
          if (e.response.data.errorCode == 5) {
            this.$toast.error(this.dataLang['reestr_fines_text77'])
          }
          if (
            e.response.data.errorCode == 4 ||
            e.response.data.errorCode == 3
          ) {
            let datas = e.response.data.data
            datas.sum = datas.pl_1
            this.errorSum = this.computedTranslate(
              datas,
              this.dataLang['reestr_fines_text3']
            )
            this.$modal.show('dontForgetFines')
          }
          if (e.response.data.errorCode == 6) {
            this.$toast.error(this.dataLang['reestr_fines_text78'])
          }
        })
        .finally(() => {
          this.$modal.hide('cancelFinesModal')
          this.localLoading = false
        })
    },
    markAsPaid() {
      this.localLoading = true
      GeneralService.edit(
        `/toll-roads/status/to-payed`,
        {
          ids: this.activeSelectRowsMultiple
        },
        { params: { ...(this.pincode && { pincode: this.pincode }) } }
      )
        .then((data) => {
          console.log(data)
          this.$toast.success(data.data.message)
          this.$modal.hide('acceptDeleteWithPin')
          this.loadData()
        })
        .catch((e) => {
          console.log(e)
          if (e.response.status == 409) {
            this.$modal.show('acceptDeleteWithPin')
            this.messageError = e.response.data.message
            this.$toast.error(e.response.data.message)
          } else if (e.response.status == 400) {
            this.messageError = e.response.data.message
            this.$toast.error(e.response.data.message)
          }
          if (e.response.data.errorCode == 7) {
            this.$toast.error(this.dataLang['reestr_fines_text76'])
          }
          if (e.response.data.errorCode == 5) {
            this.$toast.error(this.dataLang['reestr_fines_text77'])
          }
          if (e.response.data.errorCode == 6) {
            this.$toast.error(this.dataLang['reestr_fines_text78'])
          }
        })
        .finally(() => {
          this.$modal.hide('markAsPaidModal')
          this.localLoading = false
        })
    },
    oneClickModalBeforeClose() {},

    computedTranslate(values, tranaslate) {
      let keys = Object.keys(values)
      let tranaslateLocal = tranaslate
      keys.forEach((keyName) => {
        console.log(`\\${keyName}\\`)
        tranaslateLocal = tranaslateLocal.replace(
          `\\${keyName}\\`,
          values[keyName]
        )
      })
      console.log(tranaslateLocal)
      return tranaslateLocal
    },
    payOneClickSend() {
      GeneralService.editOrCreate(
        `/toll-roads/pay/${this.activeSelectRowsMultiple}`,
        {
          type: 'balance'
        }
      )
        .then((data) => {
          console.log(data)
          this.$toast.success(data.data.message)
          this.$modal.show('payDoogModal')
          this.loadData()
        })
        .catch((e) => {
          console.log(e)
          if (e.response.data.errorCode == 7) {
            this.$toast.error(this.dataLang['reestr_fines_text76'])
          }
          if (e.response.data.errorCode == 3) {
            console.log(e.response.data.data.sum)

            this.errorSum = this.computedTranslate(
              e.response.data.data,
              this.dataLang['reestr_toll_roads_text45']
            )
            this.$modal.show('dontForgetFines')
          }
          if (e.response.data.errorCode == 5) {
            this.$toast.error(this.dataLang['reestr_fines_text77'])
          }
          if (e.response.data.errorCode == 6) {
            this.$toast.error(this.dataLang['reestr_fines_text78'])
          }
          if (e.response.data.errorCode == 1) {
            this.$toast.error(e.response.data.message)
          }
        })
        .finally(() => {
          this.$modal.hide('oneClickModal')
          this.settingOneClick = {}
          this.transformTextTranslateComputed = {}
        })
    },
    payInOneClick() {
      GeneralService.get(
        `/toll-roads/pay/modal/${this.activeSelectRowsMultiple}`
      )
        .then((data) => {
          console.log(data)
          this.settingOneClick = data.data.data
          this.$modal.show('oneClickModal')
          this.transformTextTranslateComputed = this.computedTranslate(
            this.settingOneClick.message,
            this.dataLang['reestr_toll_roads_text40']
          )
        })
        .catch((e) => {
          console.log(e)
          if (e.response.data.errorCode == 7) {
            this.$toast.error(this.dataLang['reestr_fines_text76'])
          }
          if (e.response.data.errorCode == 5) {
            this.$toast.error(this.dataLang['reestr_fines_text77'])
          }
          if (e.response.data.errorCode == 6) {
            this.$toast.error(this.dataLang['reestr_fines_text78'])
          }
          if (e.response.data.errorCode == 1) {
            this.$toast.error(e.response.data.message)
          }
        })
    },

    attachIntruderModalBeforeClose() {},
    attachIntruder() {
      this.localLoading = true
      if (this.selectsFilter?.clients?.clients__name) {
        GeneralService.edit(`/toll-roads/attach/client`, {
          ids: this.activeSelectRowsMultiple,
          clientId: this.selectsFilter.clients.clients__id
        })
          .then((data) => {
            console.log(data)
            this.$toast.success(data.data.message)
            this.loadData()
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error(e.response.data.message)
            if (e.response.data.errorCode == 7) {
              this.$toast.error(this.dataLang['reestr_fines_text76'])
            }
            if (e.response.data.errorCode == 5) {
              this.$toast.error(this.dataLang['reestr_fines_text77'])
            }
            if (e.response.data.errorCode == 6) {
              this.$toast.error(this.dataLang['reestr_fines_text78'])
            }
            if (e.response.data?.message) {
              this.$toast.error(e.response.data.message)
            }
          })
          .finally(() => {
            this.selectsFilter.clients = []
            this.localLoading = false
            this.$modal.hide('attachIntruderModal')
          })
      } else {
        this.selectsFilter.clients = []
        this.localLoading = false
        this.$modal.hide('attachIntruderModal')
      }
    },
    attachIntruderOpenModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('attachIntruderModal')
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    resetFilter() {
      this.startDateFilter = ''
      this.endDateFilter = ''
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.$refs.topFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.filter = [
        { field: 'vehicle_toll_roads__status_id', value: '2', operator: '!=' }
      ]
      this.selectsFilter.discount = [
        {
          label: this.dataLang.reestr_fines_text777,
        }
      ]
      this.topFilterParams = []
      this.selectsFilter.statuses = []

      this.loadData()
    },

    // On click
    // Navigate to: "Авторизация / Данные введены";
    // Animate: Smart animate;
    // Spring: { mass: 1, stiffness: 400, damping: 30 };

    // changeDate(date, nameField, additionalParams) {
    //   this.updatedValues[nameField] = date
    //   this.findAndReplaceDataForSend(
    //     additionalParams,
    //     nameField,
    //     dayjs(date, `${this.userSetting.company__tempalte_date}`)
    //       .utc(true)
    //       .utcOffset(Number(this.userSetting.company__utc))
    //       .format(`YYYY-MM-DD HH:mm:ss`)
    //   )
    //   this.updatedValues[nameField] = date
    // },
    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
    },
    loadData() {
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/selects/field/filter_fines_table_select_discount`)
          .then((data) => {
            // this.selects.push(data.data.data.rows)

            this.selectsData.discount = this.prepareLabelsForDefaultSelects(
              data.data.data.rows,
              'allSelects',
              true
            ).filter_fines_table_select_discount
            console.log(this.selectsData.discount)
          })
          .catch((e) => {
            console.log(e)
          }),
        GeneralService.get(
          `/clients?joins=company,statistics,verifications,comment`
        )
          .then((data) => {
            this.selectsData.clients = data.data.data.rows
            console.log(data)
          })
          .catch((e) => {
            console.log(e)
          })

        // /fines/table/main?fields=vehicle_fines_statuses__id,vehicle_fines_statuses__name&uniqueBy=vehicle_fines_statuses__id
      ]).then(() => {
        if (this.startDateFilter.length == 10) {
          this.topFilterParams.push({
            field: 'vehicle_toll_roads__date_travel',
            value: this.startDateFilter.split('.').reverse().join('-'),
            operator: '>='
          })
        }
        if (this.endDateFilter.length == 10) {
          this.topFilterParams.push({
            field: 'vehicle_toll_roads__date_travel',
            value: this.endDateFilter.split('.').reverse().join('-'),
            operator: '<='
          })
        }
        GeneralService.get(`/toll-roads/table/main`, {
          params: {
            ...this.paginationFinesStateParams,
            ...(this.filter.length && {
              where: JSON.stringify(this.filter)
            }),
            ...(this.topFilterParams.length && {
              where: JSON.stringify(this.topFilterParams)
            })
          }
        })
          .then((data) => {
            this.getFinesAll(data.data.data)
            console.log(data)
            this.dataTable = data.data.data.rows
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка при загрузке таблицы')
          })
      })
    },

    // fillFilterDate(data) {
    //   if (data.value.length == 10) {
    //     if (data.operator == '>=') {
    //     }
    //   }
    // },

    topFillFilter(data) {
      this.filter = []
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.topFilterParams.find((x) => x.field == data.name)) {
        this.topFilterParams.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.operator && { operator: data.operator }),
          ...(data.mod && { mod: data.mod })
        })
      } else {
        this.topFilterParams[
          this.topFilterParams.findIndex((x) => x.field == data.name)
        ].value = newValueFilter
        console.log(this.topFilterParams)
      }
      console.log(this.topFilterParams, data)
    },

    fillFilter(data) {
      this.topFilterParams = []
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(!!data?.operator && { operator: data.operator }),
          ...(data.mod && { mod: data.mod })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        if (data.operator) {
          this.filter[
            this.filter.findIndex((x) => x.field == data.name)
          ].operator = data.operator
        }
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },
    fillGallery(datas) {
      console.log(datas)
      this.localLoading = true
      GeneralService.get(`/toll-roads/gallery/${datas.vehicle_toll_roads__id}`)
        .then((data) => {
          console.log(data.data.data.gallery)
          if (data.data.data.gallery.length) {
            this.gelleryList.push(...data.data.data.gallery)
            this.galleryExeples = 0
          } else {
            this.$toast.error('Нет фотографий')
          }
        })
        .catch((e) => {
          console.log(e)
          this.$toast.error(e.response.data.message)
        })
        .finally(() => {
          this.localLoading = false
        })
    },

    openCard(datas) {
      // row.clients__blacklist_gallery.map((item) => {
      //             if (item.client_blacklist_gallery__file) {
      //               this.gelleryList.push(item.client_blacklist_gallery__file)
      //             }
      //           })

      //           this.galleryExeples = 0
      //           console.log(this.galleryExeples)
      console.log(datas)

      window
        .open(
          `https://api.yandex-rent.ru/toll-roads/card/${
            datas.vehicle_toll_roads__id
          }?employee-token=${encodeURI(
            JSON.parse(localStorage.getItem('user-token'))
          )}`,
          '_blank'
        )
        .focus()
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    toExcel() {
      console.log(this.pincode)
      GeneralService.toSpecExcel('/toll-roads/table/main/xlsx', {
        ...(this.pincode && { pincode: this.pincode })
      })
        .then((data) => {
          // this.selects.push(data.data.data.rows)
          console.log(data)
          this.pincode = ''
          this.$modal.hide('acceptDeleteWithPin')
          const filename = new Date().toISOString()
          const url = window.URL.createObjectURL(data.data)
          const a = document.createElement('a')
          a.href = url
          a.download = `${filename}.xlsx`
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        .catch((e) => {
          console.log(e)
          if (e.response.status == 409) {
            this.currentMethodType = 'excel'
            this.$modal.show('acceptDeleteWithPin', { type: 'excel' })
            this.messageError = e.response.data.message
          } else if (e.response.status == 400) {
            this.messageError = e.response.data.message
            this.$modal.hide('acceptDeleteWithPin')
            this.$toast.error(e.response.data.message)
          }
        })
    },
    ...mapActions('finesPagination', ['getFinesAll'])
  },
  mounted() {
    this.selectsFilter.discount = {
      label: this.dataLang.reestr_fines_text777,
    }
  },
  created() {
    Promise.all([
      //   }),
    ]).then(() => {
      this.loadData()
      //   GeneralService.editOrCreate(`/integrators`, {
      //     integrators__company_id: 1,
      //     integrators__percent: 2
      //   })
      //     .then((data) => {
      //       console.log(data)
      //     })
      //     .catch((e) => {
      //       console.log(e)
      //       this.$toast.error('Неизвестная ошибка')
      //     })
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('finesPagination', ['paginationFinesStateParams']),
    ...mapGetters('finesPagination', ['numPages']),
    columns() {
      return [
        {
          field: '',
          key: 'a6',
          type: 'checkbox',
          title: '',
          width: '2%',
          align: 'center'
        },
        {
          title: this.dataLang['reestr_fines_text15'],
          children: [
            {
              field: 'vehicles__name',
              key: '172',
              title: this.dataLang['reestr_fines_text18'],
              align: 'center',
              width: '12%',
              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },

              // renderBodyCell: ({ row, column, rowIndex }, h) => {
              //   return this.dataLang[row.vehicle_types__code]
              // },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'vehicles__gosnumber',
              key: '163',
              title: this.dataLang['reestr_fines_text19'],
              align: 'center',
              width: '7%',
              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputRef={'clients__phone'}
                      inputMask={'a000aa000'}
                      maxLength='9'
                      inputName={'vehicles__gosnumber'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['reestr_toll_roads_text20'],
          children: [
            {
              field: 'vehicle_toll_roads__travel_id',
              key: '1533',
              title: this.dataLang['reestr_toll_roads_text27'],
              align: 'center',
              width: '10%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'vehicle_toll_roads__travel_id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'vehicle_toll_roads__date_travel',
              key: '134',
              title: this.dataLang['reestr_toll_roads_text28'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_toll_roads__date_travel
                      ? this.$time(row.vehicle_toll_roads__date_travel)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(
                            `${this.userSetting.company__tempalte_date} ${this.userSetting.company__tempalte_time}`
                          )
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'vehicle_toll_roads__amount',
              key: '1334',
              title: this.dataLang['reestr_toll_roads_text29'],
              align: 'center',
              width: '5%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'vehicle_toll_roads__date_to_pay',
              key: '1823',
              title: this.dataLang['reestr_toll_roads_text30'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_toll_roads__date_to_pay.split('-')[0] == '0000'
                      ? ''
                      : row.vehicle_toll_roads__date_to_pay
                          .split('-')
                          .reverse()
                          .join('.')}
                  </span>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['reestr_toll_roads_text21'],
          children: [
            {
              field: 'vehicle_toll_roads__client_amount',
              key: '11203',
              title: this.dataLang['reestr_fines_text25'],
              align: 'center',
              width: '5%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'clients__name',
              key: '1223',
              title: this.dataLang['reestr_fines_text26'],
              align: 'center',
              width: '10%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      additionalParams={{ type: 'like' }}
                      onInput={this.fillFilter}
                      inputName={'clients__name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.clients__name ?? this.dataLang['reestr_fines_text79']}
                  </span>
                )
              }
            },
            {
              field: 'clients__id',
              key: '1253',
              title: this.dataLang['reestr_fines_text27'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      additionalParams={{ type: 'like' }}
                      onInput={this.fillFilter}
                      inputName={'clients__id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'clients__balance',
              key: '1623',
              title: this.dataLang['reestr_fines_text28'],
              align: 'center',
              width: '5%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            }
          ]
        },
        {
          key: '7123',
          title: this.dataLang['reestr_fines_text29'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <img
                onClick={() => {
                  this.fillGallery(row)
                }}
                style={'cursor: pointer'}
                src={require(`@/assets/icons/icon-gallery.svg`)}
              />
            )
          }
        },
        {
          key: '8123',
          title: this.dataLang['reestr_fines_text30'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <img
                onClick={() => {
                  this.openCard(row)
                }}
                style={'cursor: pointer'}
                src={require(`@/assets/icons/card.svg`)}
              />
            )
          }
        },
        {
          field: 'vehicle_fines_statuses__name',
          key: '9123',
          title: this.dataLang['reestr_fines_text31'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.statuses}
                  onInput={(e, w) => {
                    console.log(e)
                    this.fillFilter({
                      name: 'vehicle_toll_roads__status_id',
                      value: e.value,
                      operator: '='
                    })
                  }}
                  style={'width: 100%'}
                  label={'label'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    {
                      label: this.dataLang['fine_status_nopayed'],
                      value: 1
                    },
                    {
                      label: this.dataLang['fine_status_payed'],
                      value: 2
                    },
                    {
                      label: this.dataLang['fine_status_processing'],
                      value: 3
                    },
                    {
                      label: this.dataLang['fine_status_canceling'],
                      value: 4
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>{this.dataLang[row.vehicle_fines_statuses__name]}</span>
            )
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.indicator {
  display: flex;
  padding: 5px 0;
  border-radius: 5px;
  background-color: #23b14d;
  color: white;
  width: 80px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  &--warn {
    background-color: #ff3a20;
  }
}
</style>
