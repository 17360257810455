import http from "@/http-common.js";

class SecureSettingsService {
  basePathApiService = "/settings-security";
 
  getGroups(params) {
    return http.get(this.basePathApiService, params);
  } 

  getClientsFields(id, params = null) {
    return http.get(this.basePathApiService + `/${id}/client-fields`);
  } 

  getQuestions(id, params = null) {
    return http.get(this.basePathApiService + `/${id}/questions`);
  } 

  createClientField(id, data){
    return http.post(this.basePathApiService + `/${id}/client-fields`, data);
  }

  createQuestion(id, data){
    return http.post(this.basePathApiService + `/${id}/questions`, data);
  }

  deleteQuestion(idGroup, idQuestion){
    return http.delete(this.basePathApiService + `/${idGroup}/questions/${idQuestion}`);
  }

  deleteClientField(idGroup, idField){
    return http.delete(this.basePathApiService + `/${idGroup}/client-fields/${idField}`);
  }
}
export default new SecureSettingsService();
