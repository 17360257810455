
import FullSummaryService from "@/api/FullSummaryService.js";
import Vue from "vue";
export const fullSummaryModule = {
  namespaced: true,
  state: () => ({
    fullSummaryData: {},
    fullSummaryLoadStatus: 'request',
  }),

  getters: {},
  mutations: {
    get(state, data) {
      if(data.length)
        state.fullSummaryData = data[0];
    },

    load_request(state, status) {
      state.finesLoadStatus= 'request'
    },
    load_success(state) {
      state.finesLoadStatus= 'success'
    },
    load_error(state) {
      state.finesLoadStatus= 'error'
    },
  },
  actions: {
    getFullSummaryAll({ commit }, params) {
      commit("load_request");
      console.log( FullSummaryService)
      FullSummaryService.getAll(params)
        .then((data) => {
            console.log(data);
          commit("get", data.data);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },
  },
};
