<template>
<pagination v-model="paginationParams.page" :records="paginationParams.total" @paginate="myCallback"/>
</template>

<script>
import Pagination from 'vue-pagination-2';
export default {
    components: {
        Pagination,
    },
    props: [
        'paginationParams'
    ],
    data() {
        return {
            page: 1
        }
    },
    methods: {
        myCallback() {
            console.log(paginationParams);
            console.log(1213213);
        }
    }
    ,mounted() {
        console.log(this.paginationParams);
    }


}
</script>