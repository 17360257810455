import http from "@/http-common.js";

class StorageFileService {
  basePathApiService = "/storage";
  upload(files) {
    return http.post(this.basePathApiService, files, { headers:
        { 'Content-Type': 'multipart/form-data' }
      });
  }
  uploadWithoutToken(files) {
    return http.post(this.basePathApiService + '/open', files, { headers:
        { 'Content-Type': 'multipart/form-data' }
      });
  }

  getFilesData(files){
    return http.post(this.basePathApiService + '/files-data', {
      'files':files
    });
  }

}
export default new StorageFileService();
