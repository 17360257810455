import http from "@/http-common.js";

class BidsPartsService {

  basePathApiService = "/bids-parts";
 
  getAll(params) {
    return http.get(this.basePathApiService, params);
  } 

}
export default new BidsPartsService();
