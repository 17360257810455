<template>
  <BaseModal
    name="EditsTariffModal"
    width="435"
    :clickToClose="false"
    :shiftY="0.5"
    @before-close="() => {}"
    @before-open="loadData"
    :adaptive="true"
    height="auto"
    classes="bg-transparent shadow-none"
  >
    <div class="bg-white p-30 position-relative rounded-25 overflow-hidden">
      <BaseLoader v-if="localLoading" style="z-index: 1000"></BaseLoader>
      <button
        type="button"
        class="btn-close position-absolute end-30 top-30 text-dark p-0"
        @click="$modal.hide('EditsTariffModal')"
      ></button>
      <p class="fw-bold mb-30 text-center fsc-14">
        {{ dataLang['tariff_register_text_93'] }}
      </p>
      <div class="fsc-11">
        <div class="d-flex align-items-center">
          <div class="fw-semi-bold wp-50 py-5 px-5">
            {{ dataLang['tariff_register_text_12'] }}
          </div>
          <div class="wp-50 py-5 ps-5">{{ dataForModal.vehicles__id }}</div>
        </div>
        <div class="d-flex bg-system-secondary">
          <div class="fw-semi-bold wp-50 py-5 px-5">
            {{ dataLang['tariff_register_text_13'] }}
          </div>
          <div class="wp-50 py-5 ps-5">{{ dataForModal.vehicles__name }}</div>
        </div>
        <div class="d-flex">
          <div class="fw-semi-bold wp-50 py-5 px-5">
            {{ dataLang['tariff_register_text_15'] }}
          </div>
          <div class="wp-50 py-5 ps-5">
            {{ dataForModal.vehicles__gosnumber }}
          </div>
        </div>
        <div class="d-flex mb-20 bg-system-secondary">
          <div class="fw-semi-bold wp-50 py-5 px-5">
            {{ dataLang['tariff_register_text_14'] }}
          </div>
          <div class="wp-50 py-5 ps-5">
            {{ dataForModal.vehicles__year_release }}
          </div>
        </div>
        <div class="d-flex">
          <div class="fw-semi-bold wp-50 py-5 px-5">
            {{ dataLang['tariff_register_text_94'] }}
          </div>
          <div class="wp-50 py-5 ps-5">{{ dataForModal.vehicles__cost }}</div>
        </div>
        <div class="d-flex">
          <div class="fw-semi-bold wp-50 py-5 px-5">
            {{ dataLang['tariff_register_text_95'] }}
          </div>
          <div class="wp-50" ref="tariff_for_car__koeff_price">
            <BaseInputNew
              placeholder=""
              width=""
              class="wp-100 p-0"
              input-name="tariff_for_car__koeff_price"
              @input="updateValue"
              :horizontalTitle="false"
              :value="dataForModal.tariff_for_car__koeff_price"
              :additionalParams="{}"
              name-sign=""
              inputRef="tariff_for_car__koeff_price"
              inputMask="0.000"
              maxLength="5"
            />
          </div>
        </div>
        <div class="d-flex mb-20">
          <div class="wp-50 py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_96']
            }}</span>
          </div>
          <div class="wp-50 py-5 ps-5">{{ Math.floor(Number(calcPrice)) }}</div>
        </div>
        <div class="d-flex mb-5">
          <div class="wp-50 py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_5']
            }}</span>
            {{ dataLang['tariff_register_text_106'] }}
          </div>
          <div class="wp-50" ref="tariff_for_car__pledge">
            <BaseInputNew
              placeholder=""
              width=""
              class="wp-100 p-0"
              input-name="tariff_for_car__pledge"
              @input="updateValue"
              :horizontalTitle="false"
              :value="dataForModal.tariff_for_car__pledge"
              :additionalParams="{}"
              name-sign=""
              inputRef="tariff_for_car__pledge"
              inputMask="00000000"
              maxLength="8"
            />
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="wp-50 py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_112']
            }}</span>
          </div>
          <div class="wp-50" ref="tariff_for_car__group_id">
            <BaseSelect
              placeholder=""
              style="width: 100%"
              selectLabel=""
              deselectLabel=""
              class="p-0"
              @input="
                (data) =>
                  updateValue({
                    name: 'tariff_for_car__group_id',
                    value: data.tariff_groups_for_car__id
                  })
              "
              v-model="selectsFromModal.grkoef"
              :options="selectsData.grkoef"
              label="tariff_groups_for_car__name"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
        </div>
        <div class="d-flex mb-20">
          <div class="wp-50 py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_98']
            }}</span>
            {{ dataLang['tariff_register_text_107'] }}
          </div>
          <div class="wp-50" ref="tariff_for_car__koeff_custom">
            <BaseInputNew
              placeholder=""
              class="wp-100 p-0"
              input-name="tariff_for_car__koeff_custom"
              @input="updateValue"
              :horizontalTitle="false"
              :value="dataForModal.tariff_for_car__koeff_custom"
              :additionalParams="{}"
              name-sign=""
              inputRef="tariff_for_car__koeff_custom"
              inputMask="0.000"
              maxLength="5"
            />
          </div>
        </div>
        <div class="fsc-11 px-5 mb-10">
          <span class="fw-semi-bold">{{
            dataLang['tariff_register_text_99']
          }}</span>

          {{ dataLang['tariff_register_text_111'] }}
        </div>

        <div class="d-flex justify-content-between mb-5">
          <div class="py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_100']
            }}</span>
            {{ dataLang['tariff_register_text_108'] }}
          </div>

          <div class="d-flex align-items-center gap-5 p-0">
            <BaseSelect
              placeholder=""
              style="width: 95px"
              selectLabel=""
              deselectLabel=""
              openDirection="top"
              @input="
                (data) =>
                  updateValue({
                    name: 'tariff_for_car__commission_nodriver_day_custom',
                    value: data.value
                  })
              "
              v-model="
                selectsFromModal.tariff_for_car__commission_nodriver_day_custom
              "
              :options="selectsData.comission"
              label="value"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
            <span
              >{{ dataLang['tariff_register_text_105'] }}:
              <span style="color: #23b14d"
                >{{
                  dataForModal.tariff_for_car__commission_nodriver_day_top
                }}%</span
              >
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-5">
          <div class="py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_101']
            }}</span>
            {{ dataLang['tariff_register_text_109'] }}
          </div>
          <div class="d-flex align-items-center gap-5 p-0">
            <BaseSelect
              placeholder=""
              style="width: 95px"
              selectLabel=""
              deselectLabel=""
              openDirection="top"
              @input="
                (data) =>
                  updateValue({
                    name: 'tariff_for_car__commission_nodriver_subs_custom',
                    value: data.value
                  })
              "
              v-model="
                selectsFromModal.tariff_for_car__commission_nodriver_subs_custom
              "
              :options="selectsData.comission"
              label="value"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
            <span
              >{{ dataLang['tariff_register_text_105'] }}:
              <span style="color: #23b14d"
                >{{
                  dataForModal.tariff_for_car__commission_nodriver_subs_top
                }}%</span
              >
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-5">
          <div class="py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_102']
            }}</span>
            {{ dataLang['tariff_register_text_110'] }}
          </div>

          <div class="d-flex align-items-center gap-5 p-0">
            <BaseSelect
              placeholder=""
              style="width: 95px"
              selectLabel=""
              deselectLabel=""
              openDirection="top"
              @input="
                (data) =>
                  updateValue({
                    name: 'tariff_for_car__commission_nodriver_buyout_custom',
                    value: data.value
                  })
              "
              v-model="
                selectsFromModal.tariff_for_car__commission_nodriver_buyout_custom
              "
              :options="selectsData.comission"
              label="value"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
            <span
              >{{ dataLang['tariff_register_text_105'] }}:
              <span style="color: #23b14d"
                >{{
                  dataForModal.tariff_for_car__commission_nodriver_buyout_top
                }}%</span
              >
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="py-5 px-5">
            <span class="fw-semi-bold">{{
              dataLang['tariff_register_text_103']
            }}</span>
          </div>
          <div class="d-flex align-items-center gap-5 p-0">
            <BaseSelect
              placeholder=""
              style="width: 95px"
              selectLabel=""
              deselectLabel=""
              openDirection="top"
              @input="
                (data) =>
                  updateValue({
                    name: 'tariff_for_car__commission_driver_custom',
                    value: data.value
                  })
              "
              v-model="
                selectsFromModal.tariff_for_car__commission_driver_custom
              "
              :options="selectsData.comission"
              label="value"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
            <span
              >{{ dataLang['tariff_register_text_105'] }}:
              <span style="color: #23b14d"
                >{{ dataForModal.tariff_for_car__commission_driver_top }}%</span
              >
            </span>
          </div>
        </div>
        <div class="d-flex gap-10 mt-15 justify-content-center">
          <div
            class="base-button base-button--action py-5 px-20 text-uppercase"
            @click="$modal.hide('EditsTariffModal')"
          >
            <span class="fsc-11">{{
              dataLang['orders_model_edit__btn_text5']
            }}</span>
          </div>
          <div
            class="btn btn-primary py-5 px-20 text-uppercase fsc-11 d-flex align-items-center"
            @click="sendData"
          >
            {{ dataLang['city_delivery_register_text_35'] }}
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import GeneralService from '@/api/GeneralService.js'
import { VeTable } from 'vue-easytable'
import BidsService from '@/api/BidsService'
import EventBus from '@/event-bus.js'
import OwnersService from '@/api/OwnersService'
import { Tooltip } from 'bootstrap'

export default {
  components: {
    VeTable
  },
  props: {},
  data() {
    return {
      dataForModal: {},
      localLoading: true,
      updatedValues: {},
      selectsData: {
        grkoef: [],
        comission: []
      },
      selectsFromModal: {
        grkoef: [],
        tariff_for_car__commission_nodriver_day_custom: [],
        tariff_for_car__commission_nodriver_subs_custom: [],
        tariff_for_car__commission_nodriver_buyout_custom: [],
        tariff_for_car__commission_driver_custom: []
      },
      currentOpened: null,
      calcPrice: 0
    }
  },
  methods: {
    loadData(params) {
      console.log(params.params.id)
      this.currentOpened = params.params.id
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/tariffs_groups_car`)
          .then((data) => {
            this.selectsData.grkoef = data.data.rows
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      ]).then(() => {
        this.localLoading = false
        GeneralService.get(`/tariffs_car/${params.params.id}`)
          .then((data) => {
            console.log(data)
            this.dataForModal = data.data.rows[0]
            this.selectsFromModal.tariff_for_car__commission_nodriver_day_custom =
              [
                {
                  value:
                    data.data.rows[0]
                      .tariff_for_car__commission_nodriver_day_custom
                }
              ]
            this.selectsFromModal.tariff_for_car__commission_nodriver_subs_custom =
              [
                {
                  value:
                    data.data.rows[0]
                      .tariff_for_car__commission_nodriver_subs_custom
                }
              ]
            this.selectsFromModal.tariff_for_car__commission_nodriver_buyout_custom =
              [
                {
                  value:
                    data.data.rows[0]
                      .tariff_for_car__commission_nodriver_buyout_custom
                }
              ]
            this.selectsFromModal.tariff_for_car__commission_driver_custom = [
              {
                value:
                  data.data.rows[0].tariff_for_car__commission_driver_custom
              }
            ]
            this.selectsFromModal.grkoef = this.selectsData.grkoef.find(
              (x) =>
                x.tariff_groups_for_car__id ==
                data.data.rows[0].tariff_groups_for_car__id
            )
            console.log(this.tariff_for_car__cost_with_koeff)
            this.calcPrice = this.dataForModal.tariff_for_car__cost_with_koeff
            console.log(this.calcPrice)
            // tariff_for_car__cost_with_koeff
            // this.dataForModal.tariff_for_car__koeff_price *
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error(e.response.data.message)
          })
      })

      //   /tariffs_groups_car
    },
    beforeClose(event) {
      this.currentOpened = null
      this.dataForModal = {}
      this.updatedValues = {}
      this.selectsFromModal = {
        grkoef: [],
        tariff_for_car__commission_nodriver_day_custom: [],
        tariff_for_car__commission_nodriver_subs_custom: [],
        tariff_for_car__commission_nodriver_buyout_custom: [],
        tariff_for_car__commission_driver_custom: []
      }
    },
    updateValue(data) {
      this.updatedValues[data.name] = data.value
      if (
        data.name == 'tariff_for_car__koeff_price' ||
        data.name == 'tariff_for_car__koeff_custom'
      ) {
        if (String(this.updatedValues[data.name]).length == 2) {
          let stringNew = data.value.split('').join('.')
          this.updatedValues[data.name] = stringNew
          console.log(this.updatedValues[data.name], 23123123123)
        }
        console.log(this.updatedValues[data.name].length)
        this.calcPrice = this.dataForModal.vehicles__cost * data.value
      }
      this.$refs[data.name].classList.remove('input-error')
    },
    sendData() {
      if (Object.keys(this.updatedValues).length) {
        this.localLoading = true
        GeneralService.edit(`/tariffs_car/${this.currentOpened}`, {
          ...this.updatedValues
        })
          .then((data) => {
            this.$toast.success(data.data.message)
            this.$modal.hide('EditsTariffModal')
            EventBus.$emit('updateContentDataTariff')
          })
          .catch((e) => {
            Object.keys(e.response.data.errors).forEach((elem, index) => {
              if (this.$refs[elem]) {
                this.$refs[elem].classList.add('input-error')
              }
            })
            this.localLoading = false
            this.$toast.error(e.response.data.message)
          })
      } else {
        this.$modal.hide('EditsTariffModal')
      }
    }
  },
  mounted() {
    for (let index = 20; index <= 50; index++) {
      this.selectsData.comission.push({ value: index })
    }
  },
  created() {},
  beforeDestroy() {},

  computed: {
    ...mapState('translationModule', ['dataLang'])

    //     {
    //     "sms_presets__category_id": 2,
    //     "sms_presets__company_id": 2,
    //     "sms_presets__created_at": "2024-01-19 08:36:52",
    //     "sms_presets__id": 6,
    //     "sms_presets__name": "третий",
    //     "sms_presets__text": "Не пользуйтесь нашими услугами",
    //     "sms_presets__updated_at": "2024-01-19 11:36:52"
    // }
  }
}
</script>
