
import OrgpolicyService from "@/api/OrgpolicyService.js";
import StorageFileService from "@/api/StorageFileService";
import Vue from "vue";
export const orgpolicyModule = {
  namespaced: true,
  state: () => ({
    orgpolicyData: [],
    orgpolicyLoadStatus: 'request',
    paginationOrgpolicyStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationOrgpolicyStateParams.total / state.paginationOrgpolicyStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.orgpolicyData = [...data];
    },
    create(state, data) {
      console.log(data[0])
      state.orgpolicyData.unshift(data[0])
    },
    edit_multiple(state, data) {
      let indexed = state.orgpolicyData.findIndex(x => x.orgpolicy__id == data.id)
      Object.assign(state.orgpolicyData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.orgpolicyData.findIndex(x => x.orgpolicy__id == id)
      state.orgpolicyData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationOrgpolicyStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.orgpolicyLoadStatus= 'request'
    },
    load_success(state) {
      state.orgpolicyLoadStatus= 'success'
    },
    load_error(state) {
      state.orgpolicyLoadStatus= 'error'
    },
  },
  actions: {

    getOrgpolicyAll({ commit }, params) {
      commit("load_request");
      OrgpolicyService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    uploadFile({ commit }, dataFile){
      
      if(dataFile.fileRecords.file){
        let upodaData = {
          "file":dataFile.fileRecords.file,
          "type":"file"
        }

        return StorageFileService.upload(upodaData);
      }

      return new Promise((resolve, reject) => {
          resolve({
             data:{data: dataFile.fileRecords.url}
          });
      })
    },

    createOrgpolicyData({ commit, dispatch }, createData) {
      dispatch('uploadFile', createData).then((data) => {
        createData["orgpolicy__file"] = data.data.data;
        OrgpolicyService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
        });
      });
    },

    editOrgpolicyMultiple({ commit, dispatch }, {id, dataEdit}) {
      dispatch('uploadFile', dataEdit).then( data => {
        console.log(11111111111111)
        dataEdit["orgpolicy__file"] = data.data.data;
        OrgpolicyService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
      });
    },


    deleteOrgpolicyData({ commit }, id) {
      // commit("load_request");
      OrgpolicyService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

   downloadOrgpolicyFile({ commit }, id) {
      // commit("load_request");
      OrgpolicyService.downloadFile(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },
  },
};
