import http from "@/http-common.js";
import StorageFileService from "@/api/StorageFileService.js";
import router from "@/router/index.js";
export const storageFileModule = {
  namespaced: true,
  state: () => ({
    loadStatus: ''
  }),

  getters: {},
  mutations: {
    // upload(state, data) {
    //   localStorage.setItem('translations', JSON.stringify(data))
    // },
    load_request(state, status) {
      state.loadStatus = 'request'
    },
    load_success(state) {
      state.loadStatus = 'success'
    },
    load_error(state) {
      state.loadStatus = 'error'
    },
  },
  actions: {
    uploadFiles({ commit }, file) {
      commit("load_request");
      StorageFileService.upload(file)
        .then((data) => {
          
          commit("load_success");
          console.log(data);

        })
        .catch((error) => {
          console.log(error);
          commit("load_error");
        });


    },
  },
};
