
import BidsService from "@/api/BidsService.js";
import Vue from "vue";
export const bidsModule = {
  namespaced: true,
  state: () => ({
    bidsData: [],
    bidsLoadStatus: 'request',
    paginationPromocodesStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationPromocodesStateParams.total / state.paginationPromocodesStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.bidsData = [...data];
    },

    create(state, data) {
      state.bidsData.unshift(data[0])
    },
    
    edit_multiple(state, data) {
      let indexed = state.bidsData.findIndex(x => x.request_service__id == data.id)
      Object.assign(state.bidsData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.bidsData.findIndex(x => x.request_service__id == id)
      state.bidsData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationPromocodesStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.bidsLoadStatus= 'request'
    },
    load_success(state) {
      state.bidsLoadStatus= 'success'
    },
    load_error(state) {
      state.bidsLoadStatus= 'error'
    },
  },
  actions: {
   

    getBidsAll({ commit }, params) {
      commit("load_request");
    
      BidsService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createBidsData({ commit }, createData) {
      
        BidsService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editBidsMultiple({ commit }, {id, dataEdit}) {
        BidsService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteBidsData({ commit }, id) {
      // commit("load_request");
      BidsService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
