<template>
    <div class="block-action">
        <span class="fw-bold">Названия фондов финреестра</span>
        <div class="block-action mt-20">
            <span class="fw-bold">Статьи приходов</span>
            <div class="mb-5 mt-10">
            <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                input-name="company_settings__set_req_company_name" :value="getValue(0)" @onInput="updateValue($event, 0)"
                :name-sign="dataLang?.cash_fund" :placeholder="dataLang?.cash_fund" disabled="true"></BaseInput>
            </div>
            <div class="mb-5 mt-10">
            <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                input-name="company_settings__set_req_company_name" :value="getValue(1)"  @onInput="updateValue($event, 1)"
                :name-sign="dataLang?.rs_fund" :placeholder="dataLang?.rs_fund" disabled="true"></BaseInput>
            </div>
            <div class="mb-5 mt-30">
            <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                input-name="company_settings__set_req_company_name" :value="getValue(12)"  @onInput="updateValue($event, 12)"
                :name-sign="dataLang?.cash_account_3" :placeholder="dataLang?.cash_account_3"></BaseInput>
            </div>
            <div class="mb-5 mt-10">
            <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                input-name="company_settings__set_req_company_name" :value="getValue(13)"  @onInput="updateValue($event, 13)"
                :name-sign="dataLang?.cash_account_4" :placeholder="dataLang?.cash_account_4"></BaseInput>
            </div>
        </div>
        <div class="block-action mt-20">       
            <span class="fw-bold">Статьи расходов</span>
            <div class=" d-flex flex-wrap gap-20 pt-20"> 
               
                <div class="block-action">                    
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(7)"  @onInput="updateValue($event, 7)"
                        :name-sign="dataLang?.credits_fund" :placeholder="dataLang?.creadits_fund" disabled="true"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name"  :value="getValue(2)"  @onInput="updateValue($event, 2)"
                        :name-sign="dataLang?.partners_fund" :placeholder="dataLang?.partners_fund" disabled="true"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(5)"  @onInput="updateValue($event, 5)"
                        :name-sign="dataLang?.traffic_fine_fund" :placeholder="dataLang?.traffic_fine_fund" disabled="true"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(9)"  @onInput="updateValue($event, 9)"
                        :name-sign="dataLang?.constituent_fund" :placeholder="dataLang?.constituent_fund" disabled="true"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(10)"  @onInput="updateValue($event, 10)"
                        :name-sign="dataLang?.building_fund" :placeholder="dataLang?.building_fund" disabled="true"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(11)"  @onInput="updateValue($event, 11)"
                        :name-sign="dataLang?.backup_fund" :placeholder="dataLang?.backup_fund" disabled="true"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(3)"  @onInput="updateValue($event, 3)"
                        :name-sign="dataLang?.vehicle_fund" :placeholder="dataLang?.vehicle_fund" disabled="true"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(4)"  @onInput="updateValue($event, 4)"
                        :name-sign="dataLang?.pyment_labour_fund" :placeholder="dataLang?.pyment_labour_fund" disabled="true"></BaseInput>
                    </div>
                </div>
                <div class="block-action">
                    <span class="fw-bold">Пользовательские фонды</span>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(14)"  @onInput="updateValue($event, 14)"
                        :name-sign="dataLang?.fund_10"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(15)"  @onInput="updateValue($event, 15)"
                        :name-sign="dataLang?.fund_11"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(16)"  @onInput="updateValue($event, 16)"
                        :name-sign="dataLang?.fund_12"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(17)"  @onInput="updateValue($event, 17)"
                        :name-sign="dataLang?.fund_13"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(18)"  @onInput="updateValue($event, 18)"
                        :name-sign="dataLang?.fund_14"></BaseInput>
                    </div>
                    <div class="mb-5 mt-13">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(19)"  @onInput="updateValue($event, 19)"
                        :name-sign="dataLang?.fund_15"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(20)"  @onInput="updateValue($event, 20)"
                        :name-sign="dataLang?.fund_16"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(21)"  @onInput="updateValue($event, 21)"
                        :name-sign="dataLang?.fund_17"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(22)"  @onInput="updateValue($event, 22)"
                        :name-sign="dataLang?.fund_18"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(23)"  @onInput="updateValue($event, 23)"
                        :name-sign="dataLang?.fund_19"></BaseInput>
                    </div>
                    <div class="mb-5 mt-10">
                    <BaseInput placeholder="" title="Default Title" ref="company_settings__set_req_company_name"
                        input-name="company_settings__set_req_company_name" :value="getValue(24)"  @onInput="updateValue($event, 24)"
                        :name-sign="dataLang?.fund_20"></BaseInput>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from "vuex";
    export default {
        data(){
            return {
                dataForm: [],
                timeout:null
            }
        },

        created(){
            this.getFundsSettingsAll();
        },
        
        methods:{
            ...mapActions("fundsSettingsModule", ["getFundsSettingsAll", "updateFundsSettingsNames"]),

            updateValue(e, pos){
                this.dataForm[0] = {
                    "finreestr_funds__id": this.fundsSettingsData[pos].finreestr_funds__id,
                    "finreestr_funds__name": e.value
                }

                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.requestToPath, 2000);
            },


            requestToPath() {
                this.updateFundsSettingsNames(this.dataForm);
                this.dataForm = [];
            },

            getValue(pos){
                if(this.fundsSettingsData[pos])
                    return this.fundsSettingsData[pos].finreestr_funds__name;
                return "";
            }
        },

        computed:{
            ...mapState("fundsSettingsModule", ["fundsSettingsData", "fundsSettingsLoadStatus"]),
            ...mapState("translationModule", ["dataLang"]),
        }
    }
</script>