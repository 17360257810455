<template>
  <div class="d-flex flex-column flex-grow-1">
    <BaseTopLine></BaseTopLine>

    <div class="px-10 pb-30 position-relative flex-grow-1">
      <div
        v-if="adSourceLoadStatus == 'request'"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>

      <div class="d-flex gap-10 py-15 pr-15 align-items-center">
        <div
          class="base-button base-button--action py-5"
          @click="openCreateAdsModal"
        >
          <img
            :src="require(`@/assets/icons/add.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14">{{ dataLang["registry_ads_text1"] }}</span>
        </div>
        <div
          class="base-button base-button--action py-5"
          @click="acceptAdsDelete"
        >
          <img
            :src="require(`@/assets/icons/adddel.svg`)"
            width="13.5"
            height="13.5"
          />
          <span class="fsc-14">{{ dataLang["registry_ads_text2"] }}</span>
        </div>
      </div>

      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="adSourceData"
        :border-x="true"
        :border-y="true"
        row-key-field-name="ads__id"
        :checkbox-option="{
          hideSelectAll: false,
          selectedRowChange: selectedRowChange,
          selectedAllChange: selectedAllChange,
        }"
        v-if="adSourceLoadStatus == 'success' || adSourceLoadStatus == 'error'"
        :clickHighlight="false"
        :rowStyleOption="{
          hoverHighlight: true,
        }"
        :eventCustomOption="eventCustomOption"
        :cellSelectionOption="{ enable: false }"
      />
    </div>

    <BaseModal
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
      name="adsModal"
    >
      <div class="py-20 px-30 rounded-25 bg-white">
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('adsModal')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">
            {{
              modalOpenMode == "edit"
                ? dataLang["registry_ads_text6"]
                : dataLang["registry_ads_text5"]
            }}
          </p>
          <div
            class="wp-100"
            :class="errorList.hasOwnProperty('ads__name') ? 'input-error' : ''"
            ref="ads__name"
          >
            <BaseInputNew
              placeholder=""
              class="mb-10 wp-100"
              signClass="fsc-14"
              input-name="ads__name"
              @input="updateValue"
              :value="dataForEmit.ads__name"
              inputClass="fsc-14"
            >
            </BaseInputNew>
          </div>
          <div class="wp-100 mb-15" ref="ads__comment">
            <BaseTextarea
              placeholder=""
              class="wp-100 fsc-14 mb-5"
              signClass="fsc-14 text-muted"
              input-name="ads__comment"
              @onInput="updateValue"
              :minHeight="'160'"
              inputClass="fsc-14"
              :name-sign="dataLang['registry_ads_text4']"
              :value="dataForEmit.ads__comment"
            >
            </BaseTextarea>
          </div>
          <div class="d-flex justify-content-end gap-15">
            <div
              @click="modalAction"
              class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
            >
              {{ dataLang["registry_ads_text7"] }}
            </div>
            <div class="base-button px-20 rounded-10" @click="closeModal">
              {{ dataLang["registry_ads_text8"] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <Teleport to="body">
      <SimpleModal
        size="md"
        :minHeight="320"
        nameForAction="acceptAdsDelete"
        :modal-title="dataLang['menu_admin_ads']"
      >
        <template v-slot:content
          ><p class="fsc-20 mb-30">
            {{ dataLang["vehicle_modal_service_modals_warning4"] }}
          </p>
          <div class="d-flex justify-content-center gap-30 mt-10 px-30 py-10">
            <div class="base-button py-15">
              {{ dataLang["vehile_modal_btn_reset"] }}
            </div>
            <div
              class="btn-primary btn fsc-14 ws-nowrap"
              @click="deleteRow('acceptAdsDelete')"
            >
              {{ dataLang["vehile_modal_btn_delete_ok"] }}
            </div>
          </div>
        </template>
      </SimpleModal>
    </Teleport>
  </div>
</template>
    
    
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { VeTable } from "vue-easytable";
import SimpleModal from "../components/vehicles-page-single-modal-inner/SimpleModal.vue";
import EventBus from "@/event-bus.js";
export default {
  components: {
    VeTable,
    SimpleModal,
  },

  props: {},
  data() {
    return {
      activeSelectRowsMultiple: null,
      updatedValues: {},
      dataForEmit: {},
      dataForModal: {},
      currentOpenedId: null,
      modalOpenMode: "create",
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex);
              this.modalOpenMode = "edit";
              this.$modal.show("adsModal");
              this.currentOpenedId = row.ads__id;

              this.dataForEmit = {
                ...this.dataForEmit,
                ...row,
              };

              this.updatedValues = {
                ...this.updatedValues,
                ...row,
              };
            },
          };
        },
      },
    };
  },
  methods: {
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys);
      this.activeSelectRowsMultiple = selectedRowKeys;
    },

    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys);
      this.activeSelectRowsMultiple = selectedRowKeys;
    },

    openCreateAdsModal() {
      this.modalOpenMode = "create";
      this.$modal.show("adsModal");
    },

    beforeOpen(event) {},

    closeDeleteModal() {
      EventBus.$emit("closeModal", {
        data: null,
        nameForAction: "acceptAdsDelete",
        mode: "create",
      });
    },

    acceptAdsDelete() {
      if (this.activeSelectRowsMultiple.length) {
        EventBus.$emit("openModal", {
          data: null,
          nameForAction: "acceptAdsDelete",
          mode: "create",
        });
      } else {
        this.$toast.error("Выберите хотя-бы одно поле");
      }
    },

    clearInputs() {
      Object.keys(this.dataForEmit).forEach((elem, index) => {
        if (this.$refs[elem]) {
          this.$refs[elem].classList.remove("input-error");
        }
      });
    },

    beforeClose(event) {
      this.dataForEmit = {};
      this.updatedValues = {};
      this.selectedRow = {};
      this.clearInputs();
      this.clearErrorList();
      console.log(event);
    },

    closeModal() {
      this.$modal.hide("adsModal");
    },

    modalAction() {
      if (this.modalOpenMode == "create") {
        this.commitCreate();
      } else {
        this.commitAlter();
      }
    },

    commitCreate() {
      console.log("commit create");
      this.createAdSourceData({
        data: this.updatedValues,
        callback: () => {
          this.$modal.hide("adsModal");
          this.clearInputs();
        },
      });
    },

    commitAlter() {
      this.editAdSourceSingular({
        id: this.currentOpenedId,
        dataEdit: this.updatedValues,
        callback: () => {
          this.$modal.hide("adsModal");
          this.clearInputs();
        },
      });
    },

    deleteRow(nameModal) {
      console.log(this.activeSelectRowsMultiple);
      this.deleteAdSourceData(this.activeSelectRowsMultiple);
      this.activeSelectRowsMultiple = null;
      EventBus.$emit("closeModal", { nameForAction: nameModal });
    },

    updateValue(data) {
      console.log(this.updatedValues);
      this.updatedValues[data.name] = data.value;
      this.clearErrorList();
      this.$refs[data.name].classList.remove("input-error");
    },

    ...mapActions("adSourceModule", [
      "getAdSourceAll",
      "createAdSourceData",
      "editAdSourceSingular",
      "deleteAdSourceData",
      "clearErrorList",
    ]),
  },

  mounted() {},

  created() {
    this.getAdSourceAll({
      params: {
        page: 1,
        limit: 100,
      },
    });
  },

  computed: {
    ...mapState("adSourceModule", [
      "adSourceData",
      "adSourceLoadStatus",
      "paginationAdSourceStateParams",
      "errorList",
    ]),
    ...mapState("translationModule", ["dataLang"]),
    ...mapGetters("adSourceModule", ["numPages"]),

    columns() {
      return [
        {
          field: "",
          key: "a",

          type: "checkbox",
          title: "",
          width: "3%",
          align: "center",
        },
        {
          field: "ads__name",
          key: "1",
          title: this.dataLang["registry_ads_text3"],
          align: "left",
          width: "20%",
          sortBy: "",
          ellipsis: {
            showTitle: true,
            lineClamp: 1,
          },
        },
        {
          field: "ads__comment",
          key: "2",
          title: this.dataLang["registry_ads_text4"],
          align: "left",
          width: "77%",
          sortBy: "",
          ellipsis: {
            showTitle: true,
            lineClamp: 1,
          },
        },
      ];
    },
  },
};
</script>