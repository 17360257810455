<template>
    <div>
        <div class="mt-20">
            <div class="d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('secureClientsFieldsModal')">Добавить</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div>
            </div>
            <BaseReuseTable class="mt-10" :props-data="secureClientsFieldsData" :props-columns-setting="fieldsTablesecureClientsFields"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="secureClientsFieldsModal" v-if="secureClientsFieldsLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="secureClientsFieldsModal">
                <div class="px-10 fw-bold">Добавить поле для клиента</div>
                <div class="p-10"> 
                    <div class="mb-5 mt-10">
                        <BaseInput placeholder="" title="Default Title" ref="secur_clients_fields__label"
                        input-name="secur_clients_fields__label" value="" @onInput="updateValue"
                        name-sign="Наименование поля"></BaseInput> 
                    </div>

                    <div class="mb-5 mt-10">
                        <BaseInput placeholder="" title="Default Title" ref="secur_clients_fields__name"
                        input-name="secur_clients_fields__name" value="" @onInput="updateValue"
                        name-sign="API name"></BaseInput> 
                    </div>

                    <div>
                        <span>Тип поля</span>
                        <BaseSelect :options="typeFields" placeholder="" label="label" @select="updateSelectValue" v-model="typeField">
                            <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                    </div>

                    <div>
                        <span>Поле из профиля клиента</span>
                        <BaseSelect :options="clientFields" placeholder="" label="label" @select="updateSelectValue" v-model="clientField">
                            <div slot="no-options">Пока нет опций</div>
                        </BaseSelect>
                    </div>

                  
                    <div class="d-flex gap-10 mt-15">
                        <div class="btn btn-sm btn-primary" @click="sendData">{{
                            dataLang.form_btn__btn_save }}</div>
                        <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                            dataLang.form_btn__btn_caneсl }}</div>
                    </div>
                </div>
            </Modal>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>

<script>
    
    import { mapActions, mapState, mapMutations } from "vuex";
    import Modal from '@/components/Modals.vue'
    import EventBus from '@/event-bus.js'
import SelectsService from "@/api/SelectsService";
    export default {
        components:{
            Modal,
            EventBus,
           
        },

        data(){
            return {
                typeFields: [],
                typeField: null,
                clientFields: [],
                clientField: null,
                activeSelectRow:null,
                dataForm: {}
            }
        },

        created(){
            this.loadSelects("secur_clients_fields__type_field", (typesFieldsData) => {
               this.typeFields = typesFieldsData.data.data.rows;
            })

            this.loadSelects("secur_clients_fields__client_field", (typesFieldsData) => {
               this.clientFields = typesFieldsData.data.data.rows;
            })
        },

        mounted() {
            EventBus.$on("openModal", (payload) => {
                this.editData = payload.data
                // console.log(payload.data);
                // this.prepareKeysForModal(payload.data)
            });

            EventBus.$on("selectTableRows", (payload) => {
                this.activeSelectRow = payload.data.secur_questions__id
            });

            EventBus.$on("deselectTableRows", (payload) => {
                this.activeSelectRow = null
            });

        },

        watch:{
            typeField(v){
                console.log(v)
            }
        },
        methods: {
            ...mapActions("secureClientsFieldsModule", ["getSecureСlientsFieldsAll", "createSecureСlientField", "deleteСlientsFieldsData"]),

            openModalForCreate(nameModal) {
                EventBus.$emit("openModal", { data: null, nameForAction: nameModal, mode: 'create' })
            },

            loadSelects(name, callback){
                SelectsService.getOneByField(name).then(callback);
            },

            updateSelectValue(data){
                this.dataForm[data.field] = data.value;
            },

            updateValue(data){
                this.dataForm[data.name] = data.value;
            },
            

            sendData(){
                this.createSecureСlientField({id:this.secureOpenGroupId, data:this.dataForm});
                EventBus.$emit("closeModal", { nameForAction: 'secureClientsFieldsModal' })
            },

            btnHandlerCloseModal(nameModal) {
                EventBus.$emit("closeModal", { nameForAction: 'secureClientsFieldsModal' })
            },

            deleteActionAndModal() {
                if (this.activeSelectRow) {
                    EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
                } else {
                    this.$toast.error('Вы не выбрали ни одного поля')
                }
            },

            deleteRow(nameModal) {
                this.deleteСlientsFieldsData({idGroup:this.secureOpenGroupId, idField:this.activeSelectRow})
                this.activeSelectRow = null
                EventBus.$emit("closeModal", { nameForAction: nameModal })
            },
        },

        watch:{
            secureOpenGroupId(val){
                if(val)
                    this.getSecureСlientsFieldsAll(this.secureOpenGroupId);
            }
        },

        computed:{
            ...mapState("secureGroupsModule", ["secureOpenGroupId"]),
            ...mapState("secureClientsFieldsModule", ["secureClientsFieldsData", "secureClientsFieldsLoadStatus"]),
            ...mapState("translationModule", ["dataLang"]),

            fieldsTablesecureClientsFields(){
                return [
                    { title: 'ID', field: "secur_clients_fields__id", headerFilter: "input", width:100},
                    { title: 'Название поля', field: "secur_clients_fields__label", headerFilter: "input" },
                ]
            }
        }
    }
</script>