
import OwnersService from "@/api/OwnersService.js";
import Vue from "vue";
export const ownersModule = {
  namespaced: true,
  state: () => ({
    ownersData: [],
    loadStatus: 'request',
    paginationStateParams: {
      count: 0,
      limit: 10,
      total: 0,
      page: 1
    }
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationStateParams.total / state.paginationStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.ownersData = [...data];
      // state.ownersData = data.map((item, i) => Object.assign({}, item, state.ownersData[i]));
    },
    create(state, data) {
      state.ownersData.unshift(data[0])
    },
    edit_multiple(state, data) {
      let indexed = state.ownersData.findIndex(x => x.vehicle_owners__id == data.id)
      Object.assign(state.ownersData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.ownersData.findIndex(x => x.vehicle_owners__id == id)
      state.ownersData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      console.log({ total, count, limit, page });
      state.paginationStateParams = { total, count, limit, page }
    },

    load_request(state, status) {
      state.loadStatus = 'request'
    },
    load_success(state) {
      state.loadStatus = 'success'
    },
    load_error(state) {
      state.loadStatus = 'error'
    },
  },
  actions: {

    getAll({ commit, state }, {page, limit}) {
      commit("load_request");
      OwnersService.getAll({params: {
        page: page ? page: state.page,
        limit: limit ? limit: state.limit
      }})
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createData({ commit }, createData) {
      
      OwnersService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data.rows);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          // console.log(error.response.messa);
          Vue.$toast.error('Ошибка загрузки')
          
        });
    },

    editMultiple({ commit }, {id, dataEdit}) {
      OwnersService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteData({ commit }, id) {
      // commit("load_request");
      OwnersService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
