
import EmployeesAdminService from "@/api/EmployeesAdminService.js";
import Vue from "vue";
export const employeesAdminModule = {
  namespaced: true,
  state: () => ({
    employeesAdminData: [],
    employeesAdminLoadStatus: 'request',
    paginationPromocodesStateParams: {
      count: 0,
      limit: 5,
      total: 0,
      page: 0
    },
    passwordForModal: '',
  }),

  getters: {
    numPages: state => Math.ceil(state.paginationPromocodesStateParams.total / state.paginationPromocodesStateParams.limit),
  },
  mutations: {
    get_all(state, data) {
      state.employeesAdminData = [...data];
    },
    create(state, data) {
      state.employeesAdminData.unshift(data)
    },
    edit_multiple(state, data) {
      let indexed = state.employeesAdminData.findIndex(x => x.employees__id == data.id)
      state.employeesAdminData[indexed] = Object.assign(state.employeesAdminData[indexed], data.dataEdit)
    },

    delete_data(state, id) {
      let indexed = state.employeesAdminData.findIndex(x => x.employees__id == id)
      state.employeesAdminData.splice(indexed, 1)
    },

    update_pagination(state, { total, count, limit, page }) {
      state.paginationPromocodesStateParams = { total, count, limit, page }
    },
    update_password(state, password) {
      state.passwordForModal = password
    },

    load_request(state, status) {
      state.employeesAdminLoadStatus= 'request'
    },
    load_success(state) {
      state.employeesAdminLoadStatus= 'success'
    },
    load_error(state) {
      state.employeesAdminLoadStatus= 'error'
    },
  },
  actions: {
   

    getEmployeesAdminAll({ commit }, params) {
      commit("load_request");
      console.log( EmployeesAdminService)
      EmployeesAdminService.getAll(params)
        .then((data) => {
          commit("update_pagination", data.data.data);
          commit("get_all", data.data.data.rows);
          commit("load_success");
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error('Ошибка загрузки')
          commit("load_error");
        });
    },

    createEmployeesAdminData({ commit }, createData) {
      console.log(createData);
        EmployeesAdminService.create(createData)
        .then((data) => {
          console.log(data)
          commit("create", data.data.data);
          commit("update_password", data.data.message);
          console.log(data.data);
          Vue.$toast.success('Запись добавлена')
        })
        .catch((error) => {
          console.log(error.response.data.message);
          Vue.$toast.error(error.response.data.message)
          commit("update_password", error.response.data.message);
        });
    },

    editEmployeesAdminMultiple({ commit }, {id, dataEdit}) {
        EmployeesAdminService.editMultiple(id, dataEdit)
        .then((data) => {
          commit("edit_multiple", {id, dataEdit} );
          Vue.$toast.success('Данные успешно обновлены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },


    deleteEmployeesAdminData({ commit }, id) {
      // commit("load_request");
      EmployeesAdminService.delete(id)
        .then((data) => {
          console.log(data)
          commit("delete_data", id);
          Vue.$toast.success('Данные успешно удалены')
        })
        .catch((error) => {
          Vue.$toast.error('Ошибка загрузки')
        });
    },

  },
};
