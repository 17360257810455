<template>
    <div>
        <BaseTopLine></BaseTopLine>

        <div class="p-20">
            <div class="funds">
                <template v-for="(funds) in groupFunds">
                    <div class="funds-list">
                        <template v-for="(fund) in funds"  >
                            <div class="funds-name">{{ fund.finreestr_funds__name }}</div>
                            <div class="funds-value">{{ fund.finreestr_funds__amount }}</div>
                        </template>
                    </div>
                </template>
            </div>

            <div class="mb-20 d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openaActionForm('spend')">Прочий расход</div>
                <div class="btn btn-sm btn-primary" @click="openaActionForm('coming')">Прочий приход</div>
                <div class="btn btn-sm btn-primary" @click="openaActionForm('transit')">Транзит</div>
            </div>

            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="finreestrData" :props-columns-setting="fieldsTableFinreestr"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="finreestrModal" v-if="finreestrLoadStatus == 'success'" 
            :groupBy="groupByColumnsTable"
            >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <FinreestrActionForm :action="actionForm"></FinreestrActionForm>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
import FinreestrActionForm from "@/components/finreestr/FinreestrActionForm.vue";

export default {
    components: {
    Modal,
    BaseTextarea,
    FinreestrActionForm
},

    props: {},
    data() {
        return {
            actionForm: null,
            updatedValues: {},
            activeSelectRow: null,
        };
    },
    
    
    methods: {
        clickLoadData(numPage) {
            this.getFinreestrAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },

        openaActionForm(action) {
            this.actionForm = action;
            EventBus.$emit("openModal", { data: null, nameForAction: 'finrreestrActionFormModel', mode: 'create' })
        },

        deleteActionAndModal() {
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },

        deleteRow(nameModal) {
            this.deleteFinreestrData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },
 
        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
            // this.$refs[data.nameForAction].$el.querySelectorAll('input').forEach(element => {
            //     element.value = ''
            // })
        },
       


        ...mapActions("finreestrModule", ["getFinreestrAll", 'createFinreestrData', 'editFinreestrMultiple', 'deleteFinreestrData', 'setFinreestrRoute']),
        ...mapActions("fundsModule", ["getFundsAll"]),

    },
    mounted() {
      
        EventBus.$on("openModal", (payload) => {});
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.Finreestr__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getFundsAll({params: {}});
        this.getFinreestrAll({ params: { 
            page: 1, limit: 10, 
        }})
    },
    computed: {
        ...mapState("finreestrModule", ["finreestrData", "finreestrLoadStatus", 'paginationFinreestrStateParams']),
        ...mapState("fundsModule", ["fundsData", "fundsLoadStatus", 'paginationFundsStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("finreestrModule", ["numPages"]),

        groupFunds(){
            let groups = [];
            let group = [];
            this.fundsData.forEach((item, i) => {
                if(group.length == 5){     
                    groups.push(group);
                    group = [];
                }

                group.push(item);
            });

            groups.push(group);
            return groups;
        },

        fieldsTableFinreestr() {
            return [
                { title: '№', field: "finreestr__id", headerFilter: "input" },
                { title: 'Дата время', field: "finreestr__created_at", headerFilter: "input" },
                { title: 'Сумма', field: "finreestr__amount", headerFilter: "input", mutator: (value,data) => {
                        return data.finreestr__direction+''+value;
                    }, formatter: (cell) => {
                        let value = cell.getValue();
                        if(Number(value) > 0){
                            cell.getRow().getElement().style.color = 'green';
                        }else{
                            cell.getRow().getElement().style.color = 'red';
                        }

                        return value;
                    }
                },
                { title: 'Тип платежа/Фонд', field: "finreestr_funds__name", headerFilter: "input"},
                { title: 'Контрагент', field: "finreestr__contragent", headerFilter: "input" },
                { title: 'Основание', field: "finreestr__basis", headerFilter: "input" },
                { title: 'Менеджер', field: "finreestr__manager", headerFilter: "input" }, 
            ]
        },

        groupByColumnsTable(){
            return null
        }
    },
};
</script>
    
    