import http from "@/http-common.js";

class AccessService {
  basePathApiService = "/employee/";
  auth(authData) {
    return http.post(this.basePathApiService + "auth", authData);
  }
  reg(regData) {
    return http.post(this.basePathApiService + "register", regData);
  }
  passwordRecovery(login) {
    return http.post(this.basePathApiService + "renewal", login);
  }
  tokenVerification(params) {
    return http.get("/checkaccess", params);
  } 
}
export default new AccessService();
