<template>
  <div>
    <BaseTopLine></BaseTopLine>
    <div class="position-relative">
      <BaseLoader v-if="localLoading"></BaseLoader>

      <VueTabs v-model="activateTab" @tab-change="handleTabChange">
        <VTab
          :title="dataLang['city_delivery_register_text_1']"
          data-name="places"
        >
          <div
            class="px-30"
            v-if="dataLang['city_delivery_register_text_1'] == activateTab"
          >
            <div class="d-flex gap-10 align-items-center py-15">
              <div
                class="base-button base-button--action"
                @click="$modal.show('addPlaceModal')"
              >
                <img
                  :src="require(`@/assets/icons/add.svg`)"
                  width="13.5"
                  height="13.5"
                />
                <span class="fsc-14">{{
                  dataLang['city_delivery_register_text_3']
                }}</span>
              </div>
              <div
                class="base-button base-button--action"
                @click="acceptDelete"
              >
                <img
                  :src="require(`@/assets/icons/adddel.svg`)"
                  width="18"
                  height="18"
                />
                <span class="fsc-14">
                  {{ dataLang['city_delivery_register_text_4'] }}</span
                >
              </div>
            </div>
            <div class="d-flex justify-content-between mb-15">
              <div class="d-flex gap-15 align-items-center">
                <div
                  class="btn btn-sm btn-thrity text-white fsc-14"
                  @click="clickLoadData(1, 'places')"
                >
                  {{ dataLang['register_btn_filter_on'] }}
                </div>
                <div
                  class="text-decoration-underline fsc-14 text-dark"
                  style="cursor: pointer"
                  @click="resetFilter('places')"
                >
                  {{ dataLang['register_btn_filter_off'] }}
                </div>
              </div>
              <BasePagination
                :page-count="numPagesCities"
                :pagination-params="paginationCitiesStateParams"
                :showLimitInput="false"
                @updateLimitValue="() => {}"
                :page-range="10"
                :margin-pages="0"
                :break-view-class="''"
                :click-handler="(num) => clickLoadData(num, 'cities')"
                :prev-text="''"
                :next-text="''"
                :container-class="'custom-pagination'"
                :page-class="'custom-pagination__item'"
                :pageLinkClass="'custom-pagination__item-link'"
              ></BasePagination>
            </div>

            <ve-table
              style="width: 100%"
              :columns="columns"
              :table-data="dataTable"
              :border-x="true"
              :border-y="true"
              ref="refTable"
              row-key-field-name="vehicle_places__id"
              :eventCustomOption="eventCustomOption"
              :checkbox-option="checkboxOption"
              v-if="!localLoading"
              :clickHighlight="false"
              :rowStyleOption="{
                hoverHighlight: true
              }"
              :cellSelectionOption="{ enable: false }"
            />
          </div>
          <BaseModal
            name="addPlaceModal"
            width="420"
            :clickToClose="false"
            @before-open="beforeOpen"
            @before-close="beforeClose"
            :shiftY="0.5"
            :adaptive="true"
            height="auto"
            classes="bg-transparent shadow-none overflow-auto"
          >
            <div class="bg-white rounded-25 py-25 px-20 pb-50">
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn-close"
                  @click="$modal.hide('addPlaceModal')"
                ></button>
              </div>
              <p class="text-center fw-semi-bold mb-15">
                {{ dataLang.city_delivery_register_text_37 }}
              </p>
              <div>
                <div class="" ref="vehicle_places__city_id">
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.city_delivery_register_text_8
                  }}</span>
                  <BaseSelect
                    placeholder=""
                    selectLabel=""
                    deselectLabel=""
                    @input="
                      (data) => {
                        setSimpleSelect({
                          key: 'vehicle_places__city_id',
                          value: data.cities__id
                        })
                        valueAddress = data.cities__name
                      }
                    "
                    v-model="selectsFromModal.cities"
                    :options="selectsData.cities"
                    label="cities__name"
                  >
                    <template slot="noOptions">{{
                      dataLang['select_empty']
                    }}</template>
                    <template slot="noResult">{{
                      dataLang['select_search_empty']
                    }}</template
                    ><template slot="selectedLabel">{{
                      dataLang['select_selected']
                    }}</template>
                    <template slot="deselectLabel">{{
                      dataLang['select_unselect']
                    }}</template>
                  </BaseSelect>
                </div>
                <div class="" ref="vehicle_places__address">
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.city_delivery_register_text_38
                  }}</span>
                  <BaseInputNew
                    placeholder=""
                    class="lh-1"
                    input-name="vehicle_places__address"
                    maxLength="100"
                    @input="updateValueCitiesCenter"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    :value="valueAddress"
                    @selectData="onSelectFromAddress"
                    :additionalParams="{}"
                    name-sign=""
                    :options="addressOptions"
                    selectKey="formatted_address"
                    selectFocusmethod="input"
                  >
                  </BaseInputNew>
                  <!-- <BaseInputNew
                    placeholder=""
                    class="lh-1"
                    input-name="vehicle_places__address"
                    maxLength="100"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    value=""
                    :additionalParams="{}"
                    name-sign=""
                  >
                  </BaseInputNew> -->
                </div>
                <div class="mb-20" ref="vehicle_places__name">
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.city_delivery_register_text_10
                  }}</span>
                  <BaseInputNew
                    placeholder=""
                    class="lh-1"
                    input-name="vehicle_places__name"
                    maxLength="100"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    value=""
                    :additionalParams="{}"
                    name-sign=""
                  >
                  </BaseInputNew>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-20"
                  ref="vehicle_places__work_hours_start"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.city_delivery_register_text_23
                  }}</span>
                  <BaseDatePicker
                    v-model="selectTime.start"
                    type="time"
                    class="fsc-11 idiots-class-for-input-date"
                    lang="ru"
                    id="vehicle_places__work_hours_start"
                    style="width: 210px"
                    value-type="format"
                    :format="`HH:mm`"
                    @change="
                      (date) =>
                        updateValue({
                          name: 'vehicle_places__work_hours_start',
                          value: date
                        })
                    "
                  >
                  </BaseDatePicker>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-20"
                  ref="vehicle_places__work_hours_end"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.city_delivery_register_text_24
                  }}</span>
                  <BaseDatePicker
                    v-model="selectTime.end"
                    type="time"
                    class="fsc-11 idiots-class-for-input-date"
                    lang="ru"
                    id="vehicle_places__work_hours_end"
                    style="width: 210px"
                    value-type="format"
                    :format="`HH:mm`"
                    @change="
                      (date) =>
                        updateValue({
                          name: 'vehicle_places__work_hours_end',
                          value: date
                        })
                    "
                  >
                  </BaseDatePicker>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-20"
                  ref="vehicle_places__phone"
                >
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.city_delivery_register_text_11
                  }}</span>
                  <BaseInputNew
                    placeholder=""
                    class="lh-1"
                    width="210"
                    input-name="vehicle_places__phone"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark fsc-11"
                    inputClass=""
                    inputRef="clients__phone"
                    inputMask="+{7}(000)000-00-00"
                    maxLength="16"
                    value=""
                    :additionalParams="{}"
                    name-sign=""
                  >
                  </BaseInputNew>
                </div>
                <div class="" ref="vehicle_places__is_open">
                  <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                    dataLang.city_delivery_register_text_12
                  }}</span>
                  <BaseSelect
                    placeholder=""
                    selectLabel=""
                    deselectLabel=""
                    @input="
                      (data) =>
                        updateValue({
                          name: 'vehicle_places__is_open',
                          value: data.value
                        })
                    "
                    v-model="selectsFromModal.countries"
                    :options="[
                      { label: 'Открытая', value: 1 },
                      { label: 'Закрытая', value: 0 }
                    ]"
                    label="label"
                  >
                    <template slot="noOptions">{{
                      dataLang['select_empty']
                    }}</template>
                    <template slot="noResult">{{
                      dataLang['select_search_empty']
                    }}</template
                    ><template slot="selectedLabel">{{
                      dataLang['select_selected']
                    }}</template>
                    <template slot="deselectLabel">{{
                      dataLang['select_unselect']
                    }}</template>
                  </BaseSelect>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-10 gap-30">
                <div
                  class="base-button px-20 rounded-5 text-uppercase"
                  @click="$modal.hide('addPlaceModal')"
                >
                  ОТМЕНА
                </div>
                <div
                  class="btn btn-sm btn-primary px-20 rounded-5 d-flex align-items-center justify-content-center text-uppercase"
                  @click="sendData"
                >
                  ДАЛЕЕ
                </div>
              </div>
            </div>
          </BaseModal>
        </VTab>
        <VTab
          :title="dataLang['city_delivery_register_text_2']"
          data-name="global"
        >
          <div
            class="px-30"
            v-if="dataLang['city_delivery_register_text_2'] == activateTab"
          >
            <div
              class="d-flex gap-5 align-items-center py-15"
              ref="vehicle_places__city_id"
            >
              <span class="fsc-11 input-title" style="color: #1d1e2c">{{
                dataLang.global_register_text_1
              }}</span>
              <BaseSelect
                placeholder=""
                selectLabel=""
                deselectLabel=""
                style="width: 265px"
                @input="
                  (data) => {
                    globalCity = data
                    loadDataForGlobal(true)
                  }
                "
                v-model="globalCity"
                :options="citiesGlobal"
                label="cities__name"
              >
                <template slot="noOptions">{{
                  dataLang['select_empty']
                }}</template>
                <template slot="noResult">{{
                  dataLang['select_search_empty']
                }}</template
                ><template slot="selectedLabel">{{
                  dataLang['select_selected']
                }}</template>
                <template slot="deselectLabel">{{
                  dataLang['select_unselect']
                }}</template>
              </BaseSelect>
            </div>
            <div class="d-flex gap-15 align-items-center justify-content-between mb-15">
              <div
                class="text-decoration-underline fsc-14 text-dark"
                style="cursor: pointer"
                @click="resetFilter"
              >
                {{ dataLang['register_btn_filter_off'] }}
              </div>
              <div class="fsc-11">Показано: <span class="fw-bold">{{  globalData.length }}</span>  записей</div>
            </div>
            <ve-table
              style="width: 100%"
              :columns="colGlobal"
              :table-data="globalData"
              :border-x="true"
              :border-y="true"
              ref="refTable"
              row-key-field-name="cities__id"
              v-if="!localLoading"
              :clickHighlight="false"
              :cell-style-option="{
                headerCellClass: ({ column, rowIndex }) => {
                  return 'table-header-cell-class'
                }
              }"
              :rowStyleOption="{
                hoverHighlight: true
              }"
              :cellSelectionOption="{ enable: false }"
            />
          </div>
        </VTab>
      </VueTabs>
    </div>
    <BaseModal
      name="acceptDelete"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDelete')"
          ></button>
        </div>
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">
            {{ dataLang.confirm_btn__btn_yes }}
          </div>
        </div>
      </div>
    </BaseModal>
    <PlacesModal></PlacesModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import GeneralService from '@/api/GeneralService'
import PlacesModal from './places-modal/PlacesModal.vue'
import EventBus from '@/event-bus'
export default {
  components: {
    VeTable,
    VTab,
    VueTabs,
    PlacesModal
  },

  props: {},
  data() {
    return {
      numOfList: 0,
      copyData: [],
      textparam: '12312',
      globalCity: {
        global_cities__city_id: 1,
        global_cities__company_id: 51,
        global_cities__id: 11,
        cities__center_address:
          'Республика Адыгея, Адыгейск, проспект Ленина, 31',
        cities__center_latitude: null,
        cities__center_longitude: null,
        cities__country_id: 1,
        cities__id: 1,
        cities__integrator_id: 2,
        cities__name: 'Адыгейск',
        cities__phone: '+7(800)333-60-30',
        cities__region: null,
        cities__region_id: 1,
        cities__subdomain: 'Adygejsk',
        cities__utc: '+3'
      },
      globalData: [],
      updatedValues: {},
      dataTable: null,
      localLoading: true,
      activateTab: '',
      limitPaginationValue: 100,
      valueAddress: '',
      filter: [],
      activeSelectRowsMultiple: [],
      selectTime: { start: [], end: [] },
      citiesGlobal: [],
      addressOptions: [],
      selectsData: {
        countries: [],
        region: [],
        cities: [],
        time: [],
        places: []
      },
      letOnew: {
        one: [],
        two: [],
        three: [],
        four: []
      },
      selectsFilter: {
        countries: [],
        region: [],
        time: [],
        region: [],
        cities: [],
        delivery: [],
        open: [],
        nones: [],
        sgs: [],
        sgf: [],
        sgt: []
      },
      selectsFromModal: {
        countries: [],
        region: [],
        time: [],
        integrator: []
      },
      currentOpenedId: null,
      checkboxOption: {
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          console.log(selectedRowKeys)
          this.activeSelectRowsMultiple = selectedRowKeys
        },
        // selected all change event
        selectedAllChange: ({ row, isSelected, selectedRowKeys }) => {
          console.log(selectedRowKeys)
          this.activeSelectRowsMultiple = selectedRowKeys
        }
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('placeEditModal', {
                id: row.vehicle_places__id
              })
            }
          }
        }
      },
      timeout: null,
      valueAddress: ''
    }
  },
  methods: {
    updateValueCitiesCenter(e) {
      this.valueAddress = e.value
      this.updatedValues.vehicle_places__address = e.value
      this.addressOptions = []
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        let data = await fetch(
          `https://suggest-maps.yandex.ru/v1/suggest?text=${e.value}&apikey=be9de750-f591-4271-82d1-4846630211d1&print_address=1&types=country,house,house,district,geo,street,locality&lang=ru&results=15`
        )
        let dataFromJson = await data.json()
        dataFromJson.results.forEach((elem, index) => {
          this.addressOptions.push(elem.address)
        })
      }, 400)
    },
    onSelectFromAddress(e) {
      console.log(e)
      this.valueAddress = e.value.formatted_address
      this.updatedValues.vehicle_places__address = e.value.formatted_address
    },
    deleteRow() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.delete(`/places/${this.activeSelectRowsMultiple}`)
          .then((data) => {
            console.log(data)
            this.clickLoadData(1, 'places')
            this.$modal.hide('acceptDelete')
            this.$toast.success(data.data.message)
          })
          .catch((error) => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
    acceptDelete() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$toast.error('Выберите хотя бы одно поле')
      }
    },
    beforeClose() {
      for (const key in this.selectsFromModal) {
        this.selectsFromModal[key] = []
      }
      this.updatedValues = {}
      this.openedType = 'create'
      this.currentOpenedId = null
      this.selectTime = { start: [], end: [] }
    },
    beforeOpen() {},
    sendData() {
      GeneralService.editOrCreate('/places', {
        ...this.updatedValues
      })
        .then((data) => {
          console.log(data)
          this.clickLoadData(1, 'places')
          this.$modal.hide('addPlaceModal')
          this.$toast.success(data.data.message)
          data.data.data.vehicle_places__id
          this.$modal.show('placeEditModal', {
            id: data.data.data.vehicle_places__id
          })
          this.updatedValues = {}
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).forEach((elem, index) => {
            if (this.$refs[elem]) {
              this.$refs[elem].classList.add('input-error')
            }
          })
          this.$toast.error(error.response.data.message)
        })
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      this.updatedValues = {}
      this.filter = []
      if (newTab.$attrs['data-name'] == 'places') {
        this.clickLoadData(1, newTab.$attrs['data-name'])
      } else {
        this.loadDataForGlobal(true)
      }
    },

    clickLoadData(numPage, reestrType) {
      this.limitPaginationValue = 100
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/${reestrType}/table/main`, {
          params: {
            limit: this.limitPaginationValue,
            page: numPage,
            where: JSON.stringify(this.filter)
          }
        })
          .then((data) => {
            console.log(data)
            this.dataTable = data.data.rows
            this.getCitiesAll(data.data)
          })
          .catch((error) => {
            console.log(error)
          }),
        GeneralService.get('/countries')
          .then((data) => {
            console.log(data)
            this.selectsData.countries = data.data.data.rows
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          }),
        GeneralService.get('/regions')
          .then((data) => {
            console.log(data)
            this.selectsData.region = data.data.data.rows
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          }),
        GeneralService.get('/cities?limit=5000')
          .then((data) => {
            console.log(data)
            this.selectsData.cities = data.data.data.rows
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          }),
        GeneralService.get('/places')
          .then((data) => {
            console.log(data)
            this.selectsData.places = data.data.places.rows

          })
          .catch((error) => {
            console.log(error)
          })
      ])
        .then(() => {
          this.localLoading = false
        })
        .catch((err) => console.log(err))
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
    },
    setSimpleSelect(data, id) {
      this.updatedValues[data.key] = data.value
      if (this.$refs[data.key]) {
        this.$refs[data.key].classList.remove('input-error')
      }
    },
    resetFilter(reestrType) {
      this.filter = []
      this.selectsFilter = {
        countries: [],
        region: [],
        time: [],
        integrator: [],
        delivery: [],
        open: []
      }
      if (reestrType == 'places') {
        this.clickLoadData(1, reestrType)
      } else {
        this.loadDataForGlobal(true)
      }
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },
    onChangeNew(active, row) {
      GeneralService.edit(
        `/global/settings/${this.globalCity.global_cities__id}`,
        {
          global_cities_settings__city_id: row.cities__id,
          global_cities_settings__active: active
        }
      )
        .then((data) => {
          console.log(data)
          // this.globalData = data.data.data.rows
          let index = this.globalData.findIndex(x =>
            x.cities__id == row.cities__id
          )
          this.globalData[index].active = active
          console.log(this.globalData[index]);
        })
        .catch((error) => {
          console.log(error)
        })
    },
    loadDataForGlobal(e) {
      if (e === true) {
        this.localLoading = true
      }
      GeneralService.get(`/global/cities`, {
        params: {
          limit: 10000
        }
      })
        .then((data) => {
          console.log(data)
          this.citiesGlobal = data.data.rows
          GeneralService.get(
            `/global/settings/${this.globalCity.global_cities__id}`,
            {
              params: {
                limit: 10000
              }
            }
          )
            .then((data) => {
              console.log(data)
              this.globalData = data.data.data.rows
              this.copyData = data.data.data.rows
              if (e === true) {
        this.localLoading = false
      }
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    ...mapActions('citiesPagiation', ['getCitiesAll'])
  },
  mounted() {
    EventBus.$on('updateContentData', () => {
      this.clickLoadData(1, 'places')
    })
  },

  created() {
    this.clickLoadData(1, 'places')
  },

  computed: {
    ...mapState('userSettingModule', ['userSetting', 'countNotices']),
    ...mapState('translationModule', ['dataLang']),
    ...mapState('citiesPagiation', ['paginationCitiesStateParams']),
    ...mapGetters('citiesPagiation', ['numPagesCities']),
    numPages() {
      return this.paginationStateParams.total / this.paginationStateParams.limit
    },

    colGlobal() {
      return [

        {
          field: 'regions__name',
          title: this.dataLang['global_register_text_4'],
          align: 'center',
          key: '2224',
          width: '14%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.region}
                  onInput={(e, w) => {
                    console.log(e)
                    {/* numOfList: 0, */}
                    this.globalData = this.copyData.filter((x) => {
                      return x.regions__id == e.regions__id
                    })
                  }}
                  style={'width: 100%'}
                  label={'regions__name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.region}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'cities__name',
          title: this.dataLang['global_register_text_5'],
          width: '14%',
          align: 'center',
          key: '224',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.cities}
                  onInput={(e, w) => {
                    console.log(e, 'cities__name')
                    this.globalData = this.copyData.filter((x) => {
                      return x.cities__name == e.cities__name
                    })
                  }}
                  style={'width: 100%'}
                  label={'cities__name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.cities}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          title: this.dataLang['global_register_text_6'],
          align: 'center',
          key: '0008',
          width: '7%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.nones}
                  onInput={(e, w) => {
                    console.log(e)
                    if (e.value) {
                      this.globalData = this.globalData.filter((x) => {
                        return x.active == 'none'
                      })
                    }
                  }}
                  style={'width: 100%'}
                  label={'label'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    {
                      label: 'Да',
                      value: 1
                    },
                    {
                      label: 'Нет',
                      value: 0
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'d-flex justify-content-center'}>
                <BaseRadio
                  name={row.global_cities__city_id}
                  value={'none'}
                  vModel={row.active}
                  onChange={(e) => {
                    this.onChangeNew(row.active.value, row)
                  }}
                >
                  {''}
                </BaseRadio>
              </div>
            )
          }
        },
        {
          title: `${this.dataLang['global_register_text_7']} \r\n ${this.dataLang['global_register_text_8']}`,
          align: 'center',
          key: '24724',
          width: '20%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>
                  {`${this.dataLang['global_register_text_7']}`}
                  <BaseSelect
                    vModel={this.selectsFilter.sgs}
                    onInput={(e, w) => {
                      console.log(e)
                      if (e.value) {
                        this.globalData = this.globalData.filter((x) => {
                          return x.active == 'gs'
                        })
                      }

                    }}
                    style={'width: 100%'}
                    label={'label'}
                    placeholder={''}
                    id={'ads__id'}
                    selectLabel={''}
                    deselectLabel={''}
                    options={[
                      {
                        label: 'Да',
                        value: 1
                      },
                      {
                        label: 'Нет',
                        value: 0
                      }
                    ]}
                  ></BaseSelect>
                </div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'d-flex justify-content-center'}>
                <BaseRadio
                  name={row.global_cities__city_id}
                  value={'gs'}
                  vModel={row.active}
                  onChange={(e) => {
                    this.onChangeNew(row.active.value, row)
                    console.log(row.active)
                  }}
                >
                  {''}
                </BaseRadio>
              </div>
            )
          }
        },
        {
          title: this.dataLang['global_register_text_6'],
          align: 'center',
          key: '42845',
          width: '20%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>
                  {`${this.dataLang['global_register_text_9']}`}
                </div>
                <BaseSelect
                  vModel={this.selectsFilter.sgf}
                  onInput={(e, w) => {
                    console.log(e)
                    if (e.value) {
                      this.globalData = this.globalData.filter((x) => {
                        return x.active == 'gf'
                      })
                    }
                  }}
                  style={'width: 100%'}
                  label={'label'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    {
                      label: 'Да',
                      value: 1
                    },
                    {
                      label: 'Нет',
                      value: 0
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'d-flex justify-content-center'}>
                <BaseRadio
                  name={row.global_cities__city_id}
                  value={'gf'}
                  vModel={row.active}
                  onChange={(e) => {
                    this.onChangeNew(row.active.value, row)
                  }}
                >
                  {''}
                </BaseRadio>
              </div>
            )
          }
        },
        {
          title: this.dataLang['global_register_text_6'],
          align: 'center',
          key: '42445',
          width: '20%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>
                  {`${this.dataLang['global_register_text_11']}`}
                </div>
                <BaseSelect
                  vModel={this.selectsFilter.sgt}
                  onInput={(e, w) => {
                    console.log(e)
                    if (e.value) {
                      this.globalData = this.globalData.filter((x) => {
                        return x.active == 'gt'
                      })
                    }
                  }}
                  style={'width: 100%'}
                  label={'label'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    {
                      label: 'Да',
                      value: 1
                    },
                    {
                      label: 'Нет',
                      value: 0
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'d-flex justify-content-center'}>
                <BaseRadio
                  name={row.global_cities__city_id}
                  value={'gt'}
                  vModel={row.active}
                  onChange={(e) => {
                    this.onChangeNew(row.active.value, row)
                  }}
                >
                  {''}
                </BaseRadio>
              </div>
            )
          }
        }
      ]
    },

    // }
    columns() {
      return [
        {
          field: '',
          key: 'aaa',
          type: 'checkbox',
          title: '',
          align: 'center',
          width: 30
        },
        {
          field: 'vehicle_places__id',
          title: this.dataLang['city_delivery_register_text_7'],
          key: '411',
          align: 'left',
          width: '5%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseInputNew
                  placeholder=''
                  style={'width: 100%'}
                  disabled={false}
                  onInput={this.fillFilter}
                  additionalParams={{ type: 'like' }}
                  inputName={'vehicle_places__id'}
                  value={''}
                  nameSign={''}
                />
              </div>
            )
          }
        },

        {
          field: 'cities__name',
          title: this.dataLang['city_delivery_register_text_8'],
          align: 'left',
          key: '224',
          width: '10%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.region}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find(
                        (x) => x.field == 'vehicle_places__city_id'
                      )
                    ) {
                      this.filter.push({
                        field: 'vehicle_places__city_id',
                        value: e.cities__id
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'vehicle_places__city_id'
                        )
                      ].value = e.cities__id
                    }
                  }}
                  style={'width: 100%'}
                  label={'cities__name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.cities}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'vehicle_places__address',
          title: this.dataLang['city_delivery_register_text_9'],
          align: 'left',
          key: '232',
          width: '12%',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'vehicle_places__name',
          title: this.dataLang['city_delivery_register_text_10'],
          align: 'left',
          width: '15%',
          key: '454',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.places}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find((x) => x.field == 'vehicle_places__id')
                    ) {
                      this.filter.push({
                        field: 'vehicle_places__id',
                        value: e.vehicle_places__id
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'vehicle_places__id'
                        )
                      ].value = e.vehicle_places__id
                    }
                  }}
                  style={'width: 100%'}
                  label={'vehicle_places__name'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={this.selectsData.places}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'vehicle_places__phone',
          title: this.dataLang['city_delivery_register_text_11'],
          align: 'left',

          key: '3235',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'vehicle_places__is_open',
          title: this.dataLang['city_delivery_register_text_12'],
          align: 'left',
          key: '5456',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.open}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find(
                        (x) => x.field == 'vehicle_places__is_open'
                      )
                    ) {
                      this.filter.push({
                        field: 'vehicle_places__is_open',
                        value: e.value
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'vehicle_places__is_open'
                        )
                      ].value = e.value
                    }
                  }}
                  style={'width: 100%'}
                  label='label'
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    {
                      label: 'Открытая',
                      value: 1
                    },
                    {
                      label: 'Закрытая',
                      value: 0
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'d-flex justify-content-center'}>
                {row.vehicle_places__is_open ? 'Открытая' : 'Закрытая'}
              </div>
            )
          }
        },
        {
          field: 'vehicle_places__is_delivery',
          title: this.dataLang['city_delivery_register_text_13'],
          align: 'left',
          key: '54526',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.delivery}
                  onInput={(e, w) => {
                    console.log(e)
                    if (
                      !this.filter.find(
                        (x) => x.field == 'vehicle_places__is_delivery'
                      )
                    ) {
                      this.filter.push({
                        field: 'vehicle_places__is_delivery',
                        value: e.value
                      })
                    } else {
                      this.filter[
                        this.filter.findIndex(
                          (x) => x.field == 'vehicle_places__is_delivery'
                        )
                      ].value = e.value
                    }
                  }}
                  style={'width: 100%'}
                  label='label'
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    {
                      label: 'Да',
                      value: 1
                    },
                    {
                      label: 'Нет',
                      value: 0
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'d-flex justify-content-center'}>
                {row.vehicle_places__is_delivery ? 'Да' : 'Нет'}
              </div>
            )
          }
        },
        {
          field: 'car',
          title: this.dataLang['city_delivery_register_text_14'],
          align: 'left',
          key: '1134',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.car.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.car.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        },
        {
          field: 'commercial',
          title: this.dataLang['city_delivery_register_text_15'],
          align: 'left',
          key: '43443',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.commercial.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.commercial.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        },
        {
          field: 'bike',
          title: this.dataLang['city_delivery_register_text_16'],
          align: 'left',
          key: '53521',
          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
          ,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.bike.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.bike.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        },
        {
          field: 'track',
          title: this.dataLang['city_delivery_register_text_17'],
          align: 'left',
          key: '43433',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
          ,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.track.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.track.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        },
        {
          field: 'trailer',
          title: this.dataLang['city_delivery_register_text_18'],
          align: 'left',
          key: '43543',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
          ,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.trailer.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.trailer.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        },
        {
          field: 'spec',
          title: this.dataLang['city_delivery_register_text_19'],
          align: 'left',
          key: '46343',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
          ,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.spec.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.spec.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        },
        {
          field: 'air',
          title: this.dataLang['city_delivery_register_text_20'],
          align: 'left',
          key: '43413',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
          ,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.air.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.air.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        },
        {
          field: 'water',
          title: this.dataLang['city_delivery_register_text_21'],
          align: 'left',
          key: '423343',

          ellipsis: {
            showTitle: true,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
          ,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class={'text-center'}>
                {row.water.split('/').map((e, index) => {
                  return <span style={ index == 2 && row.water.split('/').lenght ? 'color: rgb(0, 86, 175);' : ''} key={index}>{e}{index < 2 ? '/' : ''}</span>
                })}
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>

