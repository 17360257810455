import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import style from "@/styles/style.scss";
// import "vue-nav-tabs/themes/vue-tabs.css";

Vue.config.productionTip = false;

import Teleport from 'vue2-teleport';


Vue.prototype.$eventBus = new Vue();


import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
Vue.use(VueToast);

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas, far)

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru';
Vue.component('BaseDatePicker', DatePicker)

import { Timeline } from "vue-visjs";
import i18n from "./i18n";

Vue.component('Teleport', Teleport)
Vue.component("timeline", Timeline);


import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'


dayjs.extend(utc)
dayjs.extend(customParseFormat)



Vue.prototype.$time = dayjs



import VModal from 'vue-js-modal'

Vue.use(VModal, {componentName: 'BaseModal', dynamicDefault: { draggable: true} });

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
              // Если клик был за пределами элемента, вызываем специфичную функцию
              vnode.context[binding.expression](event);
          }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
      // Удаляем обработчик события
      document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

import '../node_modules/vue-easytable/libs/theme-default/index.css'


// import Paginate from 'vuejs-paginate'
// Vue.use(Paginate)


import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
Vue.use(VueFileAgent);

const requireComponent = require.context(
  './components/ui/',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {

  const componentConfig = requireComponent(fileName)

  // Получение имени компонента в PascalCase
  const componentName = upperFirst(
    camelCase(
      // Получаем имя файла независимо от глубины вложенности
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Глобальная регистрация компонента
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

import Multiselect from 'vue-multiselect'

Vue.component('BaseSelect', Multiselect)


import simplebar from 'simplebar-vue';

Vue.component('BaseScrollbar', simplebar)


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
