<template>
  <div class="py-10" ref="raytingTab" style="height: 615px;">
    <div class="row gx-15 h-100">
      <div class="col-6">
        <div class="system-block h-100">
          <p class="fw-bolder fsc-12 mb-15 text-dark">
            {{ dataLang['clients_register_text_73'] }}
          </p>
          <span class="fsc-24"> Нет оценок </span>
          <!-- <table class="rating-table">
            <tr>
              <td style="width: 20%">
                {{ dataLang['clients_register_text_74'] }}
              </td>
              <td style="width: 20%">
                {{ dataLang['clients_register_text_75'] }}
              </td>
              <td style="width: 60%">
                {{ dataLang['clients_register_text_76'] }}
              </td>
            </tr>
            <tr>
              <td>{{ dataLang['clients_register_text_74'] }}</td>
              <td style="padding: 16px 19px;">
                <div style="display: flex; gap: 3px; justify-content: center;">
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.16303 0.874816C5.52984 0.131571 6.58968 0.131572 6.9565 0.874818L8.03129 3.05258C8.17695 3.34772 8.45851 3.55229 8.78422 3.59962L11.1875 3.94884C12.0077 4.06802 12.3353 5.07599 11.7417 5.65453L10.0027 7.34968C9.76701 7.57942 9.65946 7.91042 9.7151 8.23481L10.1256 10.6284C10.2657 11.4453 9.40831 12.0683 8.67468 11.6826L6.5251 10.5525C6.23378 10.3993 5.88575 10.3993 5.59442 10.5525L3.44485 11.6826C2.71122 12.0683 1.85378 11.4453 1.99389 10.6284L2.40443 8.23481C2.46007 7.91042 2.35252 7.57942 2.11683 7.34968L0.377788 5.65453C-0.215729 5.076 0.11178 4.06802 0.932001 3.94884L3.3353 3.59962C3.66101 3.55229 3.94258 3.34772 4.08824 3.05258L5.16303 0.874816Z"
                      fill="#F5B704"
                    />
                  </svg>
                </div>
              </td>
              <td>{{ dataLang['clients_register_text_76'] }}</td>
            </tr>
          </table> -->
        </div>
      </div>
      <div class="col-6">
        <div class="system-block h-100">
          <div class="row gx-30 mb-15">
            <div class="col-6">
              <div
                class="bg-confirmation d-flex justify-content-center align-items-center rounded-5 text-center fw-bold text-white"
                style="height: 195px; font-size: 96px"
                v-html="
                  dataForTab.clients__reting ? dataForTab.clients__reting : 0
                "
              ></div>
            </div>
            <div class="col-6">
              <div
                class="d-flex align-items-center text-dark fw-semi-bold"
                style="height: 195px; font-size: 50px; line-height: 62.5px"
              >
                {{ dataLang['clients_register_text_77'] }}
              </div>
            </div>
          </div>
          <p class="fsc-14 mb-30">
            {{ dataLang['clients_register_text_78'] }} <br /><br />
            {{ dataLang['clients_register_text_79'] }}
          </p>
          <div class="bg-system-secondary px-30 py-15 text-dark fsc-14">
            <p class="mb-0 fw-semi-bold">
              {{ dataLang['vehicle_modal_rayting_text3'] }}
            </p>
            <span>{{ dataLang['clients_register_text_81'] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.rating-table {
  width: 100%;
  font-size: 12px;

  & tr:first-child {
    font-weight: 500;
    td {
    }
  }
  & tr:nth-child(2n + 1) {
    background-color: #f5f5f5;
  }
  & td {
    padding: 16px 10px;
    text-align: center;
    border: 1px solid #cacaca;
  }
}
</style>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import GeneralService from '@/api/GeneralService'
import AddNewTriesModal from '@/components/vehicles-page-single-modal-inner/AddNewTriesModal.vue'
import httpInstance from '@/http-common.js'
export default {
  components: {
    AddNewTriesModal
  },
  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },
    modalInfo: {
      type: Object
    },
    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      loadingState: true,
      tabInfo: this.modalInfo,
      currentRayting: 0,
      dataForTab: {}
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    }
  },
  created() {
    GeneralService.get(
      `/clients/${this.tabInfo.id}?fields=clients__reting,clients__id`
    )
      .then((data) => {
        this.dataForTab = data.data.data.rows[0]
        console.log(this.dataForTab)
      })
      .catch((e) => {
        console.log(e)
      })
  },
  computed: {
    ...mapState('translationModule', ['dataLang'])
  }
}
</script>
