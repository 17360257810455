<template>
  <div class="py-10 h-100" ref="blackListClientsTab">
    <div
      class="system-block position-relative h-100"
      style="max-height: 595px; min-height: 595px"
    >
      <!-- <p class="fw-bolder fsc-12 mb-15 text-dark">
        {{ dataLang['clients_register_text_73'] }}
      </p> -->
      <BaseLoader v-if="loadingState"></BaseLoader>
      <div class="d-flex gap-20">
        <div ref="clients__is_blacklisted" class="mb-10" v-if="!loadingState">
          <BaseCheckboxV2
            placeholder=""
            class=""
            input-name="clients__is_blacklisted"
            v-model="dataForTab.clients__is_blacklisted"
            signClass="fsc-11 text-dark"
            @oncheckeds="(data) => updateValue(data, true)"
            >{{ dataLang['clients_register_text_14'] }}</BaseCheckboxV2
          >
        </div>

        <div class="">
          <BaseCheckboxV2
            placeholder=""
            class="mb-10"
            input-name="gchs"
            v-model="gchs"
            signClass="fsc-11 text-dark"
            disabled
            >{{ dataLang['clients_register_text_175'] }}</BaseCheckboxV2
          >
        </div>
      </div>
      <div v-if="showData && !loadingState">
        <div class="wp-100 mb-10" ref="clients__blacklist_comment">
          <BaseInputNew
            placeholder=""
            :width="'100%'"
            class="wp-100 gap-5"
            signClass="fsc-12 ws-nowrap text-dark"
            input-name="clients__blacklist_comment"
            @input="updateValue"
            :horizontalTitle="true"
            :value="dataForTab.clients__blacklist_comment"
            :name-sign="dataLang['clients_register_text_87']"
          >
          </BaseInputNew>
        </div>
        <div
          class="mb-10 d-flex gap-25 fsc-11"
          ref=""
          style="width: 291px"
          v-if="dataForTab.clients__balance < 0"
        >
          <span>{{ dataLang['clients_register_text_88'] }}:</span>
          <span>{{ dataForTab.clients__balance }}</span>
        </div>
        <p class="fsc-12">{{ dataLang['clients_register_text_89'] }}</p>
        <div class="d-flex justify-content-between mb-10">
          <UploadFileWithPreview
            style="height: 109px; width: 109px"
            v-for="(item, index) of galleryData"
            :key="item.client_blacklist_gallery__id"
            :file-preview="item.client_blacklist_gallery__file"
            accept="image/png,image/jpg,image/jpeg"
            :max-weight="10000"
            :max-x-size="1000"
            :max-y-size="750"
            preview="image.svg"
            @clickOnImage="clickOnImage(index)"
            :additionalParams="{ keyData: index }"
            type-of-form-data="clientDoc"
            :error-message-max-weight="dataLang['error_message_max_file_size']"
            classes="file-load--gallery"
            @load-file="(params) => loadfile(params)"
            @remove-file="(params) => removefile(params)"
          />
        </div>

        <!-- <span class="fsc-24"> Нет оценок </span> -->
        <div class="px-25 py-15 mb-10" style="background-color: #e7e7e7">
          <div ref="clients__is_wanted" class="">
            <BaseCheckboxV2
              placeholder=""
              class="mb-10"
              input-name="clients__is_wanted"
              v-model="dataForTab.clients__is_wanted"
              signClass="fsc-11 text-dark"
              @oncheckeds="updateValue"
              >{{ dataLang['clients_register_text_91'] }}</BaseCheckboxV2
            >
            <p class="fsc-12 mb-0">
              {{ dataLang['clients_register_text_92'] }}
            </p>
          </div>
        </div>
        <div
          class="px-30 py-15 mb-10 fsc-12"
          style="background-color: #f5f5f5; color: #ff2400"
          v-if="dataForTab.clients__balance < 0 || dataForTab.clients__is_blacklisted == 1"
        >
          <span>{{ dataLang['clients_register_text_93'] }}</span>
          <p>{{ dataLang['clients_register_text_94'] }}</p>
        </div>
        <gallery
          :images="images"
          :index="index"
          @close="index = null"
        ></gallery>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.rating-table {
  width: 100%;
  font-size: 12px;

  & tr:first-child {
    font-weight: 500;
    td {
    }
  }
  & tr:nth-child(2n + 1) {
    background-color: #f5f5f5;
  }
  & td {
    padding: 16px 10px;
    text-align: center;
    border: 1px solid #cacaca;
  }
}
</style>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import GeneralService from '@/api/GeneralService'
import VueGallery from 'vue-gallery'
import EventBus from '@/event-bus'
import UploadFileWithPreview from '@/components/UploadFileWithPreview.vue'
import StorageFileService from '@/api/StorageFileService'
export default {
  components: {
    UploadFileWithPreview,
    gallery: VueGallery
  },
  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },
    modalInfo: {
      type: Object
    },
    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      showData: false,
      loadingState: true,
      tabInfo: this.modalInfo,
      currentRayting: 0,
      dataForTab: {},
      dataForEmit: {},
      images: [],
      index: null,
      galleryData: [],
      timeout: null,
      gchs: false
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      this.loadingState = true
      if (Object.keys(this.dataForEmit).length) {
        GeneralService.edit(`/clients/${this.modalInfo.id}`, this.dataForEmit)
          .then((data) => {
            this.$toast.success(data.data.message)
            callback(true, nameTab, oldTab, newTab, flagIsClose)
          })
          .catch((err) => {
            this.$toast.success(err.response.data.message)
            callback(false, nameTab, oldTab, newTab, flagIsClose)
          })
      } else {
        callback(true, '', oldTab, newTab, flagIsClose)
      }
      // callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    clickOnImage(index) {
      console.log(index, this.images)
      this.index = index
    },
    loadfile(params) {
      this.loadingState = true
      StorageFileService.upload(params.fileInFormData)
        .then((data) => {
          this.galleryData[params.keyData].client_blacklist_gallery__file =
            data.data.data.url
          this.images[params.keyData] = data.data.data.url
          GeneralService.edit(`/clients/${this.modalInfo.id}`, {
            clients__blacklist_gallery: this.galleryData
          }).then((data) => {
            console.log(this.galleryData)
            this.$toast.success(data.data.message)
            this.loadMainInfo()
          })
        })
        .catch((err) => {
          console.log(err)
          this.$toast.error(err.response.data.message)
        })
    },
    removefile(params) {
      this.loadingState = true
      this.galleryData[params.keyData].client_blacklist_gallery__file = ''
      this.images[params.keyData] = ''
      GeneralService.edit(`/clients/${this.modalInfo.id}`, {
        clients__blacklist_gallery: this.galleryData
      }).then((data) => {
        this.$toast.success(data.data.message)
        this.loadMainInfo()
        this.loadingState = false
      })
    },

    updateValue(data, flag) {
      this.dataForEmit[data.name] = data.value
      this.$refs[data.name].classList.remove('input-error')
      if (flag) {
        GeneralService.edit(`/clients/${this.modalInfo.id}`, {
          ...this.dataForEmit
        })
          .then((data) => {
            this.showData = !this.showData
            this.$toast.success(data.data.message)
            EventBus.$emit('updateMainModalComponent')
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(err.response.data.message)
          })
      }
      if (data.name == 'clients__blacklist_comment') {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          GeneralService.edit(`/clients/${this.modalInfo.id}`, {
            clients__blacklist_comment: data.value
          })
            .then((data) => {
              this.$toast.success(data.data.message)
            })
            .catch((err) => {
              this.$toast.success(err.response.data.message)
            })
        }, 700)
      }
    },

    loadMainInfo() {
      this.loadingState = true
      GeneralService.get(
        `/clients/${this.tabInfo.id}?joins=client_blacklist_gallery`
      )
        .then((data) => {
          this.dataForTab = data.data.data.rows[0]
          this.showData = data.data.data.rows[0].clients__is_blacklisted
          console.log(this.dataForTab, this.galleryData)

          this.gchs = this.dataForTab.clients__balance < 0

          // this.galleryData.forEach((element, index) => {
          //   let indexed =
          //     data.data.data.rows[0].clients__blacklist_gallery.findIndex(
          //       (x) =>
          //         x.client_blacklist_gallery__id ==
          //         element.client_blacklist_gallery__id
          //     )
          //   if (indexed >= 0) {
          //     this.galleryData[index] =
          //       data.data.data.rows[0].clients__blacklist_gallery[indexed]
          //   }
          //   console.log(
          //     this.galleryData,
          //     this.images,
          //     element.client_blacklist_gallery__file
          //   )
          // })
          // this.galleryData.forEach((element, index) => {
          //   this.images.push(element.client_blacklist_gallery__file)
          //   element.client_blacklist_gallery__client_id = this.tabInfo.id
          // })
          this.galleryData = data.data.data.rows[0].clients__blacklist_gallery
          this.galleryData.forEach((element, index) => {
            if (element.client_blacklist_gallery__file) {
              this.images.push(element.client_blacklist_gallery__file)
            } else {
              this.images.push('')
            }
          })
          this.loadingState = false
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },
  created() {
    this.loadMainInfo()
  },
  computed: {
    ...mapState('translationModule', ['dataLang'])
  }
}
</script>
