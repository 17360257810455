import http from "@/http-common.js";

class PlacesService {
  basePathApiService = "/places";
  getAll(sityID) {
    return http.get(this.basePathApiService + `/city/${sityID}`);
  }
  getWithID(id) {
    return http.get(this.basePathApiService + `/${id}`);
  }
  getAllWithoutID(params) {
    return http.get(this.basePathApiService, { ...params });
  }
  create(createData) {
    return http.post(this.basePathApiService, createData);
  }
  update(id, updateData) {
    return http.put(this.basePathApiService + `/${id}`, updateData);
  }
  delete(id) {
    return http.delete(this.basePathApiService + `/${id}`);
  }

}
export default new PlacesService();
