<template>
    <div>
        <BaseTopLine>
            <div class="d-flex gap-10 align-items-center">
                <div class="btn btn-sm btn-primary" @click="openModalForCreate('orgpolicyModal')">Добавить</div>
                <div class="btn btn-sm btn-opposite" @click="deleteActionAndModal">Удалить</div>
            </div>
        </BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'" :click-handler="clickLoadData"
                :prev-text="'Назад'" :next-text="'Далее'" :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="orgpolicyData" :props-columns-setting="fieldsTableOrgpolicy"
            :selectRowActivateFlag="true" :modalActivateFlag="true" nameForAction="orgpolicyModal" v-if="orgpolicyLoadStatus == 'success'" >
            </BaseReuseTable>
        </div>
        <Teleport to="body">
            <Modal nameForAction="orgpolicyModal" @closeModal="closeModal">
                <p class="fs-5 fw-bold">Добавить оргполитику</p>
                <div class="mb-20">
                    <BaseInput  placeholder="" title="Default Title" @onInput="updateValue"
                         name-sign="Заголовок" input-name="orgpolicy__name" :value="dataForModal.orgpolicy__name">
                    </BaseInput>

                    <VueFileAgent class="mt-20" ref="vueFileAgent" :theme="'list'" :multiple="false" :deletable="true"
                        :meta="true" :accept="'image/*'" :maxSize="'10MB'" :maxFiles="1" :helpText="'Выберите файл'"
                        :errorText="{
                            type: 'Only images',
                            size: 'Files should not exceed 10MB in size',
                        }" 
                      
                        @delete="deleteFile($event)"
                        @beforedelete="onBeforeDelete($event)"
                        v-model="orgpolicyAddForm.fileRecords"></VueFileAgent>
                </div>
                <div class="d-flex gap-10 mt-15">
                    <div class="btn btn-sm btn-primary" @click="sendDataFromModal">{{
                        dataLang.form_btn__btn_save }}</div>
                    <div class="btn btn-sm btn-opposite" @click="btnHandlerCloseModal">{{
                        dataLang.form_btn__btn_caneсl }}</div>
                </div>
            </Modal>
            <Modal nameForAction="acceptDelete">
                <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
                <div class="d-flex justify-content-center mt-10">
                    <div class="btn btn-sm btn-danger" @click="deleteRow('acceptDelete')">Да</div>
                </div>
            </Modal>
        </Teleport>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import EventBus from '@/event-bus.js'
import OrgpolicyService from "@/api/OrgpolicyService.js";
import BaseTextarea from "@/components/ui/BaseTextarea.vue";
import StorageFileService from "@/api/StorageFileService";
export default {
    components: {
    Modal,
    BaseTextarea
},

    props: {},
    data() {
        return {
            orgpolicyAddForm: {
                fileRecords: null
            },

            dataForModal: {},
            editData: {},
            activeSelectRow: null
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getOrgpolicyAll({params:{
                    limit: 10,
                    page: numPage
                }
            })
        },
        openModalForCreate(nameModal) {
            EventBus.$emit("openModal", { data: null, nameForAction: nameModal, mode: 'create' })
        },


        deleteActionAndModal() {
            if (this.activeSelectRow) {
                EventBus.$emit("openModal", { data: null, nameForAction: "acceptDelete", mode: 'create' });
            } else {
                this.$toast.error('Вы не выбрали ни одного поля')
            }
        },
        deleteRow(nameModal) {
            this.deleteOrgpolicyData(this.activeSelectRow)
            this.activeSelectRow = null
            EventBus.$emit("closeModal", { nameForAction: nameModal })
        },


        sendDataFromModal() {
            if (this.dataForModal.orgpolicy__id) {
                console.log(this.orgpolicyAddForm);
                this.editOrgpolicyMultiple({ id: this.dataForModal.orgpolicy__id, dataEdit: this.orgpolicyAddForm })
                EventBus.$emit("closeModal", { nameForAction: 'orgpolicyModal' })
            } else {
                this.orgpolicyAddForm.file = this.orgpolicyAddForm.fileRecords.file;
                this.orgpolicyAddForm.type = "file";
                this.createOrgpolicyData(this.orgpolicyAddForm);
                // this.$refs[orgpolicyModal].$el.querySelectorAll('input').forEach(elem => {
                //     elem.value = ''
                // })
                
                EventBus.$emit("closeModal", { nameForAction: 'orgpolicyModal' })
            }
        },


        updateValue(data) {
            this.orgpolicyAddForm[data.name] = data.value
        },

        prepareKeysForModal(payloadData) {
            //this.orgpolicyAddForm = {}

         
            StorageFileService.getFilesData(payloadData.orgpolicy__file).then((fileData) => {
                
                let data = fileData.data.data[payloadData.orgpolicy__file];
                if(data){
                    data = data[0];
                    this.orgpolicyAddForm.fileRecords = [{
                        "name":data.filename,
                        "size":data.size,
                        "type":data.mime,
                        "url":payloadData.orgpolicy__file,
                        "id": payloadData.orgpolicy__id,
                    }]
                }
            });
            

            this.dataForModal = {}
            if (!payloadData) {
                for (const key in this.orgpolicyData[0]) {
                    this.dataForModal[key] = null
                }
            } else {
                this.dataForModal = Object.assign({}, payloadData)
            }

        },

        closeModal(data) {
            this.activeSelectRow = {}
            this.dataForModal = {}
            this.orgpolicyAddForm.fileRecords = null
        },

        btnHandlerCloseModal(nameModal) {
            EventBus.$emit("closeModal", { nameForAction: 'orgpolicyModal' })
        },

        onBeforeDelete: function (fileRecord) {
            if(fileRecord.id)
                OrgpolicyService.deleteFile(fileRecord.id).then((fileData) => {
                    this.orgpolicyAddForm.fileRecords = null;
                    this.$toast.success('Картинка успешно удалена')
                });
            else {
                this.orgpolicyAddForm.fileRecords = null;
                this.$toast.success('Картинка успешно удалена')
            }
                
        },

        deleteFile(fileRecord){
           
        },

        ...mapActions("orgpolicyModule", ["getOrgpolicyAll", 'createOrgpolicyData', 'editOrgpolicyMultiple', 'deleteOrgpolicyData','downloadOrgpolicyFile', 'setOrgpolicyRoute']),


    },
    mounted() {
      
        EventBus.$on("openModal", (payload) => {
            this.editData = payload.data
            console.log(payload.data);
            this.prepareKeysForModal(payload.data)
        });
        EventBus.$on("selectTableRows", (payload) => {
            this.activeSelectRow = payload.data.orgpolicy__id
        });
        EventBus.$on("deselectTableRows", (payload) => {
            this.activeSelectRow = null
        });

    },

    created() {
        this.getOrgpolicyAll({ params: { page: 1, limit: 10 }})
    },
    computed: {
        ...mapState("orgpolicyModule", ["orgpolicyData", "orgpolicyLoadStatus", 'paginationOrgpolicyStateParams']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("orgpolicyModule", ["numPages"]),


        fieldsTableOrgpolicy() {
            return [
                { title: 'ID', field: "orgpolicy__id", headerFilter: "input" },
                { title: 'Дата создания', field: "orgpolicy__created_at", headerFilter: "input" },
                // { title: 'Дата актуализации', field: "orgpolicy__updated_at", headerFilter: "input" },
                { title: 'Наименование', field: "orgpolicy__name", headerFilter: "input" },
                { title: 'Файл', field: "orgpolicy__file", headerFilter: "input", formatter:"link", formatterParams:{
                     "target":"_blank"
                }},
            ]
        }
    },
};
</script>
    
    