<template>
    <div>
        <BaseTopLine>

        </BaseTopLine>
        <div class="p-20">
            <BasePagination :page-count="numPages" :page-range="3" :margin-pages="2" :break-view-class="'d-none'"
                :click-handler="clickLoadData" :prev-text="'Назад'" :next-text="'Далее'"
                :container-class="'pagination pagination-sm'" :page-class="'page-item'" :pageLinkClass="'page-link'">
            </BasePagination>

            <BaseReuseTable :props-data="employeesAdminData" v-if="employeesAdminLoadStatus == 'success'" :props-columns-setting="fieldsTableemployeesAdmin"
                 nameForAction="employeesAdminModal"
                >
            </BaseReuseTable>
        </div>
    </div>
</template>
    
    
<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Modal from '@/components/Modals.vue'
import JobtitlesService from "@/api/JobtitlesService.js";
import EventBus from '@/event-bus.js'
export default {
    components: {
        Modal
    },

    props: {},
    data() {
        return {
            updatedValues: {},
            dataForModal: {},
            editData: {},
            activeSelectRow: null,
            dataJobTitleOptions: [],

            listAccessPage: [],
            listUpdateAccessPage: [],
            activeSelectRow: null,
            loadJobTitiles: false,
        };
    },
    methods: {
        clickLoadData(numPage) {
            this.getEmployeesAdminAll({
                params: {
                    limit: 100,
                    page: numPage
                }
            })
        },


        ...mapActions("employeesAdminModule", ["getEmployeesAdminAll", 'createEmployeesAdminData', 'editEmployeesAdminMultiple', 'deleteEmployeesAdminData']),


    },

    mounted() { },

    created() {
        this.getEmployeesAdminAll({
            params: {
                page: 1, limit: 100,
            }
        })


        JobtitlesService.getAll().then(data => {
            this.dataJobTitleOptions = data.data.data.rows
            this.loadJobTitiles = true
        }).catch(err => { console.log(err); })
    },
    computed: {
        ...mapState("employeesAdminModule", ["employeesAdminData", "employeesAdminLoadStatus", 'paginationemployeesAdminStateParams', 'passwordForModal']),
        ...mapState("translationModule", ["dataLang"]),
        ...mapGetters("employeesAdminModule", ["numPages"]),


        fieldsTableemployeesAdmin() {
            return [
                { title: 'ID', field: "employees__id", headerFilter: "input" },
                { title: 'ФИО', field: "employees__name", headerFilter: "input" },
                {
                    title: 'Должность', field: "employees__jobtitle_id", headerFilter: "input",
                    formatter: (cell) => {
                        let index = this.dataJobTitleOptions.findIndex((item, index) => item.employee_jobtitles__id == cell.getData().employees__jobtitle_id)
                        console.log(this.dataJobTitleOptions[index]);
                        // this.dataJobTitleOptions[index].employee_jobtitles__name
                        return this.dataJobTitleOptions[index] ? this.dataJobTitleOptions[index].employee_jobtitles__name : "Не определена"
                    }
                },
                { title: 'Телефон', field: "employees__phone", headerFilter: "input" },
                { title: 'Email', field: "employees__email", headerFilter: "input" },
                { title: 'Дата последнего входа', field: "employees__last_login_at", headerFilter: "input" },
            ]
        },
    },
};
</script>
    
    